import { CssFunctionReturnType } from '@styled-system/css';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  css,
  CSSObject,
  FlattenInterpolation,
  Interpolation,
  InterpolationFunction,
  ThemedStyledProps,
} from 'styled-components/macro';

import { not } from 'shared/helpers/boolean';
import { AppTheme } from 'shared/styles/globalTheme';

type CssReturn = ReturnType<typeof css>;

type MediaFnLegacy = <Props extends Record<string, any>>(
  first:
    | CSSObject
    | TemplateStringsArray
    | FlattenInterpolation<ThemedStyledProps<Props, AppTheme>>
    | InterpolationFunction<ThemedStyledProps<Props, AppTheme>>,
  ...interpolations: Array<Interpolation<ThemedStyledProps<Props, AppTheme>>>
) => FlattenInterpolation<ThemedStyledProps<Props, AppTheme>>;

interface MediaFn extends MediaFnLegacy {
  (css: CssReturn | CssFunctionReturnType): CssReturn;
}

type Query = string;
type Media = MediaFn & { query: Query };

type QueryConfig = { feat?: string; from?: number; not?: boolean; to?: number };
const createQuery = (config: QueryConfig): Query => {
  const { feat, from, not: notOperator, to } = config;

  if (not(from) && not(to)) {
    throw new Error('You have to provide at least one value');
  }

  const max = to ? `(max-width: ${to}px)` : undefined;
  const min = from ? `(min-width: ${from}px)` : undefined;

  const query = ['screen', min, max, feat].filter(Boolean).join(' and ');

  return notOperator ? `not ${query}` : query;
};

type MediaFnConfig = { query: Query };
const createMediaFn = (config: MediaFnConfig): MediaFn => {
  return (styles: any, ...rest: any[]) => {
    if ('raw' in styles) {
      /**
       * Handle cases such as
       * media.sm`...`
       */
      return css`
        ${config.query} {
          ${css(styles, ...rest)}
        }
      `;
    }

    /**
     * Idiomatic usage with css or scss function
     * media.sm(css`...`) or media.sm(scss({...}))
     */
    return css`
      ${config.query} {
        ${styles}
      }
    `;
  };
};

const createMedia = (query: Query): Media => {
  const mediaQuery = '@media ' + query;
  const mediaFn = createMediaFn({ query: mediaQuery });
  return Object.assign(mediaFn, { query });
};

export const media = {
  /**
   * mobile (less than 768px) and tablet in portrait mode (less than 1280px)
   */
  sm: createMedia(
    [
      createQuery({ to: 768 }),
      createQuery({
        to: 1280,
        feat: '(max-height: 1200px) and (orientation: portrait)',
      }),
      createQuery({ to: 1280, feat: '(max-height: 480px)' }),
    ].join(', ')
  ),

  /**
   * desktop-only styles (768px and more in landscape)
   */
  desktop: createMedia(
    [
      createQuery({ from: 768, feat: '(orientation: landscape)' }),
      createQuery({ from: 1280 }),
    ].join(', ')
  ),

  /**
   * large screens (1280px and more)
   */
  large: createMedia(createQuery({ from: 1280 })),

  /**
   * xlarge screens (1600px and more)
   */
  xl: createMedia(createQuery({ from: 1600 })),

  /**
   * tablet (768px to 1280px); prefer sm and desktop instead
   */
  tablet: createMedia(createQuery({ from: 768, to: 1280 })),
  /**
   * mobile (less than 1024px) and tablet in portrait mode (less than 1280px)
   */
  md: createMedia(
    [
      createQuery({ to: 1024 }),
      createQuery({
        to: 1280,
        feat: '(max-height: 1200px) and (orientation: portrait)',
      }),
      createQuery({ to: 1280, feat: '(max-height: 480px)' }),
    ].join(', ')
  ),
};

export const useIsMobile = ({ large }: { large?: boolean } = {}) => {
  return useMediaQuery({ query: large ? media.md.query : media.sm.query });
};

export const useIsTablet = () => {
  return useMediaQuery({ query: media.tablet.query });
};

export const useIsXL = () => {
  return useMediaQuery({ query: media.xl.query });
};

type MediaProps = {
  children?: ReactNode | ((matches: boolean, isTable?: boolean) => ReactNode);
};

export const Mobile = (props: MediaProps) => {
  const isMobile = useIsMobile({ large: true });
  const isTablet = useIsTablet();

  if (typeof props.children === 'function') {
    return props.children(isMobile, isTablet);
  }
  return isMobile ? props.children : null;
};

export const Desktop = (props: MediaProps) => {
  const isMobile = useIsMobile({ large: true });
  if (typeof props.children === 'function') {
    return props.children(not(isMobile));
  }
  return isMobile ? null : props.children;
};

export const systemBreakpoints = ['@media ' + media.sm.query, null];
