import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MlsStatus } from 'filters/filtersContants';
import {
  DEFAULT_MLS_STATUSES,
  FILTERS_DEFAULT_STATE as filters,
} from 'filters/filtersReducer';
import { LISTINGS_DEFAULT_STATE as listings } from 'listings/listingsReducer';
import { generateListingsQueryUrl } from 'listings/listingUrlParser';
import {
  calculateMapDataFromUserLocation,
  calculateNewMapPosition,
} from 'map/mapHelper';
import { FromLinkStatus } from 'permissions/permissionsConstants';
import { searchInitialState } from 'search/search-slice';
import * as Styled from 'shared/components/SearchBar/components/SearchBarNearbyMenu/SearchBarNearbyMenuStyled';
import {
  OptionIconStyled,
  SubHeaderWrapper,
} from 'shared/components/SearchBar/SearchBarStyled';
import { SortingData } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { ReactComponent as ForSaleIcon } from 'shared/icons/nearby/for-sale.svg';
import { ReactComponent as Location } from 'shared/icons/nearby/location.svg';
import { ReactComponent as OpenHouseIcon } from 'shared/icons/nearby/openhouse.svg';
import { ReactComponent as SoldIcon } from 'shared/icons/nearby/sold.svg';
import { useIsMobile } from 'styled-system/responsive';
import { setLinkData } from 'user/userActions';
import { UserLocation } from 'user/userTypes';

export interface SearchBarNearbyMenuProps {
  userLocation: UserLocation;
  closeMenu: () => void;
}

export const searchBarNearbyMenuItems = [
  {
    icon: <OpenHouseIcon />,
    text: 'Open Houses',
    link: '',
  },
  {
    icon: <ForSaleIcon />,
    text: 'For Sale',
    link: '',
  },
  {
    icon: <SoldIcon />,
    text: 'Recently Sold',
    link: '',
  },
];

export const SearchBarNearbyMenu: React.FC<SearchBarNearbyMenuProps> = ({
  userLocation,
  closeMenu,
}) => {
  const dispatch = useDispatch();

  const [menuItems, setMenuItems] = useState<
    Array<{ icon: JSX.Element; text: string; link: string }>
  >(searchBarNearbyMenuItems);

  const isMobile = useIsMobile();

  useEffect(() => {
    const mapDataFromUserLocation =
      calculateMapDataFromUserLocation(userLocation);
    const newMapData = calculateNewMapPosition(
      mapDataFromUserLocation,
      isMobile
    );

    const mapData = {
      zoom: newMapData.zoom,
      ...newMapData.boundaries,
    };

    setMenuItems([
      {
        ...menuItems[0],
        link: `${RoutePath.LISTINGS}?${
          generateListingsQueryUrl({
            filters: {
              ...filters,
              mlsStatus: [...DEFAULT_MLS_STATUSES],
              openHouseOnly: true,
              openHouse: 7,
              lot: true,
              farm: true,
              multiFamily: true,
              commercialSale: true,
            },
            listings: { ...listings, sortBy: SortingData.distance },
            tags: searchInitialState.tags,
            userLocation,
            mapData,
          }).search
        }`,
      },
      {
        ...menuItems[1],
        link: `${RoutePath.LISTINGS}?${
          generateListingsQueryUrl({
            filters: {
              ...filters,
              lot: true,
              farm: true,
              multiFamily: true,
              commercialSale: true,
            },
            listings: { ...listings, sortBy: SortingData.distance },
            tags: searchInitialState.tags,
            userLocation,
            mapData,
          }).search
        }`,
      },
      {
        ...menuItems[2],
        link: `${RoutePath.LISTINGS}?${
          generateListingsQueryUrl({
            filters: {
              ...filters,
              mlsStatus: [MlsStatus.Sold],
              soldTimeframe: 365,
              lot: true,
              farm: true,
              multiFamily: true,
              commercialSale: true,
            },
            listings: { ...listings, sortBy: SortingData.distance },
            tags: searchInitialState.tags,
            userLocation,
            mapData,
          }).search
        }`,
      },
    ]);
  }, [userLocation]);

  const handleLinkClick = () => {
    dispatch(
      setLinkData({
        linkType: FromLinkStatus.Nearby,
      })
    );
    closeMenu();
  };

  return (
    <>
      <SubHeaderWrapper>
        <OptionIconStyled>
          <Location />
        </OptionIconStyled>
        <Styled.NearbyTitle>Search Nearby</Styled.NearbyTitle>
      </SubHeaderWrapper>
      <Styled.NearbyItemsWrapper divider>
        {menuItems.map(item => (
          <Styled.NearbyLink
            to={item.link}
            key={item.text}
            onClick={handleLinkClick}
          >
            <Styled.NearbyLinkIcon>{item.icon}</Styled.NearbyLinkIcon>
            <Styled.NearbyLinkText>{item.text}</Styled.NearbyLinkText>
          </Styled.NearbyLink>
        ))}
      </Styled.NearbyItemsWrapper>
    </>
  );
};
