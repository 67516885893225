import { MlsStatus } from 'filters/filtersContants';
import { PermissionRoles } from 'permissions/permissionsConstants';

export type HideHistoryPrice = ((mlsStatus: MlsStatus) => boolean) | false;
export type LockImages =
  | ((mlsStatus: MlsStatus, isRa: boolean) => number | false)
  | false;

export interface Permissions {
  lockImages: LockImages;
  hideHistoryPrice: HideHistoryPrice;
  preventSaveSearch: boolean;
  preventSaveToCollection: ((mlsStatus: MlsStatus) => boolean) | false;
  hideSaveToCollection: ((mlsStatus: MlsStatus) => boolean) | false;
  preventVirtualTour: ((mlsStatus: MlsStatus) => boolean) | false;
  preventDismissListing: boolean;
  preventFavoritePlaces: boolean;
  disableListingItem: ((mlsStatus: MlsStatus) => boolean) | false;
  isStatusFiltersDisabled: boolean;
  preventShareListing: boolean;
}

export interface PermissionsConfig {
  [PermissionRoles.Guest]: Permissions;
  [PermissionRoles.NotInitialized]: Permissions;
  [PermissionRoles.NotVerifiedUser]: Permissions;
  [PermissionRoles.VerifiedUser]: Permissions;
}

export const getPermissionDataDependOnMlsStatus = <
  Value,
  SoldPendingValue,
  OurListingValue
>(
  value: Value,
  soldOrPendingValue: SoldPendingValue,
  ourListingValue?: OurListingValue
) => {
  const mlsStatusPermissionsMap: Map<MlsStatus, Value | SoldPendingValue> =
    new Map();
  const ourListingsMlsStatusPermissionsMap: Map<
    MlsStatus,
    OurListingValue | Value | SoldPendingValue | undefined
  > = new Map();

  mlsStatusPermissionsMap.set(MlsStatus.Active, value);
  mlsStatusPermissionsMap.set(MlsStatus.PendingTakingBackups, value);
  mlsStatusPermissionsMap.set(MlsStatus.Pending, soldOrPendingValue);
  mlsStatusPermissionsMap.set(MlsStatus.Sold, soldOrPendingValue);
  mlsStatusPermissionsMap.set(MlsStatus.Leased, soldOrPendingValue);
  mlsStatusPermissionsMap.set(MlsStatus.Withdrawn, soldOrPendingValue);
  mlsStatusPermissionsMap.set(MlsStatus.Expired, soldOrPendingValue);

  ourListingsMlsStatusPermissionsMap.set(MlsStatus.Active, value);
  ourListingsMlsStatusPermissionsMap.set(MlsStatus.PendingTakingBackups, value);
  ourListingsMlsStatusPermissionsMap.set(MlsStatus.Pending, ourListingValue);
  ourListingsMlsStatusPermissionsMap.set(MlsStatus.Sold, ourListingValue);
  ourListingsMlsStatusPermissionsMap.set(MlsStatus.Leased, ourListingValue);
  ourListingsMlsStatusPermissionsMap.set(
    MlsStatus.Withdrawn,
    soldOrPendingValue
  );
  ourListingsMlsStatusPermissionsMap.set(MlsStatus.Expired, soldOrPendingValue);

  return (status: MlsStatus, isOurListing = false) =>
    (isOurListing
      ? ourListingsMlsStatusPermissionsMap.get(status)
      : mlsStatusPermissionsMap.get(status)) ?? false;
};

const permissionForGuest: Permissions = {
  lockImages: getPermissionDataDependOnMlsStatus(5, 0, 5),
  hideHistoryPrice: getPermissionDataDependOnMlsStatus(true, true),
  preventSaveSearch: true,
  preventSaveToCollection: getPermissionDataDependOnMlsStatus(true, true),
  hideSaveToCollection: getPermissionDataDependOnMlsStatus(false, true),
  preventVirtualTour: getPermissionDataDependOnMlsStatus(true, true),
  preventDismissListing: true,
  preventFavoritePlaces: true,
  disableListingItem: getPermissionDataDependOnMlsStatus(false, true),
  isStatusFiltersDisabled: true,
  preventShareListing: true,
};

export const permissionsConfig: PermissionsConfig = {
  [PermissionRoles.Guest]: permissionForGuest,
  [PermissionRoles.NotInitialized]: permissionForGuest,
  [PermissionRoles.NotVerifiedUser]: {
    lockImages: getPermissionDataDependOnMlsStatus(9999, 0, 5),
    hideHistoryPrice: getPermissionDataDependOnMlsStatus(true, true),
    preventSaveSearch: false,
    preventSaveToCollection: getPermissionDataDependOnMlsStatus(false, true),
    hideSaveToCollection: getPermissionDataDependOnMlsStatus(false, true),
    preventVirtualTour: getPermissionDataDependOnMlsStatus(false, true),
    preventDismissListing: false,
    preventFavoritePlaces: false,
    disableListingItem: getPermissionDataDependOnMlsStatus(false, true),
    isStatusFiltersDisabled: true,
    preventShareListing: false,
  },
  [PermissionRoles.VerifiedUser]: {
    lockImages: false,
    hideHistoryPrice: false,
    preventSaveSearch: false,
    preventSaveToCollection: false,
    hideSaveToCollection: false,
    preventVirtualTour: false,
    preventDismissListing: false,
    preventFavoritePlaces: false,
    disableListingItem: false,
    isStatusFiltersDisabled: false,
    preventShareListing: false,
  },
};
