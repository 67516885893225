import { all, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import { updateCollectionItems } from 'collections/collectionsActions';
import { getCollectionId } from 'collections/collectionsSelectors';
import {
  addComment,
  CommentsActionType,
  GetCommentsAction,
  getCommentsError,
  getCommentsSuccess,
  SaveCommentAction,
  saveCommentError,
  saveCommentSuccess,
} from 'comments/commentsActions';
import { updateCommentCount } from 'comments/commentsHelpers';
import { CommentsService } from 'comments/CommentsService';
import { Listing } from 'listings/listingsTypes';
import { RootState } from 'rootReducer';
import { not } from 'shared/helpers/boolean';

const { FETCH_COMMENTS, SAVE_COMMENT } = CommentsActionType;

export function* getCommentsRequestSaga(action: GetCommentsAction): Saga {
  try {
    const result = yield CommentsService.getComments(
      action.id,
      action.listingid,
      action.page
    );

    yield put(getCommentsSuccess(result));
    action.cb?.();
  } catch (e) {
    yield put(getCommentsError(e.message));
  }
}

export function* saveCommentRequestSaga(action: SaveCommentAction): Saga {
  try {
    const commentsWasReset = yield fork(function* () {
      yield take(CommentsActionType.RESET_COMMENTS);
      return true;
    });
    const listings: Listing[] = yield select(
      (state: RootState) => state.listings.items
    );
    const collectionId: string = yield select(getCollectionId);
    const result = yield CommentsService.saveComment({
      collectionId: action.id,
      listingId: action.listingid,
      comment: action.comment,
      owner: action.owner,
      notNotifyUser: action.notNotifyUser,
    });
    yield put(saveCommentSuccess({ listingid: action.listingid }));

    if (not(action.withoutUpdate) && not(commentsWasReset.result())) {
      yield put(addComment(result));
    }

    if (action.listingid && collectionId && action.id === collectionId) {
      // increment listing's item commentCount on Collection page
      yield put(
        updateCollectionItems(
          updateCommentCount('listingid', action.listingid, listings)
        )
      );
    }
    action.cb?.();
  } catch (e) {
    yield put(saveCommentError(e.message));
  }
}

const getCommentsSub = () => takeLatest(FETCH_COMMENTS, getCommentsRequestSaga);

const saveCommentSub = () => takeLatest(SAVE_COMMENT, saveCommentRequestSaga);

export function* commentsSagas() {
  yield all([getCommentsSub(), saveCommentSub()]);
}
