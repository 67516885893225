import { toast } from 'react-toastify';

import Toast from 'shared/components/Toaster/Toast';

export interface ToastOptions {
  onViewClick?: () => unknown;
  onUndoClick?: () => unknown;
  onEditClick?: () => unknown;
  onClose?: () => unknown;
  toastId?: string | number;
  hideProgressBar?: boolean;
  toastDomId?: string;
}

const showToast = (
  type: 'success' | 'error',
  message: string,
  {
    onViewClick,
    onUndoClick,
    onEditClick,
    onClose,
    toastId,
    hideProgressBar,
    toastDomId,
  }: ToastOptions = {}
) =>
  toast[type](
    ({ closeToast }: { closeToast: () => void }) => (
      <Toast
        type={type}
        closeToast={closeToast}
        onViewClick={onViewClick}
        onUndoClick={onUndoClick}
        onEditClick={onEditClick}
        toastDomId={toastDomId}
        message={message}
      />
    ),
    { onClose, toastId, hideProgressBar, role: 'none' }
  );

export const showSuccessToast = (message: string, options?: ToastOptions) =>
  showToast('success', message, options);

export const showErrorToast = (message: string, options?: ToastOptions) =>
  showToast('error', message, options);
