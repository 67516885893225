import styled, { css } from 'styled-components/macro';

export const DropdownOption = styled.div<{ highlight?: boolean }>`
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: flex;
  min-width: 10em;
  padding: 1em 1em;
  font-size: 16px;
  max-width: 100%;

  svg {
    margin-right: 1em;
    vertical-align: bottom;
    fill: ${({ theme }) => theme.text};
  }

  ${({ highlight }) =>
    highlight &&
    css`
      background-color: ${({ theme }) => theme.secondary};
      color: ${({ theme }) => theme.white};
      svg {
        fill: ${({ theme }) => theme.white};
      }
    `}

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.white};

    svg {
      fill: ${({ theme }) => theme.white};
    }
  }

  &.is-selected {
    background-color: #f2f9fc;
    color: #333;
  }
`;

export const DropdownOptionText = styled.span`
  margin-left: 32px;
`;
