import { RoutePath } from 'shared/constants/routesConstants';

export enum ContactSource {
  ra = 'ra',
  sa = 'sa',
}

export const contactNavRoutes = [
  { name: 'Basic Info', path: RoutePath.AGENT_CONTACT_BASIC_INFO },
  { name: 'Saved Searches', path: RoutePath.AGENT_SAVED_SEARCHES },
  { name: 'Collections', path: RoutePath.AGENT_COLLECTIONS },
  { name: 'Viewed Searches', path: RoutePath.AGENT_VIEWED_SEARCHES },
  { name: 'Viewed Listings', path: RoutePath.AGENT_VIEWED_LISTINGS },
  { name: 'Viewed Pages', path: RoutePath.AGENT_CONTACT_VIEWED_PAGES },
  { name: 'Inquiry History', path: RoutePath.AGENT_INQUIRY_HISTORY },
  { name: 'Email History', path: RoutePath.AGENT_EMAIL_HISTORY },
  { name: 'Email Settings', path: RoutePath.AGENT_EMAIL_SETTINGS },
];
