import { not, notEmpty } from 'shared/helpers/boolean';
import { UserProfile } from 'user/userTypes';

export function parsePhotoUrl(profile: UserProfile): UserProfile {
  if (not(profile.headshotUrlTransparent)) {
    return profile;
  }

  const imgTagStart = '<img';

  const profileImage = profile.headshotUrlSquare;

  const newProfile: UserProfile = {
    ...profile,
  };

  if (profileImage) {
    const isRawImgElement = profileImage.split(' ')[0] === imgTagStart;

    if (isRawImgElement) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(profileImage, 'text/html');
      const imgs = htmlDoc.getElementsByTagName('img');

      if (notEmpty(imgs)) {
        newProfile.headshotUrlSquare = imgs[0].src;
      }
    }
  }

  return newProfile;
}
