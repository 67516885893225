export enum CollectionsActionType {
  FETCH_COLLECTIONS = 'FETCH_COLLECTIONS',
  RESET_COLLECTIONS = 'RESET_COLLECTIONS',
  RESET_COLLECTION = 'RESET_COLLECTION',
  FETCH_COLLECTIONS_FAIL = 'FETCH_COLLECTIONS_FAIL',
  SET_COLLECTIONS = 'SET_COLLECTIONS',
  FILTER_COLLECTIONS = 'FILTER_COLLECTIONS',
  START_DECLINE_COLLECTION = 'START_DECLINE_COLLECTION',
  DECLINE_COLLECTION_REQUEST_SUCCESS = 'DECLINE_COLLECTION_REQUEST_SUCCESS',
  DECLINE_COLLECTION_REQUEST_FAIL = 'DECLINE_COLLECTION_REQUEST_FAIL',
  DECLINE_COLLECTION_REQUEST = 'DECLINE_COLLECTION_REQUEST',
  START_REMOVE_COLLECTION = 'START_REMOVE_COLLECTION',
  REMOVE_COLLECTION_REQUEST = 'REMOVE_COLLECTION_REQUEST',
  REMOVE_COLLECTION_SUCCESS = 'REMOVE_COLLECTION_REQUEST_SUCCESS',
  REMOVE_COLLECTION_FAIL = 'REMOVE_COLLECTION_REQUEST_FAIL',
  REMOVE_COLLECTION_FROM_STORE = 'REMOVE_COLLECTION_FROM_STORE',
  UNDO_REMOVE_COLLECTION = 'UNDO_REMOVE_COLLECTION',
  UPDATE_COLLECTION = 'UPDATE_COLLECTION',
  UPDATE_COLLECTION_ITEM = 'UPDATE_COLLECTION_ITEM',
  SAVE_COLLECTION = 'SAVE_COLLECTION',
  SAVE_COLLECTION_FAIL = 'SAVE_COLLECTION_FAIL',
  SAVE_LISTING_TO_COLLECTION = 'SAVE_LISTING_TO_COLLECTION',
  SAVE_LISTING_TO_COLLECTION_SUCCESS = 'SAVE_LISTING_TO_COLLECTION_SUCCESS',
  SET_LOADED_COLLECTION = 'SET_LOADED_COLLECTION',
  SET_LOADED_COLLECTION_FAIL = 'SET_LOADED_COLLECTION_FAIL',
  REMOVE_COLLECTION_ITEM = 'REMOVE_COLLECTION_ITEM',
  REMOVE_COLLECTION_ITEM_REQUEST = 'REMOVE_COLLECTION_ITEM_REQUEST',
  REMOVE_COLLECTION_ITEM_REQUEST_FAIL = 'REMOVE_COLLECTION_ITEM_REQUEST_FAIL',
  UPDATE_COLLECTION_REQUEST = 'UPDATE_COLLECTION_REQUEST',
  SAVE_COLLECTION_SUCCESS = 'SAVE_COLLECTION_SUCCESS',
  SHARE_COLLECTION_REQUEST = 'SHARE_COLLECTION_REQUEST',
  UNSHARE_COLLECTION_REQUEST = 'UNSHARE_COLLECTION_REQUEST',
  JOIN_COLLECTION_REQUEST = 'JOIN_COLLECTION_REQUEST',
  JOIN_COLLECTION_FAIL = 'JOIN_COLLECTION_FAIL',
  JOIN_COLLECTION = 'JOIN_COLLECTION',
  REMOVE_FROM_DISMISSED_REQUEST = 'REMOVE_FROM_DISMISSED_REQUEST',
  FETCH_COLLECTION = 'FETCH_COLLECTION',
  UPDATE_COLLECTION_ITEMS = 'UPDATE_COLLECTION_ITEMS',
  FETCH_COLLECTIONS_INVITATIONS = 'FETCH_COLLECTIONS_INVITATIONS',
  UPDATE_COLLECTIONS_INVITATIONS = 'UPDATE_COLLECTIONS_INVITATIONS',
  ACCEPT_COLLECTION_INVITATION = 'ACCEPT_COLLECTION_INVITATION',
  DECLINE_COLLECTION_INVITATION = 'DECLINE_COLLECTION_INVITATION',
  SEND_COLLECTION_INVITATION_REMINDER = 'SEND_COLLECTION_INVITATION_REMINDER',
  RECOMMEND_LISTINGS = 'RECOMMEND_LISTINGS',
}
