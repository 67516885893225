import { getEnv } from 'env-config';
import { DeploymentTarget } from 'shared/config/configConstants';

const env = getEnv();

export const apiKey = getEnv().bugsnagApiKey;

const nodeEnvProd = process.env.NODE_ENV === 'production';

export const releaseStage = nodeEnvProd
  ? env.deploymentTarget
  : DeploymentTarget.Dev;

const isProd = env.deploymentTarget === DeploymentTarget.Production;
const isStage = env.deploymentTarget === DeploymentTarget.Stage;
const isTesting = env.deploymentTarget === DeploymentTarget.Test;

/*
  We only want to be auto-notified about unhandled errors
  on testing, staging, production (both SA and RA)
  */
export const shouldAutoNotify = nodeEnvProd && (isProd || isStage || isTesting);
