import { select, take } from 'redux-saga/effects';

import { MapActionType } from 'map/mapActions';
import { TypesOfMap } from 'map/mapConstants';
import { getMapBoundaries } from 'map/mapSelectors';
import { Boundary } from 'map/mapTypes';
import { isEmpty } from 'shared/helpers/boolean';

export function* waitForInitMapBoundaries(mapType: TypesOfMap): Saga<Boundary> {
  const mapBoundaries = yield select(getMapBoundaries, mapType);

  if (isEmpty(mapBoundaries)) {
    yield take(MapActionType.CHANGE_MAP_POSITION);
  }
  return yield select(getMapBoundaries, mapType);
}
