import { FiltersState } from 'filters/filtersTypes';
import { ListingsState } from 'listings/listingsTypes';
import { Boundary, MapInnerState } from 'map/mapTypes';
import { Tag } from 'search/search-types';
import { ModalTypeProps } from 'shared/components/Modal';
import { SortFilterValue } from 'shared/components/SortFilter/SortFilter';
import { SortOrder } from 'shared/constants/appConstants';
import {
  Invitation,
  LoadingState,
  Paginated,
  SettingsDataBase,
} from 'shared/types';
import { PlaceType, Polygon } from 'shared/types/placesAndPolygons';
import { Collaborator, UserProfile } from 'user/userTypes';

export enum NotificationFrequencyOptionValue {
  Immediately = 'immediately',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Never = 'never',
}

export interface NotificationFrequencyOption {
  label: keyof typeof NotificationFrequencyOptionValue;
  value: NotificationFrequencyOptionValue;
}

export enum NotificationFrequency {
  NotificationsDisabled = 'notificationsDisabled',
}

export interface SavedSearchBody {
  id: string;
  name: string;
  filters: Partial<FiltersState>;
  tags: Tag[];
  query: string;
  version: string;
  owner?: string;
  notifications?: Notification[];
  notifyAgent?: boolean;
}

export interface Notification {
  userId: string;
  isOwner: boolean;
  frequency: NotificationFrequencyOptionValue;
  statusChanges: boolean;
  priceChanges: boolean;
  openHouse: boolean;
  sentAt: string;
  openHouseSentAt: string;
  createdAt: string;
  savedSearchId: string;
  newListings: boolean;
}

export interface SavedSearchBase {
  id: string;
  error: string;
  name: string;
  notifications: Notification;
  filters: FiltersState;
  tags: Tag[];
  agentNotified: boolean;
  updateTimeValue: string;
  description: string;
  createdAt?: string;
  updatedAt?: string;
  collaboratorCount?: number;
  collaborators: Collaborator[];
  query: string;
  owner?: string | null;
  version?: string;
}

export interface RawSavedSearch extends SavedSearchBase {
  owner: string;
  user: UserProfile;
}

export interface SavedSearch extends SavedSearchBase {
  user: UserProfile;
}

export type SearchData = Array<
  | {
      key:
        | keyof FiltersState
        | keyof ListingsState
        | keyof MapInnerState
        | keyof Boundary
        | PlaceType.Address
        | PlaceType.Street
        | 'lease'
        | 'source';
      value: string;
    }
  | { key: 'polygon'; value: Polygon }
>;

export interface ViewedSearch extends LoadingState {
  collaborators: Collaborator[];
  createdAt: string;
  description?: string;
  id: string;
  query: string;
  searchData: SearchData;
  updatedAt: string;
  username: string;
  viewedCount: number;
  searchCount: number;
  owner?: string;
}

export interface ViewedSearches extends LoadingState {
  items: Paginated<ViewedSearch>;
}

export type UnionSearch = SavedSearch | RawSavedSearch;

export interface SavedSearchFilters {
  period: number;
  page: number;
  sortType: SortOrder;
  sortBy: SortFilterValue;
}

export type SearchItem = Omit<UnionSearch, 'id' | 'notifications' | 'user'> & {
  loading: boolean;
  id: null | string;
  notifications: null | Notification;
  user?: UserProfile;
};

export interface SavedSearchState extends SavedSearchFilters {
  id?: string;
  loading: boolean;
  total: number;
  error: string;
  item: SearchItem;
  searches: SavedSearch[];
  savedSearchToRemove: string[];
  savedSearchInvitations: Invitation[];

  viewedSearches: ViewedSearches;
  limit: number;
  user: string;
}

export interface SaveSearchSettingsData extends SettingsDataBase {
  notifications?: Partial<Notification>;
}

export interface SavedSearchForUpdate {
  search: SavedSearchBase | SavedSearch | ViewedSearch;
  settings: SaveSearchSettingsData;
}

export interface CreateUpdateSaveSearchBody {
  data: SavedSearchForUpdate;
  onSuccess?: () => void;
  cb?: () => void;
}

export interface onSaveSearchParams {
  data: {
    search: SavedSearch | ViewedSearch;
    settings: {
      name: string;
      notifyAgent?: boolean;
      collaboratorEmails: string[];
      collaboratorsToRemove: string[];
    };
  };
  cb: () => void;
  onSuccess: () => void;
}

export interface SavedSearchSettingsModalProps extends ModalTypeProps {
  onSave: (params: onSaveSearchParams) => void;
  name?: string;
  id?: string;
  viewedSearch?: ViewedSearch;
  isUpdate?: boolean;
  label?: string;
  shouldDisableCollaboratorInvite: boolean;
  closeModal: () => void;
}
