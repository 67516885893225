import React, { FocusEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fetchSavedSearches } from 'saved-search/savedSearchActions';
import { getExistingSearches } from 'saved-search/savedSearchSelectors';
import { getRecentSearches } from 'search/search-selectors';
import { fetchRecentSearches } from 'search/search-slice';
import { TYPE } from 'shared/components/Dropdown/Dropdown';
import { SearchBarNearbyMenu } from 'shared/components/SearchBar/components/SearchBarNearbyMenu';
import SearchBarOption from 'shared/components/SearchBar/components/SearchBarOption';
import SearchBarOptionGroup from 'shared/components/SearchBar/components/SearchBarOptionGroup/SearchBarOptionGroup';
import { SearchBarRecentSearches } from 'shared/components/SearchBar/components/SearchBarRecentSearches';
import SearchBarSavedSearches from 'shared/components/SearchBar/components/SearchBarSavedSearches';
import SearchBarStyled from 'shared/components/SearchBar/SearchBarStyled';
import {
  LOADING_DROPDOWN_MESSAGE,
  NO_RESULTS_DROPDOWN_MESSAGE,
} from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { isEmpty, not, notEmpty } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useIsMobile } from 'styled-system/responsive';
import { isAuthorised } from 'user/userSelectors';
import { UserLocation } from 'user/userTypes';

const DROPDOWN_BOTTOM_OFFSET = 2;

interface SearchBarDropdownMenuProps {
  className?: string;
  closeMenu?: () => void;
  dropdownMenuHeight?: number;
  dropdownShifted?: boolean;
  isOpen?: boolean;
  items?: any[];
  loading?: boolean;
  noResultText?: string;
  onItemClick?: (option: any, value: string) => void;
  optionIcon?: React.ReactNode;
  searchValue?: string;
  shouldStretchDropdown?: boolean;
  showSearchButton?: boolean;
  userLocation?: UserLocation;
  dropdownMenuRef: (node: HTMLDivElement) => void;
  dropdownMenuNode: HTMLDivElement;
}

const SearchBarDropdownMenu = (props: SearchBarDropdownMenuProps) => {
  const {
    className = '',
    closeMenu,
    dropdownMenuHeight,
    dropdownShifted,
    isOpen,
    items = [],
    loading,
    noResultText = NO_RESULTS_DROPDOWN_MESSAGE,
    onItemClick,
    optionIcon,
    searchValue,
    shouldStretchDropdown,
    showSearchButton,
    userLocation,
    dropdownMenuNode,
    dropdownMenuRef,
  } = props;

  const isMobile = useIsMobile();
  const [realMenuHeight, setRealMenuHeight] = useState(dropdownMenuHeight);

  const dispatch = useDispatch();

  const history = useHistory();

  const recentSearches = useSelector(getRecentSearches);
  const isUserAuthorised = useSelector(isAuthorised);
  const savedSearches = useSelector(getExistingSearches);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchRecentSearches());

      if (isUserAuthorised) {
        dispatch(fetchSavedSearches());
      }
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (
      dropdownMenuNode !== null &&
      shouldStretchDropdown &&
      not(dropdownMenuHeight) &&
      isOpen
    ) {
      setRealMenuHeight(
        window.innerHeight -
          window.pageYOffset -
          dropdownMenuNode.getBoundingClientRect().top -
          DROPDOWN_BOTTOM_OFFSET
      );
    }
  }, [dropdownMenuNode, isOpen]);

  const options = items
    .map((option, index) => {
      if (option.type === TYPE.GROUP) {
        if (isEmpty(option.items)) {
          return null;
        }

        const options = option.items.map((item: any, itemIndex: number) => (
          <SearchBarOption
            key={itemIndex}
            onItemClick={onItemClick}
            option={item}
            searchValue={searchValue}
            closeMenu={closeMenu}
          />
        ));

        return (
          <SearchBarOptionGroup
            key={index}
            options={options}
            option={option}
            scrollableNode={dropdownMenuNode}
          />
        );
      }
      return (
        <SearchBarOption
          key={index}
          onItemClick={onItemClick}
          option={option}
          optionIcon={optionIcon}
          searchValue={searchValue}
          closeMenu={closeMenu}
        />
      );
    })
    .filter(Boolean);

  const notLoading = not(loading);
  const showNearbyMenu = Boolean(userLocation) && not(searchValue);
  const showList = notEmpty(items) || not(showNearbyMenu) ? searchValue : true;
  const showNoResults = notLoading && Boolean(searchValue);
  const showResentAndSavedSearches = not(
    history.location.pathname === RoutePath.AGENTS
  );
  const showRecentSearches =
    showResentAndSavedSearches &&
    not(isMobile) &&
    notLoading &&
    not(searchValue) &&
    notEmpty(recentSearches);
  const showSavedSearches =
    showResentAndSavedSearches &&
    not(isMobile) &&
    notEmpty(savedSearches) &&
    not(searchValue) &&
    notLoading;

  const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
    if (not(dropdownMenuNode.contains(e.relatedTarget as Node))) {
      closeMenu();
    }
  };

  return (
    <>
      {isOpen && (
        <SearchBarStyled.DropdownMenu
          className={`${className} dropdown-menu`}
          showSearchButton={showSearchButton}
          maxHeight={realMenuHeight}
          shifted={dropdownShifted}
          role="listbox"
          ref={dropdownMenuRef}
          onBlur={handleBlur}
        >
          {notLoading && showNearbyMenu && (
            <SearchBarNearbyMenu
              userLocation={userLocation}
              closeMenu={closeMenu}
            />
          )}

          {showRecentSearches && (
            <SearchBarRecentSearches
              closeMenu={closeMenu}
              recentSearches={recentSearches}
            />
          )}

          {showSavedSearches && (
            <SearchBarSavedSearches
              savedSearches={savedSearches}
              scrollableNode={dropdownMenuNode}
              closeMenu={closeMenu}
            />
          )}

          {notLoading && showList && options}

          {loading && (
            <SearchBarStyled.OptionGroup>
              {LOADING_DROPDOWN_MESSAGE}
            </SearchBarStyled.OptionGroup>
          )}

          {isEmpty(items) && showNoResults && (
            <SearchBarStyled.OptionGroup tabIndex={0}>
              {noResultText}
            </SearchBarStyled.OptionGroup>
          )}
        </SearchBarStyled.DropdownMenu>
      )}
    </>
  );
};

export default SearchBarDropdownMenu;
