import { SAVED_SEARCH_TAG_TYPE } from 'listings/listingsConstants';
import { Tag } from 'search/search-types';
import { notEmpty } from 'shared/helpers/boolean';

export const SHOW_MORE_CLASS_NAME = 'show-more';
export const SEARCH_BAR_TAG_CLASS_NAME = 'search-bar-tag';
export const SEARCH_INPUT_CLASS_NAME = 'search-input';

export interface GetDividerIsShownOptions {
  tags: Tag[];
  tagItem: Tag;
  index: number;
}

export const getDividerIsShown = (
  options: GetDividerIsShownOptions
): boolean => {
  const { tags, tagItem, index } = options;
  const searchTagIsOpen =
    notEmpty(tags) && tags[0].type === SAVED_SEARCH_TAG_TYPE && tags[0].open;

  if (searchTagIsOpen) {
    const savedSearchTags = tags[0].tags;
    const tagsLength = tags.length - 1; // - saved search tag

    return (
      index === savedSearchTags.length && savedSearchTags.length < tagsLength
    );
  }

  return tagItem.type === SAVED_SEARCH_TAG_TYPE && tags.length > 1;
};
