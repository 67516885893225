import { connect } from 'react-redux';

import { getAgentMode, getContactItem } from 'contacts/contactsSelectors';
import { RootState } from 'rootReducer';
import ContextMenu from 'shared/components/Header/components/ContextMenu/ContextMenu';
import { ConnectProps } from 'shared/types';
import {
  isAuthorised,
  selectCollectionsInvitationsCount,
  selectNotificationHistoryCount,
  selectSavedSearchInvitationsCount,
} from 'user/userSelectors';

const mapStateToProps = (state: RootState) => ({
  isAuthorised: isAuthorised(state),
  user: state.user,
  contactItem: getContactItem(state),
  agentMode: getAgentMode(state),
  savedSearchInvitationsCount: selectSavedSearchInvitationsCount(state),
  collectionsInvitationsCount: selectCollectionsInvitationsCount(state),
  notificationHistoryCount: selectNotificationHistoryCount(state),
});

export type ContextMenuConnectProps = ConnectProps<typeof mapStateToProps>;

export default connect(mapStateToProps)(ContextMenu);
