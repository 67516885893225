import React from 'react';

import { PaymentCalculatorInputProps } from 'listingDetails/components/PaymentCalculator/components/PaymentCalculatorInput';
import { CalculatorInputCallbackValue } from 'listingDetails/components/PaymentCalculator/components/PaymentCalculatorInputTypes';
import PaymentCalculatorTooltip from 'listingDetails/components/PaymentCalculator/components/PaymentCalculatorTooltip';
import PaymentCalculatorStyles from 'listingDetails/components/PaymentCalculator/PaymentCalculatorStyled';
import { CALCULATOR_MAX_CURRENCY_VALUE } from 'listingDetails/listingDetailsConstants';
import {
  PaymentCalculatorData,
  PaymentCalculatorKey,
  PaymentCalculatorState,
} from 'listingDetails/listingDetailsTypes';
import { MONTH_IN_YEAR } from 'shared/constants/appConstants';
import {
  formatPercent,
  percentDiffBetweenNumber,
  percentOfNumber,
  separateThousandsWithCommas,
} from 'shared/helpers/formatters';

export type KeyMapType = {
  [key in PaymentCalculatorKey]?: Partial<PaymentCalculatorState>;
};

const { RowLabel } = PaymentCalculatorStyles;

export type ComputeCalculatorData = Pick<
  Partial<PaymentCalculatorState>,
  | PaymentCalculatorKey.listprice
  | PaymentCalculatorKey.currencyHomeownersInsurance
  | PaymentCalculatorKey.homeownersInsurance
  | PaymentCalculatorKey.currencyDownPayment
  | PaymentCalculatorKey.downPayment
  | PaymentCalculatorKey.currencyPropertyTaxes
  | PaymentCalculatorKey.taxRate
  | PaymentCalculatorKey.taxAnnualAmount
>;

export const getCurrencyPerYear = (homePrice = 0, currencyPercent = 0) =>
  Math.round(percentOfNumber(homePrice, currencyPercent));

export const getCurrencyPerMonth = (homePrice = 0, currencyPercent = 0) =>
  Math.round(getCurrencyPerYear(homePrice, currencyPercent) / MONTH_IN_YEAR);

export const getPercentPerMonth = (homePrice = 0, currency = 0) =>
  percentDiffBetweenNumber(homePrice, currency);

const getPropertyTaxes = (taxAnnualAmount = 0) =>
  Number(Math.round(taxAnnualAmount / MONTH_IN_YEAR).toFixed(2));

export const computeCalculatorValues = (
  changedKey: PaymentCalculatorKey,
  calculatorData: ComputeCalculatorData
): ComputeCalculatorData => {
  const {
    listprice,
    currencyHomeownersInsurance,
    homeownersInsurance,
    currencyDownPayment,
    downPayment,
    currencyPropertyTaxes,
    taxRate,
    taxAnnualAmount,
  } = calculatorData;

  const keyMap: KeyMapType = {
    init: {
      currencyHomeownersInsurance: getCurrencyPerMonth(
        listprice,
        homeownersInsurance
      ),
      currencyPropertyTaxes: getPropertyTaxes(taxAnnualAmount),
      currencyDownPayment: getCurrencyPerYear(listprice, downPayment),
    },
    listprice: {
      currencyHomeownersInsurance: getCurrencyPerMonth(
        listprice,
        homeownersInsurance
      ),
      currencyPropertyTaxes: getPropertyTaxes(taxAnnualAmount),
      currencyDownPayment: getCurrencyPerYear(listprice, downPayment),
    },
    homeownersInsurance: {
      currencyHomeownersInsurance: getCurrencyPerMonth(
        listprice,
        homeownersInsurance
      ),
    },
    taxRate: {
      currencyPropertyTaxes: getCurrencyPerMonth(listprice, taxRate),
    },
    downPayment: {
      currencyDownPayment: getCurrencyPerYear(listprice, downPayment),
    },
    currencyHomeownersInsurance: {
      homeownersInsurance:
        getPercentPerMonth(listprice, currencyHomeownersInsurance) *
        MONTH_IN_YEAR,
    },
    currencyPropertyTaxes: {
      taxRate:
        getPercentPerMonth(listprice, currencyPropertyTaxes) * MONTH_IN_YEAR,
    },
    currencyDownPayment: {
      downPayment: getPercentPerMonth(listprice, currencyDownPayment),
    },
  };

  return {
    ...calculatorData,
    ...keyMap[changedKey],
  };
};

export type InputConfigData = Pick<
  PaymentCalculatorData,
  | PaymentCalculatorKey.listprice
  | PaymentCalculatorKey.taxRate
  | PaymentCalculatorKey.currencyPropertyTaxes
  | PaymentCalculatorKey.downPayment
  | PaymentCalculatorKey.currencyDownPayment
  | PaymentCalculatorKey.hoaDues
  | PaymentCalculatorKey.interestRate
  | 'initial'
>;

export const getInputConfig = (
  handleChange: (data: CalculatorInputCallbackValue) => void,
  calculatorData: InputConfigData,
  isMobile: boolean
): PaymentCalculatorInputProps[][] => {
  const {
    listprice,
    taxRate,
    currencyPropertyTaxes,
    downPayment,
    currencyDownPayment,
    hoaDues,
    interestRate,
    initial,
  } = calculatorData;

  return [
    [
      // first column
      {
        className: PaymentCalculatorKey.listprice,
        label: 'Home Price',
        onValueChange: handleChange,
        currencyArea: {
          name: PaymentCalculatorKey.listprice,
          value: listprice,
          limit: CALCULATOR_MAX_CURRENCY_VALUE,
        },
      },
      {
        className: PaymentCalculatorKey.currencyPropertyTaxes,
        label: (
          <RowLabel>
            Property Taxes
            <PaymentCalculatorTooltip
              isMobile={isMobile}
              overlayText={`The property tax rate for this property is ${formatPercent(
                initial.taxRate
              )}, according to the MLS. Your taxes will vary each year based on assessed value.`}
            />
          </RowLabel>
        ),
        onValueChange: handleChange,
        currencyArea: {
          name: PaymentCalculatorKey.currencyPropertyTaxes,
          value: currencyPropertyTaxes,
          limit: listprice,
        },
        period: '/ month',
      },
      {
        className: PaymentCalculatorKey.hoaDues,
        label: (
          <RowLabel>
            HOA Dues
            <PaymentCalculatorTooltip
              isMobile={isMobile}
              overlayText={`The Homeowners Association Dues for this property are ${separateThousandsWithCommas(
                initial.hoaDues
              )}, according to the MLS.`}
            />
          </RowLabel>
        ),
        onValueChange: handleChange,
        currencyArea: {
          name: PaymentCalculatorKey.hoaDues,
          value: hoaDues,
          limit: listprice,
        },
        period: '/ month',
      },
    ],
    [
      // second column
      {
        className: PaymentCalculatorKey.currencyDownPayment,
        label: 'Down Payment',
        onValueChange: handleChange,
        currencyArea: {
          name: PaymentCalculatorKey.currencyDownPayment,
          value: currencyDownPayment,
          limit: listprice,
        },
        percentArea: {
          name: PaymentCalculatorKey.downPayment,
          value: downPayment,
        },
      },
      {
        className: PaymentCalculatorKey.interestRate,
        label: (
          <RowLabel>
            Interest Rate
            <PaymentCalculatorTooltip
              isMobile={isMobile}
              overlayText={`The average interest rate for a 30 year fixed loan is ${formatPercent(
                initial.interestRate
              )}.`}
            />
          </RowLabel>
        ),
        onValueChange: handleChange,
        percentArea: {
          name: PaymentCalculatorKey.interestRate,
          value: interestRate,
        },
      },
    ],
  ];
};
