import { combineReducers } from 'redux';
import { DeepReadonly } from 'utility-types';

import { AGENTS_DEFAULT_STATE, agentsReducer } from 'agents/agentsReducer';
import { AgentsState } from 'agents/agentsTypes';
import {
  SELL_DEFAULT_STATE,
  sellReducer,
} from 'butter-cms/pages/sell/sellReducer';
import { SellState } from 'butter-cms/pages/sell/sellTypes';
import {
  COLLECTIONS_DEFAULT_STATE,
  collectionsReducer,
} from 'collections/collectionsReducer';
import { CollectionsState } from 'collections/collectionsTypes';
import {
  COMMENTS_DEFAULT_STATE,
  commentsReducer,
} from 'comments/commentsReducer';
import { CommentsState } from 'comments/commentsTypes';
import {
  CONTACTS_DEFAULT_STATE,
  contactsReducer,
} from 'contacts/contactsReducer';
import { ContactsState } from 'contacts/contactsTypes';
import { FiltersActionType } from 'filters/filtersActions';
import {
  FILTERS_DEFAULT_STATE,
  filtersReducer,
  getDefaultFilters,
} from 'filters/filtersReducer';
import { FiltersState } from 'filters/filtersTypes';
import {
  EMAIL_HISTORY_DEFAULT_STATE,
  emailHistoryReducer,
} from 'history/emailHistory/emailHistorySlice';
import { EmailHistoryState } from 'history/emailHistory/emailHistoryTypes';
import {
  INQUIRY_HISTORY_DEFAULT_STATE,
  inquiryHistoryReducer,
} from 'history/inquiryHistory/inquiryHistorySlice';
import { InquiryHistoryState } from 'history/inquiryHistory/inquiryHistoryTypes';
import {
  NOTIFICATION_HISTORY_DEFAULT_STATE,
  notificationHistoryReducer,
} from 'history/notificationHistory/notificationHistorySlice';
import { NotificationHistoryState } from 'history/notificationHistory/notificationHistoryTypes';
import {
  LISTING_DETAILS_DEFAULT_STATE,
  listingDetailsReducer,
} from 'listingDetails/listingDetailsReducer';
import { ListingDetailsState } from 'listingDetails/listingDetailsTypes';
import {
  LISTINGS_DEFAULT_STATE,
  listingsReducer,
} from 'listings/listingsReducer';
import { ListingsState } from 'listings/listingsTypes';
import { MAP_DEFAULT_STATE, mapReducer } from 'map/mapReducer';
import { MapState } from 'map/mapTypes';
import {
  MY_LISTINGS_DEFAULT_STATE,
  myListingsReducer,
} from 'myListings/myListingsSlice';
import { MyListingsState } from 'myListings/myListingsTypes';
import {
  PERMISSION_DEFAULT_STATE,
  permissionsReducer,
} from 'permissions/permissionsReducer';
import { PermissionsState } from 'permissions/permissionsTypes';
import { PortalAction, RESET_ALL, RESET_FILTERS } from 'rootActions';
import {
  SAVED_SEARCH_DEFAULT_STATE,
  savedSearchReducer,
} from 'saved-search/savedSearchReducer';
import { SavedSearchState } from 'saved-search/savedSearchTypes';
import { searchInitialState, searchReducer } from 'search/search-slice';
import { SearchState } from 'search/search-types';
import { USER_DEFAULT_STATE, userReducer } from 'user/userReducer';
import { UserState } from 'user/userTypes';

const portalApp = combineReducers<DeepReadonly<RootState>>({
  agents: agentsReducer,
  collections: collectionsReducer,
  comments: commentsReducer,
  contacts: contactsReducer,
  filters: filtersReducer,
  listingDetails: listingDetailsReducer,
  listings: listingsReducer,
  map: mapReducer,
  permissions: permissionsReducer,
  savedSearch: savedSearchReducer,
  search: searchReducer,
  sell: sellReducer,
  history: combineReducers<DeepReadonly<HistoryState>>({
    inquiryHistory: inquiryHistoryReducer,
    emailHistory: emailHistoryReducer,
    notificationHistory: notificationHistoryReducer,
  }),
  user: userReducer,
  myListings: myListingsReducer,
});

// TODO: Maybe we should move code in root reducer to listings reducer?
export function rootReducer(
  state: DeepReadonly<RootState>,
  action: PortalAction
): DeepReadonly<RootState> {
  if (action.type === RESET_ALL || action.type === RESET_FILTERS) {
    return {
      ...DEFAULT_STATE,
      collections: {
        ...COLLECTIONS_DEFAULT_STATE,
        collectionsToRemove: state.collections.collectionsToRemove,
      },
      savedSearch: {
        ...SAVED_SEARCH_DEFAULT_STATE,
        savedSearchToRemove: state.savedSearch.savedSearchToRemove,
      },
      contacts: state.contacts,
      user: state.user,
      map: {
        ...state.map,
        ...(action.type === RESET_ALL
          ? {
              [action.mapType]: {
                ...state.map[action.mapType],
                clusters: [],
                polygons: [],
              },
            }
          : MAP_DEFAULT_STATE),
      },
      permissions: state.permissions,
      filters: {
        ...(getDefaultFilters({
          isAgent: action.isAgent,
          agentMode: action.agentMode,
        }) as FiltersState),
      },
    };
  }

  if (action.type in FiltersActionType) {
    return portalApp(
      { ...state, listings: { ...state.listings, page: 1 } },
      action
    );
  }

  return portalApp(state, action);
}

interface HistoryState {
  inquiryHistory: InquiryHistoryState;
  emailHistory: EmailHistoryState;
  notificationHistory: NotificationHistoryState;
}

export interface RootState {
  agents: AgentsState;
  collections: CollectionsState;
  comments: CommentsState;
  contacts: ContactsState;
  filters: FiltersState;
  listingDetails: ListingDetailsState;
  listings: ListingsState;
  map: MapState;
  permissions: PermissionsState;
  savedSearch: SavedSearchState;
  search: SearchState;
  sell: SellState;
  history: HistoryState;
  user: UserState;
  myListings: MyListingsState;
}

// TODO: Remove this, add default case to switches in reducers
export const DEFAULT_STATE: RootState = {
  agents: AGENTS_DEFAULT_STATE,
  collections: COLLECTIONS_DEFAULT_STATE,
  comments: COMMENTS_DEFAULT_STATE,
  contacts: CONTACTS_DEFAULT_STATE,
  filters: FILTERS_DEFAULT_STATE,
  listingDetails: LISTING_DETAILS_DEFAULT_STATE,
  listings: LISTINGS_DEFAULT_STATE,
  map: MAP_DEFAULT_STATE,
  permissions: PERMISSION_DEFAULT_STATE,
  savedSearch: SAVED_SEARCH_DEFAULT_STATE,
  search: searchInitialState,
  sell: SELL_DEFAULT_STATE,
  history: {
    inquiryHistory: INQUIRY_HISTORY_DEFAULT_STATE,
    emailHistory: EMAIL_HISTORY_DEFAULT_STATE,
    notificationHistory: NOTIFICATION_HISTORY_DEFAULT_STATE,
  },
  user: USER_DEFAULT_STATE,
  myListings: MY_LISTINGS_DEFAULT_STATE,
};
