import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { find, isPlainObject, omit, sortBy } from 'lodash';
import queryString from 'query-string';
import { generatePath } from 'react-router-dom';
import { select } from 'redux-saga/effects';

import { getIsAdminAgent } from 'agents/agentsSelectors';
import { MlsStatus, OnMarketMlsStatuses } from 'filters/filtersContants';
import {
  FeaturesAndFactsSectionValueType,
  ListingImage,
} from 'listingDetails/listingDetailsTypes';
import {
  LISTINGS_PER_PAGE,
  UNDISCLOSED_ADDRESS,
} from 'listings/listingsConstants';
import { PropertyFilterSubType } from 'listings/listingsTypes';
import {
  ExclusivePropertyType,
  FormValues,
  Image,
} from 'myListings/CreateNewListing/model';
import {
  DATE_TEMPLATE,
  DRAFT_LISTINGS_URL,
  MARKET_LISTINGS_URL,
  MyListingLayouts,
  PropertyTypeAndSubtype,
} from 'myListings/myListingsConstants';
import { getMyListingsType } from 'myListings/myListingsSelectors';
import {
  MY_LISTINGS_DEFAULT_STATE,
  MY_LISTINGS_FILTERS_DEFAULT_STATE,
} from 'myListings/myListingsSlice';
import {
  DraftListingData,
  DraftListingResponse,
  ExclusiveListing,
  ExclusiveListingKeys,
  Media,
  MyListingsFilters,
  MyListingsState,
  UpdateDraftListingData,
} from 'myListings/myListingsTypes';
import { RootState } from 'rootReducer';
import { BreadcrumbType } from 'shared/components/Breadcrumbs/Breadcrumbs';
import { CarouselImageItem } from 'shared/components/ImagesCarousel/ImagesCarousel';
import { RoutePath } from 'shared/constants/routesConstants';
import { not, notEmpty, notEqual, notNil } from 'shared/helpers/boolean';
import {
  covertStringToUTCDate,
  isTodayOrAfterDate,
} from 'shared/helpers/formatDate';
import { priceToNumber } from 'shared/helpers/formatters';
import { getNotEmptyQueryParams } from 'shared/helpers/mainHelpers';
import { keysOf, pickBy } from 'shared/helpers/object';

const prepareBathroomsData = (
  bathroomsFull?: number,
  bathroomsHalf?: number
) => {
  const withFullBathrooms = Boolean(bathroomsFull);
  const withHalfBathrooms = Boolean(bathroomsHalf);

  const bathroomsToShow =
    withFullBathrooms && withHalfBathrooms
      ? `${bathroomsFull} full / ${bathroomsHalf} half`
      : withFullBathrooms
      ? `${bathroomsFull} full`
      : withHalfBathrooms
      ? `${bathroomsHalf} half`
      : '';

  return {
    type: FeaturesAndFactsSectionValueType.bathrooms,
    desc: bathroomsToShow,
    title: 'Bathrooms',
  };
};

export const getMyListingAddress = (listing: ExclusivePropertyType): string => {
  const { streetNumber, streetName, city, stateOrProvince, postalCode, unit } =
    listing;

  const street =
    [streetNumber, streetName].filter(Boolean).join(' ') || UNDISCLOSED_ADDRESS;

  const unitNumber = unit && street !== UNDISCLOSED_ADDRESS ? ` ${unit}` : '';

  const locationCity = city ? `${city}, ` : '';

  const stateAndPostal = [stateOrProvince, postalCode]
    .filter(Boolean)
    .join(' ');

  const comaAfterStreet = locationCity || stateAndPostal ? ', ' : '';

  return `${street}${unitNumber}${comaAfterStreet}${locationCity}${stateAndPostal}`;
};

export const prepareExclusiveListingData = (
  exslusiveListing: FormValues
): ExclusiveListing => {
  const {
    propertyType,
    propertyDetails,
    price,
    listingStatus,
    listingDetails,
    images,
    remarks,
    schools,
    polygons,
  } = exslusiveListing;

  const featuresAndFactsData = [
    prepareBathroomsData(
      propertyDetails.bathroomsfull,
      propertyDetails.bathroomshalf
    ),
    ...Object.entries({
      ...propertyDetails,
      propertyType: propertyType.type,
    }).map(([key, value]) => {
      if (key === ExclusiveListingKeys.bedroomstotal && Boolean(value)) {
        return {
          type: FeaturesAndFactsSectionValueType.bedrooms,
          desc: String(value),
          title: 'Bedrooms',
        };
      }

      if (key === ExclusiveListingKeys.squareFit && Boolean(value)) {
        return {
          type: FeaturesAndFactsSectionValueType.sqft,
          desc: String(value),
          title: 'Square Feet',
        };
      }

      if (key === ExclusiveListingKeys.lotSize && Boolean(value)) {
        return {
          type: FeaturesAndFactsSectionValueType.acres,
          desc: String(value),
          title: 'Acres',
        };
      }

      if (key === ExclusiveListingKeys.propertyType && Boolean(value)) {
        return {
          type: FeaturesAndFactsSectionValueType.propertyType,
          desc: Boolean(value) ? String(value) : '',
          title: 'Property Type',
        };
      }

      if (key === ExclusiveListingKeys.yearbuilt && Boolean(value)) {
        return {
          type: FeaturesAndFactsSectionValueType.yearBuilt,
          desc: String(value),
          title: 'Year built',
        };
      }

      if (key === ExclusiveListingKeys.stories && Boolean(value)) {
        return {
          type: FeaturesAndFactsSectionValueType.stories,
          desc: String(value),
          title: 'Stories',
        };
      }

      if (key === ExclusiveListingKeys.garagespaces && Boolean(value)) {
        return {
          type: FeaturesAndFactsSectionValueType.garages,
          desc: String(value),
          title: 'Garages',
        };
      }

      if (key === ExclusiveListingKeys.fireplaceyn && value) {
        return {
          type: FeaturesAndFactsSectionValueType.heating,
          desc: 'Fireplace',
          title: 'Heating',
        };
      }
      return null;
    }),
  ].filter(notNil);

  const propertyListData = [
    {
      label: 'Office Info',
      values: [
        ...Object.entries(listingDetails).map(([key, value]) => {
          if (key === ExclusiveListingKeys.listingAgent && value) {
            return {
              title: 'List Agent Name',
              desc: String(value),
              type: 'listagentfullname',
            };
          }
          if (key === ExclusiveListingKeys.listingAgentPhone && value) {
            return {
              title: 'List Agent Phone',
              desc: String(value),
              type: 'listagentdirectphone',
            };
          }
          if (key === ExclusiveListingKeys.listingAgentEmail && value) {
            return {
              title: 'List Agent Email',
              desc: String(value),
              type: 'listagentemail',
            };
          }
          return null;
        }),
      ].filter(notNil),
    },
  ];

  const propertyData = [
    {
      label: 'Location',
      values: [
        ...Object.entries(propertyType).map(([key, value]) => {
          if (key === ExclusiveListingKeys.formattedAddress) {
            return {
              desc: Boolean(value) ? value : '',
              title: 'Address',
              type: 'address',
            };
          }
          if (key === ExclusiveListingKeys.city && value) {
            return {
              desc: value,
              title: 'City',
              type: 'city',
            };
          }
          if (key === ExclusiveListingKeys.subdivisionName && value) {
            return {
              desc: value,
              title: 'Subdivision',
              type: 'Subdivision',
            };
          }
          if (key === ExclusiveListingKeys.postalCode && value) {
            return {
              desc: value,
              title: 'ZIP Code',
              type: 'zipCode',
            };
          }
          return null;
        }),
      ].filter(notNil),
    },
    {
      label: 'Improvements',
      values: [
        ...Object.entries(propertyDetails).map(([key, value]) => {
          if (
            key === ExclusiveListingKeys.hasFencing &&
            notEmpty(propertyDetails.fencing)
          ) {
            return {
              desc: propertyDetails.fencing.join(', '),
              title: 'Fence',
              type: 'fence',
            };
          }

          if (key === ExclusiveListingKeys.poolprivateyn && Boolean(value)) {
            return {
              desc: 'Yes',
              title: 'Pool',
              type: 'pool',
            };
          }

          if (
            key === ExclusiveListingKeys.hasExteriorFeatures &&
            notEmpty(propertyDetails.ExteriorFeatures)
          ) {
            return {
              desc: propertyDetails.ExteriorFeatures.join(', '),
              title: 'Exterior Features',
              type: 'exteriorFeatures',
            };
          }
          if (key === ExclusiveListingKeys.garagespaces && value) {
            return {
              desc: value,
              title: 'Garage Spaces',
              type: 'GarageSpaces',
            };
          }
          return null;
        }),
      ].filter(notNil),
    },
    {
      label: 'Features',
      values: [
        ...Object.entries(propertyDetails).map(([key, value]) => {
          if (key === ExclusiveListingKeys.hasView && value) {
            return {
              desc: propertyDetails.view?.join(', '),
              title: 'View',
              type: 'view',
            };
          }
          return null;
        }),
      ].filter(notNil),
    },
    {
      label: 'Size and Restrictions',
      values: [
        ...Object.entries(propertyDetails).map(([key, value]) => {
          if (key === ExclusiveListingKeys.HorseYN && value) {
            return {
              desc: 'Yes',
              title: 'Horses Allowed',
              type: 'horsesAllowed',
            };
          }
          if (
            key === ExclusiveListingKeys.lotFeatures &&
            notEmpty(propertyDetails.lotFeatures)
          ) {
            return {
              desc: propertyDetails.lotFeatures.join(', '),
              title: 'Lot Description',
              type: 'LotDescription',
            };
          }
          if (key === ExclusiveListingKeys.lotSize && value) {
            return {
              desc: value,
              title: 'Acres',
              type: 'Acres',
            };
          }
          return null;
        }),
      ].filter(notNil),
    },
  ].filter(section => notEmpty(section.values));

  const interiorFeaturesData = [
    {
      label: 'Beds and baths',
      values: [
        ...Object.entries(propertyDetails).map(([key, value]) => {
          if (key === ExclusiveListingKeys.bedroomstotal && Boolean(value)) {
            return {
              desc: String(value),
              title: 'Bedrooms',
              type: 'bedrooms',
            };
          }

          if (key === ExclusiveListingKeys.bathroomsfull && Boolean(value)) {
            return {
              desc: String(value),
              title: 'Bathrooms',
              type: 'bathrooms',
            };
          }

          if (key === ExclusiveListingKeys.ABOR_MasterMain) {
            return {
              desc: value ? 'Yes' : 'No',
              title: 'Primary on Main',
              type: 'masterOnMain',
            };
          }

          if (key === ExclusiveListingKeys.ABOR_GuestAccommodation && value) {
            return {
              desc: 'Yes',
              title: 'Guest Accommodations',
              type: 'ABOR_GuestAccommodation',
            };
          }
          return null;
        }),
      ].filter(notNil),
    },
    {
      label: 'Other Rooms',
      values: [
        ...Object.entries(propertyDetails).map(([key, value]) => {
          if (
            key === ExclusiveListingKeys.hasRooms &&
            notEmpty(propertyDetails.ABOR_Rooms)
          ) {
            return {
              desc: propertyDetails.ABOR_Rooms.join(', '),
              title: 'Other Rooms',
              type: 'otherRooms',
            };
          }
          return null;
        }),
      ].filter(notNil),
    },
  ].filter(section => notEmpty(section.values));

  const buildingInfoData = [
    {
      label: 'Building Information',
      values: [
        ...Object.entries(propertyDetails).map(([key, value]) => {
          if (key === ExclusiveListingKeys.squareFit && Boolean(value)) {
            return {
              desc: String(value),
              type: 'squareFeet',
              title: 'Square Feet',
            };
          }
          if (key === ExclusiveListingKeys.yearbuilt && value) {
            return {
              desc: String(value),
              type: 'yearBuilt',
              title: 'Year Built',
            };
          }
          if (key === ExclusiveListingKeys.stories && value) {
            return {
              desc: value,
              type: 'Stories',
              title: '# of Stories',
            };
          }
          return null;
        }),
      ].filter(notNil),
    },
  ];

  const mapData = {
    address: getMyListingAddress(propertyType),
    longitude: String(propertyType.position?.lng),
    latitude: String(propertyType.position?.lat),
  };

  const imagesToShow = images
    ? images.map(({ mediaUrl, mediaObjectId, diffImgSizes, ...rest }) => {
        return {
          ...rest,
          mediaobjectid: mediaObjectId,
          mediaurl: mediaUrl,
          diffimgsizes: diffImgSizes,
        };
      })
    : [];

  return {
    mapData,
    buildingInfoData,
    interiorFeaturesData,
    propertyData,
    propertyListData,
    featuresAndFactsData,
    images: imagesToShow,
    remarks: remarks as ExclusiveListing['remarks'],
    listingDetails: {
      price,
      address: getMyListingAddress(propertyType),
      listingAgent: listingDetails.listingAgent,
      listingAgentPhone: listingDetails.listagentdirectphone,
      listingStatus: listingStatus.status,
      bathroomTotalInteger:
        (propertyDetails.bathroomsfull || 0) +
        (propertyDetails.bathroomshalf || 0),
      propertyType: listingDetails.propertyType,
      numberOfUnitsTotal: propertyType.units,
      virtualTourUrlUnbranded:
        exslusiveListing.propertyDetails.virtualtoururlunbranded,
      expirationDate: listingDetails.expirationDate,
      mlstargetDate: listingDetails.mlsTargetDate,
      publicDate: listingDetails.publicDate,
      closePrice: listingStatus.closePrice,
    },
    schools,
    polygons,
  };
};

export const handleExclusiveTypeQuery = (
  exclusiveListingType: MyListingLayouts
) =>
  [MyListingLayouts.offMarket, MyListingLayouts.onMarket].includes(
    exclusiveListingType
  )
    ? {
        [exclusiveListingType]: true,
      }
    : {};

export const mapMyListingToFormData = (
  draftListingData: DraftListingResponse
): FormValues => {
  const { listing, polygons } = draftListingData;

  const fields = {
    images: sortBy(listing.media, 'sortOrder'),
    listingDetails: {
      listingAgent: listing.listagentfullname,
      expirationDate:
        covertStringToUTCDate(listing.expirationdate) || undefined,
      mlsTargetDate: covertStringToUTCDate(listing.mlsTargetDate) || undefined,
      publicDate: covertStringToUTCDate(listing.publicDate) || undefined,
      propertyType: listing.propertytype,
      listagentdirectphone: listing.listagentdirectphone || '',
      listagentemail: listing.listagentemail,
      listingAgentId: listing.agentId,
    },
    listingStatus: {
      status: listing.mlsstatus,
      source: listing.relatedMlsSource,
      primaryListingId: listing.primarylistingid,
      closeDate: covertStringToUTCDate(listing.closedate) || undefined,
      closePrice: listing.closeprice || '',
      showSoldInBio: listing.showOnAgentBIO,
    },
    price: listing.listprice || '',
    propertyDetails: {
      ABOR_GuestAccommodation: listing.abor_guestaccomodation,
      ABOR_MasterMain: listing.abor_mastermain,
      ABOR_Rooms: listing.abor_rooms,
      AssociationYN: listing.hasAssociation,
      CommunityFeatures: listing.communityfeatures,
      ExteriorFeatures: listing.exteriorfeatures,
      HorseYN: listing.horseyn,
      bathroomsfull: listing.bathroomsfull,
      bathroomshalf: listing.bathroomshalf,
      bedroomstotal: listing.bedroomstotal,
      fencing: listing.fencing,
      fireplaceyn: listing.hasFireplace,
      garagespaces: listing.garagespaces,
      hasCommunityFeatures: notEmpty(listing.communityfeatures),
      hasExteriorFeatures: notEmpty(listing.exteriorfeatures),
      hasFencing: notEmpty(listing.fencing),
      hasLotFeatures: notEmpty(listing.lotfeatures),
      hasRooms: notEmpty(listing.abor_rooms),
      hasView: notEmpty(listing.View),
      hasWaterfront: notEmpty(listing.waterbodyname),
      lotFeatures: listing.lotfeatures,
      poolprivateyn: listing.poolprivateyn,
      stories: listing.stories,
      view: listing.View,
      waterfrontyn: listing.waterbodyname,
      yearbuilt: listing.yearbuilt,
      lotSize: listing.lotsizearea,
      squareFit: listing.livingarea,
      virtualtoururlunbranded: listing.virtualtoururlunbranded,
    },
    propertyType: {
      formattedAddress: listing.address,
      city: listing.city,
      subdivisionName: listing.subdivisionname,
      postalCode: listing.postalcode,
      stateOrProvince: listing.stateorprovince,
      position: {
        lat: Number(listing.latitude),
        lng: Number(listing.longitude),
      },
      type:
        listing.propertyfiltertype ||
        PropertyFilterSubType.SingleFamilyResidence,
      unit: listing.unitnumber,
      streetNumber: listing.streetnumber,
      streetName: listing.streetname,
      units: listing.numberofunitstotal,
    },
    remarks: {
      private: listing.privateremarks,
      public: listing.publicremarks,
    },
    schools: polygons.schoolLevels,
    polygons: polygons.polygonsMainGroups,
  };

  return removeEmptyFields(fields);
};

const removeEmptyFields = (formValues: FormValues): FormValues => {
  const keys = keysOf(formValues);
  const res = { ...formValues };

  for (const key of keys) {
    if (key === 'listingDetails') {
      res[key] = pickBy<
        FormValues['listingDetails'],
        'listingAgent' | 'propertyType' | 'listingAgentId'
      >(formValues[key], Boolean);
      continue;
    }

    if (key === 'propertyType') {
      res[key] = pickBy<FormValues['propertyType'], 'type'>(
        formValues[key],
        Boolean
      );
      continue;
    }

    if (key === 'listingStatus') {
      res[key] = pickBy<FormValues['listingStatus'], 'status'>(
        formValues[key],
        Boolean
      );
      continue;
    }

    if (key === 'remarks' || key === 'propertyDetails') {
      res[key] = pickBy(formValues[key], Boolean);
    }
  }

  return res;
};

export const getMyListingTypeByMlsStatus = (mlsStatus: MlsStatus) => {
  return OnMarketMlsStatuses.includes(mlsStatus)
    ? MyListingLayouts.onMarket
    : MyListingLayouts.offMarket;
};

export const getDraftListingDataFromFormValues = (
  values: FormValues
): DraftListingData => {
  const {
    listingDetails,
    propertyDetails,
    propertyType,
    listingStatus,
    images,
    price,
    remarks,
  } = values;

  const formatDate = (date: Date) => dayjs(date).format('YYYY-MM-DD');

  const data: DraftListingData = {
    hasAssociation: propertyDetails.AssociationYN,
    bathroomsFull: propertyDetails.bathroomsfull,
    bathroomsHalf: propertyDetails.bathroomshalf,
    bedroomsTotal: propertyDetails.bedroomstotal,
    city: propertyType.city,
    latitude: propertyType.position?.lat,
    longitude: propertyType.position?.lng,
    streetName: propertyType.streetName,
    streetNumber: propertyType.streetNumber,
    unitNumber: propertyType.unit,
    postalCode: propertyType.postalCode,
    stateOrProvince: propertyType.stateOrProvince,
    media: images.map((image, index) => ({ ...image, sortOrder: index })),
    agentId: listingDetails.listingAgentId,
    expirationDate:
      listingDetails.expirationDate &&
      formatDate(listingDetails.expirationDate),
    mlsTargetDate:
      listingDetails.mlsTargetDate && formatDate(listingDetails.mlsTargetDate),
    publicDate:
      listingDetails.publicDate && formatDate(listingDetails.publicDate),
    listPrice: priceToNumber(price),
    publicRemarks: remarks.public,
    privateRemarks: remarks.private,
    mlsStatus: listingStatus.status,
    relatedMlsSource:
      listingStatus.status === MlsStatus.ListedOnMls
        ? listingStatus.source
        : undefined,
    primaryListingId:
      listingStatus.status === MlsStatus.ListedOnMls
        ? listingStatus.primaryListingId
        : undefined,
    showOnAgentBIO:
      listingStatus.showSoldInBio !== undefined
        ? listingStatus.showSoldInBio
        : true,
    closeDate: listingStatus.closeDate && formatDate(listingStatus.closeDate),
    closePrice: listingStatus.closePrice,
    hasFireplace: propertyDetails.fireplaceyn,
    fencing: propertyDetails.fencing,
    exteriorFeatures: propertyDetails.ExteriorFeatures,
    communityFeatures: propertyDetails.CommunityFeatures,
    garageSpaces: propertyDetails.garagespaces,
    isHorseAllowed: propertyDetails.HorseYN,
    rooms: propertyDetails.ABOR_Rooms,
    stories: propertyDetails.stories,
    yearBuilt: propertyDetails.yearbuilt,
    view: propertyDetails.view,
    virtualTourUrlUnbranded: propertyDetails.virtualtoururlunbranded,
    masterMain: propertyDetails.ABOR_MasterMain,
    isPoolPrivate: propertyDetails.poolprivateyn,
    guestAccommodation: propertyDetails.ABOR_GuestAccommodation,
    lotFeatures: propertyDetails.lotFeatures,
    lotSizeArea: propertyDetails.lotSize && Number(propertyDetails.lotSize),
    buildingAreaTotal:
      propertyDetails.squareFit && Number(propertyDetails.squareFit),
    livingArea: propertyDetails.squareFit && Number(propertyDetails.squareFit),
    waterBodyName: propertyDetails.waterfrontyn,
    waterfrontyn: propertyDetails.waterfrontyn?.length > 0,
    numberOfUnitsTotal: propertyType.units,
    ...PropertyTypeAndSubtype[propertyType.type],
    //TODO: Chech these fields. They are in the DraftListingData interface but they are not in FormValues interface
    // subdivisionName
    // countyOrParish
  };

  return pickBy<DraftListingData, 'mlsStatus'>(data, value =>
    Boolean(value || ['number', 'boolean'].includes(typeof value))
  );
};

export const getUpdateDraftListingData = ({
  initialFormValues,
  currentFormValues,
}: {
  initialFormValues: FormValues;
  currentFormValues: FormValues;
}): UpdateDraftListingData => {
  const initialData = getDraftListingDataFromFormValues(initialFormValues);
  const currentData = getDraftListingDataFromFormValues(currentFormValues);

  const keys = keysOf(initialData);
  for (const key of keys) {
    if (
      initialData[key] &&
      not(currentData[key]) &&
      not(
        typeof currentData[key] === 'boolean' ||
          typeof currentData[key] === 'number'
      )
    ) {
      Object.assign(currentData, { [key]: null });
    }
  }

  return currentData;
};

export const getImagesToDelete = ({
  initialImages,
  currentImages,
}: {
  initialImages: Image[];
  currentImages: Image[];
}): string[] => {
  const imagesToRemove = initialImages.filter(image =>
    not(find(currentImages, { mediaObjectId: image.mediaObjectId }))
  );

  return imagesToRemove.map(image => image.mediaObjectId).filter(notNil);
};

export const mapImages = (images: ListingImage[]): CarouselImageItem[] => {
  const sortedImages = sortBy(images, 'sortorder');

  return sortedImages.map(({ mediaurl, mediaobjectid, diffimgsizes }) => ({
    mediaurl,
    mediakey: mediaobjectid,
    diffimgsizes,
  }));
};

export const convertKeysToLowerCase = (
  value: Media[] | Media
): ListingImage[] => {
  return Object.fromEntries(Object.entries(value).map(objectKeysMapper));
};

const objectKeysMapper = ([key, val]: [string, unknown]) => {
  const value = (() => {
    if (isPlainObject(val)) return convertKeysToLowerCase(val as Media);

    if (Array.isArray(val)) {
      return val.map(item => {
        return isPlainObject(item) ? convertKeysToLowerCase(item) : item;
      });
    }

    return val;
  })();
  return [key.toLowerCase(), value];
};

export interface DuplicateListing {
  streetNumber: string;
  streetName: string;
  unitNumber: string;
  city: string;
  stateOrProvince: string;
  postalCode: string;
  listingId: string;
}

export const getDuplicateListingUrl = (listing: DuplicateListing) => {
  const fields: Array<keyof DuplicateListing> = [
    'streetNumber',
    'streetName',
    'unitNumber',
    'city',
    'stateOrProvince',
    'postalCode',
    'listingId',
  ];

  if (not(listing)) {
    return '';
  }

  const url = fields.reduce((acc, field: keyof DuplicateListing) => {
    if (listing[field]) {
      const separator = acc ? '-' : '';
      const value = listing[field]
        .toString()
        .toLowerCase()
        .replace(/\s/g, '-')
        .replace('/', '-');
      return acc.concat(separator, value);
    }
    return acc;
  }, '');

  return `${generatePath(RoutePath.LISTING_DETAILS, { address: url })}.html`;
};

export function* constructUrl() {
  const searchType: MyListingLayouts = yield select(getMyListingsType);
  const isDraftListing = searchType === MyListingLayouts.draft;
  const isAdminAgent: boolean = yield select(getIsAdminAgent);

  const path = not(isDraftListing) ? MARKET_LISTINGS_URL : DRAFT_LISTINGS_URL;

  const url = new URL(`${window.location.origin}/api${path}`);
  const { page, isMyListingsFirst, sortBy, sortType }: MyListingsState =
    yield select(({ myListings }) => myListings);
  const filters: MyListingsFilters = yield select(
    state => state.myListings.filters
  );
  const filtersToSend = (
    Object.keys(filters) as Array<keyof MyListingsFilters>
  ).filter(key =>
    notEqual(filters[key], MY_LISTINGS_FILTERS_DEFAULT_STATE[key])
  );

  url.searchParams.append('page', String(page));
  url.searchParams.append('sortBy', sortBy);
  url.searchParams.append('sortType', sortType);
  url.searchParams.append('limit', String(LISTINGS_PER_PAGE));

  if (not(searchType === MyListingLayouts.draft)) {
    url.searchParams.append(searchType, 'true');
  }

  if (isAdminAgent) {
    url.searchParams.append('myListingsFirst', String(isMyListingsFirst));
  }

  filtersToSend.forEach(key => {
    url.searchParams.append(key, String(filters[key]));
  });

  return url;
}

export const getEditMyListingBreadcrumbs = ({
  isAdminAgent,
  agentName,
  isEditDraft,
}: {
  isAdminAgent: boolean;
  agentName: string;
  isEditDraft?: boolean;
}) => {
  const agentInfo = agentName ? agentName.split(' ') : [];

  const [firstname] = agentInfo;

  const breadcrumbs: BreadcrumbType[] = [
    {
      value: 'My Listings',
      url: RoutePath.AGENT_MY_LISTINGS,
    },
    {
      value: `Edit ${
        isAdminAgent ? `${firstname}'s` : isEditDraft ? 'Draft' : 'My'
      } Listing`,
    },
  ];

  return breadcrumbs;
};

export const getEditMyListingTitle = ({
  isAdminAgent,
  agentName,
  isEditDraft,
}: {
  isAdminAgent: boolean;
  agentName: string;
  isEditDraft?: boolean;
}) => {
  const agentInfo = agentName ? agentName.split(' ') : [];

  const [firstname] = agentInfo;
  const title = `Edit ${
    isAdminAgent ? `${firstname}'s` : isEditDraft ? 'Draft' : 'My'
  } Listing`;
  return title;
};

export const parseMyListingQueryUrl = (
  search: string
): Partial<MyListingsState> => {
  const filters: Partial<
    MyListingsState & { agentId?: string; minPrice?: number; maxPrice?: number }
  > = getNotEmptyQueryParams(search, {
    parseBooleans: true,
    parseNumbers: true,
  });

  return {
    ...omit(filters, ['agentId', 'minPrice', 'maxPrice']),
    ...{
      filters: {
        agentId: filters.agentId || '',
        minPrice: filters.minPrice || 0,
        maxPrice: filters.maxPrice || 0,
      },
    },
  };
};

export const isCondoOrTownhousePropertyType = (
  propertyType: PropertyFilterSubType
) =>
  [PropertyFilterSubType.Condominium, PropertyFilterSubType.Townhouse].includes(
    propertyType
  );

export const isLotPropertyType = (propertyType: PropertyFilterSubType) =>
  propertyType === PropertyFilterSubType.Lot;

export const getMyListingsDefaultQueryParams = (
  myListingsType: MyListingLayouts
): string => {
  if (MyListingLayouts.onMarket === myListingsType) {
    return '';
  }

  const queryParams: {
    currentTab: MyListingLayouts;
    sortBy?: string;
  } = {
    currentTab: myListingsType,
  };

  if (MyListingLayouts.draft === myListingsType) {
    queryParams.sortBy = 'updatedAt';
  }

  return queryString.stringify(queryParams);
};

interface DisabledFields {
  isDisabledPublicDate: boolean;
  isDisabledMlsTargetDate: boolean;
}

export const getDisabledFields = ({
  mlsTargetDate,
  publicDate,
  published = false,
}: {
  mlsTargetDate?: Date;
  publicDate?: Date;
  published?: boolean;
}): DisabledFields => {
  const formattedMlsTargetDate =
    mlsTargetDate && dayjs(mlsTargetDate).format(DATE_TEMPLATE);
  const formattedPublicDate =
    publicDate && dayjs(publicDate).format(DATE_TEMPLATE);

  const isDisabledPublicDate =
    published && isTodayOrAfterDate(formattedPublicDate);
  const isDisabledMlsTargetDate =
    published && isTodayOrAfterDate(formattedMlsTargetDate);
  return {
    isDisabledPublicDate,
    isDisabledMlsTargetDate,
  };
};

export function getFiltersSectionChanged(
  filters: Partial<MyListingsState>
): boolean {
  return keysOf(filters).some(key => {
    const value = filters[key];
    const defaultValue = MY_LISTINGS_DEFAULT_STATE[key];

    if (Array.isArray(value) && Array.isArray(defaultValue)) {
      return notEqual([...defaultValue].sort(), [...value].sort());
    }

    return notEqual(defaultValue, value);
  });
}

/**
 * Creates a selector for a set of filters to check if they've changed
 */
export function changedSelector(
  selector: (root: RootState) => Partial<MyListingsState>
): (root: RootState) => boolean {
  return createSelector(selector, getFiltersSectionChanged);
}
