import React, { useEffect, useState } from 'react';
import { Subtract } from 'utility-types';

import { ModalProps } from 'shared/components/Modal/Modal';
import { ModalContext } from 'shared/components/Modal/modalContext';
import { RoutePath } from 'shared/constants/routesConstants';
import history from 'shared/services/history';
import { HistoryAction } from 'shared/types';

interface ModalProviderProps {
  children: React.ReactNode;
}
const ModalProvider = (props: ModalProviderProps) => {
  const [modalState, setModalState] = useState({
    component: null,
    props: {},
    isOpen: false,
  });

  let unlisten: () => void;

  let prevLocationPathname: string;

  useEffect(() => {
    unlisten = history.listen((location, action) => {
      const { pathname } = location;
      if (
        pathname.includes(RoutePath.LISTINGS) &&
        prevLocationPathname !== RoutePath.AGENT_MY_LISTINGS &&
        action === HistoryAction.PUSH
      ) {
        return;
      }
      if (
        pathname.includes(RoutePath.AGENTS) &&
        action === HistoryAction.PUSH
      ) {
        return;
      }
      prevLocationPathname = pathname;
      closeModal();
    });

    return unlisten;
  }, []);

  const showModal =
    <T extends ModalProps>(
      component: React.ComponentType<T>,
      props?: Subtract<T, ModalProps> & Partial<ModalProps>
    ) =>
    (e: any) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      setModalState({ component, props: props || {}, isOpen: true });
    };

  const closeModal = () => {
    setModalState({ component: null, props: {}, isOpen: false });
  };

  return (
    <ModalContext.Provider
      value={{
        ...modalState,
        showModal,
        closeModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
