import styled, { css } from 'styled-components/macro';

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SearchBarItemLabel = styled.span`
  ${ellipsis}
  flex-shrink: 1;
  line-height: normal;
`;

export const SearchBarTimesLabel = styled.span`
  flex-shrink: 1;
  line-height: normal;
  padding-left: 2px;
`;

export const SearchBarItemSecondaryLabel = styled.span`
  ${ellipsis}
  display: block;
  font-size: 14px;
  margin-top: 4px;
  min-width: 20px;
  flex-shrink: 4;
`;

export const SearchBarItemContainer = styled.div`
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: flex;
  align-items: baseline;
  font-size: 16px;
  max-width: 100%;
  min-width: 10em;
  padding: 1em 3em;

  svg {
    height: 14px;
    margin-right: 16px;
    vertical-align: bottom;
    width: 14px;
    min-width: 14px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};

    ${SearchBarItemSecondaryLabel} {
      color: #ffffff;
      opacity: 0.8;
    }

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &.is-selected {
    background-color: #f2f9fc;
    color: #333;

    ${SearchBarItemSecondaryLabel} {
      color: #ffffff;
      opacity: 0.8;
    }
  }
`;
