import { ReactNode, useEffect } from 'react';
import ReactModal from 'react-modal';

import {
  BackButton,
  CloseButton,
  defaultStyles,
  mobileStyles,
  ModalContent,
  ModalCustomHeaderActions,
  ModalHeader,
  ModalHeaderActions,
  ModalHeaderContainer,
  ModalTitle,
  smallMobileStyles,
} from 'shared/components/Modal/ModalStyled';
import { useAgentModeStyles } from 'shared/components/Modal/useAgentModeStyles';
import ScrollToTopModal from 'shared/components/ScrollToTopModal';
import { APP_MODAL_CLASSNAME } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { useLockBodyScroll } from 'shared/hooks/useLockBodyScroll';
import { useTrackedRef } from 'shared/hooks/useTrackedRef';
import { ReactComponent as IconCancel } from 'shared/icons/shared/24x24/cancel.svg';
import { ReactComponent as IconBigArrowLeft } from 'shared/icons/shared/arrows/big-arrow-left.svg';
import { useIsMobile } from 'styled-system/responsive';

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

export interface ModalType {
  isOpen?: boolean;
  label?: string | React.ReactNode;
  listagentmlsid?: string;
  headerActions?: React.ReactNode;
  closeModal(): void;
  showModal?(
    component: React.ComponentType<any>,
    props: Record<string, unknown>
  ): (e?: React.SyntheticEvent) => void;
}

export interface ModalProps extends ModalType {
  children?: ReactNode;
  hasBackBtn?: boolean;
  hasCloseBtn?: boolean;
  hasHeader?: boolean;
  hasHeaderDivider?: boolean;
  fullScreenMobile?: boolean;
  isOpen?: boolean;
  modalContentStyles?: React.CSSProperties;
  modalOverlayStyles?: React.CSSProperties;
  onGoBack?: () => void;
  onRequestClose?: () => boolean;
  styledContentStyles?: React.CSSProperties;
  loading?: boolean;
  customHeaderComponent?: ReactNode;
  closeButtonAriaLabelText?: string;
  contentLabel?: string;
  shouldCloseOnEsc?: boolean;
  onGetModalContentNode?: (node: HTMLDivElement) => void;
}

const Modal = (props: ModalProps) => {
  const isMobile = useIsMobile();
  const agentModeStyles = useAgentModeStyles();
  useLockBodyScroll();

  const {
    children,
    closeModal,
    hasCloseBtn,
    hasHeader,
    hasHeaderDivider,
    isOpen,
    label,
    hasBackBtn,
    onGoBack,
    modalContentStyles,
    modalOverlayStyles,
    onRequestClose,
    styledContentStyles,
    loading,
    customHeaderComponent,
    closeButtonAriaLabelText,
    contentLabel,
    fullScreenMobile,
    shouldCloseOnEsc,
    onGetModalContentNode,
    ...rest
  } = props;

  const onModalClose = () => {
    let canBeClosed = true;

    if (typeof onRequestClose === 'function') {
      canBeClosed = onRequestClose();
    }

    if (canBeClosed) {
      closeModal(); // Call prop function closeModal
    }
  };

  const [contentRef, contendNode] = useTrackedRef<HTMLDivElement | null>(
    onGetModalContentNode
  );

  useEffect(() => {
    if (loading && contendNode) {
      contendNode.scrollTop = 0;
    }
  }, [contendNode, loading]);

  const modalContentResponsiveStyles = isMobile
    ? {
        ...modalContentStyles,
        ...mobileStyles.content,
        ...(not(fullScreenMobile) ? smallMobileStyles.content : {}),
      }
    : modalContentStyles;

  return (
    <ReactModal
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      isOpen={isOpen}
      contentLabel={contentLabel}
      style={{
        overlay: {
          ...defaultStyles.overlay,
          ...agentModeStyles.overlay,
          ...modalOverlayStyles,
        },
        content: {
          ...defaultStyles.content,
          ...agentModeStyles.content,
          ...modalContentResponsiveStyles,
        },
      }}
      onRequestClose={onModalClose}
      shouldCloseOnOverlayClick
      portalClassName={APP_MODAL_CLASSNAME}
      shouldCloseOnEsc={shouldCloseOnEsc}
      parentSelector={
        process.env.NODE_ENV !== 'test'
          ? () => document.querySelector('#modal-root')
          : undefined
      }
      {...rest}
    >
      {hasHeader && (
        <ModalHeader hasHeaderDivider={hasHeaderDivider}>
          <ModalHeaderContainer>
            {Boolean(customHeaderComponent) ? (
              customHeaderComponent
            ) : (
              <>
                {hasBackBtn && onGoBack && (
                  <BackButton
                    blank
                    icon={<IconBigArrowLeft />}
                    role="link"
                    aria-label="Go Back"
                    onClick={onGoBack}
                  />
                )}
                <ModalTitle>{label}</ModalTitle>

                <ModalHeaderActions>
                  <ModalCustomHeaderActions>
                    {props.headerActions}
                  </ModalCustomHeaderActions>

                  {hasCloseBtn && (
                    <CloseButton
                      className="modalCloseButton"
                      blank
                      icon={<IconCancel />}
                      onClick={onModalClose}
                      aria-label={
                        closeButtonAriaLabelText
                          ? closeButtonAriaLabelText
                          : 'Close'
                      }
                    />
                  )}
                </ModalHeaderActions>
              </>
            )}
          </ModalHeaderContainer>
        </ModalHeader>
      )}

      <ModalContent
        hasHeader={hasHeader}
        hasHeaderDivider={hasHeaderDivider}
        className="modal-content"
        style={styledContentStyles}
        ref={contentRef}
      >
        {children}
        <ScrollToTopModal scrollableNode={contendNode} />
      </ModalContent>
    </ReactModal>
  );
};

Modal.defaultProps = {
  hasCloseBtn: true,
  headerActions: null,
  hasHeader: true,
  hasHeaderDivider: true,
  fullScreenMobile: true,
};

export default Modal;
