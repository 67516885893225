import { variant } from '@styled-system/variant';
import styled, { css } from 'styled-components/macro';

import { createShouldForwardProp } from 'styled-system/lib/should-forward-prop';
import { space, SpaceProps } from 'styled-system/lib/space';
import { sx, SxProps } from 'styled-system/lib/sx';

const buttonBaseVariants = variant({
  variants: {
    default: {},
  },
});

const buttonBasePropNames = [...space.propNames, 'sx', 'variant'];

export const buttonBaseConfig = {
  shouldForwardProp: createShouldForwardProp(buttonBasePropNames),
};

export type ButtonBaseProps = SpaceProps & {
  variant?: 'default';
} & SxProps;

export const buttonResetCss = css`
  appearance: none;
  background-color: unset;
  border-radius: 4px;
  border: unset;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  text-align: center;
  text-decoration: none;
`;

export const ButtonBase = styled.button.withConfig<ButtonBaseProps>(
  buttonBaseConfig
)`
  ${buttonResetCss}
  ${buttonBaseVariants}
  ${sx}
  ${space}
`;

ButtonBase.displayName = 'ButtonBase';
