import React from 'react';

import { bugsnag } from 'shared/services/bugsnag/bugsnag';
import { shouldAutoNotify } from 'shared/services/bugsnag/config';

const formatComponentStack = (componentStack: string) => {
  const lines = componentStack.split(/\s*\n\s*/g);
  let ret = '';
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) ret += `${ret.length ? '\n' : ''}${lines[line]}`;
  }
  return ret;
};

export const notifyReactError = (
  error: Error,
  info?: React.ErrorInfo
): void => {
  if (!shouldAutoNotify) {
    return void 0;
  }

  const handledState = {
    severity: 'error',
    unhandled: true,
    severityReason: { type: 'unhandledException' },
  };

  const report = new bugsnag.BugsnagReport(
    error.name,
    error.message,
    bugsnag.BugsnagReport.getStacktrace(error),
    handledState,
    error
  );

  if (info?.componentStack) {
    info.componentStack = formatComponentStack(info.componentStack);
  }

  report.updateMetaData('react', info);

  bugsnag.notify(report);
};
