import {
  daysOnOptions,
  FilterKey,
  filtersLabels,
  timeframeOptions,
} from 'filters/filtersContants';
import {
  getBathroomsTitle,
  getBedroomsTitle,
  getPriceValue,
} from 'filters/filtersHelpers';
import {
  FILTERS_DEFAULT_STATE,
  PROPERTY_TYPE_DEFAULT_STATE,
} from 'filters/filtersReducer';
import { FiltersState, TypeOfViews } from 'filters/filtersTypes';
import { CUSTOM_TAG_LABEL } from 'listings/listingsConstants';
import { SEARCH_DESCRIPTION_PLACEHOLDER } from 'saved-search/savedSearchConstants';
import { SearchData } from 'saved-search/savedSearchTypes';
import { SearchDataParser } from 'saved-search/services/SearchDataParser';
import { Tag } from 'search/search-types';
import { SelectOption } from 'shared/components/Select/SelectTypes';
import { isEmpty, not, notNil } from 'shared/helpers/boolean';
import { formatTwoNumbersRange } from 'shared/helpers/formatters';
import { keysOf } from 'shared/helpers/object';
import { PlaceType } from 'shared/types/placesAndPolygons';

export const typeOfViews: TypeOfViews[] = [
  TypeOfViews.City,
  TypeOfViews.GolfCourse,
  TypeOfViews.Greenbelt,
  TypeOfViews.HillCountry,
  TypeOfViews.LakeOrRiver,
  TypeOfViews.Panoramic,
];

type PartialTag = Pick<Tag, 'type' | 'label'>;

export const countCustomLocation = (
  description: string,
  separator: string | RegExp = ','
): string => {
  const descriptionArray = description
    .split(separator)
    .map(desc => desc.trim());
  const customLocations = descriptionArray.filter(
    desc => desc === CUSTOM_TAG_LABEL
  );
  const locationsWithoutCustomLocations = descriptionArray.filter(
    desc => desc !== CUSTOM_TAG_LABEL
  );

  if (isEmpty(customLocations) || customLocations.length === 1) {
    return description;
  }

  return descriptionArray.length === customLocations.length
    ? `${CUSTOM_TAG_LABEL} (${customLocations.length})`
    : `${locationsWithoutCustomLocations.join(', ')}, ${CUSTOM_TAG_LABEL} (${
        customLocations.length
      })`;
};

export class SearchDescriptionBuilder {
  static get({
    tags,
    filters,
  }: {
    tags: PartialTag[];
    filters: Partial<FiltersState>;
  }): string {
    const tagsTitle = this.getTagsDescription(tags);
    const filtersTitle = this.getFiltersDescription(filters);

    if (tagsTitle && filtersTitle) {
      return `${tagsTitle}, ${filtersTitle}`;
    }

    if (tagsTitle) {
      return tagsTitle;
    }

    if (filtersTitle) {
      return filtersTitle;
    }

    return SEARCH_DESCRIPTION_PLACEHOLDER;
  }

  static getFromSearchData(data: SearchData): string {
    const result = SearchDataParser.parse(data);
    return SearchDescriptionBuilder.get(result);
  }

  private static getTagsDescription(tags: PartialTag[]): string {
    const tagPriority = [
      PlaceType.County,
      PlaceType.City,
      PlaceType.PostalCode,
      PlaceType.Subdivision,
      PlaceType.SchoolDistrict,
      PlaceType.School,
      PlaceType.Street,
      PlaceType.Address,
      PlaceType.MlsArea,
      PlaceType.MlsCode,
      PlaceType.Custom,
    ];

    const tagTitles = tagPriority.map(type =>
      tags.filter(tag => tag.type === type).map(tag => tag.label)
    );

    return countCustomLocation(tagTitles.flat().join(', '));
  }

  public static getFiltersDescription(
    filters: Partial<FiltersState> & { initialized?: boolean },
    isLeaseSearch?: boolean
  ): string {
    const {
      minStories,
      minYearBuilt,
      maxLotSize,
      maxGarageSpaces,
      minGarageSpaces,
      minLotSize,
      minSquareFeet,
      maxSquareFeet,
      maxYearBuilt,
      maxStories,
      minPrice,
      maxPrice,
    } = filters;

    const baseFilters = [
      {
        postfix: '',
        value: getPriceValue({
          min: minPrice,
          max: maxPrice,
          isLeaseSearch: Boolean(isLeaseSearch),
        }),
      },
      {
        postfix: ' bds',
        value: getBedroomsTitle(filters),
      },
      {
        postfix: ' baths',
        value: getBathroomsTitle(filters),
      },
      {
        prefix: 'Size: ',
        postfix: ' sq ft',
        value: formatTwoNumbersRange(minSquareFeet, maxSquareFeet),
      },
      {
        prefix: 'Lot Size: ',
        postfix: ' acres',
        value: formatTwoNumbersRange(minLotSize, maxLotSize),
      },
      {
        prefix: 'Years: ',
        value: formatTwoNumbersRange(minYearBuilt, maxYearBuilt),
      },
      {
        prefix: 'Stories: ',
        value: formatTwoNumbersRange(minStories, maxStories),
      },
      {
        prefix: 'Garage Spaces: ',
        value: formatTwoNumbersRange(minGarageSpaces, maxGarageSpaces),
      },
    ];

    const mainFilters = baseFilters
      .filter(filter => filter.value !== 'Any')
      .map(
        ({ prefix, value, postfix }) =>
          `${prefix || ''}${value}${postfix || ''}`
      );

    const { initialized, ...allFilters } = filters;

    const additionalFilters = keysOf(allFilters)
      .map(key => this.getFilterDescriptionByKey(allFilters, key))
      .filter(notNil);
    // has to be the last in description
    if (allFilters.keyword) {
      additionalFilters.push(`Keywords: ${allFilters.keyword}`);
    }

    return mainFilters.concat(additionalFilters).join(', ');
  }

  private static getFilterDescriptionByKey(
    filters: Partial<FiltersState>,
    key: keyof FiltersState
  ): string {
    if (
      filters[key] === FILTERS_DEFAULT_STATE[key] &&
      not(key in PROPERTY_TYPE_DEFAULT_STATE)
    ) {
      return;
    }

    // Exclude falsy values, except 0
    if (filters[key] !== 0 && !filters[key]) return;

    if (key === FilterKey.MlsStatus) {
      return this.getMlsStatusDescription(filters);
    }

    if (key === FilterKey.HomeownersAssoc) {
      return `Homeowners Assoc: ${filters[key]}`;
    }

    if (key === FilterKey.WaterBodyName && filters.waterfront) {
      return `Waterfront: ${this.addCommaIfNeeded(filters[key])}`;
    }
    if (key === FilterKey.WaterFront && !filters.waterBodyName) {
      return 'Waterfront';
    }

    if (key === FilterKey.TypeOfViews && filters.views) {
      return this.getTypeOfViewsDescription(filters.views);
    }

    if (filtersLabels[key]) {
      return filtersLabels[key];
    }

    const getLabel = (options: SelectOption[]) => {
      const matchedValue = options.find(({ value }) => value === filters[key]);

      return matchedValue ? matchedValue.label : '';
    };

    const filtersWithOptions = {
      soldTimeframe: `Sold Timeframe: ${getLabel(timeframeOptions)}`,
      leasedTimeframe: `Leased Timeframe: ${getLabel(timeframeOptions)}`,
      daysOnMarket: `Listed Date: ${getLabel(daysOnOptions)}`,
      lastPriceReduction: `Last Price Reduction: ${getLabel(daysOnOptions)}`,
    };

    if (filtersWithOptions.hasOwnProperty(key)) {
      return filtersWithOptions[key as keyof typeof filtersWithOptions];
    }
  }

  private static addCommaIfNeeded = (value: unknown | unknown[]) =>
    Array.isArray(value) ? value.join(', ') : value;

  private static getTypeOfViewsDescription(views: string | string[]) {
    if (!Array.isArray(views)) {
      return `Type of Views: ${views}`;
    }
    const hasAllViews = typeOfViews.every(view => views.includes(view));
    if (hasAllViews) {
      return 'Type of Views: All';
    }
    return `Type of Views: ${views.join(', ')}`;
  }

  private static getMlsStatusDescription(
    filters: Partial<FiltersState>
  ): string | undefined {
    const statuses = filters.mlsStatus?.filter(Boolean) || [];

    if (statuses.length === 0) {
      return;
    }

    return statuses
      .map(status => status.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))
      .join(', ');
  }
}
