import { connect } from 'react-redux';

import { clearMapData } from 'map/mapActions';
import { TypesOfMap } from 'map/mapConstants';
import { resetFilters } from 'rootActions';
import { fetchSavedSearch } from 'saved-search/savedSearchActions';
import SearchBarSavedSearches from 'shared/components/SearchBar/components/SearchBarSavedSearches/SearchBarSavedSearches';
import { ConnectProps } from 'shared/types';

const mapType = TypesOfMap.SavedSearches;

const mapDispatchToProps = {
  fetchSavedSearch,
  resetFilters,
  clearMapData: clearMapData.bind(null, mapType),
};

export type SavedSearchSearchBarConnectProps = ConnectProps<
  null,
  typeof mapDispatchToProps
>;

export default connect(null, mapDispatchToProps)(SearchBarSavedSearches);
