/*global google*/

export type Place =
  | CountyPlace
  | CityPlace
  | StreetPlace
  | AddressPlace
  | SchoolPlace
  | SchoolDistrictPlace
  | PostalCodePlace
  | SubdivisionPlace
  | MlsCodePlace
  | MlsAreaPlace
  | CountyPolygonPlace
  | CityPolygonPlace
  | SchoolPolygonPlace
  | SchoolDistrictPolygonPlace
  | SubdivisionPolygonPlace
  | PostalCodePolygonPlace
  | MlsAreaPolygonPlace
  | CustomPolygonPlace;

export enum PlaceType {
  Address = 'address',
  City = 'city',
  County = 'county',
  Custom = 'custom',
  Group = 'group',
  MlsArea = 'mlsarea',
  MlsCode = 'mlscode',
  PostalCode = 'postalcode',
  School = 'school',
  SchoolDistrict = 'schooldistrict',
  Street = 'street',
  Subdivision = 'subdivision',
}

export enum PlacePolygonType {
  County = 'county.polygon',
  City = 'city.polygon',
  School = 'school.polygon',
  SchoolDistrict = 'schooldistrict.polygon',
  Subdivision = 'subdivision.polygon',
  PostalCode = 'postalcode.polygon',
  MlsArea = 'mlsarea.polygon',
  CustomPolygon = 'custom.polygon',
}

interface CountyPlace {
  type: PlaceType.County;
  value: {
    countyorparish: string;
    stateorprovince: string;
  };
}

interface CityPlace {
  type: PlaceType.City;
  value: BasePlace;
}

interface StreetPlace {
  type: PlaceType.Street;
  value: BasePlace & {
    streetname: string;
    streetsuffix: string;
    suggestAddress?: string;
  };
}

interface AddressPlace {
  type: PlaceType.Address;
  value: BasePlace & StreetAddress;
}

interface SchoolPlace {
  type: PlaceType.School;
  value: BasePlace & {
    school: string;
  };
}

interface SchoolDistrictPlace {
  type: PlaceType.SchoolDistrict;
  value: BasePlace & { schooldistrict: string };
}

interface PostalCodePlace {
  type: PlaceType.PostalCode;
  value: BasePlace & { postalcode: string };
}

interface SubdivisionPlace {
  type: PlaceType.Subdivision;
  value: BasePlace & { subdivisionname: string };
}

interface MlsCodePlace {
  type: PlaceType.MlsCode;
  value: BasePlace &
    StreetAddress & {
      listingid: string;
      sourcelistingid: string;
    };
}

interface MlsAreaPlace {
  type: PlaceType.MlsArea;
  value: { mlsareamajor: string };
}

interface CountyPolygonPlace {
  type: PlacePolygonType.County;
  value: PolygonValue<CountyPolygonProperties>;
}

type CountyPolygonProperties = Pick<PolygonProperties, 'state'>;

interface CityPolygonPlace {
  type: PlacePolygonType.City;
  value: PolygonValue<CityPolygonProperties>;
}

type CityPolygonProperties = Pick<PolygonProperties, 'state'>;

interface SchoolPolygonPlace {
  type: PlacePolygonType.School;
  value: PolygonValue<SchoolPolygonProperties>;
}

interface SchoolPolygonProperties {
  address?: Pick<PolygonProperties, 'state' | 'city'>;
  schoolDistrict?: Pick<PolygonProperties, 'name'>;
}

interface SchoolDistrictPolygonPlace {
  type: PlacePolygonType.SchoolDistrict;
  value: PolygonValue<SchoolDistrictPolygonProperties>;
}

type SchoolDistrictPolygonProperties = Pick<
  PolygonProperties,
  'state' | 'city' | 'zip'
>;

interface SubdivisionPolygonPlace {
  type: PlacePolygonType.Subdivision;
  value: PolygonValue<SubdivisionPolygonProperties>;
}

type SubdivisionPolygonProperties = Pick<
  PolygonProperties,
  'state' | 'county' | 'label' | 'city'
>;

interface PostalCodePolygonPlace {
  type: PlacePolygonType.PostalCode;
  value: PolygonValue<PostalCodePolygonProperties>;
}

type PostalCodePolygonProperties = Pick<PolygonProperties, 'state' | 'county'>;

interface MlsAreaPolygonPlace {
  type: PlacePolygonType.MlsArea;
  value: PolygonValue;
}

interface CustomPolygonPlace {
  type: PlacePolygonType.CustomPolygon;
  value: PolygonValue;
}

export interface BasePlace {
  city: string;
  // country: string;
  stateorprovince: string;
}

interface PolygonProperties {
  city: string;
  state: string;
  county: string;
  zip: string;
  name: string;
  label: string;
}

interface PolygonValue<T = unknown> {
  name: string;
  id: string;
  url: string;
  properties: T & {
    label: string;
  };
}

export interface StreetAddress {
  streetnumber: string;
  streetname: string;
  streetsuffix: string;
  streetdirprefix: string;
  streetdirsuffix: string;
  suggestAddress?: string;
}

export type Polygon =
  | ({ id: string; name: string; url: string } & (
      | {
          type: PlaceType.County;
          properties: CountyPolygonProperties;
        }
      | {
          type: PlaceType.City;
          properties: CityPolygonProperties;
        }
      | {
          type: PlaceType.School;
          properties: SchoolPolygonProperties;
        }
      | {
          type: PlaceType.SchoolDistrict;
          properties: SchoolDistrictPolygonProperties;
        }
      | {
          type: PlaceType.Subdivision;
          properties: SubdivisionPolygonProperties;
        }
      | {
          type: PlaceType.PostalCode;
          properties: PostalCodePolygonProperties;
        }
      | {
          type: PlaceType.MlsArea;
          properties: undefined;
        }
    ))
  | {
      id: string;
      type: PlaceType.Custom;
      properties: undefined;
    };

export type GoogleMapApiType = typeof google.maps;
