import { ListingWithDetails } from 'listingDetails/listingDetailsTypes';
import { Tag } from 'search/search-types';
import { not } from 'shared/helpers/boolean';
import { unsafePick } from 'shared/helpers/object';
import { resolvePlace } from 'shared/helpers/resolvePlace';
import { toPolygonTag } from 'shared/helpers/toPolygonTag';
import { PlaceType, Polygon } from 'shared/types/placesAndPolygons';

const BASIC_PLACE_FIELDS: Array<keyof ListingWithDetails> = [
  'city',
  'stateorprovince',
];

// TODO: When "as const" syntax is available, use Array<keyof ListingWithDetails> instead of string[]
export const PLACE_TYPE_FIELDS: Partial<{ [key in PlaceType]: string[] }> = {
  city: BASIC_PLACE_FIELDS,
  street: ['streetname', 'streetsuffix'].concat(BASIC_PLACE_FIELDS),
  address: ['streetname', 'streetsuffix', 'streetnumber'].concat(
    BASIC_PLACE_FIELDS
  ),
  postalcode: ['streetname', 'streetsuffix', 'postalcode'].concat(
    BASIC_PLACE_FIELDS
  ),
  subdivision: ['subdivisionname'].concat(BASIC_PLACE_FIELDS),
  school: ['school'].concat(BASIC_PLACE_FIELDS),
  schooldistrict: ['schooldistrict'].concat(BASIC_PLACE_FIELDS),
  mlscode: ['listingid', 'streetname', 'streetsuffix', 'streetnumber'].concat(
    BASIC_PLACE_FIELDS
  ),
  mlsarea: ['mlsareamajor'].concat(BASIC_PLACE_FIELDS),
  county: ['countyorparish', 'stateorprovince'],
};

export function generatePlace(listing: ListingWithDetails, type: PlaceType) {
  const fields = PLACE_TYPE_FIELDS[type];

  return {
    type,
    value: fields ? unsafePick(listing, fields) : listing,
  };
}

function mlsTagToPolygonTag(listing: ListingWithDetails, tag: Tag) {
  if (not(listing) || not(tag)) return;

  const { polygons } = listing;
  const { type } = tag;

  if (polygons && !!polygons[type]) {
    const polygonTag = { ...tag, id: polygons[type].id };

    polygonTag.type = toPolygonTag(polygonTag.type);

    return polygonTag;
  }

  return tag;
}

export function generatePolygonTag(
  listing: ListingWithDetails,
  tagType: PlaceType
) {
  const tag = generatePlace(listing, tagType);

  return mlsTagToPolygonTag(listing, {
    ...resolvePlace(tag),
    type: tag.type,
    filter: tag.value,
  });
}

export function generateTagFromPolygon(polygon: Polygon) {
  return resolvePlace({ type: toPolygonTag(polygon.type), value: polygon });
}
