import { useFormikContext } from 'formik';
import { useRouteMatch } from 'react-router-dom';

import { FilterKey, MlsStatus } from 'filters/filtersContants';
import { $getSourceFiltersChanged } from 'filters/filtersSelectors';
import {
  Checkboxes,
  CheckboxWrapper,
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  FormSection,
} from 'filters/filtersStyled';
import { SecondaryFiltersForm } from 'filters/filtersTypes';
import { EXCheckboxWrapper } from 'filters/SourceFilter/SourceFilterStyled';
import { CheckboxField } from 'shared/components/formikFields/CheckboxField';
import { RoutePath } from 'shared/constants/routesConstants';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';
import { getIsAgent } from 'user/userSelectors';

export const useSourceFiltersMatches = () => ({
  buyPage: useRouteMatch(RoutePath.LISTINGS),
  userSavedSearch: useRouteMatch(RoutePath.SAVED_SEARCH),
});

interface SourceFiltersProps {
  disableFilter: boolean;
}

export const SourceFilter = (props: SourceFiltersProps) => {
  const isLeasePage = useIsLeasePage();
  const isAgent = useSelector(getIsAgent);
  const isSourceFiltersChanged = useSelector($getSourceFiltersChanged);
  const matches = useSourceFiltersMatches();
  const { setFieldValue, values } = useFormikContext<SecondaryFiltersForm>();
  const { sourceFilters, mlsStatus } = values;

  if (
    isLeasePage ||
    not(isAgent) ||
    not(matches.buyPage || matches.userSavedSearch)
  ) {
    return null;
  }

  const handleExclusiveListingsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newEXListingsValue = e.target.checked;
    const uncheckPrivateStatus =
      sourceFilters.mlsListingsEnabled && not(newEXListingsValue);

    if (uncheckPrivateStatus) {
      const updatedMlsStatuses = mlsStatus.filter(mlsStatus =>
        not(mlsStatus === MlsStatus.Private)
      );
      setFieldValue(FilterKey.MlsStatus, updatedMlsStatuses);
    }
  };

  const handleMlsListingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMlsListingsValue = e.target.checked;
    const uncheckPrivateStatus =
      newMlsListingsValue && not(sourceFilters.exclusiveListingsEnabled);

    if (uncheckPrivateStatus) {
      const updatedMlsStatuses = mlsStatus.filter(mlsStatus =>
        not(mlsStatus === MlsStatus.Private)
      );
      setFieldValue(FilterKey.MlsStatus, updatedMlsStatuses);
    }
  };

  return (
    <FormSection data-testid="sourceFiltersSection">
      <FilterHeaderContainer>
        {isSourceFiltersChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Source
        </FilterHeaderTitle>
      </FilterHeaderContainer>

      <Checkboxes>
        <CheckboxWrapper>
          <EXCheckboxWrapper>
            <CheckboxField
              onChange={handleExclusiveListingsChange}
              name="sourceFilters.exclusiveListingsEnabled"
              labelText={`Exclusive\n listings`}
              disabled={props.disableFilter}
            />
          </EXCheckboxWrapper>
        </CheckboxWrapper>

        <CheckboxWrapper>
          <CheckboxField
            onChange={handleMlsListingsChange}
            name="sourceFilters.mlsListingsEnabled"
            labelText="MLS Listings"
            disabled={props.disableFilter}
          />
        </CheckboxWrapper>
      </Checkboxes>
    </FormSection>
  );
};
