import { FiltersState } from 'filters/filtersTypes';

export enum FiltersActionType {
  SET_FILTER_RANGE_VALUE = 'SET_FILTER_RANGE_VALUE',
  MERGE_FILTERS = 'MERGE_FILTERS',
  MERGE_FILTERS_WITH_DEFAULT = 'MERGE_FILTERS_WITH_DEFAULT',

  RESET_PRICE_FILTERS = 'RESET_PRICE_FILTERS',
  RESET_BEDS_FILTERS = 'RESET_BEDS_FILTERS',
  RESET_BATHS_FILTERS = 'RESET_BATHS_FILTERS',

  RESET_ALL_FILTERS = 'RESET_ALL_FILTERS',
  RESET_SECONDARY_FILTERS = 'RESET_SECONDARY_FILTERS',
  ADJUST_FILTERS_FOR_AGENT = 'ADJUST_FILTERS_FOR_AGENT',
  APPLY_FILTERS = 'APPLY_FILTERS',
}

export interface SetFilterRangeValueAction {
  type: FiltersActionType.SET_FILTER_RANGE_VALUE;
  value: Partial<FiltersState>;
  resetFilterKey?: boolean;
}

export const setFilterRangeValue = (
  value: Partial<FiltersState>,
  resetFilterKey = false
): SetFilterRangeValueAction => ({
  type: FiltersActionType.SET_FILTER_RANGE_VALUE,
  value,
  resetFilterKey,
});

export interface MergeFiltersAction {
  type: FiltersActionType.MERGE_FILTERS;
  filters: Partial<FiltersState>;
}

export const mergeFilters = (
  filters: Partial<FiltersState>
): MergeFiltersAction => ({
  type: FiltersActionType.MERGE_FILTERS,
  filters,
});

export interface MergeFiltersWithDefaultAction {
  type: FiltersActionType.MERGE_FILTERS_WITH_DEFAULT;
  filters: Partial<FiltersState>;
  isAgent?: boolean;
  agentMode?: boolean;
}

export const mergeFiltersWithDefault = ({
  filters,
  isAgent,
  agentMode,
}: {
  filters: Partial<FiltersState>;
  isAgent?: boolean;
  agentMode?: boolean;
}): MergeFiltersWithDefaultAction => ({
  type: FiltersActionType.MERGE_FILTERS_WITH_DEFAULT,
  filters,
  isAgent,
  agentMode,
});

export interface ResetPriceFiltersAction {
  type: FiltersActionType.RESET_PRICE_FILTERS;
}

export const resetPriceFilters = (): ResetPriceFiltersAction => ({
  type: FiltersActionType.RESET_PRICE_FILTERS,
});

export interface ResetBedsFiltersAction {
  type: FiltersActionType.RESET_BEDS_FILTERS;
}

export const resetBedsFilters = (): ResetBedsFiltersAction => ({
  type: FiltersActionType.RESET_BEDS_FILTERS,
});

export interface ResetBathsFiltersAction {
  type: FiltersActionType.RESET_BATHS_FILTERS;
}

export const resetBathsFilters = (): ResetBathsFiltersAction => ({
  type: FiltersActionType.RESET_BATHS_FILTERS,
});

export interface ResetAllFiltersAction {
  type: FiltersActionType.RESET_ALL_FILTERS;
  isAgent: boolean;
  agentMode: boolean;
}

export const resetAllFilters = ({
  isAgent,
  agentMode,
}: {
  isAgent: boolean;
  agentMode: boolean;
}): ResetAllFiltersAction => ({
  type: FiltersActionType.RESET_ALL_FILTERS,
  isAgent,
  agentMode,
});

export interface ResetSecondaryFiltersAction {
  type: FiltersActionType.RESET_SECONDARY_FILTERS;
  agentMode: boolean;
  isAgent: boolean;
}

export const resetSecondaryFilters = ({
  agentMode,
  isAgent,
}: {
  agentMode?: boolean;
  isAgent?: boolean;
}): ResetSecondaryFiltersAction => ({
  type: FiltersActionType.RESET_SECONDARY_FILTERS,
  agentMode,
  isAgent,
});

export interface AdjustFIltersForAgent {
  type: FiltersActionType.ADJUST_FILTERS_FOR_AGENT;
  agentMode: boolean;
  isLeasePage: boolean;
}

export function adjustFiltersForAgent(
  agentMode: boolean,
  isLeasePage: boolean
): AdjustFIltersForAgent {
  return {
    type: FiltersActionType.ADJUST_FILTERS_FOR_AGENT,
    agentMode,
    isLeasePage,
  };
}

export interface ApplyFiltersAction {
  type: FiltersActionType.APPLY_FILTERS;
  value: Partial<FiltersState>;
}

export function applyFilters(
  filters: Partial<FiltersState>
): ApplyFiltersAction {
  return {
    type: FiltersActionType.APPLY_FILTERS,
    value: filters,
  };
}

export type FiltersAction =
  | SetFilterRangeValueAction
  | MergeFiltersAction
  | MergeFiltersWithDefaultAction
  | ResetPriceFiltersAction
  | ResetBedsFiltersAction
  | ResetBathsFiltersAction
  | ResetAllFiltersAction
  | ResetSecondaryFiltersAction
  | AdjustFIltersForAgent
  | ApplyFiltersAction;
