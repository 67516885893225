import { Agent } from 'agents/agentsTypes';
import { AskQuestionBody } from 'listingDetails/listingDetailsTypes';
import { ContactUsBody, RequestSpecialistBody } from 'listings/listingsTypes';
import { FromLinkStatus } from 'permissions/permissionsConstants';
import { CognitoFederatedProviders } from 'shared/types';
import {
  CreateProfileBody,
  NotificationCountsPayload,
  RequestFreeWhitepaperBody,
  RequestRelocationGuideBody,
  UpdateProfileBody,
  UserLocation,
  UserProfile,
  UserProfileFederated,
  UserRole,
} from 'user/userTypes';

export enum UserActionType {
  GET_USER_SESSION = 'GET_USER_SESSION',
  GET_USER_SESSION_ERROR = 'GET_USER_SESSION_ERROR',
  USER_FEDERATED_LOG_IN = 'USER_FEDERATED_LOG_IN_START',
  USER_FEDERATED_LOG_IN_SUCCESS = 'USER_FEDERATED_LOG_IN_SUCCESS',
  USER_FEDERATED_LOG_IN_ERROR = 'USER_FEDERATED_LOG_IN_ERROR',
  START_USER_SIGN_UP = 'START_USER_SIGN_UP',
  RESET_USER_SIGN_UP = 'RESET_USER_SIGN_UP',
  USER_SIGN_UP_ERROR = 'USER_SIGN_UP_ERROR',
  USER_SIGN_UP_USERNAME_EXISTS = 'USER_SIGN_UP_USERNAME_EXISTS',
  USER_SIGN_UP_VERIFICATION_ERROR = 'USER_SIGN_UP_VERIFICATION_ERROR',
  START_USER_LOGIN = 'START_USER_LOGIN',
  RESET_USER_LOGIN = 'RESET_USER_LOGIN',
  USER_IS_LOGGED = 'USER_IS_LOGGED',
  USER_LOGGING_ERROR = 'USER_LOGGING_ERROR',
  SET_FEDERATED_USER_PROFILE = 'SET_FEDERATED_USER_PROFILE',
  START_CREATE_PROFILE = 'START_CREATE_PROFILE',
  START_UPDATE_PROFILE = 'START_UPDATE_PROFILE',
  CREATE_OR_UPDATE_PROFILE_SUCCESS = 'CREATE_OR_UPDATE_PROFILE_SUCCESS',
  CREATE_OR_UPDATE_PROFILE_ERROR = 'CREATE_OR_UPDATE_PROFILE_ERROR',
  FETCH_PROFILE = 'FETCH_PROFILE',
  FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR',
  START_SIGN_OUT = 'START_SIGN_OUT',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
  SIGN_OUT_ERROR = 'SIGN_OUT_ERROR',
  FORGOT_PASSWORD_PREPARE = 'FORGOT_PASSWORD_PREPARE',
  START_FORGOT_PASSWORD = 'START_FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',
  START_RESET_PASSWORD = 'START_RESET_PASSWORD',
  START_SET_PASSWORD = 'START_SET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',
  START_CHANGE_PASSWORD = 'START_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR',
  GET_USER_AGENT = 'GET_USER_AGENT',
  GET_USER_AGENT_SUCCESS = 'GET_USER_AGENT_SUCCESS',
  GET_USER_AGENT_ERROR = 'GET_USER_AGENT_ERROR',
  CHANGE_USER_AGENT = 'CHANGE_USER_AGENT',
  ASK_AGENT_QUESTION = 'ASK_AGENT_QUESTION',
  AGENT_LOGIN = 'AGENT_LOGIN',
  REQUEST_SPECIALIST = 'REQUEST_SPECIALIST',
  GAPI_GET_USER_FAIL = 'GAPI_GET_USER_FAIL',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL',
  SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL',
  SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS',
  SEND_VERIFICATION_EMAIL_FAIL = 'SEND_VERIFICATION_EMAIL_FAIL',
  FEDERATED_CONNECT = 'FEDERATED_CONNECT',
  FEDERATED_CONNECT_FAIL = 'FEDERATED_CONNECT_FAIL',
  FEDERATED_DISCONNECT = 'FEDERATED_DISCONNECT',
  FEDERATED_DISCONNECT_SUCCESS = 'FEDERATED_DISCONNECT_SUCCESS',
  FEDERATED_DISCONNECT_FAIL = 'FEDERATED_DISCONNECT_FAIL',
  REQUEST_RELOCATION_GUIDE = 'REQUEST_RELOCATION_GUIDE',
  REQUEST_RELOCATION_GUIDE_SUCCESS = 'REQUEST_RELOCATION_GUIDE_SUCCESS',
  REQUEST_RELOCATION_GUIDE_FAIL = 'REQUEST_RELOCATION_GUIDE_FAIL',
  SET_RECOMMENDED_AGENT = 'SET_RECOMMENDED_AGENT',
  CLEAR_RECOMMENDED_AGENT = 'CLEAR_RECOMMENDED_AGENT',
  SET_USER_LOCATION = 'SET_USER_LOCATION',
  EMAIL_NOTIFICATIONS_SUBSCRIBE = 'EMAIL_NOTIFICATIONS_SUBSCRIBE',
  EMAIL_NOTIFICATIONS_UNSUBSCRIBE = 'EMAIL_NOTIFICATIONS_UNSUBSCRIBE',
  EMAIL_NOTIFICATIONS_SUBSCRIPTION_SUCCESS = 'EMAIL_NOTIFICATIONS_SUBSCRIPTION_SUCCESS',
  EMAIL_NOTIFICATIONS_SUBSCRIPTION_FAIL = 'EMAIL_NOTIFICATIONS_SUBSCRIPTION_FAIL',
  CONTACT_US = 'CONTACT_US',
  FETCH_DEFAULT_AGENT_REQUEST = 'FETCH_DEFAULT_AGENT_REQUEST',
  FETCH_DEFAULT_AGENT_SUCCESS = 'FETCH_DEFAULT_AGENT_SUCCESS',
  FETCH_DEFAULT_AGENT_FAIL = 'FETCH_DEFAULT_AGENT_FAIL',
  FETCH_NOTIFICATION_COUNTS_SUCCESS = 'FETCH_NOTIFICATION_COUNTS_SUCCESS',
  FETCH_NOTIFICATION_INBOX_COUNT = 'FETCH_NOTIFICATION_INBOX_COUNT',
  FETCH_NOTIFICATION_INBOX_COUNT_SUCESS = 'FETCH_NOTIFICATION_INBOX_COUNT_SUCCESS',
  REQUEST_FREE_WHITEPAPER = 'REQUEST_FREE_WHITEPAPER',
  SET_LINK_DATA = 'SET_LINK_DATA',
  SET_IS_MOBILE = 'SET_IS_MOBILE',
  GET_SOCIAL_PHOTO_URL = 'GET_SOCIAL_PHOTO_URL',
  GET_SOCIAL_PHOTO_URL_SUCCESS = 'GET_SOCIAL_PHOTO_URL_SUCCESS',
  GET_SOCIAL_PHOTO_URL_FAILED = 'GET_SOCIAL_PHOTO_URL_FAILED',
  SET_SOCIAL_PHOTO_URL = 'SET_SOCIAL_PHOTO_URL',
  SET_SOCIAL_PHOTO_URL_SUCCESS = 'SET_SOCIAL_PHOTO_URL_SUCCESS',
  SET_SOCIAL_PHOTO_URL_FAILED = 'SET_SOCIAL_PHOTO_URL_FAILED',
  REMOVE_SOCIAL_PHOTO_URL = 'REMOVE_SOCIAL_PHOTO_URL',
  REMOVE_SOCIAL_PHOTO_URL_SUCCESS = 'REMOVE_SOCIAL_PHOTO_URL_SUCCESS',
  REMOVE_SOCIAL_PHOTO_URL_FAILED = 'REMOVE_SOCIAL_PHOTO_URL_FAILED',
}

export interface GetUserSessionAction {
  type: UserActionType.GET_USER_SESSION;
}

export interface GetUserSessionErrorAction {
  type: UserActionType.GET_USER_SESSION_ERROR;
  error: string;
}

export interface UserFederatedLogInStartAction {
  type: UserActionType.USER_FEDERATED_LOG_IN;
  federatedProvider: CognitoFederatedProviders;
  isAgent?: boolean;
}

export interface UserFederatedLogInSuccessAction {
  type: UserActionType.USER_FEDERATED_LOG_IN_SUCCESS;
}

export interface UserFederatedLogInErrorAction {
  type: UserActionType.USER_FEDERATED_LOG_IN_ERROR;
  error: string;
}

export interface StartUserSignUpAction {
  type: UserActionType.START_USER_SIGN_UP;
  email: string;
}

export interface ResetUserSignUpAction {
  type: UserActionType.RESET_USER_SIGN_UP;
}

export interface UserSignUpErrorAction {
  type: UserActionType.USER_SIGN_UP_ERROR;
  error: string;
}

export interface UserSignUpUsernameExistsAction {
  type: UserActionType.USER_SIGN_UP_USERNAME_EXISTS;
}
export interface UserSignUpEmailVerificationErrorAction {
  type: UserActionType.USER_SIGN_UP_VERIFICATION_ERROR;
}

export interface StartUserLogInAction {
  type: UserActionType.START_USER_LOGIN;
  email: string;
  password: string;
}

export interface ResetUserLogInAction {
  type: UserActionType.RESET_USER_LOGIN;
}

export interface UserIsLoggedAction {
  type: UserActionType.USER_IS_LOGGED;
  status: boolean;
  role: UserRole;
}

export interface UserLoginErrorAction {
  type: UserActionType.USER_LOGGING_ERROR;
  error: string;
}

export interface SetFederatedUserProfileAction {
  type: UserActionType.SET_FEDERATED_USER_PROFILE;
  data: UserProfileFederated;
}
export interface StartCreateProfileAction {
  type: UserActionType.START_CREATE_PROFILE;
  body: CreateProfileBody;
}

export interface StartUpdateProfileAction {
  type: UserActionType.START_UPDATE_PROFILE;
  body: UpdateProfileBody;
  silentUpdate: boolean;
}

export interface CreateOrUpdateProfileSuccessAction {
  type: UserActionType.CREATE_OR_UPDATE_PROFILE_SUCCESS;
  data: UserProfile;
}

export interface CreateOrUpdateProfileErrorAction {
  type: UserActionType.CREATE_OR_UPDATE_PROFILE_ERROR;
  error: string;
}

export interface FetchProfileAction {
  type: UserActionType.FETCH_PROFILE;
}

export interface FetchProfileSuccessAction {
  type: UserActionType.FETCH_PROFILE_SUCCESS;
  profile: UserProfile;
}

export interface FetchProfileErrorAction {
  type: UserActionType.FETCH_PROFILE_ERROR;
  error: string;
}

export interface StartSignOutAction {
  signOutRedirect: boolean;
  type: UserActionType.START_SIGN_OUT;
}

export interface SignOutSuccessAction {
  type: UserActionType.SIGN_OUT_SUCCESS;
}

export interface SignOutErrorAction {
  type: UserActionType.SIGN_OUT_ERROR;
  error: string;
}

export interface ForgotPasswordPrepareAction {
  type: UserActionType.FORGOT_PASSWORD_PREPARE;
}

export interface StartForgotPasswordAction {
  type: UserActionType.START_FORGOT_PASSWORD;
  email: string;
  isResend: boolean;
}

export interface ForgotPasswordSuccessAction {
  type: UserActionType.FORGOT_PASSWORD_SUCCESS;
}

export interface ForgotPasswordErrorAction {
  type: UserActionType.FORGOT_PASSWORD_ERROR;
  error: string;
}

export interface StartResetPasswordAction {
  type: UserActionType.START_RESET_PASSWORD;
  email: string;
  code: string;
  password: string;
}

export interface StartSetPasswordAction {
  type: UserActionType.START_SET_PASSWORD;
  email: string;
  temporaryPassword: string;
  password: string;
}

export interface ResetPasswordSuccessAction {
  type: UserActionType.RESET_PASSWORD_SUCCESS;
}

export interface SetPasswordSuccessAction {
  type: UserActionType.SET_PASSWORD_SUCCESS;
}

export interface SetPasswordErrorAction {
  type: UserActionType.SET_PASSWORD_ERROR;
  error: string;
}

export interface ResetPasswordErrorAction {
  type: UserActionType.RESET_PASSWORD_ERROR;
  error: string;
}

export interface StartChangePasswordAction {
  type: UserActionType.START_CHANGE_PASSWORD;
  email: string;
}

export interface ChangePasswordSuccessAction {
  type: UserActionType.CHANGE_PASSWORD_SUCCESS;
}

export interface ChangePasswordErrorAction {
  type: UserActionType.CHANGE_PASSWORD_ERROR;
  error: string;
}

export interface GetUserAgentAction {
  type: UserActionType.GET_USER_AGENT;
}

export interface GetUserAgentSuccessAction {
  type: UserActionType.GET_USER_AGENT_SUCCESS;
  agent: Agent;
}

export interface ChangeUserAgentAction {
  type: UserActionType.CHANGE_USER_AGENT;
  agentId: string;
}

export interface GetUserAgentErrorAction {
  type: UserActionType.GET_USER_AGENT_ERROR;
  error: string;
}

export interface AskAgentQuestionAction {
  type: UserActionType.ASK_AGENT_QUESTION;
  body: AskQuestionBody;
}

export interface RequestSpecialistAction {
  type: UserActionType.REQUEST_SPECIALIST;
  body: RequestSpecialistBody;
}

export interface AgentLogInAction {
  type: UserActionType.AGENT_LOGIN;
}

export interface GapiGetUserFail {
  type: UserActionType.GAPI_GET_USER_FAIL;
}

export interface VerifyEmail {
  type: UserActionType.VERIFY_EMAIL;
}

export interface VerifyEmailSuccess {
  type: UserActionType.VERIFY_EMAIL_SUCCESS;
}

export interface VerifyEmailFail {
  type: UserActionType.VERIFY_EMAIL_FAIL;
}

export interface SendVerificationEmailAction {
  type: UserActionType.SEND_VERIFICATION_EMAIL;
  code: string;
}

export interface SendVerificationEmailSuccessAction {
  type: UserActionType.SEND_VERIFICATION_EMAIL_SUCCESS;
}

export interface SendVerificationEmailFailAction {
  type: UserActionType.SEND_VERIFICATION_EMAIL_FAIL;
  error: string;
}

export interface FederatedConnectAction {
  type: UserActionType.FEDERATED_CONNECT;
  primaryUsername: string;
}

export interface FederatedConnectFailAction {
  type: UserActionType.FEDERATED_CONNECT_FAIL;
  error: string;
}

export interface FederatedDisconnectAction {
  type: UserActionType.FEDERATED_DISCONNECT;
  provider: CognitoFederatedProviders;
}

export interface FederatedDisconnectSuccessAction {
  type: UserActionType.FEDERATED_DISCONNECT_SUCCESS;
  provider: CognitoFederatedProviders;
}

export interface FederatedDisconnectFailAction {
  type: UserActionType.FEDERATED_DISCONNECT_FAIL;
  error: string;
}

export interface RequestRelocationGuideAction {
  type: UserActionType.REQUEST_RELOCATION_GUIDE;
  body: RequestRelocationGuideBody;
}

export interface RequestRelocationGuideSuccessAction {
  type: UserActionType.REQUEST_RELOCATION_GUIDE_SUCCESS;
}

export interface RequestRelocationGuideFailAction {
  type: UserActionType.REQUEST_RELOCATION_GUIDE_FAIL;
  error: string;
}

export interface SetRecommendedAgentAction {
  type: UserActionType.SET_RECOMMENDED_AGENT;
  agentNameOrId: string;
}

export interface ClearRecommendedAgentAction {
  type: UserActionType.CLEAR_RECOMMENDED_AGENT;
}

export interface SetUserLocationAction {
  type: UserActionType.SET_USER_LOCATION;
  location: UserLocation;
}
export interface EmailNotificationsSubscribeAction {
  type: UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIBE;
  hash: string;
}

export interface EmailNotificationsUnsubscribeAction {
  type: UserActionType.EMAIL_NOTIFICATIONS_UNSUBSCRIBE;
  hash: string;
}

export interface EmailNotificationsSubscriptionFailAction {
  type: UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIPTION_FAIL;
  error: string;
}
export interface EmailNotificationsSubscriptionSuccessAction {
  type: UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIPTION_SUCCESS;
}

export interface ContactUsAction {
  body: ContactUsBody;
  type: UserActionType.CONTACT_US;
}

export interface FetchDefaultAgentAction {
  type: UserActionType.FETCH_DEFAULT_AGENT_REQUEST;
}

export interface FetchDefaultAgentSuccessAction {
  type: UserActionType.FETCH_DEFAULT_AGENT_SUCCESS;
  defaultAgent: Agent;
}

export interface FetchDefaultAgentFailAction {
  type: UserActionType.FETCH_DEFAULT_AGENT_FAIL;
  error: string;
}

export interface FetchNotificationCountsSuccessAction {
  type: UserActionType.FETCH_NOTIFICATION_COUNTS_SUCCESS;
  notificationHistoryCount: number;
  savedSearchInvitationsCount: number;
  collectionsInvitationsCount: number;
}

export interface FetchNotificationInboxCountAction {
  type: UserActionType.FETCH_NOTIFICATION_INBOX_COUNT;
}

export interface FetchNotificationInboxCountSuccessAction {
  type: UserActionType.FETCH_NOTIFICATION_INBOX_COUNT_SUCESS;
  count: number;
}

export interface RequestFreeWhitepaperAction {
  type: UserActionType.REQUEST_FREE_WHITEPAPER;
  body: RequestFreeWhitepaperBody;
}

interface SetLinkDataPayload {
  linkType: FromLinkStatus;
  leadSourceDetails?: string;
  recommendedAgent?: string;
}

export interface SetLinkDataAction {
  type: UserActionType.SET_LINK_DATA;
  payload: SetLinkDataPayload;
}
export interface SetIsMobileAction {
  type: UserActionType.SET_IS_MOBILE;
  isMobile: boolean;
}

export interface GetSocialPhotoURLAction {
  type: UserActionType.GET_SOCIAL_PHOTO_URL;
}
export interface GetSocialPhotoURLSuccessAction {
  type: UserActionType.GET_SOCIAL_PHOTO_URL_SUCCESS;
  SocialPhotoURL: string;
}
export interface GetSocialPhotoURLFailAction {
  type: UserActionType.GET_SOCIAL_PHOTO_URL_FAILED;
  error: string;
}

export interface SetSocialPhotoURLAction {
  type: UserActionType.SET_SOCIAL_PHOTO_URL;
  socialPhotoURL: string;
}
export interface SetSocialPhotoURLSuccessAction {
  type: UserActionType.SET_SOCIAL_PHOTO_URL_SUCCESS;
  SocialPhotoURL: string;
}
export interface SetSocialPhotoURLFailAction {
  type: UserActionType.SET_SOCIAL_PHOTO_URL_FAILED;
  error: string;
}
export interface RemoveSocialPhotoURLAction {
  type: UserActionType.REMOVE_SOCIAL_PHOTO_URL;
}
export interface RemoveSocialPhotoURLSuccessAction {
  type: UserActionType.REMOVE_SOCIAL_PHOTO_URL_SUCCESS;
}
export interface RemoveSocialPhotoURLFailAction {
  type: UserActionType.REMOVE_SOCIAL_PHOTO_URL_FAILED;
  error: string;
}

export type UserAction =
  | GetUserSessionAction
  | GetUserSessionErrorAction
  | StartUserSignUpAction
  | UserFederatedLogInStartAction
  | UserFederatedLogInSuccessAction
  | UserFederatedLogInErrorAction
  | UserSignUpErrorAction
  | UserSignUpUsernameExistsAction
  | UserSignUpEmailVerificationErrorAction
  | StartUserLogInAction
  | ResetUserLogInAction
  | ResetUserSignUpAction
  | UserIsLoggedAction
  | UserLoginErrorAction
  | SetFederatedUserProfileAction
  | StartUpdateProfileAction
  | CreateOrUpdateProfileSuccessAction
  | CreateOrUpdateProfileErrorAction
  | StartCreateProfileAction
  | FetchProfileAction
  | FetchProfileSuccessAction
  | FetchProfileErrorAction
  | StartSignOutAction
  | SignOutSuccessAction
  | SignOutErrorAction
  | ForgotPasswordPrepareAction
  | StartForgotPasswordAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordErrorAction
  | StartResetPasswordAction
  | StartSetPasswordAction
  | ResetPasswordErrorAction
  | StartChangePasswordAction
  | ChangePasswordSuccessAction
  | ChangePasswordErrorAction
  | ResetPasswordSuccessAction
  | SetPasswordSuccessAction
  | SetPasswordErrorAction
  | GetUserAgentAction
  | GetUserAgentSuccessAction
  | ChangeUserAgentAction
  | GetUserAgentErrorAction
  | AskAgentQuestionAction
  | AgentLogInAction
  | RequestSpecialistAction
  | VerifyEmail
  | VerifyEmailSuccess
  | VerifyEmailFail
  | SendVerificationEmailAction
  | SendVerificationEmailSuccessAction
  | SendVerificationEmailFailAction
  | FederatedConnectAction
  | FederatedConnectFailAction
  | FederatedDisconnectAction
  | FederatedDisconnectSuccessAction
  | FederatedDisconnectFailAction
  | RequestRelocationGuideAction
  | RequestRelocationGuideSuccessAction
  | RequestRelocationGuideFailAction
  | SetRecommendedAgentAction
  | ClearRecommendedAgentAction
  | SetUserLocationAction
  | EmailNotificationsSubscribeAction
  | EmailNotificationsUnsubscribeAction
  | EmailNotificationsSubscriptionFailAction
  | EmailNotificationsSubscriptionSuccessAction
  | ContactUsAction
  | FetchDefaultAgentAction
  | FetchDefaultAgentSuccessAction
  | FetchDefaultAgentFailAction
  | FetchNotificationCountsSuccessAction
  | FetchNotificationInboxCountAction
  | FetchNotificationInboxCountSuccessAction
  | RequestFreeWhitepaperAction
  | SetLinkDataAction
  | SetIsMobileAction
  | GetSocialPhotoURLSuccessAction
  | GetSocialPhotoURLFailAction
  | SetSocialPhotoURLSuccessAction
  | SetSocialPhotoURLFailAction
  | RemoveSocialPhotoURLSuccessAction
  | RemoveSocialPhotoURLFailAction;

export function getUserSession(): GetUserSessionAction {
  return { type: UserActionType.GET_USER_SESSION };
}

export function getUserSessionError(error: string): GetUserSessionErrorAction {
  return { type: UserActionType.GET_USER_SESSION_ERROR, error };
}

export function userFederatedLogIn(
  federatedProvider: CognitoFederatedProviders,
  isAgent?: boolean
): UserFederatedLogInStartAction {
  return {
    type: UserActionType.USER_FEDERATED_LOG_IN,
    federatedProvider,
    isAgent,
  };
}

export function userFederatedLogInSuccess(): UserFederatedLogInSuccessAction {
  return { type: UserActionType.USER_FEDERATED_LOG_IN_SUCCESS };
}

export function userFederatedLogInError(
  error: string
): UserFederatedLogInErrorAction {
  return { type: UserActionType.USER_FEDERATED_LOG_IN_ERROR, error };
}

export function startUserSignUp(email: string): StartUserSignUpAction {
  return { type: UserActionType.START_USER_SIGN_UP, email };
}

export function resetUserSignUp(): ResetUserSignUpAction {
  return { type: UserActionType.RESET_USER_SIGN_UP };
}

export function userSignUpError(error: string): UserSignUpErrorAction {
  return { type: UserActionType.USER_SIGN_UP_ERROR, error };
}

export function userSignUpUsernameExists(): UserSignUpUsernameExistsAction {
  return { type: UserActionType.USER_SIGN_UP_USERNAME_EXISTS };
}

export function userSignUpEmailVerificationError(): UserSignUpEmailVerificationErrorAction {
  return { type: UserActionType.USER_SIGN_UP_VERIFICATION_ERROR };
}

export function startUserLogIn(
  email: string,
  password: string
): StartUserLogInAction {
  return { type: UserActionType.START_USER_LOGIN, email, password };
}

export function resetUserLogIn(): ResetUserLogInAction {
  return { type: UserActionType.RESET_USER_LOGIN };
}

export function userIsLogged(
  status: boolean,
  role: UserRole = UserRole.Client
): UserIsLoggedAction {
  return { type: UserActionType.USER_IS_LOGGED, status, role };
}

export function userLoginError(error: string): UserLoginErrorAction {
  return { type: UserActionType.USER_LOGGING_ERROR, error };
}

export function setFederatedUserProfile(
  data: UserProfileFederated
): SetFederatedUserProfileAction {
  return { type: UserActionType.SET_FEDERATED_USER_PROFILE, data };
}

export function startCreateProfile(
  body: CreateProfileBody
): StartCreateProfileAction {
  return {
    type: UserActionType.START_CREATE_PROFILE,
    body,
  };
}

export function startUpdateProfile(
  body: UpdateProfileBody,
  silentUpdate = false
): StartUpdateProfileAction {
  return {
    type: UserActionType.START_UPDATE_PROFILE,
    body,
    silentUpdate,
  };
}

export function createOrUpdateProfileSuccess(data: UserProfile) {
  return { type: UserActionType.CREATE_OR_UPDATE_PROFILE_SUCCESS, data };
}

export function createOrUpdateProfileError(
  error: string
): CreateOrUpdateProfileErrorAction {
  return { type: UserActionType.CREATE_OR_UPDATE_PROFILE_ERROR, error };
}

export function fetchProfile(): FetchProfileAction {
  return { type: UserActionType.FETCH_PROFILE };
}

export function fetchProfileSuccess(
  profile: UserProfile
): FetchProfileSuccessAction {
  return { type: UserActionType.FETCH_PROFILE_SUCCESS, profile };
}

export function fetchProfileError(error: string): FetchProfileErrorAction {
  return { type: UserActionType.FETCH_PROFILE_ERROR, error };
}

export function startSignOut(signOutRedirect = true): StartSignOutAction {
  return {
    signOutRedirect,
    type: UserActionType.START_SIGN_OUT,
  };
}

export function signOutError(error: string): SignOutErrorAction {
  return { type: UserActionType.SIGN_OUT_ERROR, error };
}

export function signOutSuccess(): SignOutSuccessAction {
  return { type: UserActionType.SIGN_OUT_SUCCESS };
}

export function forgotPasswordPrepare(): ForgotPasswordPrepareAction {
  return { type: UserActionType.FORGOT_PASSWORD_PREPARE };
}

export function startForgotPassword(
  email: string,
  isResend = false
): StartForgotPasswordAction {
  return { type: UserActionType.START_FORGOT_PASSWORD, email, isResend };
}

export function forgotPasswordSuccess(): ForgotPasswordSuccessAction {
  return { type: UserActionType.FORGOT_PASSWORD_SUCCESS };
}

export function forgotPasswordError(error: string): ForgotPasswordErrorAction {
  return { type: UserActionType.FORGOT_PASSWORD_ERROR, error };
}

export function startResetPassword(
  email: string,
  code: string,
  password: string
): StartResetPasswordAction {
  return { type: UserActionType.START_RESET_PASSWORD, email, code, password };
}

export function startSetPassword(
  email: string,
  temporaryPassword: string,
  password: string
): StartSetPasswordAction {
  return {
    type: UserActionType.START_SET_PASSWORD,
    email,
    temporaryPassword,
    password,
  };
}

export function setPasswordError(error: string): SetPasswordErrorAction {
  return { type: UserActionType.SET_PASSWORD_ERROR, error };
}

export function setPasswordSuccess(): SetPasswordSuccessAction {
  return { type: UserActionType.SET_PASSWORD_SUCCESS };
}

export function resetPasswordSuccess(): ResetPasswordSuccessAction {
  return { type: UserActionType.RESET_PASSWORD_SUCCESS };
}

export function resetPasswordError(error: string): ResetPasswordErrorAction {
  return { type: UserActionType.RESET_PASSWORD_ERROR, error };
}

export function startChangePassword(email: string): StartChangePasswordAction {
  return { type: UserActionType.START_CHANGE_PASSWORD, email };
}

export function changePasswordSuccess(): ChangePasswordSuccessAction {
  return { type: UserActionType.CHANGE_PASSWORD_SUCCESS };
}

export function changePasswordError(error: string): ChangePasswordErrorAction {
  return { type: UserActionType.CHANGE_PASSWORD_ERROR, error };
}

export function getUserAgent(): GetUserAgentAction {
  return { type: UserActionType.GET_USER_AGENT };
}

export function getUserAgentSuccess(agent: Agent): GetUserAgentSuccessAction {
  return { type: UserActionType.GET_USER_AGENT_SUCCESS, agent };
}

export function changeUserAgent(agentId: string): ChangeUserAgentAction {
  return { type: UserActionType.CHANGE_USER_AGENT, agentId };
}

export function getUserAgentError(error: string): GetUserAgentErrorAction {
  return { type: UserActionType.GET_USER_AGENT_ERROR, error };
}

export function askAgentQuestion(
  body: AskQuestionBody
): AskAgentQuestionAction {
  return { type: UserActionType.ASK_AGENT_QUESTION, body };
}

export function agentLogIn(): AgentLogInAction {
  return { type: UserActionType.AGENT_LOGIN };
}

export function requestSpecialist(
  body: RequestSpecialistBody
): RequestSpecialistAction {
  return { type: UserActionType.REQUEST_SPECIALIST, body };
}

export function gapiGetUserFail(): GapiGetUserFail {
  return {
    type: UserActionType.GAPI_GET_USER_FAIL,
  };
}

export function verifyEmail(): VerifyEmail {
  return {
    type: UserActionType.VERIFY_EMAIL,
  };
}

export function verifyEmailSuccess(): VerifyEmailSuccess {
  return {
    type: UserActionType.VERIFY_EMAIL_SUCCESS,
  };
}

export function verifyEmailFail(): VerifyEmailFail {
  return {
    type: UserActionType.VERIFY_EMAIL_FAIL,
  };
}

export function sendVerificationEmail(
  code?: string
): SendVerificationEmailAction {
  return {
    type: UserActionType.SEND_VERIFICATION_EMAIL,
    code,
  };
}

export function sendVerificationEmailSuccess(): SendVerificationEmailSuccessAction {
  return {
    type: UserActionType.SEND_VERIFICATION_EMAIL_SUCCESS,
  };
}

export function sendVerificationEmailFail(
  error: string
): SendVerificationEmailFailAction {
  return {
    type: UserActionType.SEND_VERIFICATION_EMAIL_FAIL,
    error,
  };
}

export function federatedConnect(
  primaryUsername: string
): FederatedConnectAction {
  return {
    type: UserActionType.FEDERATED_CONNECT,
    primaryUsername,
  };
}

export function federatedConnectFail(
  error: string
): FederatedConnectFailAction {
  return {
    type: UserActionType.FEDERATED_CONNECT_FAIL,
    error,
  };
}

export function federatedDisconnect(
  provider: CognitoFederatedProviders
): FederatedDisconnectAction {
  return {
    type: UserActionType.FEDERATED_DISCONNECT,
    provider,
  };
}

export function federatedDisconnectSuccess(
  provider: CognitoFederatedProviders
): FederatedDisconnectSuccessAction {
  return {
    type: UserActionType.FEDERATED_DISCONNECT_SUCCESS,
    provider,
  };
}

export function federatedDisconnectFail(
  error: string
): FederatedDisconnectFailAction {
  return {
    type: UserActionType.FEDERATED_DISCONNECT_FAIL,
    error,
  };
}

export function requestRelocationGuide(
  body: RequestRelocationGuideBody
): RequestRelocationGuideAction {
  return {
    type: UserActionType.REQUEST_RELOCATION_GUIDE,
    body,
  };
}

export function requestRelocationGuideSuccess(): RequestRelocationGuideSuccessAction {
  return {
    type: UserActionType.REQUEST_RELOCATION_GUIDE_SUCCESS,
  };
}

export function requestRelocationGuideFail(
  error: string
): RequestRelocationGuideFailAction {
  return {
    type: UserActionType.REQUEST_RELOCATION_GUIDE_FAIL,
    error,
  };
}

export function setRecommendedAgent(
  agentNameOrId: string
): SetRecommendedAgentAction {
  return { type: UserActionType.SET_RECOMMENDED_AGENT, agentNameOrId };
}

export function clearRecommendedAgent(): ClearRecommendedAgentAction {
  return {
    type: UserActionType.CLEAR_RECOMMENDED_AGENT,
  };
}

export function setUserLocation(location: UserLocation): SetUserLocationAction {
  return {
    type: UserActionType.SET_USER_LOCATION,
    location,
  };
}

export function emailNotificationsSubscribe(
  hash: string
): EmailNotificationsSubscribeAction {
  return {
    type: UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIBE,
    hash,
  };
}

export function emailNotificationsUnsubscribe(
  hash: string
): EmailNotificationsUnsubscribeAction {
  return {
    type: UserActionType.EMAIL_NOTIFICATIONS_UNSUBSCRIBE,
    hash,
  };
}

export function emailNotificationsSubscriptionSuccess(): EmailNotificationsSubscriptionSuccessAction {
  return {
    type: UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIPTION_SUCCESS,
  };
}

export function emailNotificationsSubscriptionFail(
  error: string
): EmailNotificationsSubscriptionFailAction {
  return {
    type: UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIPTION_FAIL,
    error,
  };
}

export function contactUs(body: ContactUsBody): ContactUsAction {
  return { type: UserActionType.CONTACT_US, body };
}

export function fetchDefaultAgent(): FetchDefaultAgentAction {
  return { type: UserActionType.FETCH_DEFAULT_AGENT_REQUEST };
}

export function fetchDefaultAgentSuccess(
  defaultAgent: Agent
): FetchDefaultAgentSuccessAction {
  return { type: UserActionType.FETCH_DEFAULT_AGENT_SUCCESS, defaultAgent };
}

export function fetchDefaultAgentFail(
  error: string
): FetchDefaultAgentFailAction {
  return { type: UserActionType.FETCH_DEFAULT_AGENT_FAIL, error };
}

export function fetchNotificationCountsSuccess({
  notificationHistoryCount = 0,
  savedSearchInvitationsCount = 0,
  collectionsInvitationsCount = 0,
}: NotificationCountsPayload): FetchNotificationCountsSuccessAction {
  return {
    type: UserActionType.FETCH_NOTIFICATION_COUNTS_SUCCESS,
    notificationHistoryCount,
    savedSearchInvitationsCount,
    collectionsInvitationsCount,
  };
}

export function fetchNotificationInboxCount(): FetchNotificationInboxCountAction {
  return {
    type: UserActionType.FETCH_NOTIFICATION_INBOX_COUNT,
  };
}

export function fetchNotificationInboxSuccessCount(
  count: number
): FetchNotificationInboxCountSuccessAction {
  return {
    type: UserActionType.FETCH_NOTIFICATION_INBOX_COUNT_SUCESS,
    count,
  };
}

export function requestFreeWhitepaper(
  body: RequestFreeWhitepaperBody
): RequestFreeWhitepaperAction {
  return {
    type: UserActionType.REQUEST_FREE_WHITEPAPER,
    body,
  };
}

export function setLinkData(payload: SetLinkDataPayload): SetLinkDataAction {
  return {
    type: UserActionType.SET_LINK_DATA,
    payload,
  };
}

export function setIsMobile(isMobile: boolean): SetIsMobileAction {
  return { type: UserActionType.SET_IS_MOBILE, isMobile };
}

export function startSetSocialPhotoURL(
  socialPhotoURL: string
): SetSocialPhotoURLAction {
  return { type: UserActionType.SET_SOCIAL_PHOTO_URL, socialPhotoURL };
}

export function setSocialPhotoURLSuccess(
  SocialPhotoURL: string
): SetSocialPhotoURLSuccessAction {
  return { type: UserActionType.SET_SOCIAL_PHOTO_URL_SUCCESS, SocialPhotoURL };
}

export function setSocialPhotoURLFailed(
  error: string
): SetSocialPhotoURLFailAction {
  return { type: UserActionType.SET_SOCIAL_PHOTO_URL_FAILED, error };
}

export function startRemoveSocialPhotoURL(): RemoveSocialPhotoURLAction {
  return { type: UserActionType.REMOVE_SOCIAL_PHOTO_URL };
}

export function removeSocialPhotoURLSuccess(): RemoveSocialPhotoURLSuccessAction {
  return { type: UserActionType.REMOVE_SOCIAL_PHOTO_URL_SUCCESS };
}

export function removeSocialPhotoURLFailed(
  error: string
): RemoveSocialPhotoURLFailAction {
  return { type: UserActionType.REMOVE_SOCIAL_PHOTO_URL_FAILED, error };
}
