import { DefaultTheme, ThemeProps } from 'styled-components/macro';

import { BaseError } from 'shared/types/errorTypes';

export interface ActionParam {
  type: string;
  [key: string]: any;
}

export type ConnectProps<
  StateProps extends (...args: any) => any,
  DispatchProps = Record<string, unknown>
> = ReturnType<StateProps> &
  (DispatchProps extends (...args: any) => any
    ? ReturnType<DispatchProps>
    : DispatchProps);

export interface Paginated<T> {
  hits: T[];
  total: number;
}

export interface BaseResponse<T> {
  data: T;
  description: string;
  success: boolean;
  error: BaseError;
  meta: Record<string, unknown>;
}

export interface LoadingState {
  error: string;
  loading: boolean;
}

/**
 * @example
 * type A = Promise<string>;
 * type B = UnpackPromise<A>;
 * // type B is string
 */
export type UnpackPromise<T> = T extends Promise<infer U> ? U : T;

/**
 * @example
 * type A = (id: string) => Promise<Listing>
 * type B = UnpackReturnedPromise<A>
 * // type B is Listing
 */
export type UnpackReturnedPromise<T extends (...args: any) => any> =
  UnpackPromise<ReturnType<T>>;

/**
 * It's just a wrapper around UnpackReturnedPromise with a shorter name
 * @example
 * type A = (id: string) => Promise<Listing>
 * type B = ReturnPromise<A>
 * // type B is Listing
 */
export type ReturnPromise<T extends (...args: any) => any> =
  UnpackReturnedPromise<T>;

/**
 * A copy of built-in Awaited type, which is not supported by CRA yet.
 */
export type FromAwaited<T> = T extends null | undefined
  ? T // special case for `null | undefined` when not in `--strictNullChecks` mode
  : // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object & { then(onfulfilled: infer F): any } // `await` only unwraps object types with a callable `then`. Non-object types are not unwrapped
  ? F extends (value: infer V, ...args: any) => any // if the argument to `then` is callable, extracts the first argument
    ? FromAwaited<V> // recursively unwrap the value
    : never // the argument to `then` was not callable
  : T; // non-object or non-thenable

export interface ImageInfo {
  id: number;
  mediakey: string;
  mediacategory: string;
  mediaurl: string;
  mediaobjectid: string;
  resourcerecordkey: string;
  resourcename: string;
  classname: string;
  shortdescription?: string;
  mimetype: string;
  sortorder: number;
  dateinserted: string;
  dateupdated: string;
  width: number;
  height: number;
  diffimgsizes: DiffImgSizes;
}

export interface DiffImgSizes {
  original?: string;
  large: string;
  medium: string;
  small: string;
}

export interface OptionBase {
  label: string;
  value: string;
}

export interface Pagination {
  total: number;
  page: number;
  perPage: number;
}

export enum HistoryAction {
  POP = 'POP',
  PUSH = 'PUSH',
  REPLACE = 'REPLACE',
}

export type PrimaryLabel = {
  label: string;
  id: string;
  color: string;
  backgroundColor: string;
} | null;

export interface SecondaryLabel {
  label: string;
  color: string;
  backgroundColor: string;
}

export type Themed = ThemeProps<DefaultTheme>;

export enum CognitoFederatedProviders {
  Google = 'Google',
  Facebook = 'Facebook',
}

export enum QueryStringArrayFormat {
  NONE = 'none',
  BRACKET = 'bracket',
  INDEX = 'index',
  COMMA = 'comma',
  SEPARATOR = 'separator',
}

export interface SettingsDataBase {
  name?: string;
  notifyAgent?: boolean;
  collaboratorEmails?: string[];
  collaboratorsToRemove?: string[];
}

export interface Invitation {
  favoritesName: string;
  inviterFirstname: string;
  inviteCode: string;
  createdAt: Date;
  favoritesId: number;
}

export interface InvitationsCount {
  count: number;
}

export type Constructable<T> = new (...args: any) => T;

interface HomeAddress {
  formattedAddress: string;
  zipCode: string;
  city: string;
  state: string;
  latitude: number;
  longitude: number;
}

interface HomeAddressWithOutLatAndLng {
  formattedAddress: string;
  zipCode: string;
  city: string;
  state: string;
}

export interface SellQueryParams extends HomeAddress {
  shortAddress: string;
}
export interface SellQueryParamsWithOutLatAndLng
  extends HomeAddressWithOutLatAndLng {
  shortAddress: string;
}

export interface UserHomeAddress extends HomeAddress {
  streetAddress: string;
}

export type Nullable<T> = T | undefined | null;

export type EmptyObject = Record<never, never>;

export type WithRequired<T, K extends keyof T> = Partial<T> &
  Required<Pick<T, K>>;

export type OptionalToNullable<T> = {
  [K in keyof T]: undefined extends T[K] ? T[K] | null : T[K];
};
