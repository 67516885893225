import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { pickBy, round } from 'lodash';
import { Omit } from 'utility-types';

import {
  AcrePerSq,
  MainFiltersName,
  noMaxOption,
  noMinOption,
} from 'filters/filtersContants';
import {
  FILTERS_DEFAULT_STATE,
  PROPERTY_TYPE_DEFAULT_STATE,
} from 'filters/filtersReducer';
import {
  AllFiltersForm,
  FiltersState,
  PeriodFilters,
  PriceFilters,
  PropertyTypeFilters,
  SecondaryFilters,
  SecondaryFiltersForm,
} from 'filters/filtersTypes';
import { RootState } from 'rootReducer';
import { SelectOption } from 'shared/components/Select/SelectTypes';
import { isNil, not, notEqual } from 'shared/helpers/boolean';
import {
  formatShortPrice,
  formatTwoNumbersRange,
  separateThousandsWithCommas,
} from 'shared/helpers/formatters';
import { isLeasePage } from 'shared/helpers/isLeasePage';
import { keysOf } from 'shared/helpers/object';
import history from 'shared/services/history';

export function getPriceList(
  min: number,
  max: number
): { min: Array<SelectOption<number>>; max: Array<SelectOption<number>> } {
  const priceValues: Array<SelectOption<number>> = [];
  let incValue = 25000;

  for (let value = 0; value <= max; value += incValue) {
    const label = formatShortPrice(value);

    if (value > min) {
      priceValues.push({ value, label });
    }

    if (value >= 500000) {
      incValue = 50000;
    }

    if (value >= 1000000) {
      incValue = 250000;
    }

    if (value >= 5000000) {
      incValue = 1000000;
    }

    if (value >= 15000000) {
      incValue = 5000000;
    }
  }

  const minRange = [noMinOption, ...priceValues];
  const maxRange = [noMaxOption, ...priceValues];

  return { min: minRange, max: maxRange };
}

export const getLeasePriceList = (
  min: number,
  max: number
): { min: Array<SelectOption<number>>; max: Array<SelectOption<number>> } => {
  const priceValues: Array<SelectOption<number>> = [];

  let incValue = 250;

  for (let value = 0; value <= max; value += incValue) {
    const label = separateThousandsWithCommas(value, true);

    if (value > min) {
      priceValues.push({ value, label });
    }

    if (value >= 3000) {
      incValue = 500;
    }
  }

  const minRange = [noMinOption, ...priceValues];
  const maxRange = [noMaxOption, ...priceValues];

  return { min: minRange, max: maxRange };
};

export function getYearsRange(
  min: number,
  max: number
): Array<SelectOption<number>> {
  const years: Array<SelectOption<number>> = [];

  for (let i = min; i <= max; i++) {
    years.unshift({ value: i, label: i.toString() });
  }

  return years;
}

export const getPriceValue = ({
  min = 0,
  max = 0,
  isLeaseSearch,
}: {
  min?: number;
  max?: number;
  isLeaseSearch: boolean;
}) => {
  return formatTwoNumbersRange(min, max, 1, (price: number) =>
    isLeaseSearch ? separateThousandsWithCommas(price) : formatShortPrice(price)
  );
};

export function getSquareFeet(
  min: number,
  max: number
): Array<{ value: number; label: string }> {
  const livingarea: Array<{ value: number; label: string }> = [];
  const increment = 250;

  for (let i = min; i <= max; i += increment) {
    livingarea.push({ value: i, label: i.toString() });
  }

  return livingarea;
}

export function getTitleValue(min: number, max: number): string {
  if (min && max && min === max) {
    return String(min);
  }
  const minimum = min ? min : max ? '' : `No min`;
  const separator = (max && min) || (!max && !min) ? ' — ' : '';
  const maximum = max ? max : min ? '' : `No max`;

  return `${minimum}${separator}${maximum}`;
}

export const years = getYearsRange(1800, new Date().getFullYear());

export const livingarea = getSquareFeet(250, 7500);

export const priceList = getPriceList(1, 1000000000);
export const leasePriceList = getLeasePriceList(750, 5000);

export const minlivingarea: Array<SelectOption<number>> = [
  noMinOption,
  ...livingarea,
];

export const maxlivingarea: Array<SelectOption<number>> = [
  noMaxOption,
  ...livingarea,
];

export const minYears: Array<SelectOption<number>> = [noMinOption, ...years];

export const maxYears: Array<SelectOption<number>> = [noMaxOption, ...years];

export const filterMinMax = ({
  value,
  min,
  max,
  isMin = false,
}: {
  value: number;
  min: number;
  max: number;
  isMin?: boolean;
}) => {
  if (!value) {
    return true;
  }

  if (isMin) {
    return !max || value <= max || (!max && value >= min);
  }

  return !min || value >= min || (!min && value <= max);
};

export const validateMinMax = ({
  value,
  compareValue,
  validator,
  isMin = false,
}: {
  value: string;
  compareValue: number;
  validator: (value: string) => boolean;
  isMin?: boolean;
}) => {
  const digitValue = +value;
  const comparison = isMin
    ? digitValue <= compareValue
    : digitValue >= compareValue;

  return validator(value) && (!compareValue || comparison);
};

export const validateSelectInput = (
  value: string,
  dataArray: Array<{ value: number; label: string }>
) => {
  if (!/^\d+$/.test(value.toString())) {
    return false;
  }

  const digitValue = parseInt(value, 10);

  return (
    !Number.isNaN(digitValue) &&
    !dataArray.some(obj => obj.value === digitValue)
  );
};

export const formatLotSize = (value: number) => {
  const sqft = value * AcrePerSq;
  const minAcresValue = 0.25;
  return value < minAcresValue ? `${Math.round(sqft)} sq.ft` : `${value} acres`;
};

export const getNumericValueForLotSize = (value: string | number) => {
  if (typeof value === 'number') {
    return value;
  }

  const match = value.match(/^\d*\.?\d*/i)?.pop();

  if (isNil(match)) {
    return 0;
  }

  const numeric = Number(match);

  if (/^\d*\.?\d*$/.test(value.replace(/\s/g, ''))) {
    return round(numeric / AcrePerSq, 5);
  }

  const isSq =
    value.replace(/\s/g, '').search(/^\d*\.?\d*(s|sq|sq\.|sq\.f|sq\.ft)?$/i) !==
    -1;

  return round(isSq ? numeric / AcrePerSq : numeric, 5);
};

export const getNumericValueForPrice = (value: string | number) => {
  if (/^\d+$/.test(value.toString())) {
    return +value;
  }

  if (typeof value === 'string') {
    const valueChar = value.slice(-1).toLowerCase();
    const valueWithoutChar = +value.slice(0, -1);
    let result;

    if (valueChar.includes('k')) {
      result = valueWithoutChar * 1000;
    } else if (valueChar.includes('m')) {
      result = valueWithoutChar * 1000000;
    } else if (valueChar.includes('b')) {
      result = valueWithoutChar * 1000000000;
    } else {
      result = +valueWithoutChar;
    }

    return result;
  }

  return value;
};

export function removeDefaultFilters({
  filters,
  defaultFilters = FILTERS_DEFAULT_STATE,
  checkPropertyTypeFilters,
}: {
  filters: Partial<FiltersState>;
  defaultFilters: FiltersState;
  checkPropertyTypeFilters: boolean;
}): Partial<FiltersState> {
  return pickBy(filters, (value: unknown, key: keyof FiltersState) => {
    const defaultValue = defaultFilters[key];
    if (Array.isArray(value) && Array.isArray(defaultValue)) {
      return notEqual([...defaultValue].sort(), [...value].sort());
    }

    if (
      checkPropertyTypeFilters &&
      key in PROPERTY_TYPE_DEFAULT_STATE &&
      (filters[key] ||
        filters[key] !==
          PROPERTY_TYPE_DEFAULT_STATE[key as keyof PropertyTypeFilters])
    ) {
      return true;
    }

    return notEqual(value, defaultValue);
  });
}

export function getPeriodStartAndEnd(
  days: number
): Partial<Omit<PeriodFilters, 'period'>> {
  if (not(days)) {
    return {};
  }

  const periodFilters = {
    periodStart: dayjs()
      .subtract(days - 1, 'days')
      .startOf('day')
      .format(),
  };

  const periodEnd = days === 2 ? dayjs().startOf('day').format() : '';
  if (periodEnd) {
    Object.assign(periodFilters, {
      periodEnd,
    });
  }

  return periodFilters;
}

export function getMainFiltersAriaLabel(
  filterName: MainFiltersName,
  buttonTitle: string
): string {
  return buttonTitle === 'Any'
    ? `Any ${filterName}`
    : `${filterName} from ${buttonTitle.replace('-', 'to').replace('+', '')}`;
}

export function getPriceTitle({ minPrice, maxPrice }: PriceFilters): string {
  const onLeasePage = isLeasePage(history.location.pathname);
  return formatTwoNumbersRange(minPrice, maxPrice, 1, (price: number) =>
    onLeasePage ? separateThousandsWithCommas(price) : formatShortPrice(price)
  );
}

export function getBedroomsTitle(filters: Partial<FiltersState>): string {
  const { minBedrooms, maxBedrooms } = filters;
  return formatTwoNumbersRange(minBedrooms, maxBedrooms, 1);
}

export function getBathroomsTitle({
  minBathrooms,
}: Partial<FiltersState>): string {
  return minBathrooms ? `${minBathrooms}+` : 'Any';
}

export const prepareSecondaryFilters = (
  filters: AllFiltersForm | SecondaryFiltersForm
): SecondaryFilters => ({
  ...filters.sourceFilters,
  mlsStatus: filters.mlsStatus,
  ...filters.timeFrameFilters,
  ...filters.showOnlyFilters,
  ...filters.propertyTypeFilters,
  ...filters.sizeFilters,
  ...filters.homeFeaturesFilters,
  ...filters.propertyFeaturesFilters,
  ...filters.communityFeaturesFilters,
  ...filters.greatSchoolRatingFilters,
  keyword: filters.keyword.trim(),
});

export const prepareAllFilters = (
  filters: AllFiltersForm
): Partial<FiltersState> => ({
  minBathrooms: filters.minBathrooms,
  minBedrooms: filters.minBedrooms,
  maxBedrooms: filters.maxBedrooms,
  minPrice: filters.minPrice,
  maxPrice: filters.maxPrice,
  ...prepareSecondaryFilters(filters),
});

export function getFiltersSectionChanged(
  filters: Partial<FiltersState>
): boolean {
  return keysOf(filters).some(key => {
    const value = filters[key];
    const defaultValue = FILTERS_DEFAULT_STATE[key];

    if (Array.isArray(value) && Array.isArray(defaultValue)) {
      return notEqual([...defaultValue].sort(), [...value].sort());
    }

    return notEqual(defaultValue, value);
  });
}

/**
 * Creates a selector for a set of filters to check if they've changed
 */
export function changedSelector(
  selector: (root: RootState) => Partial<FiltersState>
): (root: RootState) => boolean {
  return createSelector(selector, getFiltersSectionChanged);
}
