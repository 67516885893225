import { FILTERS_DEFAULT_STATE } from 'filters/filtersReducer';

const lowerCaseToCamelCaseQueryMap = createLowerCaseToCamelCaseQueryMap();

export function mapLowerCaseQueryToCamelCase<T>(query: Record<string, T>) {
  const result: Record<string, T> = {};
  for (const [queryKey, value] of Object.entries(query)) {
    const filterKey = lowerCaseToCamelCaseQueryMap.get(queryKey);
    result[filterKey || queryKey] = value;
  }
  return result;
}

export function getCamelCaseKey(lowerCaseKey: string) {
  return lowerCaseToCamelCaseQueryMap.get(lowerCaseKey);
}

function createLowerCaseToCamelCaseQueryMap() {
  const filterKeys = Object.keys(FILTERS_DEFAULT_STATE);
  const sortingKeys = ['ourListingsFirst', 'page', 'sortBy', 'sortType'];

  const camelCaseKeys = filterKeys.concat(sortingKeys);

  const lowerCaseToCamelCaseMap = new Map<string, string>();
  for (const key of camelCaseKeys) {
    const lowerCaseKey = key.toLowerCase();
    lowerCaseToCamelCaseMap.set(lowerCaseKey, key);
  }
  return lowerCaseToCamelCaseMap;
}
