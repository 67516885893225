import { DeepReadonly } from 'utility-types';

import { FiltersAction, FiltersActionType } from 'filters/filtersActions';
import { MlsStatus } from 'filters/filtersContants';
import {
  BathsFilters,
  BedsFilters,
  CommunityFeaturesFilters,
  FiltersState,
  GreatSchoolRatingFilters,
  HomeFeaturesFilters,
  KeywordsFilters,
  OpenHouseFilters,
  PeriodFilters,
  PriceFilters,
  PropertyFeaturesFilters,
  PropertyTypeFilters,
  SecondaryFilters,
  SizeFilters,
  TimeFrameFilters,
} from 'filters/filtersTypes';
import { RoutePath } from 'shared/constants/routesConstants';
import { not } from 'shared/helpers/boolean';
import history from 'shared/services/history';

export const PRICE_DEFAULT_STATE: PriceFilters = {
  minPrice: 0,
  maxPrice: 0,
};

export const BEDS_DEFAULT_STATE: BedsFilters = {
  minBedrooms: 0,
  maxBedrooms: 0,
};

export const BATHS_DEFAULT_STATE: BathsFilters = {
  minBathrooms: 0,
};

export const COMMUNITY_FEATURES_DEFAULT_STATE: CommunityFeaturesFilters = {
  homeownersAssoc: 'Any',
  gatedCommunity: false,
  communityPool: false,
  playground: false,
  dogPark: false,
  fitnessCenter: false,
  golfCourse: false,
  sportCourt: false,
  tennisCourts: false,
  horsesAllowed: false,
  airplaneFacilities: false,
  lakeAccess: false,
  hikingTrails: false,
  clubHouse: false,
  boatDock: false,
  park: false,
};

export const HOME_FEATURES_DEFAULT_STATE: HomeFeaturesFilters = {
  minYearBuilt: 0,
  maxYearBuilt: 0,
  masterOnMain: false,
  guestQuarters: false,
  fireplace: false,
  accessibilityFeatures: false,
  historicDesign: false,
  energyEfficient: false,
  exerciseRoom: false,
  gameRoom: false,
  mediaRoom: false,
  officeStudy: false,
  wineRoom: false,
  workshop: false,
  petsAllowed: 'Any',
};

export const DEFAULT_MLS_STATUSES = [
  MlsStatus.Active,
  MlsStatus.PendingTakingBackups,
];

export const DEFAULT_SOURCE_STATE = {
  exclusiveListingsEnabled: true,
  mlsListingsEnabled: true,
};

export const OPEN_HOUSE_DEFAULT_STATE: OpenHouseFilters = {
  ourListingsOnly: false,
  openHouseOnly: false,
  openHouse: 0,
  noPhoto: false,
};

export const MLS_STATUS_DEFAULT_STATE = {
  mlsStatus: [...DEFAULT_MLS_STATUSES],
};

export const PROPERTY_FEATURES_DEFAULT_STATE: PropertyFeaturesFilters = {
  backsToGolfCourse: false,
  backsToGreenbelt: false,
  swimmingPool: '',
  fencedYard: false,
  privateDock: false,
  views: [],
  waterfront: false,
  waterBodyName: [],
};

export const PROPERTY_TYPE_DEFAULT_STATE: PropertyTypeFilters = {
  singleFamilyResidence: true,
  condominium: true,
  townhouse: true,
  lot: false,
  farm: false,
  multiFamily: false,
  commercialSale: false,
};

export const SIZE_FILTER_DEFAULT_STATE: SizeFilters = {
  minLotSize: 0,
  maxLotSize: 0,
  minSquareFeet: 0,
  maxSquareFeet: 0,
  minStories: 0,
  maxStories: 0,
  minGarageSpaces: 0,
  maxGarageSpaces: 0,
};

export const TIME_ON_DEFAULT_STATE: TimeFrameFilters = {
  daysOnMarket: 0,
  lastPriceReduction: 0,
  soldTimeframe: 0,
  leasedTimeframe: 0,
};

export const PERIOD_DEFAULT_STATE: PeriodFilters = {
  period: 0,
  periodStart: '',
  periodEnd: '',
};

export const KEYWORDS_DEFAULT_STATE: KeywordsFilters = {
  keyword: '',
};

export const GSRATING_DEFAULT_STATE: GreatSchoolRatingFilters = {
  elementarySchoolRating: 0,
  middleSchoolRating: 0,
  highSchoolRating: 0,
};

export const PRIMARY_FILTERS_DEFAULT_STATE = {
  ...PRICE_DEFAULT_STATE,
  ...BEDS_DEFAULT_STATE,
  ...BATHS_DEFAULT_STATE,
};

export const SECONDARY_FILTERS_DEFAULT_STATE = {
  ...DEFAULT_SOURCE_STATE,
  ...MLS_STATUS_DEFAULT_STATE,
  ...COMMUNITY_FEATURES_DEFAULT_STATE,
  ...HOME_FEATURES_DEFAULT_STATE,
  ...OPEN_HOUSE_DEFAULT_STATE,
  ...PROPERTY_FEATURES_DEFAULT_STATE,
  ...PROPERTY_TYPE_DEFAULT_STATE,
  ...SIZE_FILTER_DEFAULT_STATE,
  ...TIME_ON_DEFAULT_STATE,
  ...GSRATING_DEFAULT_STATE,
  ...KEYWORDS_DEFAULT_STATE,
};

export const FILTERS_DEFAULT_STATE: FiltersState = {
  ...PRIMARY_FILTERS_DEFAULT_STATE,
  ...SECONDARY_FILTERS_DEFAULT_STATE,
  ...PERIOD_DEFAULT_STATE,
};

export const getDefaultFilters = ({
  isAgent,
  agentMode,
  secondaryFilters,
}: {
  isAgent: boolean;
  agentMode: boolean;
  secondaryFilters?: boolean;
}): FiltersState | SecondaryFilters => {
  const onLeasePage = history.location.pathname.includes(RoutePath.LEASE);

  const DEFAULT_FILTERS = secondaryFilters
    ? SECONDARY_FILTERS_DEFAULT_STATE
    : FILTERS_DEFAULT_STATE;

  return isAgent && not(agentMode) && not(onLeasePage)
    ? {
        ...DEFAULT_FILTERS,
        mlsStatus: [...DEFAULT_MLS_STATUSES, MlsStatus.Private],
      }
    : { ...DEFAULT_FILTERS };
};

export function filtersReducer(
  state: DeepReadonly<FiltersState> = FILTERS_DEFAULT_STATE,
  action: FiltersAction
): DeepReadonly<FiltersState> {
  switch (action.type) {
    case FiltersActionType.SET_FILTER_RANGE_VALUE:
    case FiltersActionType.APPLY_FILTERS:
      return {
        ...state,
        ...action.value,
      };

    case FiltersActionType.MERGE_FILTERS:
      return {
        ...state,
        ...action.filters,
      };

    case FiltersActionType.MERGE_FILTERS_WITH_DEFAULT:
      return action.isAgent
        ? {
            ...(getDefaultFilters({
              isAgent: action.isAgent,
              agentMode: action.agentMode ?? false,
            }) as FiltersState),
            ...action.filters,
          }
        : {
            ...FILTERS_DEFAULT_STATE,
            ...action.filters,
          };

    case FiltersActionType.RESET_PRICE_FILTERS:
      return { ...state, ...PRICE_DEFAULT_STATE };

    case FiltersActionType.RESET_BEDS_FILTERS:
      return { ...state, ...BEDS_DEFAULT_STATE };

    case FiltersActionType.RESET_BATHS_FILTERS:
      return { ...state, ...BATHS_DEFAULT_STATE };

    case FiltersActionType.RESET_ALL_FILTERS:
      return {
        ...state,
        ...getDefaultFilters({
          isAgent: action.isAgent,
          agentMode: action.agentMode,
        }),
      };

    case FiltersActionType.RESET_SECONDARY_FILTERS:
      return {
        ...state,
        ...getDefaultFilters({
          isAgent: action.isAgent,
          agentMode: action.agentMode,
          secondaryFilters: true,
        }),
      };

    case FiltersActionType.ADJUST_FILTERS_FOR_AGENT:
      return {
        ...state,
        mlsStatus:
          action.agentMode || action.isLeasePage
            ? [...DEFAULT_MLS_STATUSES]
            : [...DEFAULT_MLS_STATUSES, MlsStatus.Private],
      };

    default:
      return state;
  }
}
