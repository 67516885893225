import { ListingWithDetails } from 'listingDetails/listingDetailsTypes';
import { ImageInfo, Invitation, SettingsDataBase } from 'shared/types';
import { Collaborator, UserProfile } from 'user/userTypes';

export enum CollectionFilter {
  All = 'all',
  CreatedByMe = 'createdByMe',
}

export interface CollectionNotification {
  userId: string;
  isOwner: boolean;
  frequency: string;
  statusChanges: boolean;
  priceChanges: boolean;
  openHouse: boolean;
  sentAt: string;
  openHouseSentAt: string;
  createdAt: string;
  collectionId: string;
  photoChanges: boolean;
}

export interface CollectionBase {
  addedListings?: CollectionAddedListings[];
  agentId: string;
  agentMode: boolean;
  agentNotified: boolean;
  collaboratorCount?: number;
  collaborators: Collaborator[];
  commentCount: number;
  createdAt: string;
  createdBy?: string;
  description: string;
  id: string;
  images: ImageInfo[];
  loading: boolean;
  name: string;
  notifications: CollectionNotification;
  source?: string;
  total: number;
  updatedAt: string;
  user?: UserProfile;
}

export interface CollectionAddedListings {
  addedAt: string;
  addedBy: string;
  addedByProfile?: UserProfile;
  collectionId: number;
  id: number;
  listing: ListingWithDetails;
  listingId: string;
}

export interface DismissedOrProposedListings {
  addedAt: null | string;
  listingId: string;
  owner: string;
  source: string;
  score?: number;
}

export interface RawCollection extends CollectionBase {
  owner: string;
  data?: CollectionBase;
}

export interface ProposedCollectionBody {
  createdAt: string;
  id: string;
  images: ImageInfo[];
  listings: DismissedOrProposedListings[];
  name: string;
  size: number;
  total: number;
  updatedAt: string;
  loading?: boolean;
}

export interface ProposedCollection {
  data: ProposedCollectionBody;
  description: string;
  error: { status: any; message: any };
  meta?: { collectionOwner?: string };
  success: boolean;
}

export interface DismissedCollection {
  id: string;
  images: ImageInfo[];
  listings: DismissedOrProposedListings[];
  name: string;
  size: number;
  total: number;
  loading?: boolean;
}

export interface CollectionBody {
  name: string;
  description: string;
  listings: string[];
  owner?: string;
  notNotifyUser?: boolean;
}

export interface CollectionsState {
  error: string;
  loading: boolean;
  collectionsToRemove: string[];
  collections: Collection[];
  collectionsFilterBy: CollectionFilter;
  collection: Collection;
  collectionsInvitations: Invitation[];
}

export interface FetchCollectionsBody {
  silentUpdate?: boolean;
  excludeDismissed?: boolean;
  excludeProposed?: boolean;
}

export type FetchCollectionsType =
  | RawCollection
  | DismissedCollection
  | ProposedCollection;

export type Collection =
  | RawCollection
  | DismissedCollection
  | ProposedCollectionBody;

export type RawOrDismissedCollection = RawCollection | DismissedCollection;

export type RawOrProposedCollection = RawCollection | ProposedCollectionBody;

export type DismissedOrProposedCollection =
  | DismissedCollection
  | ProposedCollectionBody;

export interface UpdateCollectionData extends SettingsDataBase {
  id: string;
  notifications: Partial<CollectionNotification>;
  owner?: string;
  description?: string;
  isEmailNotificationsSettings?: boolean;
  name: string;
}

export interface UpdateCollectionBody {
  data: UpdateCollectionData;
  silentUpdate?: boolean;
  onSuccess?: () => void;
  cb?: () => void;
}

export const collectionsNotifications = [
  {
    label: 'Price Changes',
    key: 'priceChanges',
  },
  {
    label: 'Status Changes',
    key: 'statusChanges',
  },
  {
    label: 'Photo Changes',
    key: 'photoChanges',
  },
  {
    label: 'Open House',
    key: 'openHouse',
    hintText: 'You will receive Notification every Friday morning',
  },
];
