import styled from 'styled-components/macro';

import { FILTER_CONTAINER_WIDTH } from 'filters/filtersContants';
import { $getCommunityFeaturesChanged } from 'filters/filtersSelectors';
import {
  CheckboxWrapper,
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  FilterShowMore,
  FiltersRadioContainer,
  FiltersRadioTitle,
} from 'filters/filtersStyled';
import { CheckboxField } from 'shared/components/formikFields/CheckboxField';
import { RadioGroupItem } from 'shared/components/formikFields/RadioField';
import { useSelector } from 'shared/helpers/redux';

const Container = styled.div`
  width: ${FILTER_CONTAINER_WIDTH}px;
  max-width: 100%;
`;

interface CommunityFeaturesFilterProps {
  scrollableNode: HTMLDivElement | null;
  disableFilter: boolean;
}

export const CommunityFeaturesFilter = (
  props: CommunityFeaturesFilterProps
) => {
  const communityFeaturesChanged = useSelector($getCommunityFeaturesChanged);

  return (
    <Container>
      <FilterHeaderContainer>
        {communityFeaturesChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Community Features
        </FilterHeaderTitle>
      </FilterHeaderContainer>
      <FiltersRadioContainer
        aria-labelledby="homeowners-assoc"
        name="communityFeaturesFilters.homeownersAssoc"
      >
        <FiltersRadioTitle id="homeowners-assoc">
          Homeowners Assoc
        </FiltersRadioTitle>
        <RadioGroupItem
          disabled={props.disableFilter}
          label="Any"
          value="Any"
        />
        <RadioGroupItem
          disabled={props.disableFilter}
          label="Yes"
          value="Yes"
        />
        <RadioGroupItem disabled={props.disableFilter} label="No" value="No" />
      </FiltersRadioContainer>
      <CheckboxWrapper>
        <CheckboxField
          name="communityFeaturesFilters.gatedCommunity"
          disabled={props.disableFilter}
          labelText="Gated Community"
        />
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          name="communityFeaturesFilters.horsesAllowed"
          disabled={props.disableFilter}
          labelText="Horses Allowed"
        />
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          name="communityFeaturesFilters.communityPool"
          disabled={props.disableFilter}
          labelText="Community Pool"
        />
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          name="communityFeaturesFilters.airplaneFacilities"
          disabled={props.disableFilter}
          labelText="Airplane Facilities"
        />
      </CheckboxWrapper>
      <FilterShowMore
        lines={0}
        truncateChildren
        scrollableNode={props.scrollableNode}
        more="Show all Community Features"
        less="Show less Community Features"
      >
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.playground"
            disabled={props.disableFilter}
            labelText="Playground"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.lakeAccess"
            disabled={props.disableFilter}
            labelText="Lake Access"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.dogPark"
            disabled={props.disableFilter}
            labelText="Dog Park"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.hikingTrails"
            disabled={props.disableFilter}
            labelText="Hiking Trails"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.fitnessCenter"
            disabled={props.disableFilter}
            labelText="Fitness Center"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.clubHouse"
            disabled={props.disableFilter}
            labelText="Club House	"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.golfCourse"
            disabled={props.disableFilter}
            labelText="Golf Course"
          />
        </CheckboxWrapper>
        {/* <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.boatDock"
            disabled={props.disableFilter}
            labelText="Boat Dock"
          />
        </CheckboxWrapper> */}
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.sportCourt"
            disabled={props.disableFilter}
            labelText="Sport Court"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.park"
            disabled={props.disableFilter}
            labelText="Park"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="communityFeaturesFilters.tennisCourts"
            disabled={props.disableFilter}
            labelText="Tennis Courts"
          />
        </CheckboxWrapper>
      </FilterShowMore>
    </Container>
  );
};
