import { createSelector } from 'reselect';

import {
  Distances,
  FeaturesAndFactsSectionValueType,
  ListingWithDetails,
  TravelMode,
} from 'listingDetails/listingDetailsTypes';
import { isLeaseListing } from 'listings/listingsHelpers';
import { Listing } from 'listings/listingsTypes';
import { RootState } from 'rootReducer';
import { isEmpty } from 'shared/helpers/boolean';
import { addLabelsToItem } from 'shared/helpers/labelsHelper';
import { getProfile } from 'user/userSelectors';
import { UserStateProfile } from 'user/userTypes';

export const getItem = (state: RootState): ListingWithDetails =>
  state.listingDetails.item;

export const getForSaleNearby = (state: RootState): Listing[] =>
  state.listingDetails.forSaleNearby || [];

export const getSoldNearby = (state: RootState): Listing[] =>
  state.listingDetails.soldNearby || [];

export const getDistances = (state: RootState): Distances =>
  state.listingDetails.commuteTimes.distances;

export const getTravelMode = (state: RootState): TravelMode =>
  state.listingDetails.commuteTimes.travelMode;

export const getIsLeaseListing = createSelector(
  [getItem],
  (item: ListingWithDetails): boolean =>
    item.propertyType ? isLeaseListing(item.propertyType) : false
);

export const addLabelsToListing = createSelector(
  [getItem],
  (item: ListingWithDetails): ListingWithDetails => addLabelsToItem(item)
);

export const isListingFavorite = createSelector(
  [getItem, getProfile],
  (item: ListingWithDetails, profile: UserStateProfile): boolean => {
    const ownerHaveSameListing: boolean =
      item.collections.findIndex(item => {
        return item.owner === profile?.data?.username;
      }) !== -1;

    return isEmpty(item.collections.length) && ownerHaveSameListing;
  }
);

export const addLabelsToForSaleNearby = createSelector(
  [getForSaleNearby],
  (items: Listing[]): Listing[] => items.map(item => addLabelsToItem(item))
);

export const addLabelsToSoldNearby = createSelector(
  [getSoldNearby],
  (items: Listing[]): Listing[] => items.map(item => addLabelsToItem(item))
);

const getFeatures = (item: ListingWithDetails) => item.features;

export const getDaysOnMarket = createSelector([getFeatures], subSections => {
  if (subSections) {
    const daysOnMarket = subSections.find(
      elem => elem.type === FeaturesAndFactsSectionValueType.daysOnMarket
    );
    if (daysOnMarket) {
      return Number(daysOnMarket.desc);
    }
  }
});

export const getIsSyncing = createSelector([getItem], item => item.isSyncing);
