import { css } from 'styled-components/macro';

import { media } from 'styled-system/responsive';

export default css`
  .image-gallery {
    width: 100%;

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      font-size: 3em;
    }

    .image-gallery-right-nav {
      right: -125px;
    }

    .image-gallery-left-nav {
      left: -125px;
    }

    .image-gallery-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .image-gallery-slide-wrapper {
      width: 100%;
      padding-top: 16px;
    }

    .image-gallery-thumbnails-wrapper {
      width: 100%;
    }

    .image-gallery-thumbnails {
      padding: 26px 0 8px;
    }

    .image-gallery-slide {
      background-color: #081724;
    }

    .image-gallery-image {
      text-align: center;
    }

    .image-gallery-slide img {
      object-fit: contain;
      width: auto;
    }

    .image-gallery-thumbnail-inner img {
      height: 61px;
      width: 100%;
      object-fit: cover;
    }

    .image-gallery-thumbnail {
      border-width: 3px;
      width: 110px;
    }

    .image-gallery-thumbnail + .image-gallery-thumbnail {
      margin-left: 3px;
    }

    .image-gallery-thumbnail.active {
      border: 3px solid #0085ad;
    }
  }

  ${media.sm(css`
    .image-gallery {
      height: initial;

      .image-gallery-slides {
        height: 100%;
      }

      .image-gallery-slide-wrapper {
        width: 100%;
        height: 100%;
        padding: 0;
      }

      .image-gallery-right-nav {
        right: 5px;
      }

      .image-gallery-left-nav {
        left: 5px;
      }

      .image-gallery-slide img {
        height: auto;
        width: 100%;
      }
    }
  `)}
`;
