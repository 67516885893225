import React from 'react';
import { StyledComponent } from 'styled-components/macro';

import { GetProps, StyledSystemInput } from 'styled-system/lib/types';

export type SxProps = { sx?: StyledSystemInput };

/**
 * Creates a HOC component with predefined sx prop.
 *
 * @example
 * const Card = withSx(Box, 'Card')<HTMLDivElement>({
 *   bg: 'white',
 *   border: '1px solid black',
 * });
 * // is the same as
 * export const Card = forwardRef<HTMLDivElement, BoxProps>(
 *   function Card(props, ref) {
 *     return (
 *       <Box
 *         ref={ref}
 *         {...props}
 *         sx={{
 *           bg: 'white',
 *           border: '1px solid black',
 *           ...props.sx,
 *         }}
 *       />
 *     );
 *   }
 * );
 * // also you can chain your declaration with setName
 * const Card = withSx(Flex)({
 *   bg: 'white',
 *   border: '1px solid black',
 * }).setName('Card');
 */
export const withSx =
  <
    P extends SxProps,
    C extends React.ComponentType<P> | StyledComponent<any, any, P, any>
  >(
    component: C,
    displayName?: string
  ) =>
  <T>(sx: StyledSystemInput) => {
    const Sx: React.RefForwardingComponent<T, GetProps<C>> = (props, ref) => {
      return React.createElement(component, {
        ref,
        ...props,
        sx: { ...sx, ...props.sx },
      });
    };

    if (displayName) {
      Sx.displayName = displayName;
    }

    const self = Object.assign(React.forwardRef<T, GetProps<C>>(Sx), {
      setName: (name: string) => {
        Sx.displayName = name;
        return self;
      },
    });

    return self;
  };
