import { useField } from 'formik';
import React, { useContext } from 'react';
import { css } from 'styled-components/macro';

import Radio, {
  RadioContainer,
  RadioProps,
} from 'shared/components/Radio/Radio';
import { Box } from 'styled-system/components/index';
import { StyledSystemInput } from 'styled-system/lib/types';
import { media } from 'styled-system/responsive';

interface RadioGroupContextValue {
  name: string;
}

const RadioGroupCtx = React.createContext<RadioGroupContextValue | null>(null);

interface RadioGroupRootProps {
  id?: string;
  name: string;
  sx?: StyledSystemInput;
}

export const RadioGroupRoot: React.FC<RadioGroupRootProps> = props => {
  const { sx, children, ...restProps } = props;

  return (
    <RadioGroupCtx.Provider value={{ name: restProps.name }}>
      <Box
        sx={sx}
        role="radiogroup"
        {...restProps}
        css={css`
          ${RadioContainer} {
            margin: 0 16px 16px 0;
            ${media.sm(
              css`
                margin: 0 16px 24px 0;
              `
            )}
          }
        `}
      >
        {children}
      </Box>
    </RadioGroupCtx.Provider>
  );
};

interface RadioGroupItemProps extends Partial<RadioProps> {
  label: RadioProps['titleText'];
  value: RadioProps['value'];
}

export const RadioGroupItem = (props: RadioGroupItemProps) => {
  const ctx = useContext(RadioGroupCtx);
  if (ctx === null) {
    throw new Error('Use RadioGroupItem within RadioGroupRoot!');
  }

  const { label, ...restProps } = props;

  const [field, , helpers] = useField({
    name: ctx.name,
    type: 'radio',
    value: props.value,
  });

  return (
    <Radio
      name={ctx.name}
      {...field}
      checked={field.checked}
      onChange={value => helpers.setValue(value)}
      {...restProps}
      titleText={label}
      data-testid={label}
    />
  );
};
