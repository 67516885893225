export interface SessionStorageInterface {
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
  setItem: (key: string, value: string) => void;
}

export const sessionStorage: SessionStorageInterface = {
  getItem: key => window.sessionStorage.getItem(key),
  removeItem: key => window.sessionStorage.removeItem(key),
  setItem: (key, value) => window.sessionStorage.setItem(key, value),
};
