import styled, { css } from 'styled-components/macro';

import { Themed } from 'shared/types';
import { media } from 'styled-system/responsive';

export const SEARCH_TAG_HEIGHT = 32;

const Wrapper = styled.div<{
  isHidden?: boolean;
}>`
  display: flex;
  padding: 0 8px 8px 0;
  height: ${SEARCH_TAG_HEIGHT}px;
  box-sizing: content-box;

  ${({ isHidden = false }) =>
    isHidden &&
    css<any>`
      visibility: hidden;
    `};
`;

const Tag = styled.a<{
  isSaveSearch?: boolean;
}>`
  display: flex;
  border-radius: 16px;
  border: solid 1px ${({ theme }) => theme.divider};
  background-color: ${({ theme }) => theme.white};
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  padding: 8px 1em;
  height: ${SEARCH_TAG_HEIGHT}px;
  max-width: 300px;

  ${media.sm(css<any>`
    font-size: 12px;
  `)};

  ${media.sm(css<any>`
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 45vw;
    display: flex;
  `)};

  &:hover,
  &:active {
    fill: ${({ theme }) => theme.secondary};
  }

  ${({ isSaveSearch = false }) =>
    isSaveSearch &&
    css<any>`
      background-color: ${({ theme }: Themed) => theme.hover};
    `};

  svg {
    flex-shrink: 0;
    vertical-align: text-top;
    display: inline-block;
    margin-left: 0.6em;
    width: 16px;
    height: 16px;
  }
`;

const HintContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-left: 8px;
  min-width: 16px;
`;

const IconHint = styled(HintContainer)`
  background-color: ${({ theme }) => theme.white};

  svg {
    width: 12px;
    height: 12px;
    margin-left: 0;
  }
`;

const TextHint = styled(HintContainer)`
  background-color: ${({ theme }) => theme.secondary};
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
`;

const Divider = styled.div`
  height: 32px;
  margin-left: 8px;
  border-left: 1px solid ${({ theme }) => theme.divider};
`;

const Label = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SearchBarTagStyled = {
  Divider,
  IconHint,
  Tag,
  TextHint,
  Wrapper,
  Label,
};

export default SearchBarTagStyled;
