import React, { useEffect, useMemo, useState } from 'react';
import Truncate from 'react-truncate';
import TruncateMarkup from 'react-truncate-markup';

import Button from 'shared/components/Button';
import {
  ShowMoreButton,
  TruncatedWrapper,
  TruncateLines,
  TruncateTextWrapper,
} from 'shared/components/ShowMore/ShowMoreStyled';
import { not } from 'shared/helpers/boolean';
import { useTrackedRef } from 'shared/hooks/useTrackedRef';

export interface ShowMoreProps {
  lines?: number;
  children?: React.ReactNode;
  sameline?: boolean;
  // use when you need to show exact number of children (single child in a row)
  truncateChildren?: boolean;
  // use when you have to work with DOM elements. You can't truncate COMPONENTS
  truncateHtml?: boolean;
  // use when you have to show exact number of lines of components (several children in a row)
  truncateLines?: boolean;
  step?: number;
  more?: string | ((count: number) => string);
  less?: string | null;
  disable?: boolean;
  className?: boolean;
  onToggle?: (expanded: boolean) => void;
  center?: boolean;
  ariaHeading?: string;
  lineHeight?: number;
  scrollableNode?: HTMLDivElement | null;
  wrappingComponent?: (params: { children: React.ReactNode }) => JSX.Element;
  width?: number;
  showElipsis?: boolean;
}

const DefaultWrappingButtonComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => <>{children}</>;

const ShowMore = ({
  truncateChildren = false,
  truncateHtml = false,
  truncateLines = false,
  showElipsis = true,
  more = 'Show more...',
  less = 'Show less...',
  ...restProps
}: ShowMoreProps): JSX.Element => {
  const {
    children,
    sameline,
    disable,
    className,
    center,
    ariaHeading,
    onToggle,
    lineHeight,
    step,
    wrappingComponent,
    lines,
  } = restProps;
  const unexpandedHeight = lines * lineHeight;
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState<boolean>(false);
  const [visibleElementsCount, setVisibleElementsCount] = useState(lines);
  const [initial, setInitial] = useState<boolean>(true);
  const [scrollableNodePositionTop, setScrollableNodePositionTop] = useState(0);
  const [innerChildren, setInnerChildren] = useState(children);
  const [elementHeight, setElementHeight] = useState<number>(0);

  const [containerRef] = useTrackedRef<HTMLDivElement>(node => {
    setTimeout(() => {
      setElementHeight(node.scrollHeight);
    }, 0);
  });
  const [showMoreBtnRef, showMoreBtnNode] = useTrackedRef<HTMLDivElement>();

  const containerHeight = useMemo(() => {
    return expanded ? 'auto' : `${unexpandedHeight}px`;
  }, [expanded]);

  const truncatedChildren = (() => {
    if (not(truncateChildren) || disable) {
      return [];
    }
    if (expanded) {
      return React.Children.toArray(innerChildren);
    }

    return React.Children.toArray(innerChildren).slice(0, visibleElementsCount);
  })();

  useEffect(() => {
    setInitial(true);
    setInnerChildren(children);
  }, [children]);

  useEffect(() => {
    // we have to wait until user actually firstly click on the showMore
    if (initial) {
      return;
    }
    if (expanded || truncatedChildren.length > lines) {
      const scrollableNode = restProps.scrollableNode || window;
      scrollableNode.scrollTo(0, scrollableNodePositionTop);
    } else {
      (
        showMoreBtnNode as HTMLDivElement & {
          scrollIntoViewIfNeeded?: (opt_center?: boolean) => void;
        }
      )?.scrollIntoViewIfNeeded?.();
    }
  }, [expanded, step, truncatedChildren?.length, lines]);

  if (disable || lines === undefined) {
    return <>{children}</>;
  }

  const handleTruncate = (truncated: boolean) => setTruncated(truncated);

  const toggleLines = () => {
    saveScrollPosition();
    setInitial(false);
    setExpanded(!expanded);
    onToggle?.(!expanded);
  };

  const showNextLines = () => {
    const nextElementsToShow = getNextElementsCount() + visibleElementsCount;
    const expanded =
      not(React.Children.toArray(children).length - nextElementsToShow) &&
      nextElementsToShow !== visibleElementsCount;

    saveScrollPosition();
    setInitial(false);
    setVisibleElementsCount(
      nextElementsToShow === visibleElementsCount ? lines : nextElementsToShow
    );
    setExpanded(expanded);
    onToggle?.(expanded);
  };

  const saveScrollPosition = () => {
    const scrollToTop = restProps.scrollableNode?.scrollTop ?? window.scrollY;
    setScrollableNodePositionTop(scrollToTop);
  };

  const getNextElementsCount = (): number => {
    const elementsLeft =
      React.Children.toArray(children).length - visibleElementsCount;
    return elementsLeft < step ? elementsLeft : step;
  };

  const getMoreLessTitle = () => {
    if (expanded) {
      return less;
    }

    if (typeof more === 'function') {
      return more(getNextElementsCount());
    }

    return more;
  };

  const isMoreButtonVisible =
    (truncateChildren &&
      truncatedChildren.length !== React.Children.toArray(children).length) ||
    expanded ||
    truncated ||
    unexpandedHeight < elementHeight;

  const WrappingComponent = wrappingComponent || DefaultWrappingButtonComponent;

  const ellipsis = sameline ? (
    <>
      <span style={{ paddingRight: '8px' }}>...</span>
      <ShowMoreButton
        className="show-more-btn-container"
        sameline={sameline}
        center={center}
      >
        <Button
          text
          onClick={toggleLines}
          className="show-more"
          aria-label={`Show more on ${ariaHeading}`}
        >
          {expanded ? less : more}
        </Button>
      </ShowMoreButton>
    </>
  ) : (
    <> ...</>
  );

  return (
    <>
      {truncateChildren ? (
        truncatedChildren
      ) : truncateHtml ? (
        <TruncateMarkup
          lineHeight={lineHeight ?? 24}
          lines={!expanded ? lines : 99999}
          ellipsis={showElipsis && ellipsis}
          data-auto-focus="false"
          tokenize={'words'}
          onTruncate={handleTruncate}
        >
          <TruncatedWrapper>{children}</TruncatedWrapper>
        </TruncateMarkup>
      ) : truncateLines ? (
        <TruncateLines
          ref={containerRef}
          height={isMoreButtonVisible ? containerHeight : 'auto'}
        >
          {children}
        </TruncateLines>
      ) : (
        <Truncate
          trimWhitespace
          onTruncate={handleTruncate}
          lines={!expanded && lines}
          ellipsis={showElipsis && ellipsis}
          data-auto-focus="false"
          width={restProps.width || 0}
        >
          <TruncateTextWrapper isMoreButtonVisible={isMoreButtonVisible}>
            {children}
          </TruncateTextWrapper>
        </Truncate>
      )}

      {isMoreButtonVisible && (!sameline || expanded) && (
        <WrappingComponent>
          <ShowMoreButton
            ref={showMoreBtnRef}
            className={`show-more-btn-container ${className}`}
            sameline={sameline}
            data-auto-focus="false"
            center={center}
          >
            <Button
              text
              onClick={step ? showNextLines : toggleLines}
              data-testid="show-more-btn"
              data-auto-focus="false"
              className="show-more"
              aria-label={`Show more on ${ariaHeading}`}
            >
              {getMoreLessTitle()}
            </Button>
          </ShowMoreButton>
        </WrappingComponent>
      )}
    </>
  );
};

export default React.memo(ShowMore);
