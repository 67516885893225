import { diff } from 'deep-object-diff';
import { Location } from 'history';

import { notNil } from 'shared/helpers/boolean';
import { getNotEmptyQueryParams, isNumeric } from 'shared/helpers/mainHelpers';
import { keysOf } from 'shared/helpers/object';
import history from 'shared/services/history';

export type EmptyObject = Record<never, unknown>;

export const composeUrl = <T extends EmptyObject>(params: {
  defaultFilters: T;
  actualFilters: T;
}): string => {
  const url = new URL(`${window.location.origin}${history.location.pathname}`);

  const changedParams: Partial<T> = diff(
    params.defaultFilters,
    params.actualFilters
  );

  keysOf(changedParams).forEach(key => {
    if (notNil(params.actualFilters[key])) {
      url.searchParams.append(
        key.toString().toLocaleLowerCase(),
        params.actualFilters[key].toString()
      );
    }
  });

  return url.search;
};

export const parseUrl = <T>(
  location: Location,
  defaultValues: T
): Partial<T> => {
  const params = getNotEmptyQueryParams(location.search);
  const parsedParams = mapLowerCaseToCamelCase<T>(params, defaultValues);

  return parsedParams;
};

export const mapLowerCaseToCamelCase = <T extends EmptyObject>(
  params: Record<string, unknown>,
  defaultFilters: T
): Partial<T> => {
  let res: Partial<T> = {};

  const keys = keysOf<T>(defaultFilters);

  for (const key of keys) {
    const lowerCaseKey = key.toString().toLowerCase();

    if (notNil(params[lowerCaseKey])) {
      const value = isNumeric(defaultFilters[key])
        ? Number(params[lowerCaseKey])
        : params[lowerCaseKey];
      res = { ...res, [key]: value };
    }
  }

  return res;
};

export const getDomain = () =>
  window.location.hostname.replace('www.', '').replace('.com', '');
