import styled, { css } from 'styled-components/macro';

import { IconPosition } from 'shared/components/Button/ButtonConstants';
import {
  InnerProps,
  LabelProps,
  RealButtonProps,
} from 'shared/components/Button/ButtonTypes';

export const Label = styled.span<LabelProps>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props =>
    props.hasIcon &&
    props.iconPosition === IconPosition.TOP &&
    css`
      padding: 0;
    `};

  ${props =>
    props.hasIcon &&
    props.iconPosition === IconPosition.RIGHT &&
    css`
      padding: 0 5px 0 0;
    `};

  ${props =>
    props.hasIcon &&
    props.iconPosition === IconPosition.BOTTOM &&
    css`
      padding: 0;
    `};

  ${props =>
    props.hasIcon &&
    props.iconPosition === IconPosition.LEFT &&
    css`
      padding: 0 0 0 5px;
    `};
`;

export const primaryStyles = css`
  background: ${({ theme }) => theme.primary};
  border-color: ${({ theme }) => theme.primary};
  border-radius: 1px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  padding: 0 1em;

  @media (hover) {
    &:hover {
      background: ${({ theme }) => theme.primaryHover};
      border-color: ${({ theme }) => theme.primaryHover};
    }
  }

  &:active {
    background: ${({ theme }) => theme.primaryLight};
    border-color: ${({ theme }) => theme.primaryLight};
  }

  &:disabled {
    color: ${({ theme }) => theme.disabled};
    background: ${({ theme }) => theme.hover};
    border-color: ${({ theme }) => theme.hover};
    cursor: not-allowed;
  }
`;

export const secondaryStyles = css`
  background: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.primary};
  border-radius: 1px;
  color: ${({ theme }) => theme.primary};
  padding: 0 1em;

  @media (hover) {
    &:hover {
      background: ${({ theme }) => theme.primaryHover};
      border-color: ${({ theme }) => theme.primaryHover};
      color: ${({ theme }) => theme.white};
    }
  }

  &:active {
    background: ${({ theme }) => theme.primaryActive};
    border-color: ${({ theme }) => theme.primaryActive};
  }

  &:disabled {
    color: ${({ theme }) => theme.disabled};
    background: ${({ theme }) => theme.white};
    border-color: #d5dce0;
    cursor: not-allowed;
  }
`;

export const tertiaryStyles = css`
  background: ${({ theme }) => theme.white};
  border-radius: 1px;
  border-color: ${({ theme }) => theme.white};
  box-shadow: 0 2px 4px 0 rgba(86, 96, 105, 0.3);
  font-weight: bold;
  color: ${({ theme }) => theme.text2};
  padding: 0 0.75em;

  svg {
    vertical-align: -6px;
    width: 24px;
    height: 24px;
  }

  @media (hover) {
    &:hover {
      color: ${({ theme }) => theme.text2};
      border-color: ${({ theme }) => theme.white};
      background: ${({ theme }) => theme.white};
      box-shadow: 0 4px 4px 0 rgba(86, 96, 105, 0.3);
    }
  }

  &:active {
    background: ${({ theme }) => theme.white};
    box-shadow: 0 1px 4px 0 rgba(86, 96, 105, 0.3);
  }

  &:disabled {
    color: ${({ theme }) => theme.disabled};
    background: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.white};
    box-shadow: 0 2px 4px 0 rgba(86, 96, 105, 0.3);
    cursor: not-allowed;
  }
`;

export const blankStyles = css`
  background: none;
  border: 0;

  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.text3};
  }

  &:hover {
    path,
    rect,
    polygon,
    line {
      fill: ${({ theme }) => theme.text2};
    }
  }

  &:active {
    path,
    rect,
    polygon,
    line {
      fill: ${({ theme }) => theme.text};
    }
  }

  &:disabled {
    path,
    rect,
    polygon,
    line {
      fill: ${({ theme }) => theme.disabled};
    }
  }

  &:hover,
  &:active,
  &:disabled {
    background: none;
    border: 0;
  }
`;

export const texticonStyles = css`
  background: none;
  border: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.secondary};

  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.secondary};
  }

  @media (hover) {
    &:hover {
      color: ${({ theme }) => theme.secondary};
    }
  }

  &:hover,
  &:active,
  &:disabled {
    background: none;
    border: 0;
  }
`;

export const textStyles = css`
  background: none;
  border: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.secondary};

  @media (hover) {
    &:hover {
      &:not(:disabled) {
        color: ${({ theme }) => theme.secondary};
        text-decoration: underline;
      }
    }
  }

  &:hover,
  &:active,
  &:disabled {
    background: none;
    border: 0;
  }
  &:disabled {
    ${Label} {
      color: ${({ theme }) => theme.textButtonDisabled};
    }
  }
`;

export const textInactiveStyles = css`
  background: none;
  border: 0;
  font-weight: normal;
  color: ${({ theme }) => theme.text3};

  @media (hover) {
    &:hover {
      color: ${({ theme }) => theme.text3};
      text-decoration: underline;
    }
  }

  &:hover,
  &:active,
  &:disabled {
    background: none;
    border: 0;
  }
`;

export const isActiveStyles = css`
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.secondary};
`;

export const smallStyles = css`
  min-height: 32px;
  font-size: 0.75em;
  padding: 0 0.1em;
`;

export const paginationStyles = css<RealButtonProps>`
  min-width: 40px;
  font-size: 14px;
  min-height: 40px;
  padding: 0 4px;

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding: 0;
    `}

  svg {
    width: 16px;
    height: 16px;
    margin: 0 auto;
  }

  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.text3};
  }

  &:disabled {
    path,
    rect,
    polygon,
    line {
      fill: ${({ theme }) => theme.border};
    }
  }
`;

export const auxStyles = css`
  color: ${({ theme }) => theme.text3};

  svg {
    vertical-align: -4px;
  }

  @media (hover) {
    &:hover {
      background: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.text3};
      border-color: ${({ theme }) => theme.border};
      box-shadow: 0 2px 4px 0 rgba(86, 96, 105, 0.3);
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.disabled};
    border-color: ${({ theme }) => theme.divider};
    background: #f7f7f7;
    box-shadow: none;
  }

  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.secondary};
  }
`;

export const priStyles = css`
  padding: 0 1.4em;
`;

export const smallAndHasIconStyles = css`
  border-color: ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.text3};
  text-align: left;

  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.text3};
  }

  &:active {
    border-color: #7f8e9b;
    color: ${({ theme }) => theme.text3};
    path,
    rect,
    polygon,
    line {
      fill: ${({ theme }) => theme.text3};
    }
  }

  @media (hover) {
    &:hover {
      border-color: #7f8e9b;
      color: ${({ theme }) => theme.text3};
      path,
      rect,
      polygon,
      line {
        fill: ${({ theme }) => theme.text3};
      }
    }
  }

  &:disabled {
    border-color: ${({ theme }) => theme.secondaryLight};
    background: ${({ theme }) => theme.white};
    box-shadow: none;
    color: ${({ theme }) => theme.secondaryLight};

    path,
    rect,
    polygon,
    line {
      fill: ${({ theme }) => theme.secondaryLight};
    }
  }
`;

export const hasIconOnTheRightStyles = css`
  padding: 0;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const hasIconOnTheTopStyles = css`
  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
`;

export const hasBadgeStyles = css`
  color: ${({ theme }) => theme.secondary};
  border-color: ${({ theme }) => theme.secondary};
  padding-right: 0;

  &:hover:enabled {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.secondary};
    box-shadow: 0 2px 4px 0 rgba(86, 96, 105, 0.3);
    border-color: ${({ theme }) => theme.secondary};
  }

  &:disabled {
    span {
      color: ${({ theme }) => theme.disabled};
      background: transparent;
    }
  }
  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.secondary};
  }
`;

export const hasIconActive = css`
  color: ${({ theme }) => theme.secondary};
  border-color: ${({ theme }) => theme.secondary};
  padding: 0 1em 0 0;

  @media (hover) {
    &:hover {
      background: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.secondary};
      box-shadow: 0 2px 4px 0 rgba(86, 96, 105, 0.3);
      border-color: ${({ theme }) => theme.secondary};

      path,
      rect,
      polygon,
      line {
        fill: ${({ theme }) => theme.secondary};
      }
    }
  }

  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.secondary};
  }
`;

export const hasIconBordered = css`
  border-color: #7f8e9b;
  color: ${({ theme }) => theme.text3};

  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.text3};
  }
`;

export const dirtyStyles = css`
  color: ${({ theme }) => theme.text5};
  border: 1px solid ${({ theme }) => theme.border};

  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.text5};
  }

  @media (hover) {
    &&:hover {
      color: ${({ theme }) => theme.text5};
      border: 1px solid ${({ theme }) => theme.border};

      path,
      rect,
      polygon,
      line {
        fill: ${({ theme }) => theme.text5};
      }
    }
  }
`;

export const largeStyles = css`
  padding: 5px 20px;
  font-size: 14px;
  max-width: 376px;
`;

export const xlStyles = css`
  padding: 9px 20px;
  font-size: 16px;
  max-width: 376px;
`;

export const LoaderWrapper = styled.span`
  display: inline-block;
  min-width: 100px;
`;

export const Badge = styled.span<{ dirty?: boolean }>`
  display: inline-block;
  min-height: 16px;
  min-width: 16px;
  background: ${({ theme, dirty }) => (dirty ? theme.text5 : theme.secondary)};
  border-radius: 50%;
  margin-left: 5px;
  line-height: 16px;
  color: ${({ theme }) => theme.white};
  text-align: center;
  font-size: 10px;
`;

export const ButtonInnerBlock = styled.span<InnerProps>`
  padding: ${({ drawingMapButton, rmInnerPadding }) =>
    drawingMapButton || rmInnerPadding ? '0' : '0 8px;'};
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  &:only-child {
    min-width: 24px;
    min-height: 24px;
  }
  ${props =>
    props.paginationIcon &&
    css`
      padding: 0px 7px;
    `}
  ${props =>
    props.hasIcon &&
    (props.iconPosition === IconPosition.RIGHT ||
      props.iconPosition === IconPosition.LEFT) &&
    css`
      justify-content: space-between;
    `};

  ${props =>
    props.hasIcon &&
    (props.iconPosition === IconPosition.TOP ||
      props.iconPosition === IconPosition.BOTTOM) &&
    css`
      flex-direction: column;
    `};
`;

const ButtonStyles = css<RealButtonProps>`
  svg {
    vertical-align: -1px;
    width: 16px;
    height: 16px;
  }
  border-radius: 1px;
  border: solid 1px ${({ theme }) => theme.border};
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text3};
  text-decoration: none;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &:-moz-focusring {
    outline: 0;
  }

  @media (hover) {
    &:hover {
      border-color: ${({ theme }) => theme.secondary};
    }
  }
  &:disabled {
    background: #f7f7f7;
    color: ${({ theme }) => theme.disabled};
    border-color: ${({ theme }) => theme.divider};
    cursor: default;
    ${Label} {
      color: ${({ theme }) => theme.disabled};
    }

    &:hover {
      cursor: not-allowed;
      svg,
      path {
        fill: ${({ theme }) => theme.disabled};
      }
    }

    svg,
    path {
      fill: ${({ theme }) => theme.disabled};
    }
  }
  ${props => props.primary && primaryStyles}
  ${props => props.secondary && secondaryStyles}
  ${props => props.tertiary && tertiaryStyles}
  ${props => (props.frozen || props.$loading) && `pointer-events: none;`}
  ${props => props.blank && blankStyles}
  ${props => props.texticon && texticonStyles}
  ${props => props.text && textStyles}
  ${props => props.textInactive && textInactiveStyles}
  ${props => props.isActive && isActiveStyles}
  ${props => props.small && smallStyles}
  ${props => props.pagination && !props.moreBtn && paginationStyles}
  ${props =>
    props.pagination &&
    props.moreBtn &&
    paginationStyles &&
    `min-width: 0px; border: none; min-height: 40px;`}
  ${props =>
    props.pagination &&
    props.addHover &&
    !props.isMobile &&
    paginationStyles &&
    css`
      &:hover {
        color: ${({ theme }) => theme.blue};
        path,
        rect,
        polygon,
        line {
          fill: ${({ theme }) => theme.blue};
        }
      }
    `}
  ${props => props.tertiary && props.hasIcon && `padding: 0;`}
  ${props =>
    props.small &&
    !(props.primary || props.secondary || props.tertiary || props.text) &&
    auxStyles}
  ${props => props.small && (props.primary || props.secondary) && priStyles}
  ${props => props.small && props.hasIcon && smallAndHasIconStyles}
  ${props =>
    props.iconPosition === IconPosition.RIGHT && hasIconOnTheRightStyles}
  ${props => props.iconPosition === IconPosition.TOP && hasIconOnTheTopStyles}
  ${props => props.hasIconActive && hasIconActive}
  ${props => props.hasIconBordered && hasIconBordered}
  ${props => props.large && largeStyles}
  ${props => props.xl && xlStyles}
  ${props => props.hasBadge && hasBadgeStyles}
  ${props => props.dirty && dirtyStyles}
  ${props => props.parentWidth && 'width: 100%; max-width: initial;'};
  ${props => props.externalButtonLabel && 'position: relative;'};
`;

export const StyledButton = styled.button<RealButtonProps>`
  ${ButtonStyles}
`;

export const ExternalButtonLabelContainer = styled.div`
  position: absolute;
  top: -9px;
  right: -10px;
`;

interface StyledLinkButtonProps {
  blank?: any;
  frozen?: any;
  hasBadge?: any;
  hasIcon?: any;
  hasIconActive?: any;
  hasIconBordered?: any;
  iconPosition?: any;
  isActive?: any;
  large?: any;
  loading?: any;
  pagination?: any;
  parentWidth?: any;
  primary?: any;
  secondary?: any;
  small?: any;
  tertiary?: any;
  text?: any;
  texticon?: any;
  textInactive?: any;
}

export const StyledLinkButton = styled.a<StyledLinkButtonProps>`
  ${ButtonStyles}
`;
