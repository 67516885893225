import { useState } from 'react';

type Transition<E, S> = (event: E, inStates: S[], go: S) => void;

interface FsmOptions<State, Event> {
  events: Event[];
  name?: string;
  states: State[];
  transitions: (on: Transition<Event, State>) => void;
}

type PartialRecord<T> = Partial<Record<string, T>>;

export const createFSM = <State extends string, Event extends string>(
  options: FsmOptions<State, Event>
) => {
  const transitions: PartialRecord<PartialRecord<State>> = {};
  options.transitions((event, inStates, go) => {
    inStates.forEach(state => {
      if (transitions[state]) {
        transitions[state][event] = go;
      } else {
        transitions[state] = { [event]: go };
      }
    });
  });

  return function useFSM(init: State) {
    const [state, setState] = useState<State>(init);

    const debug = () => {
      console.groupCollapsed(
        `%c${options.name || 'FSM'} current state: %c%s`,
        `color:teal;font-weight:bold`,
        `color:lightcoral;font-weight:bold`,
        state
      );
      console.info('All available transitions ⬎');
      console.table(transitions);
      console.groupEnd();
    };

    const match = (...states: State[]): boolean => {
      return states.includes(state);
    };

    const not = (...states: State[]): boolean => {
      return states.includes(state) === false;
    };

    const send = (event: Event) => {
      const nextState = transitions[state]?.[event];
      if (nextState) {
        setState(nextState);
      }
    };

    return { current: state, debug, match, not, send };
  };
};
