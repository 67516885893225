import { not } from 'shared/helpers/boolean';
import { APIRequestError } from 'shared/services/ApiService';
import { strapiConfig } from 'strapi/strapi-config';
import { StrapiEndpoints } from 'strapi/strapi-constants';
import {
  ButterCollectionResponse,
  ButterPageResponse,
  ButterPagesResponse,
  StrapiPagesResponse,
} from 'strapi/strapi-types';

const STRAPI_BASE_URL = strapiConfig.baseUrl;

interface PageParams {
  levels?: number;
  locale?: string;
  preview?: boolean;
  type?: string;
}

type SetParams = {
  token?: string;
  populate?: string;
  preview?: boolean;
} & Record<string, unknown>;

function setSearchParams(search: URLSearchParams, params: SetParams): void {
  const { token, populate, ...rest } = params;

  Object.entries(rest).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      search.set(key, String(value));
    }
  });

  search.set('populate', populate);
}

export interface FetchPageParams extends PageParams {
  type?: string;
  slug?: string;
  source?: string;
}

type FetchPageStrapi = <T>(params: FetchPageParams) => Promise<T>;

type FetchPage = <T>(params: FetchPageParams) => Promise<ButterPageResponse<T>>;

export const fetchStrapiPage: FetchPageStrapi = async params => {
  const { type, slug, preview, source, ...rest } = params;
  const path = slug ? `${type}/${slug}` : type;
  const url = new URL(`${STRAPI_BASE_URL}/api/${path}`);

  switch (type) {
    case StrapiEndpoints.communityPage:
      setSearchParams(url.searchParams, {
        ...rest,
        populate: 'deep',
        publicationState: preview ? 'preview' : 'live',
        'filters[source][$in][1]': source ? source : `${strapiConfig.source}`,
      });
      break;
    case StrapiEndpoints.blogPage:
      setSearchParams(url.searchParams, {
        ...rest,
        populate: 'deep',
        publicationState: preview ? 'preview' : 'live',
        'filters[source][$in][0]': 'all',
        'filters[source][$in][1]': source ? source : `${strapiConfig.source}`,
      });
      break;
    default:
      setSearchParams(url.searchParams, {
        ...rest,
        populate: 'deep,6',
        publicationState: preview ? 'preview' : 'live',
        'filters[source][$in][0]': 'all',
        'filters[source][$in][1]': source ? source : `${strapiConfig.source}`,
      });
      break;
  }

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${strapiConfig.token}`,
    },
  };

  const response = await fetch(String(url), options);

  if (not(response.ok)) {
    throw new APIRequestError({
      error: 'StrapiCMS: Fetch page error',
      method: 'GET',
      status: response.status,
      url: String(url),
    });
  }

  return response.json().then(json => json.data);
};

export const fetchPage: FetchPage = async params => {
  const { slug, type = '*', ...rest } = params;

  const url = new URL(`${STRAPI_BASE_URL}/pages/${type}/${slug}/`);
  setSearchParams(url.searchParams, { token: strapiConfig.token, ...rest });

  const response = await fetch(String(url));

  if (not(response.ok)) {
    throw new APIRequestError({
      error: 'Strapi: Fetch error',
      method: 'GET',
      status: response.status,
      url: String(url),
    });
  }

  return response.json().then(json => json.data);
};

export interface FetchPagesParams extends PageParams {
  page?: number;
  page_size?: number;
  order?: string;
  filter?: Record<string, string>;
  slug?: string;
}

export interface FetchStrapiPagesParams {
  page?: number;
  pageSize?: number;
  sort?: string;
  [key: string]: any;
  type?: string;
}

type FetchPages = <T>(
  params: FetchPagesParams
) => Promise<ButterPagesResponse<T>>;

type FetchStrapiPages = <T>(
  params: FetchStrapiPagesParams
) => Promise<StrapiPagesResponse<T>>;

export const fetchStrapiPages: FetchStrapiPages = async params => {
  const { type, ...rest } = params;
  const url = new URL(`${STRAPI_BASE_URL}/api/${type}`);
  setSearchParams(url.searchParams, {
    ...rest,
    populate: 'deep,6',
    publicationState: 'live',
    'filters[source][$in][0]': 'all',
    'filters[source][$in][1]': `${strapiConfig.source}`,
  });

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${strapiConfig.token}`,
    },
  };

  const response = await fetch(String(url), options);

  if (not(response.ok)) {
    throw new APIRequestError({
      error: 'Strapi: Fetch error',
      method: 'GET',
      status: response.status,
      url: String(url),
    });
  }

  return response.json().then(json => json);
};

export const fetchPages: FetchPages = async params => {
  const { type = '*', filter = {}, ...rest } = params;

  const url = new URL(`${STRAPI_BASE_URL}/pages/${type}/`);
  setSearchParams(url.searchParams, {
    token: strapiConfig.token,
    ...filter,
    ...rest,
  });

  const response = await fetch(String(url));

  if (not(response.ok)) {
    throw new APIRequestError({
      error: 'StrapiCMS: Fetch pages error',
      method: 'GET',
      status: response.status,
      url: String(url),
    });
  }

  return response.json();
};

interface SearchPagesParams extends PageParams {
  page?: number;
  page_size?: number;
  query: string;
}

type SearchPages = <T>(
  params: SearchPagesParams
) => Promise<ButterPagesResponse<T>>;

export const searchPages: SearchPages = async params => {
  const { type = '*', ...rest } = params;

  const url = new URL(`${STRAPI_BASE_URL}/pages/search/`);
  setSearchParams(url.searchParams, {
    token: strapiConfig.token,
    page_type: type,
    ...rest,
  });

  const response = await fetch(String(url));

  if (not(response.ok)) {
    throw new APIRequestError({
      error: 'ButterCMS: Search pages error',
      method: 'GET',
      status: response.status,
      url: String(url),
    });
  }

  return response.json();
};

interface CollectionParams {
  filter?: Record<string, string>;
  levels?: number;
  locale?: string;
  order?: string;
  page_size?: number;
  page?: number;
  preview?: boolean;
}

interface FetchCollectionsParams<Collections extends string[]>
  extends CollectionParams {
  collections: [...Collections];
}

type FetchCollections = <Collections extends string[], T = any>(
  params: FetchCollectionsParams<Collections>
) => Promise<{ data: { [key in Collections[number]]: T } }>;

export const fetchCollections: FetchCollections = async params => {
  const { collections, filter, ...rest } = params;

  const url = new URL(`${STRAPI_BASE_URL}/content/`);
  setSearchParams(url.searchParams, {
    token: strapiConfig.token,
    keys: collections.join(),
    ...filter,
    ...rest,
  });

  const response = await fetch(String(url));

  if (not(response.ok)) {
    throw new APIRequestError({
      error: 'ButterCMS: Fetch collections error',
      method: 'GET',
      status: response.status,
      url: String(url),
    });
  }

  return response.json();
};

interface FetchCollectionParams<Collection extends string>
  extends CollectionParams {
  collection: Collection;
}

export const fetchCollection = async <T>(
  params: FetchCollectionParams<string>
): Promise<ButterCollectionResponse<T>> => {
  const { collection, ...rest } = params;
  const res = await fetchCollections({ collections: [collection], ...rest });
  return res.data[collection];
};
