import { RawSavedSearch, SavedSearchBody } from 'saved-search/savedSearchTypes';
import { SearchDescriptionBuilder } from 'saved-search/services/SearchDescriptionBuilder';
import { SortFilterValue } from 'shared/components/SortFilter/SortFilter';
import { SortOrder } from 'shared/constants/appConstants';
import { ApiService } from 'shared/services/ApiService';
import { Invitation, InvitationsCount, Paginated } from 'shared/types';
import { Collaborator } from 'user/userTypes';

const BASE_PATH = '/saved-search';

export class SavedSearchService {
  static async fetchSavedSearches(query: {
    user: string;
    page: number;
    limit: number;
    sortBy: SortFilterValue;
    sortType: SortOrder;
  }) {
    const { hits: rawSavedSearches, total } = await ApiService.request<
      Paginated<RawSavedSearch>
    >({
      method: 'GET',
      path: `${BASE_PATH}`,
      query,
    });

    for (const search of rawSavedSearches) {
      search.description = SearchDescriptionBuilder.get(search);
    }

    return { hits: rawSavedSearches, total };
  }

  static async fetchSavedSearch(id: string, user?: string) {
    const rawSavedSearch = await ApiService.request<RawSavedSearch>({
      method: 'GET',
      path: `${BASE_PATH}/${id}`,
      query: {
        user,
      },
    });

    rawSavedSearch.description = SearchDescriptionBuilder.get(rawSavedSearch);

    return rawSavedSearch;
  }

  static async createSavedSearch(savedSearchBody: SavedSearchBody) {
    const rawSavedSearch = await ApiService.request<RawSavedSearch>({
      method: 'POST',
      path: BASE_PATH,
      data: savedSearchBody,
    });

    rawSavedSearch.description = SearchDescriptionBuilder.get(rawSavedSearch);

    return rawSavedSearch;
  }

  static async updateSavedSearch(id: string, savedSearchBody: SavedSearchBody) {
    const rawSavedSearch = await ApiService.request<RawSavedSearch>({
      method: 'PATCH',
      path: `${BASE_PATH}/${id}`,
      data: savedSearchBody,
    });

    rawSavedSearch.description = SearchDescriptionBuilder.get(rawSavedSearch);

    return rawSavedSearch;
  }

  static async removeSavedSearch(id: string, owner?: string) {
    return ApiService.request({
      method: 'DELETE',
      path: `${BASE_PATH}/${id}`,
      data: {
        owner,
      },
    });
  }

  static async joinSavedSearch(inviteCode: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_PATH}/${inviteCode}/join`,
    });
  }

  static async declineSavedSearch(id: string, username?: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_PATH}/${id}/decline`,
      query: {
        username,
      },
    });
  }

  static async sendSearchResults(
    userId: string,
    savedSearchId: string,
    owner?: string
  ) {
    return ApiService.request<RawSavedSearch>({
      method: 'POST',
      path: `${BASE_PATH}/${savedSearchId}`,
      query: {
        user: userId,
      },
      data: {
        owner,
      },
    });
  }

  static async createCollaborator(id: string, collaboratorEmails: string[]) {
    return ApiService.request<Collaborator[]>({
      method: 'POST',
      path: `${BASE_PATH}/${id}/collaborator`,
      data: collaboratorEmails.map(email => ({ email })),
    });
  }

  static async removeCollaborator(id: unknown, collaboratorId: string) {
    return ApiService.request({
      method: 'DELETE',
      path: `${BASE_PATH}/${id}/collaborator/${collaboratorId}`,
    });
  }

  static async fetchSavedSearchInvitations() {
    return ApiService.request<Invitation[]>({
      method: 'GET',
      path: `${BASE_PATH}/invitations`,
    });
  }

  static async fetchSavedSearchInvitationsCount() {
    return ApiService.request<InvitationsCount>({
      method: 'GET',
      path: `${BASE_PATH}/invitations?countOnly=true`,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  static async acceptSavedSearchInvitation(inviteCode: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_PATH}/${inviteCode}/join`,
    });
  }

  static async declineSavedSearchInvitation(id: number) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_PATH}/${id}/decline`,
    });
  }

  static async sendReminder(id: string, collaboratorId: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_PATH}/${id}/collaborator/${collaboratorId}/send-a-reminder`,
    });
  }
}
