import { Dispatch, Middleware, MiddlewareAPI } from 'redux';

import {
  declineCollectionRequest,
  removeCollectionFromStore,
  removeCollectionRequest,
  undoRemoveCollection,
} from 'collections/collectionsActions';
import { CollectionsActionType } from 'collections/collectionsActionType';
import {
  markAllNotificationsAsReadInStore,
  markAllNotificationsAsReadRequest,
  undoMarkAllNotificationsAsRead,
} from 'history/notificationHistory/notificationHistorySlice';
import * as notificationHistory from 'history/notificationHistory/notificationHistorySlice';
import {
  changeMyListingsPage,
  deleteMyListing,
  startDeleteMyListing,
  undoDeleteMyListing,
} from 'myListings/myListingsSlice';
import * as myListingsSlice from 'myListings/myListingsSlice';
import { PortalAction } from 'rootActions';
import { RootState } from 'rootReducer';
import {
  declineSavedSearchRequest,
  removeSavedSearchFromStore,
  removeSavedSearchRequest,
  SavedSearchActionType,
  undoRemoveSavedSearch,
} from 'saved-search/savedSearchActions';
import { getPlural } from 'shared/helpers/getPlural';
import { showSuccessToast } from 'shared/helpers/notifications';

export const toasterMiddleware: Middleware =
  (store: MiddlewareAPI<Dispatch<PortalAction>, RootState>) =>
  (
    next: Dispatch<
      | PortalAction
      | ReturnType<typeof markAllNotificationsAsReadInStore>
      | ReturnType<typeof undoMarkAllNotificationsAsRead>
      | ReturnType<typeof markAllNotificationsAsReadRequest>
      | ReturnType<typeof startDeleteMyListing>
      | ReturnType<typeof changeMyListingsPage>
      | ReturnType<typeof deleteMyListing>
      | ReturnType<typeof undoDeleteMyListing>
    >
  ) =>
  (action: PortalAction) => {
    if (notificationHistory.markAllNotificationsAsRead.match(action)) {
      next(markAllNotificationsAsReadInStore());

      const { notificationHistoryCount, items } = action.payload;

      const prefix = getPlural('Notification', notificationHistoryCount);

      showSuccessToast(`${notificationHistoryCount} ${prefix} Marked as Read`, {
        toastId: 'markAllNotificationsAsRead',
        hideProgressBar: false,
        onUndoClick: () => {
          next(
            undoMarkAllNotificationsAsRead({
              items,
              notificationHistoryCount,
            })
          );
        },
        onClose: () => {
          next(
            markAllNotificationsAsReadRequest({
              items,
              notificationHistoryCount,
            })
          );
        },
      });
      return next(action);
    }

    if (myListingsSlice.startDeleteMyListing.match(action)) {
      const {
        listingId,
        agentId,
        publishedDraftId,
        isDraft,
        page,
        pageWasChanged,
      } = action.payload;

      showSuccessToast('Exclusive Listing Deleted', {
        hideProgressBar: false,
        onUndoClick: () => {
          if (pageWasChanged) {
            next(changeMyListingsPage({ page }));
          }
          next(undoDeleteMyListing({ listingId }));
        },
        onClose: () => {
          next(
            deleteMyListing({
              listingId,
              agentId,
              publishedDraftId,
              isDraft,
            })
          );
        },
      });

      return next(action);
    }

    switch (action.type) {
      case SavedSearchActionType.START_REMOVE_SAVED_SEARCH:
      case SavedSearchActionType.START_DECLINE_SAVED_SEARCH: {
        next(removeSavedSearchFromStore(action.id));
        const postfix =
          action.type === SavedSearchActionType.START_REMOVE_SAVED_SEARCH
            ? 'deleted'
            : 'declined';
        showSuccessToast(
          `Saved Search "${action.name}" successfully ${postfix}`,
          {
            hideProgressBar: false,
            onUndoClick: () => next(undoRemoveSavedSearch(action.id)),
            onClose: () => {
              action.type === SavedSearchActionType.START_REMOVE_SAVED_SEARCH
                ? next(removeSavedSearchRequest(action))
                : next(declineSavedSearchRequest(action));
            },
          }
        );
        return next(action);
      }

      case CollectionsActionType.START_REMOVE_COLLECTION:
      case CollectionsActionType.START_DECLINE_COLLECTION: {
        next(removeCollectionFromStore(action.id));
        const postfix =
          action.type === CollectionsActionType.START_REMOVE_COLLECTION
            ? 'deleted'
            : 'declined';
        showSuccessToast(
          `Collection "${action.name}" successfully ${postfix}`,
          {
            hideProgressBar: false,
            onUndoClick: () => {
              next(undoRemoveCollection(action.id));
            },
            onClose: () => {
              action.type === CollectionsActionType.START_REMOVE_COLLECTION
                ? next(removeCollectionRequest(action))
                : next(declineCollectionRequest(action));
            },
          }
        );
        return next(action);
      }

      default:
        next(action);
    }
  };
