import React from 'react';
import styled, { css } from 'styled-components/macro';

import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import Button from 'shared/components/Button';
import { media, Mobile } from 'styled-system/responsive';

export const CancelContainer = styled.div`
  width: 100%;
  height: 48px;
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  padding: 0 24px;
  font-size: 14px;
  ${media.sm(css`
    height: 72px;
  `)};
`;

const Subtitle = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text3};
`;

interface TooltipResetFooterProps {
  className?: string;
  closeModal?: () => void;
  count?: number;
  disabled?: boolean;
  loading?: boolean;
  onReset: () => void;
  viewAgentsButton?: () => void;
}

const TooltipResetFooter = (props: TooltipResetFooterProps) => {
  const {
    onReset,
    count,
    className,
    viewAgentsButton,
    closeModal,
    loading,
    disabled,
  } = props;

  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();

  return (
    <CancelContainer className={className}>
      <Button
        text
        disabled={disabled}
        onClick={() => {
          setTimeout(
            () => ariaLiveAnnouncerCtx.announce('Filters are reset'),
            100
          );
          onReset();
        }}
      >
        Reset
      </Button>
      {viewAgentsButton ? (
        <Button text onClick={viewAgentsButton}>
          View {count || 0} {count === 1 ? 'agent' : 'agents'}
        </Button>
      ) : (
        <Mobile>
          {isMobile =>
            isMobile ? (
              <Button primary large onClick={closeModal} loading={loading}>
                View {count || 0} {count === 1 ? 'result' : 'results'}
              </Button>
            ) : (
              <Subtitle>
                {count || 0} {count === 1 ? 'result' : 'results'}
              </Subtitle>
            )
          }
        </Mobile>
      )}
    </CancelContainer>
  );
};

export default TooltipResetFooter;
