import { CSSObjectWithLabel, StylesConfig } from 'react-select';

import { ADD_NEW_VALUE } from 'shared/components/Select/SelectConstants';
import {
  OverrideSelectStyles,
  SelectOption,
} from 'shared/components/Select/SelectTypes';
import { Themed } from 'shared/types';

export const customSelectStyles = ({
  theme,
  overrideSelectStyles = {},
  error,
}: {
  isMobile?: boolean;
  overrideSelectStyles?: OverrideSelectStyles;
  error?: boolean;
} & Themed): Partial<StylesConfig> => ({
  container: provided => ({
    ...provided,
    width: '100%',
    border: 0,
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    ...overrideSelectStyles.container,
  }),
  control: (provided, state) => {
    const { isFocused, isDisabled } = state;

    return {
      ...provided,
      color: isDisabled ? theme.text3 : provided.color,
      backgroundColor: isDisabled
        ? theme.disabledArea
        : isFocused
        ? provided.backgroundColor
        : error
        ? theme.errorBg
        : provided.backgroundColor,
      height: '40px',
      boxShadow: 'none',
      border: `1px solid ${
        isFocused
          ? theme.secondary
          : isDisabled
          ? theme.divider
          : error
          ? theme.error
          : theme.disabled
      }`,
      '&:hover': {
        borderColor: `${isFocused ? theme.secondary : theme.text3}`,
      },
      ...overrideSelectStyles.control,
    };
  },
  dropdownIndicator: (provided, state) => {
    const { isDisabled, isFocused } = state;
    return {
      ...provided,
      fill: isFocused ? theme.text2 : isDisabled ? theme.disabled : theme.text3,
      '&:hover': {
        fill: theme.text2,
      },
      ...overrideSelectStyles.dropdownIndicator,
    };
  },
  placeholder: provided => ({
    ...provided,
    color: theme.disabled,
    ...overrideSelectStyles.placeholder,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
    ...overrideSelectStyles.indicatorSeparator,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? theme.text3 : theme.text2,
    ...overrideSelectStyles.singleValue,
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: '16px',
    ...overrideSelectStyles.valueContainer,
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '1px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    border: 'solid 1px #eef0f2',
    overflowX: 'hidden',
    zIndex: 2,
    ...overrideSelectStyles.menu,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    maxHeight: '218px',
    ...overrideSelectStyles.menuList,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      color: state.isDisabled ? theme.disabled : theme.text2,
      padding: '0 0 0 28px',
      backgroundColor: state.isFocused ? theme.hover : 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
      ...overrideSelectStyles.option,
    };
  },
});

export const customDropdownStyles = ({
  isMobile,
  theme,
  error,
}: { isMobile: boolean; error?: boolean } & Themed): Partial<
  StylesConfig<SelectOption>
> => ({
  ...customSelectStyles({ isMobile, theme }),
  option: (provided, state) => {
    if (state.data.value === ADD_NEW_VALUE) {
      const styles: CSSObjectWithLabel = {
        height: '48px',
        width: '372px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Lato, sans-serif',
        fontSize: '14px',
        fontWeight: 'bold',
        color: theme.secondary,
        borderTop: '1px solid #eef0f2',
        cursor: 'pointer',
      };

      if (state.getValue().length > 0 && state.data.value === ADD_NEW_VALUE) {
        styles.bottom = '180px';
      }

      if (isMobile) {
        styles.bottom = 'none';
        styles.top = '315px';
        styles.width = '100%';
      }
      return styles;
    }
    return {
      ...provided,
      paddingLeft: '28px',
      fontSize: '16px',
      height: '48px',
      display: 'flex',
      alignItems: 'center',
      color: state.isDisabled ? theme.disabled : theme.text3,
      backgroundColor: state.isFocused ? theme.hover : 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    };
  },
});

export const SelectInputHeight = '40px';

export const customInputStyles = (
  { theme }: Themed,
  error?: boolean
): Partial<StylesConfig> => ({
  ...customSelectStyles({ theme }),
  control: (provided, state) => {
    const { isFocused, isDisabled } = state;
    let borderColor: string;
    let backgroundColor: string;

    if (isFocused) {
      borderColor = theme.secondary;
    } else if (isDisabled) {
      backgroundColor = theme.disabledArea;
      borderColor = theme.divider;
    } else if (error) {
      backgroundColor = theme.errorBg;
      borderColor = theme.error;
    } else {
      borderColor = theme.border;
    }

    return {
      ...provided,
      height: SelectInputHeight,
      boxShadow: 'none',
      border: `1px solid ${borderColor}`,
      backgroundColor,
      '&:hover': {
        borderColor: `${theme.text3}`,
      },
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      color: state.isDisabled ? theme.disabled : theme.text2,
      padding: '0 0 0 28px',
      backgroundColor: state.isFocused ? theme.hover : 'transparent',
      '&:hover': {
        backgroundColor: theme.hover,
      },
      '&:focus': {
        backgroundColor: theme.hover,
      },
      '&:active': {
        backgroundColor: theme.hover,
      },
    };
  },
  valueContainer: (provided, { selectProps }) => {
    return {
      ...provided,
      visibility: selectProps.menuIsOpen ? 'hidden' : 'visible',
    };
  },
});
