import { generatePath } from 'react-router-dom';
import { Intersection } from 'utility-types';

import { Contact } from 'contacts/contactsTypes';
import { ListingWithDetails } from 'listingDetails/listingDetailsTypes';
import { Listing } from 'listings/listingsTypes';
import { RoutePath } from 'shared/constants/routesConstants';
import { isEmpty, not } from 'shared/helpers/boolean';

type UrlPart = keyof Intersection<
  Partial<Listing>,
  Partial<ListingWithDetails>
>;

export const LISTING_FIELDS: UrlPart[] = [
  'streetnumber',
  'streetdirprefix',
  'streetname',
  'streetsuffix',
  'streetdirsuffix',
  'unitnumber',
  'city',
  'stateorprovince',
  'postalcode',
];

const addUrlPart = (url: string, part: string) => {
  return `${url}${url ? '-' : ''}${part.replace(/\s/g, '-')}`.replace('/', '-');
};

function removeLeadingSlash(input: string): string {
  return input.startsWith('/') ? input.slice(1) : input;
}

export const createListingsUrl = (
  listing?: Partial<Listing> | Partial<ListingWithDetails> | null,
  agentMode = false,
  contactItem: Contact | null = null
) => {
  if (isEmpty(listing)) {
    return '';
  }

  let urlAddress = removeLeadingSlash(listing?.urlAddress || '');

  if (not(urlAddress)) {
    for (const field of LISTING_FIELDS) {
      const value = listing?.[field];
      if (value) {
        urlAddress = addUrlPart(urlAddress, value.toString().toLowerCase());
      }
    }
    const id = listing?.sourcelistingid ?? listing?.listingid;
    if (id) {
      urlAddress = addUrlPart(urlAddress, id);
    }
  }

  let url: string;
  if (agentMode && contactItem) {
    url = generatePath(RoutePath.AGENT_LISTING_DETAILS, {
      address: urlAddress,
      contactId: contactItem.username,
    });
  } else {
    url = generatePath(RoutePath.LISTING_DETAILS, {
      address: urlAddress,
    });
  }

  return `${url}.html`;
};
