import dayjs from 'dayjs';
import { get } from 'lodash';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { AgentsService } from 'agents/AgentsService';
import { Agent } from 'agents/agentsTypes';
import { CollectionService } from 'collections/CollectionsService';
import { TypesOfInquiryHistory } from 'history/inquiryHistory/inquiryHistoryTypes';
import { NOT_OPENED_NOTIFICATION_HISTORY_STATUSES } from 'history/notificationHistory/NotificationHistory/notificationHistoryConstants';
import { NotificationHistoryService } from 'history/notificationHistory/notificationHistoryService';
import {
  changeFromLinkStatus,
  changeLoginStatus,
  clearFromLinkStatus,
} from 'permissions/permissionsActions';
import {
  FromLinkStatus,
  PermissionRoles,
} from 'permissions/permissionsConstants';
import { SavedSearchService } from 'saved-search/SavedSearchService';
import {
  AGENT_LOGIN_REDIRECT,
  DEFAULT_AGENT,
  FEDERATED_USER,
  GTM_CUSTOM_EVENTS_NAMES,
  GTM_TOASTERS_IDS,
  LOGIN_AS_AGENT,
  TEMPORARY_PASSWORD,
} from 'shared/constants/appConstants';
import { AuthErrorType } from 'shared/constants/errorConstants';
import {
  USER_PREVIOUS_LOCATION,
  USER_PREVIOUS_USERNAME,
} from 'shared/constants/rootConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { isEmpty, not } from 'shared/helpers/boolean';
import {
  getErrorType,
  mapServerValidationErrors,
} from 'shared/helpers/errorHelpers';
import { getIsDateInPast } from 'shared/helpers/formatDate';
import generatePassword from 'shared/helpers/generatePassword';
import { showErrorToast, showSuccessToast } from 'shared/helpers/notifications';
import { getAgentByNameOrSfId } from 'shared/sagas/getAgentByNameOrSfId';
import { getProfileData } from 'shared/selectors/profileSelector';
import {
  AuthService,
  CognitoUser,
  firstNameAttributeName,
  lastNameAttributeName,
  pictureAttributeName,
  roleAttributeName,
  usernameAttributeName,
} from 'shared/services/AuthService';
import { GoogleApiService } from 'shared/services/GoogleApiService';
import history from 'shared/services/history';
import { CognitoFederatedProviders, UnpackReturnedPromise } from 'shared/types';
import { GoogleMapApiType } from 'shared/types/placesAndPolygons';
import { parsePhotoUrl } from 'user/helpers/userHelpers';
import {
  AskAgentQuestionAction,
  changePasswordError,
  changePasswordSuccess,
  clearRecommendedAgent,
  ContactUsAction,
  createOrUpdateProfileError,
  createOrUpdateProfileSuccess,
  EmailNotificationsSubscribeAction,
  emailNotificationsSubscriptionFail,
  emailNotificationsSubscriptionSuccess,
  EmailNotificationsUnsubscribeAction,
  federatedConnectFail,
  FederatedDisconnectAction,
  federatedDisconnectFail,
  federatedDisconnectSuccess,
  fetchDefaultAgent,
  fetchDefaultAgentFail,
  fetchDefaultAgentSuccess,
  fetchNotificationCountsSuccess,
  fetchNotificationInboxSuccessCount,
  fetchProfileError,
  fetchProfileSuccess,
  forgotPasswordError,
  forgotPasswordSuccess,
  getUserAgentError,
  getUserAgentSuccess,
  getUserSessionError,
  removeSocialPhotoURLFailed,
  removeSocialPhotoURLSuccess,
  RequestFreeWhitepaperAction,
  RequestRelocationGuideAction,
  requestRelocationGuideFail,
  requestRelocationGuideSuccess,
  RequestSpecialistAction,
  resetPasswordError,
  resetPasswordSuccess,
  sendVerificationEmail,
  SendVerificationEmailAction,
  sendVerificationEmailFail,
  sendVerificationEmailSuccess,
  setFederatedUserProfile,
  SetLinkDataAction,
  setPasswordError,
  setPasswordSuccess,
  setRecommendedAgent,
  SetSocialPhotoURLAction,
  setSocialPhotoURLFailed,
  setSocialPhotoURLSuccess,
  signOutError,
  signOutSuccess,
  StartCreateProfileAction,
  StartForgotPasswordAction,
  StartUpdateProfileAction,
  StartUserSignUpAction,
  UserActionType,
  userFederatedLogIn,
  userFederatedLogInError,
  UserFederatedLogInStartAction,
  userFederatedLogInSuccess,
  userIsLogged,
  userLoginError,
  userSignUpEmailVerificationError,
  userSignUpError,
  userSignUpUsernameExists,
  verifyEmailFail,
  verifyEmailSuccess,
} from 'user/userActions';
import {
  OAUTH_LOADING,
  P_T_N_LINK,
  UserEmailValidationError,
} from 'user/userConstants';
import { getInquiryTrackingInfo, ptnLinkStorage } from 'user/userHelpers';
import {
  getIsInitialized,
  getUser,
  getUserRole,
  isAuthorised,
} from 'user/userSelectors';
import { UserService } from 'user/UserService';
import {
  FavoritePlace,
  UserProfile,
  UserRole,
  UserState,
} from 'user/userTypes';

export function* getCurrentSessionSaga() {
  try {
    const loginAsAgent = Boolean(sessionStorage.getItem(LOGIN_AS_AGENT));
    const agentLogInRedirect = localStorage.getItem(AGENT_LOGIN_REDIRECT);
    const federatedUser = localStorage.getItem(FEDERATED_USER);

    if (agentLogInRedirect) {
      localStorage.removeItem(AGENT_LOGIN_REDIRECT);
      yield put(userFederatedLogIn(CognitoFederatedProviders.Google));
    }

    const cognitoUser: UnpackReturnedPromise<
      typeof AuthService.getCurrentAuthenticatedUser
    > = yield call(AuthService.getCurrentAuthenticatedUser);
    const { username, attributes } = cognitoUser;

    if (federatedUser) {
      yield call(federatedConnectSaga, federatedUser);
    }

    const federatedProviderType =
      AuthService.getFederatedProviderName(attributes);

    if (federatedProviderType) {
      const firstName = attributes[firstNameAttributeName];
      const lastName = attributes[lastNameAttributeName];
      const SocialProfilePhotoURL = attributes[pictureAttributeName];
      const provider = federatedProviderType;
      yield put(
        setFederatedUserProfile({
          firstName,
          lastName,
          provider,
          SocialProfilePhotoURL:
            provider === 'Google' ? SocialProfilePhotoURL : '',
        })
      );
    }

    const role: UserRole = yield call(fetchUserRoleSaga, attributes?.email);

    const isAgent = role === UserRole.Agent;
    const isClient = role === UserRole.Client;

    if ((isClient || role === undefined) && loginAsAgent) {
      history.push(RoutePath.FORBIDDEN);
      showErrorToast('Cannot login as agent', {
        onClose: () => {
          sessionStorage.removeItem(LOGIN_AS_AGENT);
          AuthService.signOut();
        },
      });
      throw new Error('Cannot login as agent');
    }

    if (isAgent) {
      yield call(setCognitoUserRoleSaga, cognitoUser, true);
    }

    if (username && role) {
      const profile: UserProfile = yield call(fetchProfileSaga);
      if (profile) {
        yield call(setCognitoAttributesSaga, profile, cognitoUser);

        const verificationCode = localStorage.getItem(TEMPORARY_PASSWORD);
        if (verificationCode) {
          // that required for correct working Set Password route
          // @TODO find better solution
          yield put(userIsLogged(true, role));
          history.push(
            `${RoutePath.SET_PASSWORD}/?email=${profile.email}&code=${verificationCode}`
          );
        }
        if (
          profile.emailVerified ||
          federatedProviderType ||
          role === UserRole.Agent
        ) {
          yield put(changeLoginStatus(PermissionRoles.VerifiedUser));
        } else {
          yield put(changeLoginStatus(PermissionRoles.NotVerifiedUser));
        }

        // Setting up User ID for GA and user role to be tracked with GA
        ReactGA.set({
          userId: profile.username,
          ...(profile.role === UserRole.Client
            ? {
                dimension1: 'Client',
              }
            : {
                dimension3: 'Agent',
              }),
        });
      }
    }

    yield put(userIsLogged(true, role));
    yield put(userFederatedLogInSuccess());
    localStorage.removeItem(OAUTH_LOADING);
  } catch (e) {
    const query = queryString.parse(history.location.search);

    if (query.agentId) {
      localStorage.setItem(DEFAULT_AGENT, query.agentId as string);
    }

    yield put(changeLoginStatus(PermissionRoles.Guest));
    yield put(getUserSessionError(e.message));
    localStorage.removeItem(OAUTH_LOADING);

    // Setting up user role to be tracked with GA
    ReactGA.set({ dimension2: 'Guest' });
  }
}

export function* federatedLogInSaga({
  federatedProvider,
  isAgent = false,
}: UserFederatedLogInStartAction) {
  try {
    yield call(AuthService.signOut);
    const { Google, Facebook } = CognitoFederatedProviders;
    const providers: { [key in CognitoFederatedProviders]: () => void } = {
      [Google]: AuthService.loginGoogle,
      [Facebook]: AuthService.loginFacebook,
    };

    if (!isAgent) {
      sessionStorage.removeItem(LOGIN_AS_AGENT);
    }

    if (history.location.pathname !== RoutePath.HOME) {
      localStorage.setItem(
        USER_PREVIOUS_LOCATION,
        history.location.pathname + history.location.search
      );
      localStorage.removeItem(USER_PREVIOUS_USERNAME);
    }
    localStorage.setItem(OAUTH_LOADING, 'true');

    yield call(providers[federatedProvider]);

    TagManager.dataLayer({
      dataLayer: {
        event: GTM_CUSTOM_EVENTS_NAMES.REGISTRATION_OR_LOGGING_IN,
        authentication_method: federatedProvider,
        authentication_action: 'LogIn: Successful',
      },
      dataLayerName: 'PageDataLayer',
    });
  } catch (error) {
    yield put(userFederatedLogInError(error.message));
    TagManager.dataLayer({
      dataLayer: {
        event: GTM_CUSTOM_EVENTS_NAMES.REGISTRATION_OR_LOGGING_IN,
        authentication_method: federatedProvider,
        authentication_action: 'LogIn: Failed',
        authentication_error: error.message,
      },
      dataLayerName: 'PageDataLayer',
    });
  }
}

export function* logInSaga(): Saga {
  try {
    const { email, password, temporaryPassword }: UserState = yield select(
      getUser
    );
    const role: UserRole = yield call(fetchUserRoleSaga, email);
    const isAgent = role === UserRole.Agent;

    if (AuthService.isAgentEmail(email) || isAgent) {
      yield put(userFederatedLogIn(CognitoFederatedProviders.Google));
      return;
    } else {
      sessionStorage.removeItem(LOGIN_AS_AGENT);
    }

    temporaryPassword
      ? yield call(AuthService.signIn, email, temporaryPassword)
      : yield call(AuthService.signIn, email, password);

    yield call(UserService.trackUserPageHistory, {
      url: '/',
      title: 'LogIn Page',
    });

    TagManager.dataLayer({
      dataLayer: {
        event: GTM_CUSTOM_EVENTS_NAMES.REGISTRATION_OR_LOGGING_IN,
        authentication_method: 'Email',
        authentication_action: 'LogIn: Successful',
      },
      dataLayerName: 'PageDataLayer',
    });
  } catch ({ code, message }) {
    yield put(userLoginError(`${message} ${code}`)); // helper format

    TagManager.dataLayer({
      dataLayer: {
        event: GTM_CUSTOM_EVENTS_NAMES.REGISTRATION_OR_LOGGING_IN,
        authentication_method: 'Email',
        authentication_action: 'LogIn: Failed',
        authentication_error: message,
      },
      dataLayerName: 'PageDataLayer',
    });

    if (
      getErrorType(`${message} ${code}`) === AuthErrorType.UnrecognizedError
    ) {
      showErrorToast(`Something went wrong: ${message}`);
    }
  }
}

function* signUpSagaWrapper(action: StartUserSignUpAction) {
  yield race({
    task: call<any>(signUpSaga, action),
    cancelSignUp: take(UserActionType.RESET_USER_SIGN_UP),
  });
}

export function* getFavoritePlaceWithAddress(
  place: FavoritePlace,
  maps: GoogleMapApiType
) {
  try {
    const {
      results,
    }: UnpackReturnedPromise<typeof GoogleApiService.getTitleFromLatLng> =
      yield call(
        GoogleApiService.getTitleFromLatLng,
        place.latitude,
        place.longitude,
        maps
      );

    return { ...place, placeAddress: results[0].formatted_address };
  } catch (e) {
    console.warn(e);
  }
}

export function* signUpSaga() {
  try {
    const { email }: UserState = yield select(getUser);
    const role: UserRole = yield call(fetchUserRoleSaga, email);
    const isAgent = role === UserRole.Agent;

    if (AuthService.isAgentEmail(email) || isAgent) {
      yield put(userFederatedLogIn(CognitoFederatedProviders.Google));
      return;
    } else {
      sessionStorage.removeItem(LOGIN_AS_AGENT);
    }

    const password = generatePassword();
    localStorage.setItem(TEMPORARY_PASSWORD, password);
    yield call(AuthService.signUp, email, password);
    yield call(AuthService.signIn, email, password);
  } catch (error) {
    if (error.name === UserEmailValidationError.EmailExistError) {
      yield put(userSignUpUsernameExists());
    } else if (error.name === UserEmailValidationError.EmailVerificationError) {
      yield put(userSignUpEmailVerificationError());
    } else {
      showErrorToast(`Something went wrong: ${error.message}`);
      yield put(userSignUpError(error.message));
    }

    localStorage.removeItem(TEMPORARY_PASSWORD);
  }
}

export function* createOrUpdateProfileSaga(
  action: StartUpdateProfileAction | StartCreateProfileAction
) {
  let federatedProviderType = '';

  try {
    const { data, onSuccess } = action.body;
    const userProfile: UserProfile = yield select(getProfileData);
    let cognitoUser: UnpackReturnedPromise<
      typeof AuthService.getCurrentAuthenticatedUser
    > = yield call(AuthService.getCurrentAuthenticatedUser);
    const isAgent = userProfile?.role === UserRole.Agent;
    const { attributes } = cognitoUser;
    federatedProviderType = AuthService.getFederatedProviderName(attributes);
    const role = AuthService.getCognitoUserRole(attributes, isAgent);

    const cognitoEmail = cognitoUser.attributes.email;
    const { email } = data;
    if (email && cognitoEmail !== email) {
      cognitoUser = yield call(
        AuthService.updateCurrentUserAttributes,
        cognitoUser,
        { email }
      );
    }
    const isCreateProfile = action.type === UserActionType.START_CREATE_PROFILE;
    if (isCreateProfile) {
      if (federatedProviderType === 'Facebook') {
        data.facebookAccount = cognitoUser.username;
      } else if (federatedProviderType === 'Google') {
        data.googleAccount = cognitoUser.username;
      }
    }

    type Profile =
      | UnpackReturnedPromise<typeof UserService.updateProfile>
      | UnpackReturnedPromise<typeof UserService.createProfile>;
    const profile: Profile = isCreateProfile
      ? yield call(UserService.createProfile, data)
      : yield call(UserService.updateProfile, data, userProfile.username);

    let urlImage = '';
    if (isCreateProfile) {
      if (federatedProviderType === 'Facebook') {
        const facebookID = cognitoUser.username.split('_')[1];
        const facebookPhotoURL =
          'https://graph.facebook.com/' + facebookID + '/picture?type=normal';
        urlImage = 'Facebook_' + facebookPhotoURL;
        data.facebookAccount = cognitoUser.username;
      } else if (federatedProviderType === 'Google') {
        urlImage = 'Google_' + cognitoUser.attributes.picture;
        data.googleAccount = cognitoUser.username;
      }

      const body = {
        userSocialPhoto_URL: urlImage,
      };
      yield call(UserService.updateProfile, body, profile.username);
    }

    if (profile) {
      yield call(setCognitoAttributesSaga, profile, cognitoUser);
      yield put(clearFromLinkStatus());
      yield put(clearRecommendedAgent());
      // Should be fired after "CREATE_OR_UPDATE_PROFILE_SUCCESS"
      if (
        profile.emailVerified ||
        federatedProviderType ||
        role === UserRole.Agent
      ) {
        yield put(changeLoginStatus(PermissionRoles.VerifiedUser));
      } else {
        yield put(changeLoginStatus(PermissionRoles.NotVerifiedUser));
      }
    }
    onSuccess?.();
    const successMessage = isCreateProfile
      ? federatedProviderType
        ? 'Welcome!'
        : 'Verification link has been sent to your email account'
      : 'Changes saved';
    if (isCreateProfile) yield put(setSocialPhotoURLSuccess(urlImage));

    if (
      action.type === UserActionType.START_CREATE_PROFILE ||
      !action.silentUpdate
    ) {
      yield call(UserService.trackUserPageHistory, {
        url: '/',
        title: 'LogIn Page',
      });
      showSuccessToast(successMessage, {
        toastDomId: isCreateProfile
          ? GTM_TOASTERS_IDS.NEW_LEAD_REGISTRATION
          : '',
      });
      TagManager.dataLayer({
        dataLayer: {
          event: GTM_CUSTOM_EVENTS_NAMES.REGISTRATION_OR_LOGGING_IN,
          authentication_method: federatedProviderType || 'Email',
          authentication_action: 'Registration: Successful',
        },
        dataLayerName: 'PageDataLayer',
      });
    }
  } catch (e) {
    const { onError } = action.body;
    onError?.(
      e.validationErrors ? mapServerValidationErrors(e.validationErrors) : null
    );

    if (not(e.validationErrors)) {
      showErrorToast(`Something went wrong: ${e.message}`);

      TagManager.dataLayer({
        dataLayer: {
          event: GTM_CUSTOM_EVENTS_NAMES.REGISTRATION_OR_LOGGING_IN,
          authentication_method: federatedProviderType || 'Email',
          authentication_action: 'Registration: Failed',
          authentication_error: e.message,
        },
        dataLayerName: 'PageDataLayer',
      });
    }

    yield put(createOrUpdateProfileError(e.message));
  }
}

function* setCognitoAttributesSaga(
  profile: UserProfile,
  cognitoUser: CognitoUser
) {
  // user service stored email has more priority and Cognito should be updated
  const profileEmail = profile && profile.email;
  if (
    profileEmail &&
    cognitoUser &&
    cognitoUser.attributes['email'] !== profileEmail
  ) {
    yield call(AuthService.updateCurrentUserAttributes, cognitoUser, {
      email: profileEmail,
    });
  }
  // set up Cognito username for previously synced users
  if (
    cognitoUser &&
    cognitoUser.attributes[usernameAttributeName] !== profile.username
  ) {
    yield call(AuthService.updateCurrentUserAttributes, cognitoUser, {
      [usernameAttributeName]: profile.username,
    });
  }
  // close second step modal if profile found
  yield put(createOrUpdateProfileSuccess(profile));
}

function* setCognitoUserRoleSaga(cognitoUser: CognitoUser, isAgent: boolean) {
  // set up Cognito role for previously synced users
  if (cognitoUser && isAgent) {
    yield call(AuthService.updateCurrentUserAttributes, cognitoUser, {
      [roleAttributeName]: 'agent',
    });
  } else {
    yield call(AuthService.updateCurrentUserAttributes, cognitoUser, {
      [roleAttributeName]: '',
    });
  }
}

export function* fetchProfileSaga() {
  try {
    const profile: UnpackReturnedPromise<typeof UserService.getProfile> =
      yield call(UserService.getProfile);

    localStorage.removeItem(P_T_N_LINK);
    sessionStorage.removeItem(P_T_N_LINK);

    yield put(fetchProfileSuccess(parsePhotoUrl(profile)));
    yield put(clearFromLinkStatus());
    yield put(clearRecommendedAgent());
    return profile;
  } catch (e) {
    yield put(fetchProfileError(e.message));
  }
}

export function* signOutSaga() {
  try {
    const profile: UserProfile = yield select(getProfileData);
    localStorage.removeItem(DEFAULT_AGENT);
    localStorage.removeItem(OAUTH_LOADING);
    localStorage.removeItem(TEMPORARY_PASSWORD);
    sessionStorage.removeItem(LOGIN_AS_AGENT);
    localStorage.setItem(
      USER_PREVIOUS_LOCATION,
      history.location.pathname + history.location.search
    );
    localStorage.setItem(USER_PREVIOUS_USERNAME, profile?.username);
    yield call(AuthService.signOut);
    yield put(userIsLogged(false));
    yield put(signOutSuccess());
  } catch (e) {
    yield put(signOutError(e.message));
  }
}

export function* forgotPasswordSaga(action: StartForgotPasswordAction) {
  try {
    yield call(AuthService.forgotPassword, action.email);
    yield put(forgotPasswordSuccess());

    if (action.isResend) {
      showSuccessToast('Email sent');
    }
  } catch (e) {
    yield put(forgotPasswordError(e.message));
  }
}

export function* changePasswordSaga() {
  try {
    const user: UserState = yield select(getUser);

    const email = user.email || user.profile.data.email;

    yield call(AuthService.forgotPassword, email);
    yield put(changePasswordSuccess());
    showSuccessToast(
      `We've sent an email to ${email} with a link to reset your password`
    );
  } catch (e) {
    yield put(changePasswordError(e.message));
    showErrorToast(
      "We've sent an email with a link to reset your password already"
    );
  }
}

export function* resetPasswordSaga() {
  try {
    const { email, code, password }: UserState = yield select(getUser);

    yield call(AuthService.resetPassword, email, code, password);
    yield call(logInSaga);
    yield put(resetPasswordSuccess());
    showSuccessToast('Password has been updated successfully');
  } catch (e) {
    yield put(resetPasswordError(e.message));
  }
}

export function* setPasswordSaga() {
  try {
    const { temporaryPassword, password, isLogged }: UserState = yield select(
      getUser
    );
    if (!isLogged) yield call(logInSaga);
    yield call(AuthService.setPassword, temporaryPassword, password);

    // if user just sign up, temporary password still in storage
    const code = localStorage.getItem(TEMPORARY_PASSWORD);
    if (code) {
      // send welcome email without Set Password Link
      yield put(sendVerificationEmail());
      localStorage.removeItem(TEMPORARY_PASSWORD);
    }

    yield put(setPasswordSuccess());
    showSuccessToast('Password has been set successfully');
  } catch (e) {
    yield put(setPasswordError(e.message));
  }
}

export function* verifyEmailSaga() {
  try {
    const profile: UserProfile = yield select(getProfileData);
    const emailVerified = get(profile, 'emailVerified');
    if (emailVerified) {
      return;
    }
    yield call(UserService.verifyEmail);
    yield put(changeLoginStatus(PermissionRoles.VerifiedUser));
    yield put(verifyEmailSuccess());
  } catch (e) {
    yield put(verifyEmailFail());
  }
}

export function* sendVerificationEmailSaga(
  action: SendVerificationEmailAction
) {
  try {
    yield call(UserService.sendVerificationEmail, {
      ...getInquiryTrackingInfo(),
      code: action.code,
    });
    yield put(sendVerificationEmailSuccess());
  } catch (e) {
    yield put(sendVerificationEmailFail(e));
    showErrorToast('Verification email has not been sent');
  }
}

export function* waitForProfile(): Saga {
  const profile = yield select(getProfileData);
  const init = yield select(getIsInitialized);

  if (isEmpty(profile) && !init) {
    yield take([
      UserActionType.FETCH_PROFILE_SUCCESS,
      UserActionType.FETCH_PROFILE_ERROR,
      UserActionType.USER_IS_LOGGED,
    ]);
  }
  return yield select(getProfileData);
}

export function* getUserAgentSaga() {
  try {
    const profile: UserProfile = yield call(waitForProfile);
    const agentId = profile ? profile.agentId : null;

    if (!agentId) {
      throw new Error("User doesn't have agent");
    }

    const agent: UnpackReturnedPromise<typeof AgentsService.fetchAgent> =
      yield call(AgentsService.fetchAgent, {
        agentId,
        redirectToNotFound: false,
      });

    yield put(getUserAgentSuccess(agent));
  } catch (e) {
    yield put(getUserAgentError(e.message));
  }
}

export function* askQuestionSaga(action: AskAgentQuestionAction) {
  try {
    const { data, onSuccess, agentId } = action.body;

    yield call(UserService.inquiry, {
      ...getInquiryTrackingInfo(),
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      message: data.message,
      phone: data.phone,
      agentId,
      type: TypesOfInquiryHistory.AskAQuestion,
    });

    onSuccess();

    showSuccessToast('Message to the agent sent');
  } catch (error) {
    if (error?.validationErrors) {
      action.body.onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
  }

  action.body.cb();
}

export function* agentLogInSaga(): Saga {
  try {
    const user = yield select(getUser);
    const isAuthorised = !!(user.isLogged && user.profile.data);
    sessionStorage.setItem(LOGIN_AS_AGENT, 'true');

    if (isAuthorised) {
      localStorage.setItem(AGENT_LOGIN_REDIRECT, 'true');
      yield call(AuthService.signOut);
    } else {
      yield put(userFederatedLogIn(CognitoFederatedProviders.Google, true));
    }
  } catch ({ message, code }) {
    yield put(userLoginError(`${message} ${code}`)); // helper format
  }
}

export function* requestSpecialistSaga(action: RequestSpecialistAction) {
  try {
    const { data } = action.body;

    const agent: Agent = yield call(getAgentByNameOrSfId);

    yield call(UserService.inquiry, {
      ...getInquiryTrackingInfo(),
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      message: data.message,
      phone: data.phone,
      agentId: agent?.username,
      type: TypesOfInquiryHistory.RequestASpecialist,
    });

    action.body.onSuccess();

    showSuccessToast('Request successfully sent');
  } catch (error) {
    if (error?.validationErrors) {
      action.body.onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
  }

  action.body.cb();
}

export function* federatedConnectSaga(federatedUser: string) {
  try {
    yield call(UserService.cognitoFederatedConnect, federatedUser);
    yield call(AuthService.refreshSession);
    localStorage.removeItem(FEDERATED_USER);
  } catch (e) {
    yield put(federatedConnectFail(e));
  }
}

export function* federatedDisconnectSaga(action: FederatedDisconnectAction) {
  try {
    const provider = action.provider;
    yield call(UserService.cognitoFederatedDisconnect, provider);
    yield put(federatedDisconnectSuccess(provider));
  } catch (e) {
    yield put(federatedDisconnectFail(e));
  }
}

export function* emailNotificationsSubscriptionSaga(
  action:
    | EmailNotificationsSubscribeAction
    | EmailNotificationsUnsubscribeAction
) {
  const { type, hash } = action;

  try {
    if (type === UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIBE) {
      yield UserService.emailNotificationsSubscribe(hash);
    } else {
      yield UserService.emailNotificationsUnsubscribe(hash);
    }

    yield put(emailNotificationsSubscriptionSuccess());
  } catch (e) {
    yield put(emailNotificationsSubscriptionFail(e.message));
    history.push(RoutePath.HOME);
    showErrorToast(
      'There was a problem processing your request. You can always manage your email notifications from the Account Settings page'
    );
  }
}

const emailNotificationsSubscriptionSub = () =>
  takeLatest(
    [
      UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIBE,
      UserActionType.EMAIL_NOTIFICATIONS_UNSUBSCRIBE,
    ],
    emailNotificationsSubscriptionSaga
  );

export function* requestRelocationGuideSaga(
  action: RequestRelocationGuideAction
) {
  try {
    const agent: Agent = yield call(getAgentByNameOrSfId);
    const { data, onSuccess } = action.body;

    yield call(UserService.inquiry, {
      ...data,
      ...getInquiryTrackingInfo(),
      type: TypesOfInquiryHistory.RelocationGuide,
      agentId: agent?.username,
    });

    onSuccess();

    yield put(requestRelocationGuideSuccess());
    showSuccessToast(
      'Your Relocation Guide has been ordered and will be delivered in 7-14 days'
    );
  } catch (error) {
    if (error?.validationErrors) {
      action.body.onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
    yield put(requestRelocationGuideFail(error));
  }

  action.body.cb();
}

export function* contactUsSaga(action: ContactUsAction): Saga {
  try {
    const { data, onSuccess, inquiryType } = action.body;
    let agent;
    let toastMessage = 'Request successfully sent';
    if (inquiryType === TypesOfInquiryHistory.RequestAnInterview) {
      toastMessage =
        "Your request has been successfully sent. We'll be in touch soon";
      const response: {
        data: Agent;
        description: string;
      } = yield call(AgentsService.fetchInterviewerAgent);
      agent = response.data;
    } else {
      agent = yield call(getAgentByNameOrSfId);
    }

    yield call(UserService.inquiry, {
      ...getInquiryTrackingInfo(),
      ...data,
      type: inquiryType,
      agentId: agent?.username,
    });

    onSuccess();
    showSuccessToast(toastMessage);
  } catch (error) {
    const { onError } = action.body;
    if (error?.validationErrors) {
      onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
  }

  action.body.cb();
}

export function* fetchDefaultAgentSaga(): Saga {
  try {
    const userRole = yield select(getUserRole);
    if (userRole === UserRole.Agent || UserRole.Client) {
      yield put(fetchDefaultAgent());
      const defaultAgent: UnpackReturnedPromise<
        typeof AgentsService.fetchDefaultAgent
      > = yield call(AgentsService.fetchDefaultAgent);
      yield put(fetchDefaultAgentSuccess(defaultAgent));
    }
  } catch (e) {
    yield put(fetchDefaultAgentFail(e.message));
  }
}

export function* getLinkDataFromLocalStorageSaga(
  action?: SetLinkDataAction
): Saga {
  try {
    let ptLink = ptnLinkStorage.deserialize(localStorage.getItem(P_T_N_LINK));
    const nearbyLink = ptnLinkStorage.deserialize(
      sessionStorage.getItem(P_T_N_LINK)
    );

    if (getIsDateInPast(ptLink.ptLinkExpirationDate)) {
      localStorage.removeItem(P_T_N_LINK);
      ptLink = null;
    }

    const recommendedAgent =
      ptLink.recommendedAgent || action?.payload.recommendedAgent;
    const linkType =
      ptLink.ptLinkType || nearbyLink.ptLinkType || action?.payload.linkType;

    if (isEmpty(ptLink) && isEmpty(nearbyLink) && action?.payload.linkType) {
      if (linkType === FromLinkStatus.Nearby) {
        sessionStorage.setItem(
          P_T_N_LINK,
          ptnLinkStorage.serialize({
            ptLinkType: action.payload.linkType,
          })
        );
      } else {
        localStorage.setItem(
          P_T_N_LINK,
          ptnLinkStorage.serialize({
            recommendedAgent,
            ptLink: window.location.href,
            ptLinkType: linkType,
            ptLinkExpirationDate: dayjs().add(7, 'day').format(),
          })
        );
      }
    }

    if (recommendedAgent) {
      yield put(setRecommendedAgent(recommendedAgent));
    }
    if (linkType) {
      yield put(changeFromLinkStatus(linkType));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* fetchCountersSaga(): Saga {
  try {
    const userRole = yield select(getUserRole);
    const userIsAuthorised = yield select(isAuthorised);

    let notificationHistoryCount: UnpackReturnedPromise<
      typeof NotificationHistoryService.getNotificationHistoryCount
    >;
    let savedSearchInvitationsCount: UnpackReturnedPromise<
      typeof SavedSearchService.fetchSavedSearchInvitationsCount
    >;
    let collectionsInvitationsCount: UnpackReturnedPromise<
      typeof CollectionService.fetchCollectionsInvitationsCount
    >;

    if (not(userIsAuthorised)) {
      return;
    }

    if (userRole === UserRole.Agent) {
      notificationHistoryCount = yield call(
        NotificationHistoryService.getNotificationHistoryCount,
        NOT_OPENED_NOTIFICATION_HISTORY_STATUSES
      );
    } else {
      [
        notificationHistoryCount,
        savedSearchInvitationsCount,
        collectionsInvitationsCount,
      ] = yield all([
        call(
          NotificationHistoryService.getNotificationHistoryCount,
          NOT_OPENED_NOTIFICATION_HISTORY_STATUSES
        ),
        call(SavedSearchService.fetchSavedSearchInvitationsCount),
        call(CollectionService.fetchCollectionsInvitationsCount),
      ]);
    }

    yield put(
      fetchNotificationCountsSuccess({
        notificationHistoryCount: notificationHistoryCount?.total,
        savedSearchInvitationsCount: savedSearchInvitationsCount?.count,
        collectionsInvitationsCount: collectionsInvitationsCount?.count,
      })
    );
  } catch (e) {
    console.warn(e?.message);
  }
}

function* fetchNotificationInboxCountSaga(): Saga {
  try {
    const notificationHistoryCount = yield call(
      NotificationHistoryService.getNotificationHistoryCount,
      NOT_OPENED_NOTIFICATION_HISTORY_STATUSES
    );

    yield put(
      fetchNotificationInboxSuccessCount(notificationHistoryCount.total)
    );
  } catch (e) {
    console.warn(e?.message);
  }
}

export function* requestFreeWhitepaperSaga(
  action: RequestFreeWhitepaperAction
) {
  try {
    const agent: Agent = yield call(getAgentByNameOrSfId);
    const { data, onSuccess } = action.body;

    yield call(UserService.inquiry, {
      ...data,
      ...getInquiryTrackingInfo(),
      type: TypesOfInquiryHistory.RequestFreeWhitepaper,
      agentId: agent?.username,
    });

    onSuccess();

    showSuccessToast(
      'Thank you - please check your email\n inbox for your free Whitepaper',
      {
        onViewClick: () => history.push(RoutePath.NOTIFICATION_INBOX),
      }
    );
  } catch (error) {
    if (error?.validationErrors) {
      action.body.onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(
        'Requests for Free Whitepaper are not allowed from Agent accounts'
      );
    }
  }

  action.body.cb();
}

export function* createSocialPhotoURLSaga({
  socialPhotoURL,
}: SetSocialPhotoURLAction) {
  try {
    yield put(setSocialPhotoURLSuccess(socialPhotoURL));
  } catch (e) {
    yield put(setSocialPhotoURLFailed(e.message));
  }
}
export function* deleteSocialPhotoURLSaga() {
  try {
    yield call(UserService.removeSocialPhotoURL);
    yield put(removeSocialPhotoURLSuccess());
  } catch (e) {
    yield put(removeSocialPhotoURLFailed(e.message));
  }
}

export function* fetchUserRoleSaga(email: string) {
  try {
    const role: UnpackReturnedPromise<typeof UserService.getUserRoleByEmail> =
      yield call(UserService.getUserRoleByEmail, email);
    return role;
  } catch (e) {
    console.warn(e?.message);
  }
}

const agentLogInSub = () =>
  takeLatest(UserActionType.AGENT_LOGIN, agentLogInSaga);

const getCurrentUserSessionSub = () =>
  takeLatest(UserActionType.GET_USER_SESSION, function* () {
    yield* getCurrentSessionSaga();
    yield* fetchDefaultAgentSaga();
    yield* fetchCountersSaga();
    yield* getLinkDataFromLocalStorageSaga();
  });

const userFederatedLogInSub = () =>
  takeLatest(UserActionType.USER_FEDERATED_LOG_IN, federatedLogInSaga);

const logInSub = () => takeLatest(UserActionType.START_USER_LOGIN, logInSaga);

const signOutSub = () => takeLatest(UserActionType.START_SIGN_OUT, signOutSaga);

const signUpSub = () =>
  takeLatest([UserActionType.START_USER_SIGN_UP], signUpSagaWrapper);

const createOrUpdateProfileSub = () =>
  takeLatest(
    [UserActionType.START_UPDATE_PROFILE, UserActionType.START_CREATE_PROFILE],
    createOrUpdateProfileSaga
  );

const forgotPasswordSub = () =>
  takeLatest(UserActionType.START_FORGOT_PASSWORD, forgotPasswordSaga);

const resetPasswordSub = () =>
  takeLatest(UserActionType.START_RESET_PASSWORD, resetPasswordSaga);

const setPasswordSub = () =>
  takeLatest(UserActionType.START_SET_PASSWORD, setPasswordSaga);

const changePasswordSub = () =>
  takeLatest(UserActionType.START_CHANGE_PASSWORD, changePasswordSaga);

const getUserAgentSub = () =>
  takeLatest(UserActionType.GET_USER_AGENT, getUserAgentSaga);

const askQuestionSub = () =>
  takeLatest(UserActionType.ASK_AGENT_QUESTION, askQuestionSaga);

const requestSpecialistSub = () =>
  takeLatest(UserActionType.REQUEST_SPECIALIST, requestSpecialistSaga);

const verifyEmailSub = () =>
  takeLatest(
    [
      UserActionType.SET_PASSWORD_SUCCESS,
      UserActionType.RESET_PASSWORD_SUCCESS,
    ],
    verifyEmailSaga
  );

const sendVerifyEmailSub = () =>
  takeLatest(UserActionType.SEND_VERIFICATION_EMAIL, sendVerificationEmailSaga);

const federatedDisconnectSub = () =>
  takeLatest(UserActionType.FEDERATED_DISCONNECT, federatedDisconnectSaga);

const requestRelocationGuideSub = () =>
  takeLatest(
    UserActionType.REQUEST_RELOCATION_GUIDE,
    requestRelocationGuideSaga
  );

const contactUsSub = () => takeLatest(UserActionType.CONTACT_US, contactUsSaga);

const fetchProfileSub = () =>
  takeLatest(UserActionType.FETCH_PROFILE, fetchProfileSaga);

const fetchNotificationInboxCountSub = () =>
  takeLatest(
    UserActionType.FETCH_NOTIFICATION_INBOX_COUNT,
    fetchNotificationInboxCountSaga
  );

const requestFreeWhitepaperSub = () =>
  takeLatest(UserActionType.REQUEST_FREE_WHITEPAPER, requestFreeWhitepaperSaga);

const getPtLinkDataFromLocalStorageSub = () =>
  takeLatest(UserActionType.SET_LINK_DATA, getLinkDataFromLocalStorageSaga);

const createSocialPhotoURL = () =>
  takeLatest(UserActionType.SET_SOCIAL_PHOTO_URL, createSocialPhotoURLSaga);

const deleteSocialPhotoURL = () =>
  takeLatest(UserActionType.REMOVE_SOCIAL_PHOTO_URL, deleteSocialPhotoURLSaga);

export function* userSagas() {
  yield all([
    agentLogInSub(),
    askQuestionSub(),
    changePasswordSub(),
    contactUsSub(),
    createOrUpdateProfileSub(),
    emailNotificationsSubscriptionSub(),
    federatedDisconnectSub(),
    forgotPasswordSub(),
    getCurrentUserSessionSub(),
    getUserAgentSub(),
    logInSub(),
    requestRelocationGuideSub(),
    requestSpecialistSub(),
    resetPasswordSub(),
    sendVerifyEmailSub(),
    setPasswordSub(),
    signOutSub(),
    signUpSub(),
    userFederatedLogInSub(),
    verifyEmailSub(),
    fetchProfileSub(),
    fetchNotificationInboxCountSub(),
    requestFreeWhitepaperSub(),
    getPtLinkDataFromLocalStorageSub(),
    createSocialPhotoURL(),
    deleteSocialPhotoURL(),
  ]);
}
