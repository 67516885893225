import { useField } from 'formik';
import styled, { css } from 'styled-components/macro';

import {
  FILTER_CONTAINER_WIDTH,
  SELECT_MAX,
  SELECT_MIN,
} from 'filters/filtersContants';
import {
  filterMinMax,
  getTitleValue,
  maxYears,
  minYears,
  validateMinMax,
  validateSelectInput,
  years,
} from 'filters/filtersHelpers';
import { $getHomeFeaturesChanged } from 'filters/filtersSelectors';
import {
  CheckboxWrapper,
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  FilterShowMore,
  FiltersRadioContainer,
  FiltersRadioTitle,
  FiltersTitleValue,
} from 'filters/filtersStyled';
import { RadioButtonSettings, SharedFiltersProps } from 'filters/filtersTypes';
import { CheckboxField } from 'shared/components/formikFields/CheckboxField';
import { InputSelectField } from 'shared/components/formikFields/InputSelectField';
import { RadioGroupItem } from 'shared/components/formikFields/RadioField';
import { useSelector } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';
import { media } from 'styled-system/responsive';

const Container = styled.div`
  width: ${FILTER_CONTAINER_WIDTH}px;
  max-width: 100%;
`;

const ItemContainer = styled.div`
  margin-bottom: 24px;
`;

const TitleHeader = styled.div`
  width: 100%;
  font-family: Lato, sans-serif;
  font-size: 16px;
  margin-bottom: 16px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-right: 8px;
`;

const SelectContainer = styled.div`
  display: flex;
  ${media.sm(css`
    justify-content: space-between;
    align-items: center;
  `)}
`;

const SelectWrapper = styled.div`
  width: 120px;
`;

const ToContainer = styled.span`
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  padding: 8px 20px 0 20px;
  ${media.sm(css`
    padding: 0;
  `)};
`;

export const petsAllowedRadio: RadioButtonSettings[] = [
  { title: 'Any', value: 'Any' },
  { title: 'Yes', value: 'Yes' },
  { title: 'No', value: 'No' },
];

type HomeFeaturesFilterProps = Pick<SharedFiltersProps, 'disableFilter'> & {
  scrollableNode: HTMLDivElement | null;
};

export const HomeFeaturesFilter = (props: HomeFeaturesFilterProps) => {
  const isLeasePage = useIsLeasePage();

  const homeFeaturesChanged = useSelector($getHomeFeaturesChanged);

  return (
    <Container>
      <FilterHeaderContainer>
        {homeFeaturesChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Home Features
        </FilterHeaderTitle>
      </FilterHeaderContainer>
      <Prices disableFilter={Boolean(props.disableFilter)} />
      {isLeasePage && (
        <FiltersRadioContainer name="homeFeaturesFilters.petsAllowed">
          <FiltersRadioTitle>Pets Allowed</FiltersRadioTitle>
          {petsAllowedRadio.map(({ value, title }) => (
            <RadioGroupItem
              disabled={props.disableFilter}
              key={value}
              label={title}
              value={value}
            />
          ))}
        </FiltersRadioContainer>
      )}
      <CommunityFeatures
        disableFilter={Boolean(props.disableFilter)}
        scrollableNode={props.scrollableNode}
      />
    </Container>
  );
};

const isInputValid = (value: string) => validateSelectInput(value, years);

type PricesProps = {
  disableFilter: boolean;
};

const Prices = (props: PricesProps) => {
  const [homeFeatures] = useField({ name: 'homeFeaturesFilters' });
  const { minYearBuilt, maxYearBuilt } = homeFeatures.value;

  const yearsMinValue = minYears.find(y => y.value === minYearBuilt);
  const yearsMaxValue = maxYears.find(y => y.value === maxYearBuilt);

  return (
    <ItemContainer>
      <TitleHeader>
        <Title>Year Built</Title>
        <FiltersTitleValue>
          {getTitleValue(minYearBuilt, maxYearBuilt)}
        </FiltersTitleValue>
      </TitleHeader>
      <SelectContainer>
        <SelectWrapper>
          <InputSelectField
            name="homeFeaturesFilters.minYearBuilt"
            ariaLabel={`${SELECT_MIN} built year`}
            isDisabled={props.disableFilter}
            validator={value =>
              validateMinMax({
                compareValue: maxYearBuilt,
                isMin: true,
                validator: isInputValid,
                value,
              })
            }
            rawValue={minYearBuilt}
            value={yearsMinValue}
            options={minYears.filter(({ value }) =>
              filterMinMax({
                value,
                min: minYearBuilt,
                max: maxYearBuilt,
                isMin: true,
              })
            )}
          />
        </SelectWrapper>

        <ToContainer>to</ToContainer>
        <SelectWrapper>
          <InputSelectField
            name="homeFeaturesFilters.maxYearBuilt"
            ariaLabel={`${SELECT_MAX} built year`}
            isDisabled={props.disableFilter}
            validator={(value: string) =>
              validateMinMax({
                compareValue: minYearBuilt,
                validator: isInputValid,
                value,
              })
            }
            rawValue={maxYearBuilt}
            value={yearsMaxValue}
            options={maxYears.filter(({ value }) =>
              filterMinMax({
                value,
                min: minYearBuilt,
                max: maxYearBuilt,
              })
            )}
          />
        </SelectWrapper>
      </SelectContainer>
    </ItemContainer>
  );
};

type CommunityFeaturesProps = {
  disableFilter: boolean;
  scrollableNode?: HTMLDivElement | null;
};

const CommunityFeatures = (props: CommunityFeaturesProps) => {
  return (
    <>
      <CheckboxWrapper>
        <CheckboxField
          name="homeFeaturesFilters.masterOnMain"
          disabled={props.disableFilter}
          labelText="Primary on Main"
        />
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          name="homeFeaturesFilters.exerciseRoom"
          disabled={props.disableFilter}
          labelText="Exercise Room"
        />
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          name="homeFeaturesFilters.guestQuarters"
          disabled={props.disableFilter}
          labelText="Guest Quarters"
        />
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          name="homeFeaturesFilters.gameRoom"
          disabled={props.disableFilter}
          labelText="Game Room"
        />
      </CheckboxWrapper>
      <FilterShowMore
        lines={0}
        truncateChildren
        scrollableNode={props.scrollableNode}
        more="Show all Home Features"
        less="Show less Home Features"
      >
        <CheckboxWrapper>
          <CheckboxField
            name="homeFeaturesFilters.fireplace"
            disabled={props.disableFilter}
            labelText="Fireplace"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="homeFeaturesFilters.mediaRoom"
            disabled={props.disableFilter}
            labelText="Media Room"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="homeFeaturesFilters.accessibilityFeatures"
            disabled={props.disableFilter}
            labelText="Accessibility Features"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="homeFeaturesFilters.officeStudy"
            disabled={props.disableFilter}
            labelText="Office/Study"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="homeFeaturesFilters.historicDesign"
            disabled={props.disableFilter}
            labelText="Historic Design"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="homeFeaturesFilters.wineRoom"
            disabled={props.disableFilter}
            labelText="Wine Room"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="homeFeaturesFilters.energyEfficient"
            disabled={props.disableFilter}
            labelText="Energy Efficient"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="homeFeaturesFilters.workshop"
            disabled={props.disableFilter}
            labelText="Workshop"
          />
        </CheckboxWrapper>
      </FilterShowMore>
    </>
  );
};
