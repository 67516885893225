import { chain, upperFirst } from 'lodash';

import { Hint, HintGroup, TagType } from 'search/search-types';
import { TYPE } from 'shared/components/Dropdown/Dropdown';
import { resolvePlace } from 'shared/helpers/resolvePlace';
import { ReactComponent as AddressIcon } from 'shared/icons/shared/16x16/searchbar/address.svg';
import { ReactComponent as AgentsIcon } from 'shared/icons/shared/16x16/searchbar/agent.svg';
import { ReactComponent as CityIcon } from 'shared/icons/shared/16x16/searchbar/city.svg';
import { ReactComponent as CountyIcon } from 'shared/icons/shared/16x16/searchbar/county.svg';
import { ReactComponent as ListingsIcon } from 'shared/icons/shared/16x16/searchbar/listing.svg';
import { ReactComponent as MLSAreaIcon } from 'shared/icons/shared/16x16/searchbar/mls.svg';
import { ReactComponent as NeighborhoodIcon } from 'shared/icons/shared/16x16/searchbar/neighborhood.svg';
import { ReactComponent as SchoolDistrictIcon } from 'shared/icons/shared/16x16/searchbar/school-district.svg';
import { ReactComponent as SchoolIcon } from 'shared/icons/shared/16x16/searchbar/school.svg';
import { ReactComponent as StreetIcon } from 'shared/icons/shared/16x16/searchbar/street.svg';
import { ReactComponent as ZipCodeIcon } from 'shared/icons/shared/16x16/searchbar/zip-code.svg';

export enum GROUP_TYPE_NAMES {
  Address = 'Address',
  City = 'City',
  County = 'County',
  Listings = 'Listings',
  MLSArea = 'MLS Area',
  Neighborhood = 'Neighborhood',
  School = 'School',
  SchoolDistrict = 'School district',
  Street = 'Street',
  ZipCode = 'Zip Code',
  Agents = 'Agents',
}

const ORDER_BY = [
  'city',
  'county',
  'postalcode',
  'subdivision',
  'schooldistrict',
  'school',
  'street',
  'address',
  'mlsarea',
  'mlscode',
];

function resolveTypeTitle(type: string) {
  switch (type) {
    case 'mlsarea':
      return 'MLS Area';
    case 'mlscode':
      return 'Listings';
    case 'postalcode':
      return 'Zip Code';
    case 'schooldistrict':
      return 'School district';
    case 'subdivision':
      return 'Neighborhood';
    default:
      return upperFirst(type);
  }
}

export const groupByType = (data: Hint[]): HintGroup[] => {
  return (
    chain(data)
      .map((place: Omit<Hint, 'type'> & { type: TagType }) => {
        const resolvedPlace = resolvePlace(place);
        return { type: place.type, filter: place.value, ...resolvedPlace };
      })
      .compact()
      .groupBy('type')
      .mapValues((items, key) => ({
        key,
        items,
        name: resolveTypeTitle(key),
        type: TYPE.GROUP,
      }))
      .sortBy(item => ORDER_BY.indexOf(item.key))
      .values()
      // fixme: remove any; lodash chain is bad, rewrite with plain js
      .value() as any
  );
};

export const getGroupIconComponent = (name: GROUP_TYPE_NAMES) => {
  switch (name) {
    case GROUP_TYPE_NAMES.City:
      return CityIcon;
    case GROUP_TYPE_NAMES.County:
      return CountyIcon;
    case GROUP_TYPE_NAMES.Address:
      return AddressIcon;
    case GROUP_TYPE_NAMES.Listings:
      return ListingsIcon;
    case GROUP_TYPE_NAMES.MLSArea:
      return MLSAreaIcon;
    case GROUP_TYPE_NAMES.Neighborhood:
      return NeighborhoodIcon;
    case GROUP_TYPE_NAMES.School:
      return SchoolIcon;
    case GROUP_TYPE_NAMES.SchoolDistrict:
      return SchoolDistrictIcon;
    case GROUP_TYPE_NAMES.Street:
      return StreetIcon;
    case GROUP_TYPE_NAMES.ZipCode:
      return ZipCodeIcon;
    case GROUP_TYPE_NAMES.Agents:
      return AgentsIcon;
    default:
      return AddressIcon;
  }
};

export const getPluralGroupName = (name: GROUP_TYPE_NAMES) => {
  if (name === GROUP_TYPE_NAMES.Address) return `${name}es`;
  if (name === GROUP_TYPE_NAMES.Agents || name === GROUP_TYPE_NAMES.Listings) {
    return name;
  }
  if (name === GROUP_TYPE_NAMES.City) return 'Cities';
  if (name === GROUP_TYPE_NAMES.County) return 'Counties';
  if (name === GROUP_TYPE_NAMES.SchoolDistrict) return 'School Districts';
  return `${name}s`;
};
