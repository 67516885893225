import {
  DISMISSED_COLLECTION_ID,
  PROPOSED_COLLECTION_DESCRIPTION,
  PROPOSED_COLLECTION_ID,
} from 'collections/collectionsConstants';
import {
  Collection,
  CollectionFilter,
  DismissedCollection,
  DismissedOrProposedCollection,
  FetchCollectionsType,
  ProposedCollection,
  ProposedCollectionBody,
  RawCollection,
  RawOrDismissedCollection,
  RawOrProposedCollection,
} from 'collections/collectionsTypes';
import { Listing } from 'listings/listingsTypes';
import { not } from 'shared/helpers/boolean';

export const collectionsSortOptions = [
  { value: CollectionFilter.All, label: 'All' },
  { value: CollectionFilter.CreatedByMe, label: 'Created by me' },
];

export function isDismissedId(id?: string): boolean {
  return id === DISMISSED_COLLECTION_ID;
}

export function isProposedId(id?: string): boolean {
  return id === PROPOSED_COLLECTION_ID;
}

export function isRawCollectionId(id: string): boolean {
  return not(isProposedId(id) || isDismissedId(id));
}

export function isDismissed(
  collection: Collection
): collection is DismissedCollection {
  return isDismissedId(collection.id);
}
export function isProposed(
  collection: Collection
): collection is ProposedCollectionBody {
  return isProposedId(collection.id);
}

export function isNotDismissed(
  collection: Collection
): collection is RawOrProposedCollection {
  return not(isDismissed(collection));
}
export function isNotProposed(
  collection: Collection
): collection is RawOrDismissedCollection {
  return not(isProposed(collection));
}

export function isRawCollection(
  collection: Collection
): collection is RawCollection {
  return isRawCollectionId(collection.id);
}

export function isNotRawCollection(
  collection: Collection
): collection is DismissedOrProposedCollection {
  return not(isRawCollection(collection));
}

export const isAgentCollectionOwnersAgent = (
  collection: Collection,
  userId?: string
): boolean => {
  // agent is able to see collections if person who shared belongs to this agent
  const agentId = 'user' in collection && collection.user?.agentId;
  return Boolean(agentId && userId && agentId === userId);
};

export const mapToRecommendListingsData = (
  listings: Listing[],
  comments: { [key: string]: string }
) =>
  listings.map(listing => ({
    listingId: listing.listingid,
    commentText: comments[listing.listingid] || '',
  }));

export const isProposedByDescription = (
  collection: FetchCollectionsType
): collection is ProposedCollection => {
  return (
    (collection as ProposedCollection).description ===
    PROPOSED_COLLECTION_DESCRIPTION
  );
};
