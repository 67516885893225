import { Boundary, Cluster, ClusterData, RaPolygon } from 'map/mapTypes';
import { Tag } from 'search/search-types';
import { not } from 'shared/helpers/boolean';
import { ApiService } from 'shared/services/ApiService';

export class MapService {
  static async saveDrawingPolygons(data: RaPolygon) {
    return ApiService.request<Tag>({
      method: 'POST',
      path: `/polygon`,
      data,
    });
  }
}

interface FetchClusterParams {
  boundaries?: Partial<Boundary>;
  isMobile: boolean;
  listingId?: string;
  soloListing?: boolean;
  url?: URL;
  zoom: number;
}

const fetchClustersPath = `${window.location.origin}/api/v2/map/points`;

export async function fetchCluster(params: FetchClusterParams) {
  const url = params.url || new URL(fetchClustersPath);

  url.searchParams.append('limit', '500');
  url.searchParams.append('mobile', String(params.isMobile));
  url.searchParams.append('zoom', String(params.zoom));

  if (params.listingId) {
    url.searchParams.append('dismissed', 'true');
    url.searchParams.append('listingIds', params.listingId);
  }

  if (not(params.soloListing)) {
    const boundaries = params.boundaries || {};
    url.searchParams.append('minLatitude', String(boundaries.minLatitude));
    url.searchParams.append('maxLatitude', String(boundaries.maxLatitude));
    url.searchParams.append('minLongitude', String(boundaries.minLongitude));
    url.searchParams.append('maxLongitude', String(boundaries.maxLongitude));
  }

  const response = await ApiService.request<Array<Cluster<ClusterData>>>({
    createFullUrl: false,
    method: 'GET',
    path: url.toString(),
  });

  return response || [];
}
