/* eslint-disable sonarjs/no-duplicate-string */
export enum ProductName {
  RA = 'ra',
  SA = 'sa',
}

export enum ProductFullName {
  RA = 'Realty Austin',
  SA = 'Realty San Antonio',
}

export enum ProductOrigins {
  RealityAustin = 'https://www.realtyaustin.com',
  SanAntonio = 'https://www.realtysanantonio.com',
}

export enum ListingSources {
  Sabor = 'SA',
  Actris = 'AUS',
  ExclusiveListing = 'EX',
}

export enum DeploymentTarget {
  Dev = 'development',
  Test = 'testing',
  Stage = 'staging',
  Production = 'production',
}

export enum OfficeMlsIds {
  SA = 'REAU00',
  RA = '3788',
}

export const SourceToOriginMapper = {
  [ListingSources.Sabor]: ProductOrigins.SanAntonio,
  [ListingSources.Actris]: ProductOrigins.RealityAustin,
};

export const OfficeMlsIdsToProductNameMapper = {
  [OfficeMlsIds.SA]: ProductName.SA,
  [OfficeMlsIds.RA]: ProductName.RA,
};

export enum LogoSize {
  Big = 'big',
  Small = 'small',
}

export const description =
  'Looking for a better Austin Home Search? Use the #1 Austin real estate website to find homes for sale by Map, Neighborhood, School Boundary, or Builder';
export const heading = 'Realty Austin Agent Directory';
export const agentsDescription =
  'Our REALTORS average 10 years experience and sell 5x more homes, 21 days faster than average.';
export const communityFeaturesDescription =
  'Find open houses, view market trends, and see recently sold homes';

export const RASocialMedia = {
  Facebook: 'https://www.facebook.com/realtyaustin',
  Twitter: 'https://twitter.com/realtyaustin',
  Linkedin: 'https://www.linkedin.com/company/realty-austin/',
  YouTube: 'https://www.youtube.com/user/realtyaustin',
  Instagram: 'https://www.instagram.com/realtyaustin/',
  Pinterest: 'https://www.pinterest.com/therealtyaustin/',
};

export const SASocialMedia = {
  Facebook: 'https://www.facebook.com/realtysanantonio',
  Twitter: 'https://twitter.com/therealtysa',
  Linkedin: 'https://www.linkedin.com/company/realty-san-antonio/',
  YouTube: 'https://www.youtube.com/realtysanantonio',
  Instagram: 'https://www.instagram.com/realtysanantonio/',
  Pinterest: 'https://www.pinterest.com/realtysanantonio',
};
