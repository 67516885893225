import { createSelector } from 'reselect';

import { PaymentCalculatorState } from 'listingDetails/listingDetailsTypes';
import {
  MONTH_IN_30_YEARS,
  MONTH_IN_YEAR,
} from 'shared/constants/appConstants';

export const selectListingPrice = (
  { listprice }: PaymentCalculatorState,
  item?: any
) => listprice;

export const selectTaxAnnualAmount = (
  { taxAnnualAmount }: PaymentCalculatorState,
  item?: any
) => taxAnnualAmount;

export const selectPropertyTaxRate = (
  { taxRate }: PaymentCalculatorState,
  item?: any
) => taxRate;

export const selectCurrencyPropertyTaxes = (
  { currencyPropertyTaxes }: PaymentCalculatorState,
  item?: any
) => currencyPropertyTaxes;

export const selectHomeownersInsurance = (
  { homeownersInsurance }: PaymentCalculatorState,
  item?: any
) => homeownersInsurance;

export const selectCurrencyHomeownersInsurance = (
  { currencyHomeownersInsurance }: PaymentCalculatorState,
  item?: any
) => currencyHomeownersInsurance;

export const selectDownPayment = (
  { downPayment }: PaymentCalculatorState,
  item?: any
) => downPayment;

export const selectCurrencyDownPayment = (
  { currencyDownPayment }: PaymentCalculatorState,
  item?: any
) => currencyDownPayment;

export const selectHOADues = (
  { hoaDues }: PaymentCalculatorState,
  item?: any
) => {
  if (!item || !item.hoa || !item.hoa[0].values || !item.hoa[0].values[0]) {
    return hoaDues;
  }

  if (
    item.hoa[0].values[0].desc === null ||
    item.hoa[0].values[0].desc === 'No'
  ) {
    return 0;
  }
  return hoaDues;
};

export const selectInterestRate = (
  { interestRate }: PaymentCalculatorState,
  item?: any
) => interestRate;

export const selectInitialData = (
  { initial }: PaymentCalculatorState,
  item?: any
) => initial;

export const selectCurrencyPrincipalInterest = createSelector(
  selectListingPrice,
  selectInterestRate,
  selectCurrencyDownPayment,
  (listprice, interestRate, currencyDownPayment) => {
    const interestRatePerMonth = interestRate / MONTH_IN_YEAR / 100;

    return Math.round(
      (listprice - currencyDownPayment) *
        (interestRatePerMonth /
          (1 - (1 + interestRatePerMonth) ** -MONTH_IN_30_YEARS)) || 0
    );
  }
);

export const selectPropertyTaxes = createSelector(
  selectListingPrice,
  selectTaxAnnualAmount,
  selectPropertyTaxRate,
  (listprice, taxAnnualAmount, taxRate) => {
    if (taxRate) {
      return taxRate;
    }

    if (taxAnnualAmount && listprice) {
      return Number(((taxAnnualAmount / listprice) * 100).toFixed(2));
    }

    return 0;
  }
);

export const selectMonthlyMortgagePayment = createSelector(
  selectCurrencyPrincipalInterest,
  selectCurrencyPropertyTaxes,
  selectHOADues,
  (currencyPrincipalInterest, currencyPropertyTaxes, hoaDues) =>
    currencyPrincipalInterest + currencyPropertyTaxes + hoaDues
);
