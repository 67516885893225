import { pickBy } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Omit } from 'utility-types';

import { getPeriodStartAndEnd } from 'filters/filtersHelpers';
import { PeriodFilters } from 'filters/filtersTypes';
import { NOT_OPENED_NOTIFICATION_HISTORY_STATUSES } from 'history/notificationHistory/NotificationHistory/notificationHistoryConstants';
import { constructNotificationInboxUrl } from 'history/notificationHistory/notificationHistoryHelpers';
import { NotificationHistoryService } from 'history/notificationHistory/notificationHistoryService';
import {
  applyFiltersAndSortings,
  changePageOnNotificationHistory,
  fetchNotificationHistory,
  fetchNotificationHistoryFailure,
  fetchNotificationHistoryItem,
  fetchNotificationHistoryItemSuccess,
  fetchNotificationHistorySuccess,
  filterByPeriod,
  filterByType,
  markAllNotificationsAsReadFailure,
  markAllNotificationsAsReadRequest,
  markAllNotificationsAsReadSuccess,
  NOTIFICATION_HISTORY_DEFAULT_FILTERS,
  resetNotificationFilters,
  sortNotificationHistory,
  undoMarkAllNotificationsAsRead,
  updateNotificationHistoryItem,
  updateNotificationHistoryItemSuccess,
} from 'history/notificationHistory/notificationHistorySlice';
import {
  NotificationHistoryFilters,
  NotificationHistoryState,
  NotificationHistoryStatus,
} from 'history/notificationHistory/notificationHistoryTypes';
import { not } from 'shared/helpers/boolean';
import { callIfOnline } from 'shared/helpers/network';
import { showErrorToast } from 'shared/helpers/notifications';
import { composeUrl } from 'shared/helpers/url';
import { getProfileData } from 'shared/selectors/profileSelector';
import history from 'shared/services/history';
import { UnpackReturnedPromise } from 'shared/types';
import { getNotificationHistoryCount } from 'user/userSelectors';

export function* fetchNotificationHistorySagas(
  action:
    | ReturnType<typeof fetchNotificationHistory>
    | ReturnType<typeof sortNotificationHistory>
    | ReturnType<typeof changePageOnNotificationHistory>
) {
  try {
    const {
      page,
      sortType,
      sortBy,
      limit,
      type,
      period,
    }: NotificationHistoryState = yield select(
      ({ history }) => history.notificationHistory
    );

    if (not(action.type === fetchNotificationHistory.type)) {
      const query = composeUrl<NotificationHistoryFilters>({
        actualFilters: {
          page,
          sortBy,
          sortType,
          period,
          type,
        },
        defaultFilters: NOTIFICATION_HISTORY_DEFAULT_FILTERS,
      });

      history.push({ search: query, state: { forceUrlUpdate: true } });
    }

    const periodFilters: Partial<Omit<PeriodFilters, 'period'>> = pickBy(
      getPeriodStartAndEnd(period),
      Boolean
    );

    const url = constructNotificationInboxUrl({
      page: page,
      sortType: sortType,
      sortBy: sortBy,
      limit: limit,
      type,
      periodFilters: periodFilters,
    });

    const notificationHistoryItems: UnpackReturnedPromise<
      typeof NotificationHistoryService.getNotificationHistory
    > = yield call(NotificationHistoryService.getNotificationHistory, url);
    yield put(
      fetchNotificationHistorySuccess({
        notificationHistoryItems,
      })
    );
  } catch (e) {
    yield put(fetchNotificationHistoryFailure(e.message));
    callIfOnline(() => {
      showErrorToast(e.message);
    });
  }
}

export function* fetchNotificationHistoryItemSaga(
  action: ReturnType<typeof fetchNotificationHistoryItem>
): Saga {
  try {
    const { notificationId, notificationStatus } = action.payload;

    const profile = yield select(getProfileData);

    const oneNotificationHistoryItem: UnpackReturnedPromise<
      typeof NotificationHistoryService.getNotificationHistoryItem
    > = yield call(
      NotificationHistoryService.getNotificationHistoryItem,
      {
        username: profile.username,
      },
      notificationId
    );

    if (NOT_OPENED_NOTIFICATION_HISTORY_STATUSES.includes(notificationStatus)) {
      yield put(
        updateNotificationHistoryItem({
          notificationId,
          data: {
            status: NotificationHistoryStatus.Open,
          },
        })
      );
    }

    yield put(
      fetchNotificationHistoryItemSuccess({
        notificationId: action.payload.notificationId,
        notificationRawHtml: oneNotificationHistoryItem,
      })
    );
  } catch (e) {
    yield put(fetchNotificationHistoryFailure(e.message));
    showErrorToast(e.message);
  }
}

export function* updateNotificationHistoryItemSaga(
  action: ReturnType<typeof updateNotificationHistoryItem>
) {
  try {
    const updatedNotificationHistoryItem: UnpackReturnedPromise<
      typeof NotificationHistoryService.updateNotificationHistoryItem
    > = yield call(
      NotificationHistoryService.updateNotificationHistoryItem,
      action.payload.data,
      action.payload.notificationId
    );

    yield put(
      updateNotificationHistoryItemSuccess({
        notificationHistoryItem: updatedNotificationHistoryItem,
      })
    );

    action.payload.cb?.();
  } catch (e) {
    console.warn(e?.message);
  }
}

export function* markAllNotificationsAsReadSaga(
  action: ReturnType<typeof markAllNotificationsAsReadRequest>
) {
  try {
    const notificationHistoryCount: number = yield select(
      getNotificationHistoryCount
    );

    if (not(notificationHistoryCount)) {
      yield call(NotificationHistoryService.markAllNotificationsAsRead);
    }

    yield put(markAllNotificationsAsReadSuccess());
  } catch (e) {
    showErrorToast('Unable to Mark all as Read');
    yield put(
      undoMarkAllNotificationsAsRead({
        items: action.payload.items,
        notificationHistoryCount: action.payload.notificationHistoryCount,
      })
    );
    yield put(markAllNotificationsAsReadFailure());
  }
}

export const getNotificationHistorySub = () =>
  takeLatest(
    [
      fetchNotificationHistory,
      sortNotificationHistory,
      filterByType,
      filterByPeriod,
      changePageOnNotificationHistory,
      applyFiltersAndSortings,
      resetNotificationFilters,
    ],
    fetchNotificationHistorySagas
  );

export const getOneNotificationHistorySub = () =>
  takeLatest([fetchNotificationHistoryItem], fetchNotificationHistoryItemSaga);

export const updateNotificationHistoryItemSub = () =>
  takeLatest(
    [updateNotificationHistoryItem],
    updateNotificationHistoryItemSaga
  );

export const markAllNotificationsAsReadSub = () =>
  takeLatest(
    [markAllNotificationsAsReadRequest],
    markAllNotificationsAsReadSaga
  );

export function* notificationHistorySagas() {
  yield all([
    getNotificationHistorySub(),
    getOneNotificationHistorySub(),
    updateNotificationHistoryItemSub(),
    markAllNotificationsAsReadSub(),
  ]);
}
