import { get, pick } from 'lodash';
import { generatePath } from 'react-router-dom';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { updateContactNotificationsSettings } from 'contacts/contactsActions';
import {
  getAgentMode,
  getContactItem,
  getContactUsername,
} from 'contacts/contactsSelectors';
import { ContactsState } from 'contacts/contactsTypes';
import { mergeFilters, mergeFiltersWithDefault } from 'filters/filtersActions';
import {
  getPeriodStartAndEnd,
  removeDefaultFilters,
} from 'filters/filtersHelpers';
import { getDefaultFilters } from 'filters/filtersReducer';
import { $getIsPropertyTypeFiltersChanged } from 'filters/filtersSelectors';
import { FiltersState } from 'filters/filtersTypes';
import { fetchListings, fetchListingsFail } from 'listings/listingsActions';
import { SAVED_SEARCHES_PER_PAGE, searchUrl } from 'listings/listingsConstants';
import { constructUrl, normalizeMlsStatuses } from 'listings/listingsHelpers';
import { detectMapType } from 'listings/listingsSagas';
import {
  changeMapDrawingStatus,
  changeMapPositionFromUrl,
  fetchPolygons,
  resetPolygons,
} from 'map/mapActions';
import { DrawingStatus, TypesOfMap } from 'map/mapConstants';
import { isMapBoundaryValid } from 'map/mapHelper';
import { MapState } from 'map/mapTypes';
import { RootState } from 'rootReducer';
import {
  AcceptSavedSearchInvitation,
  ChangeSavedSearchesPageAction,
  ChangeViewedSearchesPageAction,
  declineSavedSearchFail,
  DeclineSavedSearchInvitation,
  declineSavedSearchSuccess,
  fetchSavedSearch,
  FetchSavedSearchAction,
  fetchSavedSearches,
  FetchSavedSearchesAction,
  fetchSavedSearchesFail,
  fetchSavedSearchesInvitations,
  fetchSavedSearchFail,
  FetchViewedSearchesAction,
  fetchViewedSearchesFail,
  fetchViewedSearchesRequest,
  fetchViewedSearchesSuccess,
  FilterViewedSearchesByPeriodAction,
  joinSavedSearch,
  joinSavedSearchFail,
  JoinSavedSearchRequestAction,
  RemoveSavedSearchRequestAction,
  removeSavedSearchRequestFail,
  removeSavedSearchRequestSuccess,
  RevertSearchAction,
  SavedSearchActionType,
  SaveSearchAsNewRequestAction,
  saveSearchFail,
  SaveSearchFromViewedRequestAction,
  SaveSearchRequestAction,
  SendSavedSearchInvitationReminderAction,
  SendSearchResultsAction,
  sendSearchResultsFail,
  sendSearchResultsSuccess,
  setSavedSearches,
  setSearch,
  shareSavedSearch,
  shareSavedSearchRequest,
  ShareSavedSearchRequestAction,
  SortSearchesAction,
  SortViewedSearchesAction,
  StartDeclineSavedSearchAction,
  StartRemoveSavedSearchAction,
  undoRemoveSavedSearch,
  unshareSavedSearch,
  UnshareSavedSearchRequestAction,
  updateSavedSearch,
  updateSavedSearchesInvitations,
  updateSavedSearchFail,
  UpdateSavedSearchRequestAction,
  updateSearchFail,
  UpdateSearchRequestAction,
} from 'saved-search/savedSearchActions';
import { SearchSortValues } from 'saved-search/savedSearchConstants';
import {
  extractLeaseFromSearchQuery,
  extractMapDataFromSavedOrViewedSearchQuery,
} from 'saved-search/savedSearchHelpers';
import { SAVED_SEARCH_DEFAULT_FILTERS_STATE } from 'saved-search/savedSearchReducer';
import { getTagsMask } from 'saved-search/savedSearchSelectors';
import { SavedSearchService } from 'saved-search/SavedSearchService';
import {
  NotificationFrequencyOptionValue,
  SavedSearch,
  SavedSearchBody,
  SavedSearchFilters,
  SavedSearchState,
  ViewedSearch,
} from 'saved-search/savedSearchTypes';
import { SearchDataParser } from 'saved-search/services/SearchDataParser';
import { SearchDescriptionBuilder } from 'saved-search/services/SearchDescriptionBuilder';
import { setSavedSearchTags } from 'search/search-slice';
import { SortOrder } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { not, notEmpty } from 'shared/helpers/boolean';
import { isLeasePage } from 'shared/helpers/isLeasePage';
import { callIfOnline } from 'shared/helpers/network';
import { showErrorToast, showSuccessToast } from 'shared/helpers/notifications';
import { composeUrl } from 'shared/helpers/url';
import { getProfileData } from 'shared/selectors/profileSelector';
import history from 'shared/services/history';
import { HistoryService } from 'shared/services/HistoryService';
import { Invitation, UnpackReturnedPromise } from 'shared/types';
import { startUpdateProfile } from 'user/userActions';
import { waitForProfile } from 'user/userSagas';
import { getIsAgent } from 'user/userSelectors';
import { UserRole } from 'user/userTypes';

function* createSavedSearchSaga(
  action:
    | SaveSearchRequestAction
    | SaveSearchAsNewRequestAction
    | SaveSearchFromViewedRequestAction
): Saga {
  const { search: viewedSearch, settings } = action.body.data;
  const { collaboratorEmails } = settings;

  try {
    const state: RootState = yield select();
    const user = yield select(getProfileData);
    const isAgent = yield select(getIsAgent);
    const contactItem = yield select(getContactItem);
    const agentMode = yield select(getAgentMode);
    const isPropertyTypeFiltersChanged = yield select(
      $getIsPropertyTypeFiltersChanged
    );

    const newSettings = pick(settings, [
      'name',
      'notifications',
      'notifyAgent',
      'notNotifyUser',
    ]);

    const reqBody = {
      ...newSettings,
      version: '2',
    } as SavedSearchBody;
    const mapType: keyof MapState = detectMapType({ action });
    const currentMap = yield select((state: RootState) => state.map[mapType]);
    const mapData = {
      boundaries: currentMap.mapBoundary,
      zoom: currentMap.zoom,
    };
    const defaultFilters = getDefaultFilters({
      isAgent,
      agentMode,
    }) as FiltersState;

    if (viewedSearch && (viewedSearch as ViewedSearch).searchData) {
      const { tags = [], filters } = SearchDataParser.parse(
        (viewedSearch as ViewedSearch).searchData
      );
      reqBody.filters = removeDefaultFilters({
        filters,
        defaultFilters,
        checkPropertyTypeFilters: true,
      });
      reqBody.tags = tags;
      reqBody.query = viewedSearch.query;
    } else {
      reqBody.filters = removeDefaultFilters({
        filters: state.filters,
        defaultFilters: defaultFilters,
        checkPropertyTypeFilters: isPropertyTypeFiltersChanged,
      });
      reqBody.tags = getTagsMask(state);
      reqBody.query = yield constructUrl({
        path: searchUrl,
        mapData: notEmpty(reqBody.tags) ? null : mapData,
        lease:
          isLeasePage(history.location.pathname) ||
          extractLeaseFromSearchQuery(viewedSearch.query),
      });
    }

    if (agentMode) {
      reqBody.owner = contactItem.username;
    }

    const savedSearch: UnpackReturnedPromise<
      typeof SavedSearchService.createSavedSearch
    > = yield call(SavedSearchService.createSavedSearch, reqBody);

    if (
      newSettings.notifications?.frequency !==
      NotificationFrequencyOptionValue.Never
    ) {
      if (agentMode && contactItem?.hasOptedOutOfEmail) {
        yield put(
          updateContactNotificationsSettings({
            body: { hasOptedOutOfEmail: false, userId: contactItem.username },
          })
        );
      }

      if (not(agentMode) && user.hasOptedOutOfEmail) {
        yield put(
          startUpdateProfile({
            data: { hasOptedOutOfEmail: false },
          })
        );
      }
    }

    if (notEmpty(collaboratorEmails)) {
      yield put(shareSavedSearchRequest(savedSearch.id, collaboratorEmails));
    }

    const redirectPath = agentMode
      ? generatePath(RoutePath.AGENT_SAVED_SEARCH, {
          contactId: contactItem.username,
          savedSearchId: savedSearch.id,
        })
      : generatePath(RoutePath.SAVED_SEARCH, { savedSearchId: savedSearch.id });

    history.push(redirectPath);

    yield put(setSearch(savedSearch));
    yield put(
      setSavedSearchTags({ name: savedSearch.name, tags: savedSearch.tags })
    );
    // fix for reset tags
    yield put(mergeFilters(savedSearch.filters));
    yield put(changeMapDrawingStatus(mapType, DrawingStatus.NoDrawing));
    yield put(fetchPolygons(mapType));

    showSuccessToast(`"${savedSearch.name}" added to Saved Searches`, {
      onViewClick: () =>
        history.push(
          agentMode
            ? generatePath(RoutePath.AGENT_SAVED_SEARCHES, {
                contactId: contactItem.username,
              })
            : RoutePath.SAVED_SEARCHES
        ),
    });

    action.body.onSuccess?.();
  } catch (e) {
    yield put(saveSearchFail(e.message));
  }

  action.body.cb?.();
}

export function* fetchSavedSearchSaga(action: FetchSavedSearchAction) {
  try {
    const savedSearch: UnpackReturnedPromise<
      typeof SavedSearchService.fetchSavedSearch
    > = yield call(
      SavedSearchService.fetchSavedSearch,
      action.id,
      action.contactId
    );

    yield put(setSearch(savedSearch));

    /**
     * Sanitizing saved search to get rid of sortBy and sortType filters
     */
    if ('sortBy' in savedSearch.filters) {
      delete (savedSearch.filters as any).sortBy;
    }
    if ('sortType' in savedSearch.filters) {
      delete (savedSearch.filters as any).sortType;
    }

    const mapType = detectMapType();

    if (action.shouldGetListings) {
      let forceMap = false;
      let boundaries = null;
      if (savedSearch && savedSearch.query) {
        const { mapBoundary, zoom } =
          yield extractMapDataFromSavedOrViewedSearchQuery(savedSearch.query);

        if (mapBoundary && isMapBoundaryValid(mapBoundary)) {
          forceMap = true;
          boundaries = mapBoundary;

          yield put(changeMapPositionFromUrl(mapType, mapBoundary, zoom));

          if (zoom) {
            history.replace({
              ...history.location,
              state: { ...history.location.state, forceZoom: zoom },
            });
          }
        }
      }

      const normalizedFilters = savedSearch.filters.mlsStatus
        ? {
            mlsStatus: normalizeMlsStatuses(savedSearch.filters.mlsStatus),
          }
        : {};
      const agentMode: boolean = yield select(getAgentMode);
      const isAgentSSOwner = savedSearch.user.role === UserRole.Agent;

      yield put(
        mergeFiltersWithDefault({
          filters: {
            ...savedSearch.filters,
            ...normalizedFilters,
            ...action.injectedFilters,
          },
          isAgent: isAgentSSOwner,
          agentMode,
        })
      );
      yield put(
        setSavedSearchTags({ name: savedSearch.name, tags: savedSearch.tags })
      );
      yield put(fetchListings(mapType, forceMap, boundaries));
    }
  } catch (e) {
    yield put(fetchSavedSearchFail(e.message));
    yield put(
      fetchListingsFail('Unable to fetch saved search listings data 404')
    );
  }
}

function* fetchSavedSearchesSaga(
  action:
    | FetchSavedSearchesAction
    | ChangeSavedSearchesPageAction
    | SortSearchesAction
): Saga {
  try {
    const agentMode: boolean = yield select(getAgentMode);
    const contact = yield select(getContactItem);
    const user = yield call(waitForProfile);
    const { page, sortBy }: SavedSearchState = yield select(
      ({ savedSearch }) => savedSearch
    );

    const sortType =
      sortBy === SearchSortValues.Name ? SortOrder.ASC : SortOrder.DESC;

    if (not(action.type === SavedSearchActionType.FETCH_SAVED_SEARCHES)) {
      const query = composeUrl({
        defaultFilters: {
          page: 1,
          sortBy: SAVED_SEARCH_DEFAULT_FILTERS_STATE.sortBy,
        },
        actualFilters: {
          page,
          sortBy,
        },
      });

      history.push({ search: query, state: { forceUrlUpdate: true } });
    }

    const userId = agentMode ? contact.username : user?.username;
    const {
      hits: savedSearches,
      total,
    }: UnpackReturnedPromise<typeof SavedSearchService.fetchSavedSearches> =
      yield call(SavedSearchService.fetchSavedSearches, {
        user: userId,
        page,
        limit: SAVED_SEARCHES_PER_PAGE,
        sortBy,
        sortType,
      });

    yield put(setSavedSearches(savedSearches, total));
  } catch (e) {
    yield put(fetchSavedSearchesFail(e.message));
  }
}

function* updateSavedSearchSettingsSaga(
  action: UpdateSearchRequestAction
): Saga {
  try {
    const state: RootState = yield select();
    const { id, version } = state.savedSearch.item;
    const tags = getTagsMask(state);
    const isAgent = yield select(getIsAgent);
    const agentMode = yield select(getAgentMode);
    const isPropertyTypeFiltersChanged = yield select(
      $getIsPropertyTypeFiltersChanged
    );
    const defaultFilters = getDefaultFilters({
      isAgent,
      agentMode,
    }) as FiltersState;
    const filters = removeDefaultFilters({
      filters: state.filters,
      defaultFilters,
      checkPropertyTypeFilters: isPropertyTypeFiltersChanged,
    });
    const contactId = yield select(getContactUsername);
    const mapType: keyof MapState = detectMapType({ action });
    const { collaboratorEmails, collaboratorsToRemove } =
      action.body.data.settings;
    const settings = pick(action.body.data.settings, [
      'name',
      'notifications',
      'notifyAgent',
    ]);

    const owner = action.body.data.search?.owner;
    const query = action.body.data.search?.query;

    const currentMap = yield select((state: RootState) => state.map[mapType]);
    const mapData = {
      boundaries: currentMap.mapBoundary,
      zoom: currentMap.zoom,
    };

    const reqBody = {
      ...settings,
      owner,
      filters,
      tags,
      version,
      query: yield constructUrl({
        path: searchUrl,
        mapData: notEmpty(tags) ? null : mapData,
        lease: extractLeaseFromSearchQuery(query),
      }),
    } as SavedSearchBody;

    if (not(id)) {
      return;
    }

    const savedSearch: UnpackReturnedPromise<
      typeof SavedSearchService.updateSavedSearch
    > = yield call(SavedSearchService.updateSavedSearch, id, reqBody);

    yield put(setSearch(savedSearch));
    yield put(
      setSavedSearchTags({ name: savedSearch.name, tags: savedSearch.tags })
    );
    yield put(changeMapDrawingStatus(mapType, DrawingStatus.NoDrawing));
    yield put(fetchPolygons(mapType));

    // Please do not refactor. Requests are called here to get consistent result.
    if (notEmpty(collaboratorsToRemove)) {
      // We have to remove agent-collaborator if he/she was already removed through notify field in prev request
      const actualIds = savedSearch.collaborators?.map(({ id }) => id) || [];
      const actualCollaborators = collaboratorsToRemove.filter(id =>
        actualIds.includes(id)
      );
      yield all(
        actualCollaborators.map(collaboratorId => {
          return call(
            SavedSearchService.removeCollaborator,
            id,
            collaboratorId
          );
        })
      );
    }

    if (notEmpty(collaboratorEmails) && id) {
      yield call(SavedSearchService.createCollaborator, id, collaboratorEmails);
    }

    yield put(fetchSavedSearch(savedSearch.id, false, {}, contactId));

    action.body.onSuccess?.();

    showSuccessToast(`Changes to "${savedSearch.name}" saved`, {
      // onUndoClick: () => {},
    });
  } catch (e) {
    yield put(updateSearchFail(e.message));
  }
  action.body.cb?.();
}

function* updateSavedSearchSaga(action: UpdateSavedSearchRequestAction) {
  try {
    const isAgent: boolean = yield select(getIsAgent);
    const agentMode: boolean = yield select(getAgentMode);
    const defaultFilters = getDefaultFilters({
      isAgent,
      agentMode,
    }) as FiltersState;
    const { search, settings } = action.body.data;
    const { collaboratorEmails, collaboratorsToRemove, ...updatedSettings } =
      settings;
    const newSettings = {
      ...pick(search, [
        'name',
        'filters',
        'tags',
        'notifications',
        'query',
        'version',
        'notifyAgent',
        'owner',
      ]),
      ...updatedSettings,
    } as SavedSearchBody;

    newSettings.filters = removeDefaultFilters({
      filters: newSettings.filters,
      defaultFilters,
      checkPropertyTypeFilters: true,
    });

    const savedSearch: UnpackReturnedPromise<
      typeof SavedSearchService.updateSavedSearch
    > = yield call(
      SavedSearchService.updateSavedSearch,
      search.id,
      newSettings
    );

    savedSearch.notifications = savedSearch.notifications
      ? savedSearch.notifications
      : { ...(search as SavedSearch).notifications };

    // yield put(updateSavedSearch(savedSearch));
    // Please do not refactor. Requests are called here to get consistent result.
    if (notEmpty(collaboratorsToRemove)) {
      // We have to remove agent-collaborator if he/she was already removed through notify field in prev request
      const actualIds = savedSearch.collaborators?.map(({ id }) => id) || [];
      const actualCollaborators = collaboratorsToRemove.filter(id =>
        actualIds.includes(id)
      );
      yield all(
        actualCollaborators.map(collaboratorId => {
          return call(
            SavedSearchService.removeCollaborator,
            savedSearch.id,
            collaboratorId
          );
        })
      );
    }

    if (notEmpty(collaboratorEmails)) {
      yield call(
        SavedSearchService.createCollaborator,
        savedSearch.id,
        collaboratorEmails
      );
    }

    action.body.onSuccess?.();
    // TODO Check if BE can return updated collaborator in prev requests
    yield put(fetchSavedSearches({ silentUpdate: true }));
    showSuccessToast(`Changes to "${savedSearch.name}" saved`, {
      // onUndoClick: () => {},
    });
  } catch (e) {
    yield put(updateSavedSearchFail(e.message));
  }

  action.body.cb?.();
}

function* startDeleteSavedSearchSaga(action: StartRemoveSavedSearchAction) {
  const agentMode: boolean = yield select(getAgentMode);
  const agent: ContactsState = yield select(({ contacts }) => contacts);
  const contactId: string = get(agent, 'contact.item.username');
  if (agentMode && contactId) {
    action.cb?.(generatePath(RoutePath.AGENT_SAVED_SEARCHES, { contactId }));
  } else {
    action.cb?.(RoutePath.SAVED_SEARCHES);
  }
}

function* deleteSavedSearchSaga(action: RemoveSavedSearchRequestAction) {
  try {
    const agent: ContactsState = yield select(({ contacts }) => contacts);
    const contactId: string = get(agent, 'contact.item.username');

    const { savedSearchToRemove }: SavedSearchState = yield select(
      ({ savedSearch }) => savedSearch
    );
    if (!savedSearchToRemove.includes(action.id)) {
      return;
    }
    yield call(SavedSearchService.removeSavedSearch, action.id, contactId);
    yield put(removeSavedSearchRequestSuccess(action.id));
  } catch (e) {
    showErrorToast(`Unable to delete Saved Search "${action.name}"`);
    yield put(removeSavedSearchRequestFail(e.message));
    yield put(undoRemoveSavedSearch(action.id));
  }
}

function* declineSavedSearchRequestSaga(action: StartDeclineSavedSearchAction) {
  try {
    const { savedSearchToRemove }: SavedSearchState = yield select(
      ({ savedSearch }) => savedSearch
    );
    if (!savedSearchToRemove.includes(action.id)) {
      return;
    }
    yield call(
      SavedSearchService.declineSavedSearch,
      action.id,
      action.contactId
    );
    yield put(declineSavedSearchSuccess(action.id));
  } catch (e) {
    showErrorToast(`Unable to decline Saved Search "${action.name}"`);
    yield put(declineSavedSearchFail(e.message));
    yield put(undoRemoveSavedSearch(action.id));
  }
}

function* shareSavedSearchSaga(action: ShareSavedSearchRequestAction) {
  const { id, collaboratorEmails } = action;

  try {
    const collaborators: UnpackReturnedPromise<
      typeof SavedSearchService.createCollaborator
    > = yield call(
      SavedSearchService.createCollaborator,
      id,
      collaboratorEmails
    );

    yield put(shareSavedSearch(collaborators));
  } catch (e) {
    showErrorToast('Unable to share saved search');
  }
}

function* unshareSavedSearchSaga(action: UnshareSavedSearchRequestAction) {
  const { id, collaboratorIds } = action;

  try {
    yield all(
      collaboratorIds.map(function* (collaboratorId) {
        yield call(SavedSearchService.removeCollaborator, id, collaboratorId);
      })
    );
    yield put(unshareSavedSearch(collaboratorIds));
  } catch (e) {
    showErrorToast('Unable to unshare saved search');
  }
}

function* joinSavedSearchSaga(action: JoinSavedSearchRequestAction) {
  try {
    yield call(SavedSearchService.joinSavedSearch, action.inviteCode);

    yield put(joinSavedSearch());
    action.cb?.();
    showSuccessToast('Invitation accepted');
  } catch (e) {
    yield put(joinSavedSearchFail(e.message));
    showErrorToast('Unable to join Saved Search');
  }
}

function* fetchViewedSearchesSaga(
  action:
    | FetchViewedSearchesAction
    | SortViewedSearchesAction
    | FilterViewedSearchesByPeriodAction
    | ChangeViewedSearchesPageAction
) {
  const { page, sortType, period, limit, user, sortBy }: SavedSearchState =
    yield select(({ savedSearch }) => savedSearch);
  const isAgent: boolean = yield select(getIsAgent);

  if (not(action.type === SavedSearchActionType.FETCH_VIEWED_SEARCHES)) {
    const query = composeUrl<SavedSearchFilters>({
      actualFilters: { page, sortType, period, sortBy },
      defaultFilters: SAVED_SEARCH_DEFAULT_FILTERS_STATE,
    });
    history.push({ search: query, state: { forceUrlUpdate: true } });
  }

  try {
    yield put(fetchViewedSearchesRequest());
    const viewedSearches: UnpackReturnedPromise<
      typeof HistoryService.fetchViewedSearches
    > = yield call(HistoryService.fetchViewedSearches, {
      sort: sortType,
      ...getPeriodStartAndEnd(period),
      user,
      page,
      limit,
    });
    const defaultFilters = getDefaultFilters({
      isAgent,
      agentMode: false,
    }) as FiltersState;

    for (const viewedSearch of viewedSearches.hits) {
      const { filters, tags } = SearchDataParser.parse(viewedSearch.searchData);

      viewedSearch.description = SearchDescriptionBuilder.get({
        tags,
        filters: removeDefaultFilters({
          filters,
          defaultFilters,
          checkPropertyTypeFilters: true,
        }),
      });
    }

    yield put(fetchViewedSearchesSuccess(viewedSearches));
  } catch (e) {
    yield put(fetchViewedSearchesFail(e.message));
  }
}

function* sendSearchResultsSaga(action: SendSearchResultsAction): Saga {
  try {
    const { userId, savedSearchId, owner } = action;
    const updatedSaveSearch = yield call(
      SavedSearchService.sendSearchResults,
      userId,
      savedSearchId,
      owner
    );

    yield put(sendSearchResultsSuccess());
    yield put(
      updateSavedSearch({
        id: updatedSaveSearch.id,
        notifications: updatedSaveSearch.notifications,
      })
    );
    showSuccessToast('Saved Search results were successfully sent');
  } catch (e) {
    yield put(sendSearchResultsFail());
    showErrorToast('Unable to send Search Result');
  }
}

function* revertSavedSearchSaga(action: RevertSearchAction) {
  try {
    const isAgent: boolean = yield select(getIsAgent);
    const agentMode: boolean = yield select(getAgentMode);

    const { filters, tags, name, query } = action.savedSearch;
    let forceMap = false;
    let boundaries = null;

    if (query) {
      const { mapBoundary, zoom } =
        yield extractMapDataFromSavedOrViewedSearchQuery(query);

      if (mapBoundary && isMapBoundaryValid(mapBoundary)) {
        forceMap = true;
        boundaries = mapBoundary;
        yield put(
          changeMapPositionFromUrl(TypesOfMap.SavedSearches, mapBoundary, zoom)
        );

        if (zoom) {
          history.replace({
            ...history.location,
            state: { ...history.location.state, forceZoom: zoom },
          });
        }
      }
    }

    yield put(resetPolygons(TypesOfMap.SavedSearches));

    yield put(
      changeMapDrawingStatus(TypesOfMap.SavedSearches, DrawingStatus.NoDrawing)
    );
    yield put(setSavedSearchTags({ name, tags }));
    yield put(mergeFiltersWithDefault({ filters, isAgent, agentMode }));
    yield put(fetchListings(TypesOfMap.SavedSearches, forceMap, boundaries));
  } catch (e) {
    showErrorToast('Unable to reset Saved Search');
  }
}

export function* fetchSavedSearchesInvitationsSaga() {
  try {
    const savedSearchesInvitations: Invitation[] = yield call(
      SavedSearchService.fetchSavedSearchInvitations
    );
    yield put(updateSavedSearchesInvitations(savedSearchesInvitations));
  } catch (e) {
    callIfOnline(() => {
      showErrorToast('Unable to fetch collections invitations');
    });
  }
}

export function* acceptSavedSearchInvitationSaga(
  action: AcceptSavedSearchInvitation
) {
  try {
    yield call(
      SavedSearchService.acceptSavedSearchInvitation,
      action.inviteCode
    );
    yield put(fetchSavedSearchesInvitations());
    yield put(fetchSavedSearches());
  } catch (e) {}
}

export function* declineSavedSearchInvitationSaga(
  action: DeclineSavedSearchInvitation
) {
  try {
    yield call(
      SavedSearchService.declineSavedSearchInvitation,
      action.savedSearchId
    );
    yield put(fetchSavedSearchesInvitations());
  } catch (e) {}
}

export function* sendSavedSearchInvitationReminderSaga(
  action: SendSavedSearchInvitationReminderAction
) {
  try {
    yield call(
      SavedSearchService.sendReminder,
      action.id,
      action.collaboratorId
    );

    showSuccessToast('Email is sent');
  } catch (e) {
    showErrorToast('Unable to send email');
  }
}

const joinSavedSearchSub = () =>
  takeLatest(
    [SavedSearchActionType.JOIN_SAVED_SEARCH_REQUEST],
    joinSavedSearchSaga
  );

const declineSavedSearchSub = () =>
  takeLatest(
    [SavedSearchActionType.DECLINE_SAVED_SEARCH_REQUEST],
    declineSavedSearchRequestSaga
  );

const shareSavedSearchSub = () =>
  takeEvery(
    [SavedSearchActionType.SHARE_SAVED_SEARCH_REQUEST],
    shareSavedSearchSaga
  );

const unshareSavedSearchSub = () =>
  takeEvery(
    [SavedSearchActionType.UNSHARE_SAVED_SEARCH_REQUEST],
    unshareSavedSearchSaga
  );

const createSavedSearchSub = () =>
  takeLatest(
    [
      SavedSearchActionType.CREATE_SAVED_SEARCH_REQUEST,
      SavedSearchActionType.SAVE_SEARCH_AS_NEW_REQUEST,
      SavedSearchActionType.SAVE_SEARCH_FROM_VIEWED_REQUEST,
    ],
    createSavedSearchSaga
  );

const fetchSavedSearchSub = () =>
  takeLatest([SavedSearchActionType.FETCH_SAVED_SEARCH], fetchSavedSearchSaga);

const fetchSavedSearchesSub = () =>
  takeLatest(
    [
      SavedSearchActionType.FETCH_SAVED_SEARCHES,
      SavedSearchActionType.CHANGE_SAVED_SEARCHES_PAGE,
      SavedSearchActionType.SORT_SAVED_SEARCHES,
    ],
    fetchSavedSearchesSaga
  );

const updateSavedSearchSettingsSub = () =>
  takeLatest(
    [SavedSearchActionType.UPDATE_SAVED_SEARCH_SETTINGS_REQUEST],
    updateSavedSearchSettingsSaga
  );

// Get triggered in Saved Search list
const updateSavedSearchSub = () =>
  takeLatest(
    [SavedSearchActionType.UPDATE_SAVED_SEARCH_REQUEST],
    updateSavedSearchSaga
  );

const startDeleteSavedSearchSub = () =>
  takeLatest(
    [SavedSearchActionType.START_REMOVE_SAVED_SEARCH],
    startDeleteSavedSearchSaga
  );

const removeSavedSearchSub = () =>
  takeLatest(
    [SavedSearchActionType.REMOVE_SAVED_SEARCH_REQUEST],
    deleteSavedSearchSaga
  );

const fetchViewedSearchesSub = () =>
  takeLatest(
    [
      SavedSearchActionType.FETCH_VIEWED_SEARCHES,
      SavedSearchActionType.SORT_VIEWED_SEARCHES,
      SavedSearchActionType.FILTER_VIEWED_SEARCHES_BY_PERIOD,
      SavedSearchActionType.CHANGE_VIEWED_SEARCHES_PAGE,
    ],
    fetchViewedSearchesSaga
  );

const sendSearchResultsSub = () =>
  takeLatest([SavedSearchActionType.SEND_SEARCH_RESULT], sendSearchResultsSaga);

const revertSavedSearchSub = () =>
  takeLatest(
    [SavedSearchActionType.REVERT_SAVED_SEARCH],
    revertSavedSearchSaga
  );

const fetchSavedSearchesInvitationsSub = () =>
  takeEvery(
    [SavedSearchActionType.FETCH_SAVED_SEARCHES_INVITATIONS],
    fetchSavedSearchesInvitationsSaga
  );

const acceptSavedSearchInvitationSub = () =>
  takeEvery(
    [SavedSearchActionType.ACCEPT_SAVED_SEARCH_INVITATION],
    acceptSavedSearchInvitationSaga
  );

const declineSavedSearchInvitationSub = () =>
  takeEvery(
    [SavedSearchActionType.DECLINE_SAVED_SEARCH_INVITATION],
    declineSavedSearchInvitationSaga
  );

const sendSavedSearchInvitationReminderSub = () =>
  takeEvery(
    [SavedSearchActionType.SEND_SAVED_SEARCH_INVITATION_REMINDER],
    sendSavedSearchInvitationReminderSaga
  );

export function* savedSearchSagas() {
  yield all([
    joinSavedSearchSub(),
    shareSavedSearchSub(),
    unshareSavedSearchSub(),
    createSavedSearchSub(),
    fetchSavedSearchSub(),
    fetchSavedSearchesSub(),
    updateSavedSearchSettingsSub(),
    updateSavedSearchSub(),
    startDeleteSavedSearchSub(),
    removeSavedSearchSub(),
    declineSavedSearchSub(),
    fetchViewedSearchesSub(),
    sendSearchResultsSub(),
    revertSavedSearchSub(),
    fetchSavedSearchesInvitationsSub(),
    acceptSavedSearchInvitationSub(),
    declineSavedSearchInvitationSub(),
    sendSavedSearchInvitationReminderSub(),
  ]);
}
