import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { not } from 'shared/helpers/boolean';
import { bugsnag } from 'shared/services/bugsnag';
import { register as registerServiceWorker } from 'sw/service-worker-registration';

enum SWState {
  latest = 'latest',
  updateAvailable = 'updateAvailable',
  updateDismissed = 'updateDismissed',
  updating = 'updating',
}

interface ServiceWorkerEvent extends Event {
  target: (Partial<ServiceWorker> & EventTarget) | null;
}

export interface SWContextValue {
  registrationRef: React.MutableRefObject<ServiceWorkerRegistration | null>;
  skipWaiting: () => void;
  state: SWState;
}

export const SWContext = createContext<SWContextValue | null>(null);
SWContext.displayName = 'ServiceWorkerContext';

export const ServiceWorkerProvider: React.FC = props => {
  const registrationRef = useRef<ServiceWorkerRegistration | null>(null);
  const [state, setState] = useState<SWState>(SWState.latest);

  useEffect(function registerServiceWorkerAndCallbacks() {
    let reloading: boolean;

    const onStateChange = (event: ServiceWorkerEvent) => {
      bugsnag.leaveBreadcrumb('Service Worker: New worker is activated');
      if (event?.target?.state === 'activated' && not(reloading)) {
        reloading = true;
        window.location.reload();
      }
    };

    registerServiceWorker({
      onSuccess: registration => {
        bugsnag.leaveBreadcrumb(
          'Service Worker: Worker is installed & activated'
        );
        registrationRef.current = registration;
      },
      onUpdate: registration => {
        bugsnag.leaveBreadcrumb('Service Worker: Update available');
        registrationRef.current = registration;
        registration.waiting.onstatechange = onStateChange;
        setState(SWState.updateAvailable);
      },
    });

    navigator?.serviceWorker?.getRegistration?.().then(registration => {
      registrationRef.current = registration;

      // if there is a new sw waiting to be activated
      if (registration?.waiting?.state === 'installed') {
        bugsnag.leaveBreadcrumb('Service Worker: Update available');
        registration.waiting.onstatechange = onStateChange;
        setState(SWState.updateAvailable);
      }
    });
  }, []);

  function skipWaiting() {
    if (state === SWState.updateAvailable) {
      const waitingWorker = registrationRef.current?.waiting;
      if (waitingWorker) {
        setState(SWState.updating);
        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    }
  }

  const contextValue: SWContextValue = {
    registrationRef,
    skipWaiting,
    state,
  };

  return (
    <SWContext.Provider value={contextValue}>
      <AutoUpdate />
      {props.children}
    </SWContext.Provider>
  );
};

export const useServiceWorkerContext = () => {
  const context = useContext(SWContext);

  // temporary workaround for tests
  if (process.env.NODE_ENV === 'test') {
    return {} as SWContextValue;
  }

  if (context === null) {
    throw new Error(
      'useServiceWorkerContext hook should only be used within ServiceWorkerProvider'
    );
  }

  return context;
};

function AutoUpdate() {
  const location = useLocation();
  const { skipWaiting } = useServiceWorkerContext();

  useEffect(() => {
    if (shouldAutoUpdate(location.pathname)) {
      skipWaiting();
    }
  }, [location.pathname, skipWaiting]);

  return <></>;
}

function shouldAutoUpdate(path: string): boolean {
  const isListingDetails = path.endsWith('.html');
  return not(isListingDetails);
}
