export enum RoutePath {
  ACCESSIBILITY_STATEMENT = '/accessibility-statement',
  AGENT_BIO = '/agents/:name',
  AGENTS = '/agents',
  BLOG = '/blog',
  BLOG_CATEGORY = '/blog/category/:slug',
  BLOG_POST = '/blog/:slug',
  BLOG_SEARCH = '/blog/search',
  BUY_BEFORE_YOU_SELL = '/buy-before-you-sell',
  CAREERS = '/careers',
  COMPANY = '/company',
  CONTACT_AGENT_MODAL = '/agents/:name/contact',
  CONTACT_AGENT_REFER_ME = '/agents/:name/refer-me',
  CONTACT_AGENT_REVIEW_ME = '/agents/:name/review-me',
  DMCA_NOTICE = '/dmca-notice',
  HOME = '/',
  INFO_PAGE = '/info/:slug',
  JOIN_WITH_ADMIN_INVITE = '/signup/:email/:first_name/:last_name/:phone',
  OFFICES = '/offices',
  P_LINK = '/p/:id',
  PRIVACY_POLICY = '/privacy-policy',
  RELOCATION_GUIDE = '/relocation-guide',
  RESET_PASSWORD = '/reset-password',
  SELL = '/sell',
  SET_PASSWORD = '/set-password',
  STYLEGUIDE = '/styleguide',
  T_LINK = '/t/',
  TERMS_OF_USE = '/terms-of-use',
  TESTIMONIAL_VIDEO_PAGE = '/careers/testimonial-video/:slug',
  TYPOGRAPHY = '/typography',

  MENU = '/menu',
  ACCOUNT_SETTINGS = '/menu/account-settings',
  EMAIL_SETTINGS = '/email-settings',
  NOTIFICATION_INBOX = '/menu/notification-inbox',
  USER_EMAIL_SETTINGS = '/menu/email-settings',
  NOT_LOGGED_USER_EMAIL_SETTINGS = '/user/email-settings',
  YOUR_CONTACT = '/menu/contact',
  EMAIL_SUBSCRIPTION = '/subscription/:hash',

  // Listing
  LISTINGS = '/listings',
  LISTING_DETAILS = '/:address',
  LEASE = '/listings/lease',
  MARKET_OVERVIEW_WITH_TAG = '/listings/market-overview/:type/:state/:param1?/:param2?',
  NEAR_BY_SEARCH = '/nearby',
  VIEWED_LISTINGS = '/my/viewed-listings',

  // Agent
  AGENT = '/agent',
  AGENT_CONTACTS = '/agent/contacts',
  AGENT_MY_LISTINGS = '/agent/my-listings',
  AGENT_CREATE_NEW_LISTING = '/agent/my-listings/create-new-listing',
  AGENT_EDIT_DRAFT_LISTING = '/agent/my-listing/:id/edit-draft-listing',
  AGENT_EDIT_MY_LISTING = '/agent/my-listings/:id/edit-my-listing',
  AGENT_CONTACT = '/agent/contacts/:contactId',
  AGENT_CONTACT_MENU = '/agent/contacts/:contactId/menu',
  AGENT_CONTACT_BASIC_INFO = '/agent/contacts/:contactId/menu/basic-info',
  AGENT_SAVED_SEARCHES = '/agent/contacts/:contactId/menu/saved-search',
  AGENT_SAVED_SEARCH = '/agent/contacts/:contactId/saved-search/:savedSearchId',
  AGENT_LISTINGS = '/agent/contacts/:contactId/listings',
  AGENT_LISTINGS_LEASE = '/agent/contacts/:contactId/listings/lease',
  AGENT_LISTING_DETAILS = '/agent/contacts/:contactId/listing/:address',
  AGENT_CONTACT_VIEWED_PAGES = '/agent/contacts/:contactId/menu/viewed-pages',
  AGENT_COLLECTIONS = '/agent/contacts/:contactId/menu/collections',
  AGENT_COLLECTION = '/agent/contacts/:contactId/collections/:collectionId',
  AGENT_VIEWED_SEARCHES = '/agent/contacts/:contactId/menu/viewed-searches',
  AGENT_VIEWED_LISTINGS = '/agent/contacts/:contactId/viewed-listings',
  AGENT_INQUIRIES = '/agent/contacts/:contactId/menu/inquiries',
  AGENT_EMAIL_HISTORY = '/agent/contacts/:contactId/menu/email-history',
  AGENT_INQUIRY_HISTORY = '/agent/contacts/:contactId/menu/inquiry-history',
  AGENT_EMAIL_SETTINGS = '/agent/contacts/:contactId/menu/email-settings',
  AGENT_NOTIFICATION_INBOX = '/agent/contacts/:contactId/menu/notification-inbox',

  // Collection
  COLLECTIONS = '/menu/collections',
  COLLECTION = '/collections/:collectionId',
  JOIN_COLLECTION = '/join/listing/collection/:collectionId',

  // Saved Search
  SAVED_SEARCHES = '/menu/saved-searches',
  SAVED_SEARCH = '/saved-search/:savedSearchId',
  JOIN_SAVED_SEARCH = '/join/saved-search/:savedSearchId',
  VIEWED_SEARCHES = '/menu/viewed-searches',

  // 403 error
  FORBIDDEN = '/403-forbidden',

  // Not found mock
  NOT_FOUND = '/404-not-found',

  // Suffixes
  MY = '/my',

  // Postfixes
  COMMENT = '/comments/:id',
  COMMENTS = '/comments',
  COMMUTE_TIMES = '/commute-times',
  CONTACT = '/contact',
  LISTING_COMMENTS = '/listing-comments',
  LISTING_COMMENT = '/listing-comments/:listingId',
  LISTING_ADD_TO_COLLECTION = '/add-to-collection',
  REFER_ME = '/refer-me',
  REVIEW_ME = '/review-me',
  REQUEST_SPECIALIST = '/request-specialist',
  SETTINGS = '/settings',
  MEMBERS = '/members',
  ADD_MEMBERS = '/add-members',
  VIEWED_SEARCHES_POSTFIX = 'viewed-searches',
  VIEWED_LISTINGS_POSTFIX = 'viewed-listings',

  // IABS Not found mock
  IABS = '/iabs/:trecLicense/404-not-found',
}

export const PAGES_WITH_LISTINGS_AND_SEARCH_BAR: string[] = [
  RoutePath.LISTINGS,
  RoutePath.LEASE,
  RoutePath.SAVED_SEARCH,
  RoutePath.AGENT_LISTINGS,
  RoutePath.AGENT_LISTINGS_LEASE,
  RoutePath.AGENT_SAVED_SEARCH,
  RoutePath.IABS,
];
