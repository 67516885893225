import queryString from 'query-string';
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { fetchListingDetails } from 'listingDetails/listingDetailsActions';
import { fetchImages } from 'listings/listingsSagas';
import { addURLToItems } from 'listings/listingsSelectors';
import { Listing, ListingsImagesResponse } from 'listings/listingsTypes';
import { MyListingLayouts } from 'myListings/myListingsConstants';
import {
  constructUrl,
  convertKeysToLowerCase,
  mapImages,
  parseMyListingQueryUrl,
} from 'myListings/myListingsHelpers';
import {
  getMyListings,
  getMyListingsToRemove,
  getMyListingsType,
} from 'myListings/myListingsSelectors';
import { MyListingService } from 'myListings/MyListingsService';
import {
  applyMyListingsFilterValue,
  changeIsMyListingsFirst,
  changeMyListingDetailsPage,
  changeMyListingsFilterValue,
  changeMyListingsPage,
  changeMyListingsType,
  changeSortByMyListings,
  changeSortTypeMyListings,
  deleteMyListing,
  fetchMyListings,
  fetchMyListingsFailure,
  fetchMyListingsRequest,
  fetchMyListingsSuccess,
  mergeMyListings,
  MY_LISTINGS_DEFAULT_STATE,
  resetMyListingsAgentsFilter,
  resetMyListingsAllFilters,
  resetMyListingsPriceFilter,
  setMyListingsFromUrl,
  undoDeleteMyListing,
} from 'myListings/myListingsSlice';
import {
  MyListingsResponse,
  MyListingsState,
} from 'myListings/myListingsTypes';
import { not } from 'shared/helpers/boolean';
import {
  removeDefaultProperties,
  removeEmptyProperties,
} from 'shared/helpers/mainHelpers';
import { showErrorToast } from 'shared/helpers/notifications';
import history from 'shared/services/history';
import { BaseResponse } from 'shared/types';

export function* getMyListingsWithImages(listings: Listing[]) {
  try {
    const images: ListingsImagesResponse = yield call(fetchImages, {
      listings,
    });

    return listings.map(listingItem => {
      return {
        ...listingItem,
        media: mapImages(images[listingItem.listingkey] || []),
      };
    });
  } catch (e) {
    console.info('Could not fetch images');
    return listings.map(listing => ({ ...listing, media: [] }));
  }
}

export function* updateMyListingsUrlSaga(): Saga {
  try {
    const {
      page,
      isMyListingsFirst,
      sortBy,
      sortType,
      filters,
      currentTab,
    }: MyListingsState = yield select(({ myListings }) => myListings);

    const selectedFilters = {
      ...removeDefaultProperties(
        { currentTab, sortBy, sortType, isMyListingsFirst, page },
        MY_LISTINGS_DEFAULT_STATE
      ),
      agentId: filters?.agentId,
      minPrice: filters?.minPrice,
      maxPrice: filters?.maxPrice,
    };

    const query = queryString.stringify(
      removeEmptyProperties(selectedFilters),
      {
        sort: false,
      }
    );

    history.push({
      pathname: history.location.pathname,
      search: query ? `?${query}` : '',
      state: history.location.state,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* fetchMyListingsSaga(
  action:
    | ReturnType<typeof fetchMyListings>
    | ReturnType<typeof changeMyListingsType>
): Saga {
  try {
    if (action?.payload?.silentUpdate) {
      return;
    }

    if (not(fetchMyListings.match(action))) {
      yield call(updateMyListingsUrlSaga);
    }

    yield put(fetchMyListingsRequest());
    const url = yield call(constructUrl);

    const { data }: BaseResponse<MyListingsResponse> = yield call(
      MyListingService.getMyListings,
      url
    );

    const myListingsType: MyListingLayouts = yield select(getMyListingsType);

    const formattedListings =
      myListingsType === MyListingLayouts.draft
        ? data.listings.map(listingItem => {
            if (listingItem.media) {
              return {
                ...listingItem,
                media: mapImages(convertKeysToLowerCase(listingItem.media)),
              };
            }

            return listingItem;
          })
        : data.listings;

    const listingsWithUrls = addURLToItems({
      items: formattedListings,
      agentMode: false,
    });

    if (myListingsType === MyListingLayouts.draft) {
      yield put(
        fetchMyListingsSuccess({
          listings: listingsWithUrls,
          total: data.total,
        })
      );
    } else {
      const listingsWithImages = yield call(
        getMyListingsWithImages,
        listingsWithUrls
      );
      yield put(
        fetchMyListingsSuccess({
          listings: listingsWithImages,
          total: data.total,
        })
      );
    }
  } catch (e) {
    yield put(fetchMyListingsFailure({ error: e.message }));
  }
}

export function* deleteMyListingSaga(
  action: ReturnType<typeof deleteMyListing>
): Saga {
  try {
    const myListingsToRemove: string[] = yield select(getMyListingsToRemove);

    if (not(myListingsToRemove.includes(action.payload.listingId))) {
      return;
    }

    yield call(MyListingService.deleteMyListing, {
      idToRemove: action.payload.listingId,
      isDraft: action.payload.isDraft,
      agentId: action.payload.agentId,
      publishedDraftId: action.payload.publishedDraftId,
    });

    yield put(fetchMyListings());
  } catch (e) {
    showErrorToast('Exclusive Listing cannot be deleted. Something went wrong');
    yield put(undoDeleteMyListing({ listingId: action.payload.listingId }));
  }
}

export function* changeMyListingDetailsPageSaga(
  action: ReturnType<typeof changeMyListingDetailsPage>
): Saga {
  try {
    yield put(changeMyListingsPage({ page: action.payload.page }));
    yield take([fetchMyListingsSuccess, fetchMyListingsFailure]);
    const listings = yield select(getMyListings);

    const listingid = action.payload.fetchingNextPage
      ? listings[0].listingid
      : listings[listings.length - 1].listingid;

    yield put(fetchListingDetails(listingid));
  } catch (error) {
    action.payload.onError();
  }
}

export function* setMyListingsFromUrlSaga() {
  const filters = parseMyListingQueryUrl(history.location.search);

  yield put(mergeMyListings(filters));
  yield put(fetchMyListings());
}

const fetchMyListingsSub = () =>
  takeLatest(
    [
      applyMyListingsFilterValue,
      fetchMyListings,
      changeMyListingsPage,
      changeMyListingsFilterValue,
      changeSortByMyListings,
      changeSortTypeMyListings,
      changeIsMyListingsFirst,
      changeMyListingsType,
      resetMyListingsAgentsFilter,
      resetMyListingsAllFilters,
      resetMyListingsPriceFilter,
    ],
    fetchMyListingsSaga
  );

const deleteMyListingSub = () =>
  takeEvery([deleteMyListing], deleteMyListingSaga);

const changeMyListingDetailsPageSub = () =>
  takeLatest([changeMyListingDetailsPage], changeMyListingDetailsPageSaga);

const setMyListingsFromUrlSub = () =>
  takeLatest([setMyListingsFromUrl], setMyListingsFromUrlSaga);

export function* myListingsSagas() {
  yield all([
    fetchMyListingsSub(),
    deleteMyListingSub(),
    changeMyListingDetailsPageSub(),
    setMyListingsFromUrlSub(),
  ]);
}
