import { formatToTimeZone } from 'date-fns-timezone';
import dayjs from 'dayjs';
import { floor, round } from 'lodash';
import tzlookup from 'tz-lookup';

import { not } from 'shared/helpers/boolean';

export interface FormatTimeWithTimeZoneOptions {
  time: string;
  format?: string;
  latitude: string;
  longitude: string;
}

export function formatShortPrice(price: number, dollarSign = true): string {
  const shortPrice = formatNumberPostfix({ value: price });

  return addDollarSign(shortPrice, dollarSign);
}

export function formatPriceToNumber(price?: string): number {
  return Boolean(price) ? Number(price?.split('$')[1].split(',').join('')) : 0;
}

export function separateThousandsWithCommas(
  price?: number | string,
  dollarSign = true
): string {
  if (typeof price === 'number') {
    return addDollarSign(Number(price).toLocaleString(), dollarSign);
  }

  if (typeof price === 'string') {
    const isPrice = new RegExp(
      /(^\d+$)|(^\d+\.\d+$)|(^\d{1,3}$)|(^\d{1,3}(,\d{1,3})*$)|(^\d{1,3}(,\d{1,3})*(\.\d+)?$)/
    ).test(price);
    const isDollarPrefixPrice = new RegExp(
      /^[$]\d{1,3}(,\d{1,3})*(\.\d+)?/
    ).test(price);
    let normalizedString: string;
    let normalizedPrice: string;

    if (isDollarPrefixPrice) {
      normalizedString = price.split('$')[1].split(',').join('');
      normalizedPrice = Number(normalizedString).toLocaleString();
      return addDollarSign(normalizedPrice, dollarSign);
    }

    if (isPrice) {
      normalizedString = price.split(',').join('');
      normalizedPrice = Number(normalizedString).toLocaleString();
      return addDollarSign(normalizedPrice, dollarSign);
    }
  }

  return '';
}

// TODO: What if second less than first or defaultFirst
// eslint-disable-next-line max-params
export function formatTwoNumbersRange(
  first?: number,
  second?: number,
  defaultFirst = 0,
  formatValue = (val: number) => String(val)
): string {
  const twoNumberDefined = Boolean(first && second);

  if (twoNumberDefined && first === second) {
    return formatValue(first);
  }

  if (twoNumberDefined) {
    return `${formatValue(first)} - ${formatValue(second)}`;
  }

  if (first) {
    return `${formatValue(first)}+`;
  }

  if (second) {
    return `${formatValue(defaultFirst)} - ${formatValue(second)}`;
  }

  return 'Any';
}

export function addDollarSign(value: string, shouldAdd = true): string {
  if (not(shouldAdd) || value.includes('$')) {
    return value;
  }

  return `$${value}`;
}

export const formatNumberPostfix = ({
  value = 0,
  precision = 2,
  cutLabel = true,
}: {
  value?: number;
  precision?: number;
  cutLabel?: boolean;
}): string => {
  if (value < 1e3) return String(floor(value, precision));
  if (value >= 1e3 && value < 1e6) {
    const price = round(value / 1e3);
    return price === 1000 ? `${price / 1e3}M` : `${price}k`;
  }
  if (value >= 1e6 && value < 1e9)
    return `${round(value / 1e6, precision).toFixed(precision)}${
      cutLabel ? 'M' : ' million'
    }`;
  if (value >= 1e9 && value < 1e12)
    return `${floor(value / 1e9, precision)}${cutLabel ? 'B' : ' billion'}`;
  if (value >= 1e12)
    return `${floor(value / 1e12, precision)}${cutLabel ? 'T' : ' trillion'}`;

  return '';
};

// FIXME: If number will come 12..1 -> NaN
export const priceToNumber = (price: string): number => {
  return Number(price.replace(/[^0-9.]/g, ''));
};

export const percentDiffBetweenNumber = (
  firstNum: number,
  secondNum: number
): number => {
  const isInfinity = firstNum === 0;
  const isNaN = firstNum === 0 && secondNum === 0;

  if (isInfinity || isNaN || firstNum === secondNum) {
    return 0;
  }

  return (secondNum / firstNum) * 100;
};

export const percentOfNumber = (num: number, percent: number): number => {
  const isInfinity = num === 0;
  const isNaN = num === 0 && percent === 0;

  if (isInfinity || isNaN) {
    return 0;
  }

  return num * (percent / 100);
};

export const formatPercent = (percent = 0, percentSign = true): string => {
  const formattedPercent = parseFloat(percent.toFixed(2));

  if (percentSign) {
    return `${formattedPercent}%`;
  }

  return `${formattedPercent}`;
};

export const formatPhone = (
  value = '',
  format = '+X (XXX) XXX-XXXX'
): string => {
  const numbers: RegExpMatchArray = value.match(/\d/g);
  const formatNumbers: RegExpMatchArray = format.match(/X/g);

  if (not(numbers)) {
    return '';
  }

  if (numbers.length > formatNumbers.length) {
    return normalizePhone(value);
  }

  return format
    .replace(/X/gi, () => (numbers.length ? numbers.shift() : ''))
    .replace(/[\s()-]+$/g, '');
};

export const normalizePhone = (value = ''): string =>
  value.match(/(\d)|(^[+]?)/g).join('');

export const formatTimeWithTimeZone = (
  options: FormatTimeWithTimeZoneOptions
): string => {
  const { time, format = 'MM/DD/YYYY' } = options;

  if (not(time) || not(dayjs(time).isValid())) {
    return '';
  }

  try {
    const timeZone: string = tzlookup(options.latitude, options.longitude);
    return formatToTimeZone(time, format, { timeZone });
  } catch (error) {
    return time;
  }
};

/*
https://developers.google.com/maps/documentation/javascript/distancematrix#distance_matrix_responses
Description: fetchDistanceSaga fetches using DistanceMatrixService.getDistanceMatrix to ger distances
DistanceMatrixService.getDistanceMatrix(req, callback): google.maps.DistanceMatrixResponse
Response contain:
{
  ...
  rows: [
    ...
    {
      ...
      duration: google.maps.Duration
    }
  ]
}
google.maps.Duration contain field text - The textual value is formatted according to the unitSystem specified in the request (or in metric, if no preference was supplied
Example:
"duration": {
        "value": 96000,
        "text": "1 day 3 hours 21 mins"
      }
 */
export const formatDistanceMatrixDurationTextForUS = (time: string) =>
  time
    .replace(/hours?/, 'h.')
    .replace(/mins?/, 'min')
    .replace(/days?/, 'd.');

export const replaceDashesToSpacesInArray = (items: string[]) =>
  items.map(item => {
    return replaceDashesToSpaces(item);
  });

export const replaceSpacesToDashesInArray = (items: string[]) =>
  items.map(item => {
    return replaceSpacesToDashes(item);
  });

export const replaceDashesToSpaces = (value: string) =>
  value.replace(/-/g, ' ');

export const replaceSpacesToDashes = (value: string) =>
  value.replace(/\s/g, '-');

export const getBathroomTableView = (
  bathroomTotalInteger: number,
  bathroomfull: number
): string => {
  if (not(bathroomTotalInteger) && not(bathroomfull)) {
    return '0';
  }

  if (not(bathroomfull)) {
    return `${bathroomTotalInteger}`;
  }

  if (not(bathroomTotalInteger)) {
    return `${bathroomfull}`;
  }

  const halfBathroomCount = bathroomTotalInteger - bathroomfull;

  if (halfBathroomCount <= 0) {
    return `${bathroomTotalInteger}`;
  }

  return `${bathroomfull}/${halfBathroomCount}`;
};
