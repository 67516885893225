import { useEffect, useRef } from 'react';

export function usePrevious<T>(value: T, initialValue?: T) {
  const ref: { current: T } = useRef(initialValue);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
