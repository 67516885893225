import { isPrerenderUA } from 'prerender';
import { DeploymentTarget, ProductName } from 'shared/config/configConstants';

export interface ClientSideEnv {
  isPrerender: boolean;
}

export interface ServerSideEnv {
  bugsnagApiKey: string;
  butterCmsToken: string;
  strapiToken: string;
  strapiUrl: string;
  cognitoDomain: string;
  configFeatures: string;
  deploymentTarget: DeploymentTarget;
  enableWhyDidYorRender?: string;
  googleAnalyticsId?: string;
  googleClientId: string;
  googleTagId?: string;
  identityPoolId: string;
  mapKey: string;
  productName: ProductName;
  recaptchaSitekey: string;
  region: string;
  salesforceBaseUrl: string;
  agentsDefaultCoverImage: string;
  agentsCoverImagesS3: string;
  userPoolId: string;
  userPoolWebClient: string;
  brytecoreApiKey: string;
  ampBaseUrl: string;
}

export type Env = ServerSideEnv & ClientSideEnv;

const initialEnv: Env = {
  ...getServerSideEnv(),
  isPrerender: isPrerenderUA(navigator?.userAgent),
};

let env: Env = initialEnv;

patchProcessEnv(env);

export function getEnv(): Env {
  return env;
}

/** For test purposes only. Resets env back to global value. */
export function resetEnv(): void {
  env = initialEnv;
}

/** For test purposes only. Replaces env with custom value. */
export function setEnv(next: Env): void {
  env = next;
}

/** Temporary patch for `process.env` */
function patchProcessEnv(env: Env) {
  process.env.REACT_APP_BUGSNAG_API_KEY = env.bugsnagApiKey;
  process.env.REACT_APP_BUTTER_CMS_TOKEN = env.butterCmsToken;
  process.env.REACT_APP_STRAPI_TOKEN = env.strapiToken;
  process.env.REACT_APP_COGNITO_DOMAIN = env.cognitoDomain;
  process.env.REACT_APP_CONFIG_FEATURES = env.configFeatures;
  process.env.REACT_APP_DEPLOYMENT_TARGET = env.deploymentTarget;
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID = env.googleAnalyticsId;
  process.env.REACT_APP_GOOGLE_CLIENT_ID = env.googleClientId;
  process.env.REACT_APP_GOOGLE_TAG_ID = env.googleTagId;
  process.env.REACT_APP_IDENTITY_POOL_ID = env.identityPoolId;
  process.env.REACT_APP_MAP_KEY = env.mapKey;
  process.env.REACT_APP_PRODUCT_NAME = env.productName;
  process.env.REACT_APP_RECAPTCHA_SITEKEY = env.recaptchaSitekey;
  process.env.REACT_APP_REGION = env.region;
  process.env.REACT_APP_SALESFORCE_BASE_URL = env.salesforceBaseUrl;
  process.env.REACT_APP_AGENTS_COVER_IMAGES_S3 = env.agentsCoverImagesS3;
  process.env.REACT_APP_AGENTS_DEFAULT_COVER_IMAGE =
    env.agentsDefaultCoverImage;
  process.env.REACT_APP_USER_POOL_ID = env.userPoolId;
  process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID = env.userPoolWebClient;
  process.env.REACT_APP_WDYR = env.enableWhyDidYorRender;
  process.env.REACT_APP_BRYTECORE_API_KEY = env.brytecoreApiKey;
  process.env.REACT_APP_AMP_BASE_URL = env.ampBaseUrl;
}

function getServerSideEnv(): ServerSideEnv {
  // debugger;
  if (window.__envConfig) {
    return window.__envConfig;
  }

  if (window.location.hostname === 'localhost') {
    throw Error(
      'Global config is not defined! Cannot find or parse frontend/public/env.config.js. To add env.config.js do `npm run set-env --help` to see all available options, set proper env and restart the app.'
    );
  } else {
    throw Error(
      'Global config is not defined! Cannot find or parse env.config.js.'
    );
  }
}
