import styled from 'styled-components/macro';

import { scss } from 'styled-system/css';
import { createShouldForwardProp } from 'styled-system/lib/should-forward-prop';
import { space, SpaceProps } from 'styled-system/lib/space';
import { sx, SxProps } from 'styled-system/lib/sx';

export type BoxProps = SpaceProps & SxProps;

export const boxPropNames = [...space.propNames, 'sx'];

export const boxConfig = {
  shouldForwardProp: createShouldForwardProp(boxPropNames),
};

export const Box = styled.div.withConfig<BoxProps>(boxConfig)(
  scss({ boxSizing: 'border-box', color: theme => theme.colors.text1, m: 0 }),
  sx,
  space
);

Box.displayName = 'Box';
