import {
  ListingSources,
  LogoSize,
  ProductFullName,
  ProductName,
  ProductOrigins,
  SASocialMedia,
} from 'shared/config/configConstants';
import { ConfigType } from 'shared/config/configTypes';
import { sanAntonioSeo } from 'shared/config/productSeo';
import { ReactComponent as IconSALogoMobile } from 'shared/icons/logo/sa-logo-mobile.svg';
import { ReactComponent as IconSALogo } from 'shared/icons/logo/sa-logo.svg';

const config: ConfigType = {
  productName: ProductName.SA,
  productSource: ListingSources.Sabor,
  productFullName: ProductFullName.SA,
  productOrigin: ProductOrigins.SanAntonio,
  mapCenter: {
    lat: 29.491309659679796,
    lng: -98.4955308706051,
  },
  logos: {
    [LogoSize.Big]: IconSALogo,
    [LogoSize.Small]: IconSALogoMobile,
  },
  seoData: sanAntonioSeo,
  modalsData: {
    requestRelocationGuide: {
      message:
        'Please send me a free copy of the San Antonio Relocation Guide.',
    },
  },
  socialMedia: SASocialMedia,
};

export default Object.freeze(config);
