import styled, { createGlobalStyle, css } from 'styled-components/macro';

import { ReactComponent as IconSearchButton } from 'shared/components/SearchBar/icons/search.svg';
import customGallery from 'shared/styles/customGallery';
import customTooltip from 'shared/styles/customTooltip';

export const HiddenTitle = styled.h1`
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
`;

export const ImageContainerWithRedFrame = styled.div<{ mediaUrl: string }>`
  position: relative;
  z-index: 1;
  width: 51.5%;
  padding-top: 28%;
  border: 2px solid ${({ theme }) => theme.primary};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: url(${({ mediaUrl }) => mediaUrl});
    background-size: cover;
    background-position: 50% 50%;
    box-shadow: 0 2px 4px rgba(86, 96, 105, 0.3);
  }
`;

const grecaptchaBadge = css`
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${customGallery}
  ${customTooltip}
  ${grecaptchaBadge}
`;

export const IconSearchButtonStyled = styled(IconSearchButton)`
  fill: ${({ theme }) => theme.white};
  height: 16px;
  width: 16px;
`;

export const MOBILE_HEADER_HEIGHT = 50;
