import { BASE_URL } from 'myListings/myListingsConstants';
import {
  DraftListing,
  DraftListingData,
  DraftListingResponse,
  DublicatedListingResponse,
  FieldCatalog,
  MyListingsResponse,
  UpdateDraftListingData,
  UploadImageResponse,
} from 'myListings/myListingsTypes';
import { not } from 'shared/helpers/boolean';
import { ApiService } from 'shared/services/ApiService';
import { BaseResponse } from 'shared/types';

export class MyListingService {
  static async uploadListingImage(data: FormData) {
    return ApiService.request<UploadImageResponse>({
      method: 'POST',
      path: `/${BASE_URL}/media`,
      data,
      isFormData: true,
    });
  }

  static async deleteImage(imagesToDelete: string[]) {
    return ApiService.request<string>({
      method: 'DELETE',
      path: `/${BASE_URL}/media`,
      data: {
        mediaObjectIds: imagesToDelete,
      },
      resolve: res => Promise.resolve(res.statusText),
    });
  }

  static async getFieldCatalog() {
    return ApiService.request<FieldCatalog>({
      method: 'GET',
      path: `/${BASE_URL}/listing/field-catalog`,
    });
  }

  static async createDraftListing(data: {
    listing: DraftListingData | UpdateDraftListingData;
    agentId: string;
  }) {
    return ApiService.request<
      BaseResponse<{
        listing: DraftListing;
      }>
    >({
      method: 'POST',
      path: `/exclusive-listing/create?agentId=${data.agentId}`,
      data: data.listing,
      prefix: 'v2',
    });
  }

  static async publishMyListing(data: { listingId: string; agentId: string }) {
    return ApiService.request({
      method: 'POST',
      path: `/exclusive-listing/publish/${data.listingId}?agentId=${data.agentId}`,
      prefix: 'v2',
    });
  }

  static async republishMyListing(data: {
    listingId: string;
    agentId: string;
    listing: UpdateDraftListingData;
  }) {
    return ApiService.request({
      method: 'POST',
      path: `/exclusive-listing/republish/${data.listingId}?agentId=${data.agentId}`,
      data: data.listing,
      prefix: 'v2',
    });
  }

  static async getMyListings(query: URL) {
    return ApiService.request<BaseResponse<MyListingsResponse>>({
      method: 'GET',
      path: query,
      createFullUrl: false,
    });
  }

  static async getDuplicatedListing(address: string) {
    return ApiService.request<DublicatedListingResponse>({
      method: 'GET',
      path: `${BASE_URL}/listing/draft/allowed?address=${address}`,
    });
  }

  static async getDraftListing(listingId: string) {
    if (not(listingId)) {
      return null;
    }
    return ApiService.request<DraftListingResponse>({
      method: 'GET',
      path: `/exclusive-listing/details/${listingId}`,
      prefix: 'v2',
    });
  }

  static async updateListing(listingId: string, data: UpdateDraftListingData) {
    return ApiService.request<
      BaseResponse<{
        listing: DraftListing;
      }>
    >({
      method: 'PATCH',
      path: `/exclusive-listing/update/${listingId}`,
      prefix: 'v2',
      data,
      allowNullValues: true,
    });
  }

  static async deleteMyListing(query: {
    idToRemove: string;
    agentId: string;
    publishedDraftId?: string;
    isDraft?: boolean;
  }) {
    return ApiService.request({
      method: 'DELETE',
      path: `/exclusive-listing/delete`,
      query: {
        ...(not(query.isDraft) && { listingId: query.idToRemove }),
        agentId: query.agentId,
        draftId: query.isDraft ? query.idToRemove : query.publishedDraftId,
      },
      prefix: 'v2',
    });
  }
}
