import { Nullable } from 'shared/types';

/* Native [].includes but with type narrowing */
export function includes<A>(collection: A[], value: any): value is A {
  return collection.includes(value);
}

interface ArrayLike<T> {
  readonly length: number;
  readonly [n: number]: T;
}

export function first<T>(input: ArrayLike<T>, ensure: true): T;
export function first<T>(input: Nullable<ArrayLike<T>>): T | undefined;
/** Returns first element of an array */
export function first<T>(input: Nullable<ArrayLike<T>>, _ensure?: boolean) {
  return input ? input[0] : undefined;
}

export function last<T>(input: ArrayLike<T>, ensure: true): T;
export function last<T>(input: Nullable<ArrayLike<T>>): T | undefined;
/** Returns last element of an array */
export function last<T>(input: Nullable<ArrayLike<T>>, _ensure?: boolean) {
  return input ? input[input.length - 1] : undefined;
}

/** Wraps input in an array if it's not one */
export function toArray<T>(input: T | T[]): T[] {
  return Array.isArray(input) ? input : Array.of(input);
}
