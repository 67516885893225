import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NOT_OPENED_NOTIFICATION_HISTORY_STATUSES } from 'history/notificationHistory/NotificationHistory/notificationHistoryConstants';
import {
  NotificationHistoryFilters,
  NotificationHistoryFiltersAndSorting,
  NotificationHistoryItem,
  NotificationHistorySortBy,
  NotificationHistoryState,
  NotificationHistoryStatus,
  NotificationHistoryTypeFilter,
} from 'history/notificationHistory/notificationHistoryTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { effect } from 'shared/helpers/redux';
import { Paginated } from 'shared/types';

export const NOTIFICATION_HISTORY_DEFAULT_FILTERS: NotificationHistoryFilters =
  {
    period: 0,
    page: 1,
    sortType: SortOrder.DESC,
    sortBy: NotificationHistorySortBy.DateTime,
    type: NotificationHistoryTypeFilter.All,
  };

export const NOTIFICATION_HISTORY_DEFAULT_STATE: NotificationHistoryState = {
  items: [],
  notificationRawHtmls: {},
  loading: false,
  markAllInProgress: false,
  total: 0,
  limit: 20,
  error: '',
  ...NOTIFICATION_HISTORY_DEFAULT_FILTERS,
};

const notificationHistorySlice = createSlice({
  name: 'notificationHistory',
  initialState: NOTIFICATION_HISTORY_DEFAULT_STATE,
  reducers: {
    fetchNotificationHistory: (
      state,
      action: PayloadAction<{ urlFilters: Partial<NotificationHistoryFilters> }>
    ) => {
      Object.assign(state, { loading: true, ...action.payload.urlFilters });
    },
    fetchNotificationHistorySuccess: (
      state,
      action: PayloadAction<{
        notificationHistoryItems: Paginated<NotificationHistoryItem>;
      }>
    ) => {
      state.items = action.payload.notificationHistoryItems.hits;
      state.total = action.payload.notificationHistoryItems.total;
      state.loading = false;
      state.error = '';
    },
    fetchNotificationHistoryFailure: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    fetchNotificationHistoryItem: effect<{
      notificationId: string;
      notificationStatus: NotificationHistoryStatus;
    }>(),
    fetchNotificationHistoryItemSuccess: (
      state,
      action: PayloadAction<{
        notificationId: string;
        notificationRawHtml: string;
      }>
    ) => {
      state.notificationRawHtmls[action.payload.notificationId] =
        action.payload.notificationRawHtml;
      state.error = '';
    },
    updateNotificationHistoryItem: effect<{
      notificationId: string;
      data: Partial<NotificationHistoryItem>;
      cb?: () => unknown;
    }>(),
    updateNotificationHistoryItemSuccess: (
      state,
      action: PayloadAction<{
        notificationHistoryItem: NotificationHistoryItem;
      }>
    ) => {
      state.items = state.items.map(item => {
        if (item.id === action.payload.notificationHistoryItem.id) {
          return action.payload.notificationHistoryItem;
        }
        return item;
      });
    },
    markAllNotificationsAsRead: (
      state,
      action: PayloadAction<{
        notificationHistoryCount: number;
        items: NotificationHistoryItem[];
      }>
    ) => {
      state.markAllInProgress = true;
    },
    markAllNotificationsAsReadSuccess: state => {
      state.markAllInProgress = false;
    },
    markAllNotificationsAsReadFailure: state => {
      state.markAllInProgress = false;
    },
    markAllNotificationsAsReadInStore: state => {
      state.items = state.items.map(item => ({
        ...item,
        status: NOT_OPENED_NOTIFICATION_HISTORY_STATUSES.includes(item.status)
          ? NotificationHistoryStatus.Open
          : item.status,
      }));
    },
    undoMarkAllNotificationsAsRead: (
      state,
      action: PayloadAction<{
        notificationHistoryCount: number;
        items: NotificationHistoryItem[];
      }>
    ) => {
      state.items = action.payload.items;
    },
    markAllNotificationsAsReadRequest: effect<{
      notificationHistoryCount: number;
      items: NotificationHistoryItem[];
    }>(),
    changePageOnNotificationHistory: (
      state,
      action: PayloadAction<{
        page: number;
      }>
    ) => {
      state.page = action.payload.page;
    },
    resetNotificationFilters: state => {
      Object.assign(state, NOTIFICATION_HISTORY_DEFAULT_STATE, {
        loading: true,
      });
    },
    resetNotificationHistory: () => NOTIFICATION_HISTORY_DEFAULT_STATE,
    sortNotificationHistory: (
      state,
      action: PayloadAction<{
        sortBy: NotificationHistorySortBy;
        sortType: SortOrder;
      }>
    ) => {
      state.sortType = action.payload.sortType;
      state.sortBy = action.payload.sortBy;
    },
    filterByType: (
      state,
      action: PayloadAction<{ typeFilter: NotificationHistoryTypeFilter }>
    ) => {
      state.type = action.payload.typeFilter;
      state.loading = true;
      state.page = 1;
    },
    filterByPeriod: (
      state,
      action: PayloadAction<{ periodFilter: number }>
    ) => {
      state.period = action.payload.periodFilter;
      state.loading = true;
      state.page = 1;
    },
    applyFiltersAndSortings: (
      state,
      action: PayloadAction<NotificationHistoryFiltersAndSorting>
    ) => {
      state.period = action.payload.period;
      state.type = action.payload.type;
      state.sortType = action.payload.sortType;
      state.sortBy = action.payload.sortBy;
    },
  },
});

export const notificationHistoryReducer = notificationHistorySlice.reducer;

export const {
  fetchNotificationHistory,
  fetchNotificationHistorySuccess,
  fetchNotificationHistoryFailure,
  fetchNotificationHistoryItem,
  fetchNotificationHistoryItemSuccess,
  sortNotificationHistory,
  changePageOnNotificationHistory,
  resetNotificationHistory,
  filterByType,
  filterByPeriod,
  applyFiltersAndSortings,
  updateNotificationHistoryItem,
  updateNotificationHistoryItemSuccess,
  markAllNotificationsAsRead,
  markAllNotificationsAsReadSuccess,
  markAllNotificationsAsReadFailure,
  markAllNotificationsAsReadInStore,
  undoMarkAllNotificationsAsRead,
  markAllNotificationsAsReadRequest,
  resetNotificationFilters,
} = notificationHistorySlice.actions;
