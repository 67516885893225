import { get } from 'lodash';

import { not } from 'shared/helpers/boolean';
import globalTheme from 'shared/styles/globalTheme';
import { StyledSystemInput, styleFn } from 'styled-system/lib/types';

type AnyObject = { [x: string]: any };

const defaultBreakpoints = [40, 52, 64].map(n => n + 'em');
const createMediaQuery = (n: string) => `@media screen and (min-width: ${n})`;
const defaultMediaQueries = defaultBreakpoints.map(createMediaQuery);

const aliases = {
  bg: 'backgroundColor',
  m: 'margin',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mr: 'marginRight',
  mt: 'marginTop',
  mx: 'marginX',
  my: 'marginY',
  p: 'padding',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  pr: 'paddingRight',
  pt: 'paddingTop',
  px: 'paddingX',
  py: 'paddingY',
};

const multiples = {
  marginX: ['marginLeft', 'marginRight'],
  marginY: ['marginTop', 'marginBottom'],
  paddingX: ['paddingLeft', 'paddingRight'],
  paddingY: ['paddingTop', 'paddingBottom'],
  size: ['width', 'height'],
};

const scales = {
  backgroundColor: 'colors',
  border: 'borders',
  borderBottom: 'borders',
  borderBottomColor: 'colors',
  borderBottomLeftRadius: 'radii',
  borderBottomRightRadius: 'radii',
  borderBottomStyle: 'borderStyles',
  borderBottomWidth: 'borderWidths',
  borderColor: 'colors',
  borderLeft: 'borders',
  borderLeftColor: 'colors',
  borderLeftStyle: 'borderStyles',
  borderLeftWidth: 'borderWidths',
  borderRadius: 'radii',
  borderRight: 'borders',
  borderRightColor: 'colors',
  borderRightStyle: 'borderStyles',
  borderRightWidth: 'borderWidths',
  borderStyle: 'borderStyles',
  borderTop: 'borders',
  borderTopColor: 'colors',
  borderTopLeftRadius: 'radii',
  borderTopRightRadius: 'radii',
  borderTopStyle: 'borderStyles',
  borderTopWidth: 'borderWidths',
  borderWidth: 'borderWidths',
  bottom: 'space',
  boxShadow: 'shadows',
  color: 'colors',
  columnGap: 'space',
  flexBasis: 'sizes',
  fontFamily: 'fonts',
  fontSize: 'fontSizes',
  fontWeight: 'fontWeights',
  gap: 'space',
  gridColumnGap: 'space',
  gridGap: 'space',
  gridRowGap: 'space',
  height: 'sizes',
  left: 'space',
  letterSpacing: 'letterSpacings',
  lineHeight: 'lineHeights',
  margin: 'space',
  marginBottom: 'space',
  marginLeft: 'space',
  marginRight: 'space',
  marginTop: 'space',
  marginX: 'space',
  marginY: 'space',
  maxHeight: 'sizes',
  maxWidth: 'sizes',
  minHeight: 'sizes',
  minWidth: 'sizes',
  outlineColor: 'colors',
  padding: 'space',
  paddingBottom: 'space',
  paddingLeft: 'space',
  paddingRight: 'space',
  paddingTop: 'space',
  paddingX: 'space',
  paddingY: 'space',
  right: 'space',
  rowGap: 'space',
  size: 'sizes',
  textShadow: 'shadows',
  top: 'space',
  width: 'sizes',
  zIndex: 'zIndices',
  // svg
  fill: 'colors',
  stroke: 'colors',
};

const positiveOrNegative = (scale: any, value: any) => {
  if (typeof value !== 'number' || value >= 0) {
    return get(scale, value, value);
  }
  const absolute = Math.abs(value);
  const n = get(scale, absolute, absolute);
  if (typeof n === 'string') return '-' + n;
  return n * -1;
};

const transforms = [
  'bottom',
  'left',
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'marginX',
  'marginY',
  'right',
  'top',
].reduce((acc, curr) => ({ ...acc, [curr]: positiveOrNegative }), {});

type Css = (input?: StyledSystemInput) => styleFn;

const exists = (x: any): boolean => x !== null && x !== undefined;

export const responsive =
  (styles: any) =>
  (theme: any): AnyObject => {
    const next: AnyObject = {};
    const mediaQueries = get(theme, 'breakpoints', defaultMediaQueries);

    for (const key in styles) {
      if (exists(styles[key])) {
        const style = styles[key];
        const value = typeof style === 'function' ? style(theme) : style;
        if (exists(value)) {
          if (not(Array.isArray(value))) {
            next[key] = value;
            continue;
          }

          for (let i = 0; i < value.slice(0, mediaQueries.length).length; i++) {
            const media = mediaQueries[i];
            if (!media) {
              next[key] = value[i];
              continue;
            }
            next[media] = next[media] || {};
            if (value[i]) {
              next[media][key] = value[i];
            }
          }
        }
      }
    }

    return next;
  };

export const css: Css = input => props => {
  // just for the tests, need to wrap everything in ThemeProvider
  const theme = { ...globalTheme, ...props.theme };
  let result: AnyObject = {};
  const output = typeof input === 'function' ? input(theme) : input;
  const styles = responsive(output)(theme);

  for (const key in styles) {
    if (exists(styles[key])) {
      const x = styles[key];
      const val = typeof x === 'function' ? x(theme) : x;

      if (key === 'variant') {
        const variant = css(get(theme, val))({ theme }) as AnyObject;
        result = { ...result, ...variant };
        continue;
      }

      if (val && typeof val === 'object') {
        result[key] = css(val)({ theme });
        continue;
      }

      const prop = get(aliases, key, key);
      const scaleName = get(scales, prop);
      const scale = get(theme, scaleName, get(theme, prop, {}));
      const transform = get(transforms, prop, get);
      const value = transform(scale, val, val);

      if (prop in multiples) {
        const dirs: string[] = get(multiples, prop);
        for (let i = 0; i < dirs.length; i++) {
          result[dirs[i]] = value;
        }
      } else {
        result[prop] = value;
      }
    }
  }

  return result;
};

export default css;
