import { get, isEqual, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

import { getAgentMode, getContactItem } from 'contacts/contactsSelectors';
import { FILTERS_DEFAULT_STATE } from 'filters/filtersReducer';
import { getNewMapPosition } from 'map/mapHelper';
import { getMapSize } from 'map/mapSelectors';
import { MapState } from 'map/mapTypes';
import { RootState } from 'rootReducer';
import {
  extractLeaseFromSearchQuery,
  extractMapDataFromSavedOrViewedSearchQuery,
} from 'saved-search/savedSearchHelpers';
import { SavedSearch, ViewedSearch } from 'saved-search/savedSearchTypes';
import { SearchDataParser } from 'saved-search/services/SearchDataParser';
import { getTags } from 'search/search-selectors';
import { Tag } from 'search/search-types';
import { not, notEmpty } from 'shared/helpers/boolean';
import { getProfile } from 'user/userSelectors';

const getFilters = (state: RootState) => state.filters;

const getSavedSearch = (state: RootState) => {
  const item = state.savedSearch.item as SavedSearch;

  return {
    filters: item.filters,
    tags: item.tags,
    query: item.query,
  };
};

export const getCurrentSavedSearch = (state: RootState) =>
  state.savedSearch.item;

const getCurrentMapData = (state: RootState, mapType: keyof MapState) => ({
  mapBoundary: state.map[mapType].mapBoundary,
  zoom: state.map[mapType].zoom,
  center: state.map[mapType].center,
});

const getForNameData = (state: RootState) => ({
  contact: state.contacts.contact,
  profile: state.user.profile,
  tags: state.search.tags,
});

export const getIsLeaseSavedSearch = createSelector(
  [getCurrentSavedSearch],
  search => Boolean(search.id && extractLeaseFromSearchQuery(search.query))
);

const getViewedSearchNamesData = (state: RootState) => ({
  contact: state.contacts.contact,
  viewedSearches: state.savedSearch.viewedSearches,
});

const getSavedSearchToRemove = (state: RootState) =>
  state.savedSearch.savedSearchToRemove;

const getSearches = (state: RootState) => state.savedSearch.searches;

export const getExistingSearches = createSelector(
  [getSearches, getSavedSearchToRemove],
  (searches, savedSearchToRemove) =>
    searches.filter(search => !savedSearchToRemove.includes(search.id))
);

export const getTagsMask = createSelector([getTags], tags => {
  return tags.reduce((newTags: Tag[], tag: Tag) => {
    if (tag.tags) {
      if (not(tag.open)) {
        newTags.push(...tag.tags);
      }
    } else {
      newTags.push(tag);
    }
    return newTags;
  }, []);
});

export const getSaveSearchName = createSelector(
  [getForNameData],
  ({ profile, contact, tags = [] }) => {
    const username = notEmpty(contact.item)
      ? get(contact, 'item.firstname')
      : get(profile, 'data.firstname');
    const tagLabels = tags.map(({ label }) => label);
    return `${username ? `${username}'s` : ''} ${tagLabels.join(
      ', '
    )} Saved Search`;
  }
);

export const getSaveSearchNamesForViewed = createSelector(
  [getViewedSearchNamesData],
  ({ contact, viewedSearches }) => {
    const firstname = contact.item ? contact.item.firstname : '';
    const hits: ViewedSearch[] = get(viewedSearches, 'items.hits');

    if (hits && hits.length) {
      return hits.map((viewedSearch: ViewedSearch) => {
        const { tags = [] } = SearchDataParser.parse(viewedSearch.searchData);
        const tagLabels = tags.map(({ label }) => label);

        return `${firstname ? `${firstname}'s` : ''} ${tagLabels.join(
          ', '
        )} Saved Search`;
      });
    }

    return [];
  }
);

export const isSavedSearchChanged = createSelector(
  [getFilters, getTagsMask, getSavedSearch, getCurrentMapData, getMapSize],
  // eslint-disable-next-line max-params
  (filters, tags, savedSearch, currentMapData, mapSize) => {
    const pageFilters = {
      filters,
      tags: uniqBy(tags, 'value'),
    };

    const savedSearchFilters = {
      filters: {
        ...FILTERS_DEFAULT_STATE,
        ...savedSearch.filters,
      },
      tags: savedSearch.tags,
    };

    const savedSearchMapData = extractMapDataFromSavedOrViewedSearchQuery(
      savedSearch.query
    );

    let updatedSavedSearchMapData;

    if (mapSize && savedSearchMapData.mapBoundary) {
      const { center, zoom, boundaries } = getNewMapPosition(
        savedSearchMapData.mapBoundary,
        mapSize
      );

      updatedSavedSearchMapData = {
        center,
        zoom,
        mapBoundary: boundaries,
      };
    }

    const isFiltersEqual = isEqual(pageFilters, savedSearchFilters);
    const isMapDataEqual = isEqual(currentMapData, updatedSavedSearchMapData);

    return !isFiltersEqual || !isMapDataEqual;
  }
);

export const isSavedSearchOwner = createSelector(
  [getCurrentSavedSearch, getContactItem, getAgentMode, getProfile],
  // eslint-disable-next-line max-params
  (savedSearch, contactItem, agentMode, profile) => {
    return (
      Boolean(savedSearch.user?.username) &&
      ((agentMode &&
        contactItem &&
        contactItem.username === savedSearch.user?.username) ||
        (!agentMode &&
          profile.data &&
          profile.data.username === savedSearch.user?.username))
    );
  }
);
