/*global google*/
import { Bounds, Coords, fitBounds } from 'google-map-react';

import {
  CUSTOM_POLYGON_TYPE,
  MAP_CONTAINER_PERCENTAGE_SIZE,
} from 'map/mapConstants';
import { Boundary, GoogleMaps, MapSize } from 'map/mapTypes';
import { Tag } from 'search/search-types';
import { HeaderHeights } from 'shared/components/Header/HeaderConstants';
import { not } from 'shared/helpers/boolean';
import { UserLocation } from 'user/userTypes';

export const _fitBounds = (
  boundary: Boundary,
  size: MapSize
): {
  newBounds: Bounds;
  center: { lat: number; lng: number };
  zoom: number;
} => {
  const bounds = {
    sw: {
      lat: boundary.maxLatitude,
      lng: boundary.minLongitude,
    },
    ne: {
      lat: boundary.minLatitude,
      lng: boundary.maxLongitude,
    },
  };

  return fitBounds(bounds, size);
};

export const calculateNewMapPosition = (
  boundary: Boundary,
  isMobile: boolean
) => {
  const mapSize = {
    width: isMobile
      ? window.innerWidth
      : (window.innerWidth / 100) * MAP_CONTAINER_PERCENTAGE_SIZE,
    height: isMobile
      ? window.innerHeight - HeaderHeights.ListingsMobile
      : window.innerHeight - HeaderHeights.ListingsDesktop,
  };

  return getNewMapPosition(boundary, mapSize);
};

export const getNewMapPosition = (boundary: Boundary, size: MapSize) => {
  const { zoom, newBounds, center } = _fitBounds(boundary, size);

  const boundaries = {
    maxLatitude: newBounds.nw.lat,
    minLongitude: newBounds.nw.lng,
    minLatitude: newBounds.se.lat,
    maxLongitude: newBounds.se.lng,
  };

  return {
    zoom,
    boundaries,
    center,
  };
};

export const getBoundariesFromMap = (
  map: GoogleMaps['Map'],
  maps: GoogleMaps
): Boundary => {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const nw = new maps.LatLng(ne.lat(), sw.lng());
  const se = new maps.LatLng(sw.lat(), ne.lng());

  return {
    maxLatitude: nw.lat(),
    minLongitude: nw.lng(),
    minLatitude: se.lat(),
    maxLongitude: se.lng(),
  };
};

export const isCurrentMapBoundariesEqualListingsBoundaries = (
  listingsBoundaries: {
    west: number;
    east: number;
    south: number;
    north: number;
  },
  boundaries: Boundary
) => {
  return (
    listingsBoundaries.west === boundaries.minLongitude &&
    listingsBoundaries.east === boundaries.maxLongitude &&
    listingsBoundaries.north === boundaries.maxLatitude &&
    listingsBoundaries.south === boundaries.minLatitude
  );
};

export const isMapBoundaryValid = (mapBoundary: Boundary) => {
  const isBoundariesInvalid =
    isNaN(mapBoundary.minLatitude) ||
    isNaN(mapBoundary.minLongitude) ||
    isNaN(mapBoundary.maxLatitude) ||
    isNaN(mapBoundary.maxLongitude);

  return not(isBoundariesInvalid);
};

export const calculateMapDataFromUserLocation = (
  userLocation: UserLocation
): Boundary => {
  // 1/79 it's a 1km
  const radius = 1 / 79;
  return {
    minLatitude: userLocation.latitude - radius,
    maxLongitude: userLocation.longitude + radius,
    maxLatitude: userLocation.latitude + radius,
    minLongitude: userLocation.longitude - radius,
  };
};

export const isTemporaryCustomTag = (tag: Tag) =>
  tag.type === CUSTOM_POLYGON_TYPE && tag.isTemporary;

export const drawPanorama = ({
  isMobile,
  streetViewNode,
  streetViewPosition,
}: {
  isMobile: boolean;
  streetViewNode: HTMLDivElement;
  streetViewPosition: Coords;
}): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const svService = new google.maps.StreetViewService();
    const pano = {
      location: streetViewPosition,
      preference: google.maps.StreetViewPreference.NEAREST,
      source: google.maps.StreetViewSource.OUTDOOR,
    };

    svService.getPanorama({ ...pano, radius: 500 }, (data, status) => {
      if (status === google.maps.StreetViewStatus.OK) {
        const heading = google.maps.geometry.spherical.computeHeading(
          data.location.latLng,
          new google.maps.LatLng(streetViewPosition.lat, streetViewPosition.lng)
        );
        const panorama = new google.maps.StreetViewPanorama(streetViewNode, {
          position: streetViewPosition,
          pano: data.location.pano,
          fullscreenControl: false,
          panControl: false,
          addressControl: false,
          linksControl: false,
          zoomControl: !isMobile,
          zoom: 0,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
        });
        panorama.setPov({
          pitch: 10,
          heading,
        });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
