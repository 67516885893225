import * as H from 'history';
import { omit } from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import { getListingDetailsId } from 'listings/listingsHelpers';
import { useIsRouteModal } from 'shared/components/ModalRouteSwitcher/useIsRouteModal';
import { RoutePath } from 'shared/constants/routesConstants';
import { not } from 'shared/helpers/boolean';
import { loadable } from 'shared/utils/loadable';

const AsyncListingDetails = loadable(
  () => import(/* webpackChunkName: "listing-details" */ 'listingDetails')
);

export const useModalMatches = () => {
  return {
    listingComments: useRouteMatch(
      RoutePath.LISTING_DETAILS + RoutePath.LISTING_COMMENT
    ),
    agentListingComments: useRouteMatch(
      RoutePath.AGENT_LISTING_DETAILS + RoutePath.LISTING_COMMENT
    ),
    listingAddToCollection: useRouteMatch(
      RoutePath.LISTING_DETAILS + RoutePath.LISTING_ADD_TO_COLLECTION
    ),
    agentListingAddToCollection: useRouteMatch(
      RoutePath.AGENT_LISTING_DETAILS + RoutePath.LISTING_ADD_TO_COLLECTION
    ),
  };
};

const ModalRouteSwitchFC = (props: { children?: React.ReactNode }) => {
  const matches = useModalMatches();
  const { isModal, previousLocation } = useIsRouteModal();
  const history = useHistory();
  const location = useLocation();

  const closeModal = () => history.push(previousLocation.current);

  const renderListingDetails = (props: RouteComponentProps) => {
    const listingId = getListingDetailsId(props.match.url);
    return (
      listingId && (
        <AsyncListingDetails
          {...props}
          closeModal={closeModal}
          isModal
          isOpen
          key="ListingDetails"
          listingId={listingId}
          goBack={history.goBack}
        />
      )
    );
  };

  const innerLocation = isModal ? previousLocation.current : location;
  const innerLocationOmitModal = omit(innerLocation, [
    'state.modal',
  ]) as H.Location;
  const listingDetailsIsExact =
    not(matches.listingComments) && not(matches.listingAddToCollection);
  const agentListingDetailsIsExact =
    not(matches.agentListingComments) &&
    not(matches.agentListingAddToCollection);

  return (
    <div>
      <Switch location={innerLocationOmitModal}>{props.children}</Switch>
      {isModal && (
        <Switch>
          <Route
            exact={agentListingDetailsIsExact}
            path={RoutePath.AGENT_LISTING_DETAILS}
            render={renderListingDetails}
          />
          <Route
            exact={listingDetailsIsExact}
            path={RoutePath.LISTING_DETAILS}
            render={renderListingDetails}
          />
        </Switch>
      )}
    </div>
  );
};

export default ModalRouteSwitchFC;
