import { useField } from 'formik';
import { useEffect } from 'react';

import { viewsOptions } from 'filters/filtersContants';
import MultiSelect from 'shared/components/MultiSelect/index';
import {
  MultiSelectOption,
  MultiSelectProps,
} from 'shared/components/MultiSelect/MultiSelect';
import { ErrorMessagePlacement } from 'shared/types/errorTypes';
import { Box } from 'styled-system/components/index';
import { StyledSystemInput } from 'styled-system/lib/types';

interface MultiSelectFieldProps extends MultiSelectProps {
  name: string;
  sx?: StyledSystemInput;
  errorMessagePlacement?: ErrorMessagePlacement;
}

export const MultiSelectField = (props: MultiSelectFieldProps) => {
  const { sx, name, ...restProps } = props;

  const [field, meta, helpers] = useField({ name });

  useEffect(() => {
    return () => helpers.setValue([]);
  }, []);

  const value = (
    restProps.options as ReadonlyArray<MultiSelectOption<string>>
  )?.filter(option => field.value?.includes(option.value));

  const handleSelectChange = (values: typeof viewsOptions) => {
    const options = values.map(({ value }) => value);
    helpers.setError(undefined);
    helpers.setValue(options);
  };

  return (
    <Box sx={sx}>
      <MultiSelect
        {...field}
        value={value || []}
        onChange={handleSelectChange}
        error={Boolean(meta.error)}
        message={meta.error}
        {...restProps}
      />
    </Box>
  );
};
