import { Form, Formik, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import CommunityFeaturesFilter from 'filters/CommunityFeaturesFilter';
import FiltersFooter from 'filters/components/FiltersFooter';
import { applyFilters, resetSecondaryFilters } from 'filters/filtersActions';
import { prepareSecondaryFilters } from 'filters/filtersHelpers';
import { $getAppliedMoreFiltersCount } from 'filters/filtersSelectors';
import {
  collapsePanelOverridesForMoreFilters,
  FiltersDivider,
} from 'filters/filtersStyled';
import { SecondaryFiltersForm } from 'filters/filtersTypes';
import { GreatSchoolsFilter } from 'filters/GreatSchoolsFilter/GreatSchoolsFilter';
import HomeFeaturesFilter from 'filters/HomeFeaturesFilter';
import KeywordsFilter from 'filters/KeywordsFilter';
import PropertyFeaturesFilter from 'filters/PropertyFeaturesFilter';
import PropertyTypeFilter from 'filters/PropertyTypeFilter';
import ShowOnlyFilter from 'filters/ShowOnlyFilter';
import SizeFilter from 'filters/SizeFilter';
import SourceFilter from 'filters/SourceFilter';
import StatusFilter from 'filters/StatusFilter/StatusFilter';
import DaysOnFilter from 'filters/TimeframeFilter/index';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useEffectOnUpdate } from 'shared/hooks/use-effect-on-update';
import { useTrackedRef } from 'shared/hooks/useTrackedRef';
import { media } from 'styled-system/responsive';
import { getIsAgent } from 'user/userSelectors';

interface MoreFiltersProps {
  disabled: boolean;
  initialValues: SecondaryFiltersForm;
  isAgentMode: boolean;
  onSubmit: () => void;
}

export default function MoreFilters(props: MoreFiltersProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
    >
      <Form>
        <FiltersForm
          disabled={Boolean(props.disabled)}
          isAgentMode={props.isAgentMode}
        />
        <SyncFormikWithRedux />
      </Form>
    </Formik>
  );
}

function SyncFormikWithRedux(): null {
  const dispatch = useDispatch();
  const { initialValues, values } = useFormikContext<SecondaryFiltersForm>();

  useEffectOnUpdate(() => {
    if (values !== initialValues) {
      dispatch(applyFilters(prepareSecondaryFilters(values)));
    }
  }, [values]);

  return null;
}

interface FiltersFormProps {
  disabled: boolean;
  isAgentMode: boolean;
}

function FiltersForm(props: FiltersFormProps) {
  const dispatch = useDispatch();

  const isAgent = useSelector(getIsAgent);
  const changedFiltersCount = useSelector($getAppliedMoreFiltersCount);
  const filtersChanged = changedFiltersCount > 0;

  const [containerRef, containerNode] = useTrackedRef<HTMLDivElement>();

  return (
    <>
      <Container isAgentMode={props.isAgentMode} ref={containerRef}>
        <SourceFilter disableFilter={props.disabled} />
        <StatusFilter disableFilter={props.disabled} />
        <DaysOnFilter disableFilter={props.disabled} />
        <ShowOnlyFilter disableFilter={props.disabled} />
        <FiltersDivider />
        <PropertyTypeFilter disableFilter={props.disabled} />
        <FiltersDivider />
        <SizeFilter disableFilter={props.disabled} />
        <FiltersDivider />
        <HomeFeaturesFilter
          disableFilter={props.disabled}
          scrollableNode={containerNode}
        />
        <FiltersDivider />
        <PropertyFeaturesFilter
          disableFilter={props.disabled}
          scrollableNode={containerNode}
        />
        <FiltersDivider />
        <CommunityFeaturesFilter
          disableFilter={props.disabled}
          scrollableNode={containerNode}
        />
        <FiltersDivider />
        <GreatSchoolsFilter disableFilter={props.disabled} />
        <FiltersDivider />
        <KeywordsFilter disableFilter={props.disabled} />
      </Container>

      <TooltipResetFooterWrapper>
        <FiltersFooter
          canReset={filtersChanged && not(props.disabled)}
          onReset={() => {
            dispatch(
              resetSecondaryFilters({ isAgent, agentMode: props.isAgentMode })
            );
          }}
        />
      </TooltipResetFooterWrapper>
    </>
  );
}

const Container = styled.div<{ isAgentMode: boolean }>`
  width: 550px;
  max-width: 100%;
  height: calc(100vh - ${({ isAgentMode }) => (isAgentMode ? 192 : 180)}px);
  background-color: ${p => p.theme.colors.white};
  overflow: auto;
  position: relative;
  padding: 0 24px;
  ${media.sm(css`
    width: 100vw;
    max-height: calc(70vh);
  `)};

  ${collapsePanelOverridesForMoreFilters}
`;

const TooltipResetFooterWrapper = styled.div`
  & > * {
    margin-top: 0;
  }
`;
