import { DeepReadonly } from 'utility-types';

import { SellAction, SellActionType } from 'butter-cms/pages/sell/sellActions';
import { SellState } from 'butter-cms/pages/sell/sellTypes';

export const SELL_DEFAULT_STATE: SellState = {
  loading: false,
  error: '',
  heroSectionImage: '',
  estimatedPrices: null,
};

export function sellReducer(
  state: DeepReadonly<SellState> = SELL_DEFAULT_STATE,
  action: SellAction
): DeepReadonly<SellState> {
  switch (action.type) {
    case SellActionType.FETCH_SELL_HERO_SECTION_IMAGE_SUCCESS:
      return {
        ...state,
        heroSectionImage: action.heroSectionImage,
      };

    case SellActionType.FETCH_SELL_HERO_SECTION_NO_IMAGE:
      return {
        ...state,
        heroSectionImage: '',
      };

    case SellActionType.ESTIMATE_SELECTED_ADDRESS:
      return {
        ...state,
        loading: true,
      };

    case SellActionType.ESTIMATE_SELECTED_ADDRESS_SUCCESS:
      return {
        ...state,
        estimatedPrices: action.prices,
        loading: false,
      };

    case SellActionType.ESTIMATE_SELECTED_ADDRESS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case SellActionType.RESET_SELL_PAGE:
      return SELL_DEFAULT_STATE;

    default:
      return state;
  }
}
