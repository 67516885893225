import { CollectionsActionType } from 'collections/collectionsActionType';
import {
  FiltersActionType,
  ResetAllFiltersAction,
  ResetBathsFiltersAction,
  ResetBedsFiltersAction,
  ResetPriceFiltersAction,
  ResetSecondaryFiltersAction,
  SetFilterRangeValueAction,
} from 'filters/filtersActions';
import { MlsStatus } from 'filters/filtersContants';
import { TypesOfInquiryHistory } from 'history/inquiryHistory/inquiryHistoryTypes';
import {
  ListingDetailsSection,
  OpenHouse,
  PropertyHistory,
  SchoolsGroup,
} from 'listingDetails/listingDetailsTypes';
import {
  ChangeListingsPageAction,
  ChangeOurListingsFirstAction,
  ListingsActionType,
  SearchListingsAction,
  SortListingsAction,
} from 'listings/listingsActions';
import {
  AddMapDrawingPolygon,
  HandleClusterClickAction,
  HandleMapChangeAction,
  MapActionType,
} from 'map/mapActions';
import { SortOrder } from 'shared/constants/appConstants';
import { ImageInfo, PrimaryLabel, SecondaryLabel } from 'shared/types';
import { FormErrors } from 'shared/types/errorTypes';
import { PlaceType } from 'shared/types/placesAndPolygons';

export interface PolygonRequestData {
  type: PlaceType | 'id';
  [key: string]: string;
}

export interface ListingCollections {
  id: string;
}

export interface ListingRecommendation {
  collectionId?: number;
  id?: number;
  listingId?: string;
  owner: string;
  source?: string;
  user: string;
}

export interface ListingsImagesResponse {
  [key: string]: ImageInfo[];
}

export type PaginatedListings = {
  total: number;
  listings: Listing[];
  nearbyListings?: Listing[];
  history: number;
  pageCount?: number;
  boundaries?: {
    latitude: {
      min: number;
      max: number;
    };
    longitude: {
      min: number;
      max: number;
    };
  };
};

export interface ListingPolygon {
  id: string;
  name: string;
  url: string;
}

export type ListingPolygons = {
  [PlaceType.County]?: ListingPolygon;
  [PlaceType.City]?: ListingPolygon;
  [PlaceType.PostalCode]?: ListingPolygon;
  [PlaceType.Subdivision]?: ListingPolygon;
  [PlaceType.SchoolDistrict]?: ListingPolygon;
  [PlaceType.MlsArea]?: ListingPolygon;
} & Partial<Record<string, ListingPolygon>>;

export interface RequestSpecialistParams {
  email?: string;
  phone?: string;
  firstname?: string;
  lastname?: string;
  message?: string;
}

export interface RequestSpecialistBody {
  data: RequestSpecialistParams;
  onError: (errors: FormErrors) => void;
  onSuccess: () => void;
  cb: () => void;
}

export interface ContactUsData {
  email: string;
  firstname: string;
  lastname: string;
  message: string;
  phone: string;
}

export interface ContactUsBody {
  data: ContactUsData;
  onError: (errors: FormErrors) => void;
  onSuccess: () => void;
  cb: () => void;
  inquiryType:
    | TypesOfInquiryHistory.RequestAnInterview
    | TypesOfInquiryHistory.ContactUs;
}

export interface PaginationOptions {
  isFirstPage: boolean;
  isLastPage: boolean;
}

export interface ListingsState {
  clickedMarkerListingId?: string;
  disableAll: boolean;
  error: string;
  fetched: boolean;
  history?: number;
  itemCount: number;
  items: Listing[];
  nearbyListings: Listing[];
  lastViewedItem?: string | -1;
  loading: boolean;
  location?: string;
  page: number;
  pageCount: number;
  paginationOptions: PaginationOptions;
  ourListingsFirst: boolean;
  selectedItemId?: string | -1;
  sortBy: string;
  sortType: SortOrder;
  viewMode: string;
  listingsBoundaries: {
    west: number;
    east: number;
    south: number;
    north: number;
  } | null;
}

export interface NewListing {
  listingkey: string; // inner key, we using for mapping images, example 350786357, images matches by listingkey -> mediaobjectid in images response, same for listing details images
  listingid: string; // inner key, we using for adding collection to listing, for adding ListingSearchParamKey in url for details,  example 6021380
  sourcelistingid: string; // inner key, we using it as a new type of listing id, example 6021380
  listofficemlsid: string; // inner key, we use it to define our listing, example 3788
  source: 'AUS' | 'SA' | 'EX'; // inner key, using to define RA or SA listings (pass in state of url when we opening listing details)
  mlsstatus: MlsStatus; // status, we  using it for adding correct label and tu get correct permissions depends on status, example : Pending - Taking Backups,
  closedate: string; // date in utc, using it for showing correct secondary label with date for Sold or Leased listing, example : 2020-10-01T00:00:00.000Z   !! required for Lease and Sold
  closeprice: string; // price, using to show correct sold or leasd price, example $2,574,000.00  !! required for Lease and Sold
  // next is the LISTING_FIELDS - those fields we are using to construct url for each listing (to open details page), and to construct address in table and other places
  streetnumber: string; // example 301
  streetdirprefix: string; // example null
  streetdirsuffix: string; // example null,
  streetname: string; // example Graham,
  streetsuffix: string; // example place,
  city: string; // example Austin,
  stateorprovince: string; // example TX
  postalcode: string; // example 78701` use for metadata in listing details
  unitnumber: string; // example 807,
  // finish for values in url and adress
  // also in details we inject those data, so we should make it optional and get from feature
  bedroomstotal: number; // using to show bedrooms in table and other places example 3, also in seo
  bathroomstotalinteger: number; //total of beth, example 7
  bathroomsfull: number; // using to show in table mode with bedth also using in some bedth calculation example 6
  livingarea: number; // show sq in table, example 4033
  buildingareatotal: number; // show sq in table, example 4033
  propertytype: PropertyType; // we are using it to define Lease or Residential Lease property example: Residential
  propertyfiltertype?: PropertyFilterSubType; // to show in table in other places, example Сondo Lease
  propertysubtype?: PropertySubType;
  publishedAt: string;
  agentId: string;
  listagentfullname: string;
  squarefeetprice: number; // to show squarefeetprice price in table and other places example 1052.8406833531983
  subdivisionname: string; // to show subdivision name in table in other places, example: Fifth & West
  onmarketdate: string; // date in table in other places, //example 2020-09-16T00:00:00.000Z
  address: string; // whole adress, using for adding description for image, example: "805 W Annie Street, Austin, TX, 78704"
  listprice: string; // using for metada and labels example: $750,000.00
  lotsizearea: number; // using in listing description to show lot size, example 24.5, in types we have number but it's a string actualy
  urlAddress: string;

  // for Open House
  isopenhouse: boolean; // using in seo
  openhousestarttime: string;
  openhouse: OpenHouse[];

  // fo labels
  isjustlisted: boolean;
  isbackonmarket: boolean;
  ispricereduced: boolean;
  ispriceincreased: boolean;
  isexclusive: boolean;

  // collection
  collections: ListingCollections[]; // to define all collection in which listing exist // examnple [id]
  recommendations?: ListingRecommendation[]; // list with recommendation // example [id]
  dismissed: boolean;

  // agent mode
  viewedcount: number; // viewed in agent mode, 3
  lastviewed: string; // 2021-03-02T14:47:18.669Z

  // Agent Bio
  listagentmlsid: string; // we define it to get description on ListingCard for buyerOrSellerRepresented label page example 463375, on AgentBio team listing, Buyer represented / Seller represented
  buyeragentmlsid: string; // we use both (with listagentmlsid) to get description  buyerOrSellerRepresented on ListingCard, on AgentBio team listing, Buyer represented / Seller represented
  colistagentmlsid: string; // we define it to get description on ListingCard for buyerOrSellerRepresented label page example 463375, on AgentBio team listing, Buyer represented / Seller represented
  cobuyeragentmlsid: string; // we use both (with listagentmlsid) to get description  buyerOrSellerRepresented on ListingCard, on AgentBio team listing, Buyer represented / Seller represented

  // (we use this only for meta as i found, probably this is transform in position for clusters on back end side)
  latitude: string;
  longitude: string;

  // Comment
  commentCount: number; // count of comments for comment

  //Exclusive Listings dates
  publicDate?: string;
  mlsTargetDate?: string;
  expirationdate?: string;
}

export enum PropertyType {
  CommercialLease = 'Commercial Lease',
  ResidentialLease = 'Residential Lease',
  Residential = 'Residential',
  ResidentialIncome = 'Residential Income',
  CommercialSale = 'Commercial Sale',
  Farm = 'Farm',
  Land = 'Land',
}

export enum PropertyFilterSubType {
  Hide = '',
  CommercialSale = 'Commercial',
  CommercialLease = 'Commercial Lease',
  Farm = 'Farm/Ranch',
  Lease = 'Lease',
  Lot = 'Lot',
  SingleFamilyResidence = 'Home',
  SingleFamilyResidenceLease = 'Home Lease',
  Condominium = 'Condo',
  CondominiumLease = 'Condo Lease',
  Townhouse = 'Townhouse',
  TownhouseLease = 'Townhouse Lease',
  MultiFamily = 'Multi-Family',
}

export enum PropertySubType {
  Industrial = 'Industrial',
  BedBreakfast = 'Bed & Breakfast',
  MobileHomePark = 'Mobile Home Park',
  Land = 'Land',
  SingleFamilyResidence = 'Single Family Residence',
  Apartment = 'Apartment',
  Retail = 'Retail',
  MixedUse = 'Mixed Use',
  MultiFamily = 'Multi Family',
  ConvenienceStore = 'Convenience Store',
  Ranch = 'Ranch',
  Office = 'Office',
  HotelMotel = 'Hotel/Motel',
  MobileHome = 'Mobile Home',
  ManufacturedHome = 'Manufactured Home',
  Efficiency = 'Efficiency',
  GarageGuestHouse = 'Garage/Guest House',
  Modular = 'Modular',
  Triplex = 'Triplex',
  Townhouse = 'Townhouse',
  Guadruplex = 'Quadruplex',
  Guintuplex = '5-plex',
  Sixplex = '6-Plex',
  Sevenplex = '7-Plex',
  Octupleplex = '8-Plex',
  Duplex = 'Duplex',
  Condominium = 'Condominium',
  Empty = '',
  Other = 'Other',
  ManufacturedOnLand = 'Manufactured On Land',
  UnimprovedLand = 'Unimproved Land',
  Agriculture = 'Agriculture',
  Business = 'Business',
  SitePlanned = 'Site Planned',
  Warehouse = 'Warehouse',
  CommercialLandUnimprvd = 'Commercial Land/Unimprvd',
  AptsRetailStripBusPrk = 'Apts/Retail/Strip/BusPrk',
  AutoService = 'Auto Service',
  Manufacturing = 'Manufacturing',
  MedicalDental = 'Medical/Dental',
  MultiUse = 'Multi-Use',
  OfcWrhsIndPrkManfcDist = 'OfcWrhs/IndPrk/Manfc/Dist',
  OfficeMedicalProfBldg = 'Office/Medical/Prof Bldg',
  OtherMotelHotelMHPrk = 'Other: Motel/Hotel/MH Prk',
  OtherSeeRemarks = 'Other - See Remarks',
  ParkingLot = 'Parking Lot',
  Recreational = 'Recreational',
  RestaurantBar = 'Restaurant/Bar',
  RvPark = 'RV Park',
  ShoppingCenter = 'Shopping Center',
  MultipleParcels = 'Multiple Parcels',
}

// TODO for now this is unused type for PURE listing with details from backend, we should add all modifiication as children of this type and use it for whole app
export interface UNSTABLE_ListingWithDetails extends NewListing {
  // using for init calculator values
  hoaDues: string;
  taxRate: string;
  hoaType: string;
  // end

  fulladdress: string; // label for coments
  publicremarks: string; // for feature and fact section

  //features

  // features: Array<SubSectionValue<FeaturesAndFactsSectionValueType>>; after modification, type using only for feature and facts for icon
  features: ListingDetailsSection<string>;

  // rentalInfo: Array<ListingDetailsSubSection<string>>; // Rental Info Section
  rentalInfo: ListingDetailsSection<string>;

  // propertyDetails?: Array<ListingDetailsSubSection<string>>;
  propertyDetails: ListingDetailsSection<string>;

  // buildingInfo?: Array<ListingDetailsSubSection<string>>;
  buildingInfo: ListingDetailsSection<string>;

  // interiorFeatures?: Array<ListingDetailsSubSection<string>>;
  interiorFeatures: ListingDetailsSection<string>;

  // financial?: Array<ListingDetailsSubSection<string>>;
  financial: ListingDetailsSection<string>;

  // hoa?: Array<ListingDetailsSubSection<string>>;
  hoa: ListingDetailsSection<string>;

  // propertyTaxes?: Array<ListingDetailsSubSection<string>>;
  propertyTaxes: ListingDetailsSection<string>;

  // propertyHistory?: PropertyHistory[][];
  propertyHistory: PropertyHistory[][];

  listofficename: string; // using for Provided By, example : "Realty Austin"
  virtualtoururlunbranded: string; // link for a virtual tour
  propertyType: PropertyType; // using to define property type type for example for Lease Listing

  // agent, useing to show agent info in sidebar and also for P and T flows

  // agent: Partial<Agent>; // using only name
  agent: {
    name: string;
  };

  // polygons, for all links
  polygons: ListingPolygons;

  // schools
  schools: SchoolsGroup;

  // agents showing info example in agentsShowingInfo const using in AgentsInfo component
  showingrequirements: string;
  lockboxlocation: string;
  abor_accessinstruction: string;
  showinginstructions: string;
  privateremarks: string;
  lockboxtype: string;
  signonpropertyyn: string;
  accesscode: string;

  // agent office info example in agentsOfficeInfo using in AgentsInfo component

  occupanttype: string;
  occupantphone: string;
  showingcontactname: string;
  showingcontactphone: string;
  showingcontacttype: string;
  showingservicephone: string;
  ownername: string;
  ownerphone: string;
  listofficephone: string;
  listagentfullname: string;
  listagentdirectphone: string;
  listagentemail: string;
  colistagentfullname: string;
  colistagentdirectphone: string;
  colistagentemail: string;

  // time and date, using in ListingDetailsContentHeader to show last updated date
  updatedAt: string; // example 2021-03-04T12:16:51.337Z
  checkedAt: string; // example 2021-03-09T11:30:09.124Z

  // Fields that we've used in other places

  // autocomplete
  countyorparish: string; // we using for creating a label of country place in autocomplete
  mlsareamajor: string; // label for mls area
  schooldistrict: string; // label for schooldisctict also for hidden h1 label on buy page when tag is schooldisctict and for seo on market overview and other places with seo
  attributioncontact: string; // using for Provided By, example: "(000) 000-0000"
}

export interface ListingLabels {
  primaryLabel: PrimaryLabel;
  secondaryLabel: SecondaryLabel;
}

export interface Listing extends NewListing, ListingLabels {
  images: ImageInfo[];
  url: string;
}

export type UpdateListingsAndUrlActionsType =
  | AddMapDrawingPolygon
  | ChangeListingsPageAction
  | ChangeOurListingsFirstAction
  | HandleClusterClickAction
  | HandleMapChangeAction
  | ResetAllFiltersAction
  | ResetBathsFiltersAction
  | ResetBedsFiltersAction
  | ResetPriceFiltersAction
  | ResetSecondaryFiltersAction
  | SearchListingsAction
  | SetFilterRangeValueAction
  | SortListingsAction;

export type FetchListingsActionsType =
  | CollectionsActionType
  | FiltersActionType
  | ListingsActionType
  | MapActionType;
