import { makeFetcher } from 'shared/helpers/swr';
import {
  fetchPage,
  fetchStrapiPage,
  fetchStrapiPages,
  FetchStrapiPagesParams,
} from 'strapi/lib/strapi';
import {
  BlogSortingValues,
  PostType,
  StrapiEndpoints,
} from 'strapi/strapi-constants';
import {
  AgentsDirectoryFields,
  InfoPageFields,
  StrapiBlogCategory,
  StrapiBlogPage,
  StrapiBlogPost,
  StrapiCategoriesMenu,
  StrapiCommunityPage,
  StrapiTestimonialFields,
  StrapiTestimonialsSliderFields,
} from 'strapi/strapi-types';

export async function fetchAgentsDirectory() {
  return fetchStrapiPage<AgentsDirectoryFields>({
    type: StrapiEndpoints.agentsDirectories,
  });
}

export const infoPageFetcher = makeFetcher((slug: string) => {
  return fetchPage<InfoPageFields>({ type: 'info_page', slug });
});

export const blogFetcher = makeFetcher(() => {
  return fetchStrapiPage<StrapiBlogPage>({ type: StrapiEndpoints.blogPage });
});

export const blogPostFetcher = makeFetcher(
  (slug: string, preview: boolean, source: string) => {
    return fetchStrapiPage<StrapiBlogPost>({
      type: StrapiEndpoints.blogPost,
      slug,
      preview,
      source,
    });
  }
);

export const blogPostsFetcher = makeFetcher(
  (params: FetchStrapiPagesParams) => {
    return fetchStrapiPages<StrapiBlogPost>({
      type: StrapiEndpoints.blogPosts,
      ...params,
    });
  }
);

export const relatedArticlesFetcher = makeFetcher(
  async (categorySlug: string, postType: PostType) => {
    return fetchStrapiPages<StrapiBlogPost>({
      'filters[categories][slug][$eq]': categorySlug,
      'filters[post_type][$eq]': postType,
      sort: `${BlogSortingValues.publishedDate}:DESC`,
      pageSize: 4,
      type: StrapiEndpoints.blogPosts,
    });
  }
);

export const categoriesMenuFetcher = makeFetcher(() => {
  return fetchStrapiPage<StrapiCategoriesMenu>({
    type: StrapiEndpoints.categoriesMenu,
  });
});

export const categoriesFetcher = makeFetcher(async (slug: string) => {
  return fetchStrapiPage<StrapiBlogCategory>({
    type: StrapiEndpoints.blogPostCategory,
    slug,
  });
});

export const testimonialFetcher = makeFetcher((slug: string) => {
  return fetchStrapiPage<StrapiTestimonialFields>({
    type: StrapiEndpoints.testimonialVideoPage,
    slug,
  });
});

export const testimonialsVideoSliderFetcher = makeFetcher(() => {
  return fetchStrapiPage<StrapiTestimonialsSliderFields>({
    type: StrapiEndpoints.testimonialVideoSliderPage,
  });
});

export const testimonialsFetcher = makeFetcher(() => {
  return fetchStrapiPages<StrapiTestimonialFields>({
    sort: `${BlogSortingValues.publishedDate}`,
    pageSize: 20,
    type: StrapiEndpoints.testimonialVideoPages,
  });
});

export const searchPostsFetcher = makeFetcher(
  (query: string, params?: { page?: number; pageSize?: number }) => {
    return fetchStrapiPages<StrapiBlogPost>({
      page: params?.page,
      pageSize: params?.pageSize,
      type: StrapiEndpoints.blogPosts,
      'filters[$or][0][title][$containsi]': query,
      'filters[$or][1][description][$containsi]': query,
    });
  }
);

export const communityPageFetcher = makeFetcher((slug: string) => {
  return fetchStrapiPage<StrapiCommunityPage>({
    type: StrapiEndpoints.communityPage,
    slug,
  });
});
