import {
  DISMISSED_COLLECTION_ID,
  PROPOSED_COLLECTION_ID,
  PROPOSED_COLLECTION_NAME,
} from 'collections/collectionsConstants';
import { isProposedByDescription } from 'collections/collectionsHelpers';
import {
  Collection,
  CollectionBody,
  CollectionNotification,
  DismissedCollection,
  FetchCollectionsType,
  ProposedCollection,
  RawCollection,
} from 'collections/collectionsTypes';
import { not } from 'shared/helpers/boolean';
import { addAddedByProfile } from 'shared/helpers/ownerHelpers';
import { ApiService } from 'shared/services/ApiService';
import { Invitation, InvitationsCount } from 'shared/types';
import { Collaborator } from 'user/userTypes';

const BASE_URL = '/listing/collection';

export class CollectionService {
  static async fetchCollections(options: {
    excludeDismissed?: boolean;
    excludeProposed?: boolean;
    user?: string;
    createdBy?: string;
  }): Promise<Collection[]> {
    const { excludeDismissed, excludeProposed, user, createdBy } = options;

    const [rawCollections, dismissedCollection, proposedCollection]: [
      RawCollection[],
      DismissedCollection | null,
      ProposedCollection | null
    ] = await Promise.all([
      ApiService.request<RawCollection[]>({
        method: 'GET',
        path: BASE_URL,
        query: { user, createdBy },
      }),
      excludeDismissed
        ? null
        : CollectionService.fetchCollection<DismissedCollection>({
            id: DISMISSED_COLLECTION_ID,
            user: user,
            limit: 0,
          }),
      excludeProposed
        ? null
        : CollectionService.fetchCollection<ProposedCollection>({
            id: PROPOSED_COLLECTION_ID,
            user: user,
            limit: 0,
          }),
    ]);

    const res: Collection[] = [...rawCollections];

    if (proposedCollection?.data && proposedCollection.data.size > 0) {
      res.push(proposedCollection.data);
    }

    if (dismissedCollection && dismissedCollection.size > 0) {
      res.push(dismissedCollection);
    }

    return res;
  }

  static async fetchCollection<T extends FetchCollectionsType>(params: {
    id: string;
    limit?: number;
    ourListingsFirst?: boolean;
    page?: number;
    sortBy?: string;
    sortType?: string;
    user?: string | null;
  }): Promise<T> {
    const {
      id,
      limit = 20,
      ourListingsFirst = false,
      page = 1,
      sortBy,
      sortType,
      user,
    } = params;

    const collection = await ApiService.request<T>({
      method: 'get',
      path: `${BASE_URL}/${id}`,
      prefix: 'v2',
      query: { limit, ourListingsFirst, page, sortBy, sortType, user },
    });

    if (not('id' in collection)) {
      if (isProposedByDescription(collection) && collection.data) {
        collection.data.id = PROPOSED_COLLECTION_ID;
        collection.data.name = PROPOSED_COLLECTION_NAME;
      } else {
        Object.assign(collection, {
          id: DISMISSED_COLLECTION_ID,
          name: DISMISSED_COLLECTION_ID,
        });
      }
      return collection;
    } else {
      return (await addAddedByProfile(collection as RawCollection)) as T;
    }
  }

  static async addItemToCollection({
    collectionId,
    listingId,
    owner,
    notNotifyUser,
  }: {
    collectionId: string;
    listingId: string;
    owner?: string;
    notNotifyUser?: boolean;
  }) {
    return ApiService.request<RawCollection>({
      method: 'PUT',
      path: `${BASE_URL}/${collectionId}/listings/${listingId}`,
      data: { owner, notNotifyUser },
    });
  }

  static async removeCollectionItem(
    collectionId: string,
    listingId: string,
    owner?: string
  ) {
    return ApiService.request({
      method: 'DELETE',
      path: `${BASE_URL}/${collectionId}/listings/${listingId}`,
      data: {
        owner,
      },
    });
  }

  static async removeCollectionItemFromDismissed(listingId: string) {
    return ApiService.request({
      method: 'POST',
      path: `/listing/${listingId}/retain`,
    });
  }

  static async saveCollection(reqBody: CollectionBody) {
    return ApiService.request<RawCollection>({
      method: 'POST',
      path: BASE_URL,
      data: {
        name: reqBody.name,
        description: reqBody.description,
        listings: reqBody.listings,
        owner: reqBody.owner,
        notNotifyUser: reqBody.notNotifyUser,
      },
    });
  }

  static async updateCollection(
    collectionId: string,
    data: {
      name: string;
      description?: string;
      notifications: Partial<CollectionNotification>;
      notifyAgent: boolean;
      owner?: string;
    }
  ) {
    return ApiService.request<RawCollection>({
      method: 'PATCH',
      path: `${BASE_URL}/${collectionId}`,
      prefix: 'v2',
      data,
    });
  }

  static async removeCollection(collectionId: string, owner?: string) {
    return ApiService.request({
      method: 'DELETE',
      path: `${BASE_URL}/${collectionId}`,
      data: {
        owner,
      },
    });
  }

  static async createCollaborator(
    collectionId: string,
    collaboratorEmails: string[]
  ) {
    return ApiService.request<Collaborator[]>({
      method: 'POST',
      path: `${BASE_URL}/${collectionId}/collaborator`,
      data: collaboratorEmails.map(email => ({ email })),
    });
  }

  static async removeCollaborator(
    collectionId: string,
    collaboratorId: string
  ) {
    return ApiService.request({
      method: 'DELETE',
      path: `${BASE_URL}/${collectionId}/collaborator/${collaboratorId}`,
    });
  }

  static async joinCollection(inviteCode: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/${inviteCode}/join`,
    });
  }

  static async declineCollection(collectionId: string, username?: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/${collectionId}/decline`,
      query: {
        username,
      },
    });
  }

  static async fetchCollectionsInvitations() {
    return ApiService.request<Invitation>({
      method: 'GET',
      path: `${BASE_URL}/invitations`,
    });
  }

  static async fetchCollectionsInvitationsCount() {
    return ApiService.request<InvitationsCount>({
      method: 'GET',
      path: `${BASE_URL}/invitations?countOnly=true`,
    });
  }

  static async declineCollectionInvitation(id: number) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/${id}/decline`,
    });
  }

  static async sendReminder(id: string, collaboratorId: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/${id}/collaborator/${collaboratorId}/send-a-reminder`,
    });
  }

  static async recommendListings(
    id: string,
    data: {
      owner?: string;
      notNotifyUser?: boolean;
      listings: Array<{
        listingId: string;
        commentText: string;
      }>;
    }
  ) {
    return ApiService.request({
      method: 'PUT',
      path: `${BASE_URL}/${id}/recommendation`,
      data,
    });
  }
}
