import {
  NotificationFrequency,
  NotificationFrequencyOption,
  NotificationFrequencyOptionValue,
} from 'saved-search/savedSearchTypes';

export const notificationFrequencies: NotificationFrequencyOption[] = [
  { label: 'Immediately', value: NotificationFrequencyOptionValue.Immediately },
  { label: 'Daily', value: NotificationFrequencyOptionValue.Daily },
  { label: 'Weekly', value: NotificationFrequencyOptionValue.Weekly },
  { label: 'Monthly', value: NotificationFrequencyOptionValue.Monthly },
  { label: 'Never', value: NotificationFrequencyOptionValue.Never },
];

export enum SearchSortValues {
  Date = 'date',
  Name = 'name',
}

export const searchesSortOptions = [
  { value: SearchSortValues.Date, label: 'Created Date' },
  { value: SearchSortValues.Name, label: 'Name' },
];

export const SEARCH_DESCRIPTION_PLACEHOLDER = 'No criteria selected';

export const frequencyNotifications: {
  [key in NotificationFrequency]: string;
} = {
  [NotificationFrequency.NotificationsDisabled]:
    'Email notifications are disabled. To receive updates choose a frequency:',
};
