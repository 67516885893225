import { Contact } from 'contacts/contactsTypes';
import { PeriodFilters } from 'filters/filtersTypes';
import { SortOrder } from 'shared/constants/appConstants';

export interface NotificationHistoryFilters {
  sortBy: NotificationHistorySortBy;
  sortType: SortOrder;
  page: number;
  period: number;
  type: NotificationHistoryTypeFilter;
}

export interface NotificationHistoryState extends NotificationHistoryFilters {
  items: NotificationHistoryItem[];
  notificationRawHtmls: RawHtml;
  loading: boolean;
  total: number;
  limit: number;
  error: string;
  markAllInProgress: boolean;
}

export enum NotificationHistorySortBy {
  DateTime = 'date',
}

export enum NotificationHistoryStatus {
  Sent = 'Sent',
  Open = 'Opened',
  Clicked = 'Clicked',
  Complained = 'Complained',
  Bounced = 'Bounced',
  NotSent = 'Not Sent',
}

export enum NotificationHistoryTypeFilter {
  All = '',
  Unread = 'Unread',
  Emails = 'Emails',
  Inquiries = 'Inquiries',
}

export type TimeType =
  | 'sentAt'
  | 'openedAt'
  | 'bouncedAt'
  | 'complainedAt'
  | 'clickedAt'
  | 'notSentAt';

export interface NotificationHistoryItem {
  status: NotificationHistoryStatus;
  subject: string;
  sentAt: string;
  openedAt: string;
  bouncedAt: string;
  complainedAt: string;
  clickedAt: string;
  createdAt: string;
  dateTime: string;
  id: string;
  notSentAt: string;
  notSentReason: string;
  updatedAt: string;
  isMarketing: boolean;
}

export interface RawHtml {
  [messageId: string]: string;
}

export interface NotificationHistoryRequestFilters {
  page: NotificationHistoryState['page'];
  sortBy: NotificationHistoryState['sortBy'];
  sortType: NotificationHistoryState['sortType'];
  limit: NotificationHistoryState['limit'];
  type: NotificationHistoryState['type'];
  username: Contact['username'];
  periodStart?: PeriodFilters['periodStart'];
  periodEnd?: PeriodFilters['periodEnd'];
}

export interface NotificationHistoryFiltersAndSorting {
  sortBy: NotificationHistorySortBy;
  sortType: SortOrder;
  type: NotificationHistoryTypeFilter;
  period: number;
}
