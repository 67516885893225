import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import { PermissionRoles } from 'permissions/permissionsConstants';
import { showModalDependOnLoginStatus } from 'permissions/permissionsHelpers';
import { ModalContext } from 'shared/components/Modal/modalContext';
import { ButtonsKeyCode } from 'shared/constants/appConstants';
import { sendUnlockGTMEvent } from 'shared/helpers/ga/index';
import { ReactComponent as LockIcon } from 'shared/icons/auth/lock-small.svg';

const Container = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.14;
  font-weight: bold;
  color: ${({ theme }) => theme.link};

  svg {
    margin-right: 4px;
  }
`;

interface Props {
  loginStatus: PermissionRoles;
  className?: string;
  unlockPostString?: string;
  gtmClickTargetName?: string;
}

const AuthLock = (props: Props) => {
  const modalContext = useContext(ModalContext);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === ButtonsKeyCode.Enter) {
      showModalDependOnLoginStatus(props.loginStatus, modalContext);
    }
  };

  return (
    <Container
      className={props.className}
      onClick={() => {
        showModalDependOnLoginStatus(props.loginStatus, modalContext);
        sendUnlockGTMEvent({ clickTarget: props.gtmClickTargetName });
      }}
      onKeyDown={handleKeyDown}
      role="button"
      aria-label={`Unlock ${props.unlockPostString}`}
      tabIndex={0}
    >
      <span>
        <LockIcon />
      </span>
      <span>Unlock {props.unlockPostString}</span>
    </Container>
  );
};

AuthLock.defaultProps = {
  unlockPostString: '',
};

export default AuthLock;
