import {
  EMAIL_HISTORY_BASE_URL,
  EMAIL_HISTORY_ITEM_BASE_URL,
} from 'history/historyConstants';
import {
  NotificationHistoryItem,
  NotificationHistoryStatus,
} from 'history/notificationHistory/notificationHistoryTypes';
import { ApiService } from 'shared/services/ApiService';
import { Paginated } from 'shared/types';

export class NotificationHistoryService {
  static async getNotificationHistory(url: URL) {
    return ApiService.request<Paginated<NotificationHistoryItem>>({
      createFullUrl: false,
      method: 'GET',
      path: String(url),
    });
  }

  static async getNotificationHistoryItem(
    query: {
      username: string;
    },
    notificationId: string
  ) {
    return ApiService.request<string>({
      method: 'GET',
      path: `${EMAIL_HISTORY_ITEM_BASE_URL}/${notificationId}`,
      query,
      resolve: res => res.text(),
    });
  }

  static async updateNotificationHistoryItem(
    data: Partial<NotificationHistoryItem>,
    notificationId: string
  ) {
    return ApiService.request<NotificationHistoryItem>({
      method: 'PATCH',
      path: `${EMAIL_HISTORY_BASE_URL}/${notificationId}`,
      data,
    });
  }

  static async markAllNotificationsAsRead() {
    return ApiService.request({
      method: 'PATCH',
      path: `${EMAIL_HISTORY_BASE_URL}/open-all`,
      resolve: res => res.text(),
    });
  }

  static async getNotificationHistoryCount(
    statuses: NotificationHistoryStatus[]
  ) {
    return ApiService.request<{ total: number }>({
      method: 'GET',
      path: `${EMAIL_HISTORY_BASE_URL}/count`,
      query: {
        status: String(statuses),
      },
    });
  }
}
