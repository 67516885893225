import { nanoid } from '@reduxjs/toolkit';
import { AriaAttributes, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';

import {
  AriaLiveAnnouncerContext,
  Role,
} from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import { not } from 'shared/helpers/boolean';

const AriaLiveAnnouncer = styled.div`
  z-index: -9999;
  position: fixed;
  opacity: 0;
  height: 0;
  width: 0;
  top: 0;
  left: 0;
`;

interface Props {
  children: JSX.Element;
}

interface ConfigItem {
  role: Role;
  'aria-live': AriaAttributes['aria-live'];
}

const configDependsOnRole: { alert: ConfigItem; log: ConfigItem } = {
  alert: {
    role: 'alert',
    'aria-live': 'assertive',
  },
  log: {
    role: 'log',
    'aria-live': 'polite',
  },
};

const AriaLiveAnnouncerProvider = (props: Props) => {
  const ariaLiveRootElement =
    document.getElementById('ariaLiveAnnouncer-root') ||
    document.createElement('div');

  const [message, setMessage] = useState<string>('');
  const [role, setRole] = useState<Role>('log');
  const [messageId, setMessageId] = useState<string>('');

  const config =
    role === 'alert' ? configDependsOnRole.alert : configDependsOnRole.log;

  const announce = (newMessage: string, newRole: Role = 'log'): void => {
    if (not(role === newRole)) {
      setRole(newRole);
    }

    setMessage(newMessage);
    setMessageId(nanoid());
  };

  return (
    <AriaLiveAnnouncerContext.Provider value={{ announce }}>
      {ReactDOM.createPortal(
        <AriaLiveAnnouncer role="log">
          <div {...config} key={messageId}>
            {message}
          </div>
        </AriaLiveAnnouncer>,
        ariaLiveRootElement
      )}
      {props.children}
    </AriaLiveAnnouncerContext.Provider>
  );
};

export default AriaLiveAnnouncerProvider;
