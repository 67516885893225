import { useState } from 'react';
import { components, InputProps } from 'react-select';

import { ButtonsCode } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';

const SelectInput = (props: InputProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (event.key === ButtonsCode.Enter ||
        event.key === ButtonsCode.Space ||
        event.key === ButtonsCode.ArrowDown ||
        event.key === ButtonsCode.ArrowUp) &&
      not(isActive)
    ) {
      setIsActive(true);
    }
  };

  return (
    <components.Input
      {...props}
      role="combobox"
      aria-activedescendant={
        props.selectProps.menuIsOpen && isActive ? 'focused-option' : ''
      }
      aria-haspopup
      onBlur={e => {
        props.onBlur?.(e);
        setIsActive(false);
      }}
      onKeyDown={handleKeyDown}
      aria-hidden={props.selectProps.menuIsOpen}
      aria-expanded={props.selectProps.menuIsOpen}
    />
  );
};

export default SelectInput;
