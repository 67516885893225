import { createSelector } from 'reselect';

import { getAgents } from 'agents/agentsSelectors';
import { changedSelector } from 'filters/filtersHelpers';
import { MY_LISTINGS_FILTERS_DEFAULT_STATE } from 'myListings/myListingsSlice';
import { MyListingsFilters } from 'myListings/myListingsTypes';
import { RootState } from 'rootReducer';
import { not, notEqual } from 'shared/helpers/boolean';
import { addLabelsToItem } from 'shared/helpers/labelsHelper';
import { keysOf } from 'shared/helpers/object';

export const getMyListingsType = (state: RootState) =>
  state.myListings.currentTab;

export const getViewMode = (state: RootState) => state.myListings.viewMode;

export const getMyListings = (state: RootState) => state.myListings.items;

export const getMyListingsPage = (state: RootState) => state.myListings.page;

export const getMyListingsLoading = (state: RootState) =>
  state.myListings.loading;

export const getMyListingsCount = (state: RootState) =>
  state.myListings.listingsCount;

export const getMyListingsWithLabels = createSelector([getMyListings], items =>
  items.map(item => addLabelsToItem(item))
);

export const getMyListingsToRemove = (state: RootState) =>
  state.myListings.itemsToRemove;

export const getExistingItems = createSelector(
  [getMyListingsWithLabels, getMyListingsToRemove],
  (items, itemsToRemove) =>
    items.filter(item => not(itemsToRemove.includes(item.listingid)))
);

export const getMyListingsFiltersChanged = (
  filters: Partial<MyListingsFilters>
): boolean => {
  return keysOf(filters).some((key: keyof typeof filters) => {
    if (
      Array.isArray(filters[key]) &&
      Array.isArray(MY_LISTINGS_FILTERS_DEFAULT_STATE[key])
    ) {
      return notEqual(
        [
          ...(MY_LISTINGS_FILTERS_DEFAULT_STATE[key] as unknown as unknown[]),
        ].sort(),
        [...(filters[key] as unknown as unknown[])].sort()
      );
    } else {
      return notEqual(MY_LISTINGS_FILTERS_DEFAULT_STATE[key], filters[key]);
    }
  });
};

export const $getMyListingsPriceFilter = createSelector(
  (state: RootState) => state.myListings.filters.minPrice,
  (state: RootState) => state.myListings.filters.maxPrice,
  (minPrice, maxPrice) => ({ minPrice, maxPrice })
);

export const $getMyListingsPriceChanged = changedSelector(
  $getMyListingsPriceFilter
);

export const getAgentsFilter = (state: RootState) =>
  state.myListings.filters.agentId;

export const getAgentsFilterChanged = createSelector([getAgentsFilter], value =>
  getMyListingsFiltersChanged({ agentId: value })
);

export const getSelectedAgentFullName = createSelector(
  [getAgentsFilter, getAgents],
  (selectedAgent, agents) => {
    const agent = agents.agentSuggestions.find(
      agent => agent.value === selectedAgent
    );
    return agent?.label;
  }
);

export const getSortBy = (state: RootState) => state.myListings.sortBy;

export const getAppliedFiltersCount = createSelector(
  getAgentsFilterChanged,
  $getMyListingsPriceChanged,
  (
    agentsFilterChanged,
    priceFilterChanged
    // eslint-disable-next-line max-params
  ) => Number(agentsFilterChanged) + Number(priceFilterChanged)
);

export const $getAreAnyFiltersApplied = createSelector(
  getAppliedFiltersCount,
  getAgentsFilterChanged,
  $getMyListingsPriceChanged,
  // eslint-disable-next-line max-params
  (appliedAdminFiltersCount, agent, priceChanged) => {
    return appliedAdminFiltersCount > 0 || agent || priceChanged;
  }
);

export const getSortType = (state: RootState) => state.myListings.sortType;
export const getIsMyListingsFirst = (state: RootState) =>
  state.myListings.isMyListingsFirst;

export const getMyListingsFilters = (state: RootState) => {
  return state.myListings.filters;
};
