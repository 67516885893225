import { createSelector } from 'reselect';

import { UserAccountType } from 'agents/agentsConstants';
import { isAdminAgent } from 'agents/agentsHelpers';
import { SAVED_SEARCH_TAG_TYPE } from 'listings/listingsConstants';
import { RootState } from 'rootReducer';
import { getTags } from 'search/search-selectors';
import { notEmpty } from 'shared/helpers/boolean';

export const getAgentBio = ({ agents }: RootState) => agents.agentBio;

export const getAgents = ({ agents }: RootState) => agents;

export const getAgentsSuggestion = ({ agents }: RootState) =>
  agents.agentSuggestions;

export const getDataForUpdateUrl = ({ agents }: RootState) => ({
  page: agents.page,
  languagesfilter: agents.languagesFilter,
  sortBy: agents.sortBy,
  sortType: agents.sortType,
});

export const getTrecLicenses = createSelector(
  [getAgentBio],
  (agentBio): string[] => {
    if (agentBio.userAccountType === UserAccountType.TeamAccount) {
      return agentBio.members.map(member => member.trecLicense).filter(Boolean);
    }
    return agentBio.trecLicense ? [agentBio.trecLicense] : [];
  }
);

export const getIsAdminAgent = ({ user: { profile } }: RootState) =>
  isAdminAgent(profile.data?.userAccountType);

export const getTagForOrsAgent = createSelector([getTags], tags => {
  if (notEmpty(tags)) {
    const lastTag = tags[tags.length - 1];

    if (lastTag.type === SAVED_SEARCH_TAG_TYPE) {
      const savedSearchTags = lastTag.tags;
      return savedSearchTags[savedSearchTags.length - 1];
    }

    return lastTag;
  }

  return null;
});
