export enum HeaderHeights {
  AgentsListDesktop = 120,
  AgentsListMobile = 96,
  AgentBioDesctop = 56,
  BackButtonMobileHeader = 48,
  ListingsDesktop = 120,
  ListingsMobile = 98,
  ListingsMobileWithMap = 98,
  CollectionDesktop = 190,
  CollectionDesktopDismissed = 170,
  CollectionMobile = 156,
  CollectionMobileWithMap = 168,
  CollectionMobileDismissed = 140,
  CollectionMobileDismissedWithMap = 184,
  SavedSearchDesktop = 121,
  SavedSearchMobile = 98,
  SavedSearchMobileWithMap = 98,
  ViewedListingsDesktop = 179,
  AgentViewedListingsMobile = 136,
  ViewedListingsMobileWithMap = 184,
  MyViewedListingsDesktop = 140,
  MyViewedListingsMobile = 112,
  MyViewedListingsMobileWithMap = 130,
  UserProfileDesktop = 120,
  UserProfileMobile = 48,
  Default = 100,
  DesktopHeaderWithSearchBar = 120,
  SearchBarMobileHeader = 48,
  ListingTableHeader = 48,
  DesktopHeaderMyListings = 192,
  MobileHeaderMyListings = 158,
  AdminDesktopHeaderMyListings = 264,
  AdminMobileHeaderMyListings = 206,
  ScrolledMyListingsHeader = 112,
  ScrolledMyListingsAdminHeader = 146,
}
