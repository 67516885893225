import {
  getGroupIconComponent,
  getPluralGroupName,
  GROUP_TYPE_NAMES,
} from 'search/helpers/autocomplete-helpers';
import SearchBarStyled from 'shared/components/SearchBar/SearchBarStyled';
import ShowMore from 'shared/components/ShowMore';

interface SearchBarOptionGroupProps {
  option: any;
  options: any[];
  scrollableNode?: HTMLDivElement;
}

const SearchBarOptionGroup: React.FC<SearchBarOptionGroupProps> = ({
  option,
  options,
  scrollableNode,
}: SearchBarOptionGroupProps) => {
  const GroupIcon = getGroupIconComponent(option.name);
  const pluralGroupName = getPluralGroupName(option.name);

  const groupTitle = (
    <SearchBarStyled.OptionGroup>
      <SearchBarStyled.OptionGroupIcon>
        <GroupIcon />
      </SearchBarStyled.OptionGroupIcon>
      <SearchBarStyled.OptionGroupText>
        {pluralGroupName}
      </SearchBarStyled.OptionGroupText>
    </SearchBarStyled.OptionGroup>
  );
  return (
    <SearchBarStyled.OptionGroupContainer key={option.name}>
      {groupTitle}
      {option.name === GROUP_TYPE_NAMES.Agents ? (
        options
      ) : (
        <ShowMore truncateChildren lines={3} scrollableNode={scrollableNode}>
          {options}
        </ShowMore>
      )}
    </SearchBarStyled.OptionGroupContainer>
  );
};

export default SearchBarOptionGroup;
