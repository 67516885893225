export const FAVORITE_PLACES_MAX_LENGTH = 5;

export const OAUTH_LOADING = 'federatedLoading';

export enum UserEmailValidationError {
  EmailExistError = 'UsernameExistsException',
  EmailVerificationError = 'UserLambdaValidationException',
}

export const P_T_N_LINK = 'ptnLink';
