import { applyMiddleware, createStore } from 'redux';
import createDebounce from 'redux-debounced';
import { composeWithDevTools } from 'redux-devtools-extension';
import { default as createSagaMiddleware } from 'redux-saga';
import { DeepReadonly } from 'utility-types';

import { DEFAULT_STATE, rootReducer, RootState } from 'rootReducer';
import { rootSaga } from 'rootSagas';
import { toasterMiddleware } from 'shared/middlewares/toasterMiddleware';

export function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers = composeWithDevTools({ trace: true });

  const store = createStore(
    rootReducer,
    DEFAULT_STATE as DeepReadonly<RootState>,
    composeEnhancers(
      applyMiddleware(toasterMiddleware, sagaMiddleware, createDebounce())
    )
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();
