import { useField } from 'formik';

import { InputSelect } from 'shared/components/Select';
import {
  InputSelectProps,
  SelectOption,
} from 'shared/components/Select/SelectTypes';

interface InputSelectFieldProps extends InputSelectProps {
  validator: (value: string) => boolean;
}

export const InputSelectField = (props: InputSelectFieldProps) => {
  const [field, , helpers] = useField({ name: props.name, type: 'select' });

  const handleChange = (option: SelectOption) => {
    helpers.setValue(option.value);
    props.onChange?.(option.value);
  };

  const value = props.options?.find(option => option.value === field.value);

  return (
    <InputSelect
      {...props}
      {...field}
      ariaLabel={props.ariaLabel}
      filterOptions={props.filterOptions}
      isDisabled={props.isDisabled}
      onChange={handleChange}
      isValid={props.validator}
      formatter={props.formatter}
      valueFormatter={props.valueFormatter}
      rawValue={props.rawValue}
      value={value}
      options={props.options}
    />
  );
};
