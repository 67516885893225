export interface ServerValidationErrors {
  [key: string]: string | { [key: string]: string };
}

export interface FormErrors {
  [key: string]: string;
}

export interface BaseError {
  status: number | null;
  message: string;
}

export enum ErrorMessagePlacement {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
}
