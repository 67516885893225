import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { Themed } from 'shared/types';

export const SelectLabel = styled.label<{ isDisabled?: boolean }>`
  color: ${({ theme }) => theme.text2};
  line-height: 1;
  font-size: 12px;
  margin-bottom: 8px;
  display: inline-block;

  ${props =>
    props.isDisabled &&
    css<any>`
      pointer-events: none;
    `};
`;

export const SelectWrapper = styled.div<{ maxWidth?: number }>`
  width: 100%;
  line-height: 20px;
  position: relative;

  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};

  .react-select__option--is-focused:not(.react-select__option--is-selected) {
    background-color: ${({ theme }) => theme.hover};
  }
`;

export const OptionContainer = styled.div<{
  isSticky?: boolean;
  isDropdown?: boolean;
  isPlaces?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  overflow-x: hidden;
  word-break: break-word;
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
  }

  ${({ isPlaces }) =>
    isPlaces &&
    css`
      & > div {
        padding: 8px 8px 8px 24px;
        height: auto;
      }
    `}

  ${({ isDropdown }) =>
    isDropdown &&
    css`
      overflow-x: visible;
      border-bottom: 1px solid #eef0f2;
      height: 48px;
      &:last-child,
      &:nth-last-child(2) {
        border-bottom: none;
      }
      &:last-child {
        padding-left: 0;
      }

      svg {
        position: absolute;
        top: 16px;
        left: 8px;
      }
    `}

  &:hover {
    background: ${({ theme }) => theme.hover};
  }

  ${props =>
    props.isSticky &&
    css`
      position: sticky;
      bottom: 0;
      background-color: ${({ theme }) => theme.white};
      width: 100%;
    `};
`;

export const OptionLabel = styled.span<{
  isDisabled: boolean;
  isDropdown?: boolean;
  isFocused?: boolean;
}>`
  font-family: Lato, sans-serif;
  font-size: 12px;
  line-height: 48px;
  height: 48px;
  letter-spacing: -0.3px;
  text-align: right;
  color: ${({ theme }) => theme.text3};
  white-space: nowrap;
  padding: ${({ isDropdown }) => (isDropdown ? '0 1em 0 0' : '0 1em')};

  ${props =>
    props.isDisabled &&
    css<any>`
      color: ${({ theme }: Themed) => theme.disabled};
    `};
  ${props =>
    props.isFocused &&
    css<{ isFocused?: boolean }>`
      background-color: ${({ theme }: Themed) => theme.hover};
    `};
`;

export const AgentOption = styled.div`
  background-color: #fff;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  z-index: 101;

  svg {
    position: absolute;
    top: 12px;
    left: 8px;
  }

  &:hover {
    background: ${({ theme }) => theme.hover};
  }
`;

export const DuplicateLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.uiRed};
`;

export const DuplicateLink = styled(Link)`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;
