import { generatePath } from 'react-router-dom';
import { select } from 'redux-saga/effects';

import { LISTINGS_PER_BIO_PAGE } from 'agents/agentsConstants';
import { isAgentBioRoute } from 'agents/agentsHelpers';
import { PROPOSED_COLLECTION_NAME } from 'collections/collectionsConstants';
import { isDismissedId, isProposedId } from 'collections/collectionsHelpers';
import { Collection } from 'collections/collectionsTypes';
import config from 'config';
import { getAgentMode } from 'contacts/contactsSelectors';
import { Contact } from 'contacts/contactsTypes';
import { MlsStatus, TIME_FRAME_ONE_YEAR_VALUE } from 'filters/filtersContants';
import { getPeriodStartAndEnd } from 'filters/filtersHelpers';
import {
  FILTERS_DEFAULT_STATE,
  PROPERTY_TYPE_DEFAULT_STATE,
} from 'filters/filtersReducer';
import {
  $getIsPropertyTypeFiltersChanged,
  $getPropertyTypeFilters,
} from 'filters/filtersSelectors';
import { FiltersState, PropertyTypeFilters } from 'filters/filtersTypes';
import {
  ListingDetailsPropertyType,
  ListingWithDetails,
} from 'listingDetails/listingDetailsTypes';
import {
  GUEST_VISITED_LISTINGS,
  LISTINGS_PER_PAGE,
  MAX_VIEW_LISTINGS_BY_GUEST,
  MlsStatusesWithoutPrice,
  RA_OFFICE_ID,
  SA_OFFICE_ID,
  SAVED_SEARCH_TAG_TYPE,
  STATIC_LISTINGS_TAB_HIDDEN_TITLE,
  UNDISCLOSED_ADDRESS,
} from 'listings/listingsConstants';
import { FetchListingsSagaAction } from 'listings/listingsSagas';
import {
  Listing,
  ListingsImagesResponse,
  ListingsState,
  PropertyFilterSubType,
  PropertyType,
} from 'listings/listingsTypes';
import {
  HandleClusterClickAction,
  HandleMapChangeAction,
  MapActionType,
} from 'map/mapActions';
import { MapItemTypes } from 'map/mapConstants';
import { Boundary, Cluster, ClusterData, Clusters } from 'map/mapTypes';
import { DraftListing } from 'myListings/myListingsTypes';
import { PermissionRoles } from 'permissions/permissionsConstants';
import { RootState } from 'rootReducer';
import { extractLeaseFromSearchQuery } from 'saved-search/savedSearchHelpers';
import {
  getCurrentSavedSearch,
  getIsLeaseSavedSearch,
} from 'saved-search/savedSearchSelectors';
import { SavedSearch, UnionSearch } from 'saved-search/savedSearchTypes';
import { Tag } from 'search/search-types';
import { HeaderHeights } from 'shared/components/Header/HeaderConstants';
import { ModalContext } from 'shared/components/Modal/modalContext';
import { SortingData } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { first } from 'shared/helpers/array';
import {
  isEmpty,
  not,
  notEmpty,
  notEqual,
  notNil,
} from 'shared/helpers/boolean';
import { createListingsUrl } from 'shared/helpers/createListingsUrl';
import {
  formatPriceToNumber,
  formatShortPrice,
  separateThousandsWithCommas,
} from 'shared/helpers/formatters';
import { isLeasePage } from 'shared/helpers/isLeasePage';
import { getIsViewedListingsPage } from 'shared/helpers/isViewedListings';
import { unsafePick } from 'shared/helpers/object';
import history from 'shared/services/history';
import { PlaceType } from 'shared/types/placesAndPolygons';
import { AuthType, showAuthModal } from 'user/helpers/showAuthModal';
import {
  getIsAgent,
  getIsLogged,
  getUserId,
  getUserLocation,
} from 'user/userSelectors';
import { UserLocation, UserProfile, UserRole } from 'user/userTypes';

// TODO: We should get rid of this method in favor of service
export function* constructUrl({
  path,
  mapData,
  collectionId,
  userId: contactId,
  trecLicenses,
  dontSaveViewedSearch,
  lease,
}: {
  path: string;
  mapData?: {
    boundaries: Boundary;
    zoom?: number;
  } | null;
  collectionId?: string;
  trecLicenses?: string[];
  userId?: string;
  dontSaveViewedSearch?: boolean | null;
  lease?: boolean;
}) {
  const url = new URL(`${window.location.origin}${path}`);

  let filters: FiltersState = yield select(({ filters }) => filters);
  const userLocation: UserLocation = yield select(getUserLocation);

  const { page, sortBy, sortType, ourListingsFirst }: ListingsState =
    yield select(({ listings }) => listings);

  const tags: Tag[] = yield select<(state: RootState) => Tag[]>(
    state => state.search.tags
  );
  const isViewedListings = getIsViewedListingsPage(history.location.pathname);

  const withoutDefaultFilters = Boolean(collectionId || isViewedListings);

  const hasExclusive =
    (not(filters.exclusiveListingsEnabled) &&
      not(filters.mlsListingsEnabled)) ||
    filters.exclusiveListingsEnabled;

  if (withoutDefaultFilters) {
    filters = {
      ...filters,
      mlsStatus: [],
      townhouse: false,
      condominium: false,
      singleFamilyResidence: false,
    };
  }

  const paramsValues = {
    ...filters,
  };

  const paramKeysToSend = (
    Object.keys(filters) as Array<keyof FiltersState>
  ).filter(
    key =>
      notEqual(filters[key], FILTERS_DEFAULT_STATE[key]) || key === 'mlsStatus'
  );

  const { tagsWithoutPolygons, polygonsIds } = filterPolygonsTags(tags);
  tagsWithoutPolygons.forEach(filter => {
    if (filter.value) {
      // For now it is always 'address', replace with tag.type in future, when BE ready
      url.searchParams.append('address', filter.value);
    }
  });

  const isUserLogged: boolean = yield select(getIsLogged);
  const isLeaseSavedSearch: boolean = yield select(getIsLeaseSavedSearch);
  const onLeasePage = isLeasePage(history.location.pathname);
  const savedSearch: SavedSearch = yield select(getCurrentSavedSearch);
  const isAgentSSOwner = savedSearch.user?.role === UserRole.Agent;
  const isLease = onLeasePage || isLeaseSavedSearch;
  const isAgent: boolean = yield select(getIsAgent);
  const agentMode: boolean = yield select(getAgentMode);
  const propertyTypeFilters: PropertyTypeFilters = yield select(
    $getPropertyTypeFilters
  );
  const isPropertyTypeFiltersChanged: boolean = yield select(
    $getIsPropertyTypeFiltersChanged
  );
  const isPrivateMlsStatusAvailable =
    isAgent && not(agentMode) && not(isLease) && hasExclusive;
  const listingsPermission = getListingsPermission({
    exclusiveListingsEnabled: filters.exclusiveListingsEnabled,
    mlsListingsEnabled: filters.mlsListingsEnabled,
    hasPrivate: filters.mlsStatus.includes(MlsStatus.Private),
    isAgent,
    agentMode,
    isMlsStatusEmpty: isEmpty(filters.mlsStatus),
    isPrivateMlsStatusAvailable,
    isAgentSSOwner,
  });
  const userId: string = yield select(getUserId);

  paramKeysToSend.forEach(key => {
    if (key === 'period' && paramsValues[key]) {
      const periodValues = getPeriodStartAndEnd(paramsValues[key]);

      if (periodValues['periodStart']) {
        url.searchParams.append(
          'periodStart',
          String(periodValues['periodStart'])
        );
      }

      if (periodValues['periodEnd']) {
        url.searchParams.append('periodEnd', String(periodValues['periodEnd']));
      }

      return;
    }

    if (
      key === 'mlsStatus' &&
      isEmpty(paramsValues[key]) &&
      not(withoutDefaultFilters)
    ) {
      if (not(isUserLogged)) {
        paramsValues[key] = [MlsStatus.Active, MlsStatus.PendingTakingBackups];
      } else {
        paramsValues[key] = [
          MlsStatus.Active,
          MlsStatus.PendingTakingBackups,
          MlsStatus.Pending,
        ];

        if (isPrivateMlsStatusAvailable) {
          paramsValues[key] = [...paramsValues[key], MlsStatus.Private];
        }

        if (not(paramsValues.openHouseOnly)) {
          paramsValues[key] = [
            ...paramsValues[key],
            isLease ? MlsStatus.Leased : MlsStatus.Sold,
          ];

          url.searchParams.append(
            isLease ? 'leasedTimeframe' : 'soldTimeframe',
            String(TIME_FRAME_ONE_YEAR_VALUE)
          );
        }
      }
    }

    if (key in PROPERTY_TYPE_DEFAULT_STATE) {
      return;
    }

    url.searchParams.append(key, String(paramsValues[key]));
  });

  if (isPropertyTypeFiltersChanged) {
    Object.keys(propertyTypeFilters).forEach(
      (key: keyof PropertyTypeFilters) => {
        if (
          propertyTypeFilters[key] ||
          propertyTypeFilters[key] !== PROPERTY_TYPE_DEFAULT_STATE[key]
        ) {
          url.searchParams.append(key, String(propertyTypeFilters[key]));
        }
      }
    );
  }

  url.searchParams.append(
    'privateExclusiveListing',
    String(listingsPermission)
  );
  url.searchParams.append('page', String(page));
  url.searchParams.append('sortBy', String(sortBy));
  url.searchParams.append('sortType', String(sortType));
  url.searchParams.append('ourListingsFirst', String(ourListingsFirst));

  if (polygonsIds && polygonsIds.length > 0) {
    url.searchParams.append('polygon', String(polygonsIds));
  }

  if (notEmpty(mapData?.boundaries)) {
    url.searchParams.append(
      'minLatitude',
      String(mapData?.boundaries.minLatitude)
    );
    url.searchParams.append(
      'minLongitude',
      String(mapData?.boundaries.minLongitude)
    );
    url.searchParams.append(
      'maxLatitude',
      String(mapData?.boundaries.maxLatitude)
    );
    url.searchParams.append(
      'maxLongitude',
      String(mapData?.boundaries.maxLongitude)
    );
  }

  if (mapData?.zoom) {
    url.searchParams.append('zoom', String(mapData.zoom));
  }

  if (collectionId) {
    if (isDismissedId(collectionId)) {
      url.searchParams.append('dismissed', 'true');
      url.searchParams.append('dismissedOnly', 'true');
    } else if (isProposedId(collectionId)) {
      url.searchParams.append('proposedOnly', 'true');
    } else {
      url.searchParams.append('collection', collectionId);
    }
  }

  if (tagsWithoutPolygons.some(tag => tag.type === PlaceType.MlsCode)) {
    url.searchParams.append('dismissed', 'true');
  }

  if (isViewedListings) {
    url.searchParams.append('viewedBy', contactId || userId);

    if (agentMode && contactId) {
      url.searchParams.append('user', contactId);
    }

    url.searchParams.append('dismissed', 'true');
  }

  if (notEmpty(trecLicenses)) {
    trecLicenses.forEach((trecLicense: string) => {
      url.searchParams.append('trecLicense', trecLicense);
    });

    url.searchParams.append('limit', String(LISTINGS_PER_BIO_PAGE));
    url.searchParams.append('lot', String(true));
    url.searchParams.append('farm', String(true));
    url.searchParams.append('multiFamily', String(true));
    url.searchParams.append('commercialSale', String(true));

    // We need to send these params on a BIO page for getting sold exclusive listings
    // when 'Show this Sold Listing on My BIO Page' is checked
    url.searchParams.append('showEnabledAgentListings', String(true));

    if (sortBy === SortingData.price) {
      // Change sortBy type when on agent page
      url.searchParams.delete('sortBy');
      url.searchParams.append('sortBy', SortingData.statusPrice);
    }
  }

  if (contactId && not(isViewedListings)) {
    url.searchParams.append('user', contactId);
  }

  if (notNil(dontSaveViewedSearch)) {
    url.searchParams.append(
      'dontSaveViewedSearch',
      String(dontSaveViewedSearch)
    );
  }

  if (userLocation) {
    url.searchParams.set('longitude', `${userLocation.longitude}`);
    url.searchParams.set('latitude', `${userLocation.latitude}`);
  }

  if (lease) {
    url.searchParams.set('lease', String(true));
  }

  return url;
}

enum ListingsPermission {
  Public = 'public',
  Exclusive = 'exclusive',
  PublicExclusive = 'public_exclusive',
}

const getListingsPermission = (props: {
  isAgent: boolean;
  exclusiveListingsEnabled: boolean;
  mlsListingsEnabled: boolean;
  hasPrivate: boolean;
  agentMode: boolean;
  isMlsStatusEmpty: boolean;
  isPrivateMlsStatusAvailable: boolean;
  isAgentSSOwner: boolean;
}): string => {
  const onAgentBioPage = isAgentBioRoute(history.location.pathname);
  const onSavedSearchPage = history.location.pathname.includes('saved-search');
  const isUsersSavedsearch = onSavedSearchPage && not(props.isAgentSSOwner);

  if (onAgentBioPage) {
    return [
      ListingsPermission.Public,
      ListingsPermission.PublicExclusive,
      ListingsPermission.Exclusive,
    ].join(',');
  }

  if (isUsersSavedsearch && not(props.hasPrivate || props.isMlsStatusEmpty)) {
    return [ListingsPermission.Public, ListingsPermission.PublicExclusive].join(
      ','
    );
  }

  const permission = [];
  const showAllListing =
    not(props.exclusiveListingsEnabled) && not(props.mlsListingsEnabled);

  if (showAllListing) {
    permission.push(
      ...[ListingsPermission.Public, ListingsPermission.PublicExclusive]
    );
  }

  if (props.mlsListingsEnabled) {
    permission.push(ListingsPermission.Public);
  }

  if (props.exclusiveListingsEnabled) {
    permission.push(ListingsPermission.PublicExclusive);
  }

  if (
    (props.hasPrivate || props.isMlsStatusEmpty) &&
    props.isPrivateMlsStatusAvailable
  ) {
    permission.push(ListingsPermission.Exclusive);
  }

  return permission.join(',');
};

export const getListingAddress = (
  listing: Partial<Listing> | Partial<ListingWithDetails> | DraftListing
): string => {
  if (listing.address) {
    return listing.address;
  }

  const street =
    Object.values(
      unsafePick(listing, [
        'streetnumber',
        'streetdirprefix',
        'streetname',
        'streetsuffix',
        'streetdirsuffix',
      ])
    )
      .filter(Boolean)
      .join(' ') || UNDISCLOSED_ADDRESS;

  const unitNumber =
    listing.unitnumber && street !== UNDISCLOSED_ADDRESS
      ? ` ${listing.unitnumber}`
      : '';

  const locationCity = listing.city ? `${listing.city}, ` : '';

  const stateAndPostal = [listing.stateorprovince, listing.postalcode]
    .filter(Boolean)
    .join(', ');

  const comaAfterStreet = locationCity || stateAndPostal ? ', ' : '';

  return `${street}${unitNumber}${comaAfterStreet}${locationCity}${stateAndPostal}`;
};

export const filterPolygonsTags = (
  tags: Tag[]
): {
  polygonsIds: string[];
  tagsWithoutPolygons: Tag[];
} => {
  let polygonsIds: string[] = [];
  let tagsWithoutPolygons: Tag[] = [];

  tags.forEach((tag: Tag) => {
    if (tag.id) {
      // polygon id
      polygonsIds.push(tag.id);
    } else if (tag.type !== SAVED_SEARCH_TAG_TYPE) {
      // tag without polygon
      tagsWithoutPolygons.push(tag);
    } else if (tag.tags) {
      // add nested tags
      const nested = filterPolygonsTags(tag.tags);
      polygonsIds = polygonsIds.concat(nested.polygonsIds);
      tagsWithoutPolygons = tagsWithoutPolygons.concat(
        nested.tagsWithoutPolygons
      );
    }
  });

  return {
    polygonsIds: Array.from(new Set(polygonsIds)),
    tagsWithoutPolygons: Array.from(new Set(tagsWithoutPolygons)),
  };
};

export const isMapAction = (
  action: FetchListingsSagaAction
): action is HandleMapChangeAction | HandleClusterClickAction => {
  return (
    action.type === MapActionType.HANDLE_MAP_CHANGE ||
    action.type === MapActionType.HANDLE_CLUSTER_CLICK
  );
};

export const getHeaderHeights = (
  path: string,
  additionalParams: {
    isMapVisible?: boolean;
    isDismissed?: boolean;
    isAgentMode?: boolean;
  } = {}
) => {
  const { isMapVisible, isDismissed } = additionalParams;

  switch (path) {
    case RoutePath.LISTINGS:
    case RoutePath.LEASE:
    case RoutePath.AGENT_LISTINGS:
    case RoutePath.AGENT_LISTINGS_LEASE:
    case RoutePath.MARKET_OVERVIEW_WITH_TAG: {
      return {
        desktop: HeaderHeights.ListingsDesktop,
        mobile: isMapVisible
          ? HeaderHeights.ListingsMobileWithMap
          : HeaderHeights.ListingsMobile,
      };
    }

    case RoutePath.AGENT_COLLECTION:
    case RoutePath.COLLECTION: {
      let mobile = isMapVisible
        ? HeaderHeights.CollectionMobileWithMap
        : HeaderHeights.CollectionMobile;
      let desktop = HeaderHeights.CollectionDesktop;

      if (isDismissed) {
        mobile = isMapVisible
          ? HeaderHeights.CollectionMobileWithMap
          : HeaderHeights.CollectionMobileDismissed;
        desktop = HeaderHeights.CollectionDesktopDismissed;
      }

      return {
        mobile,
        desktop,
      };
    }
    case RoutePath.AGENT_SAVED_SEARCH:
    case RoutePath.SAVED_SEARCH: {
      return {
        mobile: isMapVisible
          ? HeaderHeights.SavedSearchMobileWithMap
          : HeaderHeights.SavedSearchMobile,
        desktop: HeaderHeights.SavedSearchDesktop,
      };
    }
    case RoutePath.AGENT_VIEWED_LISTINGS: {
      return {
        mobile: isMapVisible
          ? HeaderHeights.ViewedListingsMobileWithMap
          : HeaderHeights.AgentViewedListingsMobile,
        desktop: HeaderHeights.ViewedListingsDesktop,
      };
    }

    case RoutePath.VIEWED_LISTINGS: {
      return {
        mobile: isMapVisible
          ? HeaderHeights.MyViewedListingsMobileWithMap
          : HeaderHeights.MyViewedListingsMobile,
        desktop: HeaderHeights.MyViewedListingsDesktop,
      };
    }

    default: {
      return {
        mobile: HeaderHeights.Default,
        desktop: HeaderHeights.Default,
      };
    }
  }
};

interface AddintionalParams {
  contactName?: string;
  saveSearchName?: string;
  collection?: Collection;
}

export const getBreadcrumbs = (
  match: any,
  additionalParams: AddintionalParams
) => {
  const {
    contactName = '',
    saveSearchName = '',
    collection = { name: '' },
  } = additionalParams;
  const {
    params: { contactId },
    url: currentUrl,
    path,
  } = match;

  const agentBreadcrumbs = contactId
    ? [
        { value: 'My Contacts', url: RoutePath.AGENT_CONTACTS },
        {
          value: contactName,
          url: generatePath(RoutePath.AGENT_CONTACT_BASIC_INFO, {
            contactId,
          }),
        },
      ]
    : [];

  switch (path) {
    case RoutePath.COLLECTION: {
      return [
        { value: 'Collections', url: RoutePath.COLLECTIONS },
        {
          value: collection.name,
          url: currentUrl,
        },
      ];
    }

    case RoutePath.AGENT_COLLECTION: {
      return [
        ...agentBreadcrumbs,
        {
          value: 'Collections',
          url: generatePath(RoutePath.AGENT_COLLECTIONS, {
            contactId,
          }),
        },
        {
          value:
            collection.name === PROPOSED_COLLECTION_NAME
              ? `RA ${PROPOSED_COLLECTION_NAME}`
              : collection.name,
          url: currentUrl,
        },
      ];
    }

    case RoutePath.SAVED_SEARCH: {
      return [
        { value: 'Saved Searches', url: RoutePath.SAVED_SEARCHES },
        { value: saveSearchName, url: currentUrl },
      ];
    }

    case RoutePath.AGENT_SAVED_SEARCH: {
      return [
        ...agentBreadcrumbs,
        {
          value: 'Saved Searches',
          url: generatePath(RoutePath.AGENT_SAVED_SEARCHES, {
            contactId,
          }),
        },
        { value: saveSearchName, url: currentUrl },
      ];
    }

    case RoutePath.AGENT_LISTINGS_LEASE:
    case RoutePath.AGENT_LISTINGS: {
      return agentBreadcrumbs;
    }

    case RoutePath.AGENT_VIEWED_LISTINGS: {
      return [
        ...agentBreadcrumbs,
        {
          value: 'Viewed Listings',
          url: currentUrl,
        },
      ];
    }
    default:
      return [];
  }
};

const listingDetailsUrlRegExp = new RegExp('([a-z0-9]+)\\.html');

export function getListingDetailsId(url?: string): string | undefined {
  return url?.match(listingDetailsUrlRegExp)?.[1];
}

const listingMlsUrlRegExp = new RegExp('^/?([0-9]+)/?$');

export function getListingDetailsIdFromMlsUrl(url: string): string | undefined {
  return url?.match(listingMlsUrlRegExp)?.[1];
}

export function addCollectionIdInListing({
  listing,
  collectionID,
  actionListingID,
}: {
  listing: Listing;
  collectionID: string;
  actionListingID?: string;
}) {
  if (listing.listingid !== actionListingID || not(actionListingID)) {
    return listing;
  }
  return {
    ...listing,
    collections: [...(listing.collections || []), { id: collectionID }],
  };
}

export const isRaListing = (listingOfficeId: string): boolean =>
  Boolean(listingOfficeId) &&
  new RegExp(`^${RA_OFFICE_ID}`).test(listingOfficeId);

export const isSaListing = (listingOfficeId: string): boolean =>
  Boolean(listingOfficeId) &&
  new RegExp(`^${SA_OFFICE_ID}`).test(listingOfficeId);

export const isOurListing = (listingOfficeId?: string): boolean =>
  Boolean(listingOfficeId) &&
  (isRaListing(listingOfficeId ?? '') || isSaListing(listingOfficeId ?? ''));

export const getClickedMarketListingPage = (
  listingId: string,
  clusters: Clusters
): number => {
  const mapListings = clusters.filter(
    cluster => cluster.type === MapItemTypes.Listing
  );
  const mapGroups = clusters.filter(
    cluster => cluster.type === MapItemTypes.Group
  );
  const clickedListing =
    notEmpty(mapListings) &&
    getClickedListing(mapListings as Cluster[], listingId);
  const clickedGroup =
    notEmpty(mapGroups) &&
    getClickedGroupOfListings(mapGroups as any, listingId);

  let listingPage = 0;

  if (clickedListing) {
    const shouldIncreasePageCount =
      clickedListing.data.serialNumber !== LISTINGS_PER_PAGE;
    if (clickedListing.data.serialNumber) {
      listingPage = shouldIncreasePageCount
        ? Math.trunc(clickedListing.data.serialNumber / LISTINGS_PER_PAGE) + 1
        : Math.trunc(clickedListing.data.serialNumber / LISTINGS_PER_PAGE);
    }
  } else if (clickedGroup) {
    const listing = first(clickedGroup.data);
    if (listing?.serialNumber) {
      const shouldIncreasePageCount =
        listing.serialNumber !== LISTINGS_PER_PAGE;
      listingPage = shouldIncreasePageCount
        ? Math.trunc(listing.serialNumber / LISTINGS_PER_PAGE) + 1
        : Math.trunc(listing.serialNumber / LISTINGS_PER_PAGE);
    }
  }

  return listingPage;
};

const getClickedListing = (mapListings: Cluster[], listingId: string) => {
  return mapListings.find(({ data }) => {
    return data ? data.listingid === listingId : false;
  });
};

const getClickedGroupOfListings = (
  mapGroups: Array<Cluster<ClusterData[]>>,
  listingId: string
) => {
  return mapGroups.find(({ data }) => {
    return data ? first(data)?.listingid === listingId : false;
  });
};

export const addImagesToListings = (
  listings: Listing[],
  images: ListingsImagesResponse
): Listing[] => {
  return listings.map((listingItem: Listing) => {
    return {
      ...listingItem,
      images: images[listingItem.listingkey] || [],
    };
  });
};

export const isFirstPage = (currentPage: number): boolean => currentPage === 1;

export const isLastPage = (
  currentPage: number,
  total: number,
  limit?: number
): boolean => {
  const listingsPerPage = limit ? limit : LISTINGS_PER_PAGE;
  const maxPageCount = Math.ceil(total / listingsPerPage);

  return currentPage === maxPageCount;
};

export const getListingsTabHiddenTitle = (tag?: Tag): string => {
  if (!tag || !tag.filter) return config.seoData.listings.hiddenTitle;

  const {
    filter: {
      city,
      stateorprovince,
      county,
      subdivision,
      postalcode,
      school,
      schooldistrict,
      mlsarea,
    },
  } = tag;

  switch (tag.type) {
    case PlaceType.City:
      return `${city} ${stateorprovince} ${STATIC_LISTINGS_TAB_HIDDEN_TITLE}`;
    case PlaceType.County:
      return `${county} County ${stateorprovince} ${STATIC_LISTINGS_TAB_HIDDEN_TITLE}`;
    case PlaceType.Subdivision:
      return `${subdivision} ${STATIC_LISTINGS_TAB_HIDDEN_TITLE}`;
    case PlaceType.PostalCode:
      return `${postalcode} ${STATIC_LISTINGS_TAB_HIDDEN_TITLE}`;
    case PlaceType.School:
      return `${school} ${STATIC_LISTINGS_TAB_HIDDEN_TITLE}`;
    case PlaceType.SchoolDistrict:
      return `${schooldistrict} ${STATIC_LISTINGS_TAB_HIDDEN_TITLE}`;
    case PlaceType.MlsArea:
      return `${mlsarea} ${STATIC_LISTINGS_TAB_HIDDEN_TITLE}`;
    default:
      return config.seoData.listings.hiddenTitle;
  }
};

export const isLeaseListing = (
  propertyType?: PropertyType | PropertyFilterSubType
): boolean =>
  propertyType === PropertyType.ResidentialLease ||
  propertyType === PropertyFilterSubType.Lease;

export const getListingPrice = (params: {
  item: {
    listprice?: string;
    mlsstatus?: MlsStatus;
    propertytype?: PropertyType;
    propertyType?: ListingDetailsPropertyType;
    closeprice?: string | null;
  };
  isPriceAvailable?: boolean;
  priceForMapPin?: boolean;
}): string => {
  const { listprice, mlsstatus, propertytype, propertyType, closeprice } =
    params.item;
  if (not(mlsstatus)) {
    return '';
  }

  if (params.priceForMapPin) {
    if (
      MlsStatusesWithoutPrice.includes(mlsstatus) &&
      not(params.isPriceAvailable)
    ) {
      return '';
    }

    return formatShortPrice(
      formatPriceToNumber(
        [MlsStatus.Expired, MlsStatus.Withdrawn].includes(mlsstatus)
          ? listprice
          : closeprice || listprice
      ),
      false
    );
  }

  const price =
    MlsStatusesWithoutPrice.includes(mlsstatus) && params.isPriceAvailable
      ? separateThousandsWithCommas(closeprice)
      : separateThousandsWithCommas(listprice);

  return isLeaseListing(propertytype || propertyType) ? price + '/mo' : price;
};

export const getListingAriaPrice = (
  price: string,
  propertyType: PropertyType
): string => {
  if (not(price)) {
    return '';
  }
  const isLease = isLeaseListing(propertyType);
  return `${price
    .replace('$', '')
    .replace(',', '')
    .replace('.00', '')} dollars ${isLease ? 'per month' : ''}`;
};

export const redirectToListingDetailsIfNeeded = (
  tag: Tag,
  agentMode = false,
  contactItem: Contact | null = null
): boolean => {
  if (!tag) {
    return false;
  }

  if (
    tag.type === PlaceType.MlsCode &&
    Boolean(tag?.filter?.listingid) &&
    not(agentMode)
  ) {
    const url = createListingsUrl(tag.filter, agentMode, contactItem);
    history.push(url);
    return true;
  }

  return false;
};

export const showLeaseTab = (user: UserProfile | null, pathname: string) => {
  return (
    isLeasePage(pathname) ||
    user?.isLeasedSearchAllowed ||
    user?.role === UserRole.Agent
  );
};

//fix for https://ra-next-jira.atlassian.net/browse/RAD-6254
// for some listings we receive wrong format of mlsStatuses
//AR mlsStatus: ["Active,Pending - Taking Backups", "Active"] => ER: ["Active", "Pending - Taking Backups", "Active"]

export const normalizeMlsStatuses = (statuses?: string[]) =>
  statuses
    ? statuses.reduce<string[]>((res, status) => {
        return [...res, ...status.split(',')];
      }, [])
    : [];

export const getIsLease = ({
  isLeaseListing = false,
  savedSearch,
}: {
  isLeaseListing?: boolean;
  savedSearch: UnionSearch;
}): boolean => {
  return savedSearch.id
    ? extractLeaseFromSearchQuery(savedSearch.query)
    : isLeasePage(history.location.pathname) || isLeaseListing;
};

export const displayLoginModal = (
  modalContext: React.ContextType<typeof ModalContext>
) => {
  const openModal = showAuthModal(AuthType.SignUp, modalContext, {
    userParams: { firstName: '', lastName: '', phone: '' },
    hasCloseBtn: false,
    onRequestClose: () => false,
  });
  openModal();
};

export const counterForceLogin = (
  modalContext: React.ContextType<typeof ModalContext>,
  loginStatus?: PermissionRoles,
  listOfficeId?: string
) => {
  const isOurList = isOurListing(listOfficeId);
  if (
    loginStatus !== PermissionRoles.VerifiedUser &&
    loginStatus !== PermissionRoles.NotVerifiedUser &&
    !isOurList
  ) {
    let visitCounter = Number(localStorage.getItem(GUEST_VISITED_LISTINGS));
    visitCounter += 1;
    localStorage.setItem(GUEST_VISITED_LISTINGS, visitCounter.toString());

    if (visitCounter >= MAX_VIEW_LISTINGS_BY_GUEST) {
      displayLoginModal(modalContext);
      return true;
    }
  }
  return false;
};

//Check if the listing has HOA  enabled
export const hasHOA = (listing: ListingWithDetails) => {
  if (!listing.hoa || !listing.hoa[0]) {
    return false;
  }

  return listing?.hoa[0].values[0].desc === 'No';
};
