import React from 'react';

import { SAVED_SEARCH_TAG_TYPE } from 'listings/listingsConstants';
import { Tag } from 'search/search-types';
import SearchBarTagStyled from 'shared/components/SearchBarTag/SearchBarTagStyled';
import { not, notEmpty } from 'shared/helpers/boolean';
import { ReactComponent as IconClose } from 'shared/icons/shared/24x24/cancel.svg';
import { ReactComponent as IconLeft } from 'shared/icons/shared/arrows/small-arrow-left.svg';

export enum SearchBarTagTestIds {
  closeButton = 'closeButton',
  divider = 'divider',
  tag = 'tag',
  textHint = 'textHint',
  iconHint = 'iconHint',
}

export interface SearchBarTagProps {
  className?: string;
  label?: string;
  showDivider?: boolean;
  showCross?: boolean;
  tag: Tag;
  onCloseTag: React.MouseEventHandler;
  toggleSearchTag: () => void;
  clickHandler: () => void;
}

function SearchBarTag(props: SearchBarTagProps) {
  const {
    className,
    label,
    showDivider,
    showCross,
    tag,
    onCloseTag,
    toggleSearchTag,
    clickHandler,
  } = props;

  const onClickHandler = (e: React.MouseEvent) => {
    if (tag.type === SAVED_SEARCH_TAG_TYPE) {
      toggleSearchTag();
      e.stopPropagation();
    } else {
      clickHandler();
    }
  };

  const isSavedSearchTag = tag.type === SAVED_SEARCH_TAG_TYPE;
  const savedSearchTagHasChildTags = isSavedSearchTag && notEmpty(tag.tags);

  const crossIsShown = showCross && not(isSavedSearchTag);

  return (
    <SearchBarTagStyled.Wrapper className={className}>
      <SearchBarTagStyled.Tag
        data-testid={SearchBarTagTestIds.tag}
        isSaveSearch={isSavedSearchTag}
        onClick={onClickHandler}
        tabIndex={0}
      >
        <SearchBarTagStyled.Label>{label}</SearchBarTagStyled.Label>

        {crossIsShown && (
          <IconClose
            onClick={onCloseTag}
            role="button"
            data-testid={SearchBarTagTestIds.closeButton}
            tabIndex={0}
          />
        )}

        {tag.open
          ? savedSearchTagHasChildTags && (
              <SearchBarTagStyled.IconHint
                data-testid={SearchBarTagTestIds.iconHint}
              >
                <IconLeft />
              </SearchBarTagStyled.IconHint>
            )
          : savedSearchTagHasChildTags && (
              <SearchBarTagStyled.TextHint
                data-testid={SearchBarTagTestIds.textHint}
              >
                {tag.tags.length}
              </SearchBarTagStyled.TextHint>
            )}
      </SearchBarTagStyled.Tag>

      {showDivider && (
        <SearchBarTagStyled.Divider data-testid={SearchBarTagTestIds.divider} />
      )}
    </SearchBarTagStyled.Wrapper>
  );
}

SearchBarTag.defaultProps = {
  className: '',
  label: '',
  isSavedSearchTag: false,
  showDivider: false,
  showCross: false,
};

export default SearchBarTag;
