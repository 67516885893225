import { MlsStatus } from 'filters/filtersContants';
import {
  FeaturesAndFactsSectionValueType,
  SchoolsGroup,
} from 'listingDetails/listingDetailsTypes';
import {
  Listing,
  ListingPolygons,
  PropertyFilterSubType,
  PropertySubType,
  PropertyType,
} from 'listings/listingsTypes';
import { Boundaries } from 'map/mapTypes';
import { MlsSource, MyListingLayouts } from 'myListings/myListingsConstants';
import { DuplicateListing } from 'myListings/myListingsHelpers';
import { CarouselImageItem } from 'shared/components/ImagesCarousel/ImagesCarousel';
import { SortOrder } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { DiffImgSizes, OptionalToNullable } from 'shared/types';

export interface DraftListingImage {
  diffImgSizes?: DiffImgSizes;
  mediaUrl: string;
  description?: string;
  shortdescription?: string;
  mediaObjectId: string;
  sortOrder?: number;
}

export interface IAgentsFilter {
  agentId: string;
}

export type MyListingsPriceFilter = {
  minPrice: number;
  maxPrice: number;
};

export type MyListingsFilters = IAgentsFilter & MyListingsPriceFilter;
export interface MyListingsState {
  currentTab: MyListingLayouts;
  viewMode: string;
  items: ListingWithMedia[] | DraftListing[];
  loading: boolean;
  page: number;
  listingsCount: number;
  error: string;
  sortBy: string;
  sortType: SortOrder;
  isMyListingsFirst: boolean;
  itemsToRemove: string[];
  filters: MyListingsFilters;
}

export interface MyListingsNav {
  currentTab: MyListingLayouts;
}

export interface UploadImageResponse {
  data: {
    mediaCategory: string;
    mediaObjectId: string;
    mediaUrl: string;
    mimeType: string;
    sortOrder?: number;
    shortDescription?: string;
    createdAt: Date;
    updatedAt: Date;
    diffImgSizes: DiffImgSizes;
  };
  description: string;
}

export enum ExclusiveListingKeys {
  bedroomstotal = 'bedroomstotal',
  squareFit = 'squareFit',
  lotSize = 'lotSize',
  propertyType = 'propertyType',
  yearbuilt = 'yearbuilt',
  stories = 'stories',
  garagespaces = 'garagespaces',
  bathroomsfull = 'bathroomsfull',
  listingAgent = 'listingAgent',
  formattedAddress = 'formattedAddress',
  bathroomshalf = 'bathroomshalf',
  ABOR_MasterMain = 'ABOR_MasterMain',
  fireplaceyn = 'fireplaceyn',
  ABOR_GuestAccommodation = 'ABOR_GuestAccommodation',
  ABOR_Rooms = 'ABOR_Rooms',
  hasRooms = 'hasRooms',
  hasFencing = 'hasFencing',
  fencing = 'fencing',
  hasExteriorFeatures = 'hasExteriorFeatures',
  ExteriorFeatures = 'ExteriorFeatures',
  hasView = 'hasView',
  view = 'view',
  poolprivateyn = 'poolprivateyn',
  HorseYN = 'HorseYN',
  expirationDate = 'expirationDate',
  mlsTargetDate = 'mlsTargetDate',
  publicDate = 'publicDate',
  images = 'images',
  status = 'status',
  closeDate = 'closeDate',
  showSoldInBio = 'showSoldInBio',
  price = 'price',
  lat = 'lat',
  lng = 'lng',
  private = 'private',
  public = 'public',
  type = 'type',
  unit = 'unit',
  streetNumber = 'streetNumber',
  streetName = 'streetName',
  city = 'city',
  stateOrProvince = 'stateOrProvince',
  postalCode = 'postalCode',
  lotFeatures = 'lotFeatures',
  AssociationYN = 'AssociationYN',
  waterfrontyn = 'waterfrontyn',
  CommunityFeatures = 'CommunityFeatures',
  virtualtoururlunbranded = 'virtualtoururlunbranded',
  subdivisionName = 'subdivisionName',
  listingAgentPhone = 'listagentdirectphone',
  listingAgentEmail = 'listagentemail',
}

export interface ExclusiveListing {
  buildingInfoData: Array<{
    label: string;
    values:
      | Array<{
          title: string;
          desc: string;
          type: string;
        }>
      | [];
  }>;
  featuresAndFactsData:
    | Array<{
        title: string;
        desc: string;
        type: FeaturesAndFactsSectionValueType;
      }>
    | [];
  images:
    | Array<{
        description?: string;
        diffimgsizes: any;
        mediaobjectid: string;
        mediaurl: string;
        shortdescription?: string;
      }>
    | [];
  interiorFeaturesData: Array<{
    label: string;
    values:
      | Array<{
          title: string;
          desc: string;
          type: string;
        }>
      | [];
  }>;
  mapData: {
    address: string;
    latitude: string;
    longitude: string;
  };
  propertyData: Array<{
    label: string;
    values:
      | Array<{
          title: string;
          desc: string;
          type: string;
        }>
      | [];
  }>;
  propertyListData: Array<{
    label: string;
    values:
      | Array<{
          title: string;
          desc: string;
          type: string;
        }>
      | [];
  }>;
  remarks: {
    private: string;
    public: string;
  };
  listingDetails: {
    address: string;
    price: string;
    listingAgent: string;
    listingAgentPhone?: string;
    listingStatus: MlsStatus;
    bathroomTotalInteger: number;
    propertyType: PropertyType;
    numberOfUnitsTotal?: string;
    virtualTourUrlUnbranded?: string;
    expirationDate?: Date;
    mlstargetDate?: Date;
    publicDate?: Date;
    closePrice?: string;
  };
  schools?: SchoolsGroup;
  polygons?: ListingPolygons;
}

export interface FieldCatalog {
  success: boolean;
  description: string;
  data: FieldCatalogItem[];
}

export interface FieldCatalogItem {
  name: string;
  type: 'single' | 'multiple';
  values: FieldCatalogItemValue[];
}

export interface FieldCatalogItemValue {
  id: number;
  value: string;
}
export interface MyListingsResponse {
  boundaries: Boundaries;
  listings: ListingWithMedia[];
  total: number;
}
export interface DraftListingPolygons {
  polygonsMainGroups: ListingPolygons;
  schoolLevels: SchoolsGroup;
}
export interface DraftListingResponse {
  listing: Omit<DraftListing, 'media'> & {
    media: DraftListingImage[];
  };
  polygons: DraftListingPolygons;
}

export interface DraftListing {
  abor_guestaccomodation?: boolean;
  abor_mastermain?: boolean;
  abor_rooms?: string[];
  address: string;
  agentId: string;
  bathroomsfull?: number;
  bathroomshalf?: number;
  bathroomstotalinteger?: number;
  bedroomstotal?: number;
  buildingareatotal?: number;
  city?: string;
  closedate?: string;
  closeprice?: string | null;
  communityfeatures?: string[];
  expirationdate?: string;
  exteriorfeatures?: string[];
  fencing?: string[];
  fireplaceyn?: boolean;
  garagespaces?: number;
  hasAssociation?: boolean;
  hasFireplace?: boolean;
  horseyn?: boolean;
  latitude?: string;
  listagentdirectphone?: string | null;
  listagentemail?: string;
  listagentfullname: string;
  listagentmlsid?: string;
  listingid: string;
  primarylistingid?: string;
  listofficemlsid: string;
  listofficename?: string;
  listprice?: string;
  longitude?: string;
  livingarea?: number;
  lotfeatures?: string[];
  lotsizearea?: number;
  lotsizesquarefeet?: number;
  mlsstatus: MlsStatus;
  mlsTargetDate?: string;
  numberofunitstotal?: string;
  offmarketdate?: string;
  onmarketdate?: string;
  publicremarks?: string;
  poolprivateyn?: boolean;
  postalcode?: string;
  privateremarks?: string;
  propertyfiltertype?: PropertyFilterSubType;
  propertysubtype?: PropertySubType;
  propertytype: PropertyType;
  remarks?: string;
  relatedDomain: string;
  relatedMlsSource?: MlsSource;
  source: 'EX';
  stateorprovince?: string;
  stories?: number;
  streetname?: string;
  streetnumber?: string;
  subdivisionname?: string;
  unitnumber?: string;
  View?: string[];
  virtualtoururlunbranded?: string;
  waterbodyname?: string[];
  yearbuilt?: number;
  createdAt: string;
  updatedAt: string;
  media: CarouselImageItem[];
  showOnAgentBIO?: boolean;
  publicDate?: string;
}

export const isDraft = (listing: any): listing is DraftListing =>
  not(listing?.sourcelistingid);

export const isPublishedListing = (
  listing: DraftListing | ListingWithMedia
): listing is ListingWithMedia => not(isDraft(listing));

export interface DraftListingData {
  agentId?: string;
  bathroomsFull?: number;
  bathroomsHalf?: number;
  bedroomsTotal?: number;
  city?: string;
  closeDate?: string;
  closePrice?: string;
  communityFeatures?: string[];
  countyOrParish?: string;
  expirationDate?: string;
  exteriorFeatures?: string[];
  fencing?: string[];
  garageSpaces?: number;
  guestAccommodation?: boolean;
  hasAssociation?: boolean;
  hasFireplace?: boolean;
  isHorseAllowed?: boolean;
  isPoolPrivate?: boolean;
  latitude?: number;
  listPrice?: number;
  buildingAreaTotal?: number;
  livingArea?: number;
  longitude?: number;
  lotFeatures?: string[];
  lotSizeArea?: number;
  lotSizeSquareFeet?: number;
  masterMain?: boolean;
  media?: DraftListingImage[];
  mlsStatus: MlsStatus;
  mlsTargetDate?: string;
  postalCode?: string;
  primaryListingId?: string;
  privateRemarks?: string;
  propertySubtype?: string;
  propertyType?: string;
  publicRemarks?: string;
  relatedMlsSource?: MlsSource;
  rooms?: string[];
  showOnAgentBIO?: boolean;
  stateOrProvince?: string;
  stories?: number;
  streetName?: string;
  streetNumber?: string;
  subdivisionName?: string;
  unitNumber?: string;
  view?: string[];
  virtualtoururlunbranded?: string;
  virtualTourUrlUnbranded?: string;
  waterBodyName?: string[];
  waterfrontyn?: boolean;
  yearBuilt?: number;
  numberOfUnitsTotal?: string;
  publicDate?: string;
}

export type Media = DraftListingImage | CarouselImageItem;

export interface ListingWithMedia extends Listing {
  media?: CarouselImageItem[];
}

export interface DublicatedListingResponse {
  data: {
    payload: DuplicateListing;
  };
}

export type UpdateDraftListingData = OptionalToNullable<DraftListingData>;
