import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Agent } from 'agents/agentsTypes';
import {
  BuyBeforeYouSellAction,
  EstimateSelectedAddressAction,
  estimateSelectedAddressFail,
  estimateSelectedAddressSuccess,
  FetchHeroSectionImageAction,
  fetchHeroSectionImageSuccess,
  fetchHeroSectionNoImage,
  RequestConsultationAction,
  RequestHomePriceEstimateAction,
  SellActionType,
} from 'butter-cms/pages/sell/sellActions';
import { arrayBufferToBase64 } from 'butter-cms/pages/sell/sellHelpers';
import { SellService } from 'butter-cms/pages/sell/sellService';
import {
  EstimatedPricesState,
  StreetViewMetadata,
} from 'butter-cms/pages/sell/sellTypes';
import { TypesOfInquiryHistory } from 'history/inquiryHistory/inquiryHistoryTypes';
import { mapServerValidationErrors } from 'shared/helpers/errorHelpers';
import { showErrorToast, showSuccessToast } from 'shared/helpers/notifications';
import { getAgentByNameOrSfId } from 'shared/sagas/getAgentByNameOrSfId';
import {
  StreetViewMetadataResponseStatus,
  StreetViewService,
} from 'shared/services/GoogleApiService';
import { getInquiryTrackingInfo } from 'user/userHelpers';
import { waitForProfile } from 'user/userSagas';
import { UserService } from 'user/UserService';

export function* requestConsultationSaga(action: RequestConsultationAction) {
  try {
    const { data, onSuccess } = action.body;
    const agent: Agent = yield call(getAgentByNameOrSfId);
    yield call(UserService.inquiry, {
      ...getInquiryTrackingInfo(),
      ...data,
      type: TypesOfInquiryHistory.RequestConsultation,
      agentId: agent?.username,
    });

    onSuccess();
    showSuccessToast('Your inquiry was successfully submitted');
  } catch (error) {
    const { onError } = action.body;
    if (error?.validationErrors) {
      onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
  }

  action.body.cb();
}

export function* requestHomePriceEstimateSaga(
  action: RequestHomePriceEstimateAction
): Saga {
  try {
    const profile = yield call(waitForProfile);
    yield call(UserService.inquiry, {
      ...action.body,
      ...getInquiryTrackingInfo(),
      agentId: profile.agentId,
      email: profile.email,
      firstname: profile.firstname,
      lastname: profile.lastname,
      phone: profile.phone,
      type: TypesOfInquiryHistory.HomePriceEstimate,
    });
  } catch (error) {
    console.info('requestHomePriceEstimate', error);
  }
}

export function* buyBeforeYouSell(action: BuyBeforeYouSellAction) {
  try {
    const { data, onSuccess } = action.body;
    const agent: Agent = yield call(getAgentByNameOrSfId);

    yield call(UserService.inquiry, {
      ...getInquiryTrackingInfo(),
      ...data,
      type: TypesOfInquiryHistory.BuyBeforeYouSell,
      agentId: agent?.username,
    });

    onSuccess();
    showSuccessToast(
      'Request has been sent. Our agent will contact you shortly'
    );
  } catch (error) {
    const { onError } = action.body;
    if (error?.validationErrors) {
      onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
  }
}

export function* fetchSellHeroSectionImage(
  action: FetchHeroSectionImageAction
): Saga {
  const { latitude, longitude } = action;
  try {
    const imageMetaData: StreetViewMetadata = yield call(
      StreetViewService.fetchStreetViewMetadata,
      latitude,
      longitude
    );
    if (
      imageMetaData?.status === StreetViewMetadataResponseStatus.ZERO_RESULTS
    ) {
      yield put(fetchHeroSectionNoImage());
    }

    if (imageMetaData?.status === StreetViewMetadataResponseStatus.OK) {
      const response = yield call(
        StreetViewService.fetchStreetViewPicture,
        latitude,
        longitude
      );
      const buffer = yield response.arrayBuffer();
      const image = `data:image/jpeg;base64,${arrayBufferToBase64(buffer)}`;
      yield put(fetchHeroSectionImageSuccess(image));
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* estimateSelectedAddressSaga(
  action: EstimateSelectedAddressAction
) {
  try {
    const prices: EstimatedPricesState = yield call(
      SellService.estimateSelectedAddress,
      action.address,
      action.zip
    );
    yield put(estimateSelectedAddressSuccess(prices));
  } catch (error) {
    yield put(estimateSelectedAddressFail(error));
  }
}

const requestConsultationSub = () =>
  takeLatest(SellActionType.REQUEST_CONSULTATION, requestConsultationSaga);

const requestHomePriceEstimateSub = () =>
  takeLatest(
    SellActionType.REQUEST_HOME_PRICE_ESTIMATE,
    requestHomePriceEstimateSaga
  );

const fetchSellHeroSectionImageSub = () =>
  takeLatest(
    SellActionType.FETCH_SELL_HERO_SECTION_IMAGE,
    fetchSellHeroSectionImage
  );

const estimateSelectedAddressSub = () =>
  takeLatest(
    SellActionType.ESTIMATE_SELECTED_ADDRESS,
    estimateSelectedAddressSaga
  );

export function* sellSagas() {
  yield all([
    requestConsultationSub(),
    requestHomePriceEstimateSub(),
    takeLatest(SellActionType.BUY_BEFORE_YOU_SELL, buyBeforeYouSell),
    fetchSellHeroSectionImageSub(),
    estimateSelectedAddressSub(),
  ]);
}
