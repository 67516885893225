import { GetHintsQueryParams, Hint, RecentSearch } from 'search/search-types';
import { ApiService } from 'shared/services/ApiService';
import { Paginated } from 'shared/types';

export class SearchService {
  static async getHints(queryParams: GetHintsQueryParams) {
    return ApiService.request<Hint[]>({
      method: 'GET',
      path: `/places/search`,
      query: queryParams as Record<string, any>,
    });
  }

  static async getRecentSearches(params: { query?: Record<string, any> } = {}) {
    return ApiService.request<Paginated<RecentSearch>>({
      method: 'get',
      path: `/history/mysearch`,
      query: params.query ?? {},
    });
  }
}
