export const SELECT_ALL_LABEL = 'All';

export enum RaSelectActionType {
  DeselectOption = 'deselect-option',
  SelectOption = 'select-option',
  RemoveValue = 'remove-value',
}

export const selectAllOptionItem = {
  value: SELECT_ALL_LABEL,
  label: SELECT_ALL_LABEL,
};
