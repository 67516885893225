import { ApiService } from 'shared/services/ApiService';

export class SellService {
  static async estimateSelectedAddress(address: string, zip: string) {
    return ApiService.request({
      method: 'GET',
      path: `/home-price-estimates?address=${address}&zip=${zip}`,
    });
  }
}
