import styled from 'styled-components/macro';

import { scss } from 'styled-system/css';
import { createShouldForwardProp } from 'styled-system/lib/should-forward-prop';
import { space, SpaceProps } from 'styled-system/lib/space';
import { sx, SxProps } from 'styled-system/lib/sx';

export const inputPropNames = [...space.propNames, 'sx'];

export const inputConfig = {
  shouldForwardProp: createShouldForwardProp(inputPropNames),
};

export type InputProps = SpaceProps & SxProps;

export const Input = styled.input.withConfig<InputProps>(inputConfig)(
  scss({
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
    '&:focus': { outline: 'none' },
    '&::placeholder': { color: theme => theme.colors.border },
    '&[placeholder]': { textOverflow: 'ellipsis' },
  }),
  sx,
  space
);
