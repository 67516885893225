import React from 'react';
import {
  components,
  DropdownIndicatorProps,
  MultiValueProps,
  OptionProps,
  ValueContainerProps,
} from 'react-select';
import { DefaultTheme } from 'styled-components/macro';

import Checkbox from 'shared/components/Checkbox';
import { MultiSelectOption } from 'shared/components/MultiSelect/MultiSelect';
import { MultiSelectStyled } from 'shared/components/MultiSelect/MultiSelectStyled';
import { not } from 'shared/helpers/boolean';

const {
  StyledCancelIcon,
  StyledDropdownIndicator,
  StyledOptionContainer,
  StyledValueContainer,
} = MultiSelectStyled;

const MultiValueRemove = (props: MultiValueProps<MultiSelectOption>) => (
  <components.MultiValueRemove {...props}>
    <StyledCancelIcon />
  </components.MultiValueRemove>
);

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => (
  <components.DropdownIndicator {...props}>
    <StyledDropdownIndicator />
  </components.DropdownIndicator>
);

const ClearIndicator = (props: DropdownIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <StyledCancelIcon />
  </components.ClearIndicator>
);

const SelectCustomOption = (props: OptionProps<MultiSelectOption>) => (
  <div
    role="option"
    aria-selected={props.isSelected}
    id={props.isFocused ? 'focused-option' : ''}
  >
    <components.Option {...props} aria-hidden="true">
      <StyledOptionContainer>
        <Checkbox labelText={props.label} checked={props.isSelected} />
      </StyledOptionContainer>
    </components.Option>
  </div>
);

const Option = (props: OptionProps<MultiSelectOption>) =>
  props.isMulti ? (
    <SelectCustomOption {...props} />
  ) : (
    <components.Option {...props} />
  );

const ValueContainer = ({
  theme,
  ...restProps
}: ValueContainerProps<MultiSelectOption>) => {
  const {
    selectProps: { isMulti, options, name },
  } = restProps;

  const showSelectValuesLength =
    name === 'propertyFeaturesFilters.waterBodyName';
  const selectValues = restProps.getValue() as MultiSelectOption[];
  const allValuesAreSelected = selectValues.length === options.length;

  return (
    <StyledValueContainer
      {...restProps}
      theme={theme as unknown as DefaultTheme}
      hideExceptAllOption={
        isMulti && allValuesAreSelected && not(showSelectValuesLength)
      }
      tagsLength={selectValues.length}
    />
  );
};
const RaSelectCustomComponents = {
  MultiValueRemove,
  DropdownIndicator,
  ClearIndicator,
  Option,
  ValueContainer,
};
export default RaSelectCustomComponents;
