import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from 'shared/hooks/usePrevious';

interface ScrollToTopProps {
  children: JSX.Element;
}

const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const location = useLocation();
  const prevProps = usePrevious(location);

  useEffect(() => {
    if (
      // exception for listing details
      location.pathname.match(/\.html$/g) ||
      prevProps?.pathname.match(/\.html$/g)
    ) {
      return;
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

export default ScrollToTop;
