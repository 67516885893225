import { useState } from 'react';
import { components, DropdownIndicatorProps, OptionProps } from 'react-select';
import Creatable from 'react-select/creatable';
import { withTheme } from 'styled-components/macro';

import SelectInput from 'shared/components/Select/components/SelectInput';
import { customInputStyles } from 'shared/components/Select/SelectHelpers';
import {
  OptionContainer,
  SelectLabel,
  SelectWrapper,
} from 'shared/components/Select/SelectStyles';
import {
  InputSelectProps,
  SelectOption,
} from 'shared/components/Select/SelectTypes';
import { ButtonsCode } from 'shared/constants/appConstants';
import { not, notNil } from 'shared/helpers/boolean';
import { ReactComponent as IconSelect } from 'shared/icons/shared/16x16/select.svg';
import { ReactComponent as IconArrowDown } from 'shared/icons/shared/arrows/small-arrow-down.svg';
import globalTheme from 'shared/styles/globalTheme';
import { Themed } from 'shared/types';

export const Option = (props: OptionProps<SelectOption>) => {
  const { isSelected } = props;

  return (
    <OptionContainer
      role="option"
      aria-selected={isSelected}
      id={props.isFocused ? 'focused-option' : ''}
    >
      {isSelected && <IconSelect height="16px" fill={globalTheme.blue} />}
      <components.Option {...props} aria-hidden />
    </OptionContainer>
  );
};

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <IconArrowDown height="16px" />
      </components.DropdownIndicator>
    )
  );
};

const InputSelect = (props: InputSelectProps & Themed) => {
  const { value, formatter, valueFormatter, rawValue } = props;

  const selectId = props.ariaLabel || props.labelText;
  const [menuIsOpen, setMenuIsOpen] = useState(props?.menuIsOpen);

  const isValidNewOption = (inputValue: any) => {
    const notAsRaw = not(props.rawValue === inputValue);

    if (typeof inputValue === 'string' && not(inputValue.trim())) {
      return false;
    }

    return props.isValid ? props.isValid(inputValue) && notAsRaw : notAsRaw;
  };

  const onCreateOption = (value: string) => {
    const option = {
      value: valueFormatter ? valueFormatter(value) : value,
      label: `${formatter ? formatter(value) : value}`,
    };
    onChange({ ...option });
  };

  const onChange = (option: SelectOption) => {
    props.onChange(option);
  };

  const onBlur = (
    e: React.FocusEvent<HTMLElement>,
    selectOptions: SelectOption[]
  ) => {
    if (props.inputMode) {
      const { value: inputValue = '' } = (
        e as React.FocusEvent<HTMLInputElement>
      ).target;

      if (isValidNewOption(inputValue)) {
        const option = selectOptions.find(opt => opt.value === inputValue);

        onChange(option || { value: inputValue, label: inputValue.toString() });
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (
      (event.key === ButtonsCode.Enter || event.key === ButtonsCode.Space) &&
      not(menuIsOpen)
    ) {
      event.preventDefault();
      setMenuIsOpen(true);
    } else if (event.key === ButtonsCode.Esc && menuIsOpen) {
      event.stopPropagation();
      setMenuIsOpen(false);
    }
  };

  const selectOptions = [...props.options];
  let selectValue = value;

  if (
    notNil(rawValue) &&
    rawValue &&
    not(selectOptions.some(opt => opt.value === rawValue))
  ) {
    selectValue = {
      value: rawValue,
      label: formatter ? formatter(rawValue) : rawValue.toString(),
    };
    selectOptions.push(selectValue);
  }

  return (
    <SelectWrapper>
      {props.labelText && (
        <SelectLabel htmlFor={selectId} isDisabled={props.isDisabled}>
          {props.labelText}
        </SelectLabel>
      )}
      <Creatable
        {...props}
        onChange={onChange}
        onCreateOption={onCreateOption}
        isValidNewOption={isValidNewOption}
        onBlur={event => onBlur(event, selectOptions)}
        createOptionPosition="first"
        value={selectValue}
        menuIsOpen={menuIsOpen}
        onKeyDown={handleKeyDown}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        isOptionDisabled={option => option.disabled}
        inputId={selectId}
        components={{
          Option,
          DropdownIndicator: not(props.inputMode) ? DropdownIndicator : null,
          Input: SelectInput,
        }}
        options={selectOptions}
        styles={customInputStyles({ theme: props.theme })}
        formatCreateLabel={value => value}
        classNamePrefix="react-select"
        theme={theme => ({ ...theme, borderRadius: 0 })}
        filterOption={props.filterOptions}
        aria-label={props.ariaLabel}
        ariaLiveMessages={{
          guidance: () => '',
        }}
      />
    </SelectWrapper>
  );
};

export default withTheme(InputSelect);
