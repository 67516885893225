import { DeepReadonly } from 'utility-types';

import { FiltersState } from 'filters/filtersTypes';
import {
  SavedSearchAction,
  SavedSearchActionType,
} from 'saved-search/savedSearchActions';
import { searchesSortOptions } from 'saved-search/savedSearchConstants';
import {
  SavedSearchFilters,
  SavedSearchState,
} from 'saved-search/savedSearchTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';

export const SAVED_SEARCH_DEFAULT_FILTERS_STATE: SavedSearchFilters = {
  sortType: SortOrder.DESC,
  period: 0,
  page: 1,
  sortBy: searchesSortOptions[0].value,
};

export const SAVED_SEARCH_DEFAULT_STATE: SavedSearchState = {
  loading: false,
  total: 0,
  error: '',
  item: {
    loading: false,
    id: null,
    name: '',
    owner: null,
    notifications: null,
    filters: {} as FiltersState,
    tags: [],
    collaborators: [],
    agentNotified: false,
    error: '',
    updateTimeValue: '',
    description: '',
    query: '',
  },
  searches: [],
  savedSearchInvitations: [],
  savedSearchToRemove: [],
  viewedSearches: {
    loading: false,
    error: '',
    items: {
      hits: [],
      total: 0,
    },
  },
  limit: 35,
  user: '',
  ...SAVED_SEARCH_DEFAULT_FILTERS_STATE,
};

export function savedSearchReducer(
  state: DeepReadonly<SavedSearchState> = SAVED_SEARCH_DEFAULT_STATE,
  action: SavedSearchAction
): DeepReadonly<SavedSearchState> {
  switch (action.type) {
    case SavedSearchActionType.FETCH_SAVED_SEARCH:
      return {
        ...state,
        item: {
          ...state.item,
          loading: true,
          error: '',
        },
      };

    case SavedSearchActionType.FETCH_SAVED_SEARCH_FAIL:
      return {
        ...state,
        item: {
          ...state.item,
          loading: false,
          error: action.error,
        },
      };

    case SavedSearchActionType.SET_SAVED_SEARCH:
      return {
        ...state,
        item: {
          ...action.searchData,
          tags: action.searchData.tags.map(tag => ({
            ...tag,
            isChild: true,
            isTemporary: false,
          })),
          loading: false,
          error: '',
        },
      };

    case SavedSearchActionType.SHARE_SAVED_SEARCH:
      return {
        ...state,
        item: {
          ...state.item,
          collaborators: [...state.item.collaborators, ...action.collaborators],
        },
      };

    case SavedSearchActionType.UNSHARE_SAVED_SEARCH:
      return {
        ...state,
        item: {
          ...state.item,
          collaborators: state.item.collaborators.filter(
            ({ id }) => !action.collaboratorIds.includes(id)
          ),
        },
      };

    case SavedSearchActionType.RESET_SAVED_SEARCH:
      return {
        ...state,
        item: { ...SAVED_SEARCH_DEFAULT_STATE.item },
      };

    case SavedSearchActionType.FETCH_SAVED_SEARCHES:
      return {
        ...state,
        loading: not(action.silentUpdate),
        error: '',
        ...action.urlFilters,
      };

    case SavedSearchActionType.FETCH_SAVED_SEARCHES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SavedSearchActionType.SET_SAVED_SEARCHES:
      return {
        ...state,
        searches: action.searches,
        total: action.total,
        loading: false,
        error: '',
      };

    case SavedSearchActionType.REMOVE_SAVED_SEARCH_FROM_STORE:
      return {
        ...state,
        savedSearchToRemove: [...state.savedSearchToRemove, action.id],
      };

    case SavedSearchActionType.DECLINE_SAVED_SEARCH_SUCCESS:
    case SavedSearchActionType.REMOVE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        searches: state.searches.filter(({ id }) => id !== action.id),
        total: state.total - 1,
        savedSearchToRemove: state.savedSearchToRemove.filter(
          s => s !== action.id
        ),
      };

    case SavedSearchActionType.UNDO_REMOVE_SAVED_SEARCH:
      return {
        ...state,
        savedSearchToRemove: state.savedSearchToRemove.filter(
          s => s !== action.id
        ),
      };

    case SavedSearchActionType.UPDATE_SAVED_SEARCH:
      return {
        ...state,
        searches: state.searches.map(search =>
          search.id === action.savedSearchData.id
            ? {
                ...search,
                ...action.savedSearchData,
              }
            : search
        ),
      };

    case SavedSearchActionType.SORT_SAVED_SEARCHES:
      return {
        ...state,
        sortBy: action.value,
      };

    case SavedSearchActionType.RESET_SAVED_SEARCHES:
    case SavedSearchActionType.RESET_VIEWED_SEARCHES:
      return {
        ...state,
        ...SAVED_SEARCH_DEFAULT_STATE,
        savedSearchToRemove: state.savedSearchToRemove,
      };

    // Viewed searches

    case SavedSearchActionType.FETCH_VIEWED_SEARCHES:
      return {
        ...state,
        user: action.userId,
        ...action.urlFilters,
      };

    case SavedSearchActionType.FETCH_VIEWED_SEARCHES_REQUEST:
      return {
        ...state,
        viewedSearches: {
          ...state.viewedSearches,
          loading: true,
          error: '',
        },
      };

    case SavedSearchActionType.FETCH_VIEWED_SEARCHES_SUCCESS:
      return {
        ...state,
        viewedSearches: {
          items: action.viewedSearches,
          loading: false,
          error: '',
        },
      };

    case SavedSearchActionType.FETCH_VIEWED_SEARCHES_FAIL:
      return {
        ...state,
        viewedSearches: {
          ...state.viewedSearches,
          loading: false,
          error: action.error,
        },
      };

    case SavedSearchActionType.SORT_VIEWED_SEARCHES:
      return {
        ...state,
        sortType: action.sortType,
        page: 1,
      };

    case SavedSearchActionType.FILTER_VIEWED_SEARCHES_BY_PERIOD:
      return {
        ...state,
        period: action.period,
        page: 1,
      };

    case SavedSearchActionType.CHANGE_VIEWED_SEARCHES_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case SavedSearchActionType.UPDATE_SAVED_SEARCHES_INVITATIONS:
      return {
        ...state,
        savedSearchInvitations: action.invitations,
      };

    case SavedSearchActionType.CHANGE_SAVED_SEARCHES_PAGE:
      return {
        ...state,
        page: action.page,
      };

    default:
      return state;
  }
}
