import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EmailHistoryFilters,
  EmailHistoryItem,
  EmailHistorySortBy,
  EmailHistoryState,
  EmailHistoryStatusFilter,
} from 'history/emailHistory/emailHistoryTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { effect } from 'shared/helpers/redux';
import { Paginated } from 'shared/types';

export const EMAIL_HISTORY_FILTERS_DEFAULT_STATE = {
  page: 1,
  period: 0,
  status: EmailHistoryStatusFilter.All,
  sortType: SortOrder.DESC,
  sortBy: EmailHistorySortBy.DateTime,
};

export const EMAIL_HISTORY_DEFAULT_STATE: EmailHistoryState = {
  items: [],
  emailRawHtmls: {},
  sortType: SortOrder.DESC,
  sortBy: EmailHistorySortBy.DateTime,
  status: EmailHistoryStatusFilter.All,
  period: 0,
  loading: false,
  total: 0,
  page: 1,
  limit: 20,
  error: '',
};

const emailHistorySlice = createSlice({
  name: 'emailHistory',
  initialState: EMAIL_HISTORY_DEFAULT_STATE,
  reducers: {
    fetchEmailHistory: (
      state,
      action: PayloadAction<{
        urlFilters: Partial<EmailHistoryFilters>;
      }>
    ) => {
      Object.assign(state, { loading: true, ...action.payload.urlFilters });
    },
    fetchEmailHistorySuccess: (
      state,
      action: PayloadAction<{ emailHistoryItems: Paginated<EmailHistoryItem> }>
    ) => {
      state.items = action.payload.emailHistoryItems.hits;
      state.total = action.payload.emailHistoryItems.total;
      state.loading = false;
      state.error = '';
    },
    fetchEmailHistoryFailure: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    fetchEmailHistoryItem: effect<{ emailID: string }>(),
    fetchEmailHistoryItemSuccess: (
      state,
      action: PayloadAction<{ emailID: string; emailRawHtml: string }>
    ) => {
      state.emailRawHtmls[action.payload.emailID] = action.payload.emailRawHtml;
      state.error = '';
    },
    sortEmailHistory: (
      state,
      action: PayloadAction<{
        sortBy?: EmailHistorySortBy;
        sortType?: SortOrder;
        status?: EmailHistoryStatusFilter;
        period?: number;
      }>
    ) => {
      return {
        ...state,
        ...action.payload,
        loading: true,
        page: 1,
      };
    },
    changePageOnEmailHistory: (
      state,
      action: PayloadAction<{
        page: number;
      }>
    ) => {
      state.page = action.payload.page;
    },
    resetEmailHistory: () => {
      return EMAIL_HISTORY_DEFAULT_STATE;
    },
  },
});

export const emailHistoryReducer = emailHistorySlice.reducer;

export const {
  fetchEmailHistory,
  fetchEmailHistorySuccess,
  fetchEmailHistoryFailure,
  sortEmailHistory,
  changePageOnEmailHistory,
  fetchEmailHistoryItem,
  fetchEmailHistoryItemSuccess,
  resetEmailHistory,
} = emailHistorySlice.actions;
