import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { media } from 'styled-system/responsive';

export const NearbyItemsWrapper = styled.div<{ divider?: boolean }>`
  padding: 8px 24px 24px 60px;
  display: flex;

  border-bottom: ${({ divider, theme }) =>
    divider ? `1px solid ${theme.divider}` : 'none'};

  ${media.sm`
      padding: 8px 18px 24px 24px;
      justify-content: space-around;
   `}
`;

export const NearbyTitle = styled.div`
  height: 48px;
  line-height: 48px;
  font-weight: bold;

  color: ${({ theme }) => theme.text2};
`;

export const NearbyLinkIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  background-color: ${({ theme }) => theme.uiAliceBlue};
  transition: all 0.3s;
`;

export const NearbyLinkText = styled.span`
  margin-left: 15px;
  font-weight: normal;
  color: ${({ theme }) => theme.text2};
`;

export const NearbyLink = styled(Link)`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 32px;
  }

  &:hover {
    ${NearbyLinkIcon} {
      background-color: ${({ theme }) => theme.blue};
      path {
        fill: #fff;
      }
    }
  }

  ${NearbyLinkText} {
    ${media.sm`
        margin: 8px 0 0 0;
        font-size: 14px;
     `}
  }

  ${media.sm`
    flex-direction: column;

    &:not(:first-child) {
       margin-left: 0;
    }
  `}
`;
