import { ViewedSearch } from 'saved-search/savedSearchTypes';
import { SearchDescriptionBuilder } from 'saved-search/services/SearchDescriptionBuilder';
import { SortOrder } from 'shared/constants/appConstants';
import { ApiService } from 'shared/services/ApiService';
import { Paginated } from 'shared/types';

export class HistoryService {
  static async fetchViewedSearches(query: {
    sort?: SortOrder;
    periodStart?: string;
    periodEnd?: string;
    user?: string;
    limit?: number;
    page?: number;
  }) {
    return await ApiService.request<Paginated<ViewedSearch>>({
      method: 'GET',
      path: `/history/search`,
      query,
    });
  }

  static async fetchViewedSearch(id: string, user: string) {
    const viewedSearch = await ApiService.request<ViewedSearch>({
      method: 'GET',
      path: `/history/search/${id}`,
      query: { user },
    });

    viewedSearch.description = SearchDescriptionBuilder.getFromSearchData(
      viewedSearch.searchData
    );

    return viewedSearch;
  }
}
