import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  InquiryHistoryAllFilters,
  InquiryHistoryAllOption,
  InquiryHistoryFiltersAndSorting,
  InquiryHistoryItem,
  InquiryHistorySortBy,
  InquiryHistoryState,
  TypesOfInquiryHistoryFilters,
} from 'history/inquiryHistory/inquiryHistoryTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { effect } from 'shared/helpers/redux';
import { Paginated } from 'shared/types';

export const INQUIRY_HISTORY_DEFAULT_FILTERS: InquiryHistoryAllFilters = {
  sortType: SortOrder.DESC,
  sortBy: InquiryHistorySortBy.DATETIME,
  inquiryType: InquiryHistoryAllOption.All,
  period: 0,
  page: 1,
};

export const INQUIRY_HISTORY_DEFAULT_STATE: InquiryHistoryState = {
  items: [],
  inquiryRawHtmls: {},
  limit: 20,
  total: 0,
  loading: false,
  error: '',
  ...INQUIRY_HISTORY_DEFAULT_FILTERS,
};

const inquiryHistorySlice = createSlice({
  name: 'inquiryHistory',
  initialState: INQUIRY_HISTORY_DEFAULT_STATE,
  reducers: {
    fetchInquiryHistory: (
      state,
      action: PayloadAction<{ urlFilters: Partial<InquiryHistoryAllFilters> }>
    ) => {
      Object.assign(state, { loading: true, ...action.payload.urlFilters });
    },
    saveInquiryHistory: (
      state,
      action: PayloadAction<{ inquiryHistoryItems: InquiryHistoryItem[] }>
    ) => {
      state.items = action.payload.inquiryHistoryItems;
    },
    fetchInquiryHistorySuccess: (
      state,
      action: PayloadAction<{
        inquiryHistoryItems: Paginated<InquiryHistoryItem>;
      }>
    ) => {
      state.items = action.payload.inquiryHistoryItems.hits;
      state.total = action.payload.inquiryHistoryItems.total;
      state.loading = false;
      state.error = '';
    },
    fetchInquiryHistoryFailure: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    fetchInquiryHistoryItem: effect<{ emailID: string }>(),
    fetchInquiryHistoryItemSuccess: (
      state,
      action: PayloadAction<{ emailID: string; inquiryRawHtml: string }>
    ) => {
      state.inquiryRawHtmls[action.payload.emailID] =
        action.payload.inquiryRawHtml;
      state.error = '';
    },
    sortInquiryHistory: (
      state,
      action: PayloadAction<{
        sortBy: string;
        sortType: SortOrder;
      }>
    ) => {
      state.sortType = action.payload.sortType;
      state.sortBy = action.payload.sortBy;
    },
    filterByType: (
      state,
      action: PayloadAction<{ inquiryType: TypesOfInquiryHistoryFilters }>
    ) => {
      state.inquiryType = action.payload.inquiryType;
      state.loading = true;
      state.page = 1;
    },
    changeInquiryHistoryPage: (
      state,
      action: PayloadAction<{ page: number }>
    ) => {
      state.page = action.payload.page;
    },
    filterByPeriod: (state, action: PayloadAction<{ period: number }>) => {
      state.period = action.payload.period;
      state.loading = true;
      state.page = 1;
    },
    applyFiltersAndSortings: (
      state,
      action: PayloadAction<InquiryHistoryFiltersAndSorting>
    ) => {
      state.period = action.payload.period;
      state.inquiryType = action.payload.inquiryType;
      state.sortType = action.payload.sortType;
      state.sortBy = action.payload.sortBy;
      state.page = 1;
    },
    resetInquiryFilters: state => {
      Object.assign(state, INQUIRY_HISTORY_DEFAULT_STATE, {
        loading: true,
      });
    },
    resetInquiryHistory: () => INQUIRY_HISTORY_DEFAULT_STATE,
  },
});

export const inquiryHistoryReducer = inquiryHistorySlice.reducer;

export const {
  fetchInquiryHistory,
  fetchInquiryHistorySuccess,
  fetchInquiryHistoryFailure,
  sortInquiryHistory,
  filterByType,
  changeInquiryHistoryPage,
  filterByPeriod,
  applyFiltersAndSortings,
  fetchInquiryHistoryItem,
  fetchInquiryHistoryItemSuccess,
  resetInquiryFilters,
  resetInquiryHistory,
} = inquiryHistorySlice.actions;
