import React, { useEffect, useState } from 'react';

import { PermissionRoles } from 'permissions/permissionsConstants';
import { getLoginStatus } from 'permissions/permissionsSelectors';
import FullScreenLoader from 'shared/components/loaders/FullScreenLoader';
import { RoutePath } from 'shared/constants/routesConstants';
import { useSelector } from 'shared/helpers/redux';
import history from 'shared/services/history';

type LeaseAccessResolverProps = {
  children: JSX.Element;
};

const LeaseAccessResolver = (props: LeaseAccessResolverProps) => {
  const isLeasedSearchAllowed = true;
  const [loading, showLoader] = useState(true);

  const loginStatus = useSelector(getLoginStatus);
  useEffect(() => {
    if (loginStatus !== PermissionRoles.NotInitialized) {
      if (isLeasedSearchAllowed) {
        showLoader(false);
      } else {
        history.replace(RoutePath.FORBIDDEN, {
          fromLeaseError: true,
        });
      }
    }
  }, [loginStatus, isLeasedSearchAllowed]);

  return loading ? <FullScreenLoader /> : props.children;
};

export default LeaseAccessResolver;
