import { UserAccountType } from 'agents/agentsConstants';
import { Agent } from 'agents/agentsTypes';
import { TypesOfInquiryHistory } from 'history/inquiryHistory/inquiryHistoryTypes';
import { CognitoFederatedProviders, UserHomeAddress } from 'shared/types';
import { FormErrors } from 'shared/types/errorTypes';

export enum UserRole {
  Agent = 'agent',
  Client = 'client',
}

export enum UserProfileStatus {
  Initial = 'Initial',
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut',
  LoggingIn = 'LoggingIn',
  LoggingOut = 'LoggingOut',
}

export interface User {
  agentId?: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  username: string;
}

export interface UserParams {
  code?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface CreateProfileBody {
  data: Partial<UserProfile>;
  onError: (errors: FormErrors) => void;
  onSuccess: () => void;
}

export interface UpdateProfileBody {
  data: Partial<UserProfile>;
  onError?: (errors?: FormErrors) => void;
  onSuccess?: () => void;
}

export interface RelocationData {
  city: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  message: string;
  state: string;
  streetAddress: string;
  zipCode: string;
}

export interface RequestRelocationGuideBody {
  data: RelocationData;
  onError: (errors: FormErrors) => void;
  onSuccess: () => void;
  cb: () => void;
}

export enum InterestedInValues {
  selling = 'Selling',
  buying = 'Buying',
  leasing = 'Leasing',
}

// leadSourceDetail is URL user has landed on site
// originalSource is referring domain
export interface UserProfile extends User {
  agentId?: string;
  city?: string;
  emailVerified?: boolean;
  facebookAccount?: string;
  googleAccount?: string;
  hasOptedOutOfEmail?: boolean;
  headshotUrlSquare?: string;
  headshotUrlTransparent?: string;
  isLeasedSearchAllowed?: boolean;
  leadSourceDetail?: string;
  marketingEmailUnsubscribe?: boolean;
  originalSource?: string;
  password?: string;
  raWeeklyEmailUnsubscribe?: boolean;
  role?: UserRole;
  showPromptSaveListing?: boolean;
  showPromptSaveSearch?: boolean;
  state?: string;
  streetAddress?: string;
  submittedPage?: string;
  url?: string;
  userAccountType?: UserAccountType;
  zipCode?: string;
  interestedIn?: InterestedInValues[];
  homeAddress?: UserHomeAddress;
  userSocialPhoto_URL?: string;
  sfId?: string;
  teamNameLookup?: string;
}

export interface UserProfileFederated {
  firstName?: string;
  lastName?: string;
  provider?: CognitoFederatedProviders;
  SocialProfilePhotoURL?: string;
}

export interface FavoritePlace {
  id?: number;
  latitude: string;
  longitude: string;
  ownerId?: string;
  placeName: string;
  placeAddress?: string;
}

export interface FavouritePlaceWithDistance extends FavoritePlace {
  distance?: string;
  duration?: string;
}

export interface UserStateProfile {
  data: UserProfile | null;
  loading: boolean;
}

export interface UserLocation {
  latitude: number;
  longitude: number;
}

export interface UserState {
  agent: Agent;
  code?: string;
  email: string;
  error?: string;
  forgotPasswordEmailSent?: boolean;
  initialized: boolean;
  isLogged: boolean;
  isMobile: boolean;
  loading: boolean;
  password?: string;
  profile: UserStateProfile;
  profileFederated: UserProfileFederated;
  recommendedAgent: string;
  registrationHasCompleted?: boolean;
  resetPasswordCompleted?: boolean;
  role: UserRole | '';
  setPasswordCompleted?: boolean;
  status: UserProfileStatus;
  temporaryPassword?: string;
  userLocation: UserLocation;
  defaultAgent: Agent | null;
  notificationHistoryCount: number;
  savedSearchInvitationsCount: number;
  collectionsInvitationsCount: number;
  SocialPhotoURL: string;
}

export interface SendVerificationEmailPayload {
  landingPage: string;
  originalSource: string;
  submittedPage: string;
  code?: string;
}

export interface NotificationCountsPayload {
  notificationHistoryCount?: number;
  savedSearchInvitationsCount?: number;
  collectionsInvitationsCount?: number;
}

interface BaseInquiryPayload {
  agentId?: string;
  city?: string;
  email: string;
  firstname: string;
  landingPage: string | null;
  lastname: string;
  listingId?: string;
  message?: string;
  originalSource: string | null;
  phone: string;
  state?: string;
  streetAddress?: string;
  submittedPage: string;
  tourDateTime?: string;
  zipCode?: string;
}

export type AskQuestionPayload = BaseInquiryPayload & {
  agentId: string;
  type: TypesOfInquiryHistory.AskAQuestion;
};

export type BioInquiryPayload = BaseInquiryPayload & {
  agentId: string;
  type: TypesOfInquiryHistory.BioInquiry;
};

export type ListingQuestionPayload = BaseInquiryPayload & {
  listingId: string;
  type: TypesOfInquiryHistory.ListingQuestion;
};

export type RelocationGuidePayload = Omit<BaseInquiryPayload, 'message'> & {
  city: string;
  message?: string;
  state: string;
  streetAddress: string;
  type: TypesOfInquiryHistory.RelocationGuide;
  zipCode: string;
};

export type ScheduleTourPayload = BaseInquiryPayload & {
  listingId: string;
  message?: string;
  tourDateTime: string;
  type:
    | TypesOfInquiryHistory.ScheduleATour
    | TypesOfInquiryHistory.ScheduleAVideoTour;
};

export type HomePriceEstimatePayload = BaseInquiryPayload & {
  longitude: string;
  latitude: string;
  type: TypesOfInquiryHistory.HomePriceEstimate;
};
export type FeedbackInquiryPayload = Partial<BaseInquiryPayload> & {
  rating: string;
  screenResolution: string;
  feedback?: string;
  type: TypesOfInquiryHistory.LeftWebsiteFeedback;
};

interface FreeWhitepaperData {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}

export interface RequestFreeWhitepaperBody {
  data: FreeWhitepaperData;
  onError: (errors: FormErrors) => void;
  onSuccess: () => void;
  cb: () => void;
}

export interface GetSocialPhotoURLPayload {
  SocialPhotoURL: string;
}
export interface SetSocialPhotoURLPayload {
  SocialPhotoURL: string;
}
export interface RemoveSocialPhotoURLPayload {}

export type InquiryPayload =
  | ({ type: TypesOfInquiryHistory.AskAQuestion } & AskQuestionPayload)
  | ({ type: TypesOfInquiryHistory.BioInquiry } & BioInquiryPayload)
  | ({ type: TypesOfInquiryHistory.BuyBeforeYouSell } & BaseInquiryPayload)
  | ({ type: TypesOfInquiryHistory.ContactUs } & BaseInquiryPayload)
  | ({ type: TypesOfInquiryHistory.RequestAnInterview } & BaseInquiryPayload)
  | ({ type: TypesOfInquiryHistory.ListingQuestion } & ListingQuestionPayload)
  | ({ type: TypesOfInquiryHistory.RelocationGuide } & RelocationGuidePayload)
  | ({ type: TypesOfInquiryHistory.RequestASpecialist } & BaseInquiryPayload)
  | ({ type: TypesOfInquiryHistory.RequestConsultation } & BaseInquiryPayload)
  | ({
      type: TypesOfInquiryHistory.HomePriceEstimate;
    } & HomePriceEstimatePayload)
  | ({ type: TypesOfInquiryHistory.RequestFreeWhitepaper } & BaseInquiryPayload)
  | ({
      type: TypesOfInquiryHistory.LeftWebsiteFeedback;
    } & FeedbackInquiryPayload)
  | ({ type: TypesOfInquiryHistory.ReferMe } & BaseInquiryPayload)
  | ({ type: TypesOfInquiryHistory.ReviewMe } & Partial<BaseInquiryPayload>)
  | ScheduleTourPayload;

export enum STATUS {
  Pending = 'pending',
  Joined = 'joined',
  Declined = 'declined',
}

export interface Collaborator {
  status: STATUS;
  username: string;
  email: string;
  invitedBy: string;
  favoritesId: number;
  id: string;
  createdAt: string;
  inviteCode?: string;
  user: UserProfile;
}
