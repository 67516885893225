import { FilterKey } from 'filters/filtersContants';
import { FILTERS_DEFAULT_STATE } from 'filters/filtersReducer';
import { FiltersState } from 'filters/filtersTypes';
import { SearchData } from 'saved-search/savedSearchTypes';
import { Tag } from 'search/search-types';
import { isNumeric } from 'shared/helpers/mainHelpers';
import { resolvePlace } from 'shared/helpers/resolvePlace';
import { toPolygonTag } from 'shared/helpers/toPolygonTag';
import { PlaceType, Polygon } from 'shared/types/placesAndPolygons';

export class SearchDataParser {
  static parse(searchData: SearchData) {
    const filters = this.getFilters(searchData);
    const tags = this.getTags(searchData);

    return { filters, tags };
  }

  private static getFilters(data: SearchData): Partial<FiltersState> {
    const filters: Partial<FiltersState> = {};
    const mlsStatus: string[] = [];

    data.filter(isFilter).forEach(item => {
      if (item.key === FilterKey.MlsStatus) {
        mlsStatus.push(item.value);
      } else {
        Object.assign(filters, {
          [item.key]: this.wrapInArrayIfNeeded(
            filters,
            item.key,
            this.convertFilterValue(item.value)
          ),
        });
      }
    });

    if (mlsStatus.length > 0) {
      filters.mlsStatus = mlsStatus.filter(Boolean);
    }

    return filters;
  }

  private static wrapInArrayIfNeeded(
    data: { [key: string]: unknown | unknown[] },
    key: string,
    value: unknown
  ) {
    if (data[key]) {
      return Array.isArray(data[key])
        ? [...(data[key] as unknown[]), value]
        : [data[key], value];
    }
    return value;
  }

  private static convertFilterValue(value: string) {
    if (value === 'true') return true;
    if (value === 'false') return false;
    if (isNumeric(value)) return Number(value);
    return value;
  }

  private static getTags(data: SearchData) {
    const tags: Tag[] = [];

    data.forEach(item => {
      if (item.key === 'polygon' && item.value) {
        const place = resolvePlace({
          type: toPolygonTag(item.value.type),
          value: item.value,
        });

        tags.push(place);
      }

      if (
        (item.key === PlaceType.Address || item.key === PlaceType.Street) &&
        item.value
      ) {
        tags.push({
          type: item.key,
          label: item.value,
          value: item.value,
        });
      }
    });

    return tags;
  }
}

function isFilter(item: {
  key: string;
  value: string | Polygon;
}): item is { key: keyof FiltersState; value: string } {
  const filtersKeys = Object.keys(FILTERS_DEFAULT_STATE);
  return filtersKeys.includes(item.key);
}
