import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getContactItem } from 'contacts/contactsSelectors';
import { getPeriodStartAndEnd } from 'filters/filtersHelpers';
import { InquiryHistoryService } from 'history/inquiryHistory/inquiryHistoryService';
import {
  applyFiltersAndSortings,
  changeInquiryHistoryPage,
  fetchInquiryHistory,
  fetchInquiryHistoryFailure,
  fetchInquiryHistoryItem,
  fetchInquiryHistoryItemSuccess,
  fetchInquiryHistorySuccess,
  filterByPeriod,
  filterByType,
  INQUIRY_HISTORY_DEFAULT_FILTERS,
  resetInquiryFilters,
  sortInquiryHistory,
} from 'history/inquiryHistory/inquiryHistorySlice';
import {
  InquiryHistoryAllOption,
  InquiryHistoryQuery,
  InquiryHistoryState,
  TypesOfInquiryHistory,
  UnitedAskAQuestionBio,
} from 'history/inquiryHistory/inquiryHistoryTypes';
import { not } from 'shared/helpers/boolean';
import { callIfOnline } from 'shared/helpers/network';
import { showErrorToast } from 'shared/helpers/notifications';
import { composeUrl } from 'shared/helpers/url';
import history from 'shared/services/history';
import { UnpackReturnedPromise } from 'shared/types';

export function* fetchInquiryHistorySagas(
  action:
    | ReturnType<typeof fetchInquiryHistory>
    | ReturnType<typeof sortInquiryHistory>
    | ReturnType<typeof filterByType>
    | ReturnType<typeof changeInquiryHistoryPage>
    | ReturnType<typeof filterByPeriod>
): Saga {
  try {
    const contactItem = yield select(getContactItem);
    const { page, sortType, period, inquiryType, sortBy }: InquiryHistoryState =
      yield select(({ history }) => history.inquiryHistory);

    if (not(action.type === fetchInquiryHistory.type)) {
      const query = composeUrl({
        defaultFilters: INQUIRY_HISTORY_DEFAULT_FILTERS,
        actualFilters: { period, page, inquiryType, sortBy, sortType },
      });

      history.push({ search: query, state: { forceUrlUpdate: true } });
    }

    const filters: InquiryHistoryQuery = {
      page,
      sort: sortType,
      username: contactItem.username,
      ...getPeriodStartAndEnd(period),
    };

    if (inquiryType !== InquiryHistoryAllOption.All) {
      filters.inquiryType = inquiryType;
    }
    if (inquiryType === TypesOfInquiryHistory.AskAQuestion) {
      filters.inquiryType = UnitedAskAQuestionBio.AskAQuestion;
    }

    const inquiryHistory: UnpackReturnedPromise<
      typeof InquiryHistoryService.getInquiryHistory
    > = yield call(InquiryHistoryService.getInquiryHistory, filters);
    yield put(
      fetchInquiryHistorySuccess({
        inquiryHistoryItems: inquiryHistory,
      })
    );
  } catch (e) {
    yield put(fetchInquiryHistoryFailure(e.message));
    callIfOnline(() => showErrorToast(e.message));
  }
}

export function* fetchInquiryHistoryItemSaga(
  action: ReturnType<typeof fetchInquiryHistoryItem>
): Saga {
  try {
    const contactItem = yield select(getContactItem);
    const oneInquiryHistoryItem: UnpackReturnedPromise<
      typeof InquiryHistoryService.getInquiryHistoryItem
    > = yield call(
      InquiryHistoryService.getInquiryHistoryItem,
      {
        username: contactItem.username,
      },
      action.payload.emailID
    );
    yield put(
      fetchInquiryHistoryItemSuccess({
        emailID: action.payload.emailID,
        inquiryRawHtml: oneInquiryHistoryItem,
      })
    );
  } catch (e) {
    yield put(fetchInquiryHistoryFailure({ error: e.message }));
    showErrorToast(e.message);
  }
}

export const getInquiryHistorySub = () =>
  takeLatest(
    [
      fetchInquiryHistory,
      filterByPeriod,
      filterByType,
      sortInquiryHistory,
      changeInquiryHistoryPage,
      applyFiltersAndSortings,
      resetInquiryFilters,
    ],
    fetchInquiryHistorySagas
  );
export const getOneInquiryHistorySub = () =>
  takeLatest([fetchInquiryHistoryItem], fetchInquiryHistoryItemSaga);

export function* inquiryHistorySagas() {
  yield all([getInquiryHistorySub(), getOneInquiryHistorySub()]);
}
