import React from 'react';

import { DropdownOptionIcon } from 'shared/components/SearchBar/components/SearchBarOptionIcon/SearchBarOptionIconStyled';

export interface SearchBarOptionIconProps {
  optionIcon?: React.ReactNode;
}

const SearchBarOptionIcon: React.FC<SearchBarOptionIconProps> = (
  props: SearchBarOptionIconProps
) => {
  const { optionIcon } = props;

  if (Boolean(optionIcon)) {
    return <DropdownOptionIcon>{optionIcon}</DropdownOptionIcon>;
  }
};

export default SearchBarOptionIcon;
