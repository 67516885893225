import { useField, useFormikContext } from 'formik';
import { useState } from 'react';
import styled from 'styled-components/macro';

import {
  bodyOfWaterOptions,
  FILTER_CONTAINER_WIDTH,
  viewsOptions,
} from 'filters/filtersContants';
import { $getPropertyFeaturesChanged } from 'filters/filtersSelectors';
import {
  CheckboxWrapper as CheckboxWrapperBase,
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  FilterShowMore,
  FiltersRadioContainer,
  FiltersRadioTitle,
  MultipleInputWrapper,
} from 'filters/filtersStyled';
import {
  RadioButtonSettings,
  SecondaryFiltersForm,
  SharedFiltersProps,
} from 'filters/filtersTypes';
import Checkbox from 'shared/components/Checkbox';
import { CheckboxField } from 'shared/components/formikFields/CheckboxField';
import { MultiSelectField } from 'shared/components/formikFields/MultiSelectField';
import { RadioGroupItem } from 'shared/components/formikFields/RadioField';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';

const Container = styled.div`
  width: ${FILTER_CONTAINER_WIDTH}px;
  max-width: 100%;
`;

const CheckboxWrapper = styled(CheckboxWrapperBase)`
  vertical-align: top;
`;

const swimmingPoolRadio: RadioButtonSettings[] = [
  { title: 'Any', value: '' },
  { title: 'Yes', value: 'true' },
  { title: 'No', value: 'false' },
];

const selectedTypeOfViewsValues = (views: string[] = []) =>
  viewsOptions.filter(({ value }) => views.includes(value));

type PropertyFeaturesFilterProps = Pick<SharedFiltersProps, 'disableFilter'> & {
  scrollableNode: HTMLDivElement | null;
};

export const PropertyFeaturesFilter = (props: PropertyFeaturesFilterProps) => {
  const { setFieldValue } = useFormikContext<SecondaryFiltersForm>();
  const [field] = useField({ name: 'propertyFeaturesFilters' });
  const { views, waterfront } = field.value;

  const propertyFeaturesChanged = useSelector($getPropertyFeaturesChanged);

  const [typeOfViewsEnabled, setTypeOfViewsEnabled] = useState(() => {
    return Boolean(selectedTypeOfViewsValues(views).length);
  });

  const selectViews = (values: typeof viewsOptions) => {
    const views = values.map(({ value }) => value);
    setFieldValue('propertyFeaturesFilters.views', views);
  };

  const toggleViewsFilter = () => {
    const newValue = not(typeOfViewsEnabled);
    setTypeOfViewsEnabled(newValue);
    selectViews(newValue ? viewsOptions : []);
  };

  const toggleWaterfrontsFilter = () => {
    setFieldValue('propertyFeaturesFilters.waterBodyName', []);
  };

  return (
    <Container>
      <FilterHeaderContainer>
        {propertyFeaturesChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Property Features
        </FilterHeaderTitle>
      </FilterHeaderContainer>
      <FiltersRadioContainer
        aria-labelledby="swimming-pool"
        name="propertyFeaturesFilters.swimmingPool"
      >
        <FiltersRadioTitle id="swimming-pool">Swimming Pool</FiltersRadioTitle>
        {swimmingPoolRadio.map(({ value, title }) => (
          <RadioGroupItem
            aria-labelledby="swimming-pool"
            disabled={props.disableFilter}
            key={value}
            label={title}
            value={value}
          />
        ))}
      </FiltersRadioContainer>
      <CheckboxWrapper>
        <CheckboxField
          name="propertyFeaturesFilters.fencedYard"
          disabled={props.disableFilter}
          labelText="Fenced Yard"
        />
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          name="propertyFeaturesFilters.backsToGolfCourse"
          disabled={props.disableFilter}
          labelText="Backs To Golf Course"
        />
      </CheckboxWrapper>
      <FilterShowMore
        lines={0}
        truncateChildren
        scrollableNode={props.scrollableNode}
        more="Show all Property Features"
        less="Show less Property Features"
      >
        <CheckboxWrapper>
          <CheckboxField
            name="propertyFeaturesFilters.backsToGreenbelt"
            disabled={props.disableFilter}
            labelText="Backs To Greenbelt"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="propertyFeaturesFilters.privateDock"
            disabled={props.disableFilter}
            labelText="Private Dock"
          />
        </CheckboxWrapper>
        <MultipleInputWrapper>
          <Checkbox
            disabled={props.disableFilter}
            labelText="Type of Views"
            checked={typeOfViewsEnabled}
            onChange={toggleViewsFilter}
            marginBottom={1}
          />
          <MultiSelectField
            name="propertyFeaturesFilters.views"
            expandOnEnabled
            selectAllOption
            placeholder="Select Type of Views"
            isDisabled={props.disableFilter || !typeOfViewsEnabled}
            options={viewsOptions}
          />
        </MultipleInputWrapper>

        <MultipleInputWrapper>
          <CheckboxField
            name="propertyFeaturesFilters.waterfront"
            disabled={props.disableFilter}
            labelText="Waterfront"
            onChange={toggleWaterfrontsFilter}
            marginBottom={1}
          />
          <MultiSelectField
            name="propertyFeaturesFilters.waterBodyName"
            placeholder="Specify Body Of Water"
            isDisabled={props.disableFilter || !waterfront}
            options={bodyOfWaterOptions}
          />
        </MultipleInputWrapper>
      </FilterShowMore>
    </Container>
  );
};
