import { US_CODE } from 'shared/constants/appConstants';
import { ERROR_MESSAGES } from 'shared/constants/errorConstants';
import { first } from 'shared/helpers/array';
import { isEmpty, not, notEmpty } from 'shared/helpers/boolean';
import { normalizePhone } from 'shared/helpers/formatters';

export const DEFAULT_MAX_LENGTH = 256;

export const lettersOnlyWithSpaceAndDashReg = /^[a-záàâãéèêíïóôõöúçñ\-\s]+$/i;

export const numbersOnlyWithCommaAndDotReG = (value: string) => {
  return value.replace(/[^\d.,]/g, '').replace(
    /\.|,/g,
    (
      i => (m: string) =>
        not(i++) ? m : ''
    )(0)
  );
};

export const isValidPassword = (value: string) =>
  not(value) || /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value);

export const isLettersOnlyWithSpaceAndDash = (value: string) =>
  not(value) || lettersOnlyWithSpaceAndDashReg.test(value);

export const isValidAlpha = (value: string) =>
  not(value) || /[A-Z]+/i.test(value);

export const isNotEmpty = (value: string) =>
  typeof value === 'string' ? notEmpty(value.trim()) : Boolean(value);

export const isValidStringLength = ({
  value,
  minLength,
  maxLength,
}: {
  value: string;
  minLength: number;
  maxLength: number;
}): boolean => value.length >= minLength && value.length <= maxLength;

export const isEmail = (value: string): boolean =>
  /^[\w-\.]+@([\w-]+\.)+[A-Z]{2,8}$/i.test(value);

export const isUSAPhone = (value: string): boolean => {
  return /^\+1\d{10}$/.test(value);
};

export const isInternationalPhone = (value: string): boolean =>
  /^\+(?:[0-9] ?){6,14}[0-9]$/.test(value);

export const isLettersOnly = (value: string): boolean =>
  /^[A-Z\s-]+$/i.test(value);

export const isNumeric = (value: string) => /^[0-9]+$/g.test(value);

const validators = {
  required: (value: string) =>
    isNotEmpty(value) ? null : ERROR_MESSAGES.REQUIRED,
  email: (value: string) => (isEmail(value) ? null : ERROR_MESSAGES.EMAIL),
  internationalPhone: (value: string) =>
    isInternationalPhone(value) ? null : ERROR_MESSAGES.PHONE,
  USAPhone: (value: string) =>
    isUSAPhone(value) ? null : ERROR_MESSAGES.PHONE,
  max: (value: string) =>
    isValidStringLength({ value, minLength: 0, maxLength: DEFAULT_MAX_LENGTH })
      ? null
      : `Please enter less than ${DEFAULT_MAX_LENGTH} characters`,
  maxCustom:
    (length: number) =>
    (value = '') =>
      isEmpty(value) ||
      isValidStringLength({ value, minLength: 0, maxLength: length })
        ? null
        : `Please enter less than ${length} characters`,
  number: (value: string) =>
    isEmpty(value) || isNumeric(value) ? null : 'Should include only numbers',
  alpha: (value: string) =>
    isValidAlpha(value) ? null : 'Should include letters',
  lettersOnly: (value: string) =>
    isEmpty(value) || isLettersOnly(value) ? null : ERROR_MESSAGES.ONLY_LETTERS,
  lettersOnlyWithSpaceAndDash: (value: string) =>
    isLettersOnlyWithSpaceAndDash(value) ? null : ERROR_MESSAGES.ONLY_LETTERS,
  password: (value: string) =>
    isValidPassword(value) ? null : 'Please enter stronger password',
};

export const getPhoneValidator = (value: string) => {
  return first(value.split(' ')) === US_CODE
    ? validators.USAPhone(normalizePhone(value))
    : validators.internationalPhone(normalizePhone(value));
};

export default validators;
