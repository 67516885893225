import { createSelector } from 'reselect';

import { getAgentMode } from 'contacts/contactsSelectors';
import { MlsStatus } from 'filters/filtersContants';
import {
  changedSelector,
  getFiltersSectionChanged,
} from 'filters/filtersHelpers';
import {
  DEFAULT_MLS_STATUSES,
  getDefaultFilters,
  PROPERTY_TYPE_DEFAULT_STATE,
} from 'filters/filtersReducer';
import { FiltersState, PropertyTypeFilters } from 'filters/filtersTypes';
import { RootState } from 'rootReducer';
import { getIsLeaseSavedSearch } from 'saved-search/savedSearchSelectors';
import { not, notEqual } from 'shared/helpers/boolean';
import { isLeasePage } from 'shared/helpers/isLeasePage';
import history from 'shared/services/history';
import { getIsAgent } from 'user/userSelectors';

export const $getPropertyTypeFilters = createSelector(
  (state: RootState) => state.filters.singleFamilyResidence,
  (state: RootState) => state.filters.condominium,
  (state: RootState) => state.filters.townhouse,
  (state: RootState) => state.filters.lot,
  (state: RootState) => state.filters.farm,
  (state: RootState) => state.filters.multiFamily,
  (state: RootState) => state.filters.commercialSale,
  (
    singleFamilyResidence,
    condominium,
    townhouse,
    lot,
    farm,
    multiFamily,
    commercialSale
    // eslint-disable-next-line max-params
  ) => ({
    singleFamilyResidence,
    condominium,
    townhouse,
    lot,
    farm,
    multiFamily,
    commercialSale,
  })
);

export const $getSizeFilters = createSelector(
  (state: RootState) => state.filters.minLotSize,
  (state: RootState) => state.filters.maxLotSize,
  (state: RootState) => state.filters.minSquareFeet,
  (state: RootState) => state.filters.maxSquareFeet,
  (state: RootState) => state.filters.minStories,
  (state: RootState) => state.filters.maxStories,
  (state: RootState) => state.filters.minGarageSpaces,
  (state: RootState) => state.filters.maxGarageSpaces,
  (
    minLotSize,
    maxLotSize,
    minSquareFeet,
    maxSquareFeet,
    minStories,
    maxStories,
    minGarageSpaces,
    maxGarageSpaces
    // eslint-disable-next-line max-params
  ) => ({
    minLotSize,
    maxLotSize,
    minSquareFeet,
    maxSquareFeet,
    minStories,
    maxStories,
    minGarageSpaces,
    maxGarageSpaces,
  })
);

const $homeFeaturesFiltersFirstChunk = createSelector(
  (state: RootState) => state.filters.minYearBuilt,
  (state: RootState) => state.filters.maxYearBuilt,
  (state: RootState) => state.filters.masterOnMain,
  (state: RootState) => state.filters.guestQuarters,
  (state: RootState) => state.filters.fireplace,
  (state: RootState) => state.filters.accessibilityFeatures,
  (state: RootState) => state.filters.historicDesign,
  (
    minYearBuilt,
    maxYearBuilt,
    masterOnMain,
    guestQuarters,
    fireplace,
    accessibilityFeatures,
    historicDesign
    // eslint-disable-next-line max-params
  ) => ({
    minYearBuilt,
    maxYearBuilt,
    masterOnMain,
    guestQuarters,
    fireplace,
    accessibilityFeatures,
    historicDesign,
  })
);

const $homeFeaturesFiltersSecondChunk = createSelector(
  (state: RootState) => state.filters.energyEfficient,
  (state: RootState) => state.filters.exerciseRoom,
  (state: RootState) => state.filters.gameRoom,
  (state: RootState) => state.filters.mediaRoom,
  (state: RootState) => state.filters.officeStudy,
  (state: RootState) => state.filters.wineRoom,
  (state: RootState) => state.filters.workshop,
  (state: RootState) => state.filters.petsAllowed,
  (
    energyEfficient,
    exerciseRoom,
    gameRoom,
    mediaRoom,
    officeStudy,
    wineRoom,
    workshop,
    petsAllowed
    // eslint-disable-next-line max-params
  ) => ({
    energyEfficient,
    exerciseRoom,
    gameRoom,
    mediaRoom,
    officeStudy,
    wineRoom,
    workshop,
    petsAllowed,
  })
);

export const $getHomeFeaturesFilters = createSelector(
  $homeFeaturesFiltersFirstChunk,
  $homeFeaturesFiltersSecondChunk,
  (firstChunk, secondChunk) => ({ ...firstChunk, ...secondChunk })
);

export const $getPropertyFeaturesFilters = createSelector(
  (state: RootState) => state.filters.views,
  (state: RootState) => state.filters.backsToGolfCourse,
  (state: RootState) => state.filters.backsToGreenbelt,
  (state: RootState) => state.filters.swimmingPool,
  (state: RootState) => state.filters.fencedYard,
  (state: RootState) => state.filters.privateDock,
  (state: RootState) => state.filters.waterfront,
  (state: RootState) => state.filters.waterBodyName,
  (
    views,
    backsToGolfCourse,
    backsToGreenbelt,
    swimmingPool,
    fencedYard,
    privateDock,
    waterfront,
    waterBodyName
    // eslint-disable-next-line max-params
  ) => ({
    views,
    backsToGolfCourse,
    backsToGreenbelt,
    swimmingPool,
    fencedYard,
    privateDock,
    waterfront,
    waterBodyName,
  })
);

const $getCommunityFeaturesFirstChunk = createSelector(
  (state: RootState) => state.filters.homeownersAssoc,
  (state: RootState) => state.filters.gatedCommunity,
  (state: RootState) => state.filters.communityPool,
  (state: RootState) => state.filters.playground,
  (state: RootState) => state.filters.dogPark,
  (state: RootState) => state.filters.fitnessCenter,
  (state: RootState) => state.filters.golfCourse,
  (state: RootState) => state.filters.sportCourt,
  (
    homeownersAssoc,
    gatedCommunity,
    communityPool,
    playground,
    dogPark,
    fitnessCenter,
    golfCourse,
    sportCourt
    // eslint-disable-next-line max-params
  ) => ({
    homeownersAssoc,
    gatedCommunity,
    communityPool,
    playground,
    dogPark,
    fitnessCenter,
    golfCourse,
    sportCourt,
  })
);

const $getCommunityFeaturesSecondChunk = createSelector(
  (state: RootState) => state.filters.tennisCourts,
  (state: RootState) => state.filters.horsesAllowed,
  (state: RootState) => state.filters.airplaneFacilities,
  (state: RootState) => state.filters.lakeAccess,
  (state: RootState) => state.filters.hikingTrails,
  (state: RootState) => state.filters.clubHouse,
  (state: RootState) => state.filters.boatDock,
  (state: RootState) => state.filters.park,
  (
    tennisCourts,
    horsesAllowed,
    airplaneFacilities,
    lakeAccess,
    hikingTrails,
    clubHouse,
    boatDock,
    park
    // eslint-disable-next-line max-params
  ) => ({
    tennisCourts,
    horsesAllowed,
    airplaneFacilities,
    lakeAccess,
    hikingTrails,
    clubHouse,
    boatDock,
    park,
  })
);

export const $getCommunityFeaturesFilters = createSelector(
  $getCommunityFeaturesFirstChunk,
  $getCommunityFeaturesSecondChunk,
  (firstChunk, secondChunk) => ({ ...firstChunk, ...secondChunk })
);

export const $getKeywordsFilters = createSelector(
  (state: RootState) => state.filters.keyword,
  keyword => ({ keyword })
);

export const $getGSRatingFilters = createSelector(
  (state: RootState) => state.filters.elementarySchoolRating,
  (state: RootState) => state.filters.middleSchoolRating,
  (state: RootState) => state.filters.highSchoolRating,
  (elementarySchoolRating, middleSchoolRating, highSchoolRating) => ({
    elementarySchoolRating,
    middleSchoolRating,
    highSchoolRating,
  })
);

export const $getPriceFilters = createSelector(
  (state: RootState) => state.filters.minPrice,
  (state: RootState) => state.filters.maxPrice,
  (minPrice, maxPrice) => ({ minPrice, maxPrice })
);

export const $getBathroomFilters = createSelector(
  (state: RootState) => state.filters.minBathrooms,
  minBathrooms => ({ minBathrooms })
);

export const $getBedroomFilters = createSelector(
  (state: RootState) => state.filters.minBedrooms,
  (state: RootState) => state.filters.maxBedrooms,
  (minBedrooms, maxBedrooms) => ({ minBedrooms, maxBedrooms })
);

export const $getSourceFilters = createSelector(
  (state: RootState) => state.filters.exclusiveListingsEnabled,
  (state: RootState) => state.filters.mlsListingsEnabled,
  (exclusiveListingsEnabled, mlsListingsEnabled) => ({
    exclusiveListingsEnabled,
    mlsListingsEnabled,
  })
);

export const $getStatusFilters = createSelector(
  (state: RootState) => state.filters.mlsStatus,
  mlsStatus => ({ mlsStatus })
);

export const $getTimeframeFilters = createSelector(
  (state: RootState) => state.filters.daysOnMarket,
  (state: RootState) => state.filters.lastPriceReduction,
  (state: RootState) => state.filters.leasedTimeframe,
  (state: RootState) => state.filters.soldTimeframe,
  // eslint-disable-next-line max-params
  (daysOnMarket, lastPriceReduction, leasedTimeframe, soldTimeframe) => ({
    daysOnMarket,
    lastPriceReduction,
    leasedTimeframe,
    soldTimeframe,
  })
);

export const $getShowOnlyFilters = createSelector(
  (state: RootState) => state.filters.openHouse,
  (state: RootState) => state.filters.openHouseOnly,
  (state: RootState) => state.filters.ourListingsOnly,
  (state: RootState) => state.filters.noPhoto,
  // eslint-disable-next-line max-params
  (openHouse, openHouseOnly, ourListingsOnly, noPhoto) => ({
    openHouse,
    openHouseOnly,
    ourListingsOnly,
    noPhoto,
  })
);

export const $getBathroomChanged = changedSelector($getBathroomFilters);
export const $getBedroomChanged = changedSelector($getBedroomFilters);
export const $getPriceChanged = changedSelector($getPriceFilters);
export const $getShowOnlyChanged = changedSelector($getShowOnlyFilters);
export const $getSourceChanged = changedSelector($getSourceFilters);
export const $getTimeframeChanged = changedSelector($getTimeframeFilters);
export const $getPropertyTypeChanged = changedSelector($getPropertyTypeFilters);
export const $getSizeChanged = changedSelector($getSizeFilters);
export const $getHomeFeaturesChanged = changedSelector($getHomeFeaturesFilters);
export const $getPropertyFeaturesChanged = changedSelector(
  $getPropertyFeaturesFilters
);
export const $getCommunityFeaturesChanged = changedSelector(
  $getCommunityFeaturesFilters
);
export const $getGSRatingFilterChanged = changedSelector($getGSRatingFilters);
export const $getKeywordsFilterChanged = changedSelector($getKeywordsFilters);

export const $getSourceFiltersChanged = createSelector(
  $getSourceFilters,
  getAgentMode,
  getIsAgent,
  (sourceFilters, agentMode, isAgent) => {
    if (not(isAgent) || agentMode) {
      return false;
    }

    return getFiltersSectionChanged(sourceFilters);
  }
);

export const $getStatusChanged = createSelector(
  $getStatusFilters,
  getAgentMode,
  getIsAgent,
  getIsLeaseSavedSearch,
  // eslint-disable-next-line max-params
  (statusFilters, agentMode, isAgent, isLeaseSS) => {
    const isLease = isLeasePage(history.location.pathname) || isLeaseSS;

    const defaultMlsStatuses =
      isAgent && not(agentMode) && not(isLease)
        ? [...DEFAULT_MLS_STATUSES, MlsStatus.Private]
        : DEFAULT_MLS_STATUSES;

    return notEqual(
      [...defaultMlsStatuses].sort(),
      [...statusFilters.mlsStatus].sort()
    );
  }
);

export const $getSecondaryFilters = createSelector(
  [
    $getSourceFilters,
    $getStatusFilters,
    $getTimeframeFilters,
    $getShowOnlyFilters,
    $getPropertyTypeFilters,
    $getSizeFilters,
    $getHomeFeaturesFilters,
    $getPropertyFeaturesFilters,
    $getCommunityFeaturesFilters,
    $getGSRatingFilters,
    $getKeywordsFilters,
  ],
  (
    sourceFilters,
    statusFilters,
    timeFrameFilters,
    showOnlyFilters,
    propertyTypeFilters,
    sizeFilters,
    homeFeaturesFilters,
    propertyFeaturesFilters,
    communityFeaturesFilters,
    greatSchoolRatingFilters,
    keywordsFilters
    // eslint-disable-next-line max-params
  ) => ({
    sourceFilters,
    ...statusFilters,
    timeFrameFilters,
    showOnlyFilters,
    propertyTypeFilters,
    sizeFilters,
    homeFeaturesFilters,
    propertyFeaturesFilters,
    communityFeaturesFilters,
    greatSchoolRatingFilters,
    ...keywordsFilters,
  })
);

export const $getFiltersForMoreFiltersModal = createSelector(
  $getPriceFilters,
  $getBathroomFilters,
  $getBedroomFilters,
  $getSecondaryFilters,
  // eslint-disable-next-line max-params
  (priceFilters, bathroomsFilters, bedroomsFilters, secondaryFilters) => ({
    ...priceFilters,
    ...bedroomsFilters,
    ...bathroomsFilters,
    ...secondaryFilters,
  })
);

export const $getAppliedMoreFiltersCount = createSelector(
  $getStatusChanged,
  $getTimeframeChanged,
  $getShowOnlyChanged,
  $getPropertyTypeChanged,
  $getSizeChanged,
  $getHomeFeaturesChanged,
  $getPropertyFeaturesChanged,
  $getCommunityFeaturesChanged,
  $getGSRatingFilterChanged,
  $getKeywordsFilterChanged,
  $getSourceFiltersChanged,
  (
    openHouseChanged,
    timeframeChanged,
    showOnlyChanged,
    propertyTypeChanged,
    sizeChanged,
    homeFeaturesChanged,
    propertyFeaturesChanged,
    communityFeaturesChanged,
    gsRatingFilterChanged,
    keywordsFilterChanged,
    sourceFiltersChanged
    // eslint-disable-next-line max-params
  ) =>
    Number(openHouseChanged) +
    Number(timeframeChanged) +
    Number(showOnlyChanged) +
    Number(propertyTypeChanged) +
    Number(sizeChanged) +
    Number(homeFeaturesChanged) +
    Number(propertyFeaturesChanged) +
    Number(communityFeaturesChanged) +
    Number(gsRatingFilterChanged) +
    Number(keywordsFilterChanged) +
    Number(sourceFiltersChanged)
);

export const $getAreAnyFiltersApplied = createSelector(
  $getAppliedMoreFiltersCount,
  $getBathroomChanged,
  $getBedroomChanged,
  $getPriceChanged,
  // eslint-disable-next-line max-params
  (appliedMoreFiltersCount, bathsChanged, bedroomsChanged, priceChanged) => {
    return (
      appliedMoreFiltersCount > 0 ||
      priceChanged ||
      bedroomsChanged ||
      bathsChanged
    );
  }
);

export const $getIsPropertyTypeFiltersChanged = createSelector(
  [$getPropertyTypeFilters],
  propertyTypeFilters => {
    return Object.keys(propertyTypeFilters).some(
      (key: keyof PropertyTypeFilters) =>
        propertyTypeFilters[key] !== PROPERTY_TYPE_DEFAULT_STATE[key]
    );
  }
);

export const getChangedFilters = createSelector(
  [
    getIsAgent,
    getAgentMode,
    $getIsPropertyTypeFiltersChanged,
    state => state.filters,
  ],
  // eslint-disable-next-line max-params
  (isAgent, agentMode, isPropertyTypeFiltersChanged, filters) => {
    const DEFAULT_FILTERS = getDefaultFilters({
      agentMode,
      isAgent,
    }) as FiltersState;

    const data = Object.fromEntries(
      Object.entries(filters).filter(([key, value]: [string, unknown]) => {
        if (
          Array.isArray(value) &&
          Array.isArray(DEFAULT_FILTERS[key as keyof Partial<FiltersState>])
        ) {
          return notEqual(
            [
              ...(DEFAULT_FILTERS[
                key as keyof Partial<FiltersState>
              ] as unknown[]),
            ].sort(),
            [...(value as unknown[])].sort()
          );
        } else {
          if (
            key in PROPERTY_TYPE_DEFAULT_STATE &&
            isPropertyTypeFiltersChanged &&
            value
          ) {
            return true;
          }

          return notEqual(
            DEFAULT_FILTERS[key as keyof Partial<FiltersState>],
            value
          );
        }
      })
    );

    return data as Partial<FiltersState>;
  }
);
