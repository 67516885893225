// action types
import { CommentItem, Comments } from 'comments/commentsTypes';

export enum CommentsActionType {
  RESET_COMMENTS = 'RESET_COMMENTS',
  FETCH_COMMENTS = 'FETCH_COMMENTS',
  FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_ERROR = 'FETCH_COMMENTS_ERROR',
  SAVE_COMMENT = 'SAVE_COMMENT',
  SAVE_COMMENT_SUCCESS = 'SAVE_COMMENT_SUCCESS',
  SAVE_COMMENT_ERROR = 'SAVE_COMMENT_ERROR',
  ADD_COMMENT = 'ADD_COMMENT',
}

// action creators

export interface ResetCommentsAction {
  type: CommentsActionType.RESET_COMMENTS;
}
export const resetComments = (): ResetCommentsAction => {
  return { type: CommentsActionType.RESET_COMMENTS };
};

export interface GetCommentsAction {
  id: string;
  page: number;
  listingid: string;
  cb?: (...args: unknown[]) => unknown;
  type: CommentsActionType.FETCH_COMMENTS;
}
export const getComments = ({
  id,
  page = 1,
  listingid = '',
  cb = () => {},
}: {
  id: string;
  page: number;
  listingid: string;
  cb?: (...args: unknown[]) => unknown;
}): GetCommentsAction => {
  return { type: CommentsActionType.FETCH_COMMENTS, id, page, listingid, cb };
};

export interface GetCommentsErrorAction {
  type: CommentsActionType.FETCH_COMMENTS_ERROR;
  error: string;
}
export const getCommentsError = (error: string): GetCommentsErrorAction => {
  return { type: CommentsActionType.FETCH_COMMENTS_ERROR, error };
};

export interface GetCommentsSuccessAction {
  type: CommentsActionType.FETCH_COMMENTS_SUCCESS;
  items: CommentItem[];
  total: number;
}
export const getCommentsSuccess = (
  items: Comments
): GetCommentsSuccessAction => {
  return {
    type: CommentsActionType.FETCH_COMMENTS_SUCCESS,
    items: items.hits,
    total: items.total,
  };
};

export interface SaveCommentAction {
  type: CommentsActionType.SAVE_COMMENT;
  id: string;
  comment: string;
  listingid: string;
  withoutUpdate?: boolean;
  cb?(): void;
  owner?: string;
  notNotifyUser?: boolean;
}
export const saveComment = ({
  id,
  comment,
  listingid = '',
  cb = () => {},
  withoutUpdate = false,
  owner,
  notNotifyUser,
}: {
  id: string;
  comment: string;
  listingid: string;
  withoutUpdate?: boolean;
  cb?(): void;
  owner?: string;
  notNotifyUser?: boolean;
}): SaveCommentAction => {
  return {
    type: CommentsActionType.SAVE_COMMENT,
    comment,
    listingid,
    id,
    cb,
    owner,
    withoutUpdate,
    notNotifyUser,
  };
};

export interface AddCommentAction {
  type: CommentsActionType.ADD_COMMENT;
  comment: CommentItem;
}
export const addComment = (comment: CommentItem): AddCommentAction => {
  return { type: CommentsActionType.ADD_COMMENT, comment };
};

export interface SaveCommentSuccessAction {
  type: CommentsActionType.SAVE_COMMENT_SUCCESS;
  listingid: string;
}

export const saveCommentSuccess = ({
  listingid,
}: {
  listingid: string;
}): SaveCommentSuccessAction => {
  return { type: CommentsActionType.SAVE_COMMENT_SUCCESS, listingid };
};

export interface SaveCommentErrorAction {
  type: CommentsActionType.SAVE_COMMENT_ERROR;
  error: string;
}
export const saveCommentError = (error: string): SaveCommentErrorAction => ({
  type: CommentsActionType.SAVE_COMMENT_ERROR,
  error,
});

export type CommentsAction =
  | ResetCommentsAction
  | GetCommentsAction
  | GetCommentsErrorAction
  | GetCommentsSuccessAction
  | SaveCommentAction
  | SaveCommentSuccessAction
  | SaveCommentErrorAction
  | AddCommentAction;
