import { Location } from 'history';
import { deburr, orderBy, pick } from 'lodash';
import queryString from 'query-string';
import { generatePath, matchPath } from 'react-router';

import {
  AGENT_ORS_DESIGNATION,
  AgentQueryKeys,
  AGENTS_LANGUAGES,
  BuyerOrSellerTitle,
  FROZEN_AGENT_PROFILEID,
  UserAccountType,
  UserTeamType,
} from 'agents/agentsConstants';
import { INITIAL_AGENTS_STATE } from 'agents/agentsReducer';
import { Agent, AgentsSortingOptions } from 'agents/agentsTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { toArray } from 'shared/helpers/array';
import { not, notEmpty } from 'shared/helpers/boolean';
import { replaceSpacesToDashesInArray } from 'shared/helpers/formatters';
import {
  getNotEmptyQueryParams,
  isNumeric,
  removeDefaultProperties,
  removeEmptyProperties,
} from 'shared/helpers/mainHelpers';
import validators, { getPhoneValidator } from 'shared/helpers/validators';
import history from 'shared/services/history';
import { QueryStringArrayFormat } from 'shared/types';

export const agentsSortingOptions: AgentsSortingOptions[] = [
  { value: 'lastname', label: 'Last Name' },
  { value: 'name', label: 'First Name' },
];

export const requestSpecialistValidationRules = {
  firstname: [
    validators.required,
    validators.max,
    validators.lettersOnlyWithSpaceAndDash,
  ],
  lastname: [
    validators.required,
    validators.max,
    validators.lettersOnlyWithSpaceAndDash,
  ],
  phone: [validators.required, (value: string) => getPhoneValidator(value)],
  email: [validators.required, validators.email],
  message: [validators.required],
};

export const getAgentSuggestions = (agents: Agent[]) => {
  const agentOptions = agents.map((agent: Agent) => {
    return {
      value: agent.username,
      label: agent.name,
      name: agent.name,
      sfId: agent.sfId,
      trecLicense: agent.trecLicense,
      url: agent.url,
    };
  });
  return orderBy(agentOptions, 'label');
};

export const generateAgentBioUrl = (url: string) =>
  generatePath(RoutePath.AGENT_BIO, {
    name: url.replace('/', ''),
  });

export const getContactAgentButtonTitle = (isTeamAccount: boolean): string => {
  return `Contact ${isTeamAccount ? 'Team' : 'Agent'}`;
};

export const getBuyerOrSellerTitle = (
  trecLicense: string,
  listAgentMlsId: string,
  buyerAgentMlsId: string,
  colistAgentMlsId: string,
  cobuyerAgentMlsId: string
  // eslint-disable-next-line max-params
): string => {
  switch (true) {
    case (trecLicense === listAgentMlsId || trecLicense === colistAgentMlsId) &&
      (trecLicense === buyerAgentMlsId || trecLicense === cobuyerAgentMlsId):
      return BuyerOrSellerTitle.Intermediary;
    case trecLicense === listAgentMlsId || trecLicense === colistAgentMlsId:
      return BuyerOrSellerTitle.SellerRepresented;
    case trecLicense === buyerAgentMlsId || trecLicense === cobuyerAgentMlsId:
      return BuyerOrSellerTitle.BuyerRepresented;
    default:
      return BuyerOrSellerTitle.SellerRepresented;
  }
};

export const getBuyerOrSellerTitleForTeam = (
  teamMembers: Agent[],
  listAgentMlsId: string,
  buyerAgentMlsId: string,
  colistAgentMlsId: string,
  cobuyerAgentMlsId: string
  // eslint-disable-next-line max-params
): string => {
  const trecLicenses = teamMembers.map(member => member.trecLicense);

  switch (true) {
    case (trecLicenses.includes(listAgentMlsId) ||
      trecLicenses.includes(colistAgentMlsId)) &&
      (trecLicenses.includes(buyerAgentMlsId) ||
        trecLicenses.includes(cobuyerAgentMlsId)):
      return BuyerOrSellerTitle.Intermediary;
    case trecLicenses.includes(listAgentMlsId) ||
      trecLicenses.includes(colistAgentMlsId):
      return BuyerOrSellerTitle.SellerRepresented;
    case trecLicenses.includes(buyerAgentMlsId) ||
      trecLicenses.includes(cobuyerAgentMlsId):
      return BuyerOrSellerTitle.BuyerRepresented;
    default:
      return BuyerOrSellerTitle.SellerRepresented;
  }
};

export const isAgentRoute = (pathname: string) => {
  const pattern = new RegExp(`^${RoutePath.AGENT}(?!s)+.*`);
  return pattern.test(pathname);
};

export const isAgentBioRoute = (pathname: string) => {
  const pattern = new RegExp(`^${RoutePath.AGENTS}/[\\w-]+(/)?$`);
  return pattern.test(pathname);
};

export const isFrozenAgent = (agent?: Agent) => {
  return (
    agent &&
    agent.profileId === FROZEN_AGENT_PROFILEID &&
    agent.userAccountType === UserAccountType.TeamMember &&
    agent.teamType === UserTeamType.Together
  );
};

export const prepareAgentsUrl = (query: {
  sortType: SortOrder;
  page: number;
  sortBy: string;
  languagesfilter: string[];
}) => {
  const cleanedQuery = removeDefaultProperties(query, {
    page: INITIAL_AGENTS_STATE.page,
    sortType: INITIAL_AGENTS_STATE.sortType,
    sortBy: INITIAL_AGENTS_STATE.sortBy,
    languagesfilter: INITIAL_AGENTS_STATE.languagesFilter,
  });

  if (
    Array.isArray(cleanedQuery.languagesfilter) &&
    notEmpty(cleanedQuery.languagesfilter)
  ) {
    cleanedQuery.languagesfilter = replaceSpacesToDashesInArray(
      cleanedQuery.languagesfilter
    );
  }

  return {
    search: queryString.stringify(removeEmptyProperties(cleanedQuery), {
      sort: false,
      arrayFormat: QueryStringArrayFormat.SEPARATOR,
      arrayFormatSeparator: '+',
      encode: false,
    }),
  };
};

export const parseAgentsUrl = (location: Location = history.location) => {
  const actualQueryParams = getNotEmptyQueryParams(location.search, {
    arrayFormat: QueryStringArrayFormat.SEPARATOR,
    arrayFormatSeparator: '+',
  });

  if (actualQueryParams.languagesfilter) {
    actualQueryParams.languagesFilter = (
      actualQueryParams.languagesfilter as string
    ).split(' ');
  }

  const agentQuery = pick(actualQueryParams, Object.values(AgentQueryKeys));

  return convertAgentQueryValues(agentQuery);
};

export const convertAgentQueryValues = (
  agentQueryValues: Record<string, string | string[]>
) => {
  const convertedAgentQueryValues: Record<
    string,
    boolean | string | number | any[]
  > = { ...agentQueryValues };

  Object.keys(convertedAgentQueryValues).forEach(key => {
    if (convertedAgentQueryValues[key] === 'true') {
      convertedAgentQueryValues[key] = true;
    }
    if (convertedAgentQueryValues[key] === 'false') {
      convertedAgentQueryValues[key] = false;
    }
    if (isNumeric(convertedAgentQueryValues[key])) {
      convertedAgentQueryValues[key] = Number(convertedAgentQueryValues[key]);
    }
    if (key === AgentQueryKeys.LanguagesFilter) {
      const filters = toArray(convertedAgentQueryValues[key]);

      convertedAgentQueryValues[key] = filters.filter(language =>
        AGENTS_LANGUAGES.find(item => item.value === language)
      );
    }
  });

  return convertedAgentQueryValues;
};

export const isAdminAgent = (userRole?: UserAccountType): boolean => {
  if (not(userRole)) {
    return false;
  }
  return (
    userRole === UserAccountType.StaffAccount ||
    userRole === UserAccountType.Coordinator
  );
};

export const getLanguagesFilterLabels = (filters: string[]) => {
  const selectedLanguages = AGENTS_LANGUAGES.filter(language =>
    filters.includes(language.value)
  );

  return selectedLanguages.map(language => language.label);
};

export function isAgentBioPage(): boolean {
  const path = history.location.pathname;

  return Boolean(matchPath(path, RoutePath.AGENT_BIO));
}

export const isORSAgent = (designations: string): boolean => {
  if (designations) {
    return designations.split(';').includes(AGENT_ORS_DESIGNATION);
  }

  return false;
};

export const getAgentUrlParam = (
  firstname: string,
  lastname: string
): string => {
  const specialSymbolsReqExp = /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi;
  const firstnameFormat = firstname
    .replace(specialSymbolsReqExp, '')
    .split(' ')
    .join('-');
  const lastnameFormat = lastname
    .replace(specialSymbolsReqExp, '')
    .split(' ')
    .join('-');
  return deburr(
    `/${firstnameFormat.concat('-', lastnameFormat).toLowerCase()}`
  );
};
