import { Hub } from '@aws-amplify/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import config from 'config';
import MemoizedMetadata from 'shared/components/Metadata';
import { notEmpty } from 'shared/helpers/boolean';
import { trackOriginalSource } from 'shared/helpers/trackOriginalSource';
import {
  HubPayload,
  HubPayloadData,
  HubPayloadEvent,
} from 'shared/services/AuthService';
import { CognitoFederatedProviders } from 'shared/types';
import { useIsMobile } from 'styled-system/responsive';
import {
  getUserSession,
  setIsMobile,
  userFederatedLogIn,
} from 'user/userActions';

type AppProps = { children: React.ReactNode };

let hubPayload: HubPayload = {};

const redirectToSalesforceCondition = (payload: HubPayload): boolean => {
  if (
    // handle Salesforce Exception processing authorization error with retry
    (payload.event === HubPayloadEvent.SignInFailure &&
      payload.data === HubPayloadData.AuthExceptionSalesforce) ||
    // handle custom flow for Social login with RA emails
    (payload.event === HubPayloadEvent.CustomStateFailure &&
      String(payload.data).includes(HubPayloadData.CustomMessageAgentEmailFlow))
  ) {
    return true;
  }
  return false;
};

// Listening for Amplify oauth events
Hub.listen('auth', ({ payload }) => {
  hubPayload = payload;
});

const App = (props: AppProps) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      onAuthEvent(payload);
    });

    if (notEmpty(hubPayload)) {
      onAuthEvent(hubPayload);
    } else {
      dispatch(getUserSession());
    }

    trackOriginalSource();

    // https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event#Safari_Mobile
    if ('ontouchstart' in document.documentElement) {
      document.body.style.cursor = 'pointer';
    }
  }, []);

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
  }, [isMobile]);

  const onAuthEvent = (payload: HubPayload) => {
    if (payload.event === HubPayloadEvent.SignIn) {
      // fix Amplify 2.x bug to provide oauth sign out after basic sigh in
      localStorage.setItem('amplify-signin-with-hostedUI', 'true');
      dispatch(getUserSession());
    } else if (redirectToSalesforceCondition(payload)) {
      dispatch(userFederatedLogIn(CognitoFederatedProviders.Google));
    }
  };

  return (
    <>
      <MemoizedMetadata
        title={config.seoData.defaultAppSeo.title}
        description={config.seoData.defaultAppSeo.description}
      />
      {props.children}
    </>
  );
};

export default App;
