import React from 'react';

import {
  Arrow,
  Container,
} from 'shared/components/PopperPopup/PopperPopupStyled';
import { PopperPopupUtils } from 'shared/components/PopperPopup/usePopperPopup';
import { StyledSystemInput } from 'styled-system/lib/types';

interface Props {
  arrow?: boolean;
  containerSx?: StyledSystemInput;
  popper: PopperPopupUtils;
}

export const PopperPopup: React.FC<Props> = props => {
  const { attributes, refs, styles } = props.popper;

  const showArrow = props.arrow ?? true;

  return (
    <Container
      ref={refs.setPopup}
      style={styles.popper}
      sx={props.containerSx}
      {...attributes.popper}
    >
      {showArrow && (
        <Arrow data-popper-arrow ref={refs.setArrow} style={styles.arrow} />
      )}
      {props.children}
    </Container>
  );
};
