import { EmailHistoryStatusFilter } from 'history/emailHistory/emailHistoryTypes';

export const handleEmailHistoryQuery = (status: EmailHistoryStatusFilter) => {
  if (
    [
      EmailHistoryStatusFilter.SoftBounced,
      EmailHistoryStatusFilter.HardBounced,
    ].includes(status)
  ) {
    return {
      status: EmailHistoryStatusFilter.Bounced,
      notSentReason: status,
    };
  } else if (
    [
      EmailHistoryStatusFilter.NotSentHardBounced,
      EmailHistoryStatusFilter.NotSentSoftBounced,
    ].includes(status)
  ) {
    return {
      status: EmailHistoryStatusFilter.NotSent,
      notSentReason:
        status === EmailHistoryStatusFilter.NotSentHardBounced
          ? EmailHistoryStatusFilter.HardBounced
          : EmailHistoryStatusFilter.SoftBounced,
    };
  } else {
    return {
      status,
    };
  }
};
