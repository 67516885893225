import { not } from 'shared/helpers/boolean';

export const callIfOnline = (cb: () => void): void => {
  const onLine = navigator.onLine;
  if (not(onLine)) {
    return;
  }

  cb();
};
