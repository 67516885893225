import styled, { css } from 'styled-components/macro';

import { gsRating } from 'filters/filtersContants';
import { validateSelectInput } from 'filters/filtersHelpers';
import { $getGSRatingFilterChanged } from 'filters/filtersSelectors';
import {
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  SelectContainer,
  SelectWrapper,
} from 'filters/filtersStyled';
import { SharedFiltersProps } from 'filters/filtersTypes';
import { InputSelectField } from 'shared/components/formikFields/InputSelectField';
import { useSelector } from 'shared/helpers/redux';
import { media } from 'styled-system/responsive';

const Container = styled.div<{ innerMode?: boolean }>`
  width: 442px;
  max-width: 100vw;

  .react-select__menu-list {
    max-height: 202px;
    ${media.sm(css`
      max-height: 184px;
    `)}
  }

  ${({ innerMode }) =>
    innerMode &&
    css`
      ${SelectWrapper} {
        padding-bottom: 0;
      }
    `}

  ${media.sm(css`
    width: 100%;
  `)};
`;

type PriceFilterProps = SharedFiltersProps;

export const GreatSchoolsFilter = (props: PriceFilterProps) => {
  const gsFiltersChanged = useSelector($getGSRatingFilterChanged);

  const isInputValid = (value: string) => {
    return validateSelectInput(value, gsRating);
  };

  return (
    <Container innerMode={props.innerMode}>
      <FilterHeaderContainer>
        {gsFiltersChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          GreatSchools Rating
        </FilterHeaderTitle>
      </FilterHeaderContainer>
      <SelectContainer>
        <SelectWrapper>
          <InputSelectField
            name="greatSchoolRatingFilters.elementarySchoolRating"
            ariaLabel={'Select great school rating for "Elementary"'}
            labelText="Elementary"
            isDisabled={props.disableFilter}
            validator={isInputValid}
            options={gsRating}
          />
        </SelectWrapper>
        <SelectWrapper>
          <InputSelectField
            name="greatSchoolRatingFilters.middleSchoolRating"
            ariaLabel={'Select great school rating for "Middle"'}
            labelText="Middle"
            isDisabled={props.disableFilter}
            validator={isInputValid}
            options={gsRating}
          />
        </SelectWrapper>
        <SelectWrapper>
          <InputSelectField
            name="greatSchoolRatingFilters.highSchoolRating"
            ariaLabel={'Select great school rating for "High"'}
            labelText="High"
            isDisabled={props.disableFilter}
            validator={isInputValid}
            options={gsRating}
          />
        </SelectWrapper>
      </SelectContainer>
    </Container>
  );
};
