import { MlsStatus } from 'filters/filtersContants';

export const VIEW_MODE_TABLE = 'Table';
export const VIEW_MODE_GALLERY = 'Gallery';
export const SAVED_SEARCH_TAG_TYPE = 'SAVED_SEARCH_TAG_TYPE';
export const LISTINGS_PER_PAGE = 20;
export const VIEWED_SEARCHES_PER_PAGE = 35;
export const SAVED_SEARCHES_PER_PAGE = 20;
export const CUSTOM_TAG_LABEL = 'Custom Location';
export const UNDISCLOSED_ADDRESS = 'Undisclosed address';
export const RA_OFFICE_ID = '3788';
export const SA_OFFICE_ID = 'REAU00';
export const STATIC_LISTINGS_TAB_HIDDEN_TITLE = 'Homes for Sale';
export const LISTING_CONTENT_HEADER_DEFAULT_HEIGHT = 50;
export const MAX_VIEW_LISTINGS_BY_GUEST = 2;
export const GUEST_VISITED_LISTINGS = 'count_l';
export const MlsStatusesWithoutPrice = [MlsStatus.Sold, MlsStatus.Leased];

export const MlsStatusesWithoutPaymentCalculator = [
  MlsStatus.Sold,
  MlsStatus.Expired,
  MlsStatus.Withdrawn,
];

export const searchUrl = '/api/v2/listing/search';
export const MIN_LISTINGS_FOR_ORS = 3;

export const EmptyStatus = 'empty';

export const PERIOD_FILTER_DEFAULT_VALUE = 0;

export const AGENT_CONTACT_VIEWED_LISTINGS_URL = '/api/v2/history/views';
export const USER_VIEWED_LISTINGS_URL = '/api/v2/history/myviews';
