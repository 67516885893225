import React, { Suspense, useContext } from 'react';

import { ModalContext } from 'shared/components/Modal/modalContext';

const ModalRoot: React.FC = () => {
  const modalContext = useContext(ModalContext);

  const {
    closeModal,
    component: Component,
    props,
    showModal,
    isOpen,
  } = modalContext;
  if (Component) {
    return (
      <Suspense fallback={null}>
        <Component
          closeModal={closeModal}
          showModal={showModal}
          isOpen={isOpen}
          {...props}
        />
      </Suspense>
    );
  }

  return null;
};

export default ModalRoot;
