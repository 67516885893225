import { createContext } from 'react';

export interface IFeaturesSupport {
  lazyLoad: boolean;
}

const defaultValueContext = {
  lazyLoad: false,
} as IFeaturesSupport;

export const FeatureSupportContext =
  createContext<IFeaturesSupport>(defaultValueContext);

export const initializeFeatureSupport = (): IFeaturesSupport => ({
  // lazyLoad: !!('loading' in HTMLImageElement.prototype)
  lazyLoad: (() => {
    const val = !!('loading' in HTMLImageElement.prototype);
    return val;
  })(),
});
