import ReactGA from 'react-ga';
import ttiPolyfill from 'tti-polyfill';

import { getEnv } from 'env-config';
import history from 'shared/services/history';

const PERFORMANCE_METRIC_CATEGORY = 'Performance Metrics - prod';

const trackPagePerformance = () => {
  ttiPolyfill
    .getFirstConsistentlyInteractive({ useMutationObserver: false })
    .then((tti: number) => {
      ReactGA.event({
        category: PERFORMANCE_METRIC_CATEGORY,
        action: 'time-to-interactive',
        value: Math.round(tti),
        nonInteraction: false,
      });
    });
};

const trackPageLoad = (list: PerformanceObserverEntryList) => {
  list.getEntries().forEach((entry: PerformanceEntry) => {
    const metricName = entry.name;
    const time = Math.round(entry.startTime + entry.duration);
    ReactGA.event({
      category: PERFORMANCE_METRIC_CATEGORY,
      action: metricName,
      value: time,
      nonInteraction: false,
    });
  });
};

export const gaSubscribe = () => {
  ReactGA.initialize(getEnv().googleAnalyticsId);

  trackPageByGa(window.location.pathname + window.location.search);

  /* PerformanceObserver is not supported in some Edge versions */
  if (typeof PerformanceObserver !== 'undefined') {
    const observer = new PerformanceObserver(trackPageLoad);
    try {
      /* Entry type 'paint' is not supported in some Safari versions */
      observer.observe({ entryTypes: ['paint'] });
    } catch (e) {}
  }

  trackPagePerformance();

  history.listen(location => {
    trackPageByGa(location.pathname + location.search);
  });
};

export const trackPageByGa = (page: string) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};
