import React from 'react';
import styled, { css } from 'styled-components/macro';

import { BottomText } from 'shared/components/Input/Input';
import { not } from 'shared/helpers/boolean';
import { Themed } from 'shared/types';
import { Flex } from 'styled-system/components';

export const CheckMark = styled.span`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.border};
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + ${CheckMark} {
    border: 5px solid ${({ theme }) => theme.secondary};
  }
`;

export const TitleText = styled.span`
  margin-left: 8px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
`;

export const RadioContainer = styled.label<{ disabled: boolean }>`
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  min-height: 16px;
  margin: 0 16px 18px 0;
  display: flex;
  flex-direction: column;

  &:hover {
    ${CheckMark} {
      border: 1px solid ${({ theme }) => theme.text2};
    }

    ${Input}:checked + ${CheckMark} {
      border: 5px solid ${({ theme }) => theme.secondary};
    }
  }

  ${props =>
    props.disabled &&
    css<any>`
      pointer-events: none;

      ${CheckMark} {
        border: 1px solid ${({ theme }: Themed) => theme.border};
        background-color: #f7f7f7;
        opacity: 0.5;
      }

      ${TitleText} {
        color: ${({ theme }: Themed) => theme.disabled};
      }
    `};
`;

export interface RadioProps {
  name: string;
  arialLabel?: string;
  titleText: string | React.ReactElement;
  checked: boolean;
  disabled?: boolean;
  value: string;
  bottomText?: string;
  onChange: (value: any) => any;
  onBlur?: (e: any) => void;
}

const Radio = (props: RadioProps) => {
  const { disabled, titleText, onChange, bottomText, ...other } = props;
  const ariaLabel =
    props.arialLabel ||
    (titleText && typeof titleText === 'string' ? titleText : '');

  return (
    <RadioContainer
      role="radio"
      aria-label={ariaLabel}
      disabled={Boolean(disabled)}
      tabIndex={0}
      onKeyPress={e => {
        if (e.key === 'Enter' && not(disabled)) {
          onChange(other.value);
        }
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Input
          {...other}
          type="radio"
          onChange={e => onChange(e.target.value)}
          tabIndex={-1}
        />
        <CheckMark />
        {titleText && <TitleText>{titleText}</TitleText>}
      </Flex>

      {Boolean(bottomText) && <BottomText>{bottomText}</BottomText>}
    </RadioContainer>
  );
};

export default Radio;
