import * as H from 'history';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import { isAgentRoute } from 'agents/agentsHelpers';
import { SIGN_UP_URL_PARAMETER } from 'shared/constants/appConstants';
import {
  USER_PREVIOUS_LOCATION,
  USER_PREVIOUS_USERNAME,
} from 'shared/constants/rootConstants';
import { not } from 'shared/helpers/boolean';
import { agentLogIn } from 'user/userActions';
import {
  getUser,
  isAuthorised as isAuthorisedSelector,
} from 'user/userSelectors';
import { UserRole } from 'user/userTypes';

type AuthProtectedRoutesProps = {
  children: React.ReactNode;
  location?: H.Location;
};

const AuthProtectedRoutes = (props: AuthProtectedRoutesProps) => {
  const isAuthorised = useSelector(isAuthorisedSelector);
  const user = useSelector(getUser);
  const location = props.location;
  const dispatch = useDispatch();

  let isAgentRouteCalled = false;

  useEffect(() => {
    if (not(user.initialized)) {
      return;
    }

    if (not(isAuthorised)) {
      localStorage.setItem(
        USER_PREVIOUS_LOCATION,
        location.pathname + location.search
      );
      localStorage.removeItem(USER_PREVIOUS_USERNAME);
    }

    if (
      not(isAgentRouteCalled) &&
      user.role !== UserRole.Agent &&
      isAgentRoute(location.pathname)
    ) {
      dispatch(agentLogIn());
      isAgentRouteCalled = true;
    }
  }, [user.initialized, user.role, isAuthorised]);

  const signUpEmail = queryString.parse(location.search)[
    SIGN_UP_URL_PARAMETER
  ] as string;

  if (
    not(user.initialized) ||
    (isAgentRoute(location.pathname) && user.role !== UserRole.Agent)
  ) {
    return null;
  }

  if (isAuthorised) {
    return <Switch location={location}>{props.children}</Switch>;
  }

  return (
    <Redirect
      push={false}
      to={{
        pathname: '/',
        state: {
          requestProtected: true,
          email: signUpEmail,
        },
      }}
    />
  );
};

export default AuthProtectedRoutes;
