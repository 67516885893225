import styled from 'styled-components/macro';

import globalTheme from 'shared/styles/globalTheme';

export const ButtonContainer = styled.div<{
  isMobile?: boolean;
}>`
  background-color: ${globalTheme.text};
  font-size: ${props => (props.isMobile ? 10 : 12)}px;
  width: ${props => (props.isMobile ? 50 : 60)}px;
  height: ${props => (props.isMobile ? 50 : 60)}px;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
`;

export const ButtonTitle = styled.span`
  color: #fff;
  font-weight: bold;
`;

export const IconContainer = styled.div`
  text-align: center;
  height: 20px;
`;
