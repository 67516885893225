import { FiltersState } from 'filters/filtersTypes';
import {
  CreateUpdateSaveSearchBody,
  SavedSearch,
  SavedSearchFilters,
  ViewedSearch,
} from 'saved-search/savedSearchTypes';
import { SortFilterValue } from 'shared/components/SortFilter/SortFilter';
import { SortOrder } from 'shared/constants/appConstants';
import { Invitation, Paginated } from 'shared/types';
import { Collaborator } from 'user/userTypes';

export enum SavedSearchActionType {
  CHANGE_VIEWED_SEARCHES_PAGE = 'CHANGE_VIEWED_SEARCHES_PAGE',
  CHANGE_SAVED_SEARCHES_PAGE = 'CHANGE_SAVED_SEARCHES_PAGE',
  CREATE_SAVED_SEARCH_FAIL = 'CREATE_SAVED_SEARCH_FAIL',
  CREATE_SAVED_SEARCH_REQUEST = 'CREATE_SAVED_SEARCH_REQUEST',
  DECLINE_SAVED_SEARCH_FAIL = 'DECLINE_SAVED_SEARCH_FAIL',
  DECLINE_SAVED_SEARCH_REQUEST = 'DECLINE_SAVED_SEARCH',
  DECLINE_SAVED_SEARCH_SUCCESS = 'DECLINE_SAVED_SEARCH_SUCCESS',
  FETCH_SAVED_SEARCH = 'FETCH_SAVED_SEARCH',
  FETCH_SAVED_SEARCH_FAIL = 'FETCH_SAVED_SEARCH_FAIL',
  FETCH_SAVED_SEARCHES = 'FETCH_SAVED_SEARCHES',
  FETCH_SAVED_SEARCHES_FAIL = 'FETCH_SAVED_SEARCHES_FAIL',
  FETCH_VIEWED_SEARCH = 'FETCH_VIEWED_SEARCH',
  FETCH_VIEWED_SEARCH_FAIL = 'FETCH_VIEWED_SEARCH_FAIL',
  FETCH_VIEWED_SEARCH_SUCCESS = 'FETCH_VIEWED_SEARCH_SUCCESS',
  FETCH_VIEWED_SEARCHES = 'FETCH_VIEWED_SEARCHES',
  FETCH_VIEWED_SEARCHES_FAIL = 'FETCH_VIEWED_SEARCHES_FAIL',
  FETCH_VIEWED_SEARCHES_REQUEST = 'FETCH_VIEWED_SEARCHES_REQUEST',
  FETCH_VIEWED_SEARCHES_SUCCESS = 'FETCH_VIEWED_SEARCHES_SUCCESS',
  FETCH_SAVED_SEARCHES_INVITATIONS = 'FETCH_SAVED_SEARCHES_INVITATIONS',
  FILTER_VIEWED_SEARCHES_BY_PERIOD = 'FILTER_VIEWED_SEARCHES_BY_PERIOD',
  JOIN_SAVED_FAIL = 'JOIN_SAVED_FAIL',
  JOIN_SAVED_SEARCH = 'JOIN_SAVED_SEARCH',
  JOIN_SAVED_SEARCH_REQUEST = 'JOIN_SAVED_SEARCH_REQUEST',
  REMOVE_SAVED_SEARCH_FAIL = 'REMOVE_SAVED_SEARCH_FAIL',
  REMOVE_SAVED_SEARCH_FROM_STORE = 'REMOVE_SAVED_SEARCH_FROM_STORE',
  REMOVE_SAVED_SEARCH_REQUEST = 'REMOVE_SAVED_SEARCH_REQUEST',
  REMOVE_SAVED_SEARCH_SUCCESS = 'REMOVE_SAVED_SEARCH_REQUEST_SUCCESS',
  RESET_SAVED_SEARCH = 'RESET_SAVED_SEARCH',
  RESET_SAVED_SEARCHES = 'RESET_SAVED_SEARCHES',
  RESET_VIEWED_SEARCHES = 'RESET_VIEWED_SEARCHES',
  REVERT_SAVED_SEARCH = 'REVERT_SAVED_SEARCH',
  SAVE_SEARCH_AS_NEW_FAIL = 'SAVE_SEARCH_AS_NEW_FAIL',
  SAVE_SEARCH_AS_NEW_REQUEST = 'SAVE_SEARCH_AS_NEW_REQUEST',
  SAVE_SEARCH_FROM_VIEWED_REQUEST = 'SAVE_SEARCH_FROM_VIEWED_REQUEST',
  SEND_SEARCH_RESULT = 'SEND_SEARCH_RESULT',
  SEND_SEARCH_RESULT_FAIL = 'SEND_SEARCH_RESULT_FAIL',
  SEND_SEARCH_RESULT_SUCCESS = 'SEND_SEARCH_RESULT_SUCCESS',
  SET_SAVED_SEARCH = 'SET_SAVED_SEARCH',
  SET_SAVED_SEARCHES = 'SET_SAVED_SEARCHES',
  SHARE_SAVED_SEARCH = 'SHARE_SAVED_SEARCH',
  SHARE_SAVED_SEARCH_REQUEST = 'SHARE_SAVED_SEARCH_REQUEST',
  SORT_SAVED_SEARCHES = 'SET_SAVED_SEARCHES_SORTING',
  SORT_VIEWED_SEARCHES = 'SORT_VIEWED_SEARCHES',
  START_DECLINE_SAVED_SEARCH = 'DECLINE_SAVED_SEARCH_REQUEST',
  START_REMOVE_SAVED_SEARCH = 'START_DELETE_SAVED_SEARCH',
  UNDO_REMOVE_SAVED_SEARCH = 'UNDO_REMOVE_SAVED_SEARCH',
  UNSHARE_SAVED_SEARCH = 'UNSHARE_SAVED_SEARCH',
  UNSHARE_SAVED_SEARCH_REQUEST = 'UNSHARE_SAVED_SEARCH_REQUEST',
  UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH',
  UPDATE_SAVED_SEARCH_FAIL = 'UPDATE_SAVED_SEARCH_FAIL',
  UPDATE_SAVED_SEARCH_REQUEST = 'UPDATE_SAVED_SEARCH_REQUEST',
  UPDATE_SAVED_SEARCH_SETTINGS_FAIL = 'UPDATE_SAVED_SEARCH_SETTINGS_FAIL',
  UPDATE_SAVED_SEARCH_SETTINGS_REQUEST = 'UPDATE_SAVED_SEARCH_SETTINGS_REQUEST',
  UPDATE_SAVED_SEARCHES_INVITATIONS = 'UPDATE_SAVED_SEARCHES_INVITATIONS',
  ACCEPT_SAVED_SEARCH_INVITATION = 'ACCEPT_SAVED_SEARCH_INVITATION',
  DECLINE_SAVED_SEARCH_INVITATION = 'DECLINE_SAVED_SEARCH_INVITATION',
  SEND_SAVED_SEARCH_INVITATION_REMINDER = 'SEND_SAVED_SEARCH_INVITATION_REMINDER',
}

export interface UndoRemoveSavedSearchAction {
  type: SavedSearchActionType.UNDO_REMOVE_SAVED_SEARCH;
  id: string;
}

export const undoRemoveSavedSearch = (
  id: string
): UndoRemoveSavedSearchAction => ({
  type: SavedSearchActionType.UNDO_REMOVE_SAVED_SEARCH,
  id,
});

export interface StartRemoveSavedSearchAction {
  type: SavedSearchActionType.START_REMOVE_SAVED_SEARCH;
  id: string;
  name: string;
  owner?: string;
  cb?: (route: string) => void;
}

export const startRemoveSavedSearch = ({
  id,
  name,
  cb = () => {},
}: {
  id: string;
  name: string;
  cb?: (route: string) => void;
}): StartRemoveSavedSearchAction => ({
  type: SavedSearchActionType.START_REMOVE_SAVED_SEARCH,
  id,
  name,
  cb,
});

export interface RemoveSavedSearchRequestAction {
  type: SavedSearchActionType.REMOVE_SAVED_SEARCH_REQUEST;
  id: string;
  name: string;
}

export const removeSavedSearchRequest = ({
  id,
  name,
}: {
  id: string;
  name: string;
}): RemoveSavedSearchRequestAction => ({
  type: SavedSearchActionType.REMOVE_SAVED_SEARCH_REQUEST,
  id,
  name,
});

export interface RemoveSavedSearchFromStoreAction {
  type: SavedSearchActionType.REMOVE_SAVED_SEARCH_FROM_STORE;
  id: string;
}

export const removeSavedSearchFromStore = (
  id: string
): RemoveSavedSearchFromStoreAction => ({
  type: SavedSearchActionType.REMOVE_SAVED_SEARCH_FROM_STORE,
  id,
});

export interface RemoveSavedSearchFailAction {
  type: SavedSearchActionType.REMOVE_SAVED_SEARCH_FAIL;
  error: string;
}

export interface RemoveSavedSearchSuccessAction {
  type: SavedSearchActionType.REMOVE_SAVED_SEARCH_SUCCESS;
  id: string;
}

export const removeSavedSearchRequestSuccess = (
  id: string
): RemoveSavedSearchSuccessAction => ({
  type: SavedSearchActionType.REMOVE_SAVED_SEARCH_SUCCESS,
  id,
});

export const removeSavedSearchRequestFail = (
  error: string
): RemoveSavedSearchFailAction => ({
  type: SavedSearchActionType.REMOVE_SAVED_SEARCH_FAIL,
  error,
});

export interface StartDeclineSavedSearchAction {
  type: SavedSearchActionType.START_DECLINE_SAVED_SEARCH;
  id: string;
  name: string;
  contactId?: string;
}

export const startDeclineSavedSearch = ({
  id,
  name,
  contactId,
}: {
  id: string;
  name: string;
  contactId?: string;
}): StartDeclineSavedSearchAction => ({
  type: SavedSearchActionType.START_DECLINE_SAVED_SEARCH,
  id,
  name,
  contactId,
});

export interface DeclineSavedSearchRequestAction {
  type: SavedSearchActionType.DECLINE_SAVED_SEARCH_REQUEST;
  id: string;
  name: string;
  contactId?: string;
}

export const declineSavedSearchRequest = ({
  id,
  name,
  contactId,
}: {
  id: string;
  name: string;
  contactId?: string;
}): DeclineSavedSearchRequestAction => ({
  type: SavedSearchActionType.DECLINE_SAVED_SEARCH_REQUEST,
  id,
  name,
  contactId,
});

export interface DeclineSavedSearchFailAction {
  type: SavedSearchActionType.DECLINE_SAVED_SEARCH_FAIL;
  error: string;
}

export const declineSavedSearchFail = (
  error: string
): DeclineSavedSearchFailAction => ({
  type: SavedSearchActionType.DECLINE_SAVED_SEARCH_FAIL,
  error,
});

export interface DeclineSavedSearchSuccessAction {
  type: SavedSearchActionType.DECLINE_SAVED_SEARCH_SUCCESS;
  id: string;
}

export const declineSavedSearchSuccess = (
  id: string
): DeclineSavedSearchSuccessAction => ({
  type: SavedSearchActionType.DECLINE_SAVED_SEARCH_SUCCESS,
  id,
});

export interface JoinSavedSearchRequestAction {
  type: SavedSearchActionType.JOIN_SAVED_SEARCH_REQUEST;
  inviteCode: string;
  cb?: () => unknown;
}

export const joinSavedSearchRequest = (
  inviteCode: string,
  cb?: () => unknown
): JoinSavedSearchRequestAction => ({
  type: SavedSearchActionType.JOIN_SAVED_SEARCH_REQUEST,
  inviteCode,
  cb,
});

export interface JoinSavedSearchAction {
  type: SavedSearchActionType.JOIN_SAVED_SEARCH;
}

export const joinSavedSearch = (): JoinSavedSearchAction => ({
  type: SavedSearchActionType.JOIN_SAVED_SEARCH,
});

export interface JoinSavedSearchFailAction {
  type: SavedSearchActionType.JOIN_SAVED_FAIL;
  error: string;
}

export const joinSavedSearchFail = (
  error: string
): JoinSavedSearchFailAction => ({
  type: SavedSearchActionType.JOIN_SAVED_FAIL,
  error,
});

export interface SendSearchResultsAction {
  type: SavedSearchActionType.SEND_SEARCH_RESULT;
  userId: string;
  savedSearchId: string;
  owner?: string;
}

export const sendSearchResults = (
  userId: string,
  savedSearchId: string,
  owner?: string
): SendSearchResultsAction => ({
  type: SavedSearchActionType.SEND_SEARCH_RESULT,
  userId,
  savedSearchId,
  owner,
});

export interface SendSearchResultsSuccessAction {
  type: SavedSearchActionType.SEND_SEARCH_RESULT_SUCCESS;
}

export const sendSearchResultsSuccess = (): SendSearchResultsSuccessAction => ({
  type: SavedSearchActionType.SEND_SEARCH_RESULT_SUCCESS,
});

export interface SendSearchResultsFailAction {
  type: SavedSearchActionType.SEND_SEARCH_RESULT_FAIL;
}

export const sendSearchResultsFail = (): SendSearchResultsFailAction => ({
  type: SavedSearchActionType.SEND_SEARCH_RESULT_FAIL,
});

export interface ShareSavedSearchAction {
  type: SavedSearchActionType.SHARE_SAVED_SEARCH;
  collaborators: Collaborator[];
}

export const shareSavedSearch = (
  collaborators: Collaborator[]
): ShareSavedSearchAction => ({
  type: SavedSearchActionType.SHARE_SAVED_SEARCH,
  collaborators,
});

export interface UnshareSavedSearchRequestAction {
  type: SavedSearchActionType.UNSHARE_SAVED_SEARCH_REQUEST;
  id: string;
  collaboratorIds: string[];
}

export const unshareSavedSearchRequest = (
  id: string,
  collaboratorIds: string[]
): UnshareSavedSearchRequestAction => ({
  type: SavedSearchActionType.UNSHARE_SAVED_SEARCH_REQUEST,
  id,
  collaboratorIds,
});

export interface UnshareSavedSearchAction {
  type: SavedSearchActionType.UNSHARE_SAVED_SEARCH;
  collaboratorIds: string[];
}

export const unshareSavedSearch = (
  collaboratorIds: string[]
): UnshareSavedSearchAction => ({
  type: SavedSearchActionType.UNSHARE_SAVED_SEARCH,
  collaboratorIds,
});

export interface ShareSavedSearchRequestAction {
  type: SavedSearchActionType.SHARE_SAVED_SEARCH_REQUEST;
  id: string;
  collaboratorEmails: string[];
}

export const shareSavedSearchRequest = (
  id: string,
  collaboratorEmails: string[]
): ShareSavedSearchRequestAction => ({
  type: SavedSearchActionType.SHARE_SAVED_SEARCH_REQUEST,
  id,
  collaboratorEmails,
});

export interface SaveSearchRequestAction {
  type: SavedSearchActionType.CREATE_SAVED_SEARCH_REQUEST;
  body: CreateUpdateSaveSearchBody;
}

export const saveSearchRequest = (
  body: CreateUpdateSaveSearchBody
): SaveSearchRequestAction => ({
  type: SavedSearchActionType.CREATE_SAVED_SEARCH_REQUEST,
  body,
});

export interface SaveSearchFailAction {
  type: SavedSearchActionType.CREATE_SAVED_SEARCH_FAIL;
  error: string;
}

export const saveSearchFail = (error: string): SaveSearchFailAction => ({
  type: SavedSearchActionType.CREATE_SAVED_SEARCH_FAIL,
  error,
});

export interface UpdateSearchRequestAction {
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH_SETTINGS_REQUEST;
  body: CreateUpdateSaveSearchBody;
}

export const updateSearchRequest = (
  body: CreateUpdateSaveSearchBody
): UpdateSearchRequestAction => ({
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH_SETTINGS_REQUEST,
  body,
});

export interface UpdateSearchFailAction {
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH_SETTINGS_FAIL;
  error: string;
}

export const updateSearchFail = (error: string): UpdateSearchFailAction => ({
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH_SETTINGS_FAIL,
  error,
});

export interface FetchSavedSearchAction {
  type: SavedSearchActionType.FETCH_SAVED_SEARCH;
  id: string;
  shouldGetListings: boolean;
  injectedFilters: Partial<FiltersState>;
  contactId?: string;
}

export const fetchSavedSearch = (
  id: string,
  shouldGetListings = true,
  injectedFilters: Partial<FiltersState> = {},
  contactId?: string
  // eslint-disable-next-line max-params
): FetchSavedSearchAction => ({
  type: SavedSearchActionType.FETCH_SAVED_SEARCH,
  id,
  shouldGetListings,
  injectedFilters,
  contactId,
});

export interface FetchSavedSearchFailAction {
  type: SavedSearchActionType.FETCH_SAVED_SEARCH_FAIL;
  error: string;
}

export const fetchSavedSearchFail = (
  error: string
): FetchSavedSearchFailAction => ({
  type: SavedSearchActionType.FETCH_SAVED_SEARCH_FAIL,
  error,
});

export interface FetchSavedSearchesAction {
  type: SavedSearchActionType.FETCH_SAVED_SEARCHES;
  userId?: string;
  silentUpdate?: boolean;
  urlFilters?: Partial<SavedSearchFilters>;
}

export const fetchSavedSearches = ({
  userId,
  silentUpdate = false,
  urlFilters = {},
}: {
  userId?: string;
  silentUpdate?: boolean;
  urlFilters?: Partial<SavedSearchFilters>;
} = {}): FetchSavedSearchesAction => ({
  type: SavedSearchActionType.FETCH_SAVED_SEARCHES,
  userId,
  silentUpdate,
  urlFilters,
});

export interface FetchSavedSearchesFailAction {
  type: SavedSearchActionType.FETCH_SAVED_SEARCHES_FAIL;
  error: string;
}

export const fetchSavedSearchesFail = (
  error: string
): FetchSavedSearchesFailAction => ({
  type: SavedSearchActionType.FETCH_SAVED_SEARCHES_FAIL,
  error,
});

export interface SetSearchAction {
  type: SavedSearchActionType.SET_SAVED_SEARCH;
  searchData: SavedSearch;
}

export const setSearch = (searchData: SavedSearch): SetSearchAction => ({
  type: SavedSearchActionType.SET_SAVED_SEARCH,
  searchData,
});

export interface SetSavedSearchesAction {
  type: SavedSearchActionType.SET_SAVED_SEARCHES;
  searches: SavedSearch[];
  total: number;
}

export const setSavedSearches = (
  searches: SavedSearch[],
  total: number
): SetSavedSearchesAction => ({
  type: SavedSearchActionType.SET_SAVED_SEARCHES,
  searches,
  total,
});

export interface ResetSearchAction {
  type: SavedSearchActionType.RESET_SAVED_SEARCH;
}

export const resetSearch = (): ResetSearchAction => ({
  type: SavedSearchActionType.RESET_SAVED_SEARCH,
});

export interface RevertSearchAction {
  type: SavedSearchActionType.REVERT_SAVED_SEARCH;
  savedSearch: SavedSearch;
}

export const revertSavedSearch = (
  savedSearch: SavedSearch
): RevertSearchAction => ({
  type: SavedSearchActionType.REVERT_SAVED_SEARCH,
  savedSearch,
});

export interface SaveSearchAsNewRequestAction {
  type: SavedSearchActionType.SAVE_SEARCH_AS_NEW_REQUEST;
  body: CreateUpdateSaveSearchBody;
}

export const saveSearchAsNewRequest = (
  body: CreateUpdateSaveSearchBody
): SaveSearchAsNewRequestAction => ({
  type: SavedSearchActionType.SAVE_SEARCH_AS_NEW_REQUEST,
  body,
});

export interface SaveSearchFromViewedRequestAction {
  type: SavedSearchActionType.SAVE_SEARCH_FROM_VIEWED_REQUEST;
  body: CreateUpdateSaveSearchBody;
}

export const saveSearchFromViewedRequest = (
  body: CreateUpdateSaveSearchBody
): SaveSearchFromViewedRequestAction => ({
  type: SavedSearchActionType.SAVE_SEARCH_FROM_VIEWED_REQUEST,
  body,
});

export interface SaveSearchAsNewFailAction {
  type: SavedSearchActionType.SAVE_SEARCH_AS_NEW_FAIL;
  error: string;
}

export const saveSearchAsNewFail = (
  error: string
): SaveSearchAsNewFailAction => ({
  type: SavedSearchActionType.SAVE_SEARCH_AS_NEW_FAIL,
  error,
});

export interface UpdateSavedSearchRequestAction {
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH_REQUEST;
  body: CreateUpdateSaveSearchBody;
}

export const updateSavedSearchRequest = (
  body: CreateUpdateSaveSearchBody
): UpdateSavedSearchRequestAction => ({
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH_REQUEST,
  body,
});

export interface UpdateSavedSearchAction {
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH;
  savedSearchData: Partial<SavedSearch>;
}

export const updateSavedSearch = (
  savedSearchData: Partial<SavedSearch>
): UpdateSavedSearchAction => ({
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH,
  savedSearchData,
});

export interface UpdateSavedSearchFailAction {
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH_FAIL;
  error: unknown;
}

export const updateSavedSearchFail = (
  error: string
): UpdateSavedSearchFailAction => ({
  type: SavedSearchActionType.UPDATE_SAVED_SEARCH_FAIL,
  error,
});

export interface ResetSavedSearchesAction {
  type: SavedSearchActionType.RESET_SAVED_SEARCHES;
}

export const resetSavedSearches = (): ResetSavedSearchesAction => ({
  type: SavedSearchActionType.RESET_SAVED_SEARCHES,
});

export interface SortSearchesAction {
  type: SavedSearchActionType.SORT_SAVED_SEARCHES;
  value: SortFilterValue;
}

export const sortSearches = (value: SortFilterValue): SortSearchesAction => ({
  type: SavedSearchActionType.SORT_SAVED_SEARCHES,
  value,
});

export interface FetchViewedSearchesAction {
  type: SavedSearchActionType.FETCH_VIEWED_SEARCHES;
  userId: string;
  urlFilters: Partial<SavedSearchFilters>;
}

export function fetchViewedSearches({
  userId,
  urlFilters,
}: {
  userId: string;
  urlFilters: Partial<SavedSearchFilters>;
}): FetchViewedSearchesAction {
  return {
    type: SavedSearchActionType.FETCH_VIEWED_SEARCHES,
    userId,
    urlFilters,
  };
}

export interface FetchViewedSearchesRequestAction {
  type: SavedSearchActionType.FETCH_VIEWED_SEARCHES_REQUEST;
}

export function fetchViewedSearchesRequest(): FetchViewedSearchesRequestAction {
  return { type: SavedSearchActionType.FETCH_VIEWED_SEARCHES_REQUEST };
}

export interface FetchViewedSearchesSuccessAction {
  type: SavedSearchActionType.FETCH_VIEWED_SEARCHES_SUCCESS;
  viewedSearches: Paginated<ViewedSearch>;
}

export function fetchViewedSearchesSuccess(
  viewedSearches: Paginated<ViewedSearch>
): FetchViewedSearchesSuccessAction {
  return {
    type: SavedSearchActionType.FETCH_VIEWED_SEARCHES_SUCCESS,
    viewedSearches,
  };
}

export interface FetchViewedSearchesFailAction {
  type: SavedSearchActionType.FETCH_VIEWED_SEARCHES_FAIL;
  error: string;
}

export function fetchViewedSearchesFail(
  error: string
): FetchViewedSearchesFailAction {
  return { type: SavedSearchActionType.FETCH_VIEWED_SEARCHES_FAIL, error };
}

export interface ResetViewedSearchesAction {
  type: SavedSearchActionType.RESET_VIEWED_SEARCHES;
}

export function resetViewedSearches(): ResetViewedSearchesAction {
  return { type: SavedSearchActionType.RESET_VIEWED_SEARCHES };
}

export interface SortViewedSearchesAction {
  type: SavedSearchActionType.SORT_VIEWED_SEARCHES;
  sortType: SortOrder;
}

export function sortViewedSearches(
  sortType: SortOrder
): SortViewedSearchesAction {
  return { type: SavedSearchActionType.SORT_VIEWED_SEARCHES, sortType };
}

export interface FilterViewedSearchesByPeriodAction {
  type: SavedSearchActionType.FILTER_VIEWED_SEARCHES_BY_PERIOD;
  period: number;
}

export function filterViewedSearchesByPeriod(
  period: number
): FilterViewedSearchesByPeriodAction {
  return {
    type: SavedSearchActionType.FILTER_VIEWED_SEARCHES_BY_PERIOD,
    period,
  };
}

export interface ChangeViewedSearchesPageAction {
  type: SavedSearchActionType.CHANGE_VIEWED_SEARCHES_PAGE;
  page: number;
}

export function changeViewedSearchesPage(
  page: number
): ChangeViewedSearchesPageAction {
  return {
    type: SavedSearchActionType.CHANGE_VIEWED_SEARCHES_PAGE,
    page,
  };
}

export interface ChangeSavedSearchesPageAction {
  type: SavedSearchActionType.CHANGE_SAVED_SEARCHES_PAGE;
  page: number;
}

export function changeSavedSearchesPage(
  page: number
): ChangeSavedSearchesPageAction {
  return {
    type: SavedSearchActionType.CHANGE_SAVED_SEARCHES_PAGE,
    page,
  };
}

export interface FetchSavedSearchesInvitationsAction {
  type: SavedSearchActionType.FETCH_SAVED_SEARCHES_INVITATIONS;
}

export function fetchSavedSearchesInvitations(): FetchSavedSearchesInvitationsAction {
  return {
    type: SavedSearchActionType.FETCH_SAVED_SEARCHES_INVITATIONS,
  };
}

export interface UpdateSavedSearchesInvitations {
  type: SavedSearchActionType.UPDATE_SAVED_SEARCHES_INVITATIONS;
  invitations: Invitation[];
}

export function updateSavedSearchesInvitations(
  invitations: Invitation[]
): UpdateSavedSearchesInvitations {
  return {
    type: SavedSearchActionType.UPDATE_SAVED_SEARCHES_INVITATIONS,
    invitations,
  };
}

export interface AcceptSavedSearchInvitation {
  type: SavedSearchActionType.ACCEPT_SAVED_SEARCH_INVITATION;
  inviteCode: string;
}

export function acceptSavedSearchInvitation(
  inviteCode: string
): AcceptSavedSearchInvitation {
  return {
    type: SavedSearchActionType.ACCEPT_SAVED_SEARCH_INVITATION,
    inviteCode,
  };
}

export interface DeclineSavedSearchInvitation {
  type: SavedSearchActionType.DECLINE_SAVED_SEARCH_INVITATION;
  savedSearchId: number;
}

export function declineSavedSearchInvitation(
  savedSearchId: number
): DeclineSavedSearchInvitation {
  return {
    type: SavedSearchActionType.DECLINE_SAVED_SEARCH_INVITATION,
    savedSearchId,
  };
}

export interface SendSavedSearchInvitationReminderAction {
  type: SavedSearchActionType.SEND_SAVED_SEARCH_INVITATION_REMINDER;
  id: string;
  collaboratorId: string;
}

export const sendSavedSearchInvitationReminder = (
  id: string,
  collaboratorId: string
): SendSavedSearchInvitationReminderAction => ({
  type: SavedSearchActionType.SEND_SAVED_SEARCH_INVITATION_REMINDER,
  id,
  collaboratorId,
});

export type SavedSearchAction =
  | ChangeViewedSearchesPageAction
  | DeclineSavedSearchFailAction
  | DeclineSavedSearchRequestAction
  | DeclineSavedSearchSuccessAction
  | FetchSavedSearchAction
  | FetchSavedSearchesAction
  | FetchSavedSearchesFailAction
  | FetchSavedSearchFailAction
  | FetchViewedSearchesAction
  | FetchViewedSearchesFailAction
  | FetchViewedSearchesRequestAction
  | FetchViewedSearchesSuccessAction
  | FilterViewedSearchesByPeriodAction
  | JoinSavedSearchAction
  | JoinSavedSearchFailAction
  | JoinSavedSearchRequestAction
  | RemoveSavedSearchFailAction
  | RemoveSavedSearchFromStoreAction
  | RemoveSavedSearchRequestAction
  | RemoveSavedSearchSuccessAction
  | ResetSavedSearchesAction
  | ResetSearchAction
  | ResetViewedSearchesAction
  | SaveSearchAsNewFailAction
  | SaveSearchAsNewRequestAction
  | SaveSearchFailAction
  | SaveSearchRequestAction
  | SendSearchResultsSuccessAction
  | SetSavedSearchesAction
  | SetSearchAction
  | ShareSavedSearchAction
  | ShareSavedSearchRequestAction
  | SortSearchesAction
  | SortViewedSearchesAction
  | StartDeclineSavedSearchAction
  | StartRemoveSavedSearchAction
  | UndoRemoveSavedSearchAction
  | UnshareSavedSearchAction
  | UnshareSavedSearchRequestAction
  | UpdateSavedSearchAction
  | UpdateSavedSearchFailAction
  | UpdateSavedSearchRequestAction
  | UpdateSearchFailAction
  | UpdateSearchRequestAction
  | FetchSavedSearchesInvitationsAction
  | UpdateSavedSearchesInvitations
  | AcceptSavedSearchInvitation
  | DeclineSavedSearchInvitation
  | SendSavedSearchInvitationReminderAction
  | ChangeSavedSearchesPageAction;
