import { SWRResponse } from 'swr';

import { TheUnion } from 'shared/helpers/fp/types';

/**
 * Wraps fetcher function and prepends key to an arguments list
 *
 * @example
 * const fetchUser = async (id: string): Promise<User> => {
 *   return fetch('/users/${id}').then(res => res.json())
 * }
 * const userFetcher = makeFetcher(fetchUser)
 *
 * function useUser(id: string) {
 *   const key = ['user-by-id', id]
 *   const { data, error } = useSWR(key, userFetcher)
 *   return [user, error] as const
 * }
 */
export function makeFetcher<P extends readonly any[], A>(
  fetcher: (...args: P) => A
) {
  return (_key: string, ...args: P) => fetcher(...args);
}

/**
 * An identity function for a type-safe key for a specific fetcher
 */
export function getFetcherKey<P extends readonly any[]>(
  fetcher: (...args: [...P]) => any,
  key: [...P] | null
): [...P] | null {
  return key;
}

type Ok<Data> = { status: 'ok'; data: Data };
type Fail<Error> = { status: 'fail'; error: Error };
type Pending = { status: 'pending' };

type SWRFields<Data, Error> = Omit<SWRResponse<Data, Error>, 'data' | 'error'>;

type SWRResponseWrapper<Data, Error> = SWRFields<Data, Error> &
  TheUnion<Ok<Data> | Fail<Error> | Pending>;

export function wrapSWRResponse<Data, Error>(
  res: SWRResponse<Data, Error>
): SWRResponseWrapper<Data, Error> {
  const { data, error, ...rest } = res;
  if (data) {
    return { ...rest, status: 'ok', data };
  }
  if (error) {
    return { ...rest, status: 'fail', error };
  }
  return { ...rest, status: 'pending' };
}
