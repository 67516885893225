import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as IconClose } from 'shared/icons/shared/24x24/cancel-bold.svg';
import { zIndex } from 'shared/styles/z-indexes';
import { media, useIsMobile } from 'styled-system/responsive';

const toasterRoot =
  document.getElementById('toaster-root') || document.createElement('div');

export const RealToaster = styled(ToastContainer)`
  &.Toastify__toast-container {
    width: auto;
    z-index: ${zIndex.HOTJAR};

    ${media.sm(css`
      width: 100%;
      margin: 0;
      padding: 0;

      &--bottom-center {
        bottom: 0;
      }
    `)};

    &--top-center {
      margin-left: 0;
      transform: translateX(-50%);
    }
  }
  .Toastify__toast {
    padding: 16px 16px;
    min-height: 56px;
    min-width: 375px;
    max-width: 440px;
    ${media.sm(css`
      min-width: initial;
      max-width: initial;
      margin-bottom: 0;
    `)};
  }
  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.success};
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.successDeep};
    }
  }
  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.error};
  }
  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.warning};
  }
  .Toastify__toast-body {
    font-size: 14px;
    font-weight: bold;
  }
  .Toastify__close-button {
    flex-shrink: 0;
    align-self: center;
    margin-left: 16px;
  }
`;

const Close = ({ closeToast }: { closeToast?: () => unknown }) => (
  <IconClose
    fill="#fff"
    width={16}
    height={16}
    className="Toastify__close-button"
    onClick={closeToast}
    role="button"
    tabIndex={0}
    aria-label="Close toaster"
  />
);

const toasterDefaultProps = {
  hideProgressBar: true,
  closeOnClick: false,
  draggable: false,
  closeButton: <Close />,
  transition: Slide,
  autoClose: 5000,
};

export const desktopProps = {
  ...toasterDefaultProps,
  position: toast.POSITION.TOP_CENTER,
};

export const mobileProps = {
  ...toasterDefaultProps,
  position: toast.POSITION.BOTTOM_CENTER,
};

const Toaster = () => {
  const isMobile = useIsMobile();
  const toasterProps = isMobile ? mobileProps : desktopProps;

  return ReactDOM.createPortal(<RealToaster {...toasterProps} />, toasterRoot);
};

export default Toaster;
