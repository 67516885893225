import React from 'react';
import styled, { css } from 'styled-components/macro';

import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import { not } from 'shared/helpers/boolean';
import { Flex } from 'styled-system/components';
import { StyledSystemInput } from 'styled-system/lib/types';
import { media } from 'styled-system/responsive';

export const CheckboxInput = styled.input<{
  $disabled?: boolean;
  error?: boolean;
}>`
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label:hover:before {
    border: 1px solid
      ${({ $disabled, theme }) => ($disabled ? theme.divider : theme.text2)};
  }

  & + label::before {
    content: '';
    display: inline-block;
    height: 14px;
    width: 14px;
    cursor: pointer;
    border-radius: 1px;
    border: 1px solid
      ${({ theme, error }) => (error ? theme.error : theme.border)};
    background-repeat: no-repeat;
    background-position: center center;
    background-color: ${({ theme, error }) => error && theme.colors.errorBg};

    ${({ $disabled }) =>
      $disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.5;
        border-color: ${({ theme }) => theme.divider};
        background-color: ${({ theme }) => theme.secondaryBackground};

        &:hover {
          border-color: ${({ theme }) => theme.divider};
        }
      `}
  }

  &:focus-visible + label::before {
    transition: box-shadow 0.2s ease 0s;
    box-shadow: ${({ theme }) => theme.boxShadowOutline};
    border-color: ${({ theme }) => theme.secondary};
  }

  &:checked + label::before {
    background-color: ${({ theme }) => theme.secondary};
    border-color: ${({ theme }) => theme.secondary};
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+MzFBODgzQUMtRjkxMC00MUQzLUJBMjktMUI0MzY4RjJGRkZBPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIHNrZXRjaHRvb2wuPC9kZXNjPiAgICA8ZGVmcz4gICAgICAgIDxwYXRoIGQ9Ik0xMS4yNTk0MTMxLDMuMzc2NTk3MTMgQzExLjYwMzcyOTksMi45NDQ3ODE5MSAxMi4yMzI5MDkyLDIuODczODUwNDMgMTIuNjY0NzI0NSwzLjIxODE2NzIzIEMxMy4wOTY1Mzk3LDMuNTYyNDg0MDMgMTMuMTY3NDcxMiw0LjE5MTY2MzM1IDEyLjgyMzE1NDQsNC42MjM0Nzg1NyBMNi40NjMzNjY4NywxMi41OTk0MjY5IEwzLjIyNDY5NTksOC42MjM0MDIzMiBDMi44NzU5MDA3LDguMTk1MTk2MzQgMi45NDAyNzU4Niw3LjU2NTMxMjQ0IDMuMzY4NDgxODQsNy4yMTY1MTcyNSBDMy43OTY2ODc4MSw2Ljg2NzcyMjA1IDQuNDI2NTcxNzIsNi45MzIwOTcyMSA0Ljc3NTM2NjkxLDcuMzYwMzAzMTkgTDYuNDQ2NzcwMjEsOS40MTIyMzc2NyBMMTEuMjU5NDEzMSwzLjM3NjU5NzEzIFoiIGlkPSJwYXRoLTEiPjwvcGF0aD4gICAgPC9kZWZzPiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iaWNvbi8xNi9zZWxlY3QiPiAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4gICAgICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4gICAgICAgICAgICA8L21hc2s+ICAgICAgICAgICAgPHVzZSBpZD0iUGF0aC00IiBmaWxsPSIjMDA4NUFEIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPiAgICAgICAgICAgIDxnIGlkPSJjb2xvci9ibHVlIiBtYXNrPSJ1cmwoI21hc2stMikiIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUtOC1Db3B5LTciIHg9IjAiIHk9IjAiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PC9yZWN0PiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
  }
`;

export const CheckboxLabelWrapper = styled.label`
  display: flex;
  align-items: flex-start;
`;

export const CheckboxLabelText = styled.span<{
  disabled: boolean;
  error: boolean;
}>`
  font-size: 14px;
  text-align: left;
  color: ${({ disabled, error, theme }) =>
    error ? theme.error : disabled ? theme.disabled : theme.text2};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${media.sm(css`
    white-space: pre-wrap;
  `)}
`;

export const HintText = styled.span`
  font-size: 10px;
  line-height: 1.4;
  color: ${({ theme }) => theme.text3};
  max-width: 130px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: -1px 0 0 8px;
`;

export interface CheckboxComponentProps {
  sx?: StyledSystemInput;
  labelText?: string;
  labelCmp?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  // ariaLabel prop should be passed if no labelText
  ariaLabel?: string;
  hintText?: string;
  error?: boolean;
  styles?: { wrapper?: React.CSSProperties; label?: React.CSSProperties };
  marginBottom?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCheckboxAuthLock?: boolean;
  name?: string;
  value?: any;
}

const Checkbox = (props: CheckboxComponentProps) => {
  const {
    onChange,
    disabled,
    checked,
    ariaLabel,
    labelText,
    labelCmp,
    error,
    hintText,
    styles,
    marginBottom,
    isCheckboxAuthLock,
    name,
    value,
    sx,
  } = props;

  const airaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();

  const chackboxId = labelText || ariaLabel;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (not(disabled)) {
      onChange?.(event);
      airaLiveAnnouncerCtx.announce(checked ? 'unchecked' : 'checked');
    }
  };

  return (
    <Flex mb={marginBottom} sx={sx}>
      <CheckboxInput
        data-testid="checkbox-test"
        aria-label={chackboxId}
        type="checkbox"
        checked={checked}
        $disabled={disabled}
        onChange={handleChange}
        id={chackboxId}
        name={name}
        aria-checked={checked}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        aria-hidden={disabled}
        value={value}
        error={error}
      />
      <CheckboxLabelWrapper htmlFor={chackboxId} style={styles?.wrapper}>
        <Column>
          <CheckboxLabelText
            disabled={disabled}
            error={error}
            style={styles?.label}
            aria-hidden={not(isCheckboxAuthLock)}
          >
            {labelText || labelCmp}
          </CheckboxLabelText>
          {hintText && <HintText>{hintText}</HintText>}
        </Column>
      </CheckboxLabelWrapper>
    </Flex>
  );
};

export default Checkbox;
