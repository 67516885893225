import { useCallback, useState } from 'react';

/** You can use this hook in case you need to specify node in dependencies array.
 * For such cases you can't use ref in deps array, because mutations won't trigger
 * a render, therefore, won't trigger the useEffect.
 **/

export function useTrackedRef<T = null>(
  onNodeMounted?: (node: T) => void
): [(node: T) => void, T] {
  const [trackedNode, setTrackedNode] = useState(null);
  const ref = useCallback(
    node => {
      if (node !== null) {
        setTrackedNode(node);
        onNodeMounted?.(node);
      }
    },
    [onNodeMounted]
  );
  return [ref, trackedNode];
}
