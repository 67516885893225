import { FiltersState } from 'filters/filtersTypes';
import { MultiSelectOption } from 'shared/components/MultiSelect/MultiSelect';
import { SelectOption } from 'shared/components/Select/SelectTypes';

const HILL_COUNTRY_VIEW_LABEL = 'Hill Country';
const PARK_GREENBELT_VIEW_LABEL = 'Park';

export const SELECT_MIN = 'select min';
export const SELECT_MAX = 'select max';

const NO_PREFERENCE = 'No Preference';

export enum MlsStatus {
  Private = 'Private',
  Active = 'Active',
  Empty = '',
  Expired = 'Expired',
  Leased = 'Leased',
  ListedOnMls = 'Listed on Mls',
  Pending = 'Pending',
  PendingTakingBackups = 'Pending - Taking Backups',
  Sold = 'Sold',
  Withdrawn = 'Withdrawn',
}

export const OffMarketMlsStatuses = [
  MlsStatus.Expired,
  MlsStatus.ListedOnMls,
  MlsStatus.Sold,
  MlsStatus.Withdrawn,
];

export const OnMarketMlsStatuses = [
  MlsStatus.Private,
  MlsStatus.Active,
  MlsStatus.Pending,
  MlsStatus.PendingTakingBackups,
];

export enum FilterKey {
  MlsStatus = 'mlsStatus',
  HomeownersAssoc = 'homeownersAssoc',
  WaterBodyName = 'waterBodyName',
  TypeOfViews = 'views',
  WaterFront = 'waterfront',
  Keyword = 'keyword',
  SwimmingPool = 'swimmingPool',
  FencedYard = 'fencedYard',
  BacksToGolfCourse = 'backsToGolfCourse',
  BacksToGreenbelt = 'backsToGreenbelt',
  PrivateDock = 'privateDock',
  GatedCommunity = 'gatedCommunity',
  CommunityPool = 'communityPool',
  Playground = 'playground',
  DogPark = 'dogPark',
  FitnessCenter = 'fitnessCenter',
  GolfCourse = 'golfCourse',
  SportCourt = 'sportCourt',
  TennisCourts = 'tennisCourts',
  HorsesAllowed = 'horsesAllowed',
  AirplaneFacilities = 'airplaneFacilities',
  LakeAccess = 'lakeAccess',
  HikingTrails = 'hikingTrails',
  ClubHouse = 'clubHouse',
  BoatDock = 'boatDock',
  Park = 'park',
  GuestQuarters = 'guestQuarters',
  SingleFamilyResidence = 'singleFamilyResidence',
  Condo = 'condominium',
  Townhouse = 'townhouse',
  Lot = 'lot',
  Farm = 'farm',
  MultiFamily = 'multiFamily',
  CommercialSale = 'commercialSale',
  MaxPrice = 'maxPrice',
  MinPrice = 'minPrice',
  MaxBedrooms = 'maxBedrooms',
  MinBedrooms = 'minBedrooms',
  Workshop = 'workshop',
  EnergyEfficient = 'energyEfficient',
  WineRoom = 'wineRoom',
  HistoricDesign = 'historicDesign',
  OfficeStudy = 'officeStudy',
  AccessibilityFeatures = 'accessibilityFeatures',
  MediaRoom = 'mediaRoom',
  Fireplace = 'fireplace',
  GameRoom = 'gameRoom',
  ExerciseRoom = 'exerciseRoom',
  MasterOnMain = 'masterOnMain',
  minBathrooms = 'minBathrooms',
  MinYearBuilt = 'minYearBuilt',
  MaxYearBuilt = 'maxYearBuilt',
  MinGarageSpaces = 'minGarageSpaces',
  MaxGarageSpaces = 'maxGarageSpaces',
  MinStories = 'minStories',
  MaxStories = 'maxStories',
  OurListingsOnly = 'ourListingsOnly',
  OpenHouseOnly = 'openHouseOnly',
  OpenHouse = 'openHouse',
  elementarySchoolRating = 'elementarySchoolRating',
  middleSchoolRating = 'middleSchoolRating',
  highSchoolRating = 'highSchoolRating',
}

export enum ListingViewsFilters {
  CityView = 'City',
  GolfCourseView = 'Golf Course',
  HillCountryView = 'Hills',
  Lake = 'Lake',
  PanoramicView = 'Panoramic',
  GreenbeltView = 'Park/Greenbelt',
  River = 'River',
}

export enum ListingWaterfrontFilters {
  BlancoRiver = 'Blanco River',
  BuchananLake = 'Buchanan Lake',
  CanyonLake = 'Canyon Lake',
  ColoradoRiver = 'Colorado River',
  ComalRiver = 'Comal River',
  GuadalupeRiver = 'Guadalupe River',
  InksLake = 'Inks Lake',
  LakeAustin = 'Lake Austin',
  LakeDunlap = 'Lake Dunlap',
  LakeLBJ = 'Lake LBJ',
  LakeMarbleFalls = 'Lake Marble Falls',
  LakeMcQueeney = 'Lake McQueeney',
  LakePlacid = 'Lake Placid',
  LakeSeguin = 'Lake Seguin',
  LakeTravis = 'Lake Travis',
  MedinaLake = 'Medina Lake',
  PedernalesRiver = 'Pedernales River',
  SanMarcosRiver = 'San Marcos River',
  SanGabrielRiver = 'San Gabriel River',
}

export enum MainFiltersName {
  Price = 'Price',
  Bathrooms = 'Bathrooms',
  Bedrooms = 'Bedrooms',
}

const {
  CityView,
  GolfCourseView,
  HillCountryView,
  Lake,
  PanoramicView,
  GreenbeltView,
  River,
} = ListingViewsFilters;

const {
  BlancoRiver,
  BuchananLake,
  CanyonLake,
  ColoradoRiver,
  ComalRiver,
  GuadalupeRiver,
  InksLake,
  LakeAustin,
  LakeDunlap,
  LakeLBJ,
  LakeMarbleFalls,
  LakeMcQueeney,
  LakePlacid,
  LakeSeguin,
  LakeTravis,
  MedinaLake,
  PedernalesRiver,
  SanMarcosRiver,
  SanGabrielRiver,
} = ListingWaterfrontFilters;

export const viewsOptions: ReadonlyArray<
  MultiSelectOption<ListingViewsFilters>
> = [
  { value: CityView, label: CityView },
  { value: GolfCourseView, label: GolfCourseView },
  { value: HillCountryView, label: HILL_COUNTRY_VIEW_LABEL },
  { value: Lake, label: Lake },
  { value: PanoramicView, label: PanoramicView },
  { value: GreenbeltView, label: GreenbeltView },
  { value: River, label: River },
];

export const viewsOptionsForSeo = {
  [CityView]: CityView,
  [GreenbeltView]: PARK_GREENBELT_VIEW_LABEL,
  [GolfCourseView]: GolfCourseView,
  [HillCountryView]: HILL_COUNTRY_VIEW_LABEL,
  [Lake]: Lake,
  [PanoramicView]: PanoramicView,
  [River]: River,
};

export const bodyOfWaterOptions: ReadonlyArray<
  MultiSelectOption<ListingWaterfrontFilters>
> = [
  { value: BlancoRiver, label: BlancoRiver },
  { value: BuchananLake, label: BuchananLake },
  { value: CanyonLake, label: CanyonLake },
  { value: ColoradoRiver, label: ColoradoRiver },
  { value: ComalRiver, label: ComalRiver },
  { value: GuadalupeRiver, label: GuadalupeRiver },
  { value: InksLake, label: InksLake },
  { value: LakeAustin, label: LakeAustin },
  { value: LakeDunlap, label: LakeDunlap },
  { value: LakeLBJ, label: LakeLBJ },
  { value: LakeMarbleFalls, label: LakeMarbleFalls },
  { value: LakeMcQueeney, label: LakeMcQueeney },
  { value: LakePlacid, label: LakePlacid },
  { value: LakeSeguin, label: LakeSeguin },
  { value: LakeTravis, label: LakeTravis },
  { value: MedinaLake, label: MedinaLake },
  { value: PedernalesRiver, label: PedernalesRiver },
  { value: SanMarcosRiver, label: SanMarcosRiver },
  { value: SanGabrielRiver, label: SanGabrielRiver },
];

export const bodyOfWaterOptionsForSeo = {
  [BlancoRiver]: BlancoRiver,
  [BuchananLake]: BuchananLake,
  [CanyonLake]: CanyonLake,
  [ColoradoRiver]: ColoradoRiver,
  [ComalRiver]: ComalRiver,
  [GuadalupeRiver]: GuadalupeRiver,
  [InksLake]: InksLake,
  [LakeAustin]: LakeAustin,
  [LakeDunlap]: LakeDunlap,
  [LakeLBJ]: LakeLBJ,
  [LakeMarbleFalls]: LakeMarbleFalls,
  [LakeMcQueeney]: LakeMcQueeney,
  [LakePlacid]: LakePlacid,
  [LakeSeguin]: LakeSeguin,
  [LakeTravis]: LakeTravis,
  [MedinaLake]: MedinaLake,
  [PedernalesRiver]: PedernalesRiver,
  [SanMarcosRiver]: SanMarcosRiver,
  [SanGabrielRiver]: SanGabrielRiver,
};

export const timeframeOptions: Array<SelectOption<number>> = [
  { value: 0, label: NO_PREFERENCE },
  { value: 1, label: '1 Day' },
  { value: 7, label: '7 Days' },
  { value: 30, label: '30 Days' },
  { value: 90, label: '90 Days' },
  { value: 183, label: '6 Months' },
  { value: 365, label: '1 Year' },
  { value: 730, label: '2 Years' },
];

export const noMinOption: SelectOption<number> = { value: 0, label: 'No Min' };
export const noMaxOption: SelectOption<number> = { value: 0, label: 'No Max' };

export const daysOnOptions: Array<SelectOption<number>> = [
  { value: 0, label: NO_PREFERENCE },
  { value: 1, label: '1 Day' },
  { value: 7, label: '7 Days' },
  { value: 30, label: '30 Days' },
  { value: 90, label: '90 Days' },
];

export const bathsOptions: Array<SelectOption<number>> = [
  { value: 0, label: 'Any' },
  { value: 1, label: '1+' },
  { value: 2, label: '2+' },
  { value: 3, label: '3+' },
  { value: 4, label: '4+' },
  { value: 5, label: '5+' },
];

export const bedsOptions: Array<SelectOption<number>> = [
  { value: 0, label: '' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
];

export const minBedsOptions: Array<SelectOption<number>> = [
  noMinOption,
  ...bedsOptions.slice(1),
];

export const maxBedsOptions: Array<SelectOption<number>> = [
  noMaxOption,
  ...bedsOptions.slice(1),
];

export const lotSizeAcres: Array<SelectOption<number>> = [
  { value: 0.046, label: '2000 sq.ft' },
  { value: 0.092, label: '4500 sq.ft' },
  { value: 0.149, label: '6500 sq.ft' },
  { value: 0.184, label: '8000 sq.ft' },
  { value: 0.25, label: '0.25 acres' },
  { value: 0.5, label: '0.5 acres' },
  { value: 1, label: '1 acres' },
  { value: 2, label: '2 acres' },
  { value: 3, label: '3 acres' },
  { value: 4, label: '4 acres' },
  { value: 5, label: '5 acres' },
  { value: 10, label: '10 acres' },
  { value: 40, label: '40 acres' },
  { value: 100, label: '100 acres' },
];

export const rangeStoriesOptions: Array<SelectOption<number>> = [
  { value: 0, label: '' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3+' },
];

export const rangeGarageSpacesOptions: Array<SelectOption<number>> = [
  { value: 0, label: '' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4+' },
];

export const gsRating: Array<SelectOption<number>> = [
  { value: 0, label: NO_PREFERENCE },
  { value: 1, label: '1+' },
  { value: 2, label: '2+' },
  { value: 3, label: '3+' },
  { value: 4, label: '4+' },
  { value: 5, label: '5+' },
  { value: 6, label: '6+' },
  { value: 7, label: '7+' },
  { value: 8, label: '8+' },
  { value: 9, label: '9+' },
  { value: 10, label: '10' },
];

export const filtersLabels: Partial<{ [key in keyof FiltersState]: string }> = {
  townhouse: 'Townhouse',
  singleFamilyResidence: 'Home',
  condominium: 'Condo',
  lot: 'Lot',
  farm: 'Farm/Ranch',
  multiFamily: 'Multi-Family',
  commercialSale: 'Commercial',
  masterOnMain: 'Primary on Main',
  guestQuarters: 'Guest Quarters',
  fireplace: 'Fireplace',
  accessibilityFeatures: 'Accessibility Features',
  historicDesign: 'Historic Design',
  energyEfficient: 'Energy Efficient',
  exerciseRoom: 'Exercise Room',
  gameRoom: 'Game Room',
  mediaRoom: 'Media Room',
  officeStudy: 'Office/Study',
  wineRoom: 'Wine Room',
  workshop: 'Workshop',
  backsToGolfCourse: 'Backs To Golf Course',
  backsToGreenbelt: 'Backs To Greenbelt',
  swimmingPool: 'Swimming Pool',
  fencedYard: 'Fenced Yard',
  privateDock: 'Private Dock',
  gatedCommunity: 'Gated Community',
  communityPool: 'Community Pool',
  playground: 'Playground',
  dogPark: 'Dog Park',
  fitnessCenter: 'Fitness Center',
  golfCourse: 'Golf Course',
  sportCourt: 'Sport Court',
  tennisCourts: 'Tennis Courts',
  horsesAllowed: 'Horses Allowed',
  airplaneFacilities: 'Airplane Facilities',
  lakeAccess: 'Lake Access',
  hikingTrails: 'Hiking Trails',
  clubHouse: 'Club House',
  boatDock: 'Boat Dock',
  park: 'Park',
};

export const PropertyTypeKeys = {
  [FilterKey.SingleFamilyResidence]: FilterKey.SingleFamilyResidence,
  [FilterKey.Condo]: FilterKey.Condo,
  [FilterKey.Townhouse]: FilterKey.Townhouse,
  [FilterKey.Lot]: FilterKey.Lot,
  [FilterKey.Farm]: FilterKey.Farm,
  [FilterKey.MultiFamily]: FilterKey.MultiFamily,
  [FilterKey.CommercialSale]: FilterKey.CommercialSale,
};

export const minLotSizeAcres: Array<SelectOption<number>> = [
  noMinOption,
  ...lotSizeAcres,
];
export const maxLotSizeAcres: Array<SelectOption<number>> = [
  noMaxOption,
  ...lotSizeAcres,
];

export const AcrePerSq = 43560;
export const FILTER_CONTAINER_WIDTH = 441;

export const SORTING_FILTERS_TOOLTIP_CLASS_NAME = 'sorting-filter-tooltip';

export enum ListingsSource {
  MlsListings = 'AUS,SA',
  ExlusiveListings = 'EX',
}

export const TIME_FRAME_ONE_YEAR_VALUE = 365;

export const OPEN_HOUSE_DEFAULT_VALUE = 0;
export const OPEN_HOUSE_WEEK_VALUE = 7;
export const TIME_FRAME_DEFAULT_VALUE = 0;
