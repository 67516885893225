import { omit } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

import { isListingDetails } from 'adwords/helpers';
import { not, notEqual } from 'shared/helpers/boolean';

export const useIsRouteModal = () => {
  const location = useLocation();
  const history = useHistory();

  const modalMode = location.state?.modal;
  const previousLocation = useRef(location);

  useEffect(() => {
    if (!modalMode) {
      previousLocation.current = location;
    }
  }, [history.action, location, modalMode]);

  const isModal = useMemo(() => {
    return Boolean(
      modalMode &&
        notEqual(
          omit(previousLocation.current, ['state.modal', 'key']),
          omit(location, ['state.modal', 'key'])
        ) &&
        not(
          isListingDetails(location.pathname) &&
            isListingDetails(previousLocation.current.pathname)
        )
    );
  }, [location, modalMode]);

  return {
    previousLocation,
    isModal,
  };
};
