import { useField } from 'formik';
import styled, { css } from 'styled-components/macro';

import {
  FILTER_CONTAINER_WIDTH,
  maxLotSizeAcres,
  minLotSizeAcres,
  noMaxOption,
  noMinOption,
  rangeGarageSpacesOptions,
  rangeStoriesOptions,
  SELECT_MAX,
  SELECT_MIN,
} from 'filters/filtersContants';
import {
  filterMinMax,
  formatLotSize,
  getNumericValueForLotSize,
  getTitleValue,
  maxlivingarea,
  minlivingarea,
  validateMinMax,
  validateSelectInput,
} from 'filters/filtersHelpers';
import { $getSizeChanged } from 'filters/filtersSelectors';
import {
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  FiltersTitleValue,
} from 'filters/filtersStyled';
import { SharedFiltersProps } from 'filters/filtersTypes';
import { InputSelectField } from 'shared/components/formikFields/InputSelectField';
import { SelectField } from 'shared/components/formikFields/SelectField';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { media } from 'styled-system/responsive';

const Container = styled.div`
  width: ${FILTER_CONTAINER_WIDTH}px;
  max-width: 100%;
  margin-bottom: -24px;
`;

const ItemContainer = styled.div<{ noMargin?: boolean }>`
  margin-bottom: 24px;
`;

const TitleHeader = styled.div`
  width: 100%;
  font-family: Lato, sans-serif;
  font-size: 16px;
  margin-bottom: 16px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-right: 8px;
`;

const SelectContainer = styled.div`
  display: flex;
  ${media.sm(css`
    justify-content: space-between;
    align-items: center;
  `)}
`;

const SelectWrapper = styled.div`
  width: 120px;
`;

const ToContainer = styled.span`
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  padding: 8px 20px 0 20px;
  ${media.sm(css`
    padding: 0;
  `)};
`;

type SizeFilterProps = Pick<SharedFiltersProps, 'disableFilter'>;

export const SizeFilter = (props: SizeFilterProps) => {
  return (
    <Container>
      <SquareFeet disableFilter={props.disableFilter} />
      <LotSize disableFilter={props.disableFilter} />
      <Stories disableFilter={props.disableFilter} />
      <GarageSpaces disableFilter={props.disableFilter} />
    </Container>
  );
};

const isSquareFeetInputValid = (value: string) => {
  return validateSelectInput(value, minlivingarea);
};

type SquareFeetProps = { disableFilter?: boolean };

const SquareFeet = (props: SquareFeetProps) => {
  const [sizeFilters] = useField({ name: 'sizeFilters' });
  const { minSquareFeet, maxSquareFeet } = sizeFilters.value;

  const squareFeetMinValue = minlivingarea.find(
    ({ value }) => value === minSquareFeet
  );

  const squareFeetMaxValue = maxlivingarea.find(
    ({ value }) => value === maxSquareFeet
  );

  const squareFeetMinValidator = (value: string) => {
    return validateMinMax({
      compareValue: maxSquareFeet,
      isMin: true,
      validator: isSquareFeetInputValid,
      value,
    });
  };
  const squareFeetMaxValidator = (value: string) => {
    return validateMinMax({
      compareValue: minSquareFeet,
      validator: isSquareFeetInputValid,
      value,
    });
  };

  const sizeChanged = useSelector($getSizeChanged);

  return (
    <ItemContainer>
      <FilterHeaderContainer>
        {sizeChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Size
        </FilterHeaderTitle>
      </FilterHeaderContainer>
      <TitleHeader>
        <Title>Square Feet</Title>
        <FiltersTitleValue>
          {getTitleValue(minSquareFeet, maxSquareFeet)}
        </FiltersTitleValue>
      </TitleHeader>
      <SelectContainer>
        <SelectWrapper>
          <InputSelectField
            name="sizeFilters.minSquareFeet"
            ariaLabel={`${SELECT_MIN} square feets`}
            isDisabled={props.disableFilter}
            validator={squareFeetMinValidator}
            rawValue={minSquareFeet}
            value={squareFeetMinValue}
            options={minlivingarea.filter(({ value }) =>
              filterMinMax({
                value,
                min: minSquareFeet,
                max: maxSquareFeet,
                isMin: true,
              })
            )}
          />
        </SelectWrapper>

        <ToContainer>to</ToContainer>
        <SelectWrapper>
          <InputSelectField
            name="sizeFilters.maxSquareFeet"
            ariaLabel={`${SELECT_MAX} square feets`}
            isDisabled={props.disableFilter}
            validator={squareFeetMaxValidator}
            rawValue={maxSquareFeet}
            value={squareFeetMaxValue}
            options={maxlivingarea.filter(({ value }) =>
              filterMinMax({ value, min: minSquareFeet, max: maxSquareFeet })
            )}
          />
        </SelectWrapper>
      </SelectContainer>
    </ItemContainer>
  );
};

const isLotSizeInputValid = (value: string) => {
  const trimmed = value.replace(/\s/g, '');
  return (
    /^\d*\.?\d*(s|sq|sq\.|sq\.f|sq\.ft)?$/i.test(trimmed) ||
    /^\d*\.?\d*(a|ac|acr|acre|acres)?$/i.test(trimmed) ||
    /^\d*\.?\d*$/.test(trimmed)
  );
};

type LotSizeProps = { disableFilter?: boolean };

const LotSize = (props: LotSizeProps) => {
  const [sizeFilters] = useField({ name: 'sizeFilters' });
  const { minLotSize, maxLotSize } = sizeFilters.value;

  const lotSizeMinValue = maxLotSizeAcres.find(
    ({ value }) => value === minLotSize
  );

  const lotSizeMaxValue = maxLotSizeAcres.find(
    ({ value }) => value === maxLotSize
  );

  const lotSizeMinValidator = (value: string) => {
    if (value && isLotSizeInputValid(value)) {
      const numeric = getNumericValueForLotSize(value);
      return numeric <= maxLotSize || not(maxLotSize);
    }
    return false;
  };
  const lotSizeMaxValidator = (value: string) => {
    if (value && isLotSizeInputValid(value)) {
      const numeric = getNumericValueForLotSize(value);
      return numeric >= minLotSize || not(minLotSize);
    }
    return false;
  };

  return (
    <ItemContainer>
      <TitleHeader>
        <Title>Lot Size Acres</Title>
        <FiltersTitleValue>
          {getTitleValue(minLotSize, maxLotSize)}
        </FiltersTitleValue>
      </TitleHeader>
      <SelectContainer>
        <SelectWrapper>
          <InputSelectField
            name="sizeFilters.minLotSize"
            ariaLabel={`${SELECT_MIN} lot size acres`}
            isDisabled={props.disableFilter}
            formatter={value => formatLotSize(getNumericValueForLotSize(value))}
            valueFormatter={value => getNumericValueForLotSize(value)}
            validator={lotSizeMinValidator}
            rawValue={minLotSize}
            value={lotSizeMinValue}
            options={minLotSizeAcres.filter(({ value }) =>
              filterMinMax({
                value,
                min: minLotSize,
                max: maxLotSize,
                isMin: true,
              })
            )}
          />
        </SelectWrapper>

        <ToContainer>to</ToContainer>
        <SelectWrapper>
          <InputSelectField
            name="sizeFilters.maxLotSize"
            ariaLabel={`${SELECT_MAX} lot size acres`}
            isDisabled={props.disableFilter}
            formatter={value => formatLotSize(getNumericValueForLotSize(value))}
            valueFormatter={value => getNumericValueForLotSize(value)}
            validator={lotSizeMaxValidator}
            rawValue={maxLotSize}
            value={lotSizeMaxValue}
            options={maxLotSizeAcres.filter(({ value }) =>
              filterMinMax({ value, min: minLotSize, max: maxLotSize })
            )}
          />
        </SelectWrapper>
      </SelectContainer>
    </ItemContainer>
  );
};

const isStoriesInputValid = (value: string) => {
  return validateSelectInput(value, rangeStoriesOptions);
};

const rangeStoriesOptionsMin = [noMinOption, ...rangeStoriesOptions.slice(1)];
const rangeStoriesOptionsMax = [noMaxOption, ...rangeStoriesOptions.slice(1)];

type StoriesProps = { disableFilter?: boolean };

const Stories = (props: StoriesProps) => {
  const [sizeFilters] = useField({ name: 'sizeFilters' });
  const { minStories, maxStories } = sizeFilters.value;

  const storiesMinValue = rangeStoriesOptionsMin.find(
    ({ value }) => value === minStories
  );

  const storiesMaxValue = rangeStoriesOptionsMax.find(
    ({ value }) => value === maxStories
  );

  const storiesMinValidator = (value: string) =>
    validateMinMax({
      value,
      compareValue: maxStories,
      validator: isStoriesInputValid,
      isMin: true,
    });
  const storiesMaxValidator = (value: string) =>
    validateMinMax({
      value,
      compareValue: minStories,
      validator: isStoriesInputValid,
    });

  return (
    <ItemContainer>
      <TitleHeader>
        <Title>Stories</Title>
        <FiltersTitleValue>
          {getTitleValue(minStories, maxStories)}
        </FiltersTitleValue>
      </TitleHeader>
      <SelectContainer>
        <SelectWrapper>
          <InputSelectField
            name="sizeFilters.minStories"
            ariaLabel={`${SELECT_MIN} stories`}
            isDisabled={props.disableFilter}
            validator={storiesMinValidator}
            rawValue={minStories}
            value={storiesMinValue}
            options={rangeStoriesOptionsMin.filter(({ value }) =>
              filterMinMax({
                value,
                min: minStories,
                max: maxStories,
                isMin: true,
              })
            )}
          />
        </SelectWrapper>

        <ToContainer>to</ToContainer>
        <SelectWrapper>
          <InputSelectField
            name="sizeFilters.maxStories"
            ariaLabel={`${SELECT_MAX} stories`}
            isDisabled={props.disableFilter}
            validator={storiesMaxValidator}
            rawValue={maxStories}
            value={storiesMaxValue}
            options={rangeStoriesOptionsMax.filter(({ value }) =>
              filterMinMax({ value, min: minStories, max: maxStories })
            )}
          />
        </SelectWrapper>
      </SelectContainer>
    </ItemContainer>
  );
};

const rangeGarageSpacesOptionsMin = [
  noMinOption,
  ...rangeGarageSpacesOptions.slice(1),
];
const rangeGarageSpacesOptionsMax = [
  noMaxOption,
  ...rangeGarageSpacesOptions.slice(1),
];

type GarageSpacesProps = { disableFilter?: boolean };

const GarageSpaces = (props: GarageSpacesProps) => {
  const [sizeFilters] = useField({ name: 'sizeFilters' });
  const { minGarageSpaces, maxGarageSpaces } = sizeFilters.value;

  return (
    <ItemContainer>
      <TitleHeader>
        <Title>Garage Spaces</Title>
        <FiltersTitleValue>
          {getTitleValue(minGarageSpaces, maxGarageSpaces)}
        </FiltersTitleValue>
      </TitleHeader>
      <SelectContainer>
        <SelectWrapper>
          <SelectField
            name="sizeFilters.minGarageSpaces"
            ariaLabel={`${SELECT_MIN} garage spaces`}
            isDisabled={props.disableFilter}
            options={rangeGarageSpacesOptionsMin.filter(({ value }) =>
              filterMinMax({
                value,
                min: minGarageSpaces,
                max: maxGarageSpaces,
                isMin: true,
              })
            )}
            overrideSelectStyles={{
              valueContainer: {
                paddingLeft: '8px',
              },
            }}
          />
        </SelectWrapper>

        <ToContainer>to</ToContainer>
        <SelectWrapper>
          <SelectField
            name="sizeFilters.maxGarageSpaces"
            ariaLabel={`${SELECT_MAX} garage spaces`}
            isDisabled={props.disableFilter}
            options={rangeGarageSpacesOptionsMax.filter(({ value }) =>
              filterMinMax({
                value,
                min: minGarageSpaces,
                max: maxGarageSpaces,
              })
            )}
            overrideSelectStyles={{
              valueContainer: {
                paddingLeft: '8px',
              },
            }}
          />
        </SelectWrapper>
      </SelectContainer>
    </ItemContainer>
  );
};
