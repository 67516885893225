import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { Contact } from 'contacts/contactsTypes';
import { Listing } from 'listings/listingsTypes';
import { MapItemTypes, TypesOfMap } from 'map/mapConstants';
import { Cluster, MapInnerState } from 'map/mapTypes';
import { RootState } from 'rootReducer';
import { createListingsUrl } from 'shared/helpers/createListingsUrl';
import { addLabelsToItemsInClusters } from 'shared/helpers/labelsHelper';
import { ImageInfo } from 'shared/types';

export const getMapByType = (state: RootState, type: TypesOfMap) =>
  state.map[type];

export const getMapSize = (state: RootState, type: TypesOfMap) =>
  state.map[type].mapSize;

export const getMapBoundaries = (state: RootState, type: TypesOfMap) =>
  state.map[type].mapBoundary;

export const getIsMapInitialized = (state: RootState, type: TypesOfMap) =>
  state.map[type].isMapInitialized;

const getClustersFromMap = (map: DeepReadonly<MapInnerState>) => map.clusters;

export const getLabelForClusters = createSelector(
  [getClustersFromMap],
  addLabelsToItemsInClusters
);

export const addURLToClusters = (
  items: any[],
  agentMode: boolean,
  contactItem: Contact | null
) => {
  return items.map(item => {
    if (item.type === MapItemTypes.Listing) {
      item.data.url = createListingsUrl(item.data, agentMode, contactItem);
    }

    if (item.type === MapItemTypes.Group && Array.isArray(item.data)) {
      item.data.forEach((item: Listing) => {
        item.url = createListingsUrl(item, agentMode, contactItem);
      });
    }

    return item;
  });
};

const getClustersImages = (
  clusters: Cluster[]
): { [key: string]: ImageInfo[] } => {
  return clusters.reduce((clustersWithImages, { data }) => {
    /* wrong type for Cluster (data) */
    if (Array.isArray(data)) {
      const imgs: { [key: string]: ImageInfo[] } = {};

      data.forEach(listing => {
        if (listing.images && listing.images.length) {
          imgs[listing.listingkey] = listing.images;
        }
      });

      return { ...clustersWithImages, ...imgs };
    }

    return data.images && data.images.length
      ? { ...clustersWithImages, [data.listingkey]: data.images }
      : clustersWithImages;
  }, {});
};

export const getLoadedClustersWithImages = createSelector(
  [getClustersFromMap],
  getClustersImages
);

const getSelectedListingId = (
  state: DeepReadonly<RootState>,
  type: string
): string | -1 => {
  return state.listings.selectedItemId;
};

const getListingsItems = (state: RootState, type: TypesOfMap): Listing[] => {
  return state.listings.items;
};

const selectHighlightedListing = (
  selectedItemId: string,
  items: Listing[]
): Listing => {
  return items.find(item => item.listingid === selectedItemId);
};

export const getHighlightedListing = createSelector(
  [getSelectedListingId, getListingsItems],
  selectHighlightedListing
);

export const getPolygonsByMapType = (state: RootState, mapType: TypesOfMap) =>
  state.map[mapType].polygons;
