import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';

import Input, { InputProps } from 'shared/components/Input/Input';
import { not } from 'shared/helpers/boolean';
import { ReactComponent as IconHidePassword } from 'shared/icons/shared/24x24/closed-eye.svg';
import { ReactComponent as IconError } from 'shared/icons/shared/24x24/error.svg';
import { ReactComponent as IconShowPassword } from 'shared/icons/shared/24x24/open-eye.svg';
import { ReactComponent as IconSuccess } from 'shared/icons/shared/24x24/success.svg';

const PasswordInputTips = styled.div`
  margin-top: 8px;
`;

const TipItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    transition: 0.2s;
    margin-right: 8px;
  }
`;

const TipText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.text4};
`;

const TipTitle = styled.div`
  color: ${({ theme }) => theme.text4};
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: left;
`;

interface Tip {
  message: string;
  isValid: (value: string) => boolean;
}

type Props = InputProps & {
  tips?: Tip[];
  error?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  autoComplete?: string;
};

const InputPassword = ({ tips, error, value, ...restProps }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const theme = useTheme();

  const toggleInputType = () => {
    setShowPassword(prevPassword => not(prevPassword));
  };

  const PasswordIcon = showPassword ? IconShowPassword : IconHidePassword;
  const passwordInputType = showPassword ? 'text' : 'password';
  return (
    <div>
      <Input
        {...restProps}
        type={passwordInputType}
        error={error}
        icon={
          <PasswordIcon
            onClick={toggleInputType}
            fill="#7f8e9b"
            width="24px"
            role="button"
            aria-label={`${showPassword ? 'Hide' : 'Show'} password`}
          />
        }
      />
      {tips && (
        <PasswordInputTips>
          <TipTitle>Password must contain:</TipTitle>

          {tips.map(tip => {
            const isValid = tip.isValid(value);
            const color = isValid ? theme.success : '#bcc8d2';

            return (
              <TipItem key={tip.message}>
                {error && not(isValid) ? (
                  <IconError fill={theme.error} width={16} height={16} />
                ) : (
                  <IconSuccess fill={color} width={16} height={16} />
                )}
                <TipText>{tip.message}</TipText>
              </TipItem>
            );
          })}
        </PasswordInputTips>
      )}
    </div>
  );
};

export default InputPassword;
