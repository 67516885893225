import { Location, Search } from 'history';
import { produce } from 'immer';
import { each, findLast, forOwn, get, omit, pick, pickBy } from 'lodash';
import queryString from 'query-string';

import {
  FilterKey,
  ListingsSource,
  MlsStatus,
  PropertyTypeKeys,
} from 'filters/filtersContants';
import { getFiltersSectionChanged } from 'filters/filtersHelpers';
import {
  DEFAULT_MLS_STATUSES,
  FILTERS_DEFAULT_STATE,
  getDefaultFilters,
} from 'filters/filtersReducer';
import { FiltersState } from 'filters/filtersTypes';
import { mapLowerCaseQueryToCamelCase } from 'listings/lib/map-query';
import {
  EmptyStatus,
  PERIOD_FILTER_DEFAULT_VALUE,
} from 'listings/listingsConstants';
import { filterPolygonsTags } from 'listings/listingsHelpers';
import { LISTINGS_DEFAULT_STATE } from 'listings/listingsReducer';
import { ListingsService } from 'listings/ListingsService';
import { ListingsState } from 'listings/listingsTypes';
import {
  CUSTOM_POLYGON_TYPE,
  DEFAULT_BOUNDARIES,
  MAPDATA_TO_URL_HASHMAP,
  USER_LOCATION_TO_URL_HASHMAP,
} from 'map/mapConstants';
import { Boundary } from 'map/mapTypes';
import { getPolygonUrlFromLink } from 'marketOverview/marketOverviewHelpers';
import { Tag, TagType } from 'search/search-types';
import { SortingData } from 'shared/constants/appConstants';
import { toArray } from 'shared/helpers/array';
import { isEmpty, isNil, not, notEmpty, notNil } from 'shared/helpers/boolean';
import { generateTagFromPolygon } from 'shared/helpers/generatePolygonTag';
import { getIsViewedListingsPage } from 'shared/helpers/isViewedListings';
import {
  getNotEmptyQueryParams,
  isNumeric,
  removeDefaultProperties,
  removeEmptyProperties,
} from 'shared/helpers/mainHelpers';
import { keysOf, mapCamelCaseToLowerCase } from 'shared/helpers/object';
import { resolvePlace } from 'shared/helpers/resolvePlace';
import history from 'shared/services/history';
import { UnpackReturnedPromise } from 'shared/types';
import { PlaceType, Polygon } from 'shared/types/placesAndPolygons';
import { UserLocation } from 'user/userTypes';

export interface GenerateListingQueryParams {
  filters: Partial<FiltersState>;
  listings?: ListingsState;
  mapData?:
    | { [key: string]: unknown }
    | {
        boundaries: Boundary;
        zoom: number;
        center: { lat: number; lng: number };
      };
  tags: Tag[];
  userLocation?: UserLocation | null;
  isAgent?: boolean;
  agentMode?: boolean;
}

export function generateListingsQueryUrl({
  filters,
  listings,
  mapData,
  tags,
  userLocation,
  isAgent,
  agentMode,
}: GenerateListingQueryParams): { search: string; path: string } {
  const selectedTags = getPreparedUrlTags(tags);
  const isViewedListing = getIsViewedListingsPage(history.location.pathname);

  const { openhouse, ...selectedFilters } = mapCamelCaseToLowerCase(
    removeDefaultProperties(
      omit(filters, keysOf(PropertyTypeKeys)),
      getDefaultFilters({
        isAgent: Boolean(isAgent),
        agentMode: Boolean(agentMode),
      })
    )
  );

  const listingsDefaultState = isViewedListing
    ? {
        ...LISTINGS_DEFAULT_STATE,
        ourListingsFirst: false,
        sortBy: SortingData.lastviewed,
      }
    : { ...LISTINGS_DEFAULT_STATE };

  const selectedListingsSortOptions = listings
    ? mapCamelCaseToLowerCase(
        removeDefaultProperties(
          {
            page: listings.page,
            sortBy: listings.sortBy,
            sortType: listings.sortType,
            ourListingsFirst: listings.ourListingsFirst,
          },
          listingsDefaultState
        )
      )
    : {};

  const userLocationQuery: { ulat?: number; ulng?: number } = {};

  if (userLocation) {
    userLocationQuery.ulat = userLocation.latitude;
    userLocationQuery.ulng = userLocation.longitude;
  }

  let query = {
    locations: selectedTags.locations,
    ...selectedTags.tags,
    ...selectedFilters,
    ...preparePropertyTypeFilters(filters),
    ...selectedListingsSortOptions,
    ...userLocationQuery,
    mlsstatus:
      not(isNil(selectedFilters.mlsstatus)) &&
      isEmpty(selectedFilters.mlsstatus)
        ? EmptyStatus
        : selectedFilters.mlsstatus,
  };

  if (mapData) {
    const preparedMapData = prepareMapDataForUrl(mapData);
    query = { ...query, ...preparedMapData };
  }

  return {
    search: queryString.stringify(removeEmptyProperties(query), {
      sort: false,
    }),
    path: get(selectedTags.urlTag, 'url') || '',
  };
}

// TODO: add tests make it shared it is used in listingDetails as well
export const composeQueryUrl = (
  pathname: string,
  queryParams: { [key: string]: unknown }
) => {
  const query = queryString.stringify(removeEmptyProperties(queryParams), {
    sort: false,
  });
  return query ? `${pathname}?${query}` : pathname;
};

export const getPreparedUrlTags = (tags: Tag[]) => {
  const urlTag = findLast(
    tags,
    tag => tag.id && tag.type !== CUSTOM_POLYGON_TYPE
  );
  const { tagsWithoutPolygons, polygonsIds } = filterPolygonsTags(
    urlTag ? tags.filter(tag => tag.id !== (urlTag as Tag).id) : tags
  );

  const queryTags: { [key: string]: string[] } = {};
  tagsWithoutPolygons.forEach((tag: Tag) => {
    if (tag.filter?.suggestAddress || tag.value) {
      const address: string = prettifyAddressUrl(
        tag.filter?.suggestAddress || tag.value || ''
      );
      queryTags[tag.type] = queryTags[tag.type]
        ? [...queryTags[tag.type], address]
        : [address];
    }
  });

  return {
    tags: queryTags,
    locations: polygonsIds,
    urlTag: urlTag || null,
  };
};

export const parseViewedListingsUrl = (
  location: Location = history.location
) => {
  const queryParams = mapLowerCaseQueryToCamelCase(
    getNotEmptyQueryParams(location.search)
  );

  const defaultSortingFilters = {
    ourListingsFirst: false,
    sortBy: SortingData.lastviewed,
  };

  const listingKeys = ['page', 'sortBy', 'sortType', 'ourListingsFirst'];
  const listings: Partial<ListingsState> = convertFilterValues(
    pick(queryParams, listingKeys)
  );
  const period = Number(queryParams['period']) || PERIOD_FILTER_DEFAULT_VALUE;

  const { mapBoundary, zoom } = getMapDataFromQuery(location.search);
  const userLocation = getUserLocationFromQuery(location.search);

  return {
    listings: { ...defaultSortingFilters, ...listings },
    mapBoundary,
    userLocation,
    zoom,
    filters: { period },
    tags: [] as Tag[],
  };
};

export async function parseListingsQueryUrl(
  location: Location = history.location
) {
  const queryParams = mapLowerCaseQueryToCamelCase(
    getNotEmptyQueryParams(location.search)
  );

  const filtersKeys = Object.keys(FILTERS_DEFAULT_STATE);
  const filters: Partial<FiltersState> = convertFilterValues(
    pick(queryParams, filtersKeys)
  );

  const listingKeys = ['page', 'sortBy', 'sortType', 'ourListingsFirst'];
  const listings: Partial<ListingsState> = convertFilterValues(
    pick(queryParams, listingKeys)
  );

  handleSeoSpecificEntries(filters);

  const { tags: queryTags = [], locations = [] } = queryParams;

  const basePolygonUrl = getPolygonUrlFromLink(location.pathname);
  const baseTag = await getTagFromUrl(basePolygonUrl);

  const polygonsIds = wrapInArray(locations);
  const polygonTags = await getTagsFromPolygonId(wrapInArray(polygonsIds));

  const tagsWithoutPolygon = getTagsFromQuery(wrapInArray(queryTags));

  const addressTags = getTagsFromSuggestAddress(
    pick(queryParams, Object.values(PlaceType))
  );

  const { mapBoundary, zoom } = getMapDataFromQuery(location.search);
  const userLocation = getUserLocationFromQuery(location.search);
  const sourceFilters = parseListingsSourse(queryParams.source as string);

  if (filters.mlsStatus?.[0] === EmptyStatus) {
    filters.mlsStatus = [];
  }

  if (
    filters.exclusiveListingsEnabled === false &&
    isNil(filters.mlsListingsEnabled)
  ) {
    filters.mlsStatus = filters.mlsStatus
      ? filters.mlsStatus.filter(mlsStatus =>
          not(mlsStatus === MlsStatus.Private)
        )
      : DEFAULT_MLS_STATUSES;
  }

  if (filters.openHouseOnly) {
    filters.openHouse = 7;
  }

  return {
    filters: { ...filters, ...sourceFilters },
    listings,
    tags: [
      ...polygonTags,
      ...tagsWithoutPolygon,
      ...addressTags,
      baseTag,
    ].filter(notNil),
    mapBoundary,
    userLocation,
    zoom,
  };
}

function handleSeoSpecificEntries(filters: Partial<FiltersState>) {
  const entries = Object.entries(filters);

  const shouldUncheckPropertyTypes = entries.some(
    ([key, value]: [string, unknown]) => key in PropertyTypeKeys && value
  );

  if (shouldUncheckPropertyTypes) {
    keysOf(PropertyTypeKeys).forEach(propertyType => {
      if (not(filters[propertyType])) {
        filters[propertyType] = false;
      }
    });
  }
}

const parseListingsSourse = (source: string) => {
  if (not(source)) {
    return {};
  }

  return {
    exclusiveListingsEnabled: source.includes(ListingsSource.ExlusiveListings),
    mlsListingsEnabled: source.includes(ListingsSource.MlsListings),
  };
};

export const convertFilterValues = (values: Record<string, unknown>) => {
  return produce(values, draft => {
    Object.keys(values).forEach(key => {
      if (key === FilterKey.SwimmingPool) {
        return void 0;
      }
      if (draft[key] === 'true') {
        draft[key] = true;
      }
      if (draft[key] === 'false') {
        draft[key] = false;
      }
      if (isNumeric(draft[key])) {
        draft[key] = Number(draft[key]);
      }
      if (
        key === FilterKey.MlsStatus ||
        key === FilterKey.TypeOfViews ||
        key === FilterKey.WaterBodyName
      ) {
        draft[key] = toArray(draft[key]);
      }
      if (key === FilterKey.Keyword && Array.isArray(draft[key])) {
        draft[key] = (draft[key] as []).join(',');
      }
    });
  });
};

export const wrapInArray = (data: unknown): string[] | [] => {
  const wrappedInArray: string[] =
    typeof data === 'string' ? [data] : (data as string[]);
  return wrappedInArray.filter(Boolean);
};

export const getTagsFromQuery = (queryTags: string[]): Tag[] => {
  const tags: Tag[] = [];
  queryTags.forEach((queryTag: string) => {
    const tag = decomposeQueryTag(queryTag);
    if (tag) tags.push(tag);
  });
  return tags;
};

export const getTagsFromSuggestAddress = (queryTags: {
  [key: string]: unknown | unknown[];
}): Tag[] => {
  const tags: Tag[] = [];

  each(queryTags, (addressesByType, type) => {
    wrapInArray(addressesByType).forEach((address: string) => {
      const normalizedAddress = normalizeAddressUrl(address);

      if (normalizedAddress) {
        tags.push({
          type: type as TagType,
          label: normalizedAddress,
          value: normalizedAddress,
          filter: {
            suggestAddress: normalizedAddress,
          },
        });
      }
    });
  });

  return tags;
};

export const getMapDataFromQuery = (search: Search) => {
  const queryParams = getNotEmptyQueryParams(search);
  const mapDataKeys = Object.values(MAPDATA_TO_URL_HASHMAP);
  const mapDataUnparsedKeys = pick(queryParams, mapDataKeys);

  return prepareMapDataFromUrl(mapDataUnparsedKeys);
};

export const getUserLocationFromQuery = (search: Search) => {
  const queryParams = getNotEmptyQueryParams(search);
  const userLocationKeys = Object.values(USER_LOCATION_TO_URL_HASHMAP);
  const userLocationUnparsedKeys = pick(queryParams, userLocationKeys);

  let userLocation: UserLocation | null = null;

  if (notEmpty(userLocationUnparsedKeys)) {
    userLocation = {
      latitude: Number(userLocationUnparsedKeys.ulat),
      longitude: Number(userLocationUnparsedKeys.ulng),
    };
  }
  return userLocation;
};

export async function getTagsFromPolygonId(polygonsIds: string[]) {
  let tags: Tag[] = [];
  if (!polygonsIds.length) {
    return tags;
  }
  const polygons = await Promise.all(
    polygonsIds.map((id: string) => ListingsService.fetchPolygons(id))
  );
  if (polygons.length) {
    tags = polygons.map((polygon: Polygon) => generateTagFromPolygon(polygon));
  }
  return tags;
}

export async function getTagFromUrl(url: string): Promise<Tag | null> {
  if (!url) {
    return null;
  }

  try {
    const basePolygon: UnpackReturnedPromise<
      typeof ListingsService.fetchPolygonByQuery
    > = await ListingsService.fetchPolygonByQuery({ url });
    if (basePolygon && basePolygon.id) {
      return generateTagFromPolygon(basePolygon);
    }
    return null;
  } catch (e) {
    console.info(e, 'getTagFromUrl');
    return null;
  }
}

export const decomposeQueryTag = (queryTag: string): Tag | null => {
  const decomposed: string[] = queryTag.split('.');
  const [, ...value] = decomposed;
  const type = decomposed[0] as TagType;
  const trimmedValue = value.map(v => v.trim());

  let filter = {};
  switch (type) {
    case PlaceType.Address: {
      filter = {
        streetnumber: trimmedValue[0],
        streetname: trimmedValue[1],
        streetsuffix: trimmedValue[2],
        city: trimmedValue[3],
        stateorprovince: trimmedValue[4],
      };
      break;
    }
    case PlaceType.Street: {
      filter = {
        streetname: trimmedValue[0],
        streetsuffix: trimmedValue[1],
        city: trimmedValue[2],
        stateorprovince: trimmedValue[3],
      };
      break;
    }
    default:
      return null;
  }

  const place = resolvePlace({ type, value: filter });

  return {
    ...place,
    filter: {
      ...filter,
      suggestAddress: place.value,
    },
  };
};

export const getListingsTagUrl = (tag: Tag, redirectPathname: string) => {
  if (tag.url) {
    return redirectPathname + tag.url;
  }

  if (tag.filter?.suggestAddress) {
    return composeQueryUrl(redirectPathname, {
      [tag.type]: prettifyAddressUrl(tag.filter.suggestAddress),
    });
  }
};

export const prettifyAddressUrl = (address: string) =>
  address && address.replace(/-/g, '_').replace(/\s/g, '-');

export const normalizeAddressUrl = (address: string) =>
  address.replace(/-/g, ' ').replace(/_/, '-');

export const prepareMapDataForUrl = (
  mapData: { [key: string]: unknown } | Boundary
) => {
  const preparedMapData: { [key: string]: unknown } = {};
  forOwn(mapData, (value: unknown, key: string) => {
    preparedMapData[MAPDATA_TO_URL_HASHMAP[key]] = value;
  });

  return preparedMapData;
};

function prepareMapDataFromUrl(mapData: Record<string, unknown>) {
  if (notEmpty(mapData)) {
    const mapBoundary = {
      minLatitude: Number(mapData.minlat) || DEFAULT_BOUNDARIES.minLatitude,
      maxLongitude: Number(mapData.maxlong) || DEFAULT_BOUNDARIES.maxLongitude,
      maxLatitude: Number(mapData.maxlat) || DEFAULT_BOUNDARIES.maxLatitude,
      minLongitude: Number(mapData.minlong) || DEFAULT_BOUNDARIES.minLongitude,
    };
    return { mapBoundary, zoom: Number(mapData.z) };
  }

  return { mapBoundary: undefined, zoom: undefined };
}

export const preparePropertyTypeFilters = (filters: Partial<FiltersState>) => {
  const propertyTypeFilters = pick(filters, keysOf(PropertyTypeKeys));
  const propertyTypeFiltersChanged =
    getFiltersSectionChanged(propertyTypeFilters);

  if (not(propertyTypeFiltersChanged)) {
    return {};
  }

  const checkedPropertyTypes = pickBy(propertyTypeFilters, Boolean);

  if (isEmpty(checkedPropertyTypes)) {
    return mapCamelCaseToLowerCase({
      [PropertyTypeKeys.singleFamilyResidence]: false,
      [PropertyTypeKeys.condominium]: false,
      [PropertyTypeKeys.townhouse]: false,
    });
  }

  return mapCamelCaseToLowerCase(checkedPropertyTypes);
};
