import { systemBreakpoints } from 'styled-system/responsive';

const globalTheme = {
  breakpoints: systemBreakpoints,

  // Colors:
  // TEXT
  text: '#1c262e',
  text2: '#515e68',
  text3: '#7f8e9b',
  text4: '#89939d',
  text5: '#292e36',
  text6: '#5d676f',
  disabled: '#bcc8d2',
  disabledArea: '#fbfbfb',

  link: '#0085ad',
  blue: '#0085ad',
  darkBlue: '#081724',
  visited: '#a724bc',

  textButtonDisabled: '#5fb4ce',

  // DEFAULT
  primary: '#e1523d',
  primaryHover: '#fe6b55',
  primaryActive: '#cb4834',
  primaryLight: '#ff8978',
  secondary: '#0085ad',
  secondaryLight: '#9ed3e3',

  // ADDITIONAL
  additional1: '#fdc525',
  additional2: '#067a56',
  additional3: '#1c262e',

  dividerShadowColor: '#f4f4f4',

  errorBg: 'rgba(243, 123, 76, 0.1)',
  error: '#c84d1c',
  success: '#3ea85a',
  successDeep: '#3C9B55',
  warning: '#ffbf45',
  border: '#bcc8d2',
  divider: '#eef0f2',
  hover: '#f4f5f6',
  label: '#13b38a',
  darkGreyDivider: '#e4e6e8',

  facebook: '#486cb0',

  white: '#ffffff',
  black: '#000000',

  contentHeaderBackground: '#',

  secondaryBackground: '#f4f5f6',
  emailBackground: '#f3f3f3',

  loaderPrimaryColor: '#f6f6f6',
  loaderSecondaryColor: '#ecebeb',

  dimmedBackground: '#f9f9fa',

  // UI ELEMENTS
  uiYellow: '#ffe380',
  uiLightYellow: '#ffe793',
  uiRed: '#ffa79a',
  uiBlue: '#538cd7',
  uiGreen: '#39bf9d',
  uiLightGreen: '#5adebc',
  uiCyan: '#76aef9',
  uiCyanLight: '#b3d4ff',
  uiPorcelain: '#edeeef',
  uiAliceBlue: '#e8f0f3',
  uiGrey: '#f6f6f7',

  // SPACES AND SIZES
  padding: '1.5vw 1.875vw',
  vpadding: '1.5vw',
  hpadding: '1.875vw',
  sidebarWidth: '280px',
  sidebarMinWidth: '250px',
  sidebarMaxWidth: '350px',

  // FONTS
  latoFontFamily: 'Lato, sans-serif',

  colors: {
    additional1: '#fdc525',
    additional2: '#067a56',
    additional3: '#1c262e',
    black: '#000000',
    blue: '#0085ad',
    border: '#bcc8d2',
    contentHeaderBackground: '#',
    darkBlue: '#081724',
    darkGreyDivider: '#e4e6e8',
    dimmedBackground: '#f9f9fa',
    disabled: '#bcc8d2',
    disabledArea: '#fbfbfb',
    divider: '#eef0f2',
    divider2: '#eff1f3',
    dividerShadowColor: '#f4f4f4',
    error: '#c84d1c',
    errorBg: 'rgba(243, 123, 76, 0.1)',
    facebook: '#486cb0',
    hover: '#f4f5f6',
    label: '#13b38a',
    link: '#0085ad',
    loaderPrimaryColor: '#f6f6f6',
    loaderSecondaryColor: '#ecebeb',
    overlay: 'rgba(28, 38, 46, 0.3)', // colors.text1 and 0.3 opacity
    primary: '#e1523d',
    primary2: '#c84d1c',
    primaryActive: '#cb4834',
    primaryHover: '#fe6b55',
    primaryLight: '#ff8978',
    secondary: '#0085ad',
    secondaryBackground: '#f4f5f6',
    secondaryLight: '#9ed3e3',
    success: '#3ea85a',
    successDeep: '#3C9B55',
    text1: '#1c262e',
    text2: '#515e68',
    text3: '#7f8e9b',
    text4: '#89939d',
    text5: '#292e36',
    text6: '#5d676f',
    text7: '#22292f',
    visited: '#a724bc',
    warning: '#ffbf45',
    white: '#ffffff',
  },
  boxShadowOutline:
    'rgb(255, 255, 255) 0 0 0 2px, rgb(34, 34, 34) 0 0 0 4px, rgba(255, 255, 255, 0.5) 0 0 0 5px',
  fonts: { lato: 'Lato, -apple-system, BlinkMacSystemFont, sans-serif' },
  fontSizes: [12, 14, 16, 18, 24, 36, 50, 64, 72],
  fontWeights: { body: 400, bold: 700, heading: 700, black: 900 },
  letterSpacings: { body: '0.04px', subheading: '0.21px', heading: '-0.42' },
  lineHeights: { body: 1.5, heading: 1.125 },
  shadows: {},
  sizes: {},
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96],
  zIndeces: {},
} as const;

export type AppTheme = typeof globalTheme;

declare module 'styled-components' {
  export interface DefaultTheme extends AppTheme {}
}

declare module 'styled-components/macro' {
  export interface DefaultTheme extends AppTheme {}
}

export default globalTheme;
