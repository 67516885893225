import { getEnv } from 'env-config';
import { ProductName } from 'shared/config/configConstants';
import RaConfig from 'shared/config/productConfigs/ra-config';
import SaConfig from 'shared/config/productConfigs/sa-config';

export const globalConfigs = {
  [ProductName.RA]: RaConfig,
  [ProductName.SA]: SaConfig,
};

export default globalConfigs[getEnv().productName] || RaConfig;
