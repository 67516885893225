import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { getContactUsername } from 'contacts/contactsSelectors';
import { fetchListingsFail } from 'listings/listingsActions';
import { CUSTOM_TAG_LABEL } from 'listings/listingsConstants';
import { filterPolygonsTags } from 'listings/listingsHelpers';
import { detectMapType } from 'listings/listingsSagas';
import { getDataForFetchingListings } from 'listings/listingsSelectors';
import { ListingsService } from 'listings/ListingsService';
import {
  addMapDrawingPolygon,
  ChangeDrawingPolygonsAction,
  FetchMarkerImagesAction,
  fetchMarkerImagesSuccess,
  FetchPolygonsAction,
  fetchPolygonsSuccess,
  MapActionType,
  saveDrawingPolygonFail,
} from 'map/mapActions';
import { MapService } from 'map/MapService';
import { RaPolygon } from 'map/mapTypes';
import { addCustomTag } from 'search/search-slice';
import { Tag } from 'search/search-types';
import { notEmpty } from 'shared/helpers/boolean';
import { showErrorToast } from 'shared/helpers/notifications';
import { UnpackReturnedPromise } from 'shared/types';

export function* getMapType(action?: unknown) {
  const { collectionId, trecLicenses } = yield select(
    getDataForFetchingListings
  );

  const isTrec = notEmpty(trecLicenses);

  return detectMapType({
    action,
    collectionId,
    isTrec,
  });
}

export function* fetchMarkerImagesSaga(action: FetchMarkerImagesAction) {
  try {
    const username: string = yield select(getContactUsername);
    const images: UnpackReturnedPromise<
      typeof ListingsService.fetchListingsImages
    > = yield call(ListingsService.fetchListingsImages, {
      listingKeys: action.markerKey,
      user: username,
    });

    yield put(
      fetchMarkerImagesSuccess(
        action.mapType,
        action.markerKey,
        images[action.markerKey] || []
      )
    );
  } catch (e) {
    yield put(fetchListingsFail(e.message));
  }
}

export function* fetchPolygonsSaga(action: FetchPolygonsAction): Saga {
  try {
    let tags: Tag[] = yield select(({ search }) => search.tags);
    tags = tags.filter(tag => !tag.isTemporary);
    const { polygonsIds } = filterPolygonsTags(tags);

    for (const polygonsId of polygonsIds) {
      const polygonRes = yield call(ListingsService.fetchPolygons, polygonsId);
      yield put(fetchPolygonsSuccess(action.mapType, polygonRes));
    }
  } catch (e) {
    showErrorToast(e.message);
  }
}

export function* saveDrawingPolygonSaga(action: ChangeDrawingPolygonsAction) {
  try {
    const drawingPolygons: RaPolygon = yield select(
      ({ map }) => map[action.mapType].drawingPolygons
    );

    const response: UnpackReturnedPromise<
      typeof MapService.saveDrawingPolygons
    > = yield call(MapService.saveDrawingPolygons, drawingPolygons);

    const tag = {
      id: response.id,
      isTemporary: true,
      label: CUSTOM_TAG_LABEL,
      type: response.type,
      value: response.id,
    };
    yield put(addCustomTag({ tag }));
    yield put(addMapDrawingPolygon(action.mapType));
  } catch (e) {
    yield put(saveDrawingPolygonFail(action.mapType));
    console.warn(e);
  }
}

const fetchPolygonsSub = () => {
  return takeLatest([MapActionType.FETCH_POLYGONS], fetchPolygonsSaga);
};

const fetchMarkerImagesSub = () =>
  takeEvery([MapActionType.FETCH_MARKER_IMAGES], fetchMarkerImagesSaga);

const saveDrawingPolygonSub = () =>
  takeLatest(
    [MapActionType.CHANGE_MAP_DRAWING_POLYGON],
    saveDrawingPolygonSaga
  );

export function* mapSagas() {
  yield all([
    fetchPolygonsSub(),
    fetchMarkerImagesSub(),
    saveDrawingPolygonSub(),
  ]);
}
