export enum PermissionRoles {
  Guest = 'guest',
  NotVerifiedUser = 'not-verified-user',
  VerifiedUser = 'verified-user',
  NotInitialized = 'not-initialized',
}

export enum FromLinkStatus {
  P = 'p',
  T = 't',
  Nearby = 'nearby',
  Empty = '',
}
