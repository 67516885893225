import queryString, { ParsedQuery } from 'query-string';

import { MAPDATA_TO_SAVED_SEARCH_QUERY } from 'map/mapConstants';
import { isEmpty } from 'shared/helpers/boolean';
import { pick } from 'shared/helpers/object';

export function extractMapDataFromSavedOrViewedSearchQuery(query: string) {
  const queryParams: ParsedQuery = queryString.parse(query);
  const mapDataUnparsedKeys = pick(queryParams, MAPDATA_TO_SAVED_SEARCH_QUERY);

  if (isEmpty(mapDataUnparsedKeys)) {
    return {};
  }

  const mapBoundary = {
    minLatitude: Number(mapDataUnparsedKeys.minLatitude),
    maxLongitude: Number(mapDataUnparsedKeys.maxLongitude),
    maxLatitude: Number(mapDataUnparsedKeys.maxLatitude),
    minLongitude: Number(mapDataUnparsedKeys.minLongitude),
  };
  const zoom = Number(mapDataUnparsedKeys.zoom);

  return {
    mapBoundary,
    zoom,
  };
}

export function extractLeaseFromSearchQuery(query: string): boolean {
  const queryParams = queryString.parse(query, { parseBooleans: true });
  return typeof queryParams.lease === 'boolean' ? queryParams.lease : false;
}
