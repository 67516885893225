import {
  ListingSources,
  LogoSize,
  ProductFullName,
  ProductName,
  ProductOrigins,
  RASocialMedia,
} from 'shared/config/configConstants';
import { ConfigType } from 'shared/config/configTypes';
import { austinSeo } from 'shared/config/productSeo';
import { ReactComponent as IconRALogoMobile } from 'shared/icons/logo/ra-logo-mobile.svg';
import { ReactComponent as IconRALogo } from 'shared/icons/logo/ra-logo.svg';

const config: ConfigType = {
  productName: ProductName.RA,
  productSource: ListingSources.Actris,
  productFullName: ProductFullName.RA,
  productOrigin: ProductOrigins.RealityAustin,
  mapCenter: {
    lat: 30.307985104880558,
    lng: -97.74993599999999,
  },
  logos: {
    [LogoSize.Big]: IconRALogo,
    [LogoSize.Small]: IconRALogoMobile,
  },
  seoData: austinSeo,
  modalsData: {
    requestRelocationGuide: {
      message: 'Please send me a free copy of the Austin Relocation Guide.',
    },
  },
  socialMedia: RASocialMedia,
};

export default Object.freeze(config);
