import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  children?: React.ReactNode;
  maxWidth?: number;
  style?: React.CSSProperties;
}

const Container = styled.div`
  width: 100%;
  padding: 60px 20px;
  min-height: 720px;
`;

const Content = styled.div<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '46em')};
  margin: 0 auto;
`;

const CenteredContent = ({ children, maxWidth, ...rest }: Props) => {
  return (
    <Container {...rest}>
      <Content maxWidth={maxWidth}>{children}</Content>
    </Container>
  );
};

export default CenteredContent;
