import { cloneDeep } from 'lodash';
import { createSelector } from 'reselect';

import { SAVED_SEARCH_TAG_TYPE } from 'listings/listingsConstants';
import { RootState } from 'rootReducer';
import { Hint, Tag } from 'search/search-types';
import { last } from 'shared/helpers/array';
import { not } from 'shared/helpers/boolean';
import { PlaceType } from 'shared/types/placesAndPolygons';

const haveTagWithSameId = (hint: Hint, tags: Tag[]) => {
  return tags.some(tag => {
    if (hint.id) {
      return hint.id === tag.id;
    }

    if (hint.filter?.listingid && tag.filter?.listingid) {
      return hint.filter.listingid === tag.filter.listingid;
    }

    return false;
  });
};

export const getAutocomplete = (state: RootState) => {
  return state.search.autocomplete;
};

export const getRecentSearches = (state: RootState) => {
  return state.search.recentSearches;
};

export const getTags = (state: RootState) => {
  return state.search.tags;
};

export const getTagType = createSelector([getTags], tags => {
  return last(tags)?.type || '';
});

export const filterSearchItems = createSelector(
  [getAutocomplete, getTags],
  (autocomplete, tags) => {
    const clonedAutocomplete = cloneDeep(autocomplete);

    if ('items' in clonedAutocomplete) {
      clonedAutocomplete.items.forEach(item => {
        if (item.type === PlaceType.Group) {
          item.items = item.items.filter(hint =>
            not(haveTagWithSameId(hint, tags))
          );

          const savedSearchTag = tags.find(
            tag => tag.type === SAVED_SEARCH_TAG_TYPE
          );

          if (savedSearchTag) {
            item.items = item.items.filter(hint =>
              not(haveTagWithSameId(hint, savedSearchTag.tags))
            );
          }
        }

        clonedAutocomplete.items = clonedAutocomplete.items.filter(
          item => item.items.length
        );
      });
    }

    return clonedAutocomplete;
  }
);
