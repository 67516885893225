import styled, { css } from 'styled-components/macro';

import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import Button from 'shared/components/Button';
import { not } from 'shared/helpers/boolean';
import { media } from 'styled-system/responsive';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  margin-top: 10px;
  border-top: 1px solid rgb(238, 240, 242);

  ${media.sm(css`
    position: absolute;
    width: 100%;
    top: calc(100% - 67px);
    background: white;
  `)}
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  && {
    margin-right: 0;

    &:disabled {
      span {
        color: ${({ theme }) => theme.disabled};
      }
    }
  }
`;

interface FiltersFooterProps {
  canApply?: boolean;
  canReset?: boolean;
  onReset: () => void;
}

const FiltersFooter = (props: FiltersFooterProps) => {
  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();
  return (
    <Container>
      <StyledButton
        disabled={not(props.canReset)}
        onClick={() => {
          ariaLiveAnnouncerCtx.announce('Filters are reset');
          props.onReset();
        }}
        small
        text
      >
        Reset
      </StyledButton>
      <StyledButton small primary type="submit">
        Apply Filters
      </StyledButton>
    </Container>
  );
};

export default FiltersFooter;
