import { get } from 'lodash';

import { compose, system, SystemConfig } from 'styled-system/lib/core';
import { SystemValue } from 'styled-system/lib/types';

type FullSpaceProps = Partial<{
  margin: SystemValue;
  marginBottom: SystemValue;
  marginLeft: SystemValue;
  marginRight: SystemValue;
  marginTop: SystemValue;
  padding: SystemValue;
  paddingBottom: SystemValue;
  paddingLeft: SystemValue;
  paddingRight: SystemValue;
  paddingTop: SystemValue;
}>;

type ShortSpaceProps = Partial<{
  m: SystemValue;
  marginX: SystemValue;
  marginY: SystemValue;
  mb: SystemValue;
  ml: SystemValue;
  mr: SystemValue;
  mt: SystemValue;
  mx: SystemValue;
  my: SystemValue;
  p: SystemValue;
  paddingX: SystemValue;
  paddingY: SystemValue;
  pb: SystemValue;
  pl: SystemValue;
  pr: SystemValue;
  pt: SystemValue;
  px: SystemValue;
  py: SystemValue;
}>;

export type SpaceProps = FullSpaceProps & ShortSpaceProps;

const defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

const getMargin = (n: number | string, scale: any) => {
  if (typeof n === 'number') {
    const isNegative = n < 0;
    const absolute = Math.abs(n);
    const value = get(scale, absolute, absolute);
    if (typeof value !== 'number') {
      return isNegative ? '-' + value : value;
    }
    return value * (isNegative ? -1 : 1);
  }
  return get(scale, n, n);
};

const marginConfig: SystemConfig = {
  margin: {
    property: 'margin',
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
  marginTop: {
    property: 'marginTop',
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
  marginRight: {
    property: 'marginRight',
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
  marginBottom: {
    property: 'marginBottom',
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
  marginLeft: {
    property: 'marginLeft',
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
  marginX: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
  marginY: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
};
marginConfig.m = marginConfig.margin;
marginConfig.mt = marginConfig.marginTop;
marginConfig.mr = marginConfig.marginRight;
marginConfig.mb = marginConfig.marginBottom;
marginConfig.ml = marginConfig.marginLeft;
marginConfig.mx = marginConfig.marginX;
marginConfig.my = marginConfig.marginY;

const paddingConfig: SystemConfig = {
  padding: {
    property: 'padding',
    scale: 'space',
    defaultScale: defaults.space,
  },
  paddingTop: {
    property: 'paddingTop',
    scale: 'space',
    defaultScale: defaults.space,
  },
  paddingRight: {
    property: 'paddingRight',
    scale: 'space',
    defaultScale: defaults.space,
  },
  paddingBottom: {
    property: 'paddingBottom',
    scale: 'space',
    defaultScale: defaults.space,
  },
  paddingLeft: {
    property: 'paddingLeft',
    scale: 'space',
    defaultScale: defaults.space,
  },
  paddingX: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
    defaultScale: defaults.space,
  },
  paddingY: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
    defaultScale: defaults.space,
  },
};
paddingConfig.p = paddingConfig.padding;
paddingConfig.pt = paddingConfig.paddingTop;
paddingConfig.pr = paddingConfig.paddingRight;
paddingConfig.pb = paddingConfig.paddingBottom;
paddingConfig.pl = paddingConfig.paddingLeft;
paddingConfig.px = paddingConfig.paddingX;
paddingConfig.py = paddingConfig.paddingY;

export const margin = system(marginConfig);
export const padding = system(paddingConfig);

export const space = compose(margin, padding);
