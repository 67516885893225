type RetryInterface = <T>(
  asyncFn: () => Promise<T>,
  config?: { timesLeft?: number; interval?: number }
) => Promise<T>;

export const retry: RetryInterface = (
  asyncFn,
  { timesLeft = 5, interval = 1000 } = {}
) => {
  return new Promise((resolve, reject) => {
    asyncFn().then(resolve, error => {
      setTimeout(() => {
        if (timesLeft === 0) {
          return reject(error);
        }
        retry(asyncFn, { timesLeft: timesLeft - 1, interval }).then(
          resolve,
          reject
        );
      }, interval);
    });
  });
};
