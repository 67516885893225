import React, { useContext } from 'react';
import { Subtract } from 'utility-types';

import { ModalProps } from 'shared/components/Modal/Modal';

export type ShowModal = (e?: React.SyntheticEvent) => void;

export interface ModalContextType {
  isOpen?: boolean;
  component?: React.ComponentType<any>;
  props: { [key: string]: any };
  showModal<T extends ModalProps>(
    component: React.ComponentType<T>,
    props?: Subtract<T, ModalProps> & Partial<ModalProps>
  ): (e?: React.SyntheticEvent) => void;
  closeModal(): void;
}

export const defaultContext: ModalContextType = {
  isOpen: false,
  component: null,
  props: {},
  showModal: () => () => {},
  closeModal: () => {},
};

export const ModalContext =
  React.createContext<ModalContextType>(defaultContext);
ModalContext.displayName = 'ModalContext';

export const useModalContext = () => {
  return useContext(ModalContext);
};
