import { BUTTER_CMS_TIMEOUT } from 'butter-cms/butter-cms-constants';
import { getEnv } from 'env-config';
import { DeploymentTarget } from 'shared/config/configConstants';

const env = getEnv();

const isProductionLike = [
  DeploymentTarget.Production,
  DeploymentTarget.Stage,
].includes(env.deploymentTarget);

export const strapiConfig = {
  hideErrorFallback: isProductionLike,
  notifyOnError: isProductionLike,
  timeout: BUTTER_CMS_TIMEOUT,
  token: env.strapiToken,
  source: env.productName,
  baseUrl: env.strapiUrl,
};
