import { DeepReadonly } from 'utility-types';

import { AgentsAction, AgentsActionType } from 'agents/agentsActions';
import { Agent, AgentsState } from 'agents/agentsTypes';
import { SortOrder } from 'shared/constants/appConstants';

import { UserAccountType } from './agentsConstants';

export const AGENTS_BUTTER_CMS_DEFAULT_STATE: AgentsState['butterCmsData'] = {
  page_title: '',
  meta_description: '',
  h1: '',
  text: '',
};

const INITIAL_AGENTS_BIO_STATE: Agent = {
  sfId: '',
  bio: '',
  defaultAgent: false,
  email: '',
  error: '',
  firstname: '',
  headshotUrlSquare: '',
  languagesSpoken: [],
  lastname: '',
  loading: false,
  members: [],
  phone: '',
  reviewCount: 0,
  reviews: { hits: [], total: 0 },
  teammate1User: '',
  teammate2User: '',
  teammate3User: '',
  teammate4User: '',
  teammates: [],
  teamNameGeneral: '',
  teamNameLookup: '',
  teamType: '',
  title: '',
  trecLicense: '',
  userAccountType: '' as UserAccountType,
  username: '',
  name: '',
  designations: '',
  awards: '',
  video: '',
  url: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  tiktok: '',
  twitter: '',
  youtube: '',
};

export const AGENTS_DEFAULT_STATE: AgentsState = {
  agentBio: INITIAL_AGENTS_BIO_STATE,
  agents: [],
  agentSuggestions: [],
  error: '',
  isSuggestionsLoading: false,
  languagesFilter: [],
  limit: 0,
  loading: false,
  page: 1,
  query: '',
  sortBy: 'lastname',
  sortType: SortOrder.ASC,
  total: 0,
  butterCmsData: AGENTS_BUTTER_CMS_DEFAULT_STATE,
};

export const INITIAL_AGENTS_STATE: Partial<AgentsState> = {
  agents: [],
  languagesFilter: [],
  limit: 0,
  query: '',
  sortBy: 'lastname',
  sortType: SortOrder.ASC,
  total: 0,
  page: 1,
};

let prevState: DeepReadonly<AgentsState> = AGENTS_DEFAULT_STATE;

export function agentsReducer(
  state: DeepReadonly<AgentsState> = AGENTS_DEFAULT_STATE,
  action: AgentsAction
): DeepReadonly<AgentsState> {
  switch (action.type) {
    case AgentsActionType.FETCH_AGENTS_REQUEST:
      prevState = { ...state };
      return {
        ...state,
        loading: true,
        error: '',
      };

    case AgentsActionType.FETCH_AGENTS_SUCCESS:
      return {
        ...state,
        agents: action.agents.hits,
        total: action.agents.total,
        butterCmsData: action.butterCmsData,
        loading: false,
        error: '',
      };

    case AgentsActionType.FETCH_AGENTS_FAIL:
      return {
        ...state,
        ...prevState,
        loading: false,
        error: action.error,
      };

    case AgentsActionType.FETCH_AGENT_SUGGESTIONS:
      return {
        ...state,
        isSuggestionsLoading: true,
      };

    case AgentsActionType.FETCH_AGENT_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        agentSuggestions: action.suggestions,
        isSuggestionsLoading: false,
      };

    case AgentsActionType.FETCH_AGENT_SUGGESTIONS_FAIL:
      return {
        ...state,
        agentSuggestions: [],
        isSuggestionsLoading: false,
      };

    case AgentsActionType.FETCH_AGENT_BIO_REQUEST:
    case AgentsActionType.FETCH_AGENT_BIO_REVIEWS_REQUEST:
      return {
        ...state,
        agentBio: {
          ...state.agentBio,
          loading: true,
          error: '',
        },
      };
    case AgentsActionType.FETCH_AGENT_BIO_SUCCESS:
      return {
        ...state,
        agentBio: action.agent,
        loading: false,
        error: '',
      };
    case AgentsActionType.FETCH_AGENT_BIO_REVIEWS_SUCCESS:
      return {
        ...state,
        agentBio: {
          ...state.agentBio,
          reviews: action.reviews,
        },
      };
    case AgentsActionType.FETCH_AGENT_BIO_REVIEWS_FAIL:
    case AgentsActionType.FETCH_AGENT_BIO_FAIL:
      return {
        ...state,
        agentBio: {
          ...state.agentBio,
          loading: false,
          error: action.error,
        },
      };

    case AgentsActionType.SORT_AGENTS:
      return {
        ...state,
        ...action.sort,
        page: 1,
      };

    case AgentsActionType.FILTER_AGENTS:
      return {
        ...state,
        query: action.query,
        page: 1,
      };

    case AgentsActionType.FILTER_AGENTS_BY_LANGUAGE:
      return {
        ...state,
        languagesFilter: action.languagesFilter,
        page: 1,
      };
    case AgentsActionType.RESET_AGENTS_LANGUAGE_FILTERS:
      return {
        ...state,
        languagesFilter: [],
        page: 1,
      };

    case AgentsActionType.CHANGE_AGENTS_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case AgentsActionType.RESET_AGENTS:
      return {
        ...state,
        ...INITIAL_AGENTS_STATE,
      };
    case AgentsActionType.RESET_AGENT_BIO:
      return {
        ...state,
        agentBio: INITIAL_AGENTS_BIO_STATE,
      };

    case AgentsActionType.SET_AGENTS_DATA_FROM_URL:
      return {
        ...state,
        ...AGENTS_DEFAULT_STATE,
        ...action.agentsQueryData,
      };

    default:
      return state;
  }
}
