import {
  ListingDetailsPropertyType,
  ListingDetailsSubSection,
} from 'listingDetails/listingDetailsTypes';
import { isLeaseListing } from 'listings/listingsHelpers';
import { isEmpty, not } from 'shared/helpers/boolean';

const UNNECESSARY_LEASE_LISTING_INFO = ['sqftPrice', 'acrePrice', 'unitPrice'];

export const filterListingDetailsData = (
  listingInfo: Array<ListingDetailsSubSection<string>>,
  propertyType?: ListingDetailsPropertyType
) => {
  const isLease = isLeaseListing(propertyType);

  if (isEmpty(listingInfo) || not(isLease)) {
    return listingInfo;
  }

  return listingInfo.map(item => ({
    ...item,
    values: item.values.filter(value =>
      not(UNNECESSARY_LEASE_LISTING_INFO.includes(value.type))
    ),
  }));
};
