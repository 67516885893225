import { useField, useFormikContext } from 'formik';

import { getIsAdminAgent } from 'agents/agentsSelectors';
import {
  FilterKey,
  MlsStatus,
  OPEN_HOUSE_DEFAULT_VALUE,
  OPEN_HOUSE_WEEK_VALUE,
  TIME_FRAME_DEFAULT_VALUE,
} from 'filters/filtersContants';
import { $getShowOnlyChanged } from 'filters/filtersSelectors';
import {
  Checkboxes,
  CheckboxWrapper,
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  FormSection,
} from 'filters/filtersStyled';
import { SecondaryFiltersForm } from 'filters/filtersTypes';
import { CheckboxField } from 'shared/components/formikFields/CheckboxField';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';

interface ShowOnlyFilterProps {
  disableFilter?: boolean;
}

export const ShowOnlyFilter = (props: ShowOnlyFilterProps) => {
  const isLeasePage = useIsLeasePage();

  const { setFieldValue } = useFormikContext<SecondaryFiltersForm>();

  const [showOnlyFilters] = useField({ name: 'showOnlyFilters' });
  const [mlsStatusFilter] = useField({ name: 'mlsStatus' });

  const isAdminAgent = useSelector(getIsAdminAgent);
  const showOnlyChanged = useSelector($getShowOnlyChanged);

  const toggleOpenHouseOnly = () => {
    const openHouseChecked = not(showOnlyFilters.value.openHouseOnly);
    const updatedMlsStatus = mlsStatusFilter.value.filter(
      (statusItem: MlsStatus) =>
        statusItem !== (isLeasePage ? MlsStatus.Leased : MlsStatus.Sold)
    );

    setFieldValue(
      'showOnlyFilters.openHouse',
      openHouseChecked ? OPEN_HOUSE_WEEK_VALUE : OPEN_HOUSE_DEFAULT_VALUE
    );
    setFieldValue(
      isLeasePage
        ? 'timeFrameFilters.leasedTimeframe'
        : 'timeFrameFilters.soldTimeframe',
      TIME_FRAME_DEFAULT_VALUE
    );
    setFieldValue(FilterKey.MlsStatus, updatedMlsStatus);
  };

  return (
    <FormSection>
      <FilterHeaderContainer>
        {showOnlyChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Show only
        </FilterHeaderTitle>
      </FilterHeaderContainer>

      <Checkboxes>
        <CheckboxWrapper>
          <CheckboxField
            name="showOnlyFilters.ourListingsOnly"
            key="Our listings"
            labelText="Our Listings"
            ariaLabel="Our Listings"
            disabled={props.disableFilter}
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="showOnlyFilters.openHouseOnly"
            key="Open houses"
            labelText="Open Houses"
            ariaLabel="Open Houses"
            disabled={props.disableFilter}
            onChange={toggleOpenHouseOnly}
          />
        </CheckboxWrapper>
        {isAdminAgent && (
          <CheckboxWrapper>
            <CheckboxField
              name="showOnlyFilters.noPhoto"
              labelText="No Photo"
              disabled={props.disableFilter}
            />
          </CheckboxWrapper>
        )}
      </Checkboxes>
    </FormSection>
  );
};
