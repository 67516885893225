import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

export const ThreeDotsLoaderContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 6px;
`;

const animationSpeed = 0.5;
const animationDelay = animationSpeed / 2;
const animation = (color: string) => keyframes`
  0% {
    background-color: ${color};
  }
  100% {
    background-color: transparent;
  }
`;

export const Loader = styled.div<{ color: string; size: number }>`
  display: inline-block;
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 5px;
  background-color: ${({ color }) => color};
  color: ${({ color }) => color};
  animation: ${({ color }) => animation(color)} ${animationSpeed}s infinite
    linear alternate;
  animation-delay: ${animationDelay}s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
    display: inline-block;
    background-color: inherit;
    color: inherit;
    animation: inherit;
    border-radius: inherit;
  }
  &:before {
    left: -12px;
    animation-delay: 0s;
  }
  &:after {
    left: 12px;
    animation-delay: ${animationDelay * 2}s;
  }
`;

interface Props {
  color?: string;
  size?: number;
}

const ThreeDotsLoader = ({ color, size = 6 }: Props) => (
  <ThreeDotsLoaderContainer data-testid="loader">
    <Loader color={color} size={size} />
  </ThreeDotsLoaderContainer>
);

ThreeDotsLoader.defaultProps = {
  color: '#fff',
};

export default ThreeDotsLoader;
