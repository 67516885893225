import React from 'react';

import { IconPosition } from 'shared/components/Button/ButtonConstants';
import {
  ExternalButtonLabelContainer,
  StyledButton,
  StyledLinkButton,
} from 'shared/components/Button/ButtonStyled';
import InnerButton from 'shared/components/Button/InnerButton';
import { notNil } from 'shared/helpers/boolean';
import { useIsMobile } from 'styled-system/responsive';

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  iconPosition?: IconPosition;
  value?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  parentWidth?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  frozen?: boolean;
  loading?: boolean;
  blank?: boolean;
  texticon?: boolean; // TODO: Unused
  text?: boolean;
  textInactive?: boolean;
  isActive?: boolean;
  small?: boolean;
  badge?: string | number;
  pagination?: boolean;
  disabled?: boolean;
  large?: boolean;
  xl?: boolean;
  onClick?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  style?: {
    [key: string]: any;
  };
  hasIconActive?: boolean;
  hasIconBordered?: boolean;
  externalButtonLabel?: JSX.Element;
  light?: boolean;
  className?: string;
  role?: string;
  drawingMapButton?: boolean;
  href?: string;
  form?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  addHover?: boolean;
  moreBtn?: boolean;
  rel?:
    | 'alternate'
    | 'author'
    | 'bookmark'
    | 'external'
    | 'help'
    | 'license'
    | 'next'
    | 'nofollow'
    | 'noreferrer'
    | 'noopener'
    | 'prev'
    | 'search'
    | 'tag';
  rmInnerPadding?: boolean;
  paginationIcon?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  dirty?: boolean;
}

const Button = React.forwardRef(
  (
    props: ButtonProps,
    ref: (element: HTMLButtonElement | HTMLAnchorElement) => void
  ) => {
    const {
      type,
      iconPosition,
      icon,
      badge,
      children,
      value,
      drawingMapButton,
      href,
      loading,
      primary,
      tertiary,
      moreBtn,
      addHover,
      rmInnerPadding,
      paginationIcon,
      dirty,
      ...rest
    } = props;

    const hasText = notNil(children) || notNil(value);
    const hasIcon = notNil(icon);
    const hasBadge = notNil(badge);
    const isMobile = useIsMobile();

    if (href) {
      return (
        <StyledLinkButton
          hasBadge={hasBadge}
          hasIcon={hasIcon}
          href={href}
          iconPosition={iconPosition}
          loading={loading}
          primary={primary}
          tertiary={tertiary}
          ref={ref}
          {...rest}
        >
          <InnerButton
            iconPosition={iconPosition}
            icon={icon}
            badge={badge}
            value={value}
            drawingMapButton={drawingMapButton}
            loading={loading}
            primary={primary}
            tertiary={tertiary}
            hasText={hasText}
            hasIcon={hasIcon}
            hasBadge={hasBadge}
            dirty={dirty}
          >
            {children}
          </InnerButton>
        </StyledLinkButton>
      );
    }

    return (
      <StyledButton
        dirty={dirty}
        hasBadge={hasBadge}
        hasIcon={hasIcon}
        iconPosition={iconPosition}
        $loading={loading}
        primary={primary}
        tertiary={tertiary}
        ref={ref}
        type={type}
        moreBtn={moreBtn}
        addHover={addHover}
        isMobile={isMobile}
        {...rest}
      >
        {rest.externalButtonLabel && (
          <ExternalButtonLabelContainer>
            {rest.externalButtonLabel}
          </ExternalButtonLabelContainer>
        )}
        <InnerButton
          rmInnerPadding={rmInnerPadding}
          iconPosition={iconPosition}
          icon={icon}
          badge={badge}
          value={value}
          drawingMapButton={drawingMapButton}
          loading={loading}
          primary={primary}
          tertiary={tertiary}
          hasText={hasText}
          hasIcon={hasIcon}
          hasBadge={hasBadge}
          paginationIcon={paginationIcon}
          isText={props.text}
          dirty={dirty}
        >
          {children}
        </InnerButton>
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

Button.defaultProps = {
  type: 'button',
  iconPosition: IconPosition.LEFT,
};

export default Button;
