import { browserStorageItem } from 'shared/constants/appConstants';
import { sessionStorage } from 'shared/services/sessionStorage';

export const trackOriginalSource = () => {
  if (sessionStorage.getItem(browserStorageItem.originalSource)) {
    return;
  }
  const url = new URL(
    document.referrer || window.location.toString()
  ).hostname.replace('www.', '');

  sessionStorage.setItem(browserStorageItem.originalSource, url);
};
