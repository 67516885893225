import React from 'react';
import styled from 'styled-components/macro';

import CenteredContainer from 'shared/components/CenteredContent';
import { Loader } from 'shared/components/loaders/ThreeDotsLoader/ThreeDotsLoader';
import { Logo } from 'shared/components/Logo';
import MemoizedMetadata from 'shared/components/Metadata';
import {
  StaticRobots,
  StaticTitle,
} from 'shared/components/Metadata/metadataConstants';
import { createFSM } from 'shared/hooks/useFSM';
import { notifyReactError } from 'shared/services/bugsnag';
import { ButtonBase, Flex, Typography } from 'styled-system/components';

interface ErrorBoundaryProps {
  reload?: () => void;
}

interface ErrorBoundaryState {
  error: Error | null;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = { reload: () => window.location.reload() };

  static getDerivedStateFromError(error: any): ErrorBoundaryState | null {
    return { error };
  }

  state: ErrorBoundaryState = { error: null };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    notifyReactError(error, info);
  }

  render() {
    if (this.state.error !== null) {
      return (
        <ErrorScreen error={this.state.error} reload={this.props.reload} />
      );
    }
    return this.props.children;
  }
}

interface ErrorScreenProps {
  error: Error;
  reload: () => void;
}

const useFSM = createFSM({
  name: 'Error boundary machine',
  events: ['show', 'hide', 'reload'],
  states: ['init', 'details', 'reloading'],
  transitions: on => [
    on('show', ['init'], 'details'),
    on('hide', ['details'], 'init'),
    on('reload', ['init', 'details'], 'reloading'),
  ],
});

const ErrorScreen = (props: ErrorScreenProps) => {
  const fsm = useFSM('init');

  const handleReloadClick = async () => {
    fsm.send('reload');
    const isLoadingChunkError = /chunk \d+ failed/.test(props.error.message);
    if (isLoadingChunkError) {
      await serviceWorkerCleanup();
    }
    props.reload();
  };

  const handleReinstallClick = async () => {
    fsm.send('reload');
    await serviceWorkerCleanup();
    props.reload();
  };

  return (
    <CenteredContainer maxWidth={520}>
      <MemoizedMetadata
        title={StaticTitle.ErrorBoundaryPageTitle}
        robots={StaticRobots.NoindexFollow}
      />

      <Logo />

      <Typography as="h2" mt={2}>
        Oops! Something unexpected happened.
      </Typography>

      <Typography as="p" mt={2} sx={{ lineHeight: 1.3, letterSpacing: 0.02 }}>
        {fsm.match('reloading')
          ? 'Please wait, page is reloading.'
          : 'Please try to reload the page, it might be a temporary glitch.'}
      </Typography>

      {fsm.match('reloading') && (
        <Flex sx={{ pl: '14px', mt: 2 }}>
          <Loader color="teal" size={8} />
        </Flex>
      )}

      <Flex mt={2}>
        {fsm.not('reloading') && (
          <PrimaryButton mr={1} onClick={handleReloadClick}>
            RELOAD
          </PrimaryButton>
        )}

        {fsm.match('init') && (
          <Button sx={{ width: '112px' }} onClick={() => fsm.send('show')}>
            SHOW DETAILS
          </Button>
        )}
        {fsm.match('details') && (
          <Button sx={{ width: '112px' }} onClick={() => fsm.send('hide')}>
            HIDE DETAILS
          </Button>
        )}
      </Flex>

      {fsm.match('details') && (
        <>
          <Details mt={3} p={2} sx={{ flexDirection: 'column' }}>
            <Pre>{props.error.toString()}</Pre>
          </Details>
          <Typography
            as="p"
            mt={2}
            sx={{ lineHeight: 1.3, letterSpacing: 0.02 }}
          >
            If the error persists after reload, please try to reinstall the
            application or{' '}
            <a
              href="https://austinrealty.com/contact"
              rel="noreferrer"
              target="_blank"
            >
              contact our support
            </a>
            .
          </Typography>
          <DetailsButton
            onClick={handleReinstallClick}
            sx={{ alignSelf: 'flex-start', mt: 2 }}
          >
            REINSTALL THE APPLICATION
          </DetailsButton>
        </>
      )}
    </CenteredContainer>
  );
};

async function serviceWorkerCleanup() {
  let $unregister: Promise<any>;
  let $clearCaches: Promise<any>;
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.getRegistration();
    $unregister = registration?.unregister();
  }
  if (window.caches && window.caches.keys) {
    const keys = await caches.keys();
    $clearCaches = Promise.all(keys.map(key => caches.delete(key)));
  }
  await Promise.all([$unregister, $clearCaches]);
}

const Details = styled(Flex)`
  border-radius: 4px;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1);
`;

const Pre = styled.pre`
  font-size: 14px;
  white-space: pre-wrap;
`;

const Button = styled(ButtonBase)`
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.24);
  color: rgba(0, 0, 0, 0.72);
  font-size: 12px;
  font-weight: bold;
  padding: 6px 12px;
  &:focus {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.16);
  }
`;

const DetailsButton = styled(Button)`
  box-shadow: 0 0 0 1px rgba(220, 0, 78, 0.24);
  color: rgba(220, 0, 78, 0.72);
  &:focus {
    background-color: rgba(220, 0, 78, 0.04);
  }
  &:hover {
    background-color: rgba(220, 0, 78, 0.08);
  }
  &:active {
    background-color: rgba(220, 0, 78, 0.16);
  }
`;

const PrimaryButton = styled(Button)`
  box-shadow: 0 0 0 1px ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.primary};
  &:focus {
    background-color: ${p => p.theme.colors.primaryLight};
    color: white;
  }
  &:hover {
    background-color: ${p => p.theme.colors.primaryHover};
    color: white;
  }
  &:active {
    background-color: ${p => p.theme.colors.primaryActive};
    color: white;
  }
`;
