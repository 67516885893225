import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';

import config from 'config';
import { getEnv } from 'env-config';
import { TEMPORARY_PASSWORD } from 'shared/constants/appConstants';
import { first } from 'shared/helpers/array';
import { CognitoFederatedProviders } from 'shared/types';
import { UserRole } from 'user/userTypes';

export const firstNameAttributeName = 'custom:firstname';
export const lastNameAttributeName = 'custom:lastname';
export const usernameAttributeName = 'custom:username';
export const pictureAttributeName = 'picture';
export const roleAttributeName = 'custom:role';

const env = getEnv();

const oauth = {
  // Domain name
  domain: env.cognitoDomain,

  // Authorized scopes
  scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],

  // Callback URL
  redirectSignIn: window.location.origin,

  // Sign out URL
  redirectSignOut: window.location.origin,

  responseType: 'code',
};

const CognitoConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool IDs
    identityPoolId: env.identityPoolId,
    // REQUIRED - Amazon Cognito Region
    region: env.region,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: env.userPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: env.userPoolWebClient,
    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: {
      portalUrl: window.location.origin,
      env: `${config.productName}`,
      envFullName: `${config.productFullName}`,
    },
    oauth,
  },
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: true,
  },
};

Amplify.configure(CognitoConfig);

// enable debug mode when required to debug oauth flow
// Amplify.Logger.LOG_LEVEL = 'DEBUG';
// (window as any).LOG_LEVEL = 'DEBUG';

export interface HubPayload {
  event?: string;
  data?: any;
  message?: string;
}

export enum HubPayloadEvent {
  SignIn = 'signIn',
  SignInFailure = 'signIn_failure',
  CustomStateFailure = 'customState_failure',
}

export enum HubPayloadData {
  AuthExceptionSalesforce = 'Exception+processing+authorization+code',
  // Custom message throwing at Cognito Pre sign-up trigger for RA emails
  CustomMessageAgentEmailFlow = 'RA_AGENT_EMAIL_FLOW',
}

export interface CognitoUser {
  username: string;
  attributes: Attributes;
}

interface Attributes {
  email?: string;
  identities?: string;
  [firstNameAttributeName]?: string;
  [lastNameAttributeName]?: string;
  [usernameAttributeName]?: string;
  [pictureAttributeName]?: string;
  [roleAttributeName]?: string;
}

interface Identity {
  providerName: string;
}

export class AuthService {
  static async signUp(email: string, password: string): Promise<unknown> {
    return Auth.signUp({
      username: email,
      password,
    });
  }

  static isAgentEmail(email: string): boolean {
    return email.indexOf('@realtyaustin.com') > -1;
  }

  static async signIn(email: string, password: string): Promise<unknown> {
    return Auth.signIn(email, password);
  }

  static async getCurrentAuthenticatedUser(): Promise<CognitoUser> {
    // bypassCache - optional, by default is false. If set to true, this call will send a request to Cognito to get the latest user data
    return Auth.currentAuthenticatedUser({ bypassCache: true });
  }

  static async updateCurrentUserAttributes(
    user: CognitoUser,
    attributes: Attributes
  ): Promise<CognitoUser> {
    await Auth.updateUserAttributes(user, attributes);
    // we need to refresh a session to get updated email into token
    await AuthService.refreshSession();
    return Auth.currentUserInfo();
  }

  static async refreshSession() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession: any = await Auth.currentSession();

    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err: Error, session: any) => {
          if (err) reject(err);
          resolve(session);
        }
      );
    });
  }

  static async getToken(): Promise<string> {
    return Auth.currentSession()
      .then(session => session.getIdToken().getJwtToken())
      .catch(() => '');
  }

  static async signOut(): Promise<unknown> {
    localStorage.removeItem(TEMPORARY_PASSWORD);
    return Auth.signOut();
  }

  static async forgotPassword(email: string): Promise<unknown> {
    return Auth.forgotPassword(email, {
      portalUrl: window.location.origin,
      env: `${config.productName}`,
      envFullName: `${config.productFullName}`,
    });
  }

  static async resetPassword(
    email: string,
    code: string,
    password: string
  ): Promise<void> {
    return Auth.forgotPasswordSubmit(email, code, password);
  }

  static async setPassword(
    temporaryPassword: string,
    password: string
  ): Promise<string> {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, temporaryPassword, password);
  }

  static async loginFacebook(): Promise<void> {
    await AuthService.federatedSignIn(CognitoFederatedProviders.Facebook);
  }

  static async loginGoogle(): Promise<void> {
    await AuthService.federatedSignIn(CognitoFederatedProviders.Google);
  }

  static async federatedSignIn(type: string): Promise<void> {
    const config = CognitoConfig.Auth;
    const { domain, redirectSignIn, scope, responseType } = config.oauth;

    const clientId = config.userPoolWebClientId;

    let url = `https://${domain}/oauth2/authorize?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}&scope=${scope.join(
      ' '
    )}`;

    url += `&identity_provider=${type}`;

    await Auth.signOut();

    window.location.replace(url);
  }

  static getFederatedProviderName(
    attributes: Attributes
  ): CognitoFederatedProviders {
    if (attributes.identities !== undefined) {
      const identities: Identity[] = JSON.parse(attributes.identities);
      const { providerName } = first(identities);
      return providerName as CognitoFederatedProviders;
    }
  }

  static getCognitoUserRole(
    attributes: Attributes,
    isAgent?: boolean
  ): UserRole {
    const providerName = this.getFederatedProviderName(attributes);
    if (providerName === CognitoFederatedProviders.Google && isAgent) {
      return UserRole.Agent;
    }
    return UserRole.Client;
  }
}
