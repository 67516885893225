import { indexOf } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  components,
  DropdownIndicatorProps,
  MenuListProps,
  OptionProps,
  default as ReactSelect,
} from 'react-select';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { useTheme } from 'styled-components/macro';

import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import {
  ErrorMessageClassName,
  Message,
  SecondaryLabel,
} from 'shared/components/Input/Input';
import SelectInput from 'shared/components/Select/components/SelectInput';
import {
  ADD_NEW_COLLECTION,
  ADD_NEW_VALUE,
} from 'shared/components/Select/SelectConstants';
import {
  customDropdownStyles,
  customSelectStyles,
} from 'shared/components/Select/SelectHelpers';
import {
  AgentOption,
  OptionContainer,
  OptionLabel,
  SelectLabel,
  SelectWrapper,
} from 'shared/components/Select/SelectStyles';
import {
  SelectOption,
  SelectProps,
} from 'shared/components/Select/SelectTypes';
import { ButtonsCode } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { ReactComponent as IconSelect } from 'shared/icons/shared/16x16/select.svg';
import { ReactComponent as IconArrowDown } from 'shared/icons/shared/arrows/small-arrow-down.svg';
import { ErrorMessagePlacement } from 'shared/types/errorTypes';
import { unsafeCoerce } from 'shared/utils/types';
import { useIsMobile } from 'styled-system/responsive';

const OPTION_HEIGHT = 40;
const MAX_MENU_HEIGHT = 240;
const MAX_OPTION_AMOUNT = 5;

const Select = (props: SelectProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(props.menuIsOpen);

  const isMobile = useIsMobile();
  const theme = useTheme();
  const ariaLiveAnnouncerCxt = useAriaLiveAnnouncerContext();
  const selectRef = useRef(null);

  useEffect(() => {
    props.setDefaultCollection?.(options.find(option => not(option.disabled)));
  }, []);

  const options = [...props.options];
  if (props.hasButton) {
    options.push({ label: props.buttonText, value: ADD_NEW_VALUE });
  }

  const { isSearchable = false } = props;
  const styles = props.hasButton ? customDropdownStyles : customSelectStyles;
  const messageColor = props.error
    ? theme.colors.error
    : props.warn
    ? theme.colors.warning
    : theme.colors.white;
  const selectId = `select ${props.ariaLabel || props.labelText}`;

  const handleFocus =
    (focused: boolean, cb: (e?: React.FocusEvent) => void) =>
    (e?: React.FocusEvent) => {
      setFocused(focused);
      cb?.(e);
    };

  const getDefaultValue = () =>
    not(props.hideDefaultValue)
      ? options.find(
          option =>
            not(option.disabled) &&
            option.label !== ADD_NEW_COLLECTION &&
            option.label !== ''
        )
      : undefined;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (
      (event.key === ButtonsCode.Enter || event.key === ButtonsCode.Space) &&
      not(menuIsOpen)
    ) {
      event.preventDefault();
      setMenuIsOpen(true);
    } else if (event.key === ButtonsCode.Esc && menuIsOpen) {
      event.stopPropagation();
      ariaLiveAnnouncerCxt.announce('Collapsed');
      setMenuIsOpen(false);
    }
  };

  return (
    <SelectWrapper maxWidth={props.maxWidth}>
      {props.labelText && (
        <SelectLabel htmlFor={selectId} isDisabled={props.isDisabled}>
          {props.labelText}
          <SecondaryLabel>{props.secondaryLabel}</SecondaryLabel>
        </SelectLabel>
      )}

      {props.isAgentList ? (
        <ReactSelect
          {...props}
          isSearchable={isSearchable}
          components={{ MenuList, DropdownIndicator, Input: SelectInput }}
          options={options}
          styles={styles({
            isMobile,
            theme,
            overrideSelectStyles: props.overrideSelectStyles,
            error: props.error,
          })}
          theme={theme => ({ ...theme, borderRadius: 0 })}
          onFocus={handleFocus(true, props.onFocus)}
          onBlur={handleFocus(false, props.onBlur)}
          autoFocus={props.focused}
          aria-label={props.ariaLabel}
          ref={selectRef}
        />
      ) : (
        <ReactSelect
          {...props}
          isSearchable={isSearchable}
          isOptionDisabled={option => option.disabled}
          inputId={selectId}
          components={{ Option, DropdownIndicator, Input: SelectInput }}
          options={options}
          styles={styles({
            isMobile,
            theme,
            overrideSelectStyles: props.overrideSelectStyles,
            error: props.error,
          })}
          theme={theme => ({ ...theme, borderRadius: 0 })}
          onMenuClose={() => setMenuIsOpen(false)}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          defaultValue={getDefaultValue()}
          onFocus={handleFocus(true, props.onFocus)}
          onBlur={handleFocus(false, props.onBlur)}
          onKeyDown={handleKeyDown}
          autoFocus={props.focused}
          aria-label={props.ariaLabel}
          ref={selectRef}
          ariaLiveMessages={{
            guidance: () => '',
          }}
        />
      )}

      {Boolean(props.message) && not(focused) && (
        <Message
          className={ErrorMessageClassName}
          color={messageColor}
          placement={
            props.floatingMessage
              ? props.errorMessagePlacement
              : ErrorMessagePlacement.Bottom
          }
          floating={props.floatingMessage}
        >
          {props.message}
        </Message>
      )}
    </SelectWrapper>
  );
};

Select.defaultProps = {
  floatingMessage: true,
};

export default Select;

export const Option = (props: OptionProps<SelectOption, false, null>) => {
  const { data, options, isSelected, isDisabled, children, isFocused } = props;

  const theme = useTheme();

  const isButton = data.value === ADD_NEW_VALUE;
  const addNew = options.find(el => el.value === ADD_NEW_VALUE);
  const isSticky = options.length > 4 && children === 'Add New Collection';

  return (
    <OptionContainer isSticky={isSticky} isDropdown={Boolean(addNew)}>
      {isSelected && not(isButton) && (
        <IconSelect height="16px" fill={theme.blue} />
      )}
      {addNew && not(isButton) && (
        <OptionLabel
          isDisabled={isDisabled}
          isDropdown={Boolean(addNew)}
          isFocused={isFocused}
        >
          {isDisabled ? `Already in this Collection` : `${data.size} listings`}
        </OptionLabel>
      )}
      <components.Option {...props} />
    </OptionContainer>
  );
};

export const MenuList = (
  props: MenuListProps<SelectOption> & {
    children: Array<React.Component<ListChildComponentProps>>;
  }
) => {
  const { options, getValue } = props;

  const theme = useTheme();

  const children = React.Children.toArray(props.children);
  const [value] = getValue() as any; // fixme type
  const initialOffset = indexOf(options, value) * OPTION_HEIGHT;
  const menuHeight =
    children.length > MAX_OPTION_AMOUNT
      ? MAX_MENU_HEIGHT
      : children.length * OPTION_HEIGHT;

  return (
    <List
      height={menuHeight}
      width={'100%'}
      itemCount={children.length}
      itemSize={OPTION_HEIGHT}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <AgentOption style={style}>
          {/* FIXME: remove unsafeCoerce and fix type error */}
          {unsafeCoerce<any>(children[index]).props.isSelected && (
            <IconSelect height="16px" fill={theme.colors.blue} />
          )}
          {children[index]}
        </AgentOption>
      )}
    </List>
  );
};

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <IconArrowDown height="16px" />
      </components.DropdownIndicator>
    )
  );
};
