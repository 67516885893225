import { call, select } from 'redux-saga/effects';

import { isDismissedId, isProposedId } from 'collections/collectionsHelpers';
import { searchUrl } from 'listings/listingsConstants';
import { ListingsService } from 'listings/ListingsService';
import { PaginatedListings } from 'listings/listingsTypes';
import { RootState } from 'rootReducer';
import { BaseResponse } from 'shared/types';

export interface FetchCollectionBoundariesResult {
  boundaries?: PaginatedListings['boundaries'];
  history?: number;
  pageCount?: number;
}

export function* fetchCollectionBoundariesSaga(id: string) {
  const url = new URL(searchUrl, window.location.origin);

  const selector = (state: RootState) => state.listings;
  const state: ReturnType<typeof selector> = yield select(selector);

  url.searchParams.append('limit', String(1));
  url.searchParams.append('ourListingsFirst', String(state.ourListingsFirst));
  url.searchParams.append('page', String(state.page));
  url.searchParams.append('sortBy', String(state.sortBy));
  url.searchParams.append('sortType', String(state.sortType));

  if (isDismissedId(id)) {
    url.searchParams.append('dismissed', 'true');
    url.searchParams.append('dismissedOnly', 'true');
  } else if (isProposedId(id)) {
    url.searchParams.append('proposedOnly', 'true');
  } else {
    url.searchParams.append('collection', id);
  }

  const response: BaseResponse<PaginatedListings> = yield call(
    ListingsService.fetchListings,
    url
  );

  return {
    boundaries: response.data.boundaries,
    history: response.data.history,
    pageCount: response.data.pageCount,
  } as FetchCollectionBoundariesResult;
}
