import { useField, useFormikContext } from 'formik';
import styled from 'styled-components/macro';

import { getAgentMode } from 'contacts/contactsSelectors';
import {
  FilterKey,
  MlsStatus,
  OPEN_HOUSE_DEFAULT_VALUE,
  TIME_FRAME_DEFAULT_VALUE,
  TIME_FRAME_ONE_YEAR_VALUE,
} from 'filters/filtersContants';
import { $getStatusChanged } from 'filters/filtersSelectors';
import {
  Checkboxes,
  CheckboxWrapper,
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  FormSection,
} from 'filters/filtersStyled';
import { SecondaryFiltersForm } from 'filters/filtersTypes';
import { permissionsConfig } from 'permissions/permissionsConfig';
import AuthLock from 'shared/components/AuthLock';
import { CheckboxField } from 'shared/components/formikFields/CheckboxField';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';
import { getIsAgent } from 'user/userSelectors';

const CheckboxAuthLock = styled(AuthLock)`
  margin-left: 8px;
  transform: translateY(2px);
`;

interface StatusFilterProps {
  disableFilter?: boolean;
}

const StatusFilter = (props: StatusFilterProps) => {
  const isLeasePage = useIsLeasePage();

  const [field] = useField({ name: FilterKey.MlsStatus });
  const { setFieldValue, values } = useFormikContext<SecondaryFiltersForm>();
  const { sourceFilters } = values;

  const agentMode = useSelector(getAgentMode);
  const isAgent = useSelector(getIsAgent);
  const statusChanged = useSelector($getStatusChanged);
  const loginStatus = useSelector(state => state.permissions.loginStatus);
  const disablePrivateStatus =
    props.disableFilter ||
    (not(sourceFilters.exclusiveListingsEnabled) &&
      sourceFilters.mlsListingsEnabled);

  const showPrivateMlsStatus = isAgent && not(agentMode) && not(isLeasePage);

  const toggleSoldListings = () => {
    const isSold = not(field.value.includes(MlsStatus.Sold));

    setFieldValue(
      'timeFrameFilters.soldTimeframe',
      isSold ? TIME_FRAME_ONE_YEAR_VALUE : TIME_FRAME_DEFAULT_VALUE
    );

    if (isSold) {
      setFieldValue('showOnlyFilters.openHouse', OPEN_HOUSE_DEFAULT_VALUE);
      setFieldValue('showOnlyFilters.openHouseOnly', false);
    }
  };

  const toggleLeasedListings = () => {
    const isLeased = not(field.value.includes(MlsStatus.Leased));

    setFieldValue(
      'timeFrameFilters.leasedTimeframe',
      isLeased ? TIME_FRAME_ONE_YEAR_VALUE : TIME_FRAME_DEFAULT_VALUE
    );

    if (isLeased) {
      setFieldValue('showOnlyFilters.openHouse', OPEN_HOUSE_DEFAULT_VALUE);
      setFieldValue('showOnlyFilters.openHouseOnly', false);
    }
  };

  const { isStatusFiltersDisabled } = permissionsConfig[loginStatus];
  const gtmTargetName = 'status filter';

  return (
    <FormSection>
      <FilterHeaderContainer>
        {statusChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Status
        </FilterHeaderTitle>
      </FilterHeaderContainer>

      <Checkboxes style={{ display: 'flex', flexWrap: 'wrap' }}>
        {showPrivateMlsStatus && (
          <CheckboxWrapper style={{ order: 1 }}>
            <CheckboxField
              name={FilterKey.MlsStatus}
              value={MlsStatus.Private}
              key={MlsStatus.Private}
              disabled={disablePrivateStatus}
              ariaLabel={MlsStatus.Private}
              labelText={MlsStatus.Private}
              checked={field.value.includes(MlsStatus.Private)}
              isCheckboxAuthLock
            />
          </CheckboxWrapper>
        )}
        <CheckboxWrapper style={{ order: showPrivateMlsStatus ? 3 : 1 }}>
          <CheckboxField
            name={FilterKey.MlsStatus}
            value={MlsStatus.Active}
            key={MlsStatus.Active}
            disabled={props.disableFilter}
            ariaLabel={MlsStatus.Active}
            labelText={MlsStatus.Active}
            checked={field.value.includes(MlsStatus.Active)}
            isCheckboxAuthLock
          />
        </CheckboxWrapper>
        <CheckboxWrapper style={{ order: showPrivateMlsStatus ? 2 : 2 }}>
          <CheckboxField
            name={FilterKey.MlsStatus}
            value={MlsStatus.Pending}
            key={MlsStatus.Pending}
            disabled={props.disableFilter || isStatusFiltersDisabled}
            ariaLabel={MlsStatus.Pending}
            labelCmp={
              <>
                {MlsStatus.Pending}{' '}
                {isStatusFiltersDisabled && (
                  <CheckboxAuthLock
                    loginStatus={loginStatus}
                    gtmClickTargetName={gtmTargetName}
                  />
                )}
              </>
            }
            checked={field.value.includes(MlsStatus.Pending)}
            isCheckboxAuthLock
          />
        </CheckboxWrapper>

        <CheckboxWrapper style={{ order: showPrivateMlsStatus ? 5 : 4 }}>
          <CheckboxField
            name={FilterKey.MlsStatus}
            value={MlsStatus.PendingTakingBackups}
            key={MlsStatus.PendingTakingBackups}
            disabled={props.disableFilter}
            ariaLabel={MlsStatus.PendingTakingBackups}
            labelText={MlsStatus.PendingTakingBackups}
            checked={field.value.includes(MlsStatus.PendingTakingBackups)}
            isCheckboxAuthLock
          />
        </CheckboxWrapper>

        <CheckboxWrapper style={{ order: showPrivateMlsStatus ? 4 : 5 }}>
          {isLeasePage ? (
            <CheckboxField
              name={FilterKey.MlsStatus}
              value={MlsStatus.Leased}
              key={MlsStatus.Leased}
              ariaLabel={MlsStatus.Leased}
              onChange={toggleLeasedListings}
              labelCmp={
                <>
                  Leased{' '}
                  {isStatusFiltersDisabled && (
                    <CheckboxAuthLock
                      loginStatus={loginStatus}
                      gtmClickTargetName={gtmTargetName}
                    />
                  )}
                </>
              }
              disabled={props.disableFilter || isStatusFiltersDisabled}
              checked={field.value.includes(MlsStatus.Leased)}
              isCheckboxAuthLock
            />
          ) : (
            <CheckboxField
              name={FilterKey.MlsStatus}
              value={MlsStatus.Sold}
              key={MlsStatus.Sold}
              onChange={toggleSoldListings}
              ariaLabel="Sold"
              labelCmp={
                <>
                  Sold{' '}
                  {isStatusFiltersDisabled && (
                    <CheckboxAuthLock
                      loginStatus={loginStatus}
                      gtmClickTargetName={gtmTargetName}
                    />
                  )}
                </>
              }
              disabled={props.disableFilter || isStatusFiltersDisabled}
              checked={field.value.includes(MlsStatus.Sold)}
              isCheckboxAuthLock
            />
          )}
        </CheckboxWrapper>
      </Checkboxes>
    </FormSection>
  );
};

export default StatusFilter;
