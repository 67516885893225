import { DrawingStatus, MapViewType } from 'map/mapConstants';
import { Boundary, Cluster, MapState, RaPolygon } from 'map/mapTypes';
import { ImageInfo } from 'shared/types';

export enum MapActionType {
  ADD_MAP_DRAWING_POLYGON = 'ADD_MAP_DRAWING_POLYGON',
  CHANGE_MAP_DRAWING_POLYGON = 'CHANGE_MAP_DRAWING_POLYGON',
  CHANGE_MAP_DRAWING_STATUS = 'CHANGE_MAP_DRAWING_STATUS',
  CHANGE_MAP_POSITION = 'CHANGE_MAP_POSITION',
  CHANGE_MAP_POSITION_FROM_URL = 'CHANGE_MAP_POSITION_FROM_URL',
  CHANGE_MAP_SIZE = 'CHANGE_MAP_SIZE',
  CHANGE_MAP_VIEW_TYPE = 'CHANGE_MAP_VIEW_TYPE',
  CLEAR_MAP_DATA = 'CLEAR_MAP_DATA',
  FETCH_MARKER_IMAGES = 'FETCH_MARKER_IMAGES',
  FETCH_MARKER_IMAGES_SUCCESS = 'FETCH_MARKER_IMAGES_SUCCESS',
  FETCH_POLYGONS = 'FETCH_POLYGONS',
  FETCH_POLYGONS_SUCCESS = 'FETCH_POLYGONS_SUCCESS',
  FORCE_TOGGLE_MAP = 'FORCE_TOGGLE_MAP',
  HANDLE_CLUSTER_CLICK = 'HANDLE_CLUSTER_CLICK',
  HANDLE_MAP_CHANGE = 'HANDLE_MAP_CHANGE',
  HANDLE_MAP_LOADING = 'HANDLE_MAP_LOADING',
  INITIALIZE_MAP = 'INITIALIZE_MAP',
  SAVE_CLUSTERS = 'SAVE_CLUSTERS',
  SOFT_CLEAR_MAP_DATA = 'SOFT_CLEAR_MAP_DATA',
  TOGGLE_MAP = 'TOGGLE_MAP',
  FETCH_CLUSTERS = 'FETCH_CLUSTERS',
  RESET_POLYGONS = 'RESET_POLYGONS',
  SAVE_DRAWING_PALYGON_FAIL = 'SAVE_DRAWING_PALYGON_FAIL',
  SET_IS_MAP_VISIBLE = 'SET_IS_MAP_VISIBLE',
}

export interface BaseMapAction {
  mapType: keyof MapState;
}

export interface ToggleMapAction extends BaseMapAction {
  type: MapActionType.TOGGLE_MAP;
  isMapVisible: boolean;
  mobileOnly: boolean;
  isMobile: boolean;
}

// eslint-disable-next-line max-params
export function toggleMap(
  mapType: keyof MapState,
  isMapVisible: boolean,
  mobileOnly: boolean,
  isMobile: boolean
): ToggleMapAction {
  return {
    type: MapActionType.TOGGLE_MAP,
    mapType,
    isMapVisible,
    mobileOnly,
    isMobile,
  };
}

export interface HandleMapChangeAction extends BaseMapAction {
  type: MapActionType.HANDLE_MAP_CHANGE;
  zoom: number;
  boundaries: Boundary;
}

export function handleMapChange(
  mapType: keyof MapState,
  zoom: number,
  boundaries: Boundary
): HandleMapChangeAction {
  return {
    type: MapActionType.HANDLE_MAP_CHANGE,
    mapType,
    zoom,
    boundaries,
  };
}

export interface ChangeMapSizeAction extends BaseMapAction {
  type: MapActionType.CHANGE_MAP_SIZE;
  size: { width: number; height: number };
  boundaries?: Boundary;
}

export function changeMapSize(
  mapType: keyof MapState,
  size: {
    width: number;
    height: number;
  },
  boundaries?: Boundary
): ChangeMapSizeAction {
  return {
    type: MapActionType.CHANGE_MAP_SIZE,
    mapType,
    size,
    boundaries,
  };
}

export interface ChangeMapPositionAction extends BaseMapAction {
  type: MapActionType.CHANGE_MAP_POSITION;
  boundaries: Boundary;
  zoom: number;
}

export function changeMapPosition(
  mapType: keyof MapState,
  boundaries: Boundary,
  zoom: number
): ChangeMapPositionAction {
  return {
    type: MapActionType.CHANGE_MAP_POSITION,
    mapType,
    boundaries,
    zoom,
  };
}

export interface ChangeMapPositionFromUrlAction extends BaseMapAction {
  type: MapActionType.CHANGE_MAP_POSITION_FROM_URL;
  boundaries: Boundary;
  zoom?: number;
}

export function changeMapPositionFromUrl(
  mapType: keyof MapState,
  boundaries: Boundary,
  zoom?: number
): ChangeMapPositionFromUrlAction {
  return {
    type: MapActionType.CHANGE_MAP_POSITION_FROM_URL,
    mapType,
    boundaries,
    zoom,
  };
}

export interface ClearMapDataAction extends BaseMapAction {
  type: MapActionType.CLEAR_MAP_DATA;
  leaveIsMapVisible?: boolean;
}

export function clearMapData(
  mapType: keyof MapState,
  leaveIsMapVisible = false
): ClearMapDataAction {
  return {
    type: MapActionType.CLEAR_MAP_DATA,
    mapType,
    leaveIsMapVisible,
  };
}

export interface SoftClearMapDataAction extends BaseMapAction {
  type: MapActionType.SOFT_CLEAR_MAP_DATA;
}

export function softClearMapData(
  mapType: keyof MapState
): SoftClearMapDataAction {
  return {
    type: MapActionType.SOFT_CLEAR_MAP_DATA,
    mapType,
  };
}

export interface InitializeMapAction extends BaseMapAction {
  type: MapActionType.INITIALIZE_MAP;
}

export function initializeMap(mapType: keyof MapState): InitializeMapAction {
  return {
    type: MapActionType.INITIALIZE_MAP,
    mapType,
  };
}

export interface FetchPolygonsSuccessAction extends BaseMapAction {
  type: MapActionType.FETCH_POLYGONS_SUCCESS;
  polygons: {
    id: string;
  };
}

export function fetchPolygonsSuccess(
  mapType: keyof MapState,
  polygons: {
    id: string;
  }
): FetchPolygonsSuccessAction {
  return { type: MapActionType.FETCH_POLYGONS_SUCCESS, mapType, polygons };
}

export interface FetchPolygonsAction extends BaseMapAction {
  type: MapActionType.FETCH_POLYGONS;
}

export function fetchPolygons(mapType: keyof MapState): FetchPolygonsAction {
  return {
    type: MapActionType.FETCH_POLYGONS,
    mapType,
  };
}

export interface ResetPolygonsAction extends BaseMapAction {
  type: MapActionType.RESET_POLYGONS;
}

export function resetPolygons(mapType: keyof MapState): ResetPolygonsAction {
  return {
    type: MapActionType.RESET_POLYGONS,
    mapType,
  };
}

export interface FetchMarkerImagesAction extends BaseMapAction {
  type: MapActionType.FETCH_MARKER_IMAGES;
  markerKey: string;
}

export function fetchMarkerImages(
  mapType: keyof MapState,
  markerKey: string
): FetchMarkerImagesAction {
  return { type: MapActionType.FETCH_MARKER_IMAGES, mapType, markerKey };
}

export interface FetchMarkerImagesSuccessAction extends BaseMapAction {
  type: MapActionType.FETCH_MARKER_IMAGES_SUCCESS;
  markerKey: string;
  images: ImageInfo[];
}

export function fetchMarkerImagesSuccess(
  mapType: keyof MapState,
  markerKey: string,
  images: ImageInfo[]
): FetchMarkerImagesSuccessAction {
  return {
    type: MapActionType.FETCH_MARKER_IMAGES_SUCCESS,
    mapType,
    markerKey,
    images,
  };
}

export interface HandleClusterClickAction extends BaseMapAction {
  type: MapActionType.HANDLE_CLUSTER_CLICK;
  boundaries: Boundary;
  zoom: number;
}

export function handleClusterClick(
  mapType: keyof MapState,
  zoom: number,
  boundaries: Boundary
): HandleClusterClickAction {
  return {
    type: MapActionType.HANDLE_CLUSTER_CLICK,
    mapType,
    zoom,
    boundaries,
  };
}

export interface SaveClustersAction extends BaseMapAction {
  type: MapActionType.SAVE_CLUSTERS;
  clusters: Cluster[];
}

export function saveClusters(
  mapType: keyof MapState,
  clusters: Cluster[]
): SaveClustersAction {
  return {
    type: MapActionType.SAVE_CLUSTERS,
    mapType,
    clusters,
  };
}

export interface ChangeMapDrawingStatusAction extends BaseMapAction {
  type: MapActionType.CHANGE_MAP_DRAWING_STATUS;
  drawingStatus: DrawingStatus;
}

export function changeMapDrawingStatus(
  mapType: keyof MapState,
  status: DrawingStatus
): ChangeMapDrawingStatusAction {
  return {
    type: MapActionType.CHANGE_MAP_DRAWING_STATUS,
    mapType,
    drawingStatus: status,
  };
}

export interface ChangeDrawingPolygonsAction extends BaseMapAction {
  type: MapActionType.CHANGE_MAP_DRAWING_POLYGON;
  feature: RaPolygon;
}

export function changeMapDrawingPolygons(
  mapType: keyof MapState,
  feature: RaPolygon
): ChangeDrawingPolygonsAction {
  return {
    type: MapActionType.CHANGE_MAP_DRAWING_POLYGON,
    mapType,
    feature,
  };
}

export interface AddMapDrawingPolygon extends BaseMapAction {
  type: MapActionType.ADD_MAP_DRAWING_POLYGON;
}

export function addMapDrawingPolygon(
  mapType: keyof MapState
): AddMapDrawingPolygon {
  return {
    type: MapActionType.ADD_MAP_DRAWING_POLYGON,
    mapType,
  };
}

export interface HandleMapLoading extends BaseMapAction {
  type: MapActionType.HANDLE_MAP_LOADING;
  loading: boolean;
}

export function handleMapLoading(
  mapType: keyof MapState,
  loading: boolean
): HandleMapLoading {
  return {
    type: MapActionType.HANDLE_MAP_LOADING,
    mapType,
    loading,
  };
}

export interface ChangeMapViewType extends BaseMapAction {
  type: MapActionType.CHANGE_MAP_VIEW_TYPE;
  mapViewType: MapViewType;
  streetViewActive: boolean;
}

export function changeMapViewType(
  mapType: keyof MapState,
  mapViewType: MapViewType,
  streetViewActive: boolean
): ChangeMapViewType {
  return {
    type: MapActionType.CHANGE_MAP_VIEW_TYPE,
    mapType,
    mapViewType,
    streetViewActive,
  };
}

export interface ForceToggleMapAction extends BaseMapAction {
  type: MapActionType.FORCE_TOGGLE_MAP;
  forceHideMap: boolean;
}

export function forceToggleMap(mapType: keyof MapState, forceHideMap: boolean) {
  return {
    type: MapActionType.FORCE_TOGGLE_MAP,
    mapType,
    forceHideMap,
  };
}

export interface HandleFetchClusters extends BaseMapAction {
  type: MapActionType.FETCH_CLUSTERS;
  boundaries: Boundary;
  zoom: number;
}

export function fetchClusters(
  mapType: keyof MapState,
  zoom: number,
  boundaries: Boundary
): HandleFetchClusters {
  return {
    type: MapActionType.FETCH_CLUSTERS,
    mapType,
    zoom,
    boundaries,
  };
}

export interface SaveDrawingPolygonFailAction extends BaseMapAction {
  type: MapActionType.SAVE_DRAWING_PALYGON_FAIL;
}

export function saveDrawingPolygonFail(
  mapType: keyof MapState
): SaveDrawingPolygonFailAction {
  return { type: MapActionType.SAVE_DRAWING_PALYGON_FAIL, mapType };
}

export interface SetIsMapVisibleAction extends BaseMapAction {
  type: MapActionType.SET_IS_MAP_VISIBLE;
  isMapVisible: boolean;
}

export function setIsMapVisible(
  mapType: keyof MapState,
  isMapVisible: boolean
) {
  return { type: MapActionType.SET_IS_MAP_VISIBLE, mapType, isMapVisible };
}

export type MapAction =
  | AddMapDrawingPolygon
  | ChangeDrawingPolygonsAction
  | ChangeMapDrawingStatusAction
  | ChangeMapPositionAction
  | ChangeMapPositionFromUrlAction
  | ChangeMapSizeAction
  | ChangeMapViewType
  | ClearMapDataAction
  | FetchMarkerImagesAction
  | FetchMarkerImagesSuccessAction
  | FetchPolygonsAction
  | ResetPolygonsAction
  | FetchPolygonsSuccessAction
  | ForceToggleMapAction
  | HandleClusterClickAction
  | HandleMapChangeAction
  | HandleMapLoading
  | InitializeMapAction
  | SaveClustersAction
  | SoftClearMapDataAction
  | ToggleMapAction
  | SaveDrawingPolygonFailAction
  | SetIsMapVisibleAction;
