import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { getAgentMode, getContactItem } from 'contacts/contactsSelectors';
import { SavedSearch } from 'saved-search/savedSearchTypes';
import { SavedSearchSearchBarConnectProps } from 'shared/components/SearchBar/components/SearchBarSavedSearches';
import { SearchBarSavedSearchItem } from 'shared/components/SearchBar/components/SearchBarSavedSearches/SearchBarSavedSearchesItem';
import SearchBarStyled, {
  OptionIconStyled,
  SubHeaderWrapper,
} from 'shared/components/SearchBar/SearchBarStyled';
import ShowMore from 'shared/components/ShowMore';
import { RoutePath } from 'shared/constants/routesConstants';
import { useSelector } from 'shared/helpers/redux';
import { ReactComponent as IconHeart } from 'shared/icons/shared/16x16/heart.svg';
import { getIsAgent } from 'user/userSelectors';

const { OptionGroup, OptionGroupContainer } = SearchBarStyled;

export const SearchBarSavedSearchesOptionGroup = styled(OptionGroup)`
  border: none;
  padding-left: 0px;
`;

export type SearchBarSavedSearchesProps = {
  savedSearches: SavedSearch[];
  scrollableNode?: HTMLDivElement;
  closeMenu: () => void;
} & SavedSearchSearchBarConnectProps;

const SearchBarSavedSearches: React.FC<SearchBarSavedSearchesProps> = props => {
  const [savedSearchId, setSavedSearchId] = useState<string>('');

  const history = useHistory();
  const contactItem = useSelector(getContactItem);
  const agentMode = useSelector(getAgentMode);
  const isAgent = useSelector(getIsAgent);
  const isUserSavedSearch = Boolean(useRouteMatch(RoutePath.SAVED_SEARCH));
  const isAgentOnContactSavedSearch = Boolean(
    useRouteMatch(RoutePath.AGENT_SAVED_SEARCH)
  );

  useEffect(() => {
    if ((isUserSavedSearch || isAgentOnContactSavedSearch) && savedSearchId) {
      props.closeMenu();
      props.resetFilters({
        agentMode,
        isAgent,
      });
      props.clearMapData();
      props.fetchSavedSearch(savedSearchId, true, {}, contactItem?.username);
    }
  }, [savedSearchId]);

  const handleItemClick = (savedSearchId: string) => {
    const path =
      agentMode && contactItem?.username
        ? generatePath(RoutePath.AGENT_SAVED_SEARCH, {
            contactId: contactItem.username,
            savedSearchId,
          })
        : generatePath(RoutePath.SAVED_SEARCH, { savedSearchId });

    history.push(path);
    setSavedSearchId(savedSearchId);
  };

  return (
    <OptionGroupContainer>
      <SubHeaderWrapper>
        <OptionIconStyled>
          <IconHeart />
        </OptionIconStyled>
        <SearchBarSavedSearchesOptionGroup>
          {agentMode && contactItem?.firstname && `${contactItem.firstname}'s `}
          Saved Searches
        </SearchBarSavedSearchesOptionGroup>
      </SubHeaderWrapper>

      <ShowMore
        lines={3}
        truncateChildren
        scrollableNode={props.scrollableNode}
      >
        {props.savedSearches.map(savedSearch => {
          return (
            <SearchBarSavedSearchItem
              key={savedSearch.id}
              onClick={handleItemClick}
              closeMenu={props.closeMenu}
              savedSearch={savedSearch}
            />
          );
        })}
      </ShowMore>
    </OptionGroupContainer>
  );
};

export default SearchBarSavedSearches;
