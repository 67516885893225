import { TheUnion } from 'shared/helpers/fp/types';

interface Fail<Error> {
  ok: false;
  error: Error;
}

interface Ok<Value> {
  ok: true;
  value: Value;
}

/** Result is just a very simplified version of Either monad */
type Result<Error, Value> = TheUnion<Fail<Error> | Ok<Value>>;

const ok = <Error, Value>(value: Value): Result<Error, Value> => {
  return { ok: true, value };
};

const fail = <Error, Value>(error: Error): Result<Error, Value> => {
  return { ok: false, error };
};

/** Wraps result of a promise */
const fromThunk = async <Error, Value>(
  thunk: () => Promise<Value | unknown>
): Promise<Result<Error, Value>> => {
  try {
    const value = await thunk();
    return ok(value as Value);
  } catch (error) {
    return fail(error as Error);
  }
};

const makeWrapper =
  <P extends readonly any[], Value, Error = any>(
    asyncFn: (...args: P) => Promise<Value>
  ) =>
  <E = Error>(...args: P) =>
    fromThunk<E, Value>(() => asyncFn(...args));

/** Unwraps the value and throws if there is an error inside */
const unwrap = <Error, Value>(result: Result<Error, Value>): Value => {
  if (result.ok === true) {
    return result.value;
  }
  throw result.error;
};

const map = <Error, Value, NextValue>(
  result: Result<Error, Value>,
  fn: (value: Value) => NextValue
): Result<Error, NextValue> => {
  return result.ok === true ? ok(fn(result.value)) : result;
};

const getOrElse = <Error, Value, Else = Value>(
  result: Result<Error, Value>,
  orElse: () => Else | Value
): Value | Else => {
  return result.ok === true ? result.value : orElse();
};

const fold = <Error, Value, A, B>(
  result: Result<Error, Value>,
  onOk: (value: Value) => A,
  onFail: (error: Error) => B
): A | B => {
  return result.ok === true ? onOk(result.value) : onFail(result.error);
};

export const result = {
  fail,
  fold,
  fromThunk,
  getOrElse,
  makeWrapper,
  map,
  ok,
  unwrap,
};
