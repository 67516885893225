import { generatePath } from 'react-router-dom';
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  AcceptCollectionInvitation,
  declineCollectionFail,
  DeclineCollectionInvitation,
  declineCollectionSuccess,
  fetchCollection,
  fetchCollections,
  FetchCollectionsAction,
  fetchCollectionsFail,
  fetchCollectionsInvitations,
  joinCollection,
  joinCollectionFail,
  JoinCollectionRequestAction,
  RecommendListingsAction,
  removeCollectionFail,
  removeCollectionItem,
  RemoveCollectionItemRequestAction,
  removeCollectionItemRequestFail,
  RemoveCollectionRequestAction,
  removeCollectionRequestSuccess,
  removeFromDismissed,
  RemoveFromDismissedAction,
  saveCollection,
  SaveCollectionAction,
  saveCollectionRequestFail,
  saveCollectionSuccess,
  SaveCollectionSuccessAction,
  SaveListingToCollectionAction,
  saveListingToCollectionSuccess,
  SendCollectionInvitationReminderAction,
  setCollections,
  ShareCollectionRequestAction,
  StartDeclineCollectionAction,
  StartRemoveCollectionAction,
  undoRemoveCollection,
  UnshareCollectionRequestAction,
  updateCollection,
  updateCollectionRequest,
  UpdateCollectionRequestAction,
  updateCollectionsInvitations,
} from 'collections/collectionsActions';
import { CollectionsActionType } from 'collections/collectionsActionType';
import {
  isRawCollection,
  mapToRecommendListingsData,
} from 'collections/collectionsHelpers';
import {
  getCollection,
  getCollectionsToRemove,
} from 'collections/collectionsSelectors';
import { CollectionService } from 'collections/CollectionsService';
import {
  Collection,
  CollectionBody,
  CollectionFilter,
  CollectionsState,
} from 'collections/collectionsTypes';
import { saveComment } from 'comments/commentsActions';
import {
  getAgentMode,
  getContactItem,
  getContactUsername,
} from 'contacts/contactsSelectors';
import { Contact, ContactsState } from 'contacts/contactsTypes';
import { toggleDismissListing } from 'listingDetails/listingDetailsActions';
import {
  addListingRecommendation,
  fetchListings,
} from 'listings/listingsActions';
import { Listing } from 'listings/listingsTypes';
import { getMapType } from 'map/mapSagas';
import { RootState } from 'rootReducer';
import { NotificationFrequencyOptionValue } from 'saved-search/savedSearchTypes';
import { GTM_TOASTERS_IDS } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { not, notEmpty } from 'shared/helpers/boolean';
import { callIfOnline } from 'shared/helpers/network';
import { showErrorToast, showSuccessToast } from 'shared/helpers/notifications';
import { safeSelect } from 'shared/helpers/saga';
import { getProfileData } from 'shared/selectors/profileSelector';
import history from 'shared/services/history';
import { Invitation, UnpackReturnedPromise } from 'shared/types';
import { startUpdateProfile } from 'user/userActions';
import { waitForProfile } from 'user/userSagas';
import { getUserId } from 'user/userSelectors';
import { UserProfile } from 'user/userTypes';

const SAVE_LISTING_TO_COLLECTION_ERROR_MESSAGE =
  'Unable to add listing to collection';

function* setRecommendation(listingIds: string[], owner?: string): Saga {
  const userId = yield select(getUserId);
  const agentMode: boolean = yield select(getAgentMode);
  const isRecommendation = agentMode && userId !== owner;
  const mapType = yield call(getMapType);

  if (isRecommendation && owner) {
    yield put(addListingRecommendation(listingIds, owner, userId, mapType));
  }
}

function* fetchCollectionsSaga(action: FetchCollectionsAction): Saga {
  try {
    yield waitForProfile();

    const { collectionsFilterBy }: CollectionsState = yield select(
      (state: RootState) => state.collections
    );
    const agentMode: boolean = yield select(getAgentMode);
    const contact = yield select(getContactItem);
    const user = yield select(getProfileData);

    const userId = agentMode ? contact.username : user?.username;
    const createdById =
      collectionsFilterBy === CollectionFilter.All ? '' : user.username;

    const collections: UnpackReturnedPromise<
      typeof CollectionService.fetchCollections
    > = yield call(CollectionService.fetchCollections, {
      excludeDismissed:
        action.excludeDismissed ||
        (agentMode && collectionsFilterBy === CollectionFilter.CreatedByMe),
      excludeProposed:
        action.excludeProposed ||
        collectionsFilterBy === CollectionFilter.CreatedByMe,
      user: userId,
      createdBy: createdById,
    });

    yield put(setCollections(collections));
  } catch (e) {
    yield put(fetchCollectionsFail(e.message));
  }
}

function* saveCollectionSaga(action: SaveCollectionAction): Saga {
  try {
    const {
      name,
      description = '',
      listing,
      comment,
      owner,
      notNotifyUser,
    } = action.payload;
    const { silentUpdate } = action;
    const contact: Contact = yield select(getContactItem);
    const agentMode: boolean = yield select(getAgentMode);
    const user: UserProfile = yield select(getProfileData);
    const mapType = yield call(getMapType, action);

    const listings = listing ? [listing.listingid] : [];
    const reqBody: CollectionBody = {
      name,
      description,
      listings,
      notNotifyUser,
    };

    if (agentMode) {
      reqBody.owner = contact.username;
    }

    const collection: UnpackReturnedPromise<
      typeof CollectionService.saveCollection
    > = yield call(CollectionService.saveCollection, reqBody);

    yield setRecommendation(listings, collection.owner);

    if (listing?.dismissed) {
      yield put(removeFromDismissed(listing, false));
    }

    yield put(
      saveCollectionSuccess(
        collection.id,
        mapType,
        collection,
        listing?.listingid
      )
    );

    const isOptedOutEmail = agentMode
      ? contact.hasOptedOutOfEmail
      : user.hasOptedOutOfEmail;

    // Set notification status for new Collection to never if user has opted out of email notifications
    if (isOptedOutEmail) {
      const {
        id,
        name,
        agentNotified: notifyAgent,
        notifications,
        owner,
        description,
      } = collection;

      yield put(
        updateCollectionRequest({
          data: {
            id,
            name,
            description,
            notifyAgent,
            owner,
            notifications: {
              ...notifications,
              frequency: NotificationFrequencyOptionValue.Never,
            },
          },
          silentUpdate: true,
        })
      );
    }

    const redirectPath = agentMode
      ? generatePath(RoutePath.AGENT_COLLECTION, {
          contactId: collection.owner,
          collectionId: collection.id,
        })
      : generatePath(RoutePath.COLLECTION, { collectionId: collection.id });

    const shouldRedirect = collection.id && not(listing) && not(silentUpdate);

    if (shouldRedirect) {
      history.push(redirectPath);
    }

    if (not(silentUpdate)) {
      showSuccessToast(
        listing
          ? `Listing added to "${collection.name}" collection`
          : `Collection "${collection.name}" has been saved`,
        {
          onViewClick: not(shouldRedirect)
            ? () => history.push(redirectPath)
            : undefined,
          toastDomId: listing ? GTM_TOASTERS_IDS.ADD_TO_FAVORITE : '',
        }
      );
    }

    if (comment && listing) {
      yield put(
        saveComment({
          id: collection.id,
          listingid: listing.listingid,
          comment,
          owner,
          withoutUpdate: true,
          notNotifyUser,
        })
      );
    }
  } catch (e) {
    yield put(saveCollectionRequestFail(e.message));
    showErrorToast(SAVE_LISTING_TO_COLLECTION_ERROR_MESSAGE);
  }

  action.payload.cb?.();
}

function* updateCollectionSaga(action: UpdateCollectionRequestAction): Saga {
  try {
    const {
      id,
      name,
      owner,
      description,
      collaboratorEmails,
      collaboratorsToRemove,
      isEmailNotificationsSettings,
      notifications,
      notifyAgent,
    } = action.body.data;

    const { silentUpdate } = action.body;
    const user: UserProfile = yield select(getProfileData);
    const contactId = yield select(getContactUsername);

    const collection: UnpackReturnedPromise<
      typeof CollectionService.updateCollection
    > = yield call(CollectionService.updateCollection, id, {
      name,
      owner,
      description,
      notifications,
      notifyAgent: Boolean(notifyAgent),
    });

    if (
      user?.hasOptedOutOfEmail &&
      notifications.frequency !== NotificationFrequencyOptionValue.Never
    ) {
      yield put(
        startUpdateProfile({
          data: { hasOptedOutOfEmail: false },
        })
      );
    }

    // Please do not refactor. Requests are called here to get consistent result.
    if (collaboratorsToRemove && notEmpty(collaboratorsToRemove)) {
      // We have to remove agent-collaborator if he/she was already removed through notify field in prev request
      const actualIds = collection.collaborators.map(({ id }) => id);
      const actualCollaborators = collaboratorsToRemove.filter(id =>
        actualIds.includes(id)
      );
      yield all(
        actualCollaborators.map(collaboratorId => {
          return call(CollectionService.removeCollaborator, id, collaboratorId);
        })
      );
    }

    if (collaboratorEmails && notEmpty(collaboratorEmails)) {
      yield call(CollectionService.createCollaborator, id, collaboratorEmails);
    }

    if (not(silentUpdate)) {
      yield put(
        fetchCollection({
          collectionId: collection.id,
          contactId: contactId,
          shouldUpdateListingsStore: false,
        })
      );
      showSuccessToast(`Changes to "${collection.name}" saved`, {
        // onUndoClick: () => {},
      });
    }

    if (isEmailNotificationsSettings && silentUpdate) {
      yield put(
        fetchCollections({
          silentUpdate,
          excludeDismissed: true,
          excludeProposed: true,
        })
      );
      showSuccessToast(`Changes to "${collection.name}" saved`);
    }

    action.body.onSuccess?.();
  } catch (e) {
    showErrorToast(`Unable to apply changes to Collection`);
  }

  action.body.cb?.();
}

function* declineCollectionSaga(action: StartDeclineCollectionAction) {
  try {
    const collectionsToRemove: string[] = yield select(getCollectionsToRemove);
    if (!collectionsToRemove.includes(action.id)) {
      return;
    }
    yield call(
      CollectionService.declineCollection,
      action.id,
      action.contactId
    );
    yield put(declineCollectionSuccess(action.id));
  } catch (e) {
    showErrorToast(`Unable to decline Collection "${action.name}`);
    yield put(declineCollectionFail(e.message));
    yield put(undoRemoveCollection(action.id));
  }
}

function* startRemoveCollectionSaga(action: StartRemoveCollectionAction) {
  const agentMode: boolean = yield select(getAgentMode);
  const agent: ContactsState = yield select(({ contacts }) => contacts);
  const contactId = agent.contact.item?.username;
  if (agentMode && contactId) {
    action.cb?.(generatePath(RoutePath.AGENT_COLLECTIONS, { contactId }));
  } else {
    action.cb?.(RoutePath.COLLECTIONS);
  }
}

function* removeCollectionSaga(action: RemoveCollectionRequestAction) {
  try {
    const collectionsToRemove: string[] = yield select(getCollectionsToRemove);
    if (!collectionsToRemove.includes(action.id)) {
      return;
    }

    yield call(CollectionService.removeCollection, action.id, action.owner);
    yield put(removeCollectionRequestSuccess(action.id));
  } catch (e) {
    showErrorToast(`Unable to delete Collection "${action.name}"`);
    yield put(removeCollectionFail(e.message));
    yield put(undoRemoveCollection(action.id));
  }
}

function* removeCollectionItemSaga(action: RemoveCollectionItemRequestAction) {
  try {
    yield call(
      CollectionService.removeCollectionItem,
      action.collectionId,
      action.listingId,
      action.owner
    );

    const listingToRemove: Listing | undefined = yield safeSelect(state => {
      return state.listings.items.find(
        item => item.listingid === action.listingId
      );
    });
    const collection: Collection = yield safeSelect(state => {
      return state.collections.collection;
    });

    yield put(removeCollectionItem(action.listingId));

    showSuccessToast(
      `"${listingToRemove?.address}" was removed from "${collection.name}" Collection`
    );

    const contactId: string = yield safeSelect(getContactUsername);

    yield put(
      fetchCollection({ collectionId: action.collectionId, contactId })
    );
  } catch (e) {
    yield put(removeCollectionItemRequestFail(e.message));
  }
}

function* saveListingToCollectionSaga(
  action: SaveListingToCollectionAction
): Saga {
  try {
    const { id, listing, owner, comment, notNotifyUser } = action.payload;

    const agentMode: boolean = yield select(getAgentMode);
    const agentContact: string = yield select(getContactUsername);
    const mapType = yield call(getMapType, action);
    const collection: UnpackReturnedPromise<
      typeof CollectionService.addItemToCollection
    > = yield call(CollectionService.addItemToCollection, {
      collectionId: id,
      listingId: listing.listingid,
      owner,
      notNotifyUser,
    });

    yield setRecommendation([listing.listingid], owner);

    if (listing.dismissed) {
      yield put(removeFromDismissed(listing, false));
    }

    yield put(
      saveListingToCollectionSuccess(id, listing.listingid, mapType, collection)
    );

    if (Boolean(comment)) {
      yield put(
        saveComment({
          id,
          listingid: listing.listingid,
          comment,
          owner,
          withoutUpdate: true,
          notNotifyUser,
        })
      );
    }

    const redirectPath = agentMode
      ? generatePath(RoutePath.AGENT_COLLECTION, {
          contactId: agentContact,
          collectionId: collection.id,
        })
      : generatePath(RoutePath.COLLECTION, { collectionId: collection.id });

    showSuccessToast(`Listing added to "${collection.name}" collection`, {
      onViewClick: () => history.push(redirectPath),
      toastDomId: GTM_TOASTERS_IDS.ADD_TO_FAVORITE,
    });
  } catch (e) {
    showErrorToast(SAVE_LISTING_TO_COLLECTION_ERROR_MESSAGE);
    yield put(saveCollectionRequestFail(e.message));
  }

  if (action.payload.cb) {
    yield call(action.payload.cb);
  }
}

function* shareCollectionSaga(action: ShareCollectionRequestAction) {
  const { id, collaboratorEmails } = action;

  try {
    const collaborators: UnpackReturnedPromise<
      typeof CollectionService.createCollaborator
    > = yield call(
      CollectionService.createCollaborator,
      id,
      collaboratorEmails
    );

    const collection: Collection = yield select(getCollection);

    if (isRawCollection(collection)) {
      yield put(
        updateCollection({
          ...collection,
          collaborators: [...collection.collaborators, ...collaborators],
        })
      );
    }
  } catch (e) {
    showErrorToast('Unable to share collection');
  }
}

function* unshareCollectionSaga(action: UnshareCollectionRequestAction) {
  const { id, collaboratorIds } = action;

  try {
    yield all(
      collaboratorIds.map(function* (collaboratorId) {
        yield call(CollectionService.removeCollaborator, id, collaboratorId);
      })
    );

    const collection: Collection = yield select(getCollection);

    if (isRawCollection(collection)) {
      yield put(
        updateCollection({
          ...collection,
          collaborators: collection.collaborators.filter(
            collaborator => !collaboratorIds.includes(collaborator.id)
          ),
        })
      );
    }
  } catch (e) {
    showErrorToast('Unable to unshare collection');
  }
}

function* joinCollectionSaga(action: JoinCollectionRequestAction) {
  try {
    yield call(CollectionService.joinCollection, action.inviteCode);
    yield put(joinCollection());
    action.cb();
    showSuccessToast('Invitation accepted');
  } catch (e) {
    yield put(joinCollectionFail(e.message));
    showErrorToast('Unable to join Collection');
  }
}

function* removeFromDismissedSaga(action: RemoveFromDismissedAction) {
  const { listing } = action;

  try {
    const { state } = history.location;
    const collection: Collection = yield select(getCollection);
    const contactId: string = yield select(getContactUsername);

    yield call(
      CollectionService.removeCollectionItemFromDismissed,
      listing.listingid
    );

    yield put(toggleDismissListing(listing.listingid, false));
    // Should remove listing only if on Collections
    if (collection.id) {
      yield put(removeCollectionItem(listing.listingid));
    }
    showSuccessToast(`${listing.address} listing was removed from dismissed`);

    if (state?.modal && state?.mapType) {
      yield put(fetchListings(state.mapType));
    }

    if (action.redirect && collection.total <= 1) {
      history.push(RoutePath.COLLECTIONS);
    } else if (action.reFetchItems && collection.id) {
      yield put(fetchCollection({ collectionId: collection.id, contactId }));
    }
  } catch (e) {
    showErrorToast('Unable to remove from dismissed');
    yield put(removeCollectionItemRequestFail(e));
  }
}

export function* fetchCollectionsInvitationsSaga() {
  try {
    const collectionsInvitations: Invitation[] = yield call(
      CollectionService.fetchCollectionsInvitations
    );

    yield put(updateCollectionsInvitations(collectionsInvitations));
  } catch (e) {
    callIfOnline(() =>
      showErrorToast('Unable to fetch collections invitations')
    );
  }
}

export function* acceptCollectionInvitationSaga(
  action: AcceptCollectionInvitation
) {
  try {
    yield call(CollectionService.joinCollection, action.inviteCode);
    yield put(fetchCollectionsInvitations());
    yield put(fetchCollections({ excludeProposed: true }));
  } catch (e) {}
}

export function* declineCollectionInvitationSaga(
  action: DeclineCollectionInvitation
) {
  try {
    yield call(
      CollectionService.declineCollectionInvitation,
      action.collectionId
    );
    yield put(fetchCollectionsInvitations());
  } catch (e) {}
}

export function* sendCollectionInvitationReminderSaga(
  action: SendCollectionInvitationReminderAction
) {
  try {
    yield call(
      CollectionService.sendReminder,
      action.id,
      action.collaboratorId
    );

    showSuccessToast('Email is sent');
  } catch (e) {
    showErrorToast('Unable to send email');
  }
}

export function* recommendListingsSaga(action: RecommendListingsAction): Saga {
  const {
    listings,
    name,
    description,
    comments,
    id,
    owner,
    cb,
    notNotifyUser,
  } = action.payload;

  try {
    const agentContact: string = yield select(getContactUsername);
    const mapType = yield call(getMapType, action);
    let collectionId = id;

    if (name) {
      yield put(
        saveCollection(
          {
            name,
            description,
            owner,
          },
          true
        )
      );
      const action: SaveCollectionSuccessAction = yield take(
        CollectionsActionType.SAVE_COLLECTION_SUCCESS
      );
      collectionId = action.id;
    }

    const collection = yield call(
      CollectionService.recommendListings,
      collectionId,
      {
        owner,
        notNotifyUser,
        listings: mapToRecommendListingsData(listings, comments),
      }
    );

    yield setRecommendation(
      listings.map(listing => listing.listingid),
      owner
    );

    yield all(
      listings.map(listing => {
        if (listing.dismissed) {
          return put(removeFromDismissed(listing, false));
        }
        return undefined;
      })
    );

    yield all(
      listings.map(listing => {
        return put(
          saveListingToCollectionSuccess(
            collectionId,
            listing.listingid,
            mapType,
            collection.data.id
          )
        );
      })
    );

    const redirectPath = generatePath(RoutePath.AGENT_COLLECTION, {
      contactId: agentContact,
      collectionId,
    });

    showSuccessToast(`Listings added to "${collection.data.name}" collection`, {
      onViewClick: () => history.push(redirectPath),
      toastDomId: GTM_TOASTERS_IDS.ADD_TO_FAVORITE,
    });
  } catch (e) {
    showErrorToast('Unable to add listings');
  }

  cb?.();
}

const joinCollectionSub = () =>
  takeLatest(
    [CollectionsActionType.JOIN_COLLECTION_REQUEST],
    joinCollectionSaga
  );

const declineCollectionSub = () =>
  takeLatest(
    [CollectionsActionType.DECLINE_COLLECTION_REQUEST],
    declineCollectionSaga
  );

const fetchCollectionsSub = () =>
  takeLatest(
    [
      CollectionsActionType.FETCH_COLLECTIONS,
      CollectionsActionType.FILTER_COLLECTIONS,
    ],
    fetchCollectionsSaga
  );

const deleteCollectionItemSub = () =>
  takeLatest(
    [CollectionsActionType.REMOVE_COLLECTION_ITEM_REQUEST],
    removeCollectionItemSaga
  );

const startDeleteCollectionSub = () =>
  takeEvery(
    [CollectionsActionType.START_REMOVE_COLLECTION],
    startRemoveCollectionSaga
  );

const deleteCollectionRequestSub = () =>
  takeEvery(
    [CollectionsActionType.REMOVE_COLLECTION_REQUEST],
    removeCollectionSaga
  );

const saveCollectionSub = () =>
  takeLatest([CollectionsActionType.SAVE_COLLECTION], saveCollectionSaga);

const updateCollectionSub = () =>
  takeLatest(
    [CollectionsActionType.UPDATE_COLLECTION_REQUEST],
    updateCollectionSaga
  );

const saveListingToCollectionSub = () =>
  takeLatest(
    [CollectionsActionType.SAVE_LISTING_TO_COLLECTION],
    saveListingToCollectionSaga
  );

const shareCollectionSub = () =>
  takeEvery(
    [CollectionsActionType.SHARE_COLLECTION_REQUEST],
    shareCollectionSaga
  );

const unshareCollectionSub = () =>
  takeEvery(
    [CollectionsActionType.UNSHARE_COLLECTION_REQUEST],
    unshareCollectionSaga
  );

const removeFromDismissedSub = () =>
  takeEvery(
    [CollectionsActionType.REMOVE_FROM_DISMISSED_REQUEST],
    removeFromDismissedSaga
  );

const fetchCollectionsInvitationsSub = () =>
  takeEvery(
    [CollectionsActionType.FETCH_COLLECTIONS_INVITATIONS],
    fetchCollectionsInvitationsSaga
  );

const acceptCollectionInvitationSub = () =>
  takeEvery(
    [CollectionsActionType.ACCEPT_COLLECTION_INVITATION],
    acceptCollectionInvitationSaga
  );

const declineCollectionInvitationSub = () =>
  takeEvery(
    [CollectionsActionType.DECLINE_COLLECTION_INVITATION],
    declineCollectionInvitationSaga
  );

const sendCollectionInvitationReminderSub = () =>
  takeEvery(
    [CollectionsActionType.SEND_COLLECTION_INVITATION_REMINDER],
    sendCollectionInvitationReminderSaga
  );

const recommendListingsSub = () =>
  takeEvery([CollectionsActionType.RECOMMEND_LISTINGS], recommendListingsSaga);

export function* collectionsSagas() {
  yield all([
    fetchCollectionsSub(),
    deleteCollectionItemSub(),
    startDeleteCollectionSub(),
    deleteCollectionRequestSub(),
    saveCollectionSub(),
    saveListingToCollectionSub(),
    shareCollectionSub(),
    unshareCollectionSub(),
    updateCollectionSub(),
    joinCollectionSub(),
    declineCollectionSub(),
    removeFromDismissedSub(),
    fetchCollectionsInvitationsSub(),
    acceptCollectionInvitationSub(),
    declineCollectionInvitationSub(),
    sendCollectionInvitationReminderSub(),
    recommendListingsSub(),
  ]);
}
