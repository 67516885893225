import { FilterKey } from 'filters/filtersContants';
import { not } from 'shared/helpers/boolean';

export enum StaticTitle {
  ListingsPageWithFilterTitle = 'for Sale',
  ListingsLeasePageWithFilterTitle = 'for Lease',
  MarketOverviewTitle = 'Homes for Sale',
  SavedSearchesPageTitle = 'Saved Searches',
  CollectionsPageTitle = 'Collections',
  AccountSettingsPageTitle = 'Account Settings',
  AgentContactsPageTitle = 'My Contacts',
  BasicInfoPageTitle = 'Basic info',
  ViewedSearchesPageTitle = 'Viewed Searches',
  ViewedListingsPageTitle = 'Viewed Listings',
  ViewedPagesPageTitle = 'Viewed Pages',
  NotFoundPageTitle = 'Not Found',
  ForbiddenPageTitle = 'Forbidden',
  ErrorBoundaryPageTitle = 'Something Unexpected Happened',
  EmailSubscription = 'Unsubscribe from email notifications',
  EmailHistory = 'Email History',
  InquiryHistory = 'Inquiry History',
  NotificationInbox = 'Notification Inbox',
  EmailSettings = 'Email Settings',
  YourContact = 'Your Contact',
}

export enum StaticDescription {
  ListingsPageWithTagDescription = 'View recent home sales and upcoming open houses.',
  MarketOverviewDescription = 'Find open houses, view market trends and see recently sold homes',
  MarketOverviewSoldHomesDescription = 'View market trends, sales history, photos, and more',
  AgentsPageDescription = 'Our REALTORS average 10 years experience and sell 5x more homes, 21 days faster than average.',
  AgentBioPageDescription = 'REALTOR® - BIO, Reviews and Transaction History',
  Error404PageDescription = 'There are no results for this URL',
  MLSListingsUpdateTimeDescription = 'Our MLS listings are updated every 5 minutes.',
}

export enum StaticKeywords {
  ListingsPageWithTagKeywords = 'View photos, open house info, and property details fo',
}

export enum StaticRobots {
  NoindexNofollow = 'noindex, nofollow',
  NoindexFollow = 'noindex, follow',
}

export const CommunityFeaturesToUseInMeta = {
  [FilterKey.HomeownersAssoc]: FilterKey.HomeownersAssoc,
  [FilterKey.GatedCommunity]: FilterKey.GatedCommunity,
  [FilterKey.CommunityPool]: FilterKey.CommunityPool,
  [FilterKey.Playground]: FilterKey.Playground,
  [FilterKey.DogPark]: FilterKey.DogPark,
  [FilterKey.FitnessCenter]: FilterKey.FitnessCenter,
  [FilterKey.GolfCourse]: FilterKey.GolfCourse,
  [FilterKey.SportCourt]: FilterKey.SportCourt,
  [FilterKey.TennisCourts]: FilterKey.TennisCourts,
  [FilterKey.HorsesAllowed]: FilterKey.HorsesAllowed,
  [FilterKey.AirplaneFacilities]: FilterKey.AirplaneFacilities,
  [FilterKey.LakeAccess]: FilterKey.LakeAccess,
  [FilterKey.HikingTrails]: FilterKey.HikingTrails,
  [FilterKey.ClubHouse]: FilterKey.ClubHouse,
  [FilterKey.BoatDock]: FilterKey.BoatDock,
  [FilterKey.Park]: FilterKey.Park,
};

export const PropertyFeaturesToUseInMeta = {
  [FilterKey.SwimmingPool]: FilterKey.SwimmingPool,
  [FilterKey.FencedYard]: FilterKey.FencedYard,
  [FilterKey.BacksToGolfCourse]: FilterKey.BacksToGolfCourse,
  [FilterKey.BacksToGreenbelt]: FilterKey.BacksToGreenbelt,
  [FilterKey.PrivateDock]: FilterKey.PrivateDock,
  [FilterKey.TypeOfViews]: FilterKey.TypeOfViews,
  [FilterKey.WaterBodyName]: FilterKey.WaterBodyName,
};

export const PropertyTypesToUseInMeta = {
  [FilterKey.CommercialSale]: FilterKey.CommercialSale,
  [FilterKey.Condo]: FilterKey.Condo,
  [FilterKey.Farm]: FilterKey.Farm,
  [FilterKey.Lot]: FilterKey.Lot,
  [FilterKey.MultiFamily]: FilterKey.MultiFamily,
  [FilterKey.SingleFamilyResidence]: FilterKey.SingleFamilyResidence,
  [FilterKey.Townhouse]: FilterKey.Townhouse,
};

export const HomeFeaturesToUseInMeta = {
  [FilterKey.Workshop]: FilterKey.Workshop,
  [FilterKey.EnergyEfficient]: FilterKey.EnergyEfficient,
  [FilterKey.WineRoom]: FilterKey.WineRoom,
  [FilterKey.HistoricDesign]: FilterKey.HistoricDesign,
  [FilterKey.OfficeStudy]: FilterKey.OfficeStudy,
  [FilterKey.AccessibilityFeatures]: FilterKey.AccessibilityFeatures,
  [FilterKey.MediaRoom]: FilterKey.MediaRoom,
  [FilterKey.Fireplace]: FilterKey.Fireplace,
  [FilterKey.GameRoom]: FilterKey.GameRoom,
  [FilterKey.ExerciseRoom]: FilterKey.ExerciseRoom,
  [FilterKey.MasterOnMain]: FilterKey.MasterOnMain,
  [FilterKey.GuestQuarters]: FilterKey.GuestQuarters,
};

export const PrimaryFilterToUseInMeta = {
  [FilterKey.MaxPrice]: FilterKey.MaxPrice,
  [FilterKey.MinPrice]: FilterKey.MinPrice,
  [FilterKey.MaxBedrooms]: FilterKey.MaxBedrooms,
  [FilterKey.MinBedrooms]: FilterKey.MinBedrooms,
  [FilterKey.minBathrooms]: FilterKey.minBathrooms,
};

export const SchoolFilterToUseInMeta = {
  [FilterKey.elementarySchoolRating]: FilterKey.elementarySchoolRating,
  [FilterKey.middleSchoolRating]: FilterKey.middleSchoolRating,
  [FilterKey.highSchoolRating]: FilterKey.highSchoolRating,
};

export const YearBuiltFilterToUseInMeta = {
  [FilterKey.MinYearBuilt]: FilterKey.MinYearBuilt,
  [FilterKey.MaxYearBuilt]: FilterKey.MaxYearBuilt,
};

export const SizeFilterToUseInMeta = {
  [FilterKey.MinStories]: FilterKey.MinStories,
  [FilterKey.MaxStories]: FilterKey.MaxStories,
  [FilterKey.MinGarageSpaces]: FilterKey.MinGarageSpaces,
  [FilterKey.MaxGarageSpaces]: FilterKey.MaxGarageSpaces,
};

export const ShowOnlyFilterToUseInMeta = {
  [FilterKey.OurListingsOnly]: FilterKey.OurListingsOnly,
  [FilterKey.OpenHouseOnly]: FilterKey.OpenHouseOnly,
};

export const SingleFiltersToUseInMeta = {
  [FilterKey.MaxPrice]: FilterKey.MaxPrice,
  [FilterKey.MinPrice]: FilterKey.MinPrice,
  [FilterKey.minBathrooms]: FilterKey.minBathrooms,
  [FilterKey.SwimmingPool]: FilterKey.SwimmingPool,
  [FilterKey.FencedYard]: FilterKey.FencedYard,
  [FilterKey.BacksToGolfCourse]: FilterKey.BacksToGolfCourse,
  [FilterKey.BacksToGreenbelt]: FilterKey.BacksToGreenbelt,
  [FilterKey.PrivateDock]: FilterKey.PrivateDock,
  [FilterKey.TypeOfViews]: FilterKey.TypeOfViews,
  [FilterKey.MinYearBuilt]: FilterKey.MinYearBuilt,
  [FilterKey.OurListingsOnly]: FilterKey.OurListingsOnly,
  [FilterKey.MlsStatus]: FilterKey.MlsStatus,
  ...SchoolFilterToUseInMeta,
  ...HomeFeaturesToUseInMeta,
  ...CommunityFeaturesToUseInMeta,
};

export const FiltersToUseInMeta = {
  [FilterKey.MlsStatus]: FilterKey.MlsStatus,
  ...CommunityFeaturesToUseInMeta,
  ...PropertyFeaturesToUseInMeta,
  ...PropertyTypesToUseInMeta,
  ...HomeFeaturesToUseInMeta,
  ...PrimaryFilterToUseInMeta,
  ...SchoolFilterToUseInMeta,
  ...YearBuiltFilterToUseInMeta,
  ...SizeFilterToUseInMeta,
  ...ShowOnlyFilterToUseInMeta,
};

export const MarketOverviewFiltersMetaText = {
  [FiltersToUseInMeta.swimmingPool]: () => 'Homes for sale with Pool',
  [FiltersToUseInMeta.airplaneFacilities]: () => 'Airplane homes for sale',
  [FiltersToUseInMeta.communityPool]: () =>
    'Homes for Sale with Community Pool',
  [FiltersToUseInMeta.gatedCommunity]: () => 'Gated Community homes for Sale',
  [FiltersToUseInMeta.golfCourse]: () => 'Houses with Golf Course for Sale',
  [FiltersToUseInMeta.fencedYard]: () => 'Houses with fenced yard for Sale',
  [FiltersToUseInMeta.guestQuarters]: () =>
    'Homes with Guest Quarters for Sale',
  [FiltersToUseInMeta.farm]: () => 'Farm house, Ranch for Sale',
  [FiltersToUseInMeta.multiFamily]: () => 'Multifamily houses for Sale',
  [FiltersToUseInMeta.maxPrice]: (value: string) => {
    if (not(value)) {
      return 'Homes For Sale';
    }
    return `Homes for Sale under ${value}`;
  },
};
