import styled, { css } from 'styled-components/macro';

import { media } from 'styled-system/responsive';

export const EXCheckboxWrapper = styled.div`
  display: flex;
  position: relative;

  & > svg {
    margin-left: 8px;
    fill: ${({ theme }) => theme.warning};
  }
`;

export const WarningMessage = styled.div`
  width: 256px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  padding: 12px;
  line-height: 1.5;
  position: absolute;
  background-color: ${({ theme }) => theme.white};
  left: 24px;
  top: 28px;
  font-size: 14px;
  z-index: 1;

  &::after {
    position: absolute;
    content: ' ';
    top: -4px;
    left: 118px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid ${({ theme }) => theme.white};
  }

  ${media.sm(css`
    width: 212px;
    top: -72px;
    left: 0;

    &::after {
      top: unset;
      bottom: -4px;
      left: 97px;
      transform: rotate(180deg);
    }
  `)}
`;
