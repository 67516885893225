import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { RoutePath } from 'shared/constants/routesConstants';
import { showErrorToast } from 'shared/helpers/notifications';

export const ManageNetworkStatus = (): null => {
  const location = useLocation();

  useEffect(() => {
    const onLine = navigator.onLine;
    if (onLine || location.pathname === RoutePath.NOT_FOUND) {
      return;
    }

    showErrorToast(
      'You are not connected to the Internet. Please check your connection and refresh the page.'
    );
  }, [location.pathname]);

  return null;
};
