import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';

import { LOGO_ITEM_ID } from 'shared/components/Header/components/DesktopHeader/DesktopHeader';
import { BackToTopContainer } from 'shared/components/Modal/ModalStyled';
import ScrollToTopButton from 'shared/components/ScrollToTopButton/ScrollToTopButton';
import { ButtonsCode } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { showScrollToTopUnder } from 'shared/helpers/mainHelpers';
import { useIsMobile } from 'styled-system/responsive';

const BackToTop = () => {
  const isMobile = useIsMobile();
  const showUnder = showScrollToTopUnder();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = throttle(() => {
      if (window.pageYOffset >= showUnder) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, 300);

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const handleBackToTopClick = () => {
    const logoItem: HTMLElement = document.getElementById(LOGO_ITEM_ID);

    if (not(isMobile)) {
      logoItem.focus();
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === ButtonsCode.Enter) {
      handleBackToTopClick();
    }
  };

  return (
    <BackToTopContainer isMobile={isMobile} hasAnimation visible={isVisible}>
      <ScrollToTopButton
        isMobile={isMobile}
        onButtonClick={handleBackToTopClick}
        handleKeyDown={handleKeyDown}
      />
    </BackToTopContainer>
  );
};

export default BackToTop;
