import { createSelector } from 'reselect';

import { permissionsConfig } from 'permissions/permissionsConfig';
import { PermissionsState } from 'permissions/permissionsTypes';
import { RootState } from 'rootReducer';

const _getLoginStatus = ({ loginStatus }: PermissionsState) => loginStatus;
export const getLoginStatus = (state: RootState) =>
  state.permissions.loginStatus;

export const selectListingItemPermissions = createSelector(
  [_getLoginStatus],
  loginStatus => {
    const {
      lockImages,
      preventSaveToCollection,
      hideSaveToCollection,
      disableListingItem,
    } = permissionsConfig[loginStatus];

    return {
      lockImages,
      preventSaveToCollection,
      hideSaveToCollection,
      disableListingItem,
    };
  }
);

export const selectListingDetailPermissions = createSelector(
  [_getLoginStatus],
  loginStatus => {
    const {
      lockImages,
      preventSaveToCollection,
      hideHistoryPrice,
      disableListingItem,
      preventVirtualTour,
      preventDismissListing,
      preventFavoritePlaces,
      preventShareListing,
    } = permissionsConfig[loginStatus];

    return {
      lockImages,
      preventSaveToCollection,
      hideHistoryPrice,
      disableListingItem,
      preventVirtualTour,
      preventDismissListing,
      preventFavoritePlaces,
      preventShareListing,
    };
  }
);

export const selectSaveSearchPermission = createSelector(
  [_getLoginStatus],
  loginStatus => {
    const { preventSaveSearch } = permissionsConfig[loginStatus];

    return preventSaveSearch;
  }
);

export const selectFavoritePlacesPermission = createSelector(
  [_getLoginStatus],
  loginStatus => {
    const { preventFavoritePlaces } = permissionsConfig[loginStatus];

    return preventFavoritePlaces;
  }
);
