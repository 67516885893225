import { isEqual } from 'lodash';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { getAgentMode, getContactItem } from 'contacts/contactsSelectors';
import { getListingsUrl } from 'listingDetails/listingDetailsHelpers';
import { generateListingsQueryUrl } from 'listings/listingUrlParser';
import { RecentSearchesState, Search } from 'search/search-types';
import { SearchBarRecentSearchItem } from 'shared/components/SearchBar/components/SearchBarRecentSearches/SearchBarRecentSearchItem';
import SearchBarStyled, {
  OptionIconStyled,
  SubHeaderWrapper,
} from 'shared/components/SearchBar/SearchBarStyled';
import { isEmpty } from 'shared/helpers/boolean';
import { Status } from 'shared/helpers/redux';
import { useToggle } from 'shared/hooks/useToggle';
import { ReactComponent as Experience } from 'shared/icons/shared/experience.svg';
import { getProfileData } from 'shared/selectors/profileSelector';
import { getIsAgent, getIsLogged } from 'user/userSelectors';
import { UserRole } from 'user/userTypes';

export const SearchBarRecentSearchesOptionGroup = styled(
  SearchBarStyled.OptionGroup
)`
  border: none;
  padding-left: 0;
`;

const SearchBarRecentSearchWrapper = styled.div<{ divider?: boolean }>`
  border-bottom: ${({ divider, theme }) => {
    return divider ? `1px solid ${theme.colors.divider}` : 'none';
  }};
`;

interface Props {
  closeMenu: () => void;
  recentSearches: RecentSearchesState;
}

const useRecentSearches = (searches: Search[]): Search[] => {
  const isLogged = useSelector(getIsLogged);

  return useMemo(() => {
    if (isLogged) {
      return searches;
    }

    return searches.reduce((acc, item) => {
      const canonicalIdx = acc.findIndex(x => {
        const xParsed = queryString.parse(x.query);
        xParsed.page = undefined;
        const iParsed = queryString.parse(item.query);
        iParsed.page = undefined;
        const equalQuery = isEqual(xParsed, iParsed);
        const equalTags =
          x.search.tags.length === item.search.tags.length &&
          x.search.tags.every(
            (tag, idx) => tag.id === item.search.tags[idx].id
          );
        return equalQuery && equalTags;
      });
      if (canonicalIdx !== -1) {
        acc[canonicalIdx] = {
          ...acc[canonicalIdx],
          searchCount: acc[canonicalIdx].searchCount
            ? acc[canonicalIdx].searchCount + 1
            : 2,
        };
      } else {
        acc.push(item);
      }
      return acc;
    }, [] as Search[]);
  }, [searches, isLogged]);
};

export const SearchBarRecentSearches = (props: Props) => {
  const history = useHistory();

  const profile = useSelector(getProfileData);
  const contactItem = useSelector(getContactItem);
  const agentMode = useSelector(getAgentMode);
  const isAgent = useSelector(getIsAgent);

  const showAll = useToggle(false);

  const items = useRecentSearches(
    'items' in props.recentSearches ? props.recentSearches.items : []
  );

  if (props.recentSearches.status === Status.failed || isEmpty(items)) {
    return null;
  }

  const leaseAllowed =
    profile?.isLeasedSearchAllowed || profile?.role === UserRole.Agent;

  const handleClick = ({ query, search }: Search) => {
    const isLeased = queryString.parse(query).lease === 'true';

    const listingsQuery = generateListingsQueryUrl({
      filters: search.filters,
      tags: search.tags,
      agentMode: agentMode,
      isAgent,
    });

    const baseUrl = getListingsUrl({
      agentMode,
      contactId: contactItem?.username,
      fromLeased: isLeased,
    });

    history.push({
      pathname: `${baseUrl}${listingsQuery.path}`,
      search: listingsQuery.search || '',
    });

    props.closeMenu();
  };

  const visibleItems = showAll.value ? items : items.slice(0, 3);

  return (
    <SearchBarRecentSearchWrapper divider>
      <SubHeaderWrapper>
        <OptionIconStyled>
          <Experience />
        </OptionIconStyled>
        <SearchBarRecentSearchesOptionGroup>
          Recent Searches
        </SearchBarRecentSearchesOptionGroup>
      </SubHeaderWrapper>

      {visibleItems.map((search, index) => {
        return (
          <SearchBarRecentSearchItem
            key={index}
            onClick={handleClick}
            closeMenu={props.closeMenu}
            search={search}
            leaseAllowed={leaseAllowed}
          />
        );
      })}

      {items.length > 3 && (
        <SearchBarStyled.ShowMoreButton text onClick={showAll.toggle}>
          {showAll.value ? 'Show less...' : 'Show more...'}
        </SearchBarStyled.ShowMoreButton>
      )}
    </SearchBarRecentSearchWrapper>
  );
};
