import {
  OfficeMlsIds,
  OfficeMlsIdsToProductNameMapper,
} from 'shared/config/configConstants';
import { globalConfigs } from 'shared/config/index';

export const getIconDependsOnMlsOffice = (officeMlsId: string) => {
  // RA
  if (new RegExp(`^${OfficeMlsIds.RA}`).test(officeMlsId)) {
    return globalConfigs[OfficeMlsIdsToProductNameMapper[OfficeMlsIds.RA]]
      .logos;
  }

  // SA
  if (new RegExp(`^${OfficeMlsIds.SA}`).test(officeMlsId)) {
    return globalConfigs[OfficeMlsIdsToProductNameMapper[OfficeMlsIds.SA]]
      .logos;
  }
};
