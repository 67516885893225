import { createSelector } from 'reselect';

import { Contact } from 'contacts/contactsTypes';
import { RootState } from 'rootReducer';
import { SavedSearch } from 'saved-search/savedSearchTypes';
import { getProfileData } from 'shared/selectors/profileSelector';
import { UserProfile } from 'user/userTypes';

export const getContact = (state: RootState) => state.contacts.contact;
export const getContactItem = (state: RootState) =>
  state.contacts.contact.item || null;
export const getContacts = (state: RootState) => state.contacts.contacts;
const getCurrentSavedSearch = (state: RootState) => state.savedSearch.item;

export const getCurrentCollection = (state: RootState) =>
  state.collections.collection;

export const getAgentMode = createSelector([getContactItem], contactItem =>
  Boolean(contactItem)
);

export const getContactName = createSelector([getContactItem], contactItem =>
  contactItem
    ? `${contactItem.firstname || ''} ${contactItem.lastname || ''}`.trim()
    : ''
);

export const getContactUsername = createSelector(
  [getContactItem],
  contactItem => contactItem?.username
);

export const getContactsOwners = createSelector(
  [getContacts, getProfileData],
  (contacts, currentUser) =>
    contacts.map(contact => assignIfThisContactsAgent(contact, currentUser))
);

export const assignIfThisContactsAgent = (
  contact: Contact,
  currentUser: UserProfile | null
) => {
  return {
    ...contact,
    currentAgentOwnsThisContact: currentUser
      ? contact.agentId === currentUser.username
      : false,
  };
};

export const getIsViewOnlyMode = createSelector(
  [
    getAgentMode,
    getContactItem,
    getProfileData,
    getCurrentSavedSearch,
    getCurrentCollection,
  ],
  // eslint-disable-next-line max-params
  (agentMode, contactItem, profile, savedSearch, collection) => {
    if (agentMode) {
      const contactAgentId = contactItem ? contactItem.agentId : '';
      const username = profile ? profile.username : '';
      const savedSearchOwnerAgentId = (savedSearch as SavedSearch)?.user
        ?.agentId;
      const collectionOwnerAgentId =
        collection && 'user' in collection ? collection.user?.agentId : '';

      if (savedSearchOwnerAgentId) {
        return savedSearchOwnerAgentId !== username;
      }

      if (collectionOwnerAgentId) {
        return collectionOwnerAgentId !== username;
      }

      return contactAgentId !== username;
    }
    return false;
  }
);
