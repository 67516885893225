import { memo, useState } from 'react';

import { getAgentMode, getIsViewOnlyMode } from 'contacts/contactsSelectors';
import BathsFilter from 'filters/BathsFilter';
import BedsFilter from 'filters/BedsFilter';
import { MainFiltersName } from 'filters/filtersContants';
import {
  getBathroomsTitle,
  getBedroomsTitle,
  getMainFiltersAriaLabel,
  getPriceTitle,
} from 'filters/filtersHelpers';
import {
  $getAppliedMoreFiltersCount,
  $getBathroomFilters,
  $getBedroomFilters,
  $getPriceFilters,
  $getSecondaryFilters,
} from 'filters/filtersSelectors';
import MoreFiltersForm from 'filters/MoreFilters';
import PriceFilter from 'filters/PriceFilter';
import { IconPosition } from 'shared/components/Button/ButtonConstants';
import {
  BathsButton,
  BedsButton,
  MoreFiltersButton,
  PriceButton,
  TooltipLargeWrapper,
} from 'shared/components/Header/HeaderStyled';
import { APP_MODAL_CLASSNAME } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';
import { ReactComponent as IconBath } from 'shared/icons/listings/bath.svg';
import { ReactComponent as IconBed } from 'shared/icons/listings/bed.svg';
import { ReactComponent as IconPrice } from 'shared/icons/listings/price.svg';
import { ReactComponent as IconPlus } from 'shared/icons/shared/24x24/plus.svg';

const Filters = memo(function Filters() {
  return (
    <>
      <Price />
      <Bedroom />
      <Bathroom />
      <MoreFilters />
    </>
  );
});

export default Filters;

function Price() {
  const isLeasePage = useIsLeasePage();

  const price = useSelector($getPriceFilters);
  const priceButtonTitle = getPriceTitle(price);

  const viewOnly = useSelector(getIsViewOnlyMode);

  return (
    <TooltipLargeWrapper
      align={{ offset: [0, 12] }}
      destroyTooltipOnHide
      isBlock
      overlay={({ closeTooltip }) => (
        <PriceFilter
          disableFilter={viewOnly}
          initialValues={price}
          onSubmit={closeTooltip}
        />
      )}
      placement="bottom"
      shouldCloseOnEscape
      shouldCloseOnScroll={false}
    >
      <PriceButton
        aria-label={getMainFiltersAriaLabel(
          MainFiltersName.Price,
          priceButtonTitle
        )}
        hasIconActive={priceButtonTitle !== 'Any'}
        icon={<IconPrice />}
        iconPosition={IconPosition.LEFT}
        isLease={isLeasePage}
        small
      >
        {priceButtonTitle === 'Any' ? 'Any Price' : priceButtonTitle}
      </PriceButton>
    </TooltipLargeWrapper>
  );
}

function Bedroom() {
  const bedroom = useSelector($getBedroomFilters);
  const bedsButtonTitle = getBedroomsTitle(bedroom);

  const viewOnly = useSelector(getIsViewOnlyMode);

  return (
    <TooltipLargeWrapper
      align={{ offset: [0, 12] }}
      destroyTooltipOnHide
      isBlock
      overlay={({ closeTooltip }) => (
        <BedsFilter
          disableFilter={viewOnly}
          initialValues={bedroom}
          onSubmit={closeTooltip}
        />
      )}
      placement="bottom"
      shouldCloseOnEscape
      shouldCloseOnScroll={false}
    >
      <BedsButton
        aria-label={getMainFiltersAriaLabel(
          MainFiltersName.Bedrooms,
          bedsButtonTitle
        )}
        hasIconActive={bedsButtonTitle !== 'Any'}
        icon={<IconBed />}
        iconPosition={IconPosition.LEFT}
        small
      >
        {bedsButtonTitle}
      </BedsButton>
    </TooltipLargeWrapper>
  );
}

function Bathroom() {
  const bathroom = useSelector($getBathroomFilters);
  const bathsButtonTitle = getBathroomsTitle(bathroom);

  const viewOnly = useSelector(getIsViewOnlyMode);

  return (
    <TooltipLargeWrapper
      align={{ offset: [0, 12] }}
      destroyTooltipOnHide
      isBlock
      overlay={({ closeTooltip }) => (
        <BathsFilter
          disableFilter={viewOnly}
          initialValues={bathroom}
          onSubmit={closeTooltip}
        />
      )}
      placement="bottom"
      shouldCloseOnEscape
      shouldCloseOnScroll={false}
    >
      <BathsButton
        aria-label={getMainFiltersAriaLabel(
          MainFiltersName.Bathrooms,
          bathsButtonTitle
        )}
        hasIconActive={bathsButtonTitle !== 'Any'}
        icon={<IconBath />}
        iconPosition={IconPosition.LEFT}
        small
      >
        {bathsButtonTitle}
      </BathsButton>
    </TooltipLargeWrapper>
  );
}

function MoreFilters() {
  const [isOpenMoreFiltersMenu, setOpenMoreFiltersMenu] = useState(false);

  const filters = useSelector($getSecondaryFilters);
  const appliedFiltersCount = useSelector($getAppliedMoreFiltersCount);

  const isAgentMode = useSelector(getAgentMode);
  const viewOnly = useSelector(getIsViewOnlyMode);

  return (
    <TooltipLargeWrapper
      align={{ offset: [0, 12] }}
      destroyTooltipOnHide
      handleShowOverlay={setOpenMoreFiltersMenu}
      isBlock
      overlay={({ closeTooltip }) => (
        <MoreFiltersForm
          disabled={viewOnly}
          initialValues={filters}
          isAgentMode={isAgentMode}
          onSubmit={closeTooltip}
        />
      )}
      placement="bottom"
      preventCloseSelector={`.${APP_MODAL_CLASSNAME}`}
      shouldCloseOnEscape
      shouldCloseOnScroll={false}
    >
      <MoreFiltersButton
        aria-label={
          isOpenMoreFiltersMenu ? 'Close more filters' : 'More filters'
        }
        badge={appliedFiltersCount || undefined}
        icon={not(appliedFiltersCount) && <IconPlus />}
        iconPosition={IconPosition.RIGHT}
        role="button"
        small
      >
        More filters
      </MoreFiltersButton>
    </TooltipLargeWrapper>
  );
}
