import React from 'react';
import styled from 'styled-components/macro';

import CounterLabel from 'shared/components/CounterLabel';
import ProfileImage from 'shared/components/ProfileImage';
import { ReactComponent as IconDown } from 'shared/icons/shared/arrows/small-arrow-down.svg';
import { UserProfile } from 'user/userTypes';

const RealButton = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImageContainer = styled.div`
  margin-right: 8px;
  position: relative;
`;

const NotificationsCount = styled(CounterLabel)`
  position: absolute;
  top: 0;
  right: -8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid ${({ theme }) => theme.white};
`;

export const ProfileName = styled.span`
  font-size: 12px;
  margin-right: 5px;
  fill: ${({ theme }) => theme.text2};
`;

export const Down = styled(IconDown)`
  width: 16px;
  height: 16px;
`;

export interface ProfileButtonProps {
  profile: UserProfile;
  SocialPhotoURL?: string;
  showNotificationsCount?: boolean;
  notificationsCount?: string;
}

function ProfileButton(props: ProfileButtonProps) {
  const { profile, notificationsCount, showNotificationsCount } = props;

  return (
    <RealButton>
      <ProfileImageContainer>
        <ProfileImage profile={profile} small />
        {notificationsCount && showNotificationsCount && (
          <NotificationsCount label={notificationsCount} />
        )}
      </ProfileImageContainer>
      <ProfileName>{profile.firstname}</ProfileName>
      <Down />
    </RealButton>
  );
}

export default ProfileButton;
