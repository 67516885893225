import {
  InquiryHistoryItem,
  InquiryHistoryQuery,
} from 'history/inquiryHistory/inquiryHistoryTypes';
import { ApiService } from 'shared/services/ApiService';

const SINGLE_BASE_URL = '/notification/email-history';

export class InquiryHistoryService {
  static async getInquiryHistory(query: InquiryHistoryQuery) {
    return ApiService.request<{ hits: InquiryHistoryItem[]; total: number }>({
      method: 'GET',
      path: `/user/inquiry`,
      query: query as Record<string, any>,
    });
  }

  static async getInquiryHistoryItem(
    query: {
      username: string;
    },
    emailID: string
  ) {
    return ApiService.request<string>({
      method: 'GET',
      path: `${SINGLE_BASE_URL}/${emailID}`,
      query,
      resolve: res => res.text(),
    });
  }
}
