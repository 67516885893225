import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import { getAgentMode } from 'contacts/contactsSelectors';
import { useIsMobile } from 'styled-system/responsive';

export let useAgentModeStyles: () => ReactModal.Styles;
// temporary workaround for test
if (process.env.NODE_ENV === 'test') {
  useAgentModeStyles = () => ({
    content: {},
    overlay: {},
  });
} else {
  useAgentModeStyles = (): ReactModal.Styles => {
    const agentMode = useSelector(getAgentMode);
    const isMobile = useIsMobile();

    if (agentMode && isMobile) {
      return {
        content: {
          paddingTop: '24px',
        },
        overlay: {},
      };
    }

    return {
      content: {},
      overlay: {},
    };
  };
}
