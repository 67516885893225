import queryString from 'query-string';

import { RequestHomePriceEstimateBody } from 'butter-cms/pages/sell/sellTypes';
import {
  OnSelectParams,
  OnSelectParamsWithOutLatAndLng,
  PlacesSelectAddressComponent,
} from 'shared/components/Select/PlacesSelect';
import { not } from 'shared/helpers/boolean';
import {
  replaceDashesToSpaces,
  replaceSpacesToDashes,
} from 'shared/helpers/formatters';
import { SellQueryParams, SellQueryParamsWithOutLatAndLng } from 'shared/types';

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer).slice();

  bytes.forEach(byte => (binary += String.fromCharCode(byte)));

  return window.btoa(binary);
}

export function getPartsFromAddressComponents(
  addressComponents: PlacesSelectAddressComponent[]
) {
  let zipCode = '';
  let streetNumber = '';
  let route = '';
  let state = '';
  let city = '';

  for (const component of addressComponents) {
    if (component.types.includes('street_number')) {
      streetNumber = component.long_name;
    }
    if (component.types.includes('route')) {
      route = component.long_name;
    }
    if (component.types.includes('postal_code')) {
      zipCode = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      state = component.short_name;
    }
    if (component.types.includes('locality')) {
      city = component.long_name;
    }
  }

  const streetAddress = `${streetNumber}${streetNumber ? ' ' : ''}${route}`;

  return {
    streetAddress,
    zipCode,
    state,
    city,
    streetNumber,
    route,
  };
}

export const composeSellQuery = (address?: OnSelectParams): string => {
  if (not(address)) {
    return '';
  }

  const { streetAddress, zipCode, state, city } = getPartsFromAddressComponents(
    address.addressComponents
  );

  return constructSellQuery({
    formattedAddress: address.formattedAddress,
    streetAddress,
    zipCode,
    state,
    city,
    latitude: address.latitude,
    longitude: address.longitude,
  });
};
export const composeSellQueryWithOutLatAndLng = (
  address?: OnSelectParamsWithOutLatAndLng
): string => {
  if (not(address)) {
    return '';
  }

  const { streetAddress, zipCode, state, city } = getPartsFromAddressComponents(
    address.addressComponents
  );

  return constructSellQueryWithOutLatAndLng({
    formattedAddress: address.formattedAddress,
    streetAddress,
    zipCode,
    state,
    city,
  });
};

export const constructSellQuery = (
  params: Omit<SellQueryParams, 'shortAddress'> & { streetAddress: string }
) => {
  return replaceSpacesToDashes(
    queryString.stringify(
      {
        formatted: params.formattedAddress,
        address: params.streetAddress,
        zip: params.zipCode,
        state: params.state,
        city: params.city,
        lat: params.latitude,
        lng: params.longitude,
      },
      {
        sort: false,
        encode: false,
      }
    )
  );
};
export const constructSellQueryWithOutLatAndLng = (
  params: Omit<SellQueryParamsWithOutLatAndLng, 'shortAddress'> & {
    streetAddress: string;
  }
) => {
  return replaceSpacesToDashes(
    queryString.stringify(
      {
        formatted: params.formattedAddress,
        address: params.streetAddress,
        zip: params.zipCode,
        state: params.state,
        city: params.city,
      },
      {
        sort: false,
        encode: false,
      }
    )
  );
};

export const getRequestHomePriceBody = (
  address?: OnSelectParams
): RequestHomePriceEstimateBody | null => {
  if (!address) {
    return null;
  }
  const { streetAddress, zipCode, state, city } = getPartsFromAddressComponents(
    address.addressComponents
  );

  return {
    address: address.formattedAddress,
    streetAddress,
    zipCode,
    state,
    city,
    latitude: String(address.latitude),
    longitude: String(address.longitude),
  };
};

export const parseSellQuery = (search: string): SellQueryParams => {
  const {
    formatted = '',
    zip: zipCode = '',
    address = '',
    city = '',
    state = '',
    lat,
    lng,
  } = queryString.parse(search) as { [key: string]: string };

  return {
    formattedAddress: replaceDashesToSpaces(formatted),
    shortAddress: replaceDashesToSpaces(address),
    city: replaceDashesToSpaces(city),
    state,
    zipCode,
    latitude: parseFloat(lat) || 0,
    longitude: parseFloat(lng) || 0,
  };
};
