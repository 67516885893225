import {
  PropertyFilterSubType,
  PropertySubType,
  PropertyType,
} from 'listings/listingsTypes';

export const myListingsButtonText = 'Create New Listing';

enum myListingsSortOptionsLabel {
  publishedDate = 'Published Date',
  updatedDate = 'Updated Date',
  publicDate = 'Public Date',
  mlsDate = 'MLS Date',
  expirationDate = 'Expiration Date',
  createdDate = 'Created Date',
  price = 'Price',
  address = 'Address',
  beds = 'Beds',
}

enum myListingsSortOptionsValue {
  publishedDate = 'publishedDate',
  updatedDate = 'updatedDate',
  publicDate = 'publicDate',
  mlsDate = 'mlsTargetDate',
  expirationDate = 'expirationDate',
  createdDate = 'createdDate',
  price = 'price',
  address = 'address',
  beds = 'beds',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
  listPrice = 'listPrice',
  street = 'streetName',
  bedrooms = 'bedroomsTotal',
}

export enum MyListingLayouts {
  onMarket = 'onMarket',
  offMarket = 'offMarket',
  draft = 'draft',
}

export const myListingTabs = [
  { name: 'On Market', layout: MyListingLayouts.onMarket },
  { name: 'Off Market', layout: MyListingLayouts.offMarket },
  { name: 'Draft', layout: MyListingLayouts.draft },
];

export const BASE_URL = 'admin-adapter';

export const MIN_IMAGE_SIZE = 50000;
export const MAX_IMAGE_SIZE = 5000000;

export enum imageStatuses {
  Done = 'done',
  RejectedFileType = 'rejected_file_type',
  SizeError = 'error_file_size',
}

export const DISABLED_DRAGGING_CLASSNAME = 'disabledDragging';

export const IMAGE_TYPE_ERROR =
  'Inappropriate photo format.\nUse a .jpg, .jpeg.';
export const IMAGE_MIN_SIZE_ERROR =
  'Upload failed. The file is too small.\nPlease upload a photo that exceeds 50KB.';
export const IMAGE_MAX_SIZE_ERROR =
  'Check the size of the photo.\nWe recommend uploading photos under 5MB';
export const ADDRESS_DUPLICATE_ERROR =
  'The listing with this address and unit number already exists.';
export const DATE_TEMPLATE = 'DD MMM YYYY';

export enum MyListingsActions {
  View_Listing_Details = 'View Listing Details',
  Preview = 'Preview',
  Edit = 'Edit',
  Publish = 'Publish',
  Delete = 'Delete',
}

export const MARKET_LISTINGS_URL = '/exclusive-listing';
export const DRAFT_LISTINGS_URL = `/v2${MARKET_LISTINGS_URL}/list`;

export const defaultSortOptions = (myListingsType: MyListingLayouts) => ({
  value:
    myListingsType === MyListingLayouts.draft
      ? myListingsSortOptionsValue.updatedAt
      : myListingsSortOptionsValue.updatedDate,
  label: myListingsSortOptionsLabel.updatedDate,
  sortType: 'DESC',
});

export const sortOptions = {
  [MyListingLayouts.onMarket]: [
    {
      value: myListingsSortOptionsValue.updatedDate,
      label: myListingsSortOptionsLabel.updatedDate,
    },
    {
      value: myListingsSortOptionsValue.publishedDate,
      label: myListingsSortOptionsLabel.publishedDate,
    },
    {
      value: myListingsSortOptionsValue.publicDate,
      label: myListingsSortOptionsLabel.publicDate,
    },
    {
      value: myListingsSortOptionsValue.mlsDate,
      label: myListingsSortOptionsLabel.mlsDate,
    },
    {
      value: myListingsSortOptionsValue.expirationDate,
      label: myListingsSortOptionsLabel.expirationDate,
    },
    {
      value: myListingsSortOptionsValue.price,
      label: myListingsSortOptionsLabel.price,
    },
    {
      value: myListingsSortOptionsValue.address,
      label: myListingsSortOptionsLabel.address,
    },
    {
      value: myListingsSortOptionsValue.beds,
      label: myListingsSortOptionsLabel.beds,
    },
  ],
  [MyListingLayouts.offMarket]: [
    {
      value: myListingsSortOptionsValue.updatedDate,
      label: myListingsSortOptionsLabel.updatedDate,
    },
    {
      value: myListingsSortOptionsValue.publicDate,
      label: myListingsSortOptionsLabel.publicDate,
    },
    {
      value: myListingsSortOptionsValue.mlsDate,
      label: myListingsSortOptionsLabel.mlsDate,
    },
    {
      value: myListingsSortOptionsValue.expirationDate,
      label: myListingsSortOptionsLabel.expirationDate,
    },
    {
      value: myListingsSortOptionsValue.createdDate,
      label: myListingsSortOptionsLabel.createdDate,
    },
    {
      value: myListingsSortOptionsValue.price,
      label: myListingsSortOptionsLabel.price,
    },
    {
      value: myListingsSortOptionsValue.address,
      label: myListingsSortOptionsLabel.address,
    },
    {
      value: myListingsSortOptionsValue.beds,
      label: myListingsSortOptionsLabel.beds,
    },
  ],
  [MyListingLayouts.draft]: [
    {
      value: myListingsSortOptionsValue.updatedAt,
      label: myListingsSortOptionsLabel.updatedDate,
    },
    {
      value: myListingsSortOptionsValue.publicDate,
      label: myListingsSortOptionsLabel.publicDate,
    },
    {
      value: myListingsSortOptionsValue.mlsDate,
      label: myListingsSortOptionsLabel.mlsDate,
    },
    {
      value: myListingsSortOptionsValue.expirationDate,
      label: myListingsSortOptionsLabel.expirationDate,
    },
    {
      value: myListingsSortOptionsValue.createdAt,
      label: myListingsSortOptionsLabel.createdDate,
    },
    {
      value: myListingsSortOptionsValue.listPrice,
      label: myListingsSortOptionsLabel.price,
    },
    {
      value: myListingsSortOptionsValue.street,
      label: myListingsSortOptionsLabel.address,
    },
    {
      value: myListingsSortOptionsValue.bedrooms,
      label: myListingsSortOptionsLabel.beds,
    },
  ],
};

export enum MlsSource {
  Abor = 'ra',
  Sabor = 'sa',
}

export const CREATE_NEW_LISTING_TITLE = 'Create New Listing';

export const PropertyTypeAndSubtype: {
  [key in PropertyFilterSubType]?: {
    propertyType: PropertyType;
    propertySubtype: PropertySubType;
  };
} = {
  [PropertyFilterSubType.SingleFamilyResidence]: {
    propertyType: PropertyType.Residential,
    propertySubtype: PropertySubType.SingleFamilyResidence,
  },
  [PropertyFilterSubType.Condominium]: {
    propertyType: PropertyType.Residential,
    propertySubtype: PropertySubType.Condominium,
  },
  [PropertyFilterSubType.Lot]: {
    propertyType: PropertyType.Land,
    propertySubtype: PropertySubType.UnimprovedLand,
  },
  [PropertyFilterSubType.Townhouse]: {
    propertyType: PropertyType.Residential,
    propertySubtype: PropertySubType.Townhouse,
  },
  [PropertyFilterSubType.Farm]: {
    propertyType: PropertyType.Farm,
    propertySubtype: PropertySubType.Ranch,
  },
  [PropertyFilterSubType.MultiFamily]: {
    propertyType: PropertyType.ResidentialIncome,
    propertySubtype: PropertySubType.Other,
  },
  [PropertyFilterSubType.CommercialSale]: {
    propertyType: PropertyType.CommercialSale,
    propertySubtype: PropertySubType.Other,
  },
};

export const directionLabels = ['Ascending', 'Descending'];
