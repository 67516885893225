import styled, { css } from 'styled-components/macro';

export const ShowMoreButton = styled.div<{
  sameline?: boolean;
  center?: boolean;
}>`
  width: 100%;
  color: ${({ theme }) => theme.link};
  ${props =>
    props.center &&
    css`
      display: flex;
      justify-content: center;
    `}
  span {
    padding: 0;
    font-size: 14px;
  }
  ${props =>
    props.sameline &&
    css`
      width: auto;
      display: inline-block;
    `};
`;

export const TruncatedWrapper = styled.div`
  & > *:last-child {
    margin-bottom: 0;
  }
  & *[style] {
    font-family: Lato, -apple-system, 'Segoe UI', Roboto, Ubuntu, 'Open Sans',
      'Helvetica Neue', sans-serif !important;
    text-align: initial !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: normal !important;
    color: ${({ theme }) => theme.text2} !important;
  }
`;

export const TruncateTextWrapper = styled.span<{
  isMoreButtonVisible?: boolean;
}>`
  /* will be applied only if show less */
  ${({ isMoreButtonVisible }) =>
    isMoreButtonVisible &&
    css`
      padding-right: 8px;
    `}
`;

export const TruncateLines = styled.div<{
  height: string;
}>`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: ${({ height }) => height};
`;
