import { FromLinkStatus } from 'permissions/permissionsConstants';
import {
  browserStorageItem,
  LANDING_PAGE,
} from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { sessionStorage } from 'shared/services/sessionStorage';
import { P_T_N_LINK } from 'user/userConstants';

export const getInquiryTrackingInfo = (): {
  landingPage: string | null;
  originalSource: string | null;
  submittedPage: string;
} => {
  const { ptLink } = ptnLinkStorage.deserialize(
    localStorage.getItem(P_T_N_LINK)
  );

  return {
    landingPage: ptLink || sessionStorage.getItem(LANDING_PAGE),
    originalSource: sessionStorage.getItem(browserStorageItem.originalSource),
    submittedPage: window.location.href,
  };
};

export const getNotificationCountLabel = (
  notificationCount: number
): string => {
  if (not(notificationCount)) {
    return '';
  }

  return notificationCount > 99 ? '99+' : String(notificationCount);
};

interface PTLinkData {
  ptLink?: string;
  ptLinkType?: FromLinkStatus;
  recommendedAgent?: string;
  ptLinkExpirationDate?: string;
}

export const ptnLinkStorage = {
  deserialize: (json: string | undefined | null): PTLinkData => {
    try {
      if (json) {
        const parsed = JSON.parse(json);
        if (typeof parsed === 'object') {
          return parsed;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return {};
  },

  serialize: (ptLinkData: PTLinkData): string => {
    return JSON.stringify(ptLinkData);
  },
};
