import React from 'react';
import { css } from 'styled-components/macro';

import { extractLeaseFromSearchQuery } from 'saved-search/savedSearchHelpers';
import {
  countCustomLocation,
  SearchDescriptionBuilder,
} from 'saved-search/services/SearchDescriptionBuilder';
import { Search } from 'search/search-types';
import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import {
  SearchBarItemContainer,
  SearchBarItemSecondaryLabel,
} from 'shared/components/SearchBar/SearchBarItemStyled';
import { ButtonsCode } from 'shared/constants/appConstants';
import { Typography } from 'styled-system/components';
import { withSx } from 'styled-system/with-sx';

const Label = withSx(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const getFiltersPrefix = (query: string, filtersTitle: string): string => {
  const lease = extractLeaseFromSearchQuery(query);
  const sufix = filtersTitle ? ': ' : '';
  return `${lease ? 'For Lease' : 'For Sale'}${sufix}`;
};

export interface Props {
  onClick: (search: Search) => void;
  closeMenu: () => void;
  search: Search;
  leaseAllowed: boolean;
}

export const SearchBarRecentSearchItem = (props: Props) => {
  const isLeaseSearch = extractLeaseFromSearchQuery(props.search.query);
  const filtersTitle = SearchDescriptionBuilder.getFiltersDescription(
    props.search.search.filters,
    isLeaseSearch
  );

  const label = props.search.search.tags.map(tag => tag.label).join(', ');

  const showTimes = props.search.searchCount && props.search.searchCount > 1;

  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ButtonsCode.Enter) {
      props.onClick(props.search);
      ariaLiveAnnouncerCtx.announce(`${label} selected`);
    }
    if (event.key === ButtonsCode.Esc) {
      props.closeMenu();
    }
  };

  return (
    <SearchBarItemContainer
      aria-label={label}
      css={css`
        padding-right: ${p => p.theme.space[2]}px;
      `}
      onMouseDown={() => props.onClick(props.search)}
      onKeyDown={handleKeyDown}
      role="option"
      tabIndex={0}
    >
      <Label sx={{ flexShrink: '1', lineHeight: 'normal', mr: '1' }}>
        {countCustomLocation(label)}
        {(Boolean(filtersTitle) || props.leaseAllowed) && (
          <SearchBarItemSecondaryLabel
            css={css`
              color: ${p => p.theme.colors.text3};
            `}
          >
            {props.leaseAllowed &&
              getFiltersPrefix(props.search.query, filtersTitle)}
            {Boolean(filtersTitle) && filtersTitle}
          </SearchBarItemSecondaryLabel>
        )}
      </Label>
      {showTimes && (
        <Typography sx={{ fontSize: '14px', ml: 'auto' }}>
          ({props.search.searchCount})
        </Typography>
      )}
    </SearchBarItemContainer>
  );
};
