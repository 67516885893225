import { RawCollection } from 'collections/collectionsTypes';
import config from 'config';
import {
  ListingDismiss,
  ListingNearby,
  ListingRetain,
  ListingShare,
  ListingWithDetails,
  ShareBody,
} from 'listingDetails/listingDetailsTypes';
import { browserStorageItem } from 'shared/constants/appConstants';
import { ApiService } from 'shared/services/ApiService';
import { BaseResponse } from 'shared/types';

export const LISTING_DETAILS_BASE_URL = '/listing';

export class ListingDetailsService {
  static async fetchListing(
    id: string,
    query: {
      address?: string;
      search?: string;
      source?: typeof config.productSource;
      isExternalLink?: string | undefined;
      username?: string;
    }
  ) {
    try {
      const previousLink =
        localStorage.getItem(browserStorageItem.originUrl) || document.referrer;

      if (Boolean(document.referrer) && query?.address) {
        if (window.location.origin !== previousLink) {
          localStorage.setItem(
            browserStorageItem.originUrl,
            window.location.origin
          );
          query.isExternalLink = 'true';
        } else {
          const { isExternalLink, ...newQuery } = query;
          query = newQuery;
        }
      }

      return ApiService.request<ListingWithDetails>({
        method: 'GET',
        path: `${LISTING_DETAILS_BASE_URL}/${id}`,
        query: query,
        prefix: 'v2',
      });
    } catch (error) {
      throw new Error('[isExternalLink]: Error creating localStorage Item');
    }
  }

  static async fetchIsListingDismissed(id: string) {
    return ApiService.request<{ data: { isListingDismissed: boolean } }>({
      method: 'get',
      path: `${LISTING_DETAILS_BASE_URL}/dismissed/${id}`,
      prefix: 'v2',
    });
  }

  static async fetchAllCollections(
    listingId: string,
    query: {
      user?: string;
      sortBy?: string;
      sortType?: string;
    } = {}
  ) {
    return ApiService.request<RawCollection[]>({
      method: 'GET',
      path: `${LISTING_DETAILS_BASE_URL}/${listingId}/collections`,
      query,
    });
  }

  static async fetchListingNearby(
    listingId: string,
    query: { username: string }
  ) {
    return ApiService.request<ListingNearby>({
      method: 'GET',
      path: `${LISTING_DETAILS_BASE_URL}/${listingId}/nearby`,
      query,
      prefix: 'v2',
    });
  }

  static async toggleDismissListing(id: string, dismissed: boolean) {
    // TODO: consider to split to two API
    const url = dismissed
      ? `${LISTING_DETAILS_BASE_URL}/${id}/retain`
      : `${LISTING_DETAILS_BASE_URL}/${id}/dismiss`;

    return ApiService.request<ListingDismiss | ListingRetain>({
      method: 'POST',
      path: url,
    });
  }

  // TODO: refactor use data: { receiverEmail, message }
  static async shareListing(
    listingId: string,
    receiverEmail: string,
    message: string
  ) {
    return ApiService.request<ListingShare, ShareBody>({
      method: 'POST',
      path: `${LISTING_DETAILS_BASE_URL}/${listingId}/share`,
      data: {
        receiverEmail,
        message,
      },
    });
  }

  static async addOpenhouse(listingId: string, openhousekey: string) {
    return ApiService.request({
      method: 'POST',
      path: `${LISTING_DETAILS_BASE_URL}/${listingId}/add-open-house-to-calendar`,
      query: { openhousekey },
    });
  }

  static async resyncListing(listingId: string) {
    return ApiService.request<BaseResponse<{ syncListing: boolean }>>({
      method: 'POST',
      path: `${LISTING_DETAILS_BASE_URL}/${listingId}/sync`,
      prefix: 'v2',
    });
  }

  static async getResyncStatus(listingId: string) {
    return ApiService.request<BaseResponse<{ syncStatusListing: boolean }>>({
      method: 'GET',
      path: `${LISTING_DETAILS_BASE_URL}/${listingId}/sync`,
      prefix: 'v2',
    });
  }
}
