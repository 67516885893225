import { formatTimeWithTimeZone } from 'shared/helpers/formatters';

const DEFAULT_END_TIME_FORMAT = 'h:mm A';
const DEFAULT_START_TIME_FORMAT = 'MMM DD, h:mm A';

export interface TimeSlotOptions {
  latitude: string;
  longitude: string;
  startTime?: string;
  endTime?: string;
}

export const getTimeSlotLabel = (props: TimeSlotOptions): string => {
  const { latitude, longitude, startTime, endTime } = props;

  const formattedStartTime = formatTimeWithTimeZone({
    time: startTime,
    format: DEFAULT_START_TIME_FORMAT,
    latitude,
    longitude,
  });

  if (startTime && endTime) {
    const formattedEndTime = formatTimeWithTimeZone({
      time: endTime,
      format: DEFAULT_END_TIME_FORMAT,
      latitude,
      longitude,
    });

    return `${formattedStartTime} - ${formattedEndTime}`;
  }

  return formattedStartTime;
};
