import dayjs from 'dayjs';
import { chain } from 'lodash';

import { ListingsSource, MlsStatus } from 'filters/filtersContants';
import { OpenHouse } from 'listingDetails/listingDetailsTypes';
import { Listing } from 'listings/listingsTypes';
import { MapItemTypes } from 'map/mapConstants';
import { Cluster, ClusterData } from 'map/mapTypes';
import { isExclusive } from 'shared/constants/appConstants';
import { isEmpty, not, notEmpty } from 'shared/helpers/boolean';
import { formatByUTC, formatDate } from 'shared/helpers/formatDate';
import { getTimeSlotLabel } from 'shared/helpers/getTimeSlotLabel';
import globalTheme from 'shared/styles/globalTheme';
import { PrimaryLabel, SecondaryLabel } from 'shared/types';

export const DEFAULT_SECONDARY_COLOR = globalTheme.black;
export const DEFAULT_SECONDARY_BG = globalTheme.white;

export const blackLabelColor = globalTheme.text5;
export const blueLabelColor = globalTheme.secondary;
export const greenLabelColor = globalTheme.label;
export const orangeLabelColor = globalTheme.primary;
export const whiteLabelColor = globalTheme.white;

enum PrimaryLabels {
  Exclusive = 'Exclusive',
  OpenHouse = 'Open House',
  JustListedNew = 'Just Listed',
  BackOnMarket = 'Back On Market',
  PriceReduced = 'Price Reduced',
  PriceIncrease = 'Price Increase',
}

export const labelColors = new Map();
export const labelBackgrounds = new Map();

// Primary labels
labelBackgrounds.set(PrimaryLabels.Exclusive, blackLabelColor);
labelBackgrounds.set(PrimaryLabels.OpenHouse, blueLabelColor);
labelBackgrounds.set(PrimaryLabels.JustListedNew, blueLabelColor);
labelBackgrounds.set(PrimaryLabels.BackOnMarket, blueLabelColor);
labelBackgrounds.set(PrimaryLabels.PriceReduced, blueLabelColor);
labelBackgrounds.set(PrimaryLabels.PriceIncrease, blueLabelColor);

// Secondary labels
labelBackgrounds.set(MlsStatus.Private, whiteLabelColor);
labelBackgrounds.set(MlsStatus.Active, whiteLabelColor);
labelBackgrounds.set(MlsStatus.PendingTakingBackups, whiteLabelColor);
labelBackgrounds.set(MlsStatus.Pending, whiteLabelColor);
labelBackgrounds.set(MlsStatus.Sold, whiteLabelColor);
labelBackgrounds.set(MlsStatus.Leased, whiteLabelColor);
labelBackgrounds.set(MlsStatus.Withdrawn, whiteLabelColor);
labelBackgrounds.set(MlsStatus.Expired, whiteLabelColor);
labelBackgrounds.set(MlsStatus.ListedOnMls, whiteLabelColor);

// Primary labels
labelColors.set(PrimaryLabels.Exclusive, whiteLabelColor);
labelColors.set(PrimaryLabels.OpenHouse, whiteLabelColor);
labelColors.set(PrimaryLabels.JustListedNew, whiteLabelColor);
labelColors.set(PrimaryLabels.BackOnMarket, whiteLabelColor);
labelColors.set(PrimaryLabels.PriceReduced, whiteLabelColor);
labelColors.set(PrimaryLabels.PriceIncrease, whiteLabelColor);

// Secondary labels
labelColors.set(MlsStatus.Private, blackLabelColor);
labelColors.set(MlsStatus.Active, blueLabelColor);
labelColors.set(MlsStatus.PendingTakingBackups, greenLabelColor);
labelColors.set(MlsStatus.Pending, greenLabelColor);
labelColors.set(MlsStatus.Sold, orangeLabelColor);
labelColors.set(MlsStatus.Leased, orangeLabelColor);
labelColors.set(MlsStatus.Withdrawn, orangeLabelColor);
labelColors.set(MlsStatus.Expired, orangeLabelColor);
labelColors.set(MlsStatus.ListedOnMls, orangeLabelColor);

export const labelsByPriority: Array<keyof Listing> = [
  'isexclusive',
  'isopenhouse',
  'isjustlisted',
  'isbackonmarket',
  'ispricereduced',
  'ispriceincreased',
];

export const labelsHashMap: { [key: string]: string } = {
  isexclusive: PrimaryLabels.Exclusive,
  isopenhouse: PrimaryLabels.OpenHouse,
  isjustlisted: PrimaryLabels.JustListedNew,
  isbackonmarket: PrimaryLabels.BackOnMarket,
  ispricereduced: PrimaryLabels.PriceReduced,
  ispriceincreased: PrimaryLabels.PriceIncrease,
};

export const dontShowPrimary = [
  MlsStatus.Sold,
  MlsStatus.Withdrawn,
  MlsStatus.Expired,
];

export const formatPrimaryLabel = ({
  primaryLabel,
  openhouse,
  latitude,
  longitude,
}: {
  primaryLabel: PrimaryLabel;
  openhouse?: OpenHouse[];
  latitude: string;
  longitude: string;
}): string => {
  if (primaryLabel?.label !== PrimaryLabels.OpenHouse || isEmpty(openhouse)) {
    return primaryLabel?.label || '';
  }

  const currentDate = formatByUTC();

  const nearestOpenHouseDate = chain(openhouse)
    .filter(date => dayjs(date.openhouseendtime).isAfter(currentDate))
    .sortBy('openhousedate')
    .head()
    .value();

  if (!nearestOpenHouseDate) {
    return primaryLabel.label;
  }

  // TODO: latitude & longitude have different types in different places: number and string
  const formattedOpenHouseDate = getTimeSlotLabel({
    latitude: `${latitude}`,
    longitude: `${longitude}`,
    startTime: nearestOpenHouseDate.openhousestarttime,
  });

  return `${primaryLabel.label}: ${formattedOpenHouseDate.toUpperCase()}`;
};

export const addLabelsToItemsInClusters = (
  clusters: Array<Cluster<ClusterData> | Cluster<Listing> | Cluster<Listing[]>>
) => {
  return clusters.map(cluster => {
    let item;

    if (cluster.type === MapItemTypes.Listing) {
      item = cluster as Cluster<Listing>;
      item.data = addLabelsToItem(item.data);
      return item;
    }

    if (cluster.type === MapItemTypes.Group && Array.isArray(cluster.data)) {
      item = cluster as Cluster<Listing[]>;
      item.data = [...item.data.map((item: Listing) => addLabelsToItem(item))];
      return item;
    }

    return cluster;
  });
};

export const addPrimaryLabel = (item: Partial<Listing>): PrimaryLabel => {
  if (
    dontShowPrimary.includes(item.mlsstatus) &&
    not(item.source === ListingsSource.ExlusiveListings)
  ) {
    return null;
  }

  const primaryLabels = labelsByPriority.reduce(
    (labelsForItem: PrimaryLabel[], label: string) => {
      if (
        item.source === ListingsSource.ExlusiveListings &&
        label === isExclusive
      ) {
        labelsForItem.push({
          label: labelsHashMap[label],
          id: label,
          color: labelColors.get(labelsHashMap[label]),
          backgroundColor: labelBackgrounds.get(labelsHashMap[label]),
        });
      }
      if (
        item[label as keyof typeof item] ||
        (notEmpty(item.openhouse) && label === 'isopenhouse')
      ) {
        labelsForItem.push({
          label: labelsHashMap[label],
          id: label,
          color: labelColors.get(labelsHashMap[label]),
          backgroundColor: labelBackgrounds.get(labelsHashMap[label]),
        });
      }

      return labelsForItem;
    },
    []
  );

  // ListingWithDetails didn't contain labelsByPriority props
  return primaryLabels[0] || null;
};

export const addSecondaryLabel = (item: Partial<Listing>): SecondaryLabel => {
  const { mlsstatus, closedate } = item;

  const soldOrLeasedListing =
    mlsstatus === MlsStatus.Sold || mlsstatus === MlsStatus.Leased;
  const labelColor = labelColors.get(mlsstatus);
  const labelBackground = labelBackgrounds.get(mlsstatus);

  return {
    label:
      soldOrLeasedListing && closedate
        ? `${mlsstatus}: ${formatDate(closedate, 'MM/DD/YYYY')}`
        : mlsstatus,
    color: labelColor ? labelColor : DEFAULT_SECONDARY_COLOR,
    backgroundColor: labelBackground ? labelBackground : DEFAULT_SECONDARY_BG,
  };
};

export const addLabelsToItem = <T>(item: T) => {
  return {
    ...item,
    primaryLabel: addPrimaryLabel(item),
    secondaryLabel: addSecondaryLabel(item),
  };
};

export const addExclusiveSecondaryLabel = (
  status: MlsStatus | undefined
): SecondaryLabel => {
  const exclusiveLabelProperties: {
    [key in MlsStatus]?: SecondaryLabel;
  } = {
    [MlsStatus.Private]: {
      label: 'Private',
      color: blackLabelColor,
      backgroundColor: DEFAULT_SECONDARY_BG,
    },
    [MlsStatus.Active]: {
      label: 'Active',
      color: blueLabelColor,
      backgroundColor: DEFAULT_SECONDARY_BG,
    },
    [MlsStatus.Pending]: {
      label: 'Pending',
      color: greenLabelColor,
      backgroundColor: DEFAULT_SECONDARY_BG,
    },
    [MlsStatus.PendingTakingBackups]: {
      label: 'Pending taking Backups',
      color: greenLabelColor,
      backgroundColor: DEFAULT_SECONDARY_BG,
    },
    [MlsStatus.Sold]: {
      label: 'Sold',
      color: orangeLabelColor,
      backgroundColor: DEFAULT_SECONDARY_BG,
    },
    [MlsStatus.Expired]: {
      label: 'Expired',
      color: orangeLabelColor,
      backgroundColor: DEFAULT_SECONDARY_BG,
    },
    [MlsStatus.Withdrawn]: {
      label: 'Withdrawn',
      color: orangeLabelColor,
      backgroundColor: DEFAULT_SECONDARY_BG,
    },
    [MlsStatus.ListedOnMls]: {
      label: 'Listed On Mls',
      color: orangeLabelColor,
      backgroundColor: DEFAULT_SECONDARY_BG,
    },
  };

  return exclusiveLabelProperties[status];
};
