import { default as RcTooltip } from 'rc-tooltip';
import React from 'react';

import { useIsMobile } from 'styled-system/responsive';

export enum Trigger {
  HOVER = 'hover',
  CLICK = 'click',
  FOCUS = 'focus',
}

export enum Placement {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
}

export interface RCTooltipProps {
  afterVisibleChange?: (visible?: boolean) => void;
  align?: Record<string, unknown>;
  arrowContent?: React.ReactNode;
  children?: React.ReactNode;
  defaultVisible?: boolean;
  destroyTooltipOnHide?: boolean;
  getTooltipContainer?: () => Element | null;
  id?: string;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  onPopupAlign?: (
    popupDomNode: Element,
    align: Record<string, unknown>
  ) => void;
  onVisibleChange?: (visible?: boolean) => void;
  overlay: React.ReactNode;
  overlayClassName?: string;
  overlayStyle?: React.CSSProperties;
  placement?: Placement | unknown;
  prefixCls?: string;
  transitionName?: string;
  trigger?: Trigger[];
  visible?: boolean;
}

export interface TooltipProps extends RCTooltipProps {
  themeLight?: boolean;
  themeNotification?: boolean;
  disabled?: boolean;
  onClick?: (() => void) | ((e: React.MouseEvent) => void);
}

const Tooltip = (props: TooltipProps) => {
  const { themeLight, themeNotification, overlay, disabled, ...rest } = props;
  const isMobile = useIsMobile();
  if (disabled) {
    return <>{props.children}</>;
  }
  return (
    <RcTooltip
      trigger={isMobile ? [Trigger.CLICK] : [Trigger.HOVER]}
      overlay={overlay}
      prefixCls={`${themeLight && `app-tooltip-light `} ${
        themeNotification ? `app-tooltip-notification ` : ''
      } app-tooltip`}
      {...rest}
    >
      {props.children}
    </RcTooltip>
  );
};

Tooltip.defaultProps = {
  themeLight: false,
};

export default Tooltip;
