import styled from 'styled-components/macro';

import { FILTER_CONTAINER_WIDTH } from 'filters/filtersContants';
import { $getPropertyTypeChanged } from 'filters/filtersSelectors';
import {
  Checkboxes,
  CheckboxWrapper,
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
} from 'filters/filtersStyled';
import { SharedFiltersProps } from 'filters/filtersTypes';
import { CheckboxField } from 'shared/components/formikFields/CheckboxField';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';

const Container = styled.div`
  width: ${FILTER_CONTAINER_WIDTH}px;
  max-width: 100%;
`;

type PropertyTypeFilterProps = Pick<SharedFiltersProps, 'disableFilter'>;

export const PropertyTypeFilter = (props: PropertyTypeFilterProps) => {
  const isLeasePage = useIsLeasePage();

  const propertyTypeChanged = useSelector($getPropertyTypeChanged);

  return (
    <Container>
      <FilterHeaderContainer>
        {propertyTypeChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Property Type
        </FilterHeaderTitle>
      </FilterHeaderContainer>
      <Checkboxes>
        <CheckboxWrapper>
          <CheckboxField
            name="propertyTypeFilters.singleFamilyResidence"
            disabled={props.disableFilter}
            labelText="Home"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          {not(isLeasePage) && (
            <CheckboxField
              name="propertyTypeFilters.farm"
              disabled={props.disableFilter}
              labelText="Farm/Ranch"
            />
          )}
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="propertyTypeFilters.condominium"
            disabled={props.disableFilter}
            labelText="Condo"
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          {not(isLeasePage) && (
            <CheckboxField
              name="propertyTypeFilters.multiFamily"
              disabled={props.disableFilter}
              labelText="Multi-Family"
            />
          )}
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxField
            name="propertyTypeFilters.townhouse"
            disabled={props.disableFilter}
            labelText="Townhouse"
          />
        </CheckboxWrapper>
        {not(isLeasePage) && (
          <>
            <CheckboxWrapper>
              <CheckboxField
                name="propertyTypeFilters.commercialSale"
                disabled={props.disableFilter}
                labelText="Commercial"
              />
            </CheckboxWrapper>
            <CheckboxWrapper>
              <CheckboxField
                name="propertyTypeFilters.lot"
                disabled={props.disableFilter}
                labelText="Lot"
              />
            </CheckboxWrapper>
            <CheckboxWrapper />
          </>
        )}
      </Checkboxes>
    </Container>
  );
};
