import { escapeRegExp, isEmpty } from 'lodash';
import React from 'react';

import {
  DefaultDropdownOptionLabel,
  DropdownOptionLabel,
} from 'shared/components/SearchBar/components/SearchBarOptionLabel/SearchBarOptionLabelStyled';

export interface SearchBarOptionLabelProps {
  input?: string;
  originalLabel?: string;
}

export const SearchBarOptionLabel: React.FC<SearchBarOptionLabelProps> = (
  props: SearchBarOptionLabelProps
): JSX.Element => {
  const { originalLabel, input } = props;

  if (!isEmpty(input) && !isEmpty(originalLabel)) {
    // escapeRegExp is validator for dynamic RegExp patterns - if pattern was broke it could cause oops error
    const inputCleanedPattern = escapeRegExp(input);
    const inputWithNoApostropheCleanedPattern = escapeRegExp(
      input.split("'")[0]
    );

    const inputMatchRegex = new RegExp(
      `(${inputCleanedPattern})|(${inputWithNoApostropheCleanedPattern})`,
      'gi'
    );
    const resultMatch = originalLabel.split(inputMatchRegex);

    return (
      <DropdownOptionLabel>
        {resultMatch.map((part, index) =>
          part?.match(inputMatchRegex) ? (
            <strong key={index}>{part}</strong>
          ) : (
            part
          )
        )}
      </DropdownOptionLabel>
    );
  }

  return <DefaultDropdownOptionLabel>{input}</DefaultDropdownOptionLabel>;
};

export default SearchBarOptionLabel;
