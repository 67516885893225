import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 *
 * Works as useEffect, but won't execute effect on first/initial render
 */
export function useEffectOnUpdate(
  effect: EffectCallback,
  deps?: DependencyList
) {
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      return void (initialRender.current = false);
    }
    return effect();
  }, deps);
}
