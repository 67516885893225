const prefix = '@media screen and ';

const methods = {
  lessThan: (size: number) => `${prefix}(max-width: ${size}px)`,
  greaterThan: (size: number) => `${prefix}(min-width: ${size}px)`,
  between: (firstSize: number, lastSize: number) =>
    `${prefix}(min-width: ${firstSize}px) and (max-width: ${lastSize}px)`,
};

export const mediaLessThan = (range: number) => {
  return methods.lessThan(range);
};
export const mediaGreaterThan = (range: number) => {
  return methods.greaterThan(range);
};
export const mediaBetween = (minRange: number, maxRange: number) => {
  return methods.between(minRange, maxRange);
};
