import { css } from 'styled-components/macro';

import { zIndex } from 'shared/styles/z-indexes';
import { media } from 'styled-system/responsive';

export default css`
  .app-tooltip.app-tooltip-zoom-enter,
  .app-tooltip.app-tooltip-zoom-leave {
    display: block;
  }

  .app-tooltip-zoom-enter,
  .app-tooltip-zoom-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-play-state: paused;
  }

  .app-tooltip-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
    animation-play-state: paused;
  }

  .app-tooltip-zoom-enter.app-tooltip-zoom-enter-active,
  .app-tooltip-zoom-appear.app-tooltip-zoom-appear-active {
    animation-name: appToolTipZoomIn;
    animation-play-state: running;
  }

  .app-tooltip-zoom-leave.app-tooltip-zoom-leave-active {
    animation-name: appToolTipZoomOut;
    animation-play-state: running;
  }

  @keyframes appToolTipZoomIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }

    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
  }

  @keyframes appToolTipZoomOut {
    0% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }

    100% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
  }

  .app-tooltip {
    position: absolute;
    display: block;
    visibility: visible;
    line-height: 1.5;
    font-size: 12px;
    padding: 1px;
    opacity: 1;
    background-color: transparent;
    z-index: ${zIndex.TOOLTIP};
    &.app-tooltip-notification {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
      z-index: ${zIndex.TOOLTIP_NOTIFICATION};
    }
  }

  .app-tooltip-hidden {
    display: none;
  }

  .app-tooltip-inner {
    position: sticky;
    text-align: left;
    text-decoration: none;
    min-height: 34px;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    &:not(.app-tooltip-light) {
      padding: 6px 8px;
      background-color: #515e68;
      color: #ffffff;
      font-weight: bold;
    }
    &.app-tooltip-light {
      padding: 12px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
      background: #fff;
      color: #515e68;
      font-weight: normal;
    }
    &.app-tooltip-notification {
      background: #fff;
      padding: 0;
      font-size: 12px;
      font-weight: normal;
      color: #515e68;
    }
  }
  .app-tooltip-arrow {
    &:not(.app-tooltip-light) {
      border-bottom: 5px solid #515e68;
    }
  }

  .app-tooltip-arrow,
  .app-tooltip-arrow-inner {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: ${zIndex.TOOLTIP + 1};
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid rgb(255, 255, 255);

    &.app-tooltip-notification {
      border-width: 0 8px 9px !important;
      border-bottom-color: #eef0f2 !important;
      top: -10px !important;
      ${media.sm`
      top: -5px !important;
      border-width: 0 6px 8px !important;
      `}
      &::after {
        top: 2px;
        content: '';
        display: block;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        border-style: solid;
        border-width: 0 8px 9px;
        border-color: transparent transparent #fff transparent;
        ${media.sm`
          top: 0 !important;
          border-width: 0 6px 8px !important;
        `}
      }
    }
  }

  .app-tooltip-placement-top .app-tooltip-arrow,
  .app-tooltip-placement-topLeft .app-tooltip-arrow,
  .app-tooltip-placement-topRight .app-tooltip-arrow {
    margin-left: -3px;
    bottom: -6px;
    transform: rotate(180deg);
  }

  .app-tooltip-placement-top .app-tooltip-arrow-inner,
  .app-tooltip-placement-topLeft .app-tooltip-arrow-inner,
  .app-tooltip-placement-topRight .app-tooltip-arrow-inner {
    bottom: 1px;
    margin-right: -6px;
  }

  .app-tooltip-placement-top .app-tooltip-arrow,
  .app-tooltip-placement-bottom .app-tooltip-arrow {
    left: 50%;
  }

  .app-tooltip-placement-topLeft .app-tooltip-arrow,
  .app-tooltip-placement-bottomLeft .app-tooltip-arrow {
    left: 24px;
  }

  .app-tooltip-placement-topRight .app-tooltip-arrow,
  .app-tooltip-placement-bottomRight .app-tooltip-arrow {
    right: 24px;
  }

  .app-tooltip-placement-right .app-tooltip-arrow,
  .app-tooltip-placement-rightTop .app-tooltip-arrow,
  .app-tooltip-placement-rightBottom .app-tooltip-arrow {
    margin-top: -3px;
    left: -6px;
    transform: rotate(-90deg);
  }

  .app-tooltip-placement-right .app-tooltip-arrow-inner,
  .app-tooltip-placement-rightTop .app-tooltip-arrow-inner,
  .app-tooltip-placement-rightBottom .app-tooltip-arrow-inner {
    left: 1px;
    margin-top: -6px;
  }

  .app-tooltip-placement-right .app-tooltip-arrow,
  .app-tooltip-placement-left .app-tooltip-arrow {
    top: 50%;
  }

  .app-tooltip-placement-rightTop .app-tooltip-arrow,
  .app-tooltip-placement-leftTop .app-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }

  .app-tooltip-placement-rightBottom .app-tooltip-arrow,
  .app-tooltip-placement-leftBottom .app-tooltip-arrow {
    bottom: 15%;
  }

  .app-tooltip-placement-left .app-tooltip-arrow,
  .app-tooltip-placement-leftTop .app-tooltip-arrow,
  .app-tooltip-placement-leftBottom .app-tooltip-arrow {
    margin-top: -3px;
    right: -6px;
    transform: rotate(90deg);
  }

  .app-tooltip-placement-left .app-tooltip-arrow-inner,
  .app-tooltip-placement-leftTop .app-tooltip-arrow-inner,
  .app-tooltip-placement-leftBottom .app-tooltip-arrow-inner {
    right: 1px;
    margin-top: -6px;
  }

  .app-tooltip-placement-bottom .app-tooltip-arrow,
  .app-tooltip-placement-bottomLeft .app-tooltip-arrow,
  .app-tooltip-placement-bottomRight .app-tooltip-arrow {
    margin-left: -3px;
    top: -6px;
  }

  .app-tooltip-placement-bottom .app-tooltip-arrow-inner,
  .app-tooltip-placement-bottomLeft .app-tooltip-arrow-inner,
  .app-tooltip-placement-bottomRight .app-tooltip-arrow-inner {
    top: 1px;
    margin-left: -6px;
  }
`;
