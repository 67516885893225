import { Form, Formik, useField, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import FiltersFooter from 'filters/components/FiltersFooter';
import { applyFilters, resetPriceFilters } from 'filters/filtersActions';
import { SELECT_MAX, SELECT_MIN } from 'filters/filtersContants';
import {
  filterMinMax,
  getNumericValueForPrice,
  getPriceTitle,
  leasePriceList,
  priceList,
} from 'filters/filtersHelpers';
import { $getPriceChanged } from 'filters/filtersSelectors';
import {
  FilterHeaderContainer,
  FilterHeaderSubtitle,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
} from 'filters/filtersStyled';
import { PriceFilters, SharedFiltersProps } from 'filters/filtersTypes';
import { InputSelectField } from 'shared/components/formikFields/InputSelectField';
import { SelectOption } from 'shared/components/Select/SelectTypes';
import { not, notEmpty } from 'shared/helpers/boolean';
import { formatShortPrice } from 'shared/helpers/formatters';
import { useSelector } from 'shared/helpers/redux';
import { useEffectOnUpdate } from 'shared/hooks/use-effect-on-update';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';
import { media, useIsMobile } from 'styled-system/responsive';

interface PriceFilterProps extends SharedFiltersProps {
  initialValues: PriceFilters;
  onSubmit: () => void;
}

export default function PriceFilter(props: PriceFilterProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
    >
      <Form>
        <PriceFilterFC disableFilter={props.disableFilter} />
        <SyncFormikWithRedux />
      </Form>
    </Formik>
  );
}

function SyncFormikWithRedux(): null {
  const dispatch = useDispatch();
  const { initialValues, values } = useFormikContext<PriceFilters>();

  useEffectOnUpdate(() => {
    if (values !== initialValues) {
      dispatch(applyFilters(values));
    }
  }, [values]);

  return null;
}

export const PriceFilterFC = (props: SharedFiltersProps) => {
  const isMobile = useIsMobile();
  const isLeasePage = useIsLeasePage();

  const dispatch = useDispatch();
  const priceChanged = useSelector($getPriceChanged);

  const [{ value: minPrice }] = useField<number>({ name: 'minPrice' });
  const [{ value: maxPrice }] = useField<number>({ name: 'maxPrice' });
  const priceTitle = getPriceTitle({ minPrice, maxPrice });

  const currentPriceList = isLeasePage ? leasePriceList : priceList;

  const isInputValid = (value: string) => {
    const digitValue = getNumericValueForPrice(value);
    return Boolean(
      value &&
        /^\d+(k|m|b)?$/i.test(value) &&
        not(currentPriceList.min.some(price => price.value === digitValue))
    );
  };

  const minValidator = (value: string) => {
    if (value && isInputValid(value)) {
      const numeric = getNumericValueForPrice(value);
      return numeric <= maxPrice || not(maxPrice);
    }
    return false;
  };

  const maxValidator = (value: string) => {
    if (value && isInputValid(value)) {
      const numeric = getNumericValueForPrice(value);
      return numeric >= minPrice || not(minPrice);
    }
    return false;
  };

  //TODO check how it works
  const filterPriceOptions = (
    option: SelectOption,
    inputValue: string
  ): boolean => {
    const { value } = option;
    const priceWithoutChars = inputValue.replace(/(\$|,|\s)/g, '');

    let preparedPrice = priceWithoutChars;

    if (isLeasePage) {
      preparedPrice =
        not(/^\d+(k|m|b)?$/i.test(priceWithoutChars)) &&
        notEmpty(priceWithoutChars)
          ? 'null'
          : getNumericValueForPrice(priceWithoutChars) > 5000
          ? priceWithoutChars
          : getNumericValueForPrice(priceWithoutChars).toString();
    }

    const hasMatch = preparedPrice
      .split(' ')
      .some(number =>
        option.label.toLowerCase().includes(number.toLowerCase())
      );

    return String(value).includes(preparedPrice) || hasMatch;
  };

  return (
    <Container innerMode={props.innerMode}>
      <ItemContainer>
        <FilterHeaderContainer>
          {isMobile && priceChanged && <FilterSectionChangedIndicator />}
          <FilterHeaderTitle>List Price</FilterHeaderTitle>
          <FilterHeaderSubtitle>{priceTitle}</FilterHeaderSubtitle>
        </FilterHeaderContainer>
        <SelectContainer>
          <SelectWrapper>
            <InputSelectField
              ariaLabel={`${SELECT_MIN} price`}
              isDisabled={props.disableFilter}
              name="minPrice"
              filterOptions={filterPriceOptions}
              validator={minValidator}
              formatter={value => formatShortPrice(+value)}
              blurInputOnSelect
              options={currentPriceList.min.filter(obj =>
                filterMinMax({
                  value: obj.value,
                  min: minPrice,
                  max: maxPrice,
                  isMin: true,
                })
              )}
              rawValue={minPrice}
            />
          </SelectWrapper>
          <ToContainer>to</ToContainer>
          <SelectWrapper>
            <InputSelectField
              blurInputOnSelect
              ariaLabel={`${SELECT_MAX} price`}
              name="maxPrice"
              isDisabled={props.disableFilter}
              validator={maxValidator}
              formatter={value => formatShortPrice(+value)}
              rawValue={maxPrice}
              options={currentPriceList.max.filter(obj =>
                filterMinMax({
                  value: obj.value,
                  min: minPrice,
                  max: maxPrice,
                })
              )}
            />
          </SelectWrapper>
        </SelectContainer>
      </ItemContainer>

      {not(props.innerMode) && (
        <FiltersFooter
          canReset={priceChanged && not(props.disableFilter)}
          onReset={() => dispatch(resetPriceFilters())}
        />
      )}
    </Container>
  );
};

const SelectWrapper = styled.div`
  width: 168px;
  padding-bottom: 22px;
  ${media.sm(css`
    width: 135px;
  `)};
`;

const ToContainer = styled.div`
  color: ${p => p.theme.colors.text2};
  font-size: 16px;
  margin: 8px 16px 0;
  ${media.sm(css`
    margin: 0;
  `)};
`;

const Container = styled.div<{ innerMode?: boolean }>`
  width: 442px;
  max-width: 100vw;

  ${({ innerMode }) =>
    innerMode
      ? css`
          ${SelectWrapper} {
            padding-bottom: 0;
          }
        `
      : css`
          ${FilterHeaderContainer} {
            padding: 24px 0;
          }
        `}

  ${media.sm(css`
    width: 100%;
  `)};
`;

const ItemContainer = styled.div`
  padding: 0 24px;
  ${media.md(css`
    padding: 0;
  `)};
`;

const SelectContainer = styled.div`
  display: flex;
  ${media.sm(css`
    justify-content: space-between;
    align-items: center;
    max-width: 441px;
  `)}
`;
