import styled from 'styled-components/macro';

const InputGroup = styled.div`
  position: relative;
  & > div {
    margin-bottom: 16px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
  & > div:first-child {
    margin-bottom: 16px;
  }
`;

export default InputGroup;
