import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';

export interface MetadataProps {
  title?: string;
  description?: string;
  robots?: string;
}

export const Metadata = ({ title, description, robots }: MetadataProps) => {
  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();

  useEffect(() => {
    if (title) {
      ariaLiveAnnouncerCtx.announce(title);
    }
  }, [title]);

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && (
        <meta name="description" content={description.slice(0, 299)} />
      )}
      {robots && <meta name="robots" content={robots} />}
    </Helmet>
  );
};

Metadata.displayName = 'Metadata';

export default React.memo(Metadata);
