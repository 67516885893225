import { Bugsnag, default as create } from '@bugsnag/browser';

import {
  apiKey,
  releaseStage,
  shouldAutoNotify,
} from 'shared/services/bugsnag/config';
import { store } from 'store';

const createBugsnag = (): Bugsnag.Client => {
  if (process.env.NODE_ENV === 'test') {
    // todo we need to mock bugsnag
    return {} as any;
  }

  return create({
    apiKey,
    autoNotify: shouldAutoNotify,
    releaseStage,
    beforeSend: report => {
      const user = store.getState().user;
      if (user.isLogged) {
        const profile = user.profile?.data;
        report.user = {
          email: profile?.email,
          firstname: profile?.firstname,
          lastname: profile?.lastname,
          username: profile?.username,
        };
      }
    },
  });
};

export const bugsnag = createBugsnag();
