import { call, put, select } from 'redux-saga/effects';

import { fetchAgentBioRequest } from 'agents/agentsActions';
import { AgentsService } from 'agents/AgentsService';
import { Agent } from 'agents/agentsTypes';
import { getRecommendedAgent } from 'user/userSelectors';

export function* getAgentByNameOrSfId(): Saga<Agent | undefined> {
  const recommendedAgent = yield select(getRecommendedAgent);
  if (recommendedAgent) {
    yield put(fetchAgentBioRequest());
    return yield call(AgentsService.fetchAgent, { agentId: recommendedAgent });
  }
  return void 0;
}
