import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { isListingDetails } from 'adwords/helpers';
import { RootState } from 'rootReducer';
import { ModalContext } from 'shared/components/Modal/modalContext';
import {
  sessionStorage,
  SessionStorageInterface,
} from 'shared/services/sessionStorage';
import { AuthType, showAuthModal } from 'user/helpers/showAuthModal';
import { getIsInitialized, isAuthorised } from 'user/userSelectors';

export const FORCE_REGISTRATION_KEY = 'force-registration';

export type AdwordsForceRegistrationProviderProps = {
  storage?: SessionStorageInterface;
} & ReturnType<typeof mapStateToProps>;

export const AdwordsForceRegistrationProvider: React.FC<
  AdwordsForceRegistrationProviderProps
> = props => {
  const { initialized, logged, storage = sessionStorage } = props;

  const [forceRegistration, setForceRegistration] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const modalContext = useContext(ModalContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (initialized) {
      if (!logged) {
        checkForceRegistration();
      } else {
        removeForceFromSearch();
      }
    }

    function checkForceRegistration() {
      const force = [
        searchParams.get('force'),
        storage.getItem(FORCE_REGISTRATION_KEY),
      ].includes('true');

      if (force) {
        storage.setItem(FORCE_REGISTRATION_KEY, 'true');
      }

      removeForceFromSearch();
      setForceRegistration(force);
    }

    function removeForceFromSearch() {
      if (searchParams.get('force') === 'true') {
        searchParams.delete('force');
        history.replace({
          pathname: location.pathname,
          search: searchParams.toString(),
          state: location.state,
        });
      }
    }
  }, [initialized]);

  useEffect(() => {
    if (logged) {
      storage.removeItem(FORCE_REGISTRATION_KEY);
      setForceRegistration(false);
    }
  }, [logged]);

  useEffect(() => {
    /* 
      If user comes from adwords on listing details we'll wait 5 seconds and
      show sign up modal
    */
    if (forceRegistration && isListingDetails(location.pathname)) {
      const timeout = setTimeout(
        showAuthModal(AuthType.SignUp, modalContext, {
          hasCloseBtn: false,
          onRequestClose: () => false,
        }),
        5000
      );
      return () => clearTimeout(timeout);
    }
  }, [forceRegistration, location.pathname]);

  return <>{props.children}</>;
};

const mapStateToProps = (state: RootState) => ({
  initialized: getIsInitialized(state),
  logged: isAuthorised(state),
});

const connected = connect(mapStateToProps);
export const ConnectedAdwordsForceRegistrationProvider = connected(
  React.memo(AdwordsForceRegistrationProvider)
);
