import { ListingSources } from 'shared/config/configConstants';
import { formatCurrentDate } from 'shared/helpers/formatDate';

export const LISTING_DETAILS_DEFAULT_ZOOM = 17;

export const ALL_GALLERY_IMAGES_AVAILABLE = 9999;

export const disclaimerText = (source?: ListingSources) =>
  source === ListingSources.ExclusiveListing
    ? "The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. All information provided is deemed reliable but is not guaranteed and should be independently verified."
    : source === ListingSources.Sabor
    ? `The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Based on information from the San Antonio Board of REALTORS® (SABOR) from ${formatCurrentDate(
        'MMMM Do, YYYY [at] h:mma'
      )}. SABOR in no way guarantees or is in any way responsible for its accuracy. SABOR and their affiliates provide the MLS and all content therein "AS IS" and without any warranty, express or implied. Data maintained by SABOR may not reflect all real estate activity in the market. All information provided is deemed reliable but is not guaranteed and should be independently verified.`
    : `The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Based on information from the Austin Board of REALTORS® (alternatively, from ACTRIS) from ${formatCurrentDate(
        'MMMM Do, YYYY [at] h:mma'
      )}. Neither the Board nor ACTRIS guarantees or is in any way responsible for its accuracy. The Austin Board of REALTORS®, ACTRIS and their affiliates provide the MLS and all content therein "AS IS" and without any warranty, express or implied. Data maintained by the Board or ACTRIS may not reflect all real estate activity in the market. All information provided is deemed reliable but is not guaranteed and should be independently verified.`;
export const HOMEOWNERS_INSURANCE = 1.09;
export const DOWNPAYMENT = 20;
export const CALCULATOR_MAX_CURRENCY_VALUE = 100000000;
export const CALCULATOR_MAX_PERCENTAGE_VALUE = 100;
export const INTEREST_RATE = 6.0;

export const listingsWithBluredPrice = [
  '4994114',
  '1488860',
  '3124982',
  '7340607',
  '4450085',
  '1249297',
  '9857590',
  '4358140',
  '6156604',
];

export const scrollableModalClassName = '.modal-content';

export const propertyOptions = {
  showinginstructions: {
    singleLine: true,
  },
  privateremarks: {
    singleLine: true,
  },
};

export const agentsShowingInfo = {
  showingrequirements: 'Showing Requirements',
  lockboxtype: 'Lockbox Type',
  lockboxlocation: 'Lockbox Location',
  signonpropertyyn: 'Sign on Property',
  accesscode: 'Access Code',
  abor_accessinstruction: 'Access Instructions',
  showinginstructions: 'Showing Instructions',
  privateremarks: 'Agent Remarks',
};

export const agentsOfficeInfo = {
  occupanttype: 'Occupied by',
  occupantphone: 'Occupant Phone',
  showingcontactname: 'Showing Contact Name',
  showingcontactphone: 'Showing Contact Phone',
  showingcontacttype: 'Showing Contact Type',
  showingservicephone: 'Showing Service Phone',
  ownername: 'Owner Name',
  ownerphone: 'Owner Phone',
  listofficename: 'List Office',
  listofficephone: 'List Office Phone',
  listagentfullname: 'List Agent Name',
  listagentdirectphone: 'List Agent Phone',
  listagentemail: 'List Agent Email',
  colistagentfullname: 'Co-List Agent Name',
  colistagentdirectphone: 'Co-List Agent Phone',
  colistagentemail: 'Co-List Agent Email',
};

export const UNLOCK_PARAM = 'unlock';
