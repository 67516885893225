/*global google*/
import { get } from 'lodash';

import { getEnv } from 'env-config';
import { GoogleMapApiType } from 'shared/types/placesAndPolygons';

export enum StreetViewMetadataResponseStatus {
  OK = 'OK', // Indicates that no errors occurred; a panorama is found and metadata is returned.
  ZERO_RESULTS = 'ZERO_RESULTS', // Indicates that no panorama could be found near the provided location. This may occur if a non-existent or invalid panorama ID is given.
  NOT_FOUND = 'NOT_FOUND', // Indicates that the address string provided in the location parameter could not be found. This may occur if a non-existent address is given.
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT', // Indicates that you have exceeded your daily quota or per-second quota for this API.
  REQUEST_DENIED = 'REQUEST_DENIED', // Indicates that your request was denied. This may occur if you did not use an API key or client ID, or if the Street View Static API is not activated in the Google Cloud Platform Console project containing your API key.
  INVALID_REQUEST = 'INVALID_REQUEST', // Generally indicates that the query parameters (address or latlng or components) are missing.
  UNKNOWN_ERROR = 'UNKNOWN_ERROR', // Indicates that the request could not be processed due to a server error. This is often a temporary status. The request may succeed if you try again.
}

const BASE_STREETVIEW_PATH = 'https://maps.googleapis.com/maps/api/streetview';

class GoogleApiService {
  static getTitleFromLatLng = (
    lat: string,
    lng: string,
    maps: GoogleMapApiType
  ) => {
    return new Promise<{ results: google.maps.GeocoderResult[] }>(
      (resolve, reject) => {
        const service = new maps.Geocoder();
        const latlng = new maps.LatLng(Number(lat), Number(lng));
        service.geocode({ location: latlng }, (response, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve({ results: response });
          } else {
            reject(new Error(`Unable to fetch address ${status}`));
          }
        });
      }
    );
  };
  static getDistanceFromLatLng({ mode, origins, destinations, maps }: any) {
    return new Promise<google.maps.DistanceMatrixResponseElement[]>(
      (resolve, reject) => {
        const service = new maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins,
            destinations,
            travelMode: mode,
            unitSystem: maps.UnitSystem.IMPERIAL,
          },
          (
            response: google.maps.DistanceMatrixResponse,
            status: google.maps.DistanceMatrixStatus
          ) => {
            if (status === maps.DistanceMatrixStatus.OK) {
              resolve(get(response, 'rows[0].elements'));
            } else {
              reject(new Error(`Unable to fetch distance ${status}`));
            }
          }
        );
      }
    );
  }
}

export class StreetViewService {
  static async fetchStreetViewMetadata(latitude: number, longitude: number) {
    const location = `${latitude},${longitude}`;
    const size = `${window.innerWidth}x${window.innerHeight}`;
    const metadataUrl = `${BASE_STREETVIEW_PATH}/metadata?size=${size}&location=${location}&fov=120&key=${
      getEnv().mapKey
    }`;

    const metadataResponse = await fetch(metadataUrl);

    return metadataResponse.json();
  }

  static async fetchStreetViewPicture(latitude: number, longitude: number) {
    const location = `${latitude},${longitude}`;
    const size = `${window.innerWidth}x${window.innerHeight}`;
    const url = `${BASE_STREETVIEW_PATH}?size=${size}&location=${location}&fov=120&key=${
      getEnv().mapKey
    }`;

    const response = await fetch(url);

    if (response.status < 300) {
      return response;
    }
  }
}

export { GoogleApiService };
