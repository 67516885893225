export enum ServerValidationErrorsMessage {
  email = 'Please enter a valid email',
  phone = 'Please enter a valid phone number',
  firstname = 'This first name is invalid',
  lastname = 'This last name is invalid',
  message = 'This message is invalid',
  streetAddress = 'This street address is invalid',
  city = 'This city is invalid',
  state = 'This state is invalid',
  zipCode = 'This zip code is invalid',
  date = 'This date is invalid',
}

export enum AuthErrorType {
  UserResetPasswordLinkExpired = 'userResetPasswordLinkExpired',
  UserNotFound = 'userNotFound',
  UserExist = 'userExist',
  WrongCredentials = 'wrongCredentials',
  EmailVerification = 'emailVerification',
  UnrecognizedError = 'UnrecognizedError',
  NoError = '',
  Empty = '',
  EmailExistOnSignInError = 'UserNotFoundException',
  EmailWithWrongPassError = 'NotAuthorizedException',
}

export const ACCESS_DENIED_FROM_AGENTS_ACCOUNT_MESSAGE =
  'Form submissions are not allowed from Agent accounts';

export const ERROR_MESSAGES = {
  REQUIRED: 'Field is required',
  GREATER_THAN_0: 'The field value should be greater than 0',
  EMAIL: 'Please enter a valid email',
  MUST_BE_A_NUMBER: 'The field value must be a number',
  ONLY_LETTERS: 'Should include only letters',
  PHONE: 'Please enter a valid phone number',
};
