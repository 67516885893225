import { useEffect } from 'react';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components/macro';

import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import { ReactComponent as IconError } from 'shared/icons/shared/24x24/error.svg';
import { ReactComponent as IconSuccess } from 'shared/icons/shared/24x24/success.svg';
import { media } from 'styled-system/responsive';

const RealToast = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  .toaster-icon {
    width: 20px;
    height: 20px;
    opacity: 0.9;
    fill: ${({ theme }) => theme.white};
    margin-right: 8px;
  }
`;

const ToastBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const ToastMessage = styled.div`
  flex: 1;
  align-self: center;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.43;
  word-break: break-word;
  white-space: pre-wrap;
  font-family: Lato, sans-serif;
  ${media.sm(css`
    white-space: normal;
  `)}
`;

export const ControlButton = styled.button`
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  color: ${({ theme }) => theme.white};
  padding-left: 16px;
  background-color: inherit;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

const ControlButtons = styled.div`
  align-items: center;
  display: flex;
  text-transform: uppercase;
  margin-left: 16px;
  & > ${ControlButton}:not(:only-of-type):first-child {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

export const iconsByType = {
  [toast.TYPE.ERROR]: IconError,
  [toast.TYPE.SUCCESS]: IconSuccess,
};

export interface ToastProps {
  type?: keyof typeof iconsByType;
  message: string;
  closeToast: () => unknown;
  onViewClick?: () => unknown;
  onUndoClick?: () => unknown;
  onEditClick?: () => unknown;
  toastDomId?: string;
}

const Toast = ({
  type,
  message,
  closeToast,
  onViewClick,
  onUndoClick,
  onEditClick,
  toastDomId,
}: ToastProps) => {
  const Icon = type ? iconsByType[type] : null;
  const ariaLiveCtx = useAriaLiveAnnouncerContext();

  useEffect(() => {
    if (message) {
      const ariaMessage = `${
        type === toast.TYPE.ERROR ? 'Failed!' : 'Success!'
      } ${message}`;
      ariaLiveCtx.announce(ariaMessage, 'alert');
    }
  }, [message]);

  return (
    <RealToast id={toastDomId}>
      {Icon && <Icon className="toaster-icon" />}
      <ToastBody>
        <ToastMessage>{message}</ToastMessage>
        <ControlButtons>
          {onUndoClick && (
            <ControlButton
              onClick={() => {
                onUndoClick();
                closeToast();
              }}
            >
              UNDO
            </ControlButton>
          )}
          {onViewClick && (
            <ControlButton
              onClick={() => {
                onViewClick();
                closeToast();
              }}
            >
              VIEW
            </ControlButton>
          )}
          {onEditClick && (
            <ControlButton
              onClick={() => {
                onEditClick();
                closeToast();
              }}
            >
              EDIT
            </ControlButton>
          )}
        </ControlButtons>
      </ToastBody>
    </RealToast>
  );
};

export default Toast;
