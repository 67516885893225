import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components/macro';

import { ErrorMessageClassName } from 'shared/components/Input/Input';
import {
  InputContainer,
  RealInput,
  TextAreaLabel,
  TextAreaLabelContainer,
  TextAreaMessage,
  TextAreaOptionalLabel,
} from 'shared/components/TextArea/TextAreaStyled';
import { not } from 'shared/helpers/boolean';
import { ReactComponent as IconError } from 'shared/icons/shared/24x24/error.svg';
import { ErrorMessagePlacement } from 'shared/types/errorTypes';

export interface TextAreaProps {
  id: string;
  value?: string;
  label?: string | React.ReactElement;
  placeholder?: string;
  disabled?: boolean;
  focused?: boolean;
  error?: boolean;
  warn?: boolean;
  message?: string;
  parentWidth?: boolean;
  optional?: boolean;
  autoResize?: boolean;
  maxHeight?: number;
  onResize?: any;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onCut?: any;
  onPaste?: any;
  onDrop?: any;
  style?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  className?: string;
  maxLength?: number;
  errorMessagePlacement?: ErrorMessagePlacement;
  floatingMessage?: boolean;
}

type Events = 'onKeyDown' | 'onCut' | 'onPaste' | 'onDrop' | 'onChange';

const TextArea = (props: TextAreaProps) => {
  const [focused, setIsFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>();
  const theme = useTheme();

  useEffect(() => {
    if (props.focused) {
      setFocusOnRef();
      setIsFocused(true);
    }
  }, [props.focused]);

  useEffect(() => {
    if (props.value === '' && props.autoResize) {
      handleAutoResize();
    }
  }, [props.value, props.autoResize]);

  const setFocusOnRef = () => {
    textareaRef?.current?.focus();
  };

  const wrapEventsWithAutoResize = () => {
    const events: Events[] = [
      'onKeyDown',
      'onCut',
      'onPaste',
      'onDrop',
      'onChange',
    ];
    const autoResizeProps: Partial<{
      [key in Events]: (...args: unknown[]) => unknown;
    }> = {};
    events.forEach(event => {
      autoResizeProps[event] =
        typeof props[event] === 'function'
          ? (e: unknown) => {
              props[event](e);
              handleAutoResize();
            }
          : handleAutoResize;
    });
    return autoResizeProps;
  };

  const handleAutoResize = () => {
    const { current } = textareaRef;
    const { onResize, maxHeight } = props;
    if (!current) {
      return;
    }
    current.style.height = 'auto';
    current.style.height = `${current.scrollHeight}px`;
    current.style.overflowY = 'hidden';
    if (maxHeight && maxHeight <= current.scrollHeight) {
      current.style.overflowY = 'scroll';
    }
    if (
      typeof onResize === 'function' &&
      (!maxHeight || maxHeight > current.scrollHeight)
    ) {
      onResize(current.scrollHeight);
    }
  };

  const {
    id,
    label,
    disabled,
    error,
    warn,
    message,
    parentWidth,
    optional,
    autoResize,
    maxHeight,
    onResize,
    maxLength,
    focused: defaultFocused,
    value,
    errorMessagePlacement,
    floatingMessage = true,
    ...rest
  } = props;
  const hasLabel = !!label;
  const hasMessage = !!message;
  const messageColor = error ? theme.error : warn ? theme.warning : theme.white;

  let autoResizeProps = {};
  if (autoResize) {
    autoResizeProps = wrapEventsWithAutoResize();
  }

  return (
    <RealInput className={props.className}>
      {(hasLabel || optional) && (
        <TextAreaLabelContainer>
          {hasLabel && (
            <TextAreaLabel
              aria-label="Comment on this listing, optional, edit. Type something"
              htmlFor={id}
            >
              {label}
            </TextAreaLabel>
          )}
          {optional && <TextAreaOptionalLabel>optional</TextAreaOptionalLabel>}
        </TextAreaLabelContainer>
      )}

      <InputContainer
        disabled={disabled}
        focused={focused}
        parentWidth={parentWidth}
        error={error}
        autoResize={autoResize}
        maxHeight={maxHeight}
        value={value}
      >
        <textarea
          {...rest}
          {...autoResizeProps}
          ref={textareaRef}
          id={id}
          rows={1}
          disabled={disabled}
          maxLength={maxLength}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={value}
        />

        {error && not(focused) && <IconError width="24px" fill={theme.error} />}
        {hasMessage && not(focused) && floatingMessage && (
          <TextAreaMessage
            floating
            className={ErrorMessageClassName}
            color={messageColor}
            placement={errorMessagePlacement}
          >
            {message}
          </TextAreaMessage>
        )}
      </InputContainer>

      {hasMessage && not(focused) && not(floatingMessage) && (
        <TextAreaMessage
          className={ErrorMessageClassName}
          color={messageColor}
          placement={ErrorMessagePlacement.Bottom}
        >
          {message}
        </TextAreaMessage>
      )}
    </RealInput>
  );
};

export default TextArea;
