import React from 'react';

import {
  ModalContext,
  ModalContextType,
} from 'shared/components/Modal/modalContext';
import { loadable } from 'shared/utils/loadable';
import { unsafeCoerce } from 'shared/utils/types';

const SignUp = loadable(
  () => import(/* webpackChunkName: "sign-up" */ 'user/SignUp')
);

const LogIn = loadable(
  () => import(/* webpackChunkName: "log-in" */ 'user/LogIn')
);

export enum AuthType {
  LogIn = 'login',
  SignUp = 'signup',
}

export type AuthModal = typeof LogIn | typeof SignUp;

export const reversAuthType = (authType: AuthType): AuthType =>
  authType === AuthType.LogIn ? AuthType.SignUp : AuthType.LogIn;

export const getAuthModal = (authType: AuthType): AuthModal =>
  authType === AuthType.LogIn ? LogIn : SignUp;

export interface ModalProps {
  [key: string]: unknown;
  onAuthLinkClick?: (
    additionalProps: ModalProps
  ) => (e?: React.SyntheticEvent) => void;
}

export interface GetAuthModalPropsOptions {
  authType: AuthType;
  props: ModalProps;
  modalContext: ModalContextType;
}

export const getAuthModalProps = (
  options: GetAuthModalPropsOptions
): ModalProps => {
  const { props, authType, modalContext } = options;

  return {
    ...props,
    listagentmlsid: modalContext.props.listagentmlsid,
    onAuthLinkClick: (additionalProps: ModalProps = {}) =>
      showAuthModal(reversAuthType(authType), modalContext, {
        ...props,
        ...additionalProps,
      }),
  };
};

export const showAuthModal = (
  authType: AuthType,
  modalContext: React.ContextType<typeof ModalContext>,
  props: ModalProps = {}
) => {
  const AuthModal = getAuthModal(authType);
  const modalAuthProps = getAuthModalProps({
    props,
    authType,
    modalContext,
  });

  return modalContext.showModal(AuthModal, unsafeCoerce(modalAuthProps));
};
