import React from 'react';

import { IconPosition } from 'shared/components/Button/ButtonConstants';
import {
  Badge,
  ButtonInnerBlock,
  Label,
  LoaderWrapper,
} from 'shared/components/Button/ButtonStyled';
import ThreeDotsLoader from 'shared/components/loaders/ThreeDotsLoader';
import { notNil } from 'shared/helpers/boolean';
import globalTheme from 'shared/styles/globalTheme';

export interface ButtonInnerProps {
  iconPosition?: IconPosition;
  icon?: any;
  badge?: string | number;
  children?: React.ReactNode;
  value?: string;
  drawingMapButton?: boolean;
  loading?: boolean;
  primary?: boolean;
  tertiary?: boolean;
  hasText?: boolean;
  hasIcon?: boolean;
  hasBadge?: boolean;
  rmInnerPadding?: boolean;
  paginationIcon?: boolean;
  isText?: boolean;
  dirty?: boolean;
}

const InnerButton = (props: ButtonInnerProps) => {
  const {
    iconPosition,
    icon,
    badge,
    children,
    value,
    drawingMapButton,
    loading,
    primary,
    tertiary,
    rmInnerPadding,
    paginationIcon,
    isText,
    dirty,
  } = props;

  const hasText = notNil(children) || notNil(value);
  const hasIcon = notNil(icon);
  const hasBadge = notNil(badge);

  return (
    <ButtonInnerBlock
      rmInnerPadding={rmInnerPadding}
      paginationIcon={paginationIcon}
      drawingMapButton={drawingMapButton}
      iconPosition={iconPosition}
      hasIcon={hasIcon}
    >
      {loading ? (
        <LoaderWrapper>
          <ThreeDotsLoader
            color={
              isText
                ? globalTheme.secondary
                : primary
                ? globalTheme.white
                : tertiary
                ? globalTheme.text2
                : globalTheme.primary
            }
          />
        </LoaderWrapper>
      ) : (
        <>
          {(iconPosition === IconPosition.RIGHT ||
            iconPosition === IconPosition.BOTTOM) && (
            <>
              {hasText && (
                <Label hasIcon={hasIcon} iconPosition={iconPosition}>
                  {children || value}
                </Label>
              )}
              {hasIcon && icon}
              {hasBadge && <Badge dirty={dirty}>{badge}</Badge>}
            </>
          )}

          {(iconPosition === IconPosition.LEFT ||
            iconPosition === IconPosition.TOP) && (
            <>
              {hasIcon && icon}
              {hasText && (
                <Label hasIcon={hasIcon} iconPosition={iconPosition}>
                  {children || value}
                </Label>
              )}
              {hasBadge && <Badge>{badge}</Badge>}
            </>
          )}
        </>
      )}
    </ButtonInnerBlock>
  );
};

export default InnerButton;
