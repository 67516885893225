import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useEffect, useRef, useState } from 'react';
import ScrollLock from 'react-scrolllock';
import styled from 'styled-components/macro';

import { SORTING_FILTERS_TOOLTIP_CLASS_NAME } from 'filters/filtersContants';
import { not } from 'shared/helpers/boolean';
import { zIndex } from 'shared/styles/z-indexes';
import { media, useIsMobile } from 'styled-system/responsive';

export const TooltipWrapper = styled.span`
  .rc-tooltip {
    pointer-events: all;
    opacity: 1 !important;
    background-color: transparent;
    z-index: 9999999 !important;
    font-size: 1em;
  }

  .rc-tooltip-inner {
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  }

  .rc-tooltip-arrow {
    display: none;
  }
`;
const TooltipOverlay = styled.div`
  ${media.sm`
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.1;
    height: 100vh;
    width: 100vw;
    background: #000;
    z-index: ${zIndex.HEADER + 1};
  `}
`;

const OpenTooltipButtonWrapper = styled.div<{ isBlock?: boolean }>`
  display: ${({ isBlock }) => (isBlock ? `block` : `inline-block`)};
`;

export interface Props {
  overlay?: ((args: { closeTooltip: () => void }) => JSX.Element) | JSX.Element;
  align?: {
    offset: number[];
    targetOffset?: number[];
  };
  placement?: string;
  destroyTooltipOnHide?: boolean;
  shouldCloseOnClick?: boolean;
  shouldCloseOnScroll?: boolean;
  shouldCloseOnEscape?: boolean;
  shouldShowOverlay?: boolean;
  shouldUnlockScroll?: boolean;
  preventCloseRefs?: Array<React.RefObject<HTMLDivElement>>;
  preventCloseSelector?: string;
  handleShowOverlay?(isOpen: boolean): void;
  isBlock?: boolean;
  overlayClassName?: string;
  shouldShowTooltip?: boolean;
  children: JSX.Element;
  getTooltipContainer?: () => Element | null;
  disabled?: boolean;
}

// Docs for rc-tooltip https://github.com/react-component/tooltip
const TooltipLarge = (props: Props) => {
  const {
    shouldCloseOnClick,
    shouldCloseOnScroll,
    shouldCloseOnEscape,
    shouldShowTooltip = true,
    handleShowOverlay,
    shouldUnlockScroll,
    getTooltipContainer,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const divNode = useRef<HTMLDivElement>(null);
  const spanNode = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();
  const header = document.querySelector('header');

  useEffect(() => {
    document.addEventListener(
      'click',
      handleClickOutside,
      not(shouldCloseOnClick)
    );
    if (shouldCloseOnScroll) {
      document.addEventListener('scroll', closeTooltip, true);
    }
    if (shouldCloseOnEscape) {
      document.addEventListener('keydown', closeTooltip);
    }
    return () => {
      document.removeEventListener(
        'click',
        handleClickOutside,
        not(shouldCloseOnClick)
      );
      document.removeEventListener('scroll', closeTooltip, true);
      document.removeEventListener('keydown', closeTooltip);
    };
  }, []);

  useEffect(() => {
    if (
      header &&
      props.overlayClassName === SORTING_FILTERS_TOOLTIP_CLASS_NAME
    ) {
      header.style.zIndex = `${
        isOpen && isMobile ? zIndex.CONTENT_HEADER_WRAPPER : zIndex.HEADER
      }`;
    }
  }, [isMobile, isOpen]);

  if (props.disabled) {
    return props.children;
  }

  const handleClickOutside: EventListenerOrEventListenerObject = (
    event: Event
  ) => {
    const spanContainer = spanNode.current;
    const el = event.target as Node;

    if (spanContainer && el instanceof Node && spanContainer.contains(el)) {
      handleShowOverlay?.(not(isOpen));
      setIsOpen(prevState => not(prevState));
    } else if (shouldCloseTooltip(el)) {
      handleShowOverlay?.(false);
      setIsOpen(false);
    }
  };

  const shouldCloseTooltip = (el: Node) => {
    const divContainer = divNode.current;
    const { shouldCloseOnClick, preventCloseRefs, preventCloseSelector } =
      props;
    const elFromSelector = preventCloseSelector
      ? document.querySelector(preventCloseSelector)
      : null;

    const preventCloseContainers = preventCloseRefs
      ? preventCloseRefs.map(ref => ref.current).filter(node => Boolean(node))
      : null;

    const isRefContains =
      not(preventCloseContainers) ||
      not(preventCloseContainers.some(container => container.contains(el)));
    const isSelectorContains = elFromSelector && elFromSelector.contains(el);
    const isDivContains = divContainer && not(divContainer.contains(el));

    if (isSelectorContains) {
      return false;
    }

    return shouldCloseOnClick ? isRefContains : isDivContains;
  };

  const closeTooltip: EventListenerOrEventListenerObject = (event: Event) => {
    if (event.type === 'keydown') {
      if ((event as KeyboardEvent).key === 'Escape') {
        setIsOpen(false);
        handleShowOverlay?.(false);
      }
    } else {
      const divContainer = divNode.current;
      const el = event.target;
      if (
        divContainer &&
        el instanceof Node &&
        not(divContainer.contains(el))
      ) {
        setIsOpen(false);
        handleShowOverlay?.(false);
      }
    }
  };

  const isActiveScrollLock =
    isOpen && isMobile && not(shouldCloseOnScroll) && not(shouldUnlockScroll);

  return (
    <TooltipWrapper>
      <Tooltip
        visible={shouldShowTooltip && isOpen}
        onPopupAlign={node => {
          const el = node.querySelector('.rc-tooltip-inner');
          el.setAttribute('role', 'none');
        }}
        {...rest}
        overlay={
          <div ref={divNode}>
            <ScrollLock isActive={isActiveScrollLock}>
              {typeof props.overlay === 'function'
                ? props.overlay({ closeTooltip: () => setIsOpen(false) })
                : props.overlay}
            </ScrollLock>
          </div>
        }
        getTooltipContainer={
          ((el: any) => getTooltipContainer?.() || el.parentNode) as any
        }
      >
        <OpenTooltipButtonWrapper isBlock={props.isBlock} ref={spanNode}>
          {props.children}
        </OpenTooltipButtonWrapper>
      </Tooltip>
      {isOpen && props.shouldShowOverlay && isMobile && <TooltipOverlay />}
    </TooltipWrapper>
  );
};

export default TooltipLarge;
