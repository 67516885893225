import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import Button from 'shared/components/Button';
import { media } from 'styled-system/responsive';

const ResetChangesButton = styled(Button)`
  margin-left: 0;
  margin-right: 8px;
  padding: 0 8px;

  ${media.sm(css<any>`
    margin-right: inherit;
    display: inline-flex;
  `)}
`;

const SaveAsNewButton = styled(Button)`
  margin-left: 0;
  margin-right: 8px;
  padding: 0 8px;

  ${media.sm(css<any>`
    margin-left: inherit;
    margin-right: inherit;
    width: inherit;
    padding: inherit;
  `)}
`;

const UpdateButton = styled(Button)`
  margin-left: 0;
  margin-right: 16px;
  padding: 0 8px;

  ${media.sm(css<any>`
    margin-left: 8px;
    margin-right: 0;
    width: inherit;
    padding: inherit;
  `)}
`;

const ResetSearchButton = styled(Button)`
  display: block;
  padding: 0 8px;
`;

const SaveSearchButton = styled(Button)`
  display: block;
  padding: 0 8px;
`;

const SaveSearchBtnWrapper = styled.div<{ btnDisabled: boolean }>`
  display: inline-block;

  ${({ btnDisabled }) =>
    btnDisabled &&
    css`
      cursor: not-allowed;
      & > button {
        pointer-events: none;
      }
    `}
`;

const Wrapper = styled.div`
  display: flex;

  ${media.sm(css<any>`
    margin-left: auto;
    display: inline-flex;

    button:last-child {
      margin-right: 0;
    }
  `)}
`;

const CollaboratorsLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blue};
  &:hover {
    text-decoration: underline;
  }
`;

const SavedSearchCtaButtonsStyled = {
  ResetChangesButton,
  ResetSearchButton,
  SaveAsNewButton,
  SaveSearchButton,
  SaveSearchBtnWrapper,
  Wrapper,
  UpdateButton,
  CollaboratorsLink,
};

export default SavedSearchCtaButtonsStyled;
