import { default as currentConfig, globalConfigs } from 'shared/config';
import { ProductName } from 'shared/config/configConstants';

let config = currentConfig;

export function mockGlobalConfig(configType: ProductName) {
  config = globalConfigs[configType];
}

export function resetGlobalConfig() {
  config = currentConfig;
}

export function getConfig() {
  return config;
}

export default config;
