import queryString from 'query-string';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { Agent } from 'agents/agentsTypes';
import { fetchCollection } from 'collections/collectionsActions';
import {
  getAgentMode,
  getContact,
  getContactUsername,
} from 'contacts/contactsSelectors';
import { ContactState } from 'contacts/contactsTypes';
import { TypesOfInquiryHistory } from 'history/inquiryHistory/inquiryHistoryTypes';
import {
  selectCurrencyDownPayment,
  selectCurrencyHomeownersInsurance,
  selectCurrencyPropertyTaxes,
  selectDownPayment,
  selectHomeownersInsurance,
  selectPropertyTaxes,
} from 'listingDetails/components/PaymentCalculator/paymentCalculatorSelectors';
import {
  AddOpenHouseToCalendarRequestAction,
  AskQuestionAction,
  calculatorInitListingAction,
  CalculatorInitListingAction,
  calculatorValuesChange,
  CalculatorValuesChangeAction,
  ChangeListingDetailsPageAction,
  CommuteTimesActionType,
  dismissListing,
  DismissListingAction,
  fetchDistanceFail,
  FetchDistanceRequestAction,
  fetchDistanceSuccess,
  FetchListingAction,
  fetchListingDetails,
  FetchListingDetailsAction,
  fetchListingDetailsSuccess,
  fetchListingFail,
  fetchListingNearbyFail,
  fetchListingNearbySuccess,
  fetchListingSuccess,
  ListingDetailsActionType,
  mergeListingDetails,
  PaymentCalculatorActionType,
  ScheduleTourAction,
  SetTravelModeAction,
  ShareListingAction,
  toggleDismissListing,
  updateListingItem,
} from 'listingDetails/listingDetailsActions';
import {
  DOWNPAYMENT,
  INTEREST_RATE,
} from 'listingDetails/listingDetailsConstants';
import {
  parseListingDetailsUrl,
  prepareListingDetailsData,
} from 'listingDetails/listingDetailsHelpers';
import { ListingDetailsService } from 'listingDetails/ListingDetailsService';
import {
  ListingDetailsPaymentPeriod,
  ListingWithDetails,
  PaymentCalculatorKey,
  PaymentCalculatorState,
} from 'listingDetails/listingDetailsTypes';
import {
  changeListingsPage,
  fetchListings,
  ListingsActionType,
} from 'listings/listingsActions';
import { fetchImages } from 'listings/listingsSagas';
import { getCollectionID, getItems } from 'listings/listingsSelectors';
import { TypesOfMap } from 'map/mapConstants';
import { getMapBoundaries } from 'map/mapSelectors';
import { RootState } from 'rootReducer';
import { GTM_TOASTERS_IDS, MONTH_IN_YEAR } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { mapServerValidationErrors } from 'shared/helpers/errorHelpers';
import {
  formatDistanceMatrixDurationTextForUS,
  priceToNumber,
} from 'shared/helpers/formatters';
import { showErrorToast, showSuccessToast } from 'shared/helpers/notifications';
import { safeSelect } from 'shared/helpers/saga';
import { isNotEmpty } from 'shared/helpers/validators';
import { getAgentByNameOrSfId } from 'shared/sagas/getAgentByNameOrSfId';
import { getProfileData } from 'shared/selectors/profileSelector';
import { GoogleApiService } from 'shared/services/GoogleApiService';
import history from 'shared/services/history';
import {
  FromAwaited,
  ReturnPromise,
  UnpackReturnedPromise,
} from 'shared/types';
import { getInquiryTrackingInfo } from 'user/userHelpers';
import { waitForProfile } from 'user/userSagas';
import { getIsLogged, isAuthorised } from 'user/userSelectors';
import { UserService } from 'user/UserService';
import { FavoritePlace, UserProfile } from 'user/userTypes';

export function* fetchListingSaga(
  action: FetchListingAction | FetchListingDetailsAction
) {
  try {
    const query = queryString.parse(history.location.search);

    const username: string = yield safeSelect(getContactUsername);
    query.username = username;

    const parsedUrl = parseListingDetailsUrl(history.location.pathname);
    if (parsedUrl?.address) {
      query.address = parsedUrl.address;
    }

    type ListingRes = FromAwaited<
      ReturnType<typeof ListingDetailsService.fetchListing>
    >;

    let listing: ListingRes = yield call(
      ListingDetailsService.fetchListing,
      action.listingId,
      query
    );

    if (not(listing)) {
      throw new Error('Cannot fetch listing');
    }

    yield fork(fetchListingNearbySaga, { id: listing.listingid });

    listing = prepareListingDetailsData({
      collections: [],
      images: [],
      ...listing,
    });

    yield put(fetchListingSuccess(listing));

    const isLoggedIn: boolean = yield safeSelect(isAuthorised);
    if (isLoggedIn) {
      const contact: ContactState = yield safeSelect(getContact);
      const isAgentMode: boolean = yield safeSelect(getAgentMode);

      type CollectionsRes = FromAwaited<
        ReturnType<typeof ListingDetailsService.fetchAllCollections>
      >;

      const collectionsCall = call(
        ListingDetailsService.fetchAllCollections,
        listing.listingid,
        isAgentMode ? { user: contact.currentContactId } : {}
      );

      type DismissedRes = FromAwaited<
        ReturnType<typeof ListingDetailsService.fetchIsListingDismissed>
      >;

      const dismissedCall = call(
        ListingDetailsService.fetchIsListingDismissed,
        listing.listingid
      );

      type Res = { collections: CollectionsRes; dismissed: DismissedRes };

      const { collections, dismissed }: Res = yield all({
        collections: collectionsCall,
        dismissed: dismissedCall,
      });

      yield put(
        mergeListingDetails({
          collections,
          dismissed: dismissed.data.isListingDismissed,
        })
      );
    }

    yield put(
      calculatorInitListingAction({
        hoaDues: priceToNumber(listing.hoaDues || '0'),
        listprice: priceToNumber(listing.listprice),
        taxRate: Number(Number(listing.taxRate).toFixed(2)),
        hoaType: listing.hoaType,
        taxAnnualAmount: parseInt(listing.taxannualamount || '0'),
        downPayment:
          Number(localStorage.getItem(PaymentCalculatorKey.downPayment)) ||
          DOWNPAYMENT,
        interestRate:
          Number(localStorage.getItem(PaymentCalculatorKey.interestRate)) ||
          INTEREST_RATE,
        initial: {
          hoaDues: priceToNumber(listing.hoaDues || '0'),
          taxRate: Number(Number(listing.taxRate).toFixed(2)),
        },
      })
    );
  } catch (e) {
    yield put(fetchListingFail(e.message));
  }
}

export function* fetchListingNearbySaga(payload: { id: string }) {
  try {
    const username: string = yield select(getContactUsername);

    const result: UnpackReturnedPromise<
      typeof ListingDetailsService.fetchListingNearby
    > = yield call(ListingDetailsService.fetchListingNearby, payload.id, {
      username,
    });

    if (not(result)) {
      throw new Error(
        'No result from ListingDetailsService.fetchListingNearby'
      );
    }

    const forSaleNearby = result.Active;
    const soldNearby = result.Sold;

    type ImagesTuple = [
      ReturnPromise<typeof fetchImages>,
      ReturnPromise<typeof fetchImages>
    ];

    const [imagesActive, imagesSold]: ImagesTuple = yield all([
      call(fetchImages, {
        listings: forSaleNearby,
        user: username,
      }),
      call(fetchImages, {
        listings: soldNearby,
        user: username,
      }),
    ]);

    forSaleNearby.forEach(listing => {
      listing.images = imagesActive[listing.listingkey] || [];
    });

    soldNearby.forEach(listing => {
      listing.images = imagesSold[listing.listingkey] || [];
    });

    yield put(fetchListingNearbySuccess(forSaleNearby, soldNearby));
  } catch (e) {
    yield put(fetchListingNearbyFail(e.message));
  }
}

export function* askQuestionSaga(action: AskQuestionAction) {
  try {
    const { data, listingId, agentId, onSuccess } = action.body;

    const agent: Agent = yield call(getAgentByNameOrSfId);

    yield call<typeof UserService.inquiry>(UserService.inquiry, {
      ...getInquiryTrackingInfo(),
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      message: data.message,
      phone: data.phone,
      listingId,
      type: TypesOfInquiryHistory.ListingQuestion,
      agentId: agent?.username || agentId,
    });

    onSuccess();

    showSuccessToast(
      'Thanks for your message. Our Agent will contact you soon'
    );
  } catch (error) {
    if (error?.validationErrors) {
      action.body.onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast('Message to the agent sent Error');
    }
  }

  action.body.cb();
}

export function* scheduleTourSaga(action: ScheduleTourAction) {
  try {
    const { data, onSuccess, listingId, agentId } = action.body;
    const agent: Agent = yield call(getAgentByNameOrSfId);
    const type = action.videoTour
      ? TypesOfInquiryHistory.ScheduleAVideoTour
      : TypesOfInquiryHistory.ScheduleATour;

    yield call<typeof UserService.inquiry>(UserService.inquiry, {
      ...getInquiryTrackingInfo(),
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      message: data.message,
      phone: data.phone,
      tourDateTime: data.date || '',
      listingId,
      type,
      agentId: agent?.username || agentId,
    });

    onSuccess();

    showSuccessToast(
      'We will attempt to coordinate your request with the seller. Stay tuned for confirmation',
      {
        toastDomId: action.videoTour
          ? GTM_TOASTERS_IDS.SCHEDULE_VIDEO_TOUR
          : GTM_TOASTERS_IDS.SCHEDULE_TOUR,
      }
    );
  } catch (e) {
    if (e?.validationErrors) {
      action.body.onError(mapServerValidationErrors(e.validationErrors));
    } else {
      showErrorToast('Tour scheduled Error');
    }
  }

  action.body.cb();
}

export function* dismissListingSaga({
  payload: { listing, updateListings, mapType },
}: DismissListingAction): Saga {
  try {
    if (not(listing)) return;

    const collectionId: string = yield select(getCollectionID);
    const contactId: string = yield select(getContactUsername);

    yield call(
      ListingDetailsService.toggleDismissListing,
      listing.listingid,
      listing.dismissed
    );

    const listingName = listing.address;

    yield put(toggleDismissListing(listing.listingid, !listing.dismissed));

    if (!listing.dismissed) {
      const profile: UserProfile = yield select(getProfileData);
      const updatedCollection = listing.collections.filter(
        collection => collection.owner !== profile?.username
      );
      yield put(updateListingItem({ collections: updatedCollection }));
    }

    if (updateListings && mapType) {
      // when listingDetails opened over Collection page
      if (mapType === TypesOfMap.Collections && collectionId) {
        yield put(fetchCollection({ collectionId, contactId }));
      } else {
        const listingsBoundaries = yield select(
          getMapBoundaries,
          TypesOfMap.Listings
        );
        yield put(fetchListings(mapType, true, listingsBoundaries));
      }
    }

    if (listing.dismissed) {
      showSuccessToast(`"${listingName}" listing removed from Dismissed`);
    } else {
      const undoed: boolean = yield call(
        () =>
          new Promise(resolve =>
            showSuccessToast(`"${listingName}" listing dismissed`, {
              onClose: () => resolve(false),
            })
          )
      );

      if (undoed) {
        const undoListing: ListingWithDetails = {
          ...listing,
          dismissed: !listing.dismissed,
        };

        yield put(dismissListing({ listing: undoListing }));
      }
    }
  } catch (e) {
    showErrorToast(e.message);
  }
}

export function* initCalculatorSaga({
  calculatorData,
}: CalculatorInitListingAction) {
  try {
    // todo add request to get homeownersInsurance from bankrate
    // const homeownersInsurance = yield call(PaymentCalculatorService.fetchHomeownersInsurance);
    const calculator: PaymentCalculatorState = yield select(
      ({ listingDetails: { calculator } }: RootState) => calculator
    );

    const { Annually, Quarterly } = ListingDetailsPaymentPeriod;
    const { hoaType, hoaDues } = calculatorData;
    const hoaIndex =
      hoaType === Annually ? MONTH_IN_YEAR : hoaType === Quarterly ? 3 : 1;
    const hoa = Number(((hoaDues || 0) / hoaIndex).toFixed(2));

    yield put(
      calculatorValuesChange(
        {
          ...calculatorData,
          hoaDues: hoa,
          taxRate: selectPropertyTaxes(calculator),
          currencyPropertyTaxes: selectCurrencyPropertyTaxes(calculator),
          currencyHomeownersInsurance:
            selectCurrencyHomeownersInsurance(calculator),
          homeownersInsurance: selectHomeownersInsurance(calculator),
          downPayment: selectDownPayment(calculator),
          currencyDownPayment: selectCurrencyDownPayment(calculator),
          initial: {
            ...calculatorData.initial,
            hoaDues: Number(
              Number((calculatorData.initial?.hoaDues || 0) / hoaIndex).toFixed(
                2
              )
            ),
          },
        },
        PaymentCalculatorKey.init
      )
    );
  } catch (e) {
    showErrorToast('Something went wrong');
  }
}

export function* calculatorValuesChangeSaga({
  changedKey,
  calculatorData,
}: CalculatorValuesChangeAction) {
  try {
    const { downPayment }: PaymentCalculatorState = yield select(
      ({ listingDetails: { calculator } }: RootState) => calculator
    );

    if (changedKey === PaymentCalculatorKey.downPayment) {
      yield localStorage.setItem(
        PaymentCalculatorKey.downPayment,
        (calculatorData.downPayment || 0).toString()
      );
    }
    if (changedKey === PaymentCalculatorKey.currencyDownPayment) {
      yield localStorage.setItem(
        PaymentCalculatorKey.downPayment,
        downPayment.toString()
      );
    }
    if (changedKey === PaymentCalculatorKey.interestRate) {
      yield localStorage.setItem(
        PaymentCalculatorKey.interestRate,
        (calculatorData.interestRate || 0).toString()
      );
    }
  } catch (e) {
    showErrorToast('Something went wrong');
  }
}

export function* fetchDistanceSaga(
  action: FetchDistanceRequestAction | SetTravelModeAction
) {
  try {
    const {
      commuteTimes: { distances },
      item: { latitude, longitude },
    } = yield select(({ listingDetails }) => listingDetails);
    if (isNotEmpty(distances[action.travelMode])) {
      // already fetched
      return;
    }
    const placesCords = action.favoritePlaces.map((place: FavoritePlace) => ({
      lng: parseFloat(place.longitude),
      lat: parseFloat(place.latitude),
    }));
    const results: google.maps.DistanceMatrixResponseElement[] = yield call(
      GoogleApiService.getDistanceFromLatLng,
      {
        mode: action.travelMode,
        origins: [
          {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        ],
        destinations: placesCords,
        maps: action.maps,
      }
    );
    const modeDistances = results.map(result => ({
      distance: result.distance ? result.distance.text : '',
      duration: result.duration
        ? formatDistanceMatrixDurationTextForUS(result.duration.text)
        : '',
    }));

    yield put(fetchDistanceSuccess(modeDistances, action.travelMode));
  } catch (e) {
    yield put(fetchDistanceFail(e.message));
  }
}

export function* shareListingSaga(action: ShareListingAction) {
  try {
    const { listingId, receiverEmail, message } = action.body.data;

    yield call(
      ListingDetailsService.shareListing,
      listingId,
      receiverEmail,
      message
    );

    action.body.onSuccess();

    showSuccessToast('Email sent');
  } catch (e) {
    showErrorToast('Email sending Error');
  }

  action.body.cb();
}

export function* fetchListingDetailsSaga(action: FetchListingDetailsAction) {
  yield call(waitForProfile);

  yield call(fetchListingSaga, action);

  yield put(fetchListingDetailsSuccess());
}

export function* addOpenHouseToCalendarSaga(
  action: AddOpenHouseToCalendarRequestAction
): Saga {
  try {
    const isLogged = yield select(getIsLogged);

    if (isLogged) {
      yield call(
        ListingDetailsService.addOpenhouse,
        action.listingId,
        action.openhousekey
      );
    }
  } catch (error) {
    console.warn(error?.message);
  }
}

function* changeListingDetailsPageSaga({
  fetchingNextPage,
  page,
  onError,
}: ChangeListingDetailsPageAction): Saga {
  try {
    yield put(changeListingsPage(page));
    yield take([
      ListingsActionType.FETCH_LISTINGS_SUCCESS,
      ListingsActionType.FETCH_LISTINGS_FAIL,
    ]);

    const listings = yield select(getItems);

    const listingid = fetchingNextPage
      ? listings[0].listingid
      : listings[listings.length - 1].listingid;

    yield put(fetchListingDetails(listingid));
  } catch (error) {
    onError();
    console.warn(error?.message);
  }
}

const fetchListingSub = () => {
  return takeLatest(ListingDetailsActionType.FETCH_LISTING, fetchListingSaga);
};

const askQuestionSub = () => {
  return takeLatest(ListingDetailsActionType.ASK_QUESTION, askQuestionSaga);
};

const scheduleTourSub = () => {
  return takeLatest(ListingDetailsActionType.SCHEDULE_TOUR, scheduleTourSaga);
};

const dismissListingSub = () => {
  return takeLatest(
    ListingDetailsActionType.DISMISS_LISTING,
    dismissListingSaga
  );
};

const initCalculatorDataSub = () =>
  takeLatest(
    PaymentCalculatorActionType.CALCULATOR_INIT_LISTING_DATA,
    initCalculatorSaga
  );

const calculatorValuesChangeSub = () =>
  takeLatest(
    PaymentCalculatorActionType.CALCULATOR_VALUES_CHANGE,
    calculatorValuesChangeSaga
  );

const fetchDistanceSub = () =>
  takeLatest(
    [
      CommuteTimesActionType.FETCH_DISTANCE_REQUEST,
      CommuteTimesActionType.SET_TRAVEL_MODE,
    ],
    fetchDistanceSaga
  );

const fetchListingDetailsSub = () =>
  takeLatest(
    ListingDetailsActionType.FETCH_LISTING_DETAILS,
    fetchListingDetailsSaga
  );

const shareListingSub = () =>
  takeLatest(ListingDetailsActionType.SHARE_LISTING, shareListingSaga);

const changeListingDetailsPageSub = () =>
  takeLatest(
    ListingDetailsActionType.CHANGE_LISTINGS_DETAILS_PAGE,
    changeListingDetailsPageSaga
  );

const addOpenHouseToCalendarSub = () =>
  takeLatest(
    ListingDetailsActionType.ADD_OPEN_HOUSE_TO_CALENDAR,
    addOpenHouseToCalendarSaga
  );

export function* listingDetailsSagas() {
  yield all([
    changeListingDetailsPageSub(),
    fetchListingDetailsSub(),
    fetchListingSub(),
    dismissListingSub(),
    askQuestionSub(),
    scheduleTourSub(),
    initCalculatorDataSub(),
    calculatorValuesChangeSub(),
    fetchDistanceSub(),
    shareListingSub(),
    addOpenHouseToCalendarSub(),
  ]);
}
