import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Omit } from 'utility-types';

import { ListingsProps } from 'listings/Buy/Buy';
import LeaseAccessResolver from 'listings/LeaseAccessResolver';
import { getListingDetailsId } from 'listings/listingsHelpers';
import { TypesOfMap } from 'map/mapConstants';
import App from 'shared/components/App';
import AuthProtectedRoutes from 'shared/components/AuthProtectedRoutes';
import FullScreenLoader from 'shared/components/loaders/FullScreenLoader';
import ModalRouteSwitch from 'shared/components/ModalRouteSwitcher/ModalRouteSwitch';
import { useIsRouteModal } from 'shared/components/ModalRouteSwitcher/useIsRouteModal';
import PrePopulatedSignUpForm from 'shared/components/PrePopulatedSignUpForm';
import UserLocation from 'shared/components/UserLocation';
import { RoutePath } from 'shared/constants/routesConstants';
import { not } from 'shared/helpers/boolean';
import { loadable } from 'shared/utils/loadable';

export type ListingsOmitMapType = Omit<ListingsProps, 'mapType'>;

const Playground = loadable(() => {
  return import(
    /* webpackChunkName: "playground" */
    'shared/components/playground'
  );
});

export const AsyncRoutes = {
  AgentBio: loadable(
    () => import(/* webpackChunkName: "agent-bio" */ 'agents/AgentBio')
  ),
  AgentList: loadable(
    () => import(/* webpackChunkName: "agent-list" */ 'agents/AgentList')
  ),
  AgentRoutes: loadable(
    () => import(/* webpackChunkName: "agent-routes" */ 'agents/AgentRoutes')
  ),
  BlogRoutes: loadable(
    () => import(/* webpackChunkName: "blog-routes" */ 'strapi/pages/blog')
  ),
  CollectionListings: loadable(
    () =>
      import(
        /* webpackChunkName: "collection" */ 'collections/CollectionListings'
      )
  ),
  Careers: loadable(
    () => import(/* webpackChunkName: "careers" */ 'butter-cms/pages/careers')
  ),
  Company: loadable(
    () => import(/* webpackChunkName: "company" */ 'butter-cms/pages/company')
  ),
  DmcaNotice: loadable(
    () =>
      import(
        /* webpackChunkName: "dmca-notice" */
        'butter-cms/pages/dmca-notice'
      )
  ),
  EmailSubscription: loadable(
    () =>
      import(
        /* webpackChunkName: "email-subscription" */
        'shared/components/EmailSubscription'
      )
  ),
  EmailSettings: loadable(
    () =>
      import(
        /* webpackChunkName: "email-settings" */
        'user/EmailSettings/components/EmailSettingsRedirect'
      )
  ),
  EmailSettingsNotLoggedUser: loadable(
    () =>
      import(
        /* webpackChunkName: "email-settings-not-logged-user" */
        'user/EmailSettings/components/EmailSettingsNotLoggedUser'
      )
  ),
  Error403: loadable(
    () =>
      import(/* webpackChunkName: "error-403" */ 'shared/components/Error403')
  ),
  Error404: loadable(
    () =>
      import(/* webpackChunkName: "error-404" */ 'shared/components/Error404')
  ),
  Home: loadable(
    () => import(/* webpackChunkName: "home" */ 'butter-cms/pages/home')
  ),
  ListingDetails: loadable(
    () => import(/* webpackChunkName: "listing-details" */ 'listingDetails')
  ),
  Listings: loadable(
    () => import(/* webpackChunkName: "buy" */ 'listings/Buy')
  ),
  NearBySearch: loadable(
    () =>
      import(/* webpackChunkName: "nearby-search" */ 'listings/NearBySearch')
  ),
  Offices: loadable(
    () => import(/* webpackChunkName: "offices" */ 'butter-cms/pages/offices')
  ),
  PLinksResolver: loadable(
    () =>
      import(
        /* webpackChunkName: "p-link-resolver" */ 'permissions/PLinksResolver'
      )
  ),
  PrivacyPolicy: loadable(
    () =>
      import(
        /* webpackChunkName: "privacy" */
        'butter-cms/pages/privacy-policy'
      )
  ),
  ProfileMenu: loadable(
    () => import(/* webpackChunkName: "profile-menu" */ 'user/ProfileMenu')
  ),
  SavedSearchListings: loadable(
    () =>
      import(
        /* webpackChunkName: "saved-search-listings" */
        'saved-search/SavedSearchListings'
      )
  ),
  Sell: loadable(
    () =>
      import(
        /* webpackChunkName: "sell" */
        'butter-cms/pages/sell'
      )
  ),
  TermsOfUse: loadable(
    () =>
      import(
        /* webpackChunkName: "terms-of-use" */
        'butter-cms/pages/terms-of-use'
      )
  ),
  TestimonialVideoPage: loadable(
    () =>
      import(
        /* webpackChunkName: "testimonial-video-page" */
        'strapi/pages/testimonial'
      )
  ),
  TLinksResolver: loadable(
    () =>
      import(
        /* webpackChunkName: "t-link-resolver" */ 'permissions/TLinksResolver'
      )
  ),
  ViewedSearches: loadable(
    () =>
      import(
        /* webpackChunkName: "viewed-searches" */ 'saved-search/ViewedSearchList'
      )
  ),
  ViewedListings: loadable(
    () =>
      import(
        /* webpackChunkName: "viewed-listings" */
        'listings/ViewedListings'
      )
  ),
  AccessibilityStatement: loadable(
    () =>
      import(
        /* webpackChunkName: "accessibility-statement" */
        'butter-cms/pages/accessibility-statement'
      )
  ),
  Homeward: loadable(
    () =>
      import(
        /* webpackChunkName: "homeward" */
        'butter-cms/pages/homeward'
      )
  ),
  InfoPagesRoutes: loadable(
    () =>
      import(
        /* webpackChunkName: "info-pages-routes" */
        'butter-cms/pages/routes'
      )
  ),
  Iabs404: loadable(
    () => import(/* webpackChunkName: "iabs-404" */ 'iabs/Iabs')
  ),
};

const useMatches = () => {
  return {
    listingComments: useRouteMatch(
      RoutePath.LISTING_DETAILS + RoutePath.LISTING_COMMENT
    ),
    listingAddToCollection: useRouteMatch(
      RoutePath.LISTING_DETAILS + RoutePath.LISTING_ADD_TO_COLLECTION
    ),
    requestSpecialist: useRouteMatch(
      RoutePath.AGENTS + RoutePath.REQUEST_SPECIALIST
    ),
    setPassword: useRouteMatch(RoutePath.SET_PASSWORD),
    resetPassword: useRouteMatch(RoutePath.RESET_PASSWORD),
    relocationGuide: useRouteMatch(RoutePath.RELOCATION_GUIDE),
    leasePage: useRouteMatch(RoutePath.LEASE),
  };
};

const Routes = (props: RouteComponentProps) => {
  const matches = useMatches();
  const { isModal } = useIsRouteModal();

  const shouldListingDetailsBeExact =
    (not(matches.listingComments) && not(matches.listingAddToCollection)) ||
    isModal;

  const shouldHomeBeExact =
    not(matches.resetPassword) &&
    not(matches.setPassword) &&
    not(matches.relocationGuide);

  return (
    <App>
      <React.Suspense fallback={<FullScreenLoader />}>
        <UserLocation>
          <LastLocationProvider>
            <Switch>
              {process.env.NODE_ENV === 'development' && (
                <Route exact path="/dev-playground">
                  <Playground />
                </Route>
              )}
              <Route path={RoutePath.INFO_PAGE}>
                <AsyncRoutes.InfoPagesRoutes />
              </Route>
              <Route
                exact
                path={RoutePath.EMAIL_SUBSCRIPTION}
                component={AsyncRoutes.EmailSubscription}
              />
              <Route
                exact
                path={RoutePath.EMAIL_SETTINGS}
                component={AsyncRoutes.EmailSettings}
              />
              <Route
                exact
                path={RoutePath.NOT_LOGGED_USER_EMAIL_SETTINGS}
                component={AsyncRoutes.EmailSettingsNotLoggedUser}
              />
              <Route exact={shouldHomeBeExact} path={RoutePath.HOME}>
                <PrePopulatedSignUpForm>
                  <AsyncRoutes.Home />
                </PrePopulatedSignUpForm>
              </Route>
              <Route exact path={RoutePath.SELL}>
                <AsyncRoutes.Sell />
              </Route>
              <Route exact path={RoutePath.ACCESSIBILITY_STATEMENT}>
                <AsyncRoutes.AccessibilityStatement />
              </Route>
              <Route exact path={RoutePath.DMCA_NOTICE}>
                <AsyncRoutes.DmcaNotice />
              </Route>
              <Route exact path={RoutePath.PRIVACY_POLICY}>
                <AsyncRoutes.PrivacyPolicy />
              </Route>
              <Route exact path={RoutePath.TERMS_OF_USE}>
                <AsyncRoutes.TermsOfUse />
              </Route>
              <Route
                exact
                path={RoutePath.P_LINK}
                component={AsyncRoutes.PLinksResolver}
              />
              <Route
                path={RoutePath.T_LINK}
                component={AsyncRoutes.TLinksResolver}
              />
              <Route
                exact
                path={RoutePath.NEAR_BY_SEARCH}
                component={AsyncRoutes.NearBySearch}
              />
              <Route exact path={RoutePath.BUY_BEFORE_YOU_SELL}>
                <AsyncRoutes.Homeward />
              </Route>
              <ModalRouteSwitch>
                {!matches.requestSpecialist && (
                  <Route
                    path={RoutePath.AGENT_BIO}
                    render={props => (
                      <PrePopulatedSignUpForm>
                        <AsyncRoutes.AgentBio
                          mapType={TypesOfMap.BioPage}
                          {...props}
                        />
                      </PrePopulatedSignUpForm>
                    )}
                  />
                )}
                <Route
                  path={RoutePath.AGENTS}
                  component={AsyncRoutes.AgentList}
                />
                <Route path={RoutePath.TESTIMONIAL_VIDEO_PAGE}>
                  <AsyncRoutes.TestimonialVideoPage />
                </Route>
                <Route path={RoutePath.CAREERS}>
                  <AsyncRoutes.Careers />
                </Route>
                <Route path={RoutePath.BLOG}>
                  <AsyncRoutes.BlogRoutes />
                </Route>
                <Route path={RoutePath.COMPANY}>
                  <AsyncRoutes.Company />
                </Route>
                <Route path={RoutePath.OFFICES}>
                  <AsyncRoutes.Offices />
                </Route>
                <Route
                  key={RoutePath.LEASE}
                  path={RoutePath.LEASE}
                  render={props => (
                    <LeaseAccessResolver>
                      <AsyncRoutes.Listings
                        mapType={TypesOfMap.Lease}
                        persistMap
                        {...props}
                      />
                    </LeaseAccessResolver>
                  )}
                />
                <Route
                  key={RoutePath.LISTINGS}
                  path={RoutePath.LISTINGS}
                  render={props => (
                    <PrePopulatedSignUpForm>
                      <AsyncRoutes.Listings
                        mapType={TypesOfMap.Listings}
                        persistMap
                        {...props}
                      />
                    </PrePopulatedSignUpForm>
                  )}
                />

                <Route
                  exact
                  path={RoutePath.FORBIDDEN}
                  component={AsyncRoutes.Error403}
                />

                <Route
                  exact={shouldListingDetailsBeExact}
                  path={RoutePath.LISTING_DETAILS}
                  component={ListingDetailsRouteComponent}
                />

                <Route
                  exact
                  key={RoutePath.IABS}
                  path={RoutePath.IABS}
                  component={AsyncRoutes.Iabs404}
                />

                <AuthProtectedRoutes>
                  <Route
                    exact
                    key={RoutePath.SAVED_SEARCH}
                    path={RoutePath.SAVED_SEARCH}
                    render={(props: ListingsOmitMapType) => (
                      <PrePopulatedSignUpForm>
                        <AsyncRoutes.SavedSearchListings
                          mapType={TypesOfMap.SavedSearches}
                          {...props}
                          persistMap
                        />
                      </PrePopulatedSignUpForm>
                    )}
                  />

                  <Route
                    path={RoutePath.COLLECTION}
                    render={props => (
                      <PrePopulatedSignUpForm>
                        <AsyncRoutes.CollectionListings {...props} />
                      </PrePopulatedSignUpForm>
                    )}
                  />

                  <Route
                    path={RoutePath.MENU}
                    component={AsyncRoutes.ProfileMenu}
                  />
                  <Route
                    path={RoutePath.AGENT}
                    component={AsyncRoutes.AgentRoutes}
                  />
                  <Route
                    exact
                    path={RoutePath.JOIN_WITH_ADMIN_INVITE}
                    component={AsyncRoutes.Home}
                  />
                  <Route
                    exact
                    path={RoutePath.JOIN_SAVED_SEARCH}
                    component={AsyncRoutes.Home}
                  />
                  <Route
                    exact
                    path={RoutePath.JOIN_COLLECTION}
                    component={AsyncRoutes.Home}
                  />
                  <Route
                    component={AsyncRoutes.ViewedSearches}
                    path={RoutePath.VIEWED_SEARCHES}
                  />
                  <Route
                    path={RoutePath.VIEWED_LISTINGS}
                    component={AsyncRoutes.ViewedListings}
                  />
                  <Route component={AsyncRoutes.Error404} />
                </AuthProtectedRoutes>
              </ModalRouteSwitch>
            </Switch>
          </LastLocationProvider>
        </UserLocation>
      </React.Suspense>
    </App>
  );
};

function ListingDetailsRouteComponent(props: RouteComponentProps) {
  const match = useRouteMatch();
  const listingId = getListingDetailsId(match.url);
  return listingId ? (
    <PrePopulatedSignUpForm>
      <AsyncRoutes.ListingDetails
        {...props}
        listingId={listingId}
        closeModal={() => {}}
      />
    </PrePopulatedSignUpForm>
  ) : (
    <AsyncRoutes.Error404 />
  );
}

export default withRouter(Routes);
