import { ReactText } from 'react';
import { Omit } from 'utility-types';

import { Agent } from 'agents/agentsTypes';
import { CollectionBase } from 'collections/collectionsTypes';
import { MlsStatus } from 'filters/filtersContants';
import { OpenHouseBarDate } from 'listingDetails/components/OpenHouseBar/OpenHouseBarTypes';
import { ShareListingAction } from 'listingDetails/listingDetailsActions';
import {
  Listing,
  ListingPolygons,
  PropertyFilterSubType,
  PropertyType,
} from 'listings/listingsTypes';
import { ListingSources } from 'shared/config/configConstants';
import { DiffImgSizes, PrimaryLabel, SecondaryLabel } from 'shared/types';
import { FormErrors } from 'shared/types/errorTypes';
import { FavoritePlace } from 'user/userTypes';

export enum PaymentCalculatorKey {
  unknown = '',
  init = 'init',
  listprice = 'listprice',
  hoaDues = 'hoaDues',
  hoaType = 'hoaType',
  downPayment = 'downPayment',
  taxRate = 'taxRate',
  homeownersInsurance = 'homeownersInsurance',
  interestRate = 'interestRate',
  currencyDownPayment = 'currencyDownPayment',
  currencyPropertyTaxes = 'currencyPropertyTaxes',
  currencyHomeownersInsurance = 'currencyHomeownersInsurance',
  currencyPrincipalInterest = 'currencyPrincipalInterest',
  monthlyMortgagePayment = 'monthlyMortgagePayment',
  taxAnnualAmount = 'taxAnnualAmount',
}

export enum ListingLocationType {
  Address = 'address',
  County = 'county',
  City = 'city',
  ZipCode = 'zipCode',
  Neighborhood = 'neighborhood',
  SchoolDistrict = 'schoolDistrict',
  MlsArea = 'mlsArea',
}

export enum FeaturesAndFactsSectionValueType {
  bedrooms = 'bedrooms',
  bathrooms = 'bathrooms',
  sqft = 'sqft',
  acres = 'acres',
  propertyType = 'propertyType',
  heating = 'heating',
  parking = 'parking',
  yearBuilt = 'yearBuilt',
  stories = 'stories',
  cooling = 'cooling',
  daysOnMarket = 'daysOnMarket',
  garages = 'garages',
}

export interface PaymentCalculatorInitial {
  [PaymentCalculatorKey.hoaDues]?: number;
  [PaymentCalculatorKey.taxRate]?: number;
  [PaymentCalculatorKey.homeownersInsurance]?: number;
  [PaymentCalculatorKey.interestRate]?: number;
}

export interface PaymentCalculatorData {
  [PaymentCalculatorKey.listprice]: number;
  [PaymentCalculatorKey.hoaDues]: number;
  [PaymentCalculatorKey.downPayment]: number;
  [PaymentCalculatorKey.taxRate]: number;
  [PaymentCalculatorKey.homeownersInsurance]: number;
  [PaymentCalculatorKey.interestRate]: number;
  [PaymentCalculatorKey.currencyDownPayment]: number;
  [PaymentCalculatorKey.currencyPropertyTaxes]: number;
  [PaymentCalculatorKey.currencyHomeownersInsurance]: number;
  [PaymentCalculatorKey.currencyPrincipalInterest]: number;
  [PaymentCalculatorKey.monthlyMortgagePayment]: number;
  [PaymentCalculatorKey.taxAnnualAmount]: number;
  initial: PaymentCalculatorInitial;
}

export interface PaymentCalculatorState extends PaymentCalculatorData {
  [PaymentCalculatorKey.hoaType]: string;
  changedArea: PaymentCalculatorKey;
}

export interface SubSectionValue<T> {
  type: T;
  title: string;
  desc: string;
  singleLine?: boolean;
}

export interface SchoolAddress {
  deliveryLine: string;
  city: string;
  state: string;
  zip: string;
  street: string;
}

export interface SchoolDistrict {
  id: string;
  name: string;
}

export interface School {
  address: SchoolAddress;
  admission: string;
  charter: boolean;
  desc: string;
  gradeHigh: string;
  gradeLow: string;
  gsRating: number;
  gsSchoolUrl: string;
  id: string;
  label?: string;
  latitude: number;
  level: string;
  longitude: number;
  name?: string;
  rating: string;
  schoolDistrict: SchoolDistrict;
  schoolType: string;
  studentTeacherRatio: string;
  title: string;
  type: string;
  url: string;
}

export enum SchoolType {
  Elementary = 'Elementary',
  Middle = 'Middle',
  High = 'High',
}

export type SchoolsGroup = { [key in SchoolType]: School[] };

export interface PropertyHistory {
  id?: number;
  date: string;
  property_type?: PropertyType;
  status: string;
  days_diff?: number;
  list_price: number | string;
  list_price_diff?: number;
  action: string;
  listing_id?: string;
  listing?: Listing;
}

export interface OpenHouse {
  openhousedate?: string;
  openhousestarttime: string;
  openhouseendtime?: string;
}

export interface Distance {
  duration: string;
  distance: string;
}

export interface Distances {
  [key: string]: Distance[];
}

export enum TravelMode {
  DRIVING = 'DRIVING',
  TRANSIT = 'TRANSIT',
  BICYCLING = 'BICYCLING',
  WALKING = 'WALKING',
}

export enum BuyBeforeYouSellPosition {
  SIDEBAR = 'SIDEBAR',
  UNDER_PAYMENT_CALCULATOR = 'UNDER_PAYMENT_CALCULATOR',
}

export enum ListingDetailsPaymentPeriod {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually',
}

export interface CommuteTimes {
  activePlace: FavoritePlace | null;
  travelMode: TravelMode;
  distances: Distances;
  error: string;
  loading: boolean;
  streetViewAvailable: boolean;
}

export interface ListingNearby {
  Active: Listing[];
  Sold: Listing[];
}

export interface ListingDetailsSubSection<T> {
  label: string;
  values: Array<SubSectionValue<T>>;
}

export interface ListingDetailsSection<T> {
  label: string;
  subSections: Array<ListingDetailsSubSection<T>>;
}

export interface SchoolsSection {
  label: string;
  subSections: SchoolsGroup[];
}

export type ListingCollectionList = Omit<
  CollectionBase,
  'agentMode' | 'notifications' | 'images' | 'collaborators' | 'loading'
> & {
  owner: string;
};

export enum PropertyDetailsLabels {
  Location = 'Location',
  SizeAndRestrictions = 'Size and Restrictions',
  Improvements = 'Improvements',
  Features = 'Features',
  Utilities = 'Utilities',
}

export interface PrimaryLabelData {
  primaryLabel: PrimaryLabel;
  latitude: string;
  longitude: string;
  openhouse: OpenHouse[] | OpenHouseBarDate[];
}

export type ListingDetailsPropertyType = PropertyFilterSubType | PropertyType;

export interface ListingWithDetails {
  // TODO: Create new interface ListingWithDetailsWithImages
  // TODO: Create new interface ListingWithDetailsWithLabels
  abor_accessinstruction?: string;
  accesscode?: string;
  address: string;
  agent?: Agent | Partial<Agent>;
  bathroomstotalinteger?: ReactText;
  bedroomstotal?: ReactText;
  livingarea?: ReactText;
  buildingarea?: ReactText;
  buildingInfo?: Array<ListingDetailsSubSection<string>>;
  checkedAt?: string;
  city: string;
  closedate?: string;
  closeprice: string;
  colistagentdirectphone?: string;
  colistagentemail?: string;
  colistagentfullname?: string;
  collections: ListingCollectionList[];
  dismissed: boolean;
  expirationdate?: string;
  features?: Array<SubSectionValue<FeaturesAndFactsSectionValueType>>;
  financial?: Array<ListingDetailsSubSection<string>>;
  fulladdress: string;
  hoa?: Array<ListingDetailsSubSection<string>>;
  hoaDues?: string;
  hoaType?: string;
  images: ListingImage[];
  interiorFeatures?: Array<ListingDetailsSubSection<string>>;
  isexclusive?: boolean;
  isFavorite: boolean;
  isSyncing: boolean;
  latitude: string;
  listings: Array<Omit<ListingWithDetails, 'listings'>>;
  listagentdirectphone?: string;
  listagentemail?: string;
  listagentfullname?: string;
  listingid: string;
  listingkey: string;
  listofficemlsid?: string;
  listofficename?: string;
  listofficephone?: string;
  listprice: string;
  lockboxlocation?: string;
  lockboxtype?: string;
  longitude: string;
  mlsstatus: MlsStatus;
  mlsTargetDate?: string;
  occupantphone?: string;
  occupanttype?: string;
  openhouse: OpenHouseBarDate[];
  ownername?: string;
  ownerphone?: string;
  parcelnumber: string;
  polygons?: ListingPolygons;
  postalcode: string;
  primaryLabel: PrimaryLabel | null;
  privateremarks?: string;
  primarylistingid: string;
  propertyDetails?: Array<ListingDetailsSubSection<string>>;
  propertyfiltertype?: PropertyFilterSubType;
  propertyHistory?: PropertyHistory[][];
  propertyTaxes?: Array<ListingDetailsSubSection<string>>;
  propertyType?: ListingDetailsPropertyType;
  propertysubtype?: string;
  publicDate?: string;
  publicremarks: string;
  rentalInfo?: Array<ListingDetailsSubSection<string>>;
  schools?: SchoolsGroup;
  secondaryLabel?: SecondaryLabel;
  showingcontactname?: string;
  showingcontactphone?: string;
  showingcontacttype?: string;
  showinginstructions?: string;
  showingrequirements?: string;
  showingservicephone?: string;
  signonpropertyyn?: string;
  source?: ListingSources;
  sourcelistingid?: string;
  stateorprovince: string;
  streetdirprefix: string | null;
  streetdirsuffix: string | null;
  streetname: string;
  streetnumber: string;
  streetsuffix: string;
  subdivisionname: string;
  taxannualamount?: string;
  taxRate?: string;
  unitnumber?: string;
  updatedAt?: string;
  urlAddress: string;
  virtualtoururlunbranded?: string;
  usageStatistics: ListingStatistic;
  attributioncontact?: string;
}

export interface ListingDetailsState {
  loading: boolean;
  error: string;
  calculator: PaymentCalculatorState;
  forSaleNearby?: Listing[];
  soldNearby?: Listing[];
  commuteTimes: CommuteTimes;
  item: ListingWithDetails;
}

export interface ScheduleTourData {
  email: string;
  phone: string;
  firstname: string;
  lastname: string;
  message: string;
  date?: string;
}

export interface ScheduleTourBody {
  data: ScheduleTourData;
  listingId: string;
  agentId?: string;
  onError: (errors: FormErrors) => void;
  onSuccess: () => void;
  cb: () => void;
}

export interface ShareBody {
  receiverEmail: string;
  message: string;
}

export interface ShareListingBody {
  data: { listingId: string; receiverEmail: string; message: string };
  onSuccess: () => void;
  cb: () => void;
}

export interface AskQuestionData {
  email: string;
  phone: string;
  firstname: string;
  lastname: string;
  message: string;
}

export interface AskQuestionBody {
  data: AskQuestionData;
  listingId?: string;
  agentId?: string;
  onError: (errors: FormErrors) => void;
  onSuccess: () => void;
  cb: () => void;
}

export interface ListingStatistic {
  savedToCollectionsCount: string;
  dismissedCount: string;
  listingId: string;
  questionsCount: string;
  scheduledToursCount: string;
  viewsCount: string;
}

export interface ListingDismiss {
  owner: string;
  listing: {
    listingid: string;
  };
}

// Always status.OK
export type ListingRetain = number;

export interface ListingAskQuestion {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  message?: string;
}

export interface ListingScheduleTour {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  message?: string;
  date?: string;
}

// Always status.OK
export type ListingShare = number;

export interface ListingImage {
  diffimgsizes: DiffImgSizes;
  mediaurl: string;
  width?: number;
  height?: number;
  description?: string;
  shortdescription?: string;
  mediaobjectid: string;
}

export enum ActionButtonsLabel {
  DISMISSED = 'Dismissed',
  DISMISS = 'Dismiss',
  FAVORITE = 'Favorite',
  COMMENT = 'Comment',
  SHARE = 'Share',
  TOUR_IN_PERSON = 'Tour in Person',
  VIDEO_CHAT_TOUR = 'Video Chat Tour',
  ASK_A_QUESTION = 'Ask a Question',
  RECOMMEND_THIS_LISTING = 'Recommend this Listing',
  REQUEST_PHOTOS_AND_SOLD_PRICE = 'Request Photos and Sold Price',
  REQUEST_PHOTOS_AND_LEASED_PRICE = 'Request Photos and Leased Price',
}

export type SelectedTourType =
  | ActionButtonsLabel.TOUR_IN_PERSON
  | ActionButtonsLabel.VIDEO_CHAT_TOUR;

export interface ShareListingModalProps {
  listingImageURL: string;
  listing: ListingWithDetails;
  agentId: string;
  closeModal: () => void;
  shareListing: (body: ShareListingBody) => ShareListingAction;
}
