import { Form, Formik, useFormikContext } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import { getAgentMode } from 'contacts/contactsSelectors';
import { BathsFilterFC as BathsFilter } from 'filters/BathsFilter/BathsFilter';
import { BedsFilterFC as BedsFilter } from 'filters/BedsFilter/BedsFilter';
import CommunityFeaturesFilter from 'filters/CommunityFeaturesFilter';
import FiltersFooter from 'filters/components/FiltersFooter';
import { applyFilters, resetAllFilters } from 'filters/filtersActions';
import { prepareAllFilters } from 'filters/filtersHelpers';
import {
  $getAreAnyFiltersApplied,
  $getFiltersForMoreFiltersModal,
} from 'filters/filtersSelectors';
import { FiltersDivider } from 'filters/filtersStyled';
import { GreatSchoolsFilter } from 'filters/GreatSchoolsFilter/GreatSchoolsFilter';
import HomeFeaturesFilter from 'filters/HomeFeaturesFilter';
import KeywordsFilter from 'filters/KeywordsFilter';
import { PriceFilterFC as PriceFilter } from 'filters/PriceFilter/PriceFilter';
import PropertyFeaturesFilter from 'filters/PropertyFeaturesFilter';
import PropertyTypeFilter from 'filters/PropertyTypeFilter';
import ShowOnlyFilter from 'filters/ShowOnlyFilter';
import SizeFilter from 'filters/SizeFilter';
import SourceFilter from 'filters/SourceFilter';
import StatusFilter from 'filters/StatusFilter/StatusFilter';
import TimeframeFilter from 'filters/TimeframeFilter';
import Modal, { ModalTypeProps } from 'shared/components/Modal';
import { ModalInnerFormContent } from 'shared/components/Modal/ModalStyled';
import { useSelector } from 'shared/helpers/redux';
import { useEffectOnUpdate } from 'shared/hooks/use-effect-on-update';
import { media } from 'styled-system/responsive';
import { getIsAgent } from 'user/userSelectors';

type FormValues = ReturnType<typeof $getFiltersForMoreFiltersModal>;

interface MoreFiltersModalProps extends ModalTypeProps {
  disableAllFilters?: boolean;
}

export function MoreFiltersModal(props: MoreFiltersModalProps) {
  const initialValues = useSelector($getFiltersForMoreFiltersModal);

  const [scrollableNode, setScrollableNode] = useState<HTMLDivElement | null>(
    null
  );

  return (
    <Modal
      closeModal={props.closeModal}
      isOpen={props.isOpen}
      label={props.label}
      modalContentStyles={{ width: '503px' }}
      onGetModalContentNode={setScrollableNode}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={props.closeModal}
      >
        <StyledForm>
          <FiltersContainer
            disabled={Boolean(props.disableAllFilters)}
            scrollableNode={scrollableNode}
          />
          <SyncFormikWithRedux />
        </StyledForm>
      </Formik>
    </Modal>
  );
}

function SyncFormikWithRedux(): null {
  const dispatch = useDispatch();
  const { initialValues, values } = useFormikContext<FormValues>();

  useEffectOnUpdate(() => {
    if (values !== initialValues) {
      dispatch(applyFilters(prepareAllFilters(values)));
    }
  }, [values]);

  return null;
}

interface FiltersContainerProps {
  disabled: boolean;
  scrollableNode: HTMLDivElement | null;
}

function FiltersContainer(props: FiltersContainerProps) {
  const dispatch = useDispatch();

  const agentMode = useSelector(getAgentMode);
  const isAgent = useSelector(getIsAgent);

  const areAnyFiltersApplied = useSelector($getAreAnyFiltersApplied);

  return (
    <>
      <Container>
        <PriceFilter innerMode disableFilter={props.disabled} />
        <FiltersDivider />
        <BedsFilter innerMode disableFilter={props.disabled} />
        <FiltersDivider />
        <BathsFilter innerMode disableFilter={props.disabled} />
        <FiltersDivider />
        <SourceFilter disableFilter={props.disabled} />
        <StatusFilter disableFilter={props.disabled} />
        <TimeframeFilter disableFilter={props.disabled} />
        <ShowOnlyFilter disableFilter={props.disabled} />
        <FiltersDivider />
        <PropertyTypeFilter disableFilter={props.disabled} />
        <FiltersDivider />
        <SizeFilter disableFilter={props.disabled} />
        <FiltersDivider />
        <HomeFeaturesFilter
          disableFilter={props.disabled}
          scrollableNode={props.scrollableNode}
        />
        <FiltersDivider />
        <PropertyFeaturesFilter
          disableFilter={props.disabled}
          scrollableNode={props.scrollableNode}
        />
        <FiltersDivider />
        <CommunityFeaturesFilter
          disableFilter={props.disabled}
          scrollableNode={props.scrollableNode}
        />
        <FiltersDivider />
        <GreatSchoolsFilter disableFilter={props.disabled} />
        <FiltersDivider />
        <KeywordsFilter disableFilter={props.disabled} />
      </Container>
      <FiltersFooter
        canReset={areAnyFiltersApplied}
        onReset={() => dispatch(resetAllFilters({ agentMode, isAgent }))}
      />
    </>
  );
}

const Container = styled(ModalInnerFormContent)`
  transform: translate3d(0, 0, 0);
  ${media.sm(css`
    padding: 0 24px 32px;
  `)}
`;

const StyledForm = styled(Form)`
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  padding-bottom: 56px;
`;
