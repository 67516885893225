import { DeepReadonly } from 'utility-types';

import { ContactsAction, ContactsActionType } from 'contacts/contactsActions';
import { ContactsState, ContactState } from 'contacts/contactsTypes';
import { SortOrder } from 'shared/constants/appConstants';

export const CONTACT_DEFAULT_STATE: ContactState = {
  basicInfoWasLoaded: false,
  currentContactId: '',
  error: '',
  item: null,
  loading: false,
};

export const CONTACTS_DEFAULT_STATE: ContactsState = {
  contact: CONTACT_DEFAULT_STATE,
  contacts: [],
  error: '',
  loading: false,
  page: 1,
  query: '',
  sortBy: 'activity',
  sortType: SortOrder.DESC,
  total: 0,
};

const INITIAL_CONTACTS_STATE: Partial<ContactsState> = {
  contacts: [],
  query: '',
  sortBy: 'activity',
  sortType: SortOrder.DESC,
  total: 0,
};

let prevState: DeepReadonly<ContactsState> = CONTACTS_DEFAULT_STATE;

export function contactsReducer(
  state: DeepReadonly<ContactsState> = CONTACTS_DEFAULT_STATE,
  action: ContactsAction
): DeepReadonly<ContactsState> {
  switch (action.type) {
    case ContactsActionType.FETCH_CONTACTS_REQUEST:
      prevState = { ...state };
      return {
        ...state,
        loading: true,
        error: '',
      };

    case ContactsActionType.SORT_CONTACTS:
      return {
        ...state,
        ...action.sort,
        page: 1,
      };

    case ContactsActionType.FILTER_CONTACTS:
      return {
        ...state,
        query: action.query,
        page: 1,
      };

    case ContactsActionType.CHANGE_CONTACTS_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case ContactsActionType.FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.contacts.hits,
        total: action.contacts.total,
        loading: false,
        error: '',
      };

    case ContactsActionType.FETCH_CONTACTS_FAIL:
      return {
        ...state,
        ...prevState,
        loading: false,
        error: action.error,
      };

    case ContactsActionType.RESET_CONTACTS:
      return {
        ...state,
        ...INITIAL_CONTACTS_STATE,
      };

    case ContactsActionType.FETCH_CONTACT:
      return {
        ...state,
        contact: {
          ...state.contact,
          loading: true,
          error: '',
          currentContactId: action.id,
        },
      };

    case ContactsActionType.FETCH_CONTACT_SUCCESS:
    case ContactsActionType.UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contact: {
          ...state.contact,
          item: action.contact,
          loading: false,
          error: '',
        },
      };

    case ContactsActionType.FETCH_CONTACT_FAIL:
    case ContactsActionType.UPDATE_CONTACT_FAIL:
      return {
        ...state,
        contact: {
          ...state.contact,
          loading: false,
          error: action.error,
        },
      };

    case ContactsActionType.SET_BASIC_INFO_WAS_LOADED:
      return {
        ...state,
        contact: {
          ...state.contact,
          basicInfoWasLoaded: true,
        },
      };

    case ContactsActionType.UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_SUCCESS: {
      return {
        ...state,
        contact: {
          ...state.contact,
          item: state.contact.item
            ? {
                ...state.contact.item,
                ...action.contactNotificationsSettings,
              }
            : null,
        },
      };
    }

    case ContactsActionType.RESET_CONTACT:
      return {
        ...state,
        page: CONTACTS_DEFAULT_STATE.page,
        contact: { ...CONTACT_DEFAULT_STATE },
      };

    default:
      return state;
  }
}
