import { DeepReadonly } from 'utility-types';

import { Agent } from 'agents/agentsTypes';
import { CollectionsAction } from 'collections/collectionsActions';
import { CollectionsActionType } from 'collections/collectionsActionType';
import * as notificationHistory from 'history/notificationHistory/notificationHistorySlice';
import {
  SavedSearchAction,
  SavedSearchActionType,
} from 'saved-search/savedSearchActions';
import { CognitoFederatedProviders } from 'shared/types';
import { UserAction, UserActionType } from 'user/userActions';
import { OAUTH_LOADING } from 'user/userConstants';
import { UserProfileStatus, UserState } from 'user/userTypes';

const { Initial, LoggedIn, LoggedOut, LoggingIn, LoggingOut } =
  UserProfileStatus;

export const USER_DEFAULT_STATE: UserState = {
  initialized: false,
  loading: false,
  isLogged: false,
  isMobile: false,
  status: !Boolean(localStorage.getItem(OAUTH_LOADING)) ? Initial : LoggingIn,
  email: '',
  profile: {
    data: null,
    loading: false,
  },
  profileFederated: {
    firstName: '',
    lastName: '',
  },
  registrationHasCompleted: false,
  forgotPasswordEmailSent: false,
  resetPasswordCompleted: false,
  setPasswordCompleted: false,
  code: '',
  role: '',
  agent: {} as Agent,
  recommendedAgent: '',
  userLocation: null,
  defaultAgent: null,
  notificationHistoryCount: 0,
  savedSearchInvitationsCount: 0,
  collectionsInvitationsCount: 0,
  SocialPhotoURL: '',
};

export function userReducer(
  state: DeepReadonly<UserState> = USER_DEFAULT_STATE,
  action: UserAction | SavedSearchAction | CollectionsAction
): DeepReadonly<UserState> {
  if (notificationHistory.markAllNotificationsAsReadInStore.match(action)) {
    return {
      ...state,
      notificationHistoryCount: 0,
    };
  }

  if (notificationHistory.undoMarkAllNotificationsAsRead.match(action)) {
    return {
      ...state,
      notificationHistoryCount: action.payload.notificationHistoryCount,
    };
  }

  if (notificationHistory.updateNotificationHistoryItemSuccess.match(action)) {
    return {
      ...state,
      notificationHistoryCount:
        state.notificationHistoryCount > 0
          ? state.notificationHistoryCount - 1
          : state.notificationHistoryCount,
    };
  }

  switch (action.type) {
    case UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIBE:
    case UserActionType.EMAIL_NOTIFICATIONS_UNSUBSCRIBE:
    case UserActionType.GET_USER_SESSION:
    case UserActionType.GET_USER_AGENT:
    case UserActionType.FEDERATED_CONNECT:
    case UserActionType.FEDERATED_DISCONNECT:
      return {
        ...state,
        loading: true,
      };

    case UserActionType.GET_USER_SESSION_ERROR:
      return {
        ...state,
        isLogged: false,
        loading: false,
        initialized: true,
        error: action.error,
      };

    case UserActionType.USER_FEDERATED_LOG_IN:
      return {
        ...state,
        loading: true,
        status: LoggingIn,
        error: '',
      };

    case UserActionType.USER_FEDERATED_LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        status: LoggedIn,
        error: '',
      };

    case UserActionType.USER_FEDERATED_LOG_IN_ERROR:
      return {
        ...state,
        loading: false,
        status: LoggedOut,
        error: action.error,
      };

    case UserActionType.START_USER_SIGN_UP:
      return {
        ...state,
        email: action.email,
        loading: true,
        error: '',
        status: LoggingIn,
      };

    case UserActionType.RESET_USER_LOGIN:
    case UserActionType.RESET_USER_SIGN_UP:
      return {
        ...state,
        email: '',
        loading: false,
        error: '',
        status: LoggedOut,
      };

    case UserActionType.USER_SIGN_UP_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        status: LoggedOut,
      };

    case UserActionType.USER_SIGN_UP_USERNAME_EXISTS:
      return {
        ...state,
        error: 'username exists',
        loading: false,
      };

    case UserActionType.USER_SIGN_UP_VERIFICATION_ERROR:
      return {
        ...state,
        error: 'email is not verified',
        loading: false,
      };

    case UserActionType.START_USER_LOGIN:
      return {
        ...state,
        email: action.email,
        password: action.password,
        loading: true,
        error: '',
        status: LoggingIn,
      };

    case UserActionType.USER_IS_LOGGED:
      return {
        ...state,
        isLogged: action.status,
        role: action.role,
        initialized: true,
        loading: false,
        status: action.status ? LoggedIn : LoggedOut,
      };

    case UserActionType.SET_FEDERATED_USER_PROFILE:
      return {
        ...state,
        profileFederated: action.data,
        status: LoggedIn,
      };

    case UserActionType.START_UPDATE_PROFILE:
    case UserActionType.START_CREATE_PROFILE:
      return {
        ...state,
        error: '',
        profile: {
          ...state.profile,
          loading: true,
        },
      };

    case UserActionType.CREATE_OR_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data,
          loading: false,
        },
        loading: false,
        registrationHasCompleted: true,
      };

    case UserActionType.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            emailVerified: true,
          },
        },
      };

    case UserActionType.SIGN_OUT_SUCCESS:
      return {
        ...state,
        initialized: false,
        email: '',
        profile: {
          data: null,
          loading: false,
        },
        profileFederated: {},
        registrationHasCompleted: false,
        loading: true,
        status: LoggingOut,
      };

    case UserActionType.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          loading: false,
          data: action.profile,
        },
        loading: false,
        status: LoggedIn,
      };

    case UserActionType.FETCH_PROFILE_ERROR:
      return {
        ...state,
        profile: {
          data: null,
          loading: false,
        },
        error: action.error,
        loading: false,
        status: LoggedOut,
      };

    case UserActionType.FORGOT_PASSWORD_PREPARE:
      return {
        ...state,
        forgotPasswordEmailSent: false,
        error: '',
      };

    case UserActionType.START_FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
        email: action.email,
      };

    case UserActionType.START_CHANGE_PASSWORD:
      return {
        ...state,
        email: action.email,
        loading: true,
      };

    case UserActionType.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordEmailSent: true,
      };

    case UserActionType.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotPasswordEmailSent: false,
        error: action.error,
      };

    case UserActionType.START_RESET_PASSWORD:
      return {
        ...state,
        email: action.email,
        code: action.code,
        password: action.password,
        loading: true,
      };

    case UserActionType.START_SET_PASSWORD:
      return {
        ...state,
        email: action.email,
        temporaryPassword: action.temporaryPassword,
        password: action.password,
        loading: true,
      };

    case UserActionType.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPasswordCompleted: true,
      };

    case UserActionType.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        setPasswordCompleted: true,
      };

    case UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIPTION_FAIL:
    case UserActionType.EMAIL_NOTIFICATIONS_SUBSCRIPTION_SUCCESS:
    case UserActionType.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UserActionType.GET_USER_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agent: action.agent,
      };

    case UserActionType.CHANGE_USER_AGENT:
      return {
        ...state,
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            agentId: action.agentId,
          },
        },
      };

    case UserActionType.FEDERATED_DISCONNECT_SUCCESS:
      const provider = action.provider;
      return {
        ...state,
        loading: false,
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            facebookAccount:
              provider === CognitoFederatedProviders.Facebook
                ? null
                : state.profile.data.facebookAccount,
            googleAccount:
              provider === CognitoFederatedProviders.Google
                ? null
                : state.profile.data.googleAccount,
          },
        },
      };

    case UserActionType.USER_LOGGING_ERROR:
    case UserActionType.SIGN_OUT_ERROR:
    case UserActionType.CHANGE_PASSWORD_ERROR:
    case UserActionType.RESET_PASSWORD_ERROR:
    case UserActionType.SET_PASSWORD_ERROR:
    case UserActionType.GET_USER_AGENT_ERROR:
    case UserActionType.FEDERATED_CONNECT_FAIL:
    case UserActionType.FEDERATED_DISCONNECT_FAIL:
    case UserActionType.FETCH_DEFAULT_AGENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserActionType.CREATE_OR_UPDATE_PROFILE_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
        },
        error: action.error,
      };

    case UserActionType.SET_RECOMMENDED_AGENT:
      return {
        ...state,
        recommendedAgent: action.agentNameOrId,
      };
    case UserActionType.CLEAR_RECOMMENDED_AGENT: {
      return {
        ...state,
        recommendedAgent: USER_DEFAULT_STATE.recommendedAgent,
      };
    }

    case UserActionType.SET_USER_LOCATION: {
      return {
        ...state,
        userLocation: action.location,
      };
    }

    case UserActionType.FETCH_DEFAULT_AGENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case UserActionType.FETCH_DEFAULT_AGENT_SUCCESS:
      return {
        ...state,
        defaultAgent: action.defaultAgent,
        loading: false,
        error: '',
      };

    case UserActionType.FETCH_NOTIFICATION_COUNTS_SUCCESS:
      return {
        ...state,
        notificationHistoryCount: action.notificationHistoryCount,
        savedSearchInvitationsCount: action.savedSearchInvitationsCount,
        collectionsInvitationsCount: action.collectionsInvitationsCount,
      };

    case SavedSearchActionType.UPDATE_SAVED_SEARCHES_INVITATIONS:
      return {
        ...state,
        savedSearchInvitationsCount: action.invitations.length,
      };

    case CollectionsActionType.UPDATE_COLLECTIONS_INVITATIONS:
      return {
        ...state,
        collectionsInvitationsCount: action.invitations.length,
      };

    case UserActionType.FETCH_NOTIFICATION_INBOX_COUNT_SUCESS:
      return {
        ...state,
        notificationHistoryCount: action.count,
      };

    case UserActionType.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      };
    case UserActionType.GET_SOCIAL_PHOTO_URL_SUCCESS:
      return {
        ...state,
        SocialPhotoURL: action.SocialPhotoURL,
      };

    case UserActionType.SET_SOCIAL_PHOTO_URL_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            userSocialPhoto_URL: action.SocialPhotoURL,
          },
        },
      };

    case UserActionType.REMOVE_SOCIAL_PHOTO_URL_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            userSocialPhoto_URL: null,
          },
        },
      };

    default:
      return state;
  }
}
