import styled, { css } from 'styled-components/macro';

import Button from 'shared/components/Button';
import { DropdownOption } from 'shared/components/SearchBar/components/SearchBarOption/SearchBarOptionStyled';
import {
  OptionContainer,
  SelectWrapper,
} from 'shared/components/Select/SelectStyles';
import { ShowMoreButton } from 'shared/components/ShowMore/ShowMoreStyled';
import { zIndex } from 'shared/styles/z-indexes';
import { media } from 'styled-system/responsive';

export const SEARCH_BUTTON_DESKTOP_WIDTH = 120;
export const SEARCH_BAR_DESKTOP_HEIGHT = 56;
export const SEARCH_BAR_MOBILE_HEIGHT = 48;
export const DROPDOWN_SPACE_TO_INPUT = 1;
export const DROPDOWN_LEFT_SHIFT = 16;
export const DROPDOWN_MAX_HEIGHT = window.innerHeight * 0.3;
export const DROPDOWN_MAX_HEIGHT_BUY_PAGE = 536;
export const DROPDOWN_MAX_HEIGHT_AGENT_PAGE = 488;
export const DROPDOWN_MAX_HEIGHT_BUY_PAGE_MOBILE = 296;

export const ShowTopShadowOnScroll = css`
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.white};

  ${media.md(
    () => css<any>`
      max-height: ${SEARCH_BAR_MOBILE_HEIGHT}px;
    `
  )}

  ${SelectWrapper} {
    padding: 0;
    line-height: unset;

    .react-select__control {
      border: none;
      height: ${SEARCH_BAR_DESKTOP_HEIGHT}px;
      font-size: 16px;

      ${media.sm(
        () => css<any>`
          height: ${SEARCH_BAR_MOBILE_HEIGHT}px;
          font-size: 14px;
        `
      )}
    }

    .react-select__menu {
      -webkit-overflow-scrolling: touch;
      background-color: ${({ theme }) => theme.white};
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      line-height: 1;
      overflow: auto;
      z-index: ${zIndex.DROPDOWN_MENU};
      transform: translateX(-36px);
      width: calc(100% + 36px);
      border: none;
      margin: ${DROPDOWN_SPACE_TO_INPUT}px 0 0;

      .react-select__menu-list {
        max-height: ${DROPDOWN_MAX_HEIGHT}px;
      }

      ${media.md(
        () => css<any>`
          width: calc(100% + ${SEARCH_BAR_MOBILE_HEIGHT}px);
          transform: none;
        `
      )}

      ${OptionContainer} {
        height: ${SEARCH_BAR_MOBILE_HEIGHT}px;
        box-sizing: border-box;
        color: rgba(51, 51, 51, 0.8);
        cursor: pointer;
        display: flex;

        &:first-child {
          background-color: ${({ theme }) => theme.secondary};
          color: ${({ theme }) => theme.white};
        }

        .react-select__option {
          background-color: transparent;
          color: inherit;
          cursor: inherit;
          font-size: 16px;
          padding: 1em 1em 1em 48px;

          ${media.sm(css<any>`
            padding-left: 16px;
            font-size: 14px;
          `)}
        }

        svg {
          margin-right: 1em;
          vertical-align: bottom;
          fill: ${({ theme }) => theme.text};
        }

        &:hover {
          background-color: ${({ theme }) => theme.secondary};
          color: ${({ theme }) => theme.white};

          svg {
            fill: ${({ theme }) => theme.white};
          }
        }
      }
    }

    .react-select__indicators {
      display: none;
    }

    .react-select__value-container {
      padding: 0 16px;

      > div {
        margin: 0;
        padding: 0;
      }
    }

    .react-select__input {
      font-size: 16px;
    }

    .react-select__placeholder {
      font-family: Lato, -apple-system, 'Segoe UI', Roboto, Ubuntu, 'Open Sans',
        'Helvetica Neue', sans-serif;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
      line-height: 1.15;

      ${media.sm(
        () => css<any>`
          width: 89%; // to match width of the input place holder for listing
        `
      )}
    }
  }
`;

const Wrapper = styled.form<{
  showSearchButton?: boolean;
  isFocused?: boolean;
}>`
  display: flex;
  color: ${({ theme }) => theme.text2};
  font-weight: normal;
  position: relative;
  top: 0;
  width: 100%;
  z-index: ${zIndex.SEARCH_BAR};
  border-radius: 1px;

  & > div {
    position: relative;
    display: flex;
    width: 100%;
  }

  svg {
    vertical-align: middle;
  }

  ${props =>
    props.showSearchButton &&
    media.sm(css<any>`
      flex-direction: column;
    `)};

  ${({ isFocused = false }) =>
    isFocused &&
    css<any>`
      box-shadow: -1px 4px 8px 0 rgba(188, 200, 210, 0.29);
    `};
`;

const IconWrapper = styled.label`
  display: block;
  padding-left: 16px;
  width: 36px;
  height: 20px;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
`;

const ClearButton = styled(Button)`
  width: auto;
  font-size: 14px;
  border: 0;
  background: transparent;
  font-family: Lato, sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;
`;

const SearchButton = styled(Button)`
  padding: 0;
  width: ${SEARCH_BUTTON_DESKTOP_WIDTH}px;
  height: ${SEARCH_BAR_DESKTOP_HEIGHT}px;
  border-radius: 0px 1px 1px 0;

  ${media.md(
    () => css<any>`
      width: ${SEARCH_BAR_MOBILE_HEIGHT}px;
      height: ${SEARCH_BAR_MOBILE_HEIGHT}px;
      span {
        justify-content: center;
      }
    `
  )}
`;

const SearchInput = styled.input<{ showSearchButton?: boolean }>`
  &::-ms-clear {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${({ theme }) => theme.disabled};
  }

  &[placeholder] {
    text-overflow: ellipsis;
  }

  flex-basis: auto;
  flex-grow: 1;
  border: 0;
  margin: ${props => props.showSearchButton && 0};
  min-width: 50px;
  max-width: 100%;
  padding: 0 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  border-radius: ${props => (props.showSearchButton ? '1px 0 0 1px' : '1px')};

  ${props =>
    props.showSearchButton &&
    media.sm(css<any>`
      width: 87vw;
    `)};

  ${media.sm(css<any>`
    flex: 1;
    width: 100%;
    min-width: 33px;
  `)};
`;

const OptionGroupContainer = styled.div<{ divider?: boolean }>`
  ${ShowMoreButton} {
    padding: 0 0 8px 48px;
  }
`;

const OptionGroup = styled(DropdownOption)`
  border-top: 1px solid ${({ theme }) => theme.divider};
  color: ${({ theme }) => theme.text2};
  cursor: initial;
  font-weight: bold;
  padding: 16px 16px 14px;

  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.text2};
  }

  &.listings-results-item {
    border-top: none;
  }
`;

const DropdownMenu = styled.div<{
  showSearchButton?: boolean;
  maxHeight?: number;
  shifted?: boolean;
}>`
  position: absolute;
  max-height: ${props =>
    props.maxHeight ? `${props.maxHeight}px` : `${DROPDOWN_MAX_HEIGHT}px`};
  width: ${({ showSearchButton, shifted }) => {
    if (showSearchButton && shifted) {
      return `calc(100% - ${SEARCH_BUTTON_DESKTOP_WIDTH}px - ${DROPDOWN_LEFT_SHIFT}px)`;
    }
    if (showSearchButton) {
      return `calc(100% - ${SEARCH_BUTTON_DESKTOP_WIDTH}px)`;
    }
    if (shifted) {
      return `calc(100% - ${DROPDOWN_LEFT_SHIFT}px)`;
    }
    return '100%';
  }}};
  top: calc(100% + ${DROPDOWN_SPACE_TO_INPUT}px);
  left: ${({ shifted }) => (shifted ? `${DROPDOWN_LEFT_SHIFT}px` : `0`)};
  overflow: auto;
  z-index: ${zIndex.DROPDOWN_MENU};
  -webkit-overflow-scrolling: touch;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  line-height: 1;
  border-radius: 0;

  ${media.sm(css<any>`
    left: 0;
    width: 100%;
    box-shadow: unset;
    top: 100%;
    ${ShowTopShadowOnScroll}
  `)};

  ${OptionGroupContainer}:first-child ${OptionGroup} + [role="option"],

  ${DropdownOption}[role="option"]:first-child {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.white};
    height: ${SEARCH_BAR_MOBILE_HEIGHT}px;

    svg {
      fill: ${({ theme }) => theme.white};
    }
  }
`;

const ShowMore = styled(Button)`
  padding: 0 0 8px 38px;
  font-size: 14px;
`;

const OptionGroupIcon = styled.div`
  svg {
    fill: ${({ theme }) => theme.text2};
  }
`;
const OptionGroupText = styled.div``;

export const OptionIconStyled = styled.span`
  padding: 0px 16px;
  line-heigth: 2px;
  path,
  rect,
  polygon,
  line {
    fill: ${({ theme }) => theme.text2};
  }
`;

export const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchBarStyled = {
  ClearButton,
  DropdownMenu,
  IconWrapper,
  InputWrapper,
  SearchInput,
  OptionGroup,
  OptionGroupContainer,
  OptionGroupIcon,
  OptionGroupText,
  SearchButton,
  ShowMoreButton: ShowMore,
  Wrapper,
  OptionIconStyled,
  SubHeaderWrapper,
};

export default SearchBarStyled;
