import { components, StylesConfig } from 'react-select';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as CancelIcon } from 'shared/icons/shared/24x24/cancel.svg';
import { ReactComponent as DropdownIndicator } from 'shared/icons/shared/arrows/small-arrow-down.svg';
import globalTheme from 'shared/styles/globalTheme';

const iconStyled = css`
  width: 16px;
  height: 16px;
  * {
    fill: ${globalTheme.text3};
  }
`;

const StyledCancelIcon = styled(CancelIcon)`
  ${iconStyled};
`;

const StyledDropdownIndicator = styled(DropdownIndicator)`
  ${iconStyled};
`;

const StyledOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;
`;

const StyledValueContainer = styled(components.ValueContainer)<{
  tagsLength: number;
  hideExceptAllOption: boolean;
}>`
  margin: -4px 0 -4px;
  flex-wrap: nowrap !important;
  ${({ tagsLength, hideExceptAllOption }) =>
    tagsLength > 1 &&
    css`
      ${!hideExceptAllOption &&
      css`
        &:after {
          content: '+ ${tagsLength - 1} more';
          font-size: 14px;
          line-height: 18px;
          color: ${globalTheme.link};
          font-weight: bold;
          margin: auto 0;
        }
      `}
      > div {
        display: none;
        &:first-child,
        &:last-child {
          display: flex;
        }
      }
    `}
  ${({ hideExceptAllOption }) =>
    hideExceptAllOption &&
    css`
      > div {
        display: none;
        &:first-child,
        &:last-child {
          display: flex;
        }
      }
    `}

    &>div {
    &:last-child {
      width: 2px;
      flex: none;
    }
  }
`;

export const MultiSelectStyled = {
  StyledCancelIcon,
  StyledDropdownIndicator,
  StyledOptionContainer,
  StyledValueContainer,
};

export const getMultiSelectStyles = ({
  error,
}: {
  error?: boolean;
}): StylesConfig => ({
  container: base => base,
  control: (base, { isFocused, isDisabled, selectProps }) => ({
    ...base,
    borderRadius: '1px',
    border: `solid 1px ${
      isDisabled
        ? globalTheme.divider
        : isFocused
        ? globalTheme.secondary
        : error
        ? globalTheme.error
        : globalTheme.border
    }`,
    background: isDisabled
      ? globalTheme.hover
      : error
      ? globalTheme.errorBg
      : globalTheme.white,
    padding: '0 8px',
    height: '40px',
    boxShadow: isFocused ? `0 0 1px ${globalTheme.text3}` : 'none',
    '&:hover': {
      borderColor: isFocused ? globalTheme.secondary : globalTheme.text3,
    },
  }),

  multiValue: base => ({
    ...base,
    border: `1px solid ${globalTheme.divider}`,
    borderRadius: '16px',
    background: globalTheme.white,
    margin: '4px 8px 4px 0',
    overflow: 'hidden',
  }),
  multiValueLabel: base => ({
    ...base,
    fontFamily: globalTheme.latoFontFamily,
    fontSize: '12px',
    lineHeight: '16px',
    color: globalTheme.text2,
  }),
  multiValueRemove: base => ({
    ...base,
    '&:hover': { backgroundColor: 'transparent' },
  }),

  clearIndicator: base => ({
    ...base,
    padding: '10px 4px 4px',
    '&:hover': { backgroundColor: 'transparent' },
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '8px 0',
    margin: 'auto',
    '&:hover': { backgroundColor: 'transparent' },
  }),
  group: base => base,
  groupHeading: base => base,
  indicatorsContainer: base => ({
    ...base,
    alignItems: 'flex-start',
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: globalTheme.divider,
    margin: '0 4px',
    maxHeight: '40px',
    '&:first-of-type': {
      display: 'none',
    },
  }),
  input: base => ({
    ...base,
    fontFamily: globalTheme.latoFontFamily,
    fontSize: '14px',
    lineHeight: '18px',
    margin: 0,
  }),
  loadingIndicator: base => base,
  loadingMessage: base => base,
  menu: base => ({
    ...base,
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: globalTheme.white,
    fontFamily: globalTheme.latoFontFamily,
    fontSize: '14px',
    lineHeight: '18px',
    color: globalTheme.text2,
    marginTop: '4px',
  }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  menuPortal: base => base,
  noOptionsMessage: base => base,
  option: (base, state) => ({
    ...base,
    padding: '12px',
    backgroundColor: state.isFocused ? globalTheme.hover : globalTheme.white,
    '&:active': { backgroundColor: globalTheme.white },
    '&:hover': { backgroundColor: globalTheme.hover },
  }),
  placeholder: (base, { isDisabled }) => ({
    ...base,
    fontFamily: globalTheme.latoFontFamily,
    fontSize: '14px',
    lineHeight: '18px',
    color: isDisabled ? globalTheme.text3 : globalTheme.disabled,
  }),
  singleValue: base => ({
    ...base,
    fontFamily: globalTheme.latoFontFamily,
    fontSize: '14px',
    lineHeight: '18px',
    color: globalTheme.text2,
    background: globalTheme.white,
  }),
  valueContainer: (base, { isDisabled }) => ({
    ...base,
    padding: 0,
    color: isDisabled ? globalTheme.text3 : base.color,
  }),
});
