import { Omit } from 'utility-types';

import { PeriodFilters } from 'filters/filtersTypes';
import { EMAIL_HISTORY_BASE_URL } from 'history/historyConstants';
import {
  NotificationHistorySortBy,
  NotificationHistoryStatus,
  NotificationHistoryTypeFilter,
} from 'history/notificationHistory/notificationHistoryTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { not, notEmpty } from 'shared/helpers/boolean';

export function constructNotificationInboxUrl({
  page,
  sortType,
  sortBy,
  limit,
  type,
  periodFilters,
}: {
  page: number;
  sortType: SortOrder;
  sortBy: NotificationHistorySortBy;
  limit: number;
  type: NotificationHistoryTypeFilter;
  periodFilters: Partial<Omit<PeriodFilters, 'period'>>;
}) {
  const url = new URL(`${window.location.origin}/api${EMAIL_HISTORY_BASE_URL}`);
  url.searchParams.set('page', String(page));
  url.searchParams.set('sortType', String(sortType));
  url.searchParams.set('sortBy', String(sortBy));
  url.searchParams.set('limit', String(limit));

  if (notEmpty(periodFilters)) {
    if (periodFilters?.periodStart) {
      url.searchParams.set('periodStart', String(periodFilters?.periodStart));
    }

    if (periodFilters?.periodEnd) {
      url.searchParams.set('periodEnd', String(periodFilters?.periodEnd));
    }
  }

  if (type === NotificationHistoryTypeFilter.Unread) {
    url.searchParams.set(
      'status',
      String([
        NotificationHistoryStatus.Sent,
        NotificationHistoryStatus.NotSent,
        NotificationHistoryStatus.Bounced,
        NotificationHistoryStatus.Complained,
      ])
    );
  }

  if (
    type === NotificationHistoryTypeFilter.Inquiries ||
    type === NotificationHistoryTypeFilter.Emails
  ) {
    url.searchParams.set('showOnly', String(type));
  }

  url.searchParams.set('dateOrder', 'dateTime');

  return url;
}

export const isNotOpenedNotification = (
  status: NotificationHistoryStatus
): boolean => {
  return not(
    status === NotificationHistoryStatus.Clicked ||
      status === NotificationHistoryStatus.Open
  );
};
