export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortingData {
  address = 'address',
  baths = 'baths',
  beds = 'beds',
  date = 'date',
  distance = 'distance',
  listingid = 'listingid',
  neighborhood = 'neighborhood',
  openHouseDate = 'openHouseDate',
  price = 'price',
  updatedDate = 'recentlyChanged',
  soldDate = 'soldDate',
  squareFeet = 'sqfeet',
  squareFoot = 'sqfeetPrice',
  status = 'status',
  statusPrice = 'statusPrice',
  type = 'type',
  lastviewed = 'lastviewed',
  propertyfiltertype = 'propertyfiltertype',
  addedDate = 'recentlyAdded',
}

export enum neighborhoodLevel {
  first = 1,
  second = 2,
  third = 3,
  fourth = 4,
}

export enum ListingsSearchingModes {
  nearby = 'nearbySearch',
}

export enum NoResultsTitle {
  COLLECTION_LISTINGS = 'There are no listings in this collection yet',
  SAVED_SEARCH_LISTINGS = 'You’ll find the results here as soon as the listings match your search criteria.',
}

export const AGENT_LOGIN_REDIRECT = 'agentLoginRedirect';
export const AGENT_TIP_HEIGHT = 28;
export const DEFAULT_AGENT = 'defaultAgent';
export const INPUT_MAX_LENGTH = 40;
export const LANDING_PAGE = 'landing-page';
export const LISTINGS_SEARCH_BAR_PLACEHOLDER =
  'Search by City, Neighborhood, Address, School, Zip Code';
export const SAVED_SEARCH_AND_COLLECTION_NAME_MAX_LENGTH = 57;
export const COLLECTION_DESCRIPTION_MAX_LENGTH = 250;
export const SAVED_SEARCH_AND_COLLECTION_NAME_MIN_LENGTH = 2;
export const SAVED_SEARCH_SETTINGS_MODAL_NAME = 'Save Search';
export const SCREENS_COUNT_OFFSET_FOR_SCROLL_TO_TOP = 4;
export const TABS_HEIGHT = 48;
export const TEMPORARY_PASSWORD = 'temporaryPassword';
export const US_CODE = '+1';

export const FEDERATED_USER = 'federatedUser';

export const APP_MODAL_CLASSNAME = 'realty-modal-classname';
export const TABLE_HEADER_HEIGHT = 48;
export const TABLE_LARGE_HEADER_HEIGHT = 64;

export enum GTM_TOASTERS_IDS {
  SCHEDULE_TOUR = 'schedule-tour',
  SCHEDULE_VIDEO_TOUR = 'schedule-a-video-tour',
  ADD_TO_FAVORITE = 'favorite-listing',
  NEW_LEAD_REGISTRATION = 'new-lead-registration',
}

export enum GTM_CUSTOM_EVENTS_NAMES {
  LISTING_DETAILS_VIEW = 'Listing Details Page Load',
  SEARCH_WITH_FILTERS = 'Search With Filters',
  REGISTRATION_OR_LOGGING_IN = 'Registration or Logging In',
  UNLOCK = 'Unlock',
}

export const GTM_DEBUG_MODE = 'gtm_debug';

export const MONTH_IN_YEAR = 12;
export const MONTH_IN_30_YEARS = 360;

export const STATUS_FORBIDDEN = 403;
export const STATUS_NOT_FOUND = 404;
export const STATUS_OK = 200;

export const SIGN_UP_URL_PARAMETER = 'signup';

export const YOU_TUBE_VIDEO_ID_REG_EXP =
  /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;

export const NO_RESULTS_DROPDOWN_MESSAGE = 'No results';
export const LOADING_DROPDOWN_MESSAGE = 'Loading...';

export enum browserStorageItem {
  deviceSessionUUID = 'device-session-uuid',
  guestRecentSearches = 'guest-recent-searches',
  hardReset = 'app-hard-reset-executed',
  listingViewedIds = 'listingIds',
  originalSource = 'original-source',
  originUrl = 'original-url',
  showPromptSaveListing = 'showPromptSaveListing',
  showPromptSaveSearch = 'showPromptSaveSearch',
  userSession = 'user-session',
}

export enum ButtonsKeyCode {
  Space = 32,
  Enter = 13,
  Esc = 27,
}

// TODO: rename it to ButtonKey
export enum ButtonsCode {
  Space = ' ',
  Enter = 'Enter',
  Esc = 'Escape',
  Tab = 'Tab',
  Shift = 'Shift',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
}

export const isExclusive = 'isexclusive';

export const LOGIN_AS_AGENT = 'loginAsAgent';
