import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VIEW_MODE_GALLERY } from 'listings/listingsConstants';
import { Listing } from 'listings/listingsTypes';
import {
  defaultSortOptions,
  MyListingLayouts,
} from 'myListings/myListingsConstants';
import {
  IAgentsFilter,
  MyListingsFilters,
  MyListingsPriceFilter,
  MyListingsState,
} from 'myListings/myListingsTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { effect } from 'shared/helpers/redux';

export const DEFAULT_AGENTS_FILTER: IAgentsFilter = {
  agentId: '',
};

export const DEFAULT_MY_LISTINGS_PRICE_FILTER: MyListingsPriceFilter = {
  minPrice: 0,
  maxPrice: 0,
};

export const MY_LISTINGS_FILTERS_DEFAULT_STATE: MyListingsFilters = {
  ...DEFAULT_AGENTS_FILTER,
  ...DEFAULT_MY_LISTINGS_PRICE_FILTER,
};

export const MY_LISTINGS_DEFAULT_STATE: MyListingsState = {
  currentTab: MyListingLayouts.onMarket,
  viewMode: VIEW_MODE_GALLERY,
  items: [],
  loading: false,
  page: 1,
  listingsCount: 0,
  error: '',
  sortBy: 'updatedDate',
  sortType: SortOrder.DESC,
  isMyListingsFirst: true,
  itemsToRemove: [],
  filters: MY_LISTINGS_FILTERS_DEFAULT_STATE,
};

const myListingsSlice = createSlice({
  name: 'myListings',
  initialState: MY_LISTINGS_DEFAULT_STATE,
  reducers: {
    changeMyListingsType: (
      state,
      action: PayloadAction<{
        type: MyListingLayouts;
        silentUpdate?: boolean;
      }>
    ) => {
      state.currentTab = action.payload.type;
      state.page = 1;
      state.sortBy = defaultSortOptions(state.currentTab).value;
      state.sortType = SortOrder.DESC;
      state.isMyListingsFirst = true;
    },
    changeViewMode: (state, action: PayloadAction<{ viewMode: string }>) => {
      state.viewMode = action.payload.viewMode;
    },
    fetchMyListings: state => state,
    fetchMyListingsRequest: state => {
      state.loading = true;
    },
    fetchMyListingsSuccess: (
      state,
      action: PayloadAction<{ listings: Listing[]; total: number }>
    ) => {
      state.loading = false;

      state.items = action.payload.listings;
      state.listingsCount = action.payload.total;
    },
    fetchMyListingsFailure: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    changeMyListingsPage: (state, action: PayloadAction<{ page: number }>) => {
      state.page = action.payload.page;
    },
    changeMyListingDetailsPage: effect<{
      page: number;
      fetchingNextPage: boolean;
      onError: () => void;
    }>(),
    changeSortByMyListings: (
      state,
      action: PayloadAction<{ sortBy: string }>
    ) => {
      state.sortBy = action.payload.sortBy;
      state.page = 1;
      state.loading = true;
    },
    changeSortTypeMyListings: (
      state,
      action: PayloadAction<{ sortType: SortOrder }>
    ) => {
      state.sortType = action.payload.sortType;
      state.page = 1;
      state.loading = true;
    },
    changeIsMyListingsFirst: (
      state,
      action: PayloadAction<{ isMyListingsFirst: boolean }>
    ) => {
      state.isMyListingsFirst = action.payload.isMyListingsFirst;
      state.page = 1;
      state.loading = true;
    },
    startDeleteMyListing: (
      state,
      action: PayloadAction<{
        listingId: string;
        agentId: string;
        publishedDraftId?: string;
        isDraft?: boolean;
        page?: number;
        pageWasChanged?: boolean;
      }>
    ) => {
      state.itemsToRemove.push(action.payload.listingId);
      state.listingsCount = state.listingsCount - 1;
    },
    undoDeleteMyListing: (
      state,
      action: PayloadAction<{ listingId: string }>
    ) => {
      state.itemsToRemove = state.itemsToRemove.filter(
        listingId => listingId !== action.payload.listingId
      );

      state.listingsCount = state.listingsCount + 1;
    },
    changeMyListingsFilterValue: (
      state,
      action: PayloadAction<{ filters: Partial<MyListingsFilters> }>
    ) => {
      Object.assign(state.filters, action.payload.filters);
      state.page = 1;
      state.loading = true;
    },
    applyMyListingsFilterValue: (
      state,
      action: PayloadAction<{ filters: Partial<MyListingsFilters> }>
    ) => {
      Object.assign(state.filters, action.payload.filters);
      state.page = 1;
    },
    resetMyListingsAgentsFilter: state => {
      state.filters.agentId = DEFAULT_AGENTS_FILTER.agentId;
      state.page = 1;
      state.loading = true;
    },
    resetMyListingsPriceFilter: state => {
      state.filters.minPrice = DEFAULT_MY_LISTINGS_PRICE_FILTER.minPrice;
      state.filters.maxPrice = DEFAULT_MY_LISTINGS_PRICE_FILTER.maxPrice;
      state.page = 1;
      state.loading = true;
    },
    resetMyListingsAllFilters: (
      state,
      action: PayloadAction<{ silentUpdate?: boolean }>
    ) => {
      state.filters = MY_LISTINGS_FILTERS_DEFAULT_STATE;
      state.page = 1;
      state.loading = true;
    },
    setMyListings: (state, action: PayloadAction<{ listings: Listing[] }>) => {
      state.items = action.payload.listings;
      state.loading = false;
    },
    deleteMyListing: effect<{
      listingId: string;
      agentId: string;
      publishedDraftId?: string;
      isDraft?: boolean;
    }>(),
    mergeMyListings: (
      state,
      action: PayloadAction<Partial<MyListingsState>>
    ) => {
      Object.assign(state, action.payload);
    },
    setMyListingsFromUrl: state => state,
  },
});

export const myListingsReducer = myListingsSlice.reducer;

export const {
  applyMyListingsFilterValue,
  changeMyListingsType,
  changeViewMode,
  fetchMyListings,
  fetchMyListingsRequest,
  fetchMyListingsSuccess,
  fetchMyListingsFailure,
  changeMyListingsPage,
  changeSortByMyListings,
  changeSortTypeMyListings,
  changeIsMyListingsFirst,
  startDeleteMyListing,
  undoDeleteMyListing,
  deleteMyListing,
  changeMyListingDetailsPage,
  changeMyListingsFilterValue,
  resetMyListingsAgentsFilter,
  resetMyListingsPriceFilter,
  resetMyListingsAllFilters,
  setMyListings,
  setMyListingsFromUrl,
  mergeMyListings,
} = myListingsSlice.actions;
