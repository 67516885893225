import {
  EmailHistoryItem,
  EmailHistorySortBy,
  EmailHistoryStatusFilter,
} from 'history/emailHistory/emailHistoryTypes';
import {
  EMAIL_HISTORY_BASE_URL,
  EMAIL_HISTORY_ITEM_BASE_URL,
} from 'history/historyConstants';
import { SortOrder } from 'shared/constants/appConstants';
import { ApiService } from 'shared/services/ApiService';
import { Paginated } from 'shared/types';

export class EmailHistoryService {
  static async getEmailHistory(query: {
    username: string;
    page: number;
    sortType: SortOrder;
    sortBy: EmailHistorySortBy;
    limit: number;
    status: EmailHistoryStatusFilter;
    periodStart: string;
    periodEnd: string;
    notSentReason?: string;
  }) {
    return ApiService.request<Paginated<EmailHistoryItem>>({
      method: 'GET',
      path: `${EMAIL_HISTORY_BASE_URL}`,
      query,
    });
  }
  static async getEmailHistoryItem(
    query: {
      username: string;
    },
    emailID: string
  ) {
    return ApiService.request<string>({
      method: 'GET',
      path: `${EMAIL_HISTORY_ITEM_BASE_URL}/${emailID}`,
      query,
      resolve: res => res.text(),
    });
  }
}
