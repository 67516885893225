import { not } from 'shared/helpers/boolean';

type CreateShouldForwardProp = (
  props: any[]
) => (prop: any, defaultValidatorProp: (prop: any) => boolean) => boolean;

export const createShouldForwardProp: CreateShouldForwardProp = props => {
  const regex = new RegExp(`^(${props.join('|')})$`);
  return (prop, defaultValidatorFn) => {
    return defaultValidatorFn(prop) && not(regex.test(prop));
  };
};
