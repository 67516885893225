import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getContactItem } from 'contacts/contactsSelectors';
import { getPeriodStartAndEnd } from 'filters/filtersHelpers';
import { handleEmailHistoryQuery } from 'history/emailHistory/emailHistoryHelpers';
import { EmailHistoryService } from 'history/emailHistory/emailHistoryService';
import {
  changePageOnEmailHistory,
  EMAIL_HISTORY_FILTERS_DEFAULT_STATE,
  fetchEmailHistory,
  fetchEmailHistoryFailure,
  fetchEmailHistoryItem,
  fetchEmailHistoryItemSuccess,
  fetchEmailHistorySuccess,
  sortEmailHistory,
} from 'history/emailHistory/emailHistorySlice';
import {
  EmailHistoryFilters,
  EmailHistoryRequestFilters,
  EmailHistoryState,
} from 'history/emailHistory/emailHistoryTypes';
import { not } from 'shared/helpers/boolean';
import { callIfOnline } from 'shared/helpers/network';
import { showErrorToast } from 'shared/helpers/notifications';
import { composeUrl } from 'shared/helpers/url';
import history from 'shared/services/history';
import { UnpackReturnedPromise } from 'shared/types';

export function* fetchEmailHistorySagas(
  action:
    | ReturnType<typeof fetchEmailHistory>
    | ReturnType<typeof sortEmailHistory>
    | ReturnType<typeof changePageOnEmailHistory>
): Saga {
  try {
    const { page, sortType, sortBy, limit, status, period }: EmailHistoryState =
      yield select(({ history }) => history.emailHistory);

    if (not(action.type === fetchEmailHistory.type)) {
      const query = composeUrl<EmailHistoryFilters>({
        defaultFilters: EMAIL_HISTORY_FILTERS_DEFAULT_STATE,
        actualFilters: { period, page, status, sortBy, sortType },
      });

      history.push({ search: query, state: { forceUrlUpdate: true } });
    }

    const contactItem = yield select(getContactItem);
    const statusFilter = handleEmailHistoryQuery(status);

    const filters: EmailHistoryRequestFilters = {
      page,
      sortType,
      sortBy,
      limit,
      username: contactItem.username,
      ...statusFilter,
      ...getPeriodStartAndEnd(period),
    };

    const emailHistoryItems: UnpackReturnedPromise<
      typeof EmailHistoryService.getEmailHistory
    > = yield call<any>(EmailHistoryService.getEmailHistory, filters);
    yield put(
      fetchEmailHistorySuccess({
        emailHistoryItems,
      })
    );
  } catch (e) {
    yield put(fetchEmailHistoryFailure(e.message));
    callIfOnline(() => showErrorToast(e.message));
  }
}

export function* fetchEmailHistoryItemSaga(
  action: ReturnType<typeof fetchEmailHistoryItem>
): Saga {
  try {
    const contactItem = yield select(getContactItem);

    const oneEmailHistoryItem: UnpackReturnedPromise<
      typeof EmailHistoryService.getEmailHistoryItem
    > = yield call(
      EmailHistoryService.getEmailHistoryItem,
      {
        username: contactItem.username,
      },
      action.payload.emailID
    );
    yield put(
      fetchEmailHistoryItemSuccess({
        emailID: action.payload.emailID,
        emailRawHtml: oneEmailHistoryItem,
      })
    );
  } catch (e) {
    yield put(fetchEmailHistoryFailure({ error: e.message }));
    showErrorToast(e.message);
  }
}

export const getEmailHistorySub = () =>
  takeLatest(
    [fetchEmailHistory, sortEmailHistory, changePageOnEmailHistory],
    fetchEmailHistorySagas
  );

export const getOneEmailHistorySub = () =>
  takeLatest([fetchEmailHistoryItem], fetchEmailHistoryItemSaga);

export function* emailHistorySagas() {
  yield all([getEmailHistorySub(), getOneEmailHistorySub()]);
}
