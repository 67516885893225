import { useMemo, useState } from 'react';

import { not } from 'shared/helpers/boolean';

export const useToggle = (initial: boolean | (() => boolean) = false) => {
  const [state, setState] = useState(initial);
  const actions = useMemo(() => {
    return {
      off: () => setState(false),
      on: () => setState(true),
      set: (value: boolean) => setState(value),
      toggle: () => setState(value => not(value)),
    };
  }, []);
  return { value: state, ...actions };
};
