import { useField } from 'formik';
import { isEqual } from 'lodash';

import { Select } from 'shared/components/Select/index';
import {
  SelectOption,
  SelectProps,
} from 'shared/components/Select/SelectTypes';
import { Box } from 'styled-system/components/index';
import { StyledSystemInput } from 'styled-system/lib/types';

interface SelectFieldProps extends SelectProps {
  sx?: StyledSystemInput;
  name: string;
}
export const SelectField = (props: SelectFieldProps) => {
  const { sx, onChange, ...restProps } = props;

  const [field, meta, helpers] = useField({ name: props.name, type: 'select' });

  const handleChange = (option: SelectOption) => {
    helpers.setError(undefined);
    helpers.setValue(option.value);
    onChange?.(option);
  };

  const value = restProps.options?.find(option => {
    return isEqual(option.value, field.value);
  });

  return (
    <Box sx={sx}>
      <Select
        {...field}
        value={value}
        onChange={handleChange}
        error={Boolean(meta.error)}
        message={meta.error}
        {...restProps}
      />
    </Box>
  );
};
