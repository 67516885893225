import React from 'react';

import { Tag } from 'search/search-types';
import { UpdateProfileBody, UserProfile } from 'user/userTypes';

interface HeaderContextProps {
  areAnyFiltersApplied: boolean;
  canShowSaveSearchPopup: boolean;
  isAuthorized: boolean;
  profile: UserProfile;
  updateProfile: (data: UpdateProfileBody, silentUpdate: boolean) => void;
  tags: Tag[];
}

export const HeaderContext = React.createContext<HeaderContextProps | null>(
  null
);
