import { DeepReadonly } from 'utility-types';

import { AgentsAction, AgentsActionType } from 'agents/agentsActions';
import { CollectionsAction } from 'collections/collectionsActions';
import { CollectionsActionType } from 'collections/collectionsActionType';
import { ToggleDismissListingAction } from 'listingDetails/listingDetailsActions';
import { ListingsAction, ListingsActionType } from 'listings/listingsActions';
import {
  LISTINGS_PER_PAGE,
  VIEW_MODE_GALLERY,
} from 'listings/listingsConstants';
import { addCollectionIdInListing } from 'listings/listingsHelpers';
import { Listing, ListingsState } from 'listings/listingsTypes';
import { MapAction, MapActionType } from 'map/mapActions';
import { DrawingStatus, TypesOfMap } from 'map/mapConstants';
import { SavedSearchAction } from 'saved-search/savedSearchActions';
import { addTag, removeAllTags, removeTag } from 'search/search-slice';
import { SortingData, SortOrder } from 'shared/constants/appConstants';

export const VIEWED_LISTINGS_FILTERS_DEFAULT_STATE = {
  sortBy: 'lastviewed',
  sortType: SortOrder.DESC,
  ourListingsFirst: false,
  period: 0,
};

export const LISTINGS_DEFAULT_STATE: ListingsState = {
  clickedMarkerListingId: '-1',
  disableAll: false,
  error: '',
  fetched: false,
  itemCount: 0,
  items: [],
  nearbyListings: [],
  lastViewedItem: -1,
  loading: false,
  page: 1,
  pageCount: 1,
  paginationOptions: { isFirstPage: false, isLastPage: false },
  ourListingsFirst: true,
  selectedItemId: -1,
  sortBy: SortingData.date,
  sortType: SortOrder.DESC,
  viewMode: VIEW_MODE_GALLERY,
  listingsBoundaries: null,
};

export function listingsReducer(
  state: DeepReadonly<ListingsState> = LISTINGS_DEFAULT_STATE,
  action:
    | ListingsAction
    | CollectionsAction
    | SavedSearchAction
    | MapAction
    | AgentsAction
    | ToggleDismissListingAction
): DeepReadonly<ListingsState> {
  if (
    removeTag.match(action) ||
    removeAllTags.match(action) ||
    addTag.match(action)
  ) {
    return { ...state, page: 1 };
  }

  switch (action.type) {
    case ListingsActionType.FETCH_LISTINGS_REQUEST:
      return { ...state, loading: true, error: '' };

    case AgentsActionType.INIT_AGENT_BIO_AND_LIST:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case AgentsActionType.RESET_AGENT_BIO:
      return {
        ...state,
        items: [],
        loading: true,
        error: '',
        page: 1,
        sortBy: 'price',
      };

    case CollectionsActionType.SET_LOADED_COLLECTION_FAIL:
      return {
        ...state,
        fetched: true,
      };

    case ListingsActionType.FETCH_LISTINGS_FAIL:
      return {
        ...state,
        itemCount: 0,
        loading: false,
        fetched: true,
        error: action.error,
      };

    case ListingsActionType.FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        items: action.listings.listings,
        itemCount: action.listings.total,
        nearbyListings: action.listings.nearbyListings || [],
        pageCount: action.listings.pageCount || 0,
        loading: false,
        fetched: true,
        history: action.listings.history || 0,
        listingsBoundaries: action.listings.boundaries
          ? {
              west: action.listings.boundaries.longitude?.min,
              east: action.listings.boundaries.longitude?.max,
              north: action.listings.boundaries.latitude?.max,
              south: action.listings.boundaries.latitude?.min,
            }
          : null,
      };

    case ListingsActionType.HIGHLIGHT_LISTINGS_ITEM:
      return { ...state, selectedItemId: action.itemIndex, lastViewedItem: -1 };

    case ListingsActionType.SET_LAST_VIEWED_ITEM:
      return { ...state, lastViewedItem: action.listingId };

    case ListingsActionType.SET_CLICKED_MARKER_ID:
      return { ...state, clickedMarkerListingId: action.listingId };

    case ListingsActionType.CHANGE_VIEW_MODE:
      return { ...state, viewMode: action.viewMode };

    case ListingsActionType.CHANGE_LISTINGS_PAGE:
      return { ...state, page: action.page };

    case ListingsActionType.SEARCH_LISTINGS:
      return { ...state, location: action.location, page: 1 };

    case ListingsActionType.SORT_LISTINGS:
      return {
        ...state,
        ...action.sort,
        page: 1,
      };

    case ListingsActionType.FETCH_VIEWED_LISTINGS:
      return {
        ...state,
        sortBy: action.sortBy || state.sortBy,
      };

    case MapActionType.HANDLE_CLUSTER_CLICK:
      return {
        ...state,
        page: 1,
      };

    case CollectionsActionType.SAVE_LISTING_TO_COLLECTION_SUCCESS:
    case CollectionsActionType.SAVE_COLLECTION_SUCCESS:
      return {
        ...state,
        items: state.items.map(listing =>
          addCollectionIdInListing({
            listing: listing as Listing,
            collectionID: action.id,
            actionListingID: action.listingid,
          })
        ),
        // eslint-disable-next-line sonarjs/no-identical-functions
        nearbyListings: state.nearbyListings.map(listing =>
          addCollectionIdInListing({
            listing: listing as Listing,
            collectionID: action.id,
            actionListingID: action.listingid,
          })
        ),
      };

    case CollectionsActionType.UPDATE_COLLECTION_ITEMS:
      return {
        ...state,
        items: action.items,
      };

    case CollectionsActionType.REMOVE_COLLECTION_ITEM: {
      const newItemCount = state.itemCount - 1;
      const minCountPerPage = (state.page - 1) * LISTINGS_PER_PAGE + 1;
      return {
        ...state,
        items: state.items.filter(({ listingid }) => listingid !== action.id),
        itemCount: newItemCount,
        page:
          newItemCount > 0 && newItemCount < minCountPerPage
            ? state.page - 1
            : state.page,
      };
    }

    case MapActionType.CHANGE_MAP_DRAWING_STATUS: {
      const shouldDisable: boolean =
        action.drawingStatus === DrawingStatus.DrawingInProcess ||
        action.drawingStatus === DrawingStatus.InitDrawing;
      return {
        ...state,
        disableAll: shouldDisable,
      };
    }

    case MapActionType.ADD_MAP_DRAWING_POLYGON: {
      return { ...state, page: 1 };
    }

    case ListingsActionType.MERGE_LISTINGS:
      return {
        ...state,
        ...action.listings,
      };

    case ListingsActionType.MERGE_LISTINGS_WITH_DEFAULT:
      return {
        ...LISTINGS_DEFAULT_STATE,
        ...action.listings,
      };

    case ListingsActionType.CHANGE_OUR_LISTINGS_FIRST:
      return {
        ...state,
        ourListingsFirst: action.ourListingsFirst,
        page: 1,
      };

    case ListingsActionType.SWITCH_OFF_OUR_LISTINGS_FIRST:
      return {
        ...state,
        ourListingsFirst: false,
        page: 1,
      };

    case MapActionType.HANDLE_MAP_CHANGE: {
      const page = action.mapType === TypesOfMap.Listings ? 1 : state.page;
      return {
        ...state,
        page,
      };
    }

    case ListingsActionType.SET_IS_FIRST_LISTING_PAGE: {
      return {
        ...state,
        paginationOptions: {
          ...state.paginationOptions,
          isFirstPage: action.isFirstPage,
        },
      };
    }

    case ListingsActionType.SET_IS_LAST_LISTING_PAGE: {
      return {
        ...state,
        paginationOptions: {
          ...state.paginationOptions,
          isLastPage: action.isLastPage,
        },
      };
    }

    case ListingsActionType.CLEAR_LISTINGS_ITEMS: {
      return {
        ...state,
        items: [],
        nearbyListings: [],
        fetched: false,
        page: 1,
      };
    }

    case ListingsActionType.ADD_LISTING_RECOMMENDATION: {
      const { listingIds, owner, user } = action;
      return {
        ...state,
        items: state.items.map(listing =>
          listingIds.includes(listing.listingid)
            ? {
                ...listing,
                recommendations: [
                  ...(listing.recommendations || []),
                  { user, owner },
                ],
              }
            : listing
        ),
        nearbyListings: state.nearbyListings.map(listing =>
          listingIds.includes(listing.listingid)
            ? {
                ...listing,
                recommendations: [
                  ...(listing.recommendations || []),
                  { user, owner },
                ],
              }
            : listing
        ),
      };
    }

    case ListingsActionType.HANDLE_LISTINGS_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case ListingsActionType.CHANGE_LISTINGS_SORTBY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }

    case AgentsActionType.CHANGE_AGENT_BIO_LISTINGS_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }

    default:
      return state;
  }
}
