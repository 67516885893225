import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { PermissionRoles } from 'permissions/permissionsConstants';
import { getLoginStatus } from 'permissions/permissionsSelectors';
import { useModalContext } from 'shared/components/Modal/modalContext';
import { SIGN_UP_URL_PARAMETER } from 'shared/constants/appConstants';
import { notEmpty } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { AuthType, showAuthModal } from 'user/helpers/showAuthModal';

export function PrePopulatedSignUpForm(props: { children: React.ReactNode }) {
  const location = useLocation();

  const loginStatus = useSelector(getLoginStatus);

  const modalCtx = useModalContext();

  useEffect(() => {
    const signUpEmail = queryString.parse(location.search)[
      SIGN_UP_URL_PARAMETER
    ] as string;

    if (loginStatus === PermissionRoles.Guest && notEmpty(signUpEmail)) {
      showAuthModal(AuthType.SignUp, modalCtx, {
        userParams: {
          email: signUpEmail,
          firstName: '',
          lastName: '',
          phone: '',
        },
      })();
    }
  }, [loginStatus]);

  return <>{props.children}</>;
}
