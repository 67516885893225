import { variant } from '@styled-system/variant';
import styled from 'styled-components/macro';

import { scss } from 'styled-system/css';
import { createShouldForwardProp } from 'styled-system/lib/should-forward-prop';
import { space, SpaceProps } from 'styled-system/lib/space';
import { sx, SxProps } from 'styled-system/lib/sx';

const imageVariants = variant({
  variants: {
    default: {},
  },
});

const imagePropNames = [...space.propNames, 'sx', 'variant'];

export const imageConfig = {
  shouldForwardProp: createShouldForwardProp(imagePropNames),
};

export type ImageProps = SpaceProps & {
  variant?: 'default';
} & SxProps;

export const Image = styled.img.withConfig<ImageProps>(imageConfig)(
  scss({ maxWidth: '100%', height: 'auto' }),
  imageVariants,
  sx,
  space
);

Image.displayName = 'Image';
