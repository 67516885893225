import { select, take } from 'redux-saga/effects';

import { isEmpty } from 'shared/helpers/boolean';
import { UserActionType } from 'user/userActions';
import { getUserLocation } from 'user/userSelectors';
import { UserLocation } from 'user/userTypes';

export function* waitForUserLocation(): Saga<UserLocation> {
  const userLocation = yield select(getUserLocation);

  if (isEmpty(userLocation)) {
    yield take(UserActionType.SET_USER_LOCATION);
  }
  return yield select(getUserLocation);
}
