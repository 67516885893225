import {
  agentsDescription,
  description,
  heading,
} from 'shared/config/configConstants';

export const austinSeo = {
  envName: 'Austin',
  listings: {
    hiddenTitle: 'Austin Home Search',
    pageTitle: 'Search Austin Homes for Sale | Austin Home Listings',
    leasePageTitle: 'Search Austin Homes for Lease | Austin Home Listings',
    description: 'Our Austin MLS Listings are updated every 5 minutes.',
    title: 'Austin Home Listings',
  },
  agent: {
    location: 'Austin',
  },
  home: {
    h1: 'We Love Austin Real Estate',
  },
  savedSearches: {
    description,
  },
  collections: {
    description,
  },
  accountSettings: {
    description,
  },
  agents: {
    title: 'Top Realtors in Austin TX | Austin TX Real Estate Agent Directory',
    hiddenTitle: heading,
    description: agentsDescription,
    h1: heading,
  },
  defaultAppSeo: {
    title: 'Realty Austin',
    description:
      'Search all Austin Home Listings, learn about Austin and research Austin neighborhoods.',
  },
};

export const sanAntonioSeo = {
  envName: 'San Antonio',
  listings: {
    hiddenTitle: 'San Antonio Home Search',
    pageTitle: 'San Antonio Home Search | Realty San Antonio',
    leasePageTitle:
      'Search San Antonio Homes for Lease | San Antonio Home Listings',
    description: 'Our San Antonio MLS Listings are updated every 5 minutes.',
    title: 'San Antonio Home Listings',
  },
  agent: {
    location: 'San Antonio',
  },
  home: {
    h1: 'San Antonio Real Estate',
  },
  savedSearches: {
    description:
      'Looking for a better San Antonio Home Search? Use the #1 San Antonio real estate website to find homes for sale by Map, Neighborhood, School Boundary, or Builder',
  },
  collections: {
    description:
      'Looking for a better San Antonio Home Search? Use the #1 San Antonio real estate website to find homes for sale by Map, Neighborhood, School Boundary, or Builder',
  },
  accountSettings: {
    description:
      'Looking for a better San Antonio Home Search? Use the #1 San Antonio real estate website to find homes for sale by Map, Neighborhood, School Boundary, or Builder.',
  },
  agents: {
    title:
      'Top Realtors in San Antonio TX | San Antonio TX Real Estate Agent Directory',
    hiddenTitle: 'Realty San Antonio Agent Directory',
    description: agentsDescription,
    h1: 'Realty San Antonio Agent Directory',
  },
  defaultAppSeo: {
    title: 'Realty San Antonio',
    description:
      'Search all San Antonio Home Listings, learn about San Antonio and research San Antonio neighborhoods.',
  },
};
