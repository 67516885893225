import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactResizeDetector from 'react-resize-detector';
import { matchPath } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { getListingsUrl } from 'listingDetails/listingDetailsHelpers';
import { redirectToListingDetailsIfNeeded } from 'listings/listingsHelpers';
import { getListingsTagUrl } from 'listings/listingUrlParser';
import { MapState } from 'map/mapTypes';
import { Tag } from 'search/search-types';
import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import SearchBarDropdownMenu from 'shared/components/SearchBar/components/SearchBarDropdownMenu';
import SearchBarTag from 'shared/components/SearchBarTag';
import { SearchBarWithTagsConnectProps } from 'shared/components/SearchBarWithTags';
import {
  getDividerIsShown,
  SEARCH_BAR_TAG_CLASS_NAME,
  SEARCH_INPUT_CLASS_NAME,
} from 'shared/components/SearchBarWithTags/SearchBarWithTagsHelper';
import SearchBarWithTagsStyled from 'shared/components/SearchBarWithTags/SearchBarWithTagsStyled';
import {
  ButtonsCode,
  LISTINGS_SEARCH_BAR_PLACEHOLDER,
} from 'shared/constants/appConstants';
import {
  PAGES_WITH_LISTINGS_AND_SEARCH_BAR,
  RoutePath,
} from 'shared/constants/routesConstants';
import { isEmpty, not, notEmpty } from 'shared/helpers/boolean';
import { Status } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';
import { useTrackedRef } from 'shared/hooks/useTrackedRef';
import { ReactComponent as IconSearch } from 'shared/icons/shared/24x24/search.svg';
import { PlaceType } from 'shared/types/placesAndPolygons';
import { useIsMobile } from 'styled-system/responsive';
import { UserLocation } from 'user/userTypes';

export enum SearchBarWithTagsTestIds {
  showMoreButton = 'showMoreButton',
}

export const MOBILE_PLACEHOLDER = 'City, Neighborhood, Zip, School';

export type SearchBarWithTagsProps = SearchBarWithTagsConnectProps & {
  className?: string;
  disabled?: boolean;
  dropdownMenuHeight?: number;
  dropdownShifted?: boolean;
  mapType: keyof MapState;
  placeholder?: string;
} & RouteComponentProps;

function SearchBarWithTags(props: SearchBarWithTagsProps) {
  const isMobile = useIsMobile();
  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();
  const isLeasePage = useIsLeasePage();

  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(true);
  const [hiddenTags, setHiddenTags] = useState<number>(0);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isTagsMultiLine, setIsTagsMultiline] = useState<boolean>(false);
  const [resizeContainerWidth, setResizeContainerWidth] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');

  const inputContainerRef = useRef<HTMLDivElement>();
  const clearBtnRef = useRef<HTMLButtonElement>();
  const inputRef = useRef<HTMLInputElement>();
  const resizeContainerRef = useRef<HTMLDivElement>();
  const tagsWrapperRef = useRef<HTMLDivElement>();
  const [dropdownMenuRef, dropdownMenuNode] = useTrackedRef<HTMLDivElement>();

  const redirectPathname = getListingsUrl({
    agentMode: props.agentMode,
    contactId: props.contactItem?.username,
    fromLeased: isLeasePage,
  });

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  useEffect(() => {
    if (tagsWrapperRef.current) {
      setHiddenTags(getHiddenTags());
    }
  }, [props.tags, resizeContainerWidth]);

  useEffect(() => {
    if (props.autocomplete.status === Status.succeeded && dropdownIsOpen) {
      const length = props.autocomplete.items.reduce(
        (accumulator, { items }) => accumulator + items.length,
        0
      );
      const textToAnnounce =
        length === 0
          ? 'No results found'
          : length === 1
          ? '1 result found'
          : `${length} results found`;
      ariaLiveAnnouncerCtx.announce(textToAnnounce);
    }
  }, [props.autocomplete, dropdownIsOpen]);

  function closeMenuAndClearInput() {
    setSearchValue('');
    setDropdownIsOpen(false);
    setIsFocused(false);
  }

  function getHiddenTags(): number {
    const container = tagsWrapperRef.current;

    if (not(container)) {
      return 0;
    }

    const containerWidth = container.getBoundingClientRect().width;
    const tags = container.querySelectorAll(`.${SEARCH_BAR_TAG_CLASS_NAME}`);

    const initialValue = {
      hiddenTags: 0,
      tagsWidth: 0,
    };
    const result = Array.from(tags).reduce((accumulator, tag) => {
      const tagWidth = tag.getBoundingClientRect().width;
      accumulator.tagsWidth = accumulator.tagsWidth + tagWidth;
      if (accumulator.tagsWidth > containerWidth) {
        accumulator.hiddenTags = accumulator.hiddenTags + 1;
      }
      return accumulator;
    }, initialValue);

    return result.hiddenTags === tags.length
      ? result.hiddenTags - 1
      : result.hiddenTags;
  }

  function isInputHidden() {
    const inputElements = resizeContainerRef.current.querySelectorAll(
      `.${SEARCH_INPUT_CLASS_NAME}`
    );

    const searchInput = inputElements[0];

    if (searchInput !== null) {
      return (
        (searchInput as HTMLElement).offsetWidth === 0 ||
        (searchInput as HTMLElement).offsetHeight === 0
      );
    }

    return false;
  }

  function getUserLocation(): UserLocation | null {
    const isListingsPage = matchPath(props.location.pathname, {
      path: RoutePath.LISTINGS,
    });

    return isListingsPage && isEmpty(props.tags) ? props.userLocation : null;
  }

  function handleDropdownClose() {
    inputRef?.current?.blur();
    setIsFocused(false);
    setIsTagsMultiline(false);
    setDropdownIsOpen(false);
  }

  function handleItemClick(tag: Tag): void {
    const wasRedirected = redirectToListingDetailsIfNeeded(
      tag,
      props.agentMode,
      props.contactItem
    );

    if (wasRedirected) {
      closeMenuAndClearInput();
      return;
    }

    if (not(PAGES_WITH_LISTINGS_AND_SEARCH_BAR.includes(props.match.path))) {
      props.history.push(getListingsTagUrl(tag, redirectPathname));
      return void 0;
    }

    if (
      props.agentMode &&
      props.contactItem &&
      tag.type === PlaceType.MlsCode
    ) {
      tag.type = PlaceType.Address;
    }

    if (Boolean(tag.value)) {
      props.addTag({ tag });
    }

    closeMenuAndClearInput();
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const trimmedValue = value.trim();

    if (not(trimmedValue)) {
      setSearchValue('');
      ariaLiveAnnouncerCtx.announce(LISTINGS_SEARCH_BAR_PLACEHOLDER);
      return false;
    }

    props.fetchAutocomplete({ query: trimmedValue });
    setDropdownIsOpen(true);
    setSearchValue(value);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === ButtonsCode.Enter) {
      if (isEmpty(searchValue)) {
        props.searchListings('');
        return;
      }

      if ('items' in props.autocomplete && notEmpty(props.autocomplete.items)) {
        const [firstOption] = props.autocomplete.items.flatMap(
          ({ items }) => items
        );
        const unsafeTag = firstOption as Tag;
        const wasRedirected = redirectToListingDetailsIfNeeded(
          unsafeTag,
          props.agentMode,
          props.contactItem
        );

        if (wasRedirected) {
          closeMenuAndClearInput();
          return;
        }

        if (unsafeTag) {
          if (
            not(PAGES_WITH_LISTINGS_AND_SEARCH_BAR.includes(props.match.path))
          ) {
            props.history.push(getListingsTagUrl(unsafeTag, redirectPathname));
            return void 0;
          }
          props.addTag({ tag: unsafeTag });
          setSearchValue('');
          handleDropdownClose();
        }
      }
    }
    if (event.key === ButtonsCode.Esc) {
      handleDropdownClose();
    }
  }

  function onResizeHandler(width: number) {
    setResizeContainerWidth(width);
  }

  function focusInput() {
    inputRef.current.focus();
    setIsFocused(true);
    setDropdownIsOpen(true);
  }

  function openSearchBar() {
    if (getHiddenTags() > 0 || isInputHidden()) {
      setIsTagsMultiline(true);
    }
    focusInput();
  }

  function blurInput() {
    inputRef.current.blur();
    setIsFocused(false);
  }

  function clearAllTags() {
    props.removeAllTags({ mapType: props.mapType });
    setSearchValue('');
    setIsFocused(false);
  }

  function checkCloseOrOpenSecondLine() {
    const inputHidden = isInputHidden();
    const hiddenTags = getHiddenTags();
    if (hiddenTags > 0 || inputHidden) {
      setIsTagsMultiline(true);
      focusInput();
    } else {
      blurInput();
      setIsTagsMultiline(false);
    }
  }

  function onToggleSearchTag() {
    props.toggleSearchTag();
    setTimeout(() => checkCloseOrOpenSecondLine(), 0);
  }

  function onRemoveTag(tag: Tag) {
    return (e: React.MouseEvent) => {
      props.removeTag({ tag });
      e.stopPropagation();
      const hiddenTags = getHiddenTags();
      if (hiddenTags > 0) {
        setIsTagsMultiline(true);
        if (isFocused) {
          focusInput();
        }
      } else {
        blurInput();
        setIsTagsMultiline(false);
      }
    };
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    if (
      not(dropdownMenuNode.contains(e.relatedTarget as Node)) &&
      not(clearBtnRef?.current?.contains(e.relatedTarget as Node))
    ) {
      handleDropdownClose();
    }
  }

  const clearIsShown =
    (isFocused && notEmpty(searchValue)) || (isFocused && notEmpty(props.tags));

  const shouldShowSecondLine = isTagsMultiLine && isFocused;
  const shouldShowShowMore =
    notEmpty(props.tags) && not(isFocused) && Boolean(hiddenTags);

  return (
    <SearchBarWithTagsStyled.Wrapper
      isFocused={isFocused}
      onSubmit={e => e.preventDefault()}
    >
      <OutsideClickHandler onOutsideClick={handleDropdownClose}>
        <SearchBarWithTagsStyled.SearchBarInput
          ref={inputContainerRef}
          shouldShowSecondLine={shouldShowSecondLine}
        >
          {not(props.disabled) && (
            <SearchBarWithTagsStyled.SearchButton
              icon={<IconSearch className="icon-search" />}
              onClick={openSearchBar}
              aria-label="Open search"
            />
          )}

          <SearchBarWithTagsStyled.ReactResizeDetectorWrapper
            hasTags={isTagsMultiLine}
            isFocused={isFocused}
            onClick={openSearchBar}
            ref={resizeContainerRef}
          >
            <ReactResizeDetector
              handleWidth
              onResize={onResizeHandler}
              refreshMode="throttle"
            />

            {notEmpty(props.tags) && (
              <SearchBarWithTagsStyled.TagsWrapper
                isFocused={isFocused}
                ref={tagsWrapperRef}
                shouldShowSecondLine={shouldShowSecondLine}
              >
                {props.tags.map((tagItem, index) => (
                  <SearchBarTag
                    className={SEARCH_BAR_TAG_CLASS_NAME}
                    clickHandler={openSearchBar}
                    key={index}
                    label={tagItem.label}
                    onCloseTag={onRemoveTag(tagItem)}
                    showCross={not(props.disabled)}
                    showDivider={getDividerIsShown({
                      tags: props.tags,
                      tagItem,
                      index,
                    })}
                    tag={tagItem}
                    toggleSearchTag={onToggleSearchTag}
                  />
                ))}
              </SearchBarWithTagsStyled.TagsWrapper>
            )}

            {shouldShowShowMore && (
              <SearchBarWithTagsStyled.ShowMoreButton
                data-testid={SearchBarWithTagsTestIds.showMoreButton}
                onClick={openSearchBar}
              >
                {`+ ${hiddenTags} more`}
              </SearchBarWithTagsStyled.ShowMoreButton>
            )}

            <SearchBarWithTagsStyled.SearchInput
              onBlur={handleBlur}
              autoComplete="off"
              className={SEARCH_INPUT_CLASS_NAME}
              disabled={props.disabled}
              hide={shouldShowShowMore}
              isFocused={isFocused}
              onChange={handleInputChange}
              onClick={openSearchBar}
              onKeyDown={handleKeyDown}
              onFocus={focusInput}
              placeholder={isMobile ? MOBILE_PLACEHOLDER : props.placeholder}
              ref={inputRef}
              shouldShowSecondLine={shouldShowSecondLine}
              value={searchValue}
            />
          </SearchBarWithTagsStyled.ReactResizeDetectorWrapper>

          {clearIsShown && not(props.disabled) && (
            <SearchBarWithTagsStyled.ClearButton
              onClick={clearAllTags}
              ref={clearBtnRef}
              role="button"
              aria-label="Clear search"
            >
              Clear
            </SearchBarWithTagsStyled.ClearButton>
          )}

          {not(props.disabled) && (
            <SearchBarDropdownMenu
              dropdownMenuNode={dropdownMenuNode}
              dropdownMenuRef={dropdownMenuRef}
              closeMenu={handleDropdownClose}
              dropdownMenuHeight={props.dropdownMenuHeight}
              dropdownShifted={props.dropdownShifted}
              isOpen={dropdownIsOpen && isFocused}
              items={
                'items' in props.autocomplete ? props.autocomplete.items : []
              }
              loading={props.autocomplete.status === Status.loading}
              onItemClick={handleItemClick}
              searchValue={searchValue}
              userLocation={getUserLocation()}
            />
          )}
        </SearchBarWithTagsStyled.SearchBarInput>
      </OutsideClickHandler>
    </SearchBarWithTagsStyled.Wrapper>
  );
}

SearchBarWithTags.defaultProps = {
  placeholderText: MOBILE_PLACEHOLDER,
  className: '',
  disabled: false,
};

export default SearchBarWithTags;
