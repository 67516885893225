import React from 'react';
import ReactGoogleMapsLoader from 'react-google-maps-loader';

/*global google*/
import { getEnv } from 'env-config';
import { GoogleMapApiType } from 'shared/types/placesAndPolygons';

export interface GoogleMapLoaderProps {
  children?: React.ReactNode;
  render?: (maps: GoogleMapApiType) => React.ReactNode;
}

export const GoogleMapsApiContext =
  React.createContext<GoogleMapApiType | null>(null);

export const GoogleApiLoader = (props: GoogleMapLoaderProps) => {
  const { children, render } = props;

  const renderChildren = (maps: GoogleMapApiType) => {
    if (children) {
      return children;
    }
    if (render) {
      return render(maps);
    }
    return null;
  };

  return (
    <ReactGoogleMapsLoader
      params={{
        key: getEnv().mapKey,
        libraries: 'places',
      }}
      render={(maps, error) => {
        // test value for testing env
        if (process.env.NODE_ENV === 'test') {
          return (
            <GoogleMapsApiContext.Provider value={google.maps}>
              {renderChildren(google.maps)}
            </GoogleMapsApiContext.Provider>
          );
        }

        if (maps && !error) {
          return (
            <GoogleMapsApiContext.Provider value={maps}>
              {renderChildren(maps)}
            </GoogleMapsApiContext.Provider>
          );
        }

        if (error) {
          throw new Error(error);
        }

        return '';
      }}
    />
  );
};

export function useGoogleMapsAPI() {
  const ctx = React.useContext(GoogleMapsApiContext);
  if (ctx === null) {
    throw new Error(
      'useGoogleMapsAPI hook should only be used within GoogleApiLoader'
    );
  }
  return ctx;
}
