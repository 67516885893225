import { isPlainObject } from 'lodash';

import { not } from 'shared/helpers/boolean';
import { EmptyObject, Nullable, WithRequired } from 'shared/types';
import { unsafeCoerce } from 'shared/utils/types';

type StringRecord = Record<string, unknown>;

// eslint-disable-next-line @typescript-eslint/ban-types
type unsafe_object = object;

/**
 * Typed Object.keys alternative
 */
export const keysOf = <T extends EmptyObject>(record: T): Array<keyof T> => {
  return Object.keys(record) as Array<keyof T>;
};

/**
 * Creates a shallow copy of an input with lowercased keys.
 */
export function mapCamelCaseToLowerCase<T>(input: Record<string, T>) {
  const output: Record<string, T> = {};
  for (const key of keysOf(input)) {
    const value = input[key];
    const lowerCaseKey = key.toLowerCase();
    output[lowerCaseKey] = value;
  }
  return output;
}

export function getNestedObjKeys<T extends StringRecord>(
  obj: T,
  prefix = ''
): string[] {
  return Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res;
    }

    if (isPlainObject(obj[el])) {
      return [
        ...res,
        ...getNestedObjKeys(obj[el] as StringRecord, prefix + el + '.'),
      ];
    }

    return [...res, prefix + el];
  }, []);
}

export function pick<T extends unsafe_object, K extends keyof T>(
  input: T,
  keys: K[]
): Pick<T, K>;
export function pick<T extends unsafe_object, K extends keyof T>(
  input: Nullable<T>,
  keys: K[]
): Partial<Pick<T, K>>;
export function pick<T extends unsafe_object, K extends keyof T>(
  input: Nullable<T>,
  keys: K[]
): Partial<Pick<T, K>> {
  if (not(input)) {
    return {};
  }
  const picked: Pick<T, K> = {} as any;
  for (const key of keys) {
    if (key in input) {
      picked[key] = input[key];
    }
  }
  return picked;
}

export function unsafePick<T extends unsafe_object>(input: T, keys: string[]) {
  const output: Partial<T> = pick(input, unsafeCoerce(keys));
  return output;
}

/**
 * @example
 * for partial type:
 * pickBy<T>(obj) - returns Partial<T>;
 *
 * pickBy with required fields:
 * interface Exm {
 *   a: string,
 *   b: string,
 * }
 * pickBy<Exm, 'a'>(obj) - returns object with the next type:
 * {
 *   a: string,
 *   b: string | undefined
 * }
 */

export function pickBy<T extends EmptyObject>(
  input: T,
  validator?: (el: T[keyof T]) => boolean
): Partial<T>;

export function pickBy<T extends EmptyObject, K extends keyof T>(
  input: T,
  validator?: (el: T[keyof T]) => boolean
): WithRequired<T, K>;

export function pickBy<T extends EmptyObject, K extends keyof T>(
  input: T,
  validator: (el: T[keyof T]) => boolean = Boolean
): WithRequired<T, K> {
  const res = {} as WithRequired<T, K>;

  const keys = keysOf(input);
  keys.forEach(key => {
    if (validator(input[key])) {
      res[key as K] = unsafeCoerce(input[key]);
    }
  });

  return res;
}
