import { connect } from 'react-redux';

import { RootState } from 'rootReducer';
import UserLocation from 'shared/components/UserLocation/UserLocation';
import { ConnectProps } from 'shared/types';
import { setUserLocation } from 'user/userActions';
import { getUserLocation } from 'user/userSelectors';

const mapStateToProps = (state: RootState) => ({
  userLocation: getUserLocation(state),
});

const mapDispatchToProps = {
  setUserLocation,
};

export type UserLocationConnectedProps = ConnectProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(UserLocation);
