import { get, pick } from 'lodash';

import {
  CUSTOM_TAG_LABEL,
  UNDISCLOSED_ADDRESS,
} from 'listings/listingsConstants';
import { TagType } from 'search/search-types';
import {
  BasePlace,
  Place,
  PlacePolygonType,
  PlaceType,
  StreetAddress,
} from 'shared/types/placesAndPolygons';

const basicPlaceFormat = ({ city, stateorprovince }: BasePlace): string => {
  return [city, stateorprovince].filter(Boolean).join(', ');
};

export const getAddress = ({
  streetnumber,
  streetdirprefix,
  streetname,
  streetsuffix,
  streetdirsuffix,
}: StreetAddress): string => {
  return (
    [streetnumber, streetdirprefix, streetname, streetsuffix, streetdirsuffix]
      .filter(Boolean)
      .join(' ') || UNDISCLOSED_ADDRESS
  );
};

// TODO: Maybe refactor this monster to class
const _resolvePlace = (place: Place) => {
  switch (place.type) {
    case PlaceType.County:
      return `${place.value.countyorparish}, ${place.value.stateorprovince}`;

    case PlaceType.City:
      return basicPlaceFormat(place.value);

    case PlaceType.Street:
      return (
        place.value.suggestAddress ||
        `${place.value.streetname} ${
          place.value.streetsuffix
        }, ${basicPlaceFormat(place.value)}`
      );

    case PlaceType.Address:
      return (
        place.value.suggestAddress ||
        `${getAddress(place.value)}, ${basicPlaceFormat(place.value)}`
      );

    case PlaceType.School:
      return `${place.value.school}, ${basicPlaceFormat(place.value)}`;

    case PlaceType.SchoolDistrict:
      return `${place.value.schooldistrict}, ${basicPlaceFormat(place.value)}`;

    case PlaceType.PostalCode:
      return `${place.value.postalcode}`;

    case PlaceType.Subdivision:
      return `${place.value.subdivisionname}, ${basicPlaceFormat(place.value)}`;

    case PlaceType.MlsCode:
      return `${getAddress(place.value)}, ${basicPlaceFormat(place.value)}`;

    case PlaceType.MlsArea:
      return `${place.value.mlsareamajor}`;

    case PlacePolygonType.County:
      return {
        type: PlaceType.County,
        label: place.value.properties.label,
        value: place.value.properties.label,
        id: place.value.id,
        url: place.value.url,
        filter: {
          stateorprovince: place.value.properties.state,
          county: place.value.name,
        },
      };

    case PlacePolygonType.City:
      return {
        type: PlaceType.City,
        label: place.value.properties.label,
        value: place.value.properties.label,
        id: place.value.id,
        url: place.value.url,
        filter: {
          stateorprovince: place.value.properties.state,
          city: place.value.name,
        },
      };

    case PlacePolygonType.School: {
      const address = pick(place.value.properties.address, ['city', 'state']);
      const schoolDistrict = get(place.value.properties.schoolDistrict, 'name');
      const city = address.city || '';
      const state = address.state || '';

      return {
        type: PlaceType.School,
        label: place.value.properties.label || place.value.name,
        value: place.value.properties.label || place.value.name,
        id: place.value.id,
        url: place.value.url,
        filter: {
          city,
          stateorprovince: state,
          school: place.value.name,
          schoolDistrict,
        },
      };
    }

    case PlacePolygonType.SchoolDistrict:
      const { id, properties, name } = place.value;
      const { state, city, zip } = properties;

      return {
        type: PlaceType.SchoolDistrict,
        label: place.value.properties.label,
        value: place.value.properties.label,
        id,
        url: place.value.url,
        filter: {
          city,
          stateorprovince: state,
          schooldistrict: name,
          zip,
        },
      };

    case PlacePolygonType.Subdivision:
      return {
        type: PlaceType.Subdivision,
        label: place.value.properties.label,
        value: place.value.properties.label,
        id: place.value.id,
        url: place.value.url,
        filter: {
          county: place.value.properties.county,
          stateorprovince: place.value.properties.state,
          subdivision: place.value.name,
          city: place.value.properties.city,
        },
      };

    case PlacePolygonType.PostalCode:
      return {
        type: PlaceType.PostalCode,
        label: place.value.properties.label,
        value: place.value.properties.label,
        id: place.value.id,
        url: place.value.url,
        filter: {
          county: place.value.properties.county,
          stateorprovince: place.value.properties.state,
          postalcode: place.value.name,
        },
      };

    case PlacePolygonType.MlsArea:
      return {
        type: PlaceType.MlsArea,
        label: place.value.properties.label,
        value: place.value.properties.label,
        id: place.value.id,
        url: place.value.url,
        filter: {
          mlsarea: place.value.name,
        },
      };

    case PlacePolygonType.CustomPolygon:
      return {
        type: PlaceType.Custom,
        label: CUSTOM_TAG_LABEL,
        id: place.value.id,
      };
    default:
      return '';
  }
};

export const resolvePlace = (place: { type: TagType; value?: any }) => {
  const resolvedPlace = _resolvePlace(place as Place);

  if (typeof resolvedPlace === 'string') {
    return { type: place.type, label: resolvedPlace, value: resolvedPlace };
  }

  return resolvedPlace;
};
