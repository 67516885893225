import { DeepReadonly } from 'utility-types';

import {
  PermissionsActions,
  PermissionsActionType,
} from 'permissions/permissionsActions';
import {
  FromLinkStatus,
  PermissionRoles,
} from 'permissions/permissionsConstants';
import { PermissionsState } from 'permissions/permissionsTypes';

export const PERMISSION_DEFAULT_STATE: PermissionsState = {
  loginStatus: PermissionRoles.NotInitialized,
  fromLinkStatus: FromLinkStatus.Empty,
};

export function permissionsReducer(
  state: DeepReadonly<PermissionsState> = PERMISSION_DEFAULT_STATE,
  action: PermissionsActions
): DeepReadonly<PermissionsState> {
  switch (action.type) {
    case PermissionsActionType.CHANGE_LOGIN_STATUS: {
      return {
        ...state,
        loginStatus: action.loginStatus,
      };
    }
    case PermissionsActionType.CHANGE_FROM_LINK_STATUS: {
      return {
        ...state,
        fromLinkStatus: action.fromLinkStatus,
      };
    }
    case PermissionsActionType.CLEAR_FROM_LINK_STATUS: {
      return {
        ...state,
        fromLinkStatus: PERMISSION_DEFAULT_STATE.fromLinkStatus,
      };
    }
    default:
      return state;
  }
}
