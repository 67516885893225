export const AGENTS_PER_PAGE = 20;
export const LISTINGS_PER_BIO_PAGE = 9;
export const AGENT_SEARCH_PLACEHOLDER = 'Search by First Name or Last Name';
export const FROZEN_AGENT_PROFILEID = '00e1W0000014L0PQAU';
export const AGENT_ORS_DESIGNATION = 'Online Relocation Specialist';

export enum AgentTabNavItems {
  Bio = 'Bio',
  Reviews = 'Reviews',
  Video = 'Video',
  AwardsAndRecognition = 'Awards and Recognition',
  Designations = 'Designations',
}

export enum UserAccountType {
  IndividualAgent = 'Individual Agent',
  SystemAccount = 'System Account',
  TeamMember = 'Team Member',
  TeamAccount = 'Team Account',
  StaffAccount = 'Staff Account',
  Coordinator = 'Coordinator',
  LSA = 'LSA',
}

export enum UserTeamType {
  Separate = 'Separate',
  Together = 'Together',
}

export enum BuyerOrSellerTitle {
  SellerRepresented = 'Seller Represented',
  BuyerRepresented = 'Buyer Represented',
  Intermediary = 'Intermediary',
}

export enum AgentQueryKeys {
  Page = 'page',
  LanguagesFilter = 'languagesFilter',
  SortBy = 'sortBy',
  SortType = 'sortType',
}

export enum LeadSource {
  Web = 'web',
  OrsAgent = 'orsAgent',
}

export const sfIdRegExp = /([a-zA-Z0-9]{15}$|[a-zA-Z0-9]{18}$)/;

/** Language render in into colums. If add language, insert it into the right position. */
export const AGENTS_LANGUAGES = [
  { label: 'American Sign Language', value: 'american-sign-language' },
  { label: 'Italian', value: 'italian' },
  { label: 'Arabic', value: 'arabic' },
  { label: 'Japanese', value: 'japanese' },
  { label: 'Cantonese', value: 'cantonese' },
  { label: 'Korean', value: 'korean' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'Mandarin', value: 'mandarin' },
  { label: 'Dutch', value: 'dutch' },
  { label: 'Portuguese', value: 'portuguese' },
  { label: 'Farsi/ Persian', value: 'farsiandpersian' },
  { label: 'Romanian', value: 'romanian' },
  { label: 'French', value: 'french' },
  { label: 'Russian', value: 'russian' },
  { label: 'German', value: 'german' },
  { label: 'Shanghainese', value: 'shanghainese' },
  { label: 'Greek', value: 'greek' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Tagalog', value: 'tagalog' },
  { label: 'Hungarian', value: 'hungarian' },
  { label: 'Taiwanese', value: 'taiwanese' },
  { label: 'Ilocano', value: 'ilocano' },
  { label: 'Urdu', value: 'urdu' },
];
