import React from 'react';

import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import {
  DropdownOption,
  DropdownOptionText,
} from 'shared/components/SearchBar/components/SearchBarOption/SearchBarOptionStyled';
import SearchBarOptionIcon from 'shared/components/SearchBar/components/SearchBarOptionIcon';
import SearchBarOptionLabel from 'shared/components/SearchBar/components/SearchBarOptionLabel';
import { ButtonsCode } from 'shared/constants/appConstants';

// TODO: check and define this interface previous was any, this was picked from tests
export interface Option {
  id: string;
  value: string;
  label: string;
}

export interface SearchBarOptionProps {
  className?: string;
  highlight?: boolean;
  onItemClick?: (option: any, value: string) => void;
  option: Option;
  optionIcon?: React.ReactNode;
  searchValue?: string;
  closeMenu?: () => void;
}

const SearchBarOption = (props: SearchBarOptionProps) => {
  const inputValue = props.searchValue?.trim() || '';
  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();

  const handleMouseDown = () => {
    props.onItemClick?.(props.option, inputValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ButtonsCode.Enter) {
      props.onItemClick?.(props.option, inputValue);
      ariaLiveAnnouncerCtx.announce(`${props.option.value} selected`);
    }
    if (event.key === ButtonsCode.Esc) {
      props.closeMenu?.();
    }
  };

  return (
    <DropdownOption
      aria-label={props.option.value}
      className={props.className}
      highlight={props.highlight}
      key={props.option.id || props.option.value}
      // onClick doesn't work here
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyDown}
      role="option"
      tabIndex={0}
    >
      {props.optionIcon && (
        <SearchBarOptionIcon optionIcon={props.optionIcon} />
      )}

      <DropdownOptionText>
        <SearchBarOptionLabel
          input={inputValue}
          originalLabel={props.option.label || props.option.value}
        />
      </DropdownOptionText>
    </DropdownOption>
  );
};

export default SearchBarOption;
