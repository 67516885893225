import {
  FromLinkStatus,
  PermissionRoles,
} from 'permissions/permissionsConstants';

export enum PermissionsActionType {
  CHANGE_LOGIN_STATUS = 'CHANGE_LOGIN_STATUS',
  CHANGE_FROM_LINK_STATUS = 'CHANGE_FROM_LINK_STATUS',
  CLEAR_FROM_LINK_STATUS = 'CLEAR_FROM_LINK_STATUS',
}

export interface ChangeLoginStatusAction {
  type: PermissionsActionType.CHANGE_LOGIN_STATUS;
  loginStatus: PermissionRoles;
}

export function changeLoginStatus(
  loginStatus: PermissionRoles
): ChangeLoginStatusAction {
  return {
    type: PermissionsActionType.CHANGE_LOGIN_STATUS,
    loginStatus,
  };
}

export interface ChangeFromLinkStatusAction {
  type: PermissionsActionType.CHANGE_FROM_LINK_STATUS;
  fromLinkStatus: FromLinkStatus;
}

export function changeFromLinkStatus(
  fromLinkStatus: FromLinkStatus
): ChangeFromLinkStatusAction {
  return {
    type: PermissionsActionType.CHANGE_FROM_LINK_STATUS,
    fromLinkStatus,
  };
}

export interface ClearFromLinkStatusAction {
  type: PermissionsActionType.CLEAR_FROM_LINK_STATUS;
}

export function clearFromLinkStatus(): ClearFromLinkStatusAction {
  return {
    type: PermissionsActionType.CLEAR_FROM_LINK_STATUS,
  };
}

export type PermissionsActions =
  | ChangeLoginStatusAction
  | ChangeFromLinkStatusAction
  | ClearFromLinkStatusAction;
