import { useMemo, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {
  components,
  DropdownIndicatorProps,
  InputProps,
  MenuPlacement,
  OptionProps,
  default as ReactSelect,
} from 'react-select';
import styled, { useTheme } from 'styled-components/macro';

import { DEFAULT_BOUNDARIES } from 'map/mapConstants';
import { DuplicateError } from 'myListings/CreateNewListing/CreateNewListing';
import { GoogleApiLoader } from 'shared/components/GoogleApiLoader';
import {
  ErrorMessageClassName,
  Message,
  SecondaryLabel,
} from 'shared/components/Input/Input';
import SelectInput from 'shared/components/Select/components/SelectInput';
import {
  customInputStyles,
  SelectInputHeight,
} from 'shared/components/Select/SelectHelpers';
import {
  DuplicateLink,
  DuplicateLinkWrapper,
  OptionContainer,
  SelectLabel,
  SelectWrapper,
} from 'shared/components/Select/SelectStyles';
import { InputMode, SelectOption } from 'shared/components/Select/SelectTypes';
import { NO_RESULTS_DROPDOWN_MESSAGE } from 'shared/constants/appConstants';
import { isEmpty, not, notEmpty } from 'shared/helpers/boolean';
import { showErrorToast } from 'shared/helpers/notifications';
import { ReactComponent as IconError } from 'shared/icons/shared/24x24/error.svg';
import { ReactComponent as IconArrowDown } from 'shared/icons/shared/arrows/small-arrow-down.svg';
import { ErrorMessagePlacement } from 'shared/types/errorTypes';

const SelectPlacesWrapper = styled(SelectWrapper)<{
  maxWidth?: number;
}>`
  position: relative;
  & div {
    border-radius: 0;
  }
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};

  & > svg {
    position: absolute;
    top: calc(${SelectInputHeight} / 2);
    transform: translateY(-50%);
    right: 16px;
  }
`;

export const SelectPlacesLabel = styled(SelectLabel)`
  margin-bottom: 8px;
`;

export const Option = (props: OptionProps<SelectOption>) => {
  return (
    <OptionContainer isPlaces>
      <components.Option {...props} />
    </OptionContainer>
  );
};

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const theme = useTheme();

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <IconArrowDown height="16px" fill={theme.blue} />
      </components.DropdownIndicator>
    )
  );
};

export interface PlacesAutocompleteRenderProps {
  getInputProps: () => {
    onChange: (event: { target: { value: string } }) => void;
  };
  suggestions: Suggestion[];
  loading: boolean;
}

export interface GeoParams {
  address_components: PlacesSelectAddressComponent[];
  formatted_address: string;
  geometry: unknown;
  place_id: string;
  types: string[];
}

export interface Suggestion {
  id: string;
  description: string;
}

export interface PlacesSelectAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface OnSelectParams {
  latitude: number;
  longitude: number;
  formattedAddress: string;
  addressComponents: PlacesSelectAddressComponent[];
}
export interface OnSelectParamsWithOutLatAndLng {
  formattedAddress: string;
  addressComponents: PlacesSelectAddressComponent[];
}

export interface PlacesSelectProps {
  label?: string;
  isDisabled?: boolean;
  placeholder?: string;
  value: string;
  inputMode?: InputMode;
  menuIsOpen?: boolean;
  focused?: boolean;
  searchOptionsTypes?: string[];
  onSelect?: (params: OnSelectParams) => void;
  setIsAdjustin?: (isAdjustin: boolean) => void;
  error?: boolean;
  warn?: boolean;
  message?: string;
  ariaLabel?: string;
  maxWidth?: number;
  errorMessagePlacement?: ErrorMessagePlacement;
  floatingMessage?: boolean;
  secondaryLabel?: string;
  duplicateError?: DuplicateError | null;
  menuPlacement?: MenuPlacement;
}

export const PlacesSelect = (props: PlacesSelectProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<SelectOption | null>(null);

  const theme = useTheme();

  const selectId = props.label || props.ariaLabel;

  const MemoizedSelectInput = useMemo(
    () =>
      function MemoizedInput(props: InputProps) {
        return <SelectInput {...props} inputMode={props.inputMode} />;
      },
    [props.inputMode]
  );

  const searchOptions = {
    region: 'us',
    location: {},
    radius: 50000,
    componentRestrictions: { country: 'us' },
    types: props.searchOptionsTypes,
  };

  const handleSearchChange = (address: string) => {
    setAddress(address);
  };

  const handleSelect = async (selected: SelectOption) => {
    props.setIsAdjustin?.(false);
    setAddress(selected.label);
    setSelectedValue(selected);
    await getGeoParams(selected.label);
  };

  const getGeoParams = async (address: string) => {
    try {
      const geocode: GeoParams[] = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(geocode[0]);

      props.onSelect?.({
        latitude: lat,
        longitude: lng,
        formattedAddress: geocode[0].formatted_address,
        addressComponents: geocode[0].address_components,
      });
    } catch (error) {
      showErrorToast(error?.message || String(error));
    }
  };

  const wrapCbToAccessParams =
    (cb: (event: { target: { value: string } }) => void) => (value: string) => {
      cb({ target: { value } });
    };

  const mapSuggestionsToOptions = (suggestions: Suggestion[]) => {
    return suggestions.map(({ id, description }) => ({
      value: id,
      label: description,
    }));
  };

  const messageColor = props.error
    ? theme.error
    : props.warn
    ? theme.warning
    : theme.white;

  return (
    <GoogleApiLoader
      render={maps => {
        if (isEmpty(searchOptions.location)) {
          searchOptions.location = new maps.LatLng(
            DEFAULT_BOUNDARIES.minLatitude,
            DEFAULT_BOUNDARIES.minLongitude
          );
        }

        return (
          <PlacesAutocomplete
            value={address}
            onChange={handleSearchChange}
            onSelect={handleSelect}
            searchOptions={searchOptions}
            highlightFirstSuggestion
          >
            {({
              getInputProps,
              suggestions,
              loading,
            }: PlacesAutocompleteRenderProps) => {
              const { onChange } = getInputProps();

              return (
                <>
                  {props.label && (
                    <SelectPlacesLabel
                      htmlFor={selectId}
                      isDisabled={props.isDisabled}
                    >
                      {props.label}
                      <SecondaryLabel>{props.secondaryLabel}</SecondaryLabel>
                    </SelectPlacesLabel>
                  )}
                  <SelectPlacesWrapper maxWidth={props.maxWidth}>
                    <ReactSelect
                      isDisabled={props.isDisabled}
                      inputId={selectId}
                      placeholder={props.placeholder}
                      blurInputOnSelect
                      noOptionsMessage={() => NO_RESULTS_DROPDOWN_MESSAGE}
                      isSearchable
                      styles={customInputStyles({ theme }, props.error)}
                      filterOption={() => true}
                      components={{
                        Option,
                        DropdownIndicator: not(props.inputMode)
                          ? DropdownIndicator
                          : null,
                        Input: MemoizedSelectInput,
                      }}
                      value={
                        selectedValue || props.value
                          ? {
                              value: props.value,
                              label: props.value.toString(),
                            }
                          : null
                      }
                      classNamePrefix="react-select"
                      inputValue={address}
                      options={mapSuggestionsToOptions(suggestions)}
                      onChange={handleSelect}
                      onInputChange={wrapCbToAccessParams(onChange)}
                      menuIsOpen={notEmpty(address)}
                      isLoading={loading}
                      autoFocus={props.focused}
                      aria-label={props.ariaLabel}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      menuPlacement={props.menuPlacement}
                    />
                    {Boolean(props.message) && not(focused) && (
                      <>
                        <IconError width="24px" fill={theme.error} />
                        <Message
                          className={ErrorMessageClassName}
                          color={messageColor}
                          floating={props.floatingMessage}
                          placement={
                            props.floatingMessage
                              ? props.errorMessagePlacement
                              : ErrorMessagePlacement.Bottom
                          }
                          duplicateError={Boolean(props.duplicateError)}
                        >
                          {props.message}
                          {props.duplicateError && (
                            <DuplicateLinkWrapper>
                              <DuplicateLink
                                to={props.duplicateError.listingURL}
                                target="_blank"
                              >
                                See listing
                              </DuplicateLink>
                            </DuplicateLinkWrapper>
                          )}
                        </Message>
                      </>
                    )}
                  </SelectPlacesWrapper>
                </>
              );
            }}
          </PlacesAutocomplete>
        );
      }}
    />
  );
};

PlacesSelect.defaultProps = {
  floatingMessage: true,
};

export default PlacesSelect;
