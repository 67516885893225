import { Location } from 'history';

import { ListingsState, PaginatedListings } from 'listings/listingsTypes';
import { Boundary, MapState } from 'map/mapTypes';
import { SortOrder } from 'shared/constants/appConstants';

export enum ListingsActionType {
  ADD_LISTING_RECOMMENDATION = 'ADD_LISTING_RECOMMENDATION',
  CHANGE_LISTINGS_PAGE = 'CHANGE_LISTINGS_PAGE',
  CHANGE_LISTINGS_SORTBY = 'CHANGE_LISTINGS_SORTBY',
  CHANGE_OUR_LISTINGS_FIRST = 'CHANGE_OUR_LISTINGS_FIRST',
  CHANGE_VIEW_MODE = 'CHANGE_VIEW_MODE',
  CLEAR_LISTINGS_ITEMS = 'CLEAR_LISTINGS_ITEMS',
  FETCH_LISTINGS = 'FETCH_LISTINGS',
  FETCH_LISTINGS_FAIL = 'FETCH_LISTINGS_FAIL',
  FETCH_LISTINGS_REQUEST = 'FETCH_LISTINGS_REQUEST',
  FETCH_LISTINGS_SUCCESS = 'FETCH_LISTINGS_SUCCESS',
  FETCH_VIEWED_LISTINGS = 'FETCH_VIEWED_LISTINGS',
  HANDLE_LISTINGS_LOADING = 'HANDLE_LISTINGS_LOADING',
  HIGHLIGHT_LISTINGS_ITEM = 'HIGHLIGHT_LISTINGS_ITEM',
  MERGE_LISTINGS = 'MERGE_LISTINGS',
  MERGE_LISTINGS_WITH_DEFAULT = 'MERGE_LISTINGS_WITH_DEFAULT',
  SEARCH_LISTINGS = 'SEARCH_LISTINGS',
  SET_CLICKED_MARKER_ID = 'SET_CLICKED_MARKER_ID',
  SET_CLICKED_MARKER_ID_AND_CHANGE_PAGE = 'SET_CLICKED_MARKER_ID_AND_CHANGE_PAGE',
  SET_IS_FIRST_LISTING_PAGE = 'SET_IS_FIRST_LISTING_PAGE',
  SET_IS_LAST_LISTING_PAGE = 'SET_IS_LAST_LISTING_PAGE',
  SET_LAST_VIEWED_ITEM = 'SET_LAST_VIEWED_ITEM',
  SET_LISTINGS_FROM_URL = 'SET_LISTINGS_FROM_URL',
  SORT_LISTINGS = 'SORT_LISTINGS',
  SWITCH_OFF_OUR_LISTINGS_FIRST = 'SWITCH_OFF_OUR_LISTINGS_FIRST',
  UPDATE_LISTINGS_URL = 'UPDATE_LISTINGS_URL',
  INIT_LISTINGS = 'INIT_LISTINGS',
  TRACK_AREA_OF_INTEREST = 'TRACK_AREA_OF_INTEREST',
}

export interface SetClickedMarkerIdAction {
  type: ListingsActionType.SET_CLICKED_MARKER_ID;
  listingId?: string;
}

export function setClickedMarkerId(
  listingId?: string
): SetClickedMarkerIdAction {
  return { type: ListingsActionType.SET_CLICKED_MARKER_ID, listingId };
}

export interface TrackAreaOfInterestAction {
  type: ListingsActionType.TRACK_AREA_OF_INTEREST;
  listingId: string;
}

export function trackAreaOfInterest(
  listingId: string
): TrackAreaOfInterestAction {
  return { type: ListingsActionType.TRACK_AREA_OF_INTEREST, listingId };
}

export interface SetLastViewedItemAction {
  type: ListingsActionType.SET_LAST_VIEWED_ITEM;
  listingId?: string | -1;
}

export function setLastViewedItem(
  listingId?: string | -1
): SetLastViewedItemAction {
  return { type: ListingsActionType.SET_LAST_VIEWED_ITEM, listingId };
}

export interface SetClickedMarkerAndChangePageAction {
  type: ListingsActionType.SET_CLICKED_MARKER_ID_AND_CHANGE_PAGE;
  listingId: string;
}

export function setClickedMarkerIdAndChangePage(
  listingId: string
): SetClickedMarkerAndChangePageAction {
  return {
    type: ListingsActionType.SET_CLICKED_MARKER_ID_AND_CHANGE_PAGE,
    listingId,
  };
}

export interface FetchListingsFailAction {
  type: ListingsActionType.FETCH_LISTINGS_FAIL;
  error: string;
}

export function fetchListingsFail(error: string): FetchListingsFailAction {
  return { type: ListingsActionType.FETCH_LISTINGS_FAIL, error };
}

export interface HighlightListingsItemAction {
  type: ListingsActionType.HIGHLIGHT_LISTINGS_ITEM;
  itemIndex?: string | -1;
}

export function highlightListingsItem(
  itemIndex?: string | -1
): HighlightListingsItemAction {
  return {
    type: ListingsActionType.HIGHLIGHT_LISTINGS_ITEM,
    itemIndex,
  };
}

export interface FetchListingsSuccessAction {
  type: ListingsActionType.FETCH_LISTINGS_SUCCESS;
  listings: PaginatedListings;
}

export function fetchListingsSuccess(
  listings: PaginatedListings
): FetchListingsSuccessAction {
  return { type: ListingsActionType.FETCH_LISTINGS_SUCCESS, listings };
}

export interface FetchListingsAction {
  type: ListingsActionType.FETCH_LISTINGS;
  mapType?: keyof MapState;
  force: boolean;
  limit?: number;
  boundaries?: Boundary;
}

export function fetchListings(
  mapType?: keyof MapState,
  force = false,
  boundaries?: Boundary
): FetchListingsAction {
  return {
    type: ListingsActionType.FETCH_LISTINGS,
    mapType,
    force,
    boundaries,
  };
}

export interface FetchListingsRequestAction {
  type: ListingsActionType.FETCH_LISTINGS_REQUEST;
}

export function fetchListingsRequest(): FetchListingsRequestAction {
  return { type: ListingsActionType.FETCH_LISTINGS_REQUEST };
}

export interface ChangeListingsPageAction {
  type: ListingsActionType.CHANGE_LISTINGS_PAGE;
  page: number;
}

export function changeListingsPage(page: number): ChangeListingsPageAction {
  return { type: ListingsActionType.CHANGE_LISTINGS_PAGE, page };
}

export interface SearchListingsAction {
  type: ListingsActionType.SEARCH_LISTINGS;
  location: string;
}

export function searchListings(location: string): SearchListingsAction {
  return { type: ListingsActionType.SEARCH_LISTINGS, location };
}

export interface SortListingsAction {
  type: ListingsActionType.SORT_LISTINGS;
  sort: {
    sortBy?: string;
    sortType?: SortOrder;
  };
}

export function sortListings(sort: {
  sortBy?: string;
  sortType?: SortOrder;
}): SortListingsAction {
  return { type: ListingsActionType.SORT_LISTINGS, sort };
}

export interface ChangeViewModeAction {
  type: ListingsActionType.CHANGE_VIEW_MODE;
  viewMode: string;
}

export function changeViewMode(viewMode: string): ChangeViewModeAction {
  return { type: ListingsActionType.CHANGE_VIEW_MODE, viewMode };
}

export interface FetchViewedListingsAction {
  type: ListingsActionType.FETCH_VIEWED_LISTINGS;
  userId?: string | null;
  sortBy?: string;
  isInit?: boolean;
}

export function fetchViewedListings({
  userId,
  sortBy,
  isInit,
}: {
  userId?: string | null;
  sortBy?: string;
  isInit?: boolean;
}): FetchViewedListingsAction {
  return {
    type: ListingsActionType.FETCH_VIEWED_LISTINGS,
    userId,
    sortBy,
    isInit,
  };
}

export interface MergeListingsAction {
  type: ListingsActionType.MERGE_LISTINGS;
  listings: Partial<ListingsState>;
}

export const mergeListings = (
  listings: Partial<ListingsState>
): MergeListingsAction => ({
  type: ListingsActionType.MERGE_LISTINGS,
  listings,
});

export interface MergeListingsWithDefaultAction {
  type: ListingsActionType.MERGE_LISTINGS_WITH_DEFAULT;
  listings: Partial<ListingsState>;
}

export const mergeListingsWithDefault = (
  listings: Partial<ListingsState>
): MergeListingsWithDefaultAction => ({
  type: ListingsActionType.MERGE_LISTINGS_WITH_DEFAULT,
  listings,
});

export interface SetListingsFromUrlAction {
  type: ListingsActionType.SET_LISTINGS_FROM_URL;
  location?: Location;
}

export function setListingsFromUrl(
  location?: Location
): SetListingsFromUrlAction {
  return {
    type: ListingsActionType.SET_LISTINGS_FROM_URL,
    location,
  };
}

export interface ChangeOurListingsFirstAction {
  type: ListingsActionType.CHANGE_OUR_LISTINGS_FIRST;
  ourListingsFirst: boolean;
}

export function changeOurListingsFirst(
  ourListingsFirst: boolean
): ChangeOurListingsFirstAction {
  return {
    type: ListingsActionType.CHANGE_OUR_LISTINGS_FIRST,
    ourListingsFirst,
  };
}

export interface SwitchOffOurListingsFirstAction {
  type: ListingsActionType.SWITCH_OFF_OUR_LISTINGS_FIRST;
}

export function switchOffOurListingsFirst(): SwitchOffOurListingsFirstAction {
  return { type: ListingsActionType.SWITCH_OFF_OUR_LISTINGS_FIRST };
}

export interface SetIsFirstListingPageAction {
  type: ListingsActionType.SET_IS_FIRST_LISTING_PAGE;
  isFirstPage: boolean;
}

export function setIsFirstListingPage(
  isFirstPage: boolean
): SetIsFirstListingPageAction {
  return {
    type: ListingsActionType.SET_IS_FIRST_LISTING_PAGE,
    isFirstPage,
  };
}

export interface SetIsLastListingPageAction {
  type: ListingsActionType.SET_IS_LAST_LISTING_PAGE;
  isLastPage: boolean;
}

export function setIsLastListingPage(
  isLastPage: boolean
): SetIsLastListingPageAction {
  return {
    type: ListingsActionType.SET_IS_LAST_LISTING_PAGE,
    isLastPage,
  };
}

export interface ClearListingsItemsAction {
  type: ListingsActionType.CLEAR_LISTINGS_ITEMS;
}

export function clearListingsItems(): ClearListingsItemsAction {
  return {
    type: ListingsActionType.CLEAR_LISTINGS_ITEMS,
  };
}

export interface UpdateListingsURLAction {
  type: ListingsActionType.UPDATE_LISTINGS_URL;
}

export function updateListingsURL(): UpdateListingsURLAction {
  return {
    type: ListingsActionType.UPDATE_LISTINGS_URL,
  };
}

export interface AddListingRecommendationAction {
  type: ListingsActionType.ADD_LISTING_RECOMMENDATION;
  listingIds: string[];
  user: string;
  owner: string;
  mapType?: keyof MapState;
}

export const addListingRecommendation = (
  listingIds: string[],
  user: string,
  owner: string,
  mapType?: keyof MapState
  // eslint-disable-next-line max-params
): AddListingRecommendationAction => ({
  type: ListingsActionType.ADD_LISTING_RECOMMENDATION,
  listingIds,
  user,
  owner,
  mapType,
});

export interface HandleListingsLoading {
  type: ListingsActionType.HANDLE_LISTINGS_LOADING;
  loading: boolean;
}

export const handleListingsLoading = (
  loading: boolean
): HandleListingsLoading => ({
  type: ListingsActionType.HANDLE_LISTINGS_LOADING,
  loading,
});

export interface ChangeListingsSortBy {
  type: ListingsActionType.CHANGE_LISTINGS_SORTBY;
  sortBy: string;
}

export const changeListingsSortBy = (sortBy: string): ChangeListingsSortBy => {
  return {
    type: ListingsActionType.CHANGE_LISTINGS_SORTBY,
    sortBy,
  };
};

export interface InitListings {
  type: ListingsActionType.INIT_LISTINGS;
}

export const initListings = (): InitListings => {
  return {
    type: ListingsActionType.INIT_LISTINGS,
  };
};

export type ListingsAction =
  | FetchListingsFailAction
  | HighlightListingsItemAction
  | FetchListingsSuccessAction
  | FetchListingsAction
  | FetchListingsRequestAction
  | ChangeListingsPageAction
  | SearchListingsAction
  | SortListingsAction
  | ChangeViewModeAction
  | FetchViewedListingsAction
  | MergeListingsAction
  | MergeListingsWithDefaultAction
  | SetListingsFromUrlAction
  | ChangeOurListingsFirstAction
  | SetClickedMarkerIdAction
  | SwitchOffOurListingsFirstAction
  | SetIsFirstListingPageAction
  | SetIsLastListingPageAction
  | SetLastViewedItemAction
  | ClearListingsItemsAction
  | UpdateListingsURLAction
  | AddListingRecommendationAction
  | HandleListingsLoading
  | ChangeListingsSortBy
  | InitListings
  | TrackAreaOfInterestAction;
