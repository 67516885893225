import { useEffect } from 'react';

import { getEnv } from 'env-config';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { getIsInitialized } from 'user/userSelectors';

const grecaptchaUrl = 'https://www.google.com/recaptcha/api.js';

interface GReCaptcha {
  execute: (siteKey: string, params: { action: string }) => Promise<string>;
  ready: (cb: () => void) => void;
}

let resolve: (grecaptcha: GReCaptcha) => void;
let reject: (error: Error) => void;
const recaptchaPromise: Promise<GReCaptcha> = new Promise((res, rej) => {
  resolve = res;
  reject = rej;
});

export function ReCAPTCHA(): null {
  const isInitialized = useSelector(getIsInitialized);
  useEffect(() => {
    if (isInitialized) {
      initializeRecaptcha();
    }
  }, [isInitialized]);
  return null;
}

export const initializeRecaptcha = () => {
  const script = document.createElement('script');
  script.src = `${grecaptchaUrl}?render=${getEnv().recaptchaSitekey}`;
  script.onload = () => {
    const grecaptcha: GReCaptcha | undefined = (window as any).grecaptcha;
    if (not(grecaptcha)) {
      const error = new Error('Google reCAPTCHA is not available');
      reject(error);
      import(/* webpackChunkName: "bugsnag" */ 'shared/services/bugsnag').then(
        ({ bugsnag }) => bugsnag.notify(error)
      );
      return void 0;
    }
    grecaptcha.ready(() => {
      resolve(grecaptcha);
    });
  };
  document.body.appendChild(script);
};

export const executeRecaptcha = async (params: { action: string }) => {
  const recaptcha = await recaptchaPromise;
  return recaptcha.execute(getEnv().recaptchaSitekey, {
    action: params.action,
  });
};
