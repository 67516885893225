import styled, { css } from 'styled-components/macro';

import { not } from 'shared/helpers/boolean';
import { ErrorMessagePlacement } from 'shared/types/errorTypes';
import { media } from 'styled-system/responsive';

const errorStyles = css`
  background-color: ${({ theme }) => theme.errorBg};
  border-color: ${({ theme }) => theme.error};

  & > svg {
    position: absolute;
    top: 8px;
    right: 16px;
  }
`;

const disabledStyles = css`
  background-color: #f7f7f7;
  border-color: ${({ theme }) => theme.divider};
  color: ${({ theme }) => theme.disabled};
  cursor: not-allowed;
  &:hover {
    border-color: ${({ theme }) => theme.divider};
  }
`;

const disabledTextAreaStyles = css`
  background-color: ${({ theme }) => theme.secondaryBackground};
  color: ${({ theme }) => theme.text3};
  -webkit-text-fill-color: ${({ theme }) => theme.text3};
  opacity: 1;
`;

const focusStyles = css`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.secondary};
  &:hover {
    border-color: ${({ theme }) => theme.secondary};
  }
`;

export const RealInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TextAreaLabel = styled.label`
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  margin-bottom: 8px;
`;

export const InputContainer = styled.div<{
  parentWidth?: boolean;
  error?: boolean;
  focused?: boolean;
  disabled?: boolean;
  autoResize?: boolean;
  maxHeight?: number;
  value?: string;
}>`
  background-color: ${({ theme }) => theme.white};
  border-radius: 1px;
  border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.text2};
  position: relative;
  transition: 0.3s;
  min-height: 40px;
  &:hover {
    border-color: ${({ theme }) => theme.text3};
  }
  ${props => props.parentWidth && 'width: 100%;'};
  ${props => props.error && errorStyles};
  ${props => props.focused && focusStyles};
  ${props => props.disabled && disabledStyles};
  ${props => props.disabled && props.value && disabledTextAreaStyles};
  textarea {
    display: block;
    border: 0;
    background-color: transparent;
    color: inherit;
    cursor: inherit;
    width: 100%;
    height: 64px;
    font-size: 14px;
    padding: 11px 16px;
    resize: none;
    opacity: 1;
    ${props => props.autoResize && `height: 38px`};
    ${props => props.maxHeight && `max-height: ${props.maxHeight}px`};
    &::placeholder {
      color: ${({ theme }) => theme.disabled};
    }
    &:focus {
      outline: 0;
    }
  }
`;

export const TextAreaOptionalLabel = styled.span`
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-style: italic;
  color: ${({ theme }) => theme.disabled};
  margin-left: 7px;
`;

export const TextAreaLabelContainer = styled.div`
  margin-bottom: 8px;
`;

export const TextAreaMessage = styled.div<{
  color?: string;
  placement?: ErrorMessagePlacement;
  floating?: boolean;
}>`
  border-radius: 1px;
  max-width: 380px;
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  padding: 4px 8px;
  transition: 0.3s;
  background-color: ${({ color, theme }) => color || theme.error};
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  ${({ placement, color, theme }) =>
    placement === ErrorMessagePlacement.Top &&
    css`
      right: 0;
      top: -28px;

      &::after {
        bottom: -4px;
        left: 20px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid ${color || theme.error};
      }
    `}

  ${({ placement, color, theme }) =>
    placement === ErrorMessagePlacement.Right &&
    css`
      left: calc(100% + 6px);
      top: 4px;

      &::after {
        bottom: 8px;
        left: -4px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 5px solid ${color || theme.error};
      }
    `}

  ${({ placement, color, theme }) =>
    placement === ErrorMessagePlacement.Bottom &&
    css`
      right: 0;
      bottom: -28px;

      &::after {
        top: -4px;
        left: 20px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 5px solid ${color || theme.error};
      }
    `}

  ${({ floating }) =>
    not(floating) &&
    css`
      position: relative;
      margin-top: 8px;
      width: fit-content;
      top: auto;
      bottom: auto;
      right: auto;
      left: auto;
      white-space: normal;
    `}

  ${media.sm`
    max-width: 320px;
  `};
`;

TextAreaMessage.defaultProps = {
  placement: ErrorMessagePlacement.Top,
};
