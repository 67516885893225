import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { RoutePath } from 'shared/constants/routesConstants';
import { not } from 'shared/helpers/boolean';
import { getInitials, parseImgSrc } from 'shared/helpers/userImageHelpers';

const smallImageStyle = css`
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
`;

const mediumImageStyle = css`
  width: 56px;
  height: 56px;
  font-size: 20px;
  font-weight: bold;
`;

const largeImageStyle = css`
  width: 84px;
  height: 84px;
  font-size: 20px;
  font-weight: bold;
  margin-right: 16px;
`;

const greyImageStyle = css`
  background-color: ${({ theme }) => theme.divider};
  color: ${({ theme }) => theme.text2};
`;

export const InitialsProfileImage = styled.div<{
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  grey?: boolean;
}>`
  color: ${({ theme }) => theme.white};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-color: ${({ theme }) => theme.secondary};
  font-size: 24px;
  font-weight: normal;
  cursor: pointer;
  flex-shrink: 0;
  ${props => props.small && smallImageStyle};
  ${props => props.medium && mediumImageStyle};
  ${props => props.large && largeImageStyle};
  ${props => props.grey && greyImageStyle};
`;

export const RealProfileImage = styled.img<{
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  grey?: boolean;
}>`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  object-fit: contain;
  flex-shrink: 0;
  cursor: pointer;
  ${props => props.small && smallImageStyle};
  ${props => props.medium && mediumImageStyle};
  ${props => props.large && largeImageStyle}
  ${props => props.grey && greyImageStyle};
`;

export interface ProfileImageData {
  firstname: string;
  lastname: string;
  headshotUrlSquare?: string;
  userSocialPhoto_URL?: string;
}

export interface ProfileImageProps {
  socialImage?: string;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  profile?: ProfileImageData | null;
  className?: string;
  grey?: boolean;
  isLink?: boolean;
  onClick?: (e: unknown) => void;
  handleAgentImageClick?: () => void;
}

type ProfileImageState = 'initial' | 'loaded' | 'failed';

const ProfileImage = (props: ProfileImageProps) => {
  const {
    profile,
    small,
    medium,
    large,
    className,
    grey,
    isLink,
    handleAgentImageClick = () => {},
    onClick = () => {},
  } = props;

  const [state, setState] = useState<ProfileImageState>('initial');

  useEffect(() => {
    setState('initial');
  }, [profile.userSocialPhoto_URL]);

  if (not(profile)) {
    return null;
  }
  let splitter = '';
  let urlImage: any = '';
  if (profile.userSocialPhoto_URL) {
    if (profile.userSocialPhoto_URL.includes('Google')) {
      splitter = 'Google_';
    } else {
      splitter = 'Facebook_';
    }
    urlImage = profile.userSocialPhoto_URL.split(splitter);
  }

  const initials = getInitials(profile.firstname, profile.lastname);
  const image = (
    <>
      {(state === 'initial' || state === 'failed') && (
        <InitialsProfileImage
          className={className}
          small={small}
          medium={medium}
          large={large}
          grey={grey}
          onClick={handleAgentImageClick}
        >
          {initials}
        </InitialsProfileImage>
      )}
      {(state === 'initial' || state === 'loaded') && (
        <RealProfileImage
          alt={`${profile.firstname} ${profile.lastname}`}
          className={className}
          small={small}
          medium={medium}
          large={large}
          grey={grey}
          style={{ display: state === 'initial' ? 'none' : 'flex' }}
          src={parseImgSrc(urlImage[1])}
          onError={() => setState('failed')}
          onLoad={() => setState('loaded')}
          onClick={handleAgentImageClick}
        />
      )}
    </>
  );

  return isLink ? (
    <div onClick={onClick}>
      <Link
        to={RoutePath.ACCOUNT_SETTINGS}
        aria-label={'Navigate to the Account settings'}
      >
        {image}
      </Link>
    </div>
  ) : (
    image
  );
};

export default ProfileImage;
