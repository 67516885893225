import { useField } from 'formik';
import { useEffect } from 'react';

import Checkbox from 'shared/components/Checkbox';
import { CheckboxComponentProps } from 'shared/components/Checkbox/Checkbox';
import { not } from 'shared/helpers/boolean';
import { Box } from 'styled-system/components';
import { StyledSystemInput } from 'styled-system/lib/types';

interface TextFieldProps extends CheckboxComponentProps {
  label?: string;
  name: string;
  sx?: StyledSystemInput;
}

export const CheckboxField = (props: TextFieldProps) => {
  const { sx, label, labelCmp, onChange, ...restProps } = props;
  const [field, meta, helpers] = useField({ name: props.name });

  useEffect(() => {
    return () => helpers.setValue(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(field.value)) {
      if (field.value.includes(props.value)) {
        const value = field.value.filter(el => el !== props.value);
        helpers.setValue(value);
      } else {
        helpers.setValue([...field.value, props.value]);
      }
    } else {
      helpers.setValue(not(field.value));
    }
    helpers.setError(undefined);
    onChange?.(event);
  };

  return (
    <Box sx={sx}>
      <Checkbox
        {...field}
        ariaLabel={label}
        labelCmp={labelCmp || <span>{label}</span>}
        error={Boolean(meta.error)}
        onChange={handleChange}
        value={field.value ?? false}
        checked={field.value ?? false}
        {...restProps}
      />
    </Box>
  );
};
