/**
 * Generates a random password
 *
 * @param numLc Number of lowercase letters to be used (default 4)
 * @param numUc Number of uppercase letters to be used (default 4)
 * @param numDigits Number of digits to be used (default 4)
 * @param numSpecial Number of special characters to be used (default 2)
 */
const generatePassword = ({
  numLc = 4,
  numUc = 4,
  numDigits = 4,
  numSpecial = 2,
}: {
  numLc?: number;
  numUc?: number;
  numDigits?: number;
  numSpecial?: number;
} = {}) => {
  const lcLetters = 'abcdefghijklmnopqrstuvwxyz';
  const ucLetters = lcLetters.toUpperCase();
  const numbers = '0123456789';
  const special = '!"$%\'()*,-./:<>@[\\]^_`{|}~';

  const getRand = (values: string): string => {
    return values.charAt(Math.floor(Math.random() * values.length));
  };

  const shuffle = (o: string[]) => {
    for (
      let j, x, i = o.length;
      i;
      j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
    ) {
      return o;
    }
  };

  const pass: string[] = [];
  for (let i = 0; i < numLc; ++i) {
    pass.push(getRand(lcLetters));
  }
  for (let i = 0; i < numUc; ++i) {
    pass.push(getRand(ucLetters));
  }
  for (let i = 0; i < numDigits; ++i) {
    pass.push(getRand(numbers));
  }
  for (let i = 0; i < numSpecial; ++i) {
    pass.push(getRand(special));
  }

  return shuffle(pass).join('');
};

export default generatePassword;
