import {
  ListingsImagesResponse,
  PaginatedListings,
} from 'listings/listingsTypes';
import { Boundaries } from 'map/mapTypes';
import { ApiService } from 'shared/services/ApiService';
import { BaseResponse } from 'shared/types';
import { Polygon } from 'shared/types/placesAndPolygons';

const BASE_URL = '/listing';

export class ListingsService {
  static async fetchListingsImages(query: {
    listingKeys: string;
    user?: string | null;
  }) {
    return ApiService.request<ListingsImagesResponse>({
      method: 'GET',
      path: `${BASE_URL}/media-bulk/images`,
      query,
    });
  }

  static async fetchPolygons(id: string) {
    return ApiService.request<Polygon>({
      method: 'GET',
      path: `/polygon/${id}`,
    });
  }

  static async fetchPolygonByQuery(query: { url: string }) {
    return ApiService.request<Polygon>({
      method: 'GET',
      path: `/polygon`,
      query,
    });
  }

  static async fetchBoundaries(url: string) {
    return ApiService.request<Boundaries>({
      method: 'GET',
      path: url,
      createFullUrl: false,
    });
  }

  static async fetchMarketOverview(id: string) {
    return ApiService.request({
      method: 'GET',
      path: `/market-overview/${id}`,
    });
  }

  static async fetchListings(url: URL) {
    return ApiService.request<BaseResponse<PaginatedListings>>({
      createFullUrl: false,
      method: 'GET',
      path: String(url),
    });
  }

  static async trackAreaOfInterest(listingId: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/${listingId}/view`,
    });
  }
}
