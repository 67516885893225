import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import config from 'config';
import { LogoSize } from 'shared/config/configConstants';
import { getIconDependsOnMlsOffice } from 'shared/config/configHelpers';
import { RoutePath } from 'shared/constants/routesConstants';

interface LogoProps {
  className?: string;
  height?: number;
  iconStyles?: React.CSSProperties;
  isIcon?: boolean;
  isLink?: boolean;
  listingOfficemlsId?: string;
  mobile?: boolean;
  onClick?: (e: unknown) => void;
}

const LogoWrapper = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Logo: React.FC<LogoProps> = props => {
  const {
    className,
    mobile = false,
    isLink,
    isIcon = false,
    iconStyles,
    listingOfficemlsId,
    onClick = () => {},
  } = props;

  const size: LogoSize = isIcon || mobile ? LogoSize.Small : LogoSize.Big;
  const isListingIcon = Boolean(listingOfficemlsId);
  const Logo = isListingIcon
    ? getIconDependsOnMlsOffice(listingOfficemlsId)[size]
    : config.logos[size];

  return (
    <>
      {isIcon ? (
        <Logo style={iconStyles} />
      ) : (
        <LogoWrapper onClick={onClick}>
          {isLink ? (
            <Link to={RoutePath.HOME} aria-label={config.productFullName}>
              <Logo className={size === LogoSize.Big ? className : void 0} />
            </Link>
          ) : (
            <Logo className={size === LogoSize.Big ? className : void 0} />
          )}
        </LogoWrapper>
      )}
    </>
  );
};
