import { AgentsAction } from 'agents/agentsActions';
import { CollectionsAction } from 'collections/collectionsActions';
import { CommentsAction } from 'comments/commentsActions';
import { ContactsAction } from 'contacts/contactsActions';
import { FiltersAction } from 'filters/filtersActions';
import { ListingDetailsAction } from 'listingDetails/listingDetailsActions';
import { ListingsAction } from 'listings/listingsActions';
import { MapAction } from 'map/mapActions';
import { MapState } from 'map/mapTypes';
import { PermissionsActions } from 'permissions/permissionsActions';
import { SavedSearchAction } from 'saved-search/savedSearchActions';

export const RESET_ALL = 'RESET_ALL';
export const RESET_FILTERS = 'RESET_FILTERS';

export interface ResetAllAction {
  type: typeof RESET_ALL;
  mapType: keyof MapState;
  agentMode: boolean;
  isAgent: boolean;
}
export function resetAll({
  mapType,
  agentMode,
  isAgent,
}: {
  mapType: keyof MapState;
  agentMode: boolean;
  isAgent: boolean;
}): ResetAllAction {
  return { type: RESET_ALL, mapType, agentMode, isAgent };
}

export interface ResetFilters {
  type: typeof RESET_FILTERS;
  agentMode: boolean;
  isAgent: boolean;
}
export function resetFilters({
  agentMode,
  isAgent,
}: {
  agentMode: boolean;
  isAgent: boolean;
}): ResetFilters {
  return { type: RESET_FILTERS, agentMode, isAgent };
}

export type RootAction = ResetAllAction | ResetFilters;

export type PortalAction =
  | AgentsAction
  | CollectionsAction
  | CommentsAction
  | ContactsAction
  | FiltersAction
  | ListingDetailsAction
  | ListingsAction
  | MapAction
  | PermissionsActions
  | RootAction
  | SavedSearchAction;
