import config from 'config';
import { Contact } from 'contacts/contactsTypes';
import { PropertyTypeKeys } from 'filters/filtersContants';
import { FiltersState } from 'filters/filtersTypes';
import { ListingWithDetails } from 'listingDetails/listingDetailsTypes';
import { MlsStatusesWithoutPrice } from 'listings/listingsConstants';
import { Listing, PropertyType } from 'listings/listingsTypes';
import { Tag } from 'search/search-types';
import {
  FiltersToUseInMeta,
  MarketOverviewFiltersMetaText,
  StaticDescription,
  StaticTitle,
} from 'shared/components/Metadata/metadataConstants';
import { communityFeaturesDescription } from 'shared/config/configConstants';
import { includes } from 'shared/helpers/array';
import { not } from 'shared/helpers/boolean';
import {
  formatNumberPostfix,
  separateThousandsWithCommas,
} from 'shared/helpers/formatters';
import { getIsExclusiveListing } from 'shared/helpers/isExclusiveListing';
import { keysOf } from 'shared/helpers/object';
import { PlaceType } from 'shared/types/placesAndPolygons';

const propertyTypeDisplayValue: Record<keyof typeof PropertyTypeKeys, string> =
  {
    commercialSale: 'Commercial Real Estate',
    condominium: 'Condos',
    farm: 'Farms or Ranches',
    lot: 'Lots',
    multiFamily: 'Multi-Family Homes',
    singleFamilyResidence: 'Single Family Homes',
    townhouse: 'Townhouses',
  };

export const getMarketOverviewPageTitle = ({
  tag,
  filterKey,
  filterParams = '',
  newFilterParams,
  maxPrice,
}: {
  tag?: Tag;
  filterKey: keyof Partial<FiltersState>;
  filterParams?: string;
  newFilterParams?: Partial<FiltersState>;
  maxPrice: string;
}): string => {
  if (not(tag?.filter)) return '';

  const filterLabel =
    MarketOverviewFiltersMetaText[filterKey]?.(maxPrice) || '';

  const {
    filter: {
      city,
      stateorprovince,
      county,
      subdivision,
      postalcode,
      school,
      mlsarea,
      schooldistrict,
    },
    label,
  } = tag;

  const propertyType =
    filterKey && includes(keysOf(propertyTypeDisplayValue), filterKey)
      ? propertyTypeDisplayValue[filterKey]
      : '';

  const listingsMlsStatus = filterParams
    ? filterParams.match(new RegExp('(mlsstatus=Sold.+soldtimeframe)', 'g'))
      ? 'Sold'
      : ''
    : '';

  if (newFilterParams) {
    const filterKeys = keysOf(newFilterParams);
    const numericFilterKeys = [
      'maxPrice',
      'minBedrooms',
      'maxBedrooms',
      'minBathrooms',
    ];
    const numericalFilter =
      newFilterParams[filterKeys[0]] &&
      numericFilterKeys.includes(filterKeys[0]) &&
      ((filterKeys.length === 1 &&
        !newFilterParams.minBedrooms &&
        !newFilterParams.maxBedrooms) ||
        (Number(newFilterParams.minBedrooms) ===
          Number(newFilterParams.maxBedrooms) &&
          filterKeys.length === 2))
        ? String(filterKeys[0])
        : '';

    if (numericalFilter) {
      const maxPriceValue =
        numericalFilter === 'maxPrice'
          ? formatNumberPostfix({
              value: newFilterParams.maxPrice,
            })
          : '';
      const bedroomsNumber =
        numericalFilter === 'minBedrooms' || numericalFilter === 'maxBedrooms'
          ? String(newFilterParams.maxBedrooms)
          : '';
      const bathroomsNumber =
        numericalFilter === 'minBathrooms'
          ? String(newFilterParams.minBathrooms)
          : '';

      switch (tag.type) {
        case PlaceType.City:
          return numericalFilter === 'maxPrice'
            ? `Homes for Sale in ${city} ${stateorprovince} under $${maxPriceValue} | ${city} Real Estate`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `${bedroomsNumber} Bedroom Homes for Sale in ${city} ${stateorprovince} | ${city} Real Estate`
            : numericalFilter === 'minBathrooms'
            ? `${bathroomsNumber} Bathrooms Homes for Sale in ${city} ${stateorprovince} | ${city} Real Estate`
            : `Homes for Sale in ${city} ${stateorprovince}`;
        case PlaceType.County:
          return numericalFilter === 'maxPrice'
            ? `${county} County Homes for Sale under $${maxPriceValue} | ${county} County Real Estate`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `${county} County ${bedroomsNumber} Bedroom Homes for Sale | ${county} County Real Estate`
            : numericalFilter === 'minBathrooms'
            ? `${county} County ${bathroomsNumber} Bathrooms Homes for Sale | ${county} County Real Estate`
            : `${county} County ${stateorprovince} Homes for Sale`;
        case PlaceType.Subdivision:
          return numericalFilter === 'maxPrice'
            ? `${subdivision} Homes for Sale under $${maxPriceValue} | ${subdivision}`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `${subdivision} ${bedroomsNumber} Bedroom Homes for Sale | ${subdivision}`
            : numericalFilter === 'minBathrooms'
            ? `${subdivision} ${bathroomsNumber} Bathrooms Homes for Sale | ${subdivision}`
            : `${subdivision} Homes for Sale`;
        case PlaceType.PostalCode:
          return numericalFilter === 'maxPrice'
            ? `${postalcode} Homes for Sale under $${maxPriceValue} | ${postalcode} Real Estate | Homes for Sale in ${postalcode}`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `${postalcode} ${bedroomsNumber} Bedroom Homes for Sale | ${postalcode} Real Estate | Homes for Sale in ${postalcode}`
            : numericalFilter === 'minBathrooms'
            ? `${postalcode} ${bathroomsNumber} Bathrooms Homes for Sale | ${postalcode} Real Estate | Homes for Sale in ${postalcode}`
            : `${postalcode} Homes for Sale`;
        case PlaceType.School:
          return numericalFilter === 'maxPrice'
            ? `${school} Homes for Sale under $${maxPriceValue} | ${school}`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `${school} ${bedroomsNumber} Bedroom Homes for Sale | ${school}`
            : numericalFilter === 'minBathrooms'
            ? `${school} ${bathroomsNumber} Bathrooms Homes for Sale | ${school}`
            : `${school} Homes for Sale`;
        case PlaceType.SchoolDistrict:
          return numericalFilter === 'maxPrice'
            ? `Homes for Sale in ${schooldistrict} under $${maxPriceValue}`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `${bedroomsNumber} Bedroom Homes for Sale in ${schooldistrict}`
            : numericalFilter === 'minBathrooms'
            ? `${bathroomsNumber} Bathrooms Homes for Sale in ${schooldistrict}`
            : `${schooldistrict} Homes for Sale`;
        case PlaceType.MlsArea:
          return numericalFilter === 'maxPrice'
            ? `MLS Area ${mlsarea} Homes | Search All Homes for Sale in ${config.seoData.envName} MLS Area ${mlsarea} under $${maxPriceValue}`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `MLS Area ${mlsarea} Homes | Search All ${bedroomsNumber} Bedroom Homes for Sale in ${config.seoData.envName} MLS Area ${mlsarea}`
            : numericalFilter === 'minBathrooms'
            ? `MLS Area ${mlsarea} Homes | Search All ${bathroomsNumber} Bathrooms Homes for Sale in ${config.seoData.envName} MLS Area ${mlsarea}`
            : `MLS Area ${mlsarea} Homes for Sale`;
      }
    }
  }

  if (filterKey && listingsMlsStatus !== 'Sold') {
    switch (tag.type) {
      case PlaceType.City:
        return propertyType
          ? `${propertyType} for Sale in ${city} ${stateorprovince} | ${city} Real Estate`
          : `${city} ${filterLabel} | ${config.seoData.envName} TX Real Estate`;
      case PlaceType.County:
        return propertyType
          ? `${county} County ${propertyType} for Sale | ${county} County Real Estate`
          : `${county} County ${stateorprovince} ${filterLabel} | ${config.seoData.envName} Real Estate`;
      case PlaceType.Subdivision:
        return propertyType
          ? `${subdivision} ${propertyType} for Sale | ${subdivision}`
          : `${subdivision} ${filterLabel} | ${config.seoData.envName} TX Real Estate`;
      case PlaceType.PostalCode:
        return propertyType
          ? `${postalcode} ${propertyType} for Sale | ${postalcode} Real Estate | Homes for Sale in ${postalcode}`
          : `${postalcode} ${filterLabel} | ${config.seoData.envName} TX Real Estate`;
      case PlaceType.School:
        return propertyType
          ? `${school} ${propertyType} for Sale | ${school}`
          : `${school} ${filterLabel} | ${config.seoData.envName} Real Estate`;
      case PlaceType.SchoolDistrict:
        return propertyType
          ? `${propertyType} for Sale in ${schooldistrict}`
          : `${schooldistrict} ${filterLabel} | ${config.seoData.envName} Real Estate`;
      case PlaceType.MlsArea:
        return propertyType
          ? `MLS Area ${mlsarea} ${propertyType} | Search All ${propertyType} for Sale in ${config.seoData.envName} MLS Area ${mlsarea}`
          : `${config.seoData.envName} MLS Area ${mlsarea} ${filterLabel} | ${config.seoData.envName}`;
      default:
        return `${StaticTitle.MarketOverviewTitle} ${filterLabel} in ${label} | ${config.productFullName}`;
    }
  }

  switch (tag.type) {
    case PlaceType.City:
      return listingsMlsStatus === 'Sold'
        ? `Sold Homes in ${city} ${stateorprovince} | ${config.productFullName}`
        : `${StaticTitle.MarketOverviewTitle} in ${city} ${stateorprovince} | ${city} Real Estate | ${config.productFullName}`;
    case PlaceType.County:
      return listingsMlsStatus === 'Sold'
        ? `${county} County Sold Homes | ${county} County Real Estate`
        : `${county} County ${StaticTitle.MarketOverviewTitle} | ${county} County Real Estate`;
    case PlaceType.Subdivision:
      return listingsMlsStatus === 'Sold'
        ? `${subdivision} Sold Homes | ${label}`
        : `${subdivision} ${StaticTitle.MarketOverviewTitle} | ${label}`;
    case PlaceType.PostalCode:
      return listingsMlsStatus === 'Sold'
        ? `${postalcode} Sold Homes | ${config.productFullName}`
        : `${postalcode} ${StaticTitle.MarketOverviewTitle} | ${postalcode} Real Estate | ${config.productFullName}`;
    case PlaceType.School:
      return listingsMlsStatus === 'Sold'
        ? `${school} Sold Homes | ${label}`
        : `${school} ${StaticTitle.MarketOverviewTitle} | ${label}`;
    case PlaceType.SchoolDistrict:
      return listingsMlsStatus === 'Sold'
        ? `Sold Homes in ${label}`
        : `${StaticTitle.MarketOverviewTitle} in ${label}`;
    case PlaceType.MlsArea:
      return listingsMlsStatus === 'Sold'
        ? `Search All Sold homes in ${config.productFullName} MLS Area ${mlsarea}`
        : `MLS Area ${mlsarea} Homes | Search All ${StaticTitle.MarketOverviewTitle} in ` +
            (config.seoData.envName === 'Austin'
              ? `${config.productFullName}`
              : `${config.seoData.envName}`) +
            ` MLS Area ${mlsarea}`;
    default:
      return `${StaticTitle.MarketOverviewTitle} in ${label} | ${config.productFullName}`;
  }
};

export const getMarketOverviewPageDescription = ({
  tag,
  listingsForSale = 'X',
  soldListings,
  nearbyListings,
  filterKey,
  filterParams = '',
  newFilterParams,
  minPrice,
  maxPrice,
}: {
  tag?: Tag;
  listingsForSale: number | string;
  soldListings?: Listing[];
  nearbyListings?: Listing[];
  filterKey: keyof Partial<FiltersState>;
  filterParams?: string;
  newFilterParams?: Partial<FiltersState>;
  minPrice?: string;
  maxPrice?: string;
}): string => {
  if (not(tag?.filter)) return '';

  const filterLabel =
    MarketOverviewFiltersMetaText[filterKey]?.(maxPrice) || 'homes for sale';

  const {
    filter: {
      city,
      stateorprovince,
      county,
      subdivision,
      postalcode,
      school,
      schooldistrict,
      mlsarea,
    },
    label,
  } = tag;

  const soldListingsPrices: number[] = [];
  const nearbyListingsPrices: number[] = [];

  const listingsMlsStatus = filterParams
    ? filterParams.match(new RegExp('(mlsstatus=Sold.+soldtimeframe)', 'g'))
      ? 'Sold'
      : ''
    : '';

  const propertyType =
    filterKey && includes(keysOf(propertyTypeDisplayValue), filterKey)
      ? propertyTypeDisplayValue[filterKey]
      : '';

  if (listingsMlsStatus === 'Sold') {
    soldListings.forEach(soldListing => {
      if (
        not(
          soldListing.propertytype === PropertyType.CommercialLease ||
            soldListing.propertytype === PropertyType.ResidentialLease
        )
      ) {
        soldListingsPrices.push(
          parseInt(soldListing.listprice.replace(/[$,]/g, ''))
        );
      }
    });
  }

  const minSoldListingPrice = not(soldListingsPrices.length === 0)
    ? separateThousandsWithCommas(Math.min(...soldListingsPrices))
    : '';
  const maxSoldListingPrice = not(soldListingsPrices.length === 0)
    ? separateThousandsWithCommas(Math.max(...soldListingsPrices))
    : '';
  const soldListingsForSale = not(soldListingsPrices.length === 0)
    ? soldListingsPrices.length
    : 0;
  const soldListingCloseDate = soldListings[0]
    ? Date.parse(soldListings[0].closedate)
    : 0;
  const soldListingsTimeframe = filterParams
    ? filterParams.match(new RegExp('(soldtimeframe=30)', 'g'))
      ? 30
      : filterParams.match(new RegExp('(soldtimeframe=365)', 'g'))
      ? 365
      : 0
    : 0;

  if (
    ((typeof listingsForSale === 'number' && listingsForSale < 1) ||
      listingsForSale === '') &&
    nearbyListings
  ) {
    nearbyListings.forEach(nearbyListing => {
      if (
        not(
          nearbyListing.propertytype === PropertyType.CommercialLease ||
            nearbyListing.propertytype === PropertyType.ResidentialLease
        )
      ) {
        nearbyListingsPrices.push(
          parseInt(nearbyListing.listprice.replace(/[$,]/g, ''))
        );
      }
    });
  }

  const minNearbyListingPrice = not(nearbyListingsPrices.length === 0)
    ? separateThousandsWithCommas(Math.min(...nearbyListingsPrices))
    : '';
  const maxNearbyListingPrice = not(nearbyListingsPrices.length === 0)
    ? separateThousandsWithCommas(Math.max(...nearbyListingsPrices))
    : '';
  const nearbyListingsForSale = not(nearbyListingsPrices.length === 0)
    ? String(nearbyListingsPrices.length)
    : '';

  if (newFilterParams) {
    const filterKeys = keysOf(newFilterParams);
    const numericFilterKeys = [
      'maxPrice',
      'minBedrooms',
      'maxBedrooms',
      'minBathrooms',
    ];
    const numericalFilter =
      newFilterParams[filterKeys[0]] &&
      numericFilterKeys.includes(filterKeys[0]) &&
      ((filterKeys.length === 1 &&
        !newFilterParams.minBedrooms &&
        !newFilterParams.maxBedrooms) ||
        (Number(newFilterParams.minBedrooms) ===
          Number(newFilterParams.maxBedrooms) &&
          filterKeys.length === 2))
        ? String(filterKeys[0])
        : '';

    if (numericalFilter) {
      const maxPriceValue =
        numericalFilter === 'maxPrice'
          ? formatNumberPostfix({
              value: newFilterParams.maxPrice,
            })
          : '';
      const bedroomsNumber =
        numericalFilter === 'minBedrooms' || numericalFilter === 'maxBedrooms'
          ? String(newFilterParams.maxBedrooms)
          : '';
      const bathroomsNumber =
        numericalFilter === 'minBathrooms'
          ? String(newFilterParams.minBathrooms)
          : '';

      switch (tag.type) {
        case PlaceType.City:
          return numericalFilter === 'maxPrice'
            ? `Browse all ${listingsForSale} homes for sale in ${city}, ${stateorprovince} under $${maxPriceValue}. ${StaticDescription.MarketOverviewDescription} in ${city}.`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `Browse all ${listingsForSale} ${bedroomsNumber} Bedroom homes for sale in ${city}, ${stateorprovince}. ${StaticDescription.MarketOverviewDescription} in ${city}.`
            : numericalFilter === 'minBathrooms'
            ? `Browse all ${listingsForSale} ${bathroomsNumber} Bathrooms homes for sale in ${city}, ${stateorprovince}. ${StaticDescription.MarketOverviewDescription} in ${city}.`
            : `Browse all ${listingsForSale} homes for sale in ${city}, ${stateorprovince}. ${StaticDescription.MarketOverviewDescription} in ${city}.`;
        case PlaceType.County:
          return numericalFilter === 'maxPrice'
            ? `Browse all ${listingsForSale} homes for sale in ${county}, ${stateorprovince} under $${maxPriceValue}. ${StaticDescription.MarketOverviewDescription} in ${county}.`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `Browse all ${listingsForSale} ${bedroomsNumber} Bedroom homes for sale in ${county}, ${stateorprovince}. ${StaticDescription.MarketOverviewDescription} in ${county}.`
            : numericalFilter === 'minBathrooms'
            ? `Browse all ${listingsForSale} ${bathroomsNumber} Bathrooms homes for sale in ${county}, ${stateorprovince}. ${StaticDescription.MarketOverviewDescription} in ${county}.`
            : `Browse all ${listingsForSale} homes for sale in ${county}, ${stateorprovince}. ${StaticDescription.MarketOverviewDescription} in ${county}.`;
        case PlaceType.Subdivision:
          return numericalFilter === 'maxPrice'
            ? `Browse all ${listingsForSale} homes for sale in ${subdivision} under $${maxPriceValue}. ${StaticDescription.MarketOverviewDescription} in ${subdivision}.`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `Browse all ${listingsForSale} ${bedroomsNumber} Bedroom homes for sale in ${subdivision}. ${StaticDescription.MarketOverviewDescription} in ${subdivision}.`
            : numericalFilter === 'minBathrooms'
            ? `Browse all ${listingsForSale} ${bathroomsNumber} Bathrooms homes for sale in ${subdivision}. ${StaticDescription.MarketOverviewDescription} in ${subdivision}.`
            : `Browse all ${listingsForSale} homes for sale in ${subdivision}. ${StaticDescription.MarketOverviewDescription} in ${subdivision}.`;
        case PlaceType.PostalCode:
          return numericalFilter === 'maxPrice'
            ? `Browse all ${listingsForSale} homes for sale in ${postalcode} under $${maxPriceValue}. ${StaticDescription.MarketOverviewDescription} in ${postalcode}.`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `Browse all ${listingsForSale} ${bedroomsNumber} Bedroom homes for sale in ${postalcode}. ${StaticDescription.MarketOverviewDescription} in ${postalcode}.`
            : numericalFilter === 'minBathrooms'
            ? `Browse all ${listingsForSale} ${bathroomsNumber} Bathrooms homes for sale in ${postalcode}. ${StaticDescription.MarketOverviewDescription} in ${postalcode}.`
            : `Browse all ${listingsForSale} homes for sale in ${postalcode}. ${StaticDescription.MarketOverviewDescription} in ${postalcode}.`;
        case PlaceType.School:
          return numericalFilter === 'maxPrice'
            ? `Browse all ${listingsForSale} homes for sale near ${school} under $${maxPriceValue}. ${StaticDescription.MarketOverviewDescription} near ${school}.`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `Browse all ${listingsForSale} ${bedroomsNumber} Bedroom homes for sale near ${school}. ${StaticDescription.MarketOverviewDescription} near ${school}.`
            : numericalFilter === 'minBathrooms'
            ? `Browse all ${listingsForSale} ${bathroomsNumber} Bathrooms homes for sale near ${school}. ${StaticDescription.MarketOverviewDescription} near ${school}.`
            : `Browse all ${listingsForSale} homes for sale near ${school}. ${StaticDescription.MarketOverviewDescription} near ${school}.`;
        case PlaceType.SchoolDistrict:
          return numericalFilter === 'maxPrice'
            ? `Browse all ${listingsForSale} homes for sale in ${schooldistrict} under $${maxPriceValue}. ${StaticDescription.MarketOverviewDescription} in ${schooldistrict}.`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `Browse all ${listingsForSale} ${bedroomsNumber} Bedroom homes for sale in ${schooldistrict}. ${StaticDescription.MarketOverviewDescription} in ${schooldistrict}.`
            : numericalFilter === 'minBathrooms'
            ? `Browse all ${listingsForSale} ${bathroomsNumber} Bathrooms homes for sale in ${schooldistrict}. ${StaticDescription.MarketOverviewDescription} in ${schooldistrict}.`
            : `Browse all ${listingsForSale} homes for sale in ${schooldistrict}. ${StaticDescription.MarketOverviewDescription} in ${schooldistrict}.`;
        case PlaceType.MlsArea:
          return numericalFilter === 'maxPrice'
            ? `Browse all ${listingsForSale} homes for sale in MLS area ${mlsarea} under $${maxPriceValue}. ${StaticDescription.MarketOverviewDescription} in ${mlsarea}.`
            : numericalFilter === 'minBedrooms' ||
              numericalFilter === 'maxBedrooms'
            ? `Browse all ${listingsForSale} ${bedroomsNumber} Bedroom homes for sale in MLS area ${mlsarea}. ${StaticDescription.MarketOverviewDescription} in ${mlsarea}.`
            : numericalFilter === 'minBathrooms'
            ? `Browse all ${listingsForSale} ${bathroomsNumber} Bathrooms homes for sale in MLS area ${mlsarea}. ${StaticDescription.MarketOverviewDescription} in ${mlsarea}.`
            : `Browse all ${listingsForSale} homes for sale in MLS area ${mlsarea}. ${StaticDescription.MarketOverviewDescription} in ${mlsarea}.`;
      }
    }
  }

  if (tag.type && propertyType && listingsMlsStatus !== 'Sold') {
    switch (tag.type) {
      case PlaceType.City:
        return (
          (soldListings.length >= 2
            ? `Find ${propertyType} in ${city}, ${stateorprovince} between ${minPrice} and ${maxPrice}. `
            : `Find ${propertyType} in ${city}, ${stateorprovince}. `) +
          `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        );
      case PlaceType.County:
        return (
          (soldListings.length >= 2
            ? `Browse ${propertyType} in ${county}, ${stateorprovince} between ${minPrice} and ${maxPrice}. `
            : `Browse ${propertyType} in ${county}, ${stateorprovince}. `) +
          `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        );
      case PlaceType.Subdivision:
        return (
          (soldListings.length >= 2
            ? `Find ${propertyType} in ${subdivision} between ${minPrice} and ${maxPrice}. `
            : `Find ${propertyType} in ${subdivision}. `) +
          `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        );
      case PlaceType.PostalCode:
        return `Find ${propertyType} in ${postalcode}. ${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`;
      case PlaceType.School:
        return `Find ${propertyType} near ${school}. ${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`;
      case PlaceType.SchoolDistrict:
        return `Find ${propertyType} in ${schooldistrict}. ${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`;
      case PlaceType.MlsArea:
        return `Find ${propertyType} in MLS area ${mlsarea}. ${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`;
    }
  }

  switch (tag.type) {
    case PlaceType.City:
      return filterKey && listingsMlsStatus !== 'Sold'
        ? `Search ${listingsForSale} ${filterLabel}. ${config.seoData.listings.description}`
        : listingsMlsStatus === 'Sold'
        ? (soldListingsTimeframe > 30
            ? soldListingsForSale > 1
              ? `Browse houses sold for the last year in ${city}, ${stateorprovince} between ${minSoldListingPrice} and ${maxSoldListingPrice}. `
              : `Browse houses sold for the last year in ${city}, ${stateorprovince}. `
            : soldListingsForSale > 1
            ? `Find recently sold homes in ${city}, ${stateorprovince} between ${minSoldListingPrice} and ${maxSoldListingPrice}. `
            : `Find recently sold homes in ${city}, ${stateorprovince}. `) +
          `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        : (Number(listingsForSale) < 2
            ? nearbyListingsForSale
              ? `Find ${nearbyListingsForSale} homes for sale in ${city}, ${stateorprovince} and nearby between ${minNearbyListingPrice} and ${maxNearbyListingPrice}. `
              : `Find ${listingsForSale} homes for sale in ${city}, ${stateorprovince}. `
            : `Find ${listingsForSale} homes for sale in ${city}, ${stateorprovince} between ${minPrice} and ${maxPrice}. `) +
          `${StaticDescription.MarketOverviewDescription} in ${city}. ${StaticDescription.MLSListingsUpdateTimeDescription}`;
    case PlaceType.County:
      return listingsMlsStatus === 'Sold'
        ? (soldListingsTimeframe > 30
            ? soldListingsForSale > 1
              ? `Browse houses sold for the last year in ${county}, ${stateorprovince} between ${minSoldListingPrice} and ${maxSoldListingPrice}. `
              : `Browse houses sold for the last year in ${county}, ${stateorprovince}. `
            : soldListingsForSale > 1
            ? `Browse recently sold homes in ${county}, ${stateorprovince} between ${minSoldListingPrice} and ${maxSoldListingPrice}. `
            : `Browse recently sold homes in ${county}, ${stateorprovince}. `) +
            `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        : (Number(listingsForSale) < 2
            ? nearbyListingsForSale
              ? `Browse all ${nearbyListingsForSale} homes for sale in ${county}, ${stateorprovince} and nearby between ${minNearbyListingPrice} and ${maxNearbyListingPrice}. `
              : `Browse all homes for sale in ${county}, ${stateorprovince}. `
            : `Browse all ${listingsForSale} homes for sale in ${county}, ${stateorprovince} between ${minPrice} and ${maxPrice}. `) +
            `${StaticDescription.MarketOverviewDescription} in ${county} County. ${StaticDescription.MLSListingsUpdateTimeDescription}`;
    case PlaceType.Subdivision:
      return listingsMlsStatus === 'Sold'
        ? (soldListingsTimeframe > 30
            ? soldListingsForSale > 1
              ? `Browse houses sold for the last year in ${subdivision} between ${minSoldListingPrice} and ${maxSoldListingPrice}. `
              : `Browse houses sold for the last year in ${subdivision}. `
            : soldListingsForSale > 1
            ? `Find recently sold homes in ${subdivision} between ${minSoldListingPrice} and ${maxSoldListingPrice}. `
            : `Find recently sold homes in ${subdivision}. `) +
            `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        : (Number(listingsForSale) < 2
            ? nearbyListingsForSale
              ? `Find ${nearbyListingsForSale} homes for sale in ${subdivision} and nearby between ${minNearbyListingPrice} and ${maxNearbyListingPrice}. `
              : `Find homes for sale in ${subdivision}. `
            : `Find ${listingsForSale} homes for sale in ${subdivision} between ${minPrice} and ${maxPrice}. `) +
            `${StaticDescription.MarketOverviewDescription} in ${subdivision}. ${StaticDescription.MLSListingsUpdateTimeDescription}`;
    case PlaceType.PostalCode:
      return listingsMlsStatus === 'Sold'
        ? (soldListingsTimeframe > 30
            ? `Browse houses sold for the last year in ${postalcode}. `
            : `Find recently sold homes in ${postalcode}. `) +
            `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        : (Number(listingsForSale) < 2
            ? nearbyListingsForSale
              ? `Browse all ${nearbyListingsForSale} homes for sale in ${postalcode} and nearby. `
              : `Browse homes for sale in ${postalcode}. `
            : `Browse all ${listingsForSale} homes for sale in ${postalcode}. `) +
            `${StaticDescription.MarketOverviewDescription} in ${postalcode}.`;
    case PlaceType.School:
      return listingsMlsStatus === 'Sold'
        ? (soldListingsTimeframe > 30
            ? `Browse houses sold for the last year near ${school}. `
            : `Find recently sold homes near ${school}. `) +
            `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        : (Number(listingsForSale) < 2
            ? nearbyListingsForSale
              ? `Browse all ${nearbyListingsForSale} homes for sale near ${school} and nearby. `
              : `Browse homes for sale near ${school}. `
            : `Browse all ${listingsForSale} homes for sale near ${school}. `) +
            `${StaticDescription.MarketOverviewDescription} near ${school}.`;
    case PlaceType.SchoolDistrict:
      return listingsMlsStatus === 'Sold'
        ? (soldListingsTimeframe > 30
            ? `Browse houses sold for the last year in ${schooldistrict}. `
            : `Find recently sold homes in ${schooldistrict}. `) +
            `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        : (Number(listingsForSale) < 2
            ? nearbyListingsForSale
              ? `Browse all ${nearbyListingsForSale} homes for sale in ${schooldistrict} and nearby. `
              : `Browse homes for sale in ${schooldistrict}. `
            : `Browse all ${listingsForSale} homes for sale in ${schooldistrict}. `) +
            `${StaticDescription.MarketOverviewDescription} in ${schooldistrict}.`;
    case PlaceType.MlsArea:
      return listingsMlsStatus === 'Sold'
        ? (soldListingsTimeframe > 30
            ? `Browse houses sold for the last year in MLS area ${mlsarea}. `
            : `Find recently sold homes in MLS area ${mlsarea}. `) +
            `${StaticDescription.MarketOverviewSoldHomesDescription}. ${StaticDescription.MLSListingsUpdateTimeDescription}`
        : (Number(listingsForSale) < 2
            ? nearbyListingsForSale
              ? `Browse all ${nearbyListingsForSale} homes for sale in MLS area ${mlsarea} and nearby. `
              : `Browse homes for sale in MLS area ${mlsarea}. `
            : `Browse all ${listingsForSale} homes for sale in MLS area ${mlsarea}. `) +
            `${StaticDescription.MarketOverviewDescription} in ${mlsarea}.`;
    default:
      return `Browse all ${listingsForSale} ${filterLabel} in ${label}. ${StaticDescription.MarketOverviewDescription} in ${label}.`;
  }
};

export const getListingDetailsPageTitle = (
  listing: ListingWithDetails
): string => {
  const isMyListing = getIsExclusiveListing(listing.source);
  const id = listing.sourcelistingid || listing.listingid;

  return `${listing.address} - ${isMyListing ? 'EL#' : 'MLS#'} ${id}`;
};

export const getListingDetailsPageDescription = (
  listing: ListingWithDetails
): string => {
  const isMyListing = getIsExclusiveListing(listing.source);
  const id = listing.sourcelistingid || listing.listingid;

  const listingPublicremarks = listing.publicremarks
    ? ` · ${listing.publicremarks}`
    : '';

  const listingPrice = MlsStatusesWithoutPrice.includes(listing.mlsstatus)
    ? ''
    : ` ${listing.listprice} ·`;

  return `${listing.propertyType} for sale at ${
    listing.address
  } ·${listingPrice} ${
    isMyListing ? 'EL#' : 'MLS#'
  } ${id}${listingPublicremarks}`;
};

export const getCollectionsPageTitle = (contact: Contact): string => {
  const prefix = contact ? `${contact.firstname} ${contact.lastname} - ` : '';
  return `${prefix} ${StaticTitle.CollectionsPageTitle}`;
};

interface ListingsPageTitleOptions {
  isLeasePage?: boolean;
  filterKey?: keyof Partial<FiltersState>;
  maxPrice: string;
  hasMetaForMinPrice: boolean;
  bedroomsCount?: number;
  bathroomsCount?: number;
  viewsOption: string;
  waterfrontOption: string;
}

export const getCommunityFeaturesTitle = ({
  isLeasePage = false,
  filterKey,
}: {
  isLeasePage?: boolean;
  filterKey?: keyof Partial<FiltersState>;
}): string => {
  const titleText = isLeasePage
    ? StaticTitle.ListingsLeasePageWithFilterTitle
    : StaticTitle.ListingsPageWithFilterTitle;
  const defaultTitle = isLeasePage
    ? config.seoData.listings.leasePageTitle
    : config.seoData.listings.pageTitle;
  switch (filterKey) {
    case FiltersToUseInMeta.homeownersAssoc:
      return `${config.seoData.envName} Homes ${titleText} Homeowners Association | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.gatedCommunity:
      return `Search ${config.seoData.envName} Homes ${titleText} with Gated Community | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.communityPool:
      return `Search ${config.seoData.envName} Homes ${titleText} with Community Pool | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.playground:
      return `Search ${config.seoData.envName} Homes ${titleText} with Outdoor Playground | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.dogPark:
      return `Search ${config.seoData.envName} Homes ${titleText} with Dog Park | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.fitnessCenter:
      return `Search ${config.seoData.envName} Homes ${titleText} with Fitness Center and Workout Facilities | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.golfCourse:
      return `Search ${config.seoData.envName} Golf Course Homes ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.sportCourt:
      return `Search ${config.seoData.envName} Homes ${titleText} with Sport Court | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.tennisCourts:
      return `Search ${config.seoData.envName} Homes ${titleText} with Tennis Court | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.horsesAllowed:
      return `Search ${config.seoData.envName} Homes ${titleText}, Horses Allowed | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.airplaneFacilities:
      return `Search ${config.seoData.envName} Homes ${titleText} with Airplane Facilities | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.lakeAccess:
      return `Search ${config.seoData.envName} Lake Access Homes ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.hikingTrails:
      return `Search ${config.seoData.envName} Homes ${titleText} near Hiking Trails | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.clubHouse:
      return `Search ${config.seoData.envName} Homes ${titleText} with Private Clubhouse | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.boatDock:
      return `Search ${config.seoData.envName} Homes ${titleText} with Boat Dock | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.park:
      return `Search ${config.seoData.envName} Homes ${titleText} in Park | ${config.seoData.listings.title}`;
    default:
      return defaultTitle;
  }
};

export const getListingsPageTitle = ({
  isLeasePage = false,
  filterKey,
  maxPrice,
  hasMetaForMinPrice,
  bedroomsCount,
  bathroomsCount,
  viewsOption,
  waterfrontOption,
}: ListingsPageTitleOptions): string => {
  const titleText = isLeasePage
    ? StaticTitle.ListingsLeasePageWithFilterTitle
    : StaticTitle.ListingsPageWithFilterTitle;
  const defaultTitle = isLeasePage
    ? config.seoData.listings.leasePageTitle
    : config.seoData.listings.pageTitle;

  switch (filterKey) {
    case FiltersToUseInMeta.privateDock:
      return `${config.seoData.envName} houses with Private Dock ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.backsToGolfCourse:
      return `${config.seoData.envName} Golf Course Homes ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.backsToGreenbelt:
      return `${config.seoData.envName} Greenbelt Homes ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.farm:
      return `Search ${config.seoData.envName} Farm house and Ranch ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.multiFamily:
      return `Search ${config.seoData.envName} Multifamily houses ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.condominium:
      return `Search ${config.seoData.envName} Condos ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.townhouse:
      return `Search ${config.seoData.envName} Townhomes ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.singleFamilyResidence:
      return `Search ${config.seoData.envName} Single Family Homes ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.commercialSale:
      return `Search ${config.seoData.envName} Commercial Real Estate ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.views:
      return `Search ${config.seoData.envName} ${viewsOption} view Homes ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.waterBodyName:
      return `Search ${config.seoData.envName} homes with waterfront ${waterfrontOption} ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.swimmingPool:
      return `${config.seoData.envName} Homes ${titleText} with Pool | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.guestQuarters:
      return `${config.seoData.envName} Homes with Guest Quarters ${titleText} |${config.seoData.listings.title}`;
    case FiltersToUseInMeta.fencedYard:
      return `${config.seoData.envName} houses with Fenced Yard ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.maxPrice:
      return `${config.seoData.envName} Homes ${titleText} under ${maxPrice} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.minPrice:
      return hasMetaForMinPrice
        ? `Search ${config.seoData.envName} Luxury Homes and exclusive Houses ${titleText} | ${config.seoData.listings.title}`
        : defaultTitle;
    case FiltersToUseInMeta.minBedrooms:
    case FiltersToUseInMeta.maxBedrooms:
      return `Search ${config.seoData.envName} ${bedroomsCount} Bedroom Homes ${titleText} | ${config.seoData.listings.title}`;
    case FiltersToUseInMeta.workshop:
      return `Search ${config.seoData.envName} Homes ${titleText} with workshop | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.energyEfficient:
      return `Search Energy Efficient ${config.seoData.envName} Homes ${titleText} | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.wineRoom:
      return `Search ${config.seoData.envName} Homes ${titleText} with wine room | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.historicDesign:
      return `Search ${config.seoData.envName} Homes ${titleText} with historic design | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.officeStudy:
      return `Search ${config.seoData.envName} Homes office study ${titleText} | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.accessibilityFeatures:
      return `Search ${config.seoData.envName} Homes ${titleText} with accessibility features | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.mediaRoom:
      return `Search ${config.seoData.envName} Homes ${titleText} with media room | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.fireplace:
      return `Search ${config.seoData.envName} Homes ${titleText} with fireplace | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.gameRoom:
      return `Search ${config.seoData.envName} Homes ${titleText} with game room | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.exerciseRoom:
      return `Search ${config.seoData.envName} Homes ${titleText} with room for exercise, fitness training | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.masterOnMain:
      return `Search ${config.seoData.envName} Homes ${titleText} with Master on Main | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.minBathrooms:
      return `Search ${config.seoData.envName} ${bathroomsCount} Bathroom Homes ${titleText} | ${config.seoData.envName} Home Listings`;
    case FiltersToUseInMeta.openHouseOnly:
      return `Search ${config.seoData.envName} Open House Homes ${titleText} | ${config.seoData.envName} Home Listings`;
    default:
      return defaultTitle;
  }
};

export const getCommunityFeaturesDescription = ({
  total,
  filterKey,
  isLeasePage,
}: {
  total: number | string;
  filterKey: keyof Partial<FiltersState>;
  isLeasePage: boolean;
}): string => {
  const titleText = isLeasePage
    ? StaticTitle.ListingsLeasePageWithFilterTitle
    : StaticTitle.ListingsPageWithFilterTitle;

  switch (filterKey) {
    case FiltersToUseInMeta.homeownersAssoc:
      return `Search ${total} ${config.seoData.envName} Homes with Homeowners Association. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.gatedCommunity:
      return `Browse all ${total} Homes ${titleText} with Gated Community in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.communityPool:
      return `Browse all ${total} Homes ${titleText} with Community Pool in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.playground:
      return `Browse all ${total} Homes ${titleText} with Outdoor Playground in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.dogPark:
      return `Browse all ${total} Homes ${titleText} with Dog Park in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.fitnessCenter:
      return `Browse all ${total} Homes ${titleText} with Fitness Center and Workout Facilities in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.golfCourse:
      return `Browse all ${total} Golf Course Homes ${titleText} with Gated Community in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.sportCourt:
      return `Browse all ${total} Homes ${titleText} with Sport Court in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.tennisCourts:
      return `Browse all ${total} Homes ${titleText} with Tennis Court in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.horsesAllowed:
      return `Browse all ${total} Homes ${titleText}, Horses Allowed in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.airplaneFacilities:
      return `Browse all ${total} Homes ${titleText} with Airplane Facilities in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.lakeAccess:
      return `Browse all ${total} Lake Access Homes ${titleText} with Gated Community in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.hikingTrails:
      return `Browse all ${total} Homes ${titleText} near Hiking Trails in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.clubHouse:
      return `Browse all ${total} Homes ${titleText} with Private Clubhouse in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.boatDock:
      return `Browse all ${total} Homes ${titleText} with Boat Dock in ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.park:
      return `Browse all ${total} Homes ${titleText} in Park, ${config.seoData.envName}. ${communityFeaturesDescription} in ${config.seoData.envName}.`;
    default:
      return `Search ${total} ${config.seoData.listings.description}`;
  }
};

export const getListingsPageDescription = ({
  total,
  filterKey,
  maxPrice,
  hasMetaForMinPrice,
  bedroomsCount,
  isLeasePage,
  bathroomsCount,
  viewsOption,
  waterfrontOption,
}: {
  total: number | string;
  filterKey: keyof Partial<FiltersState>;
  maxPrice: string;
  hasMetaForMinPrice: boolean;
  bedroomsCount?: number;
  isLeasePage: boolean;
  bathroomsCount?: number;
  viewsOption: string;
  waterfrontOption: string;
}): string => {
  const titleText = isLeasePage
    ? StaticTitle.ListingsLeasePageWithFilterTitle
    : StaticTitle.ListingsPageWithFilterTitle;

  switch (filterKey) {
    case FiltersToUseInMeta.privateDock:
      return `Search ${total} ${config.seoData.envName} Homes with Private Dock ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.backsToGolfCourse:
      return `Search ${total} ${config.seoData.envName} Homes that back to Golf Course ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.backsToGreenbelt:
      return `Search ${total} ${config.seoData.envName} Homes that back to greenbelt areas ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.swimmingPool:
      return `Search ${total} ${config.seoData.envName} Homes with Pool ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.views:
      return `Search ${total} ${config.seoData.envName} Homes with ${viewsOption} view. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.waterBodyName:
      return `Search ${total} Homes with waterfront ${waterfrontOption}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.farm:
      return `Search ${total} ${config.seoData.envName} Farm house and Ranch ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.multiFamily:
      return `Search ${total} ${config.seoData.envName} Multifamily houses ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.condominium:
      return `Search ${total} ${config.seoData.envName} Condos and Apartments ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.townhouse:
      return `Search ${total} ${config.seoData.envName} Townhomes ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.singleFamilyResidence:
      return `Search ${total} ${config.seoData.envName} Single Family Homes ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.commercialSale:
      return `Search ${total} ${config.seoData.envName} Commercial Real Estate ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.fencedYard:
      return `Search ${total} ${config.seoData.envName} Homes with fenced yard ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.guestQuarters:
      return `Search ${total} ${config.seoData.envName} Homes with Guest Quarters ${titleText}. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.maxPrice:
      return `Search ${total} ${config.seoData.envName} Homes ${titleText} under ${maxPrice} | Use our detailed filters to find the best House | ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.minPrice:
      return hasMetaForMinPrice
        ? `Search ${total} Luxury Homes, exclusive Houses ${titleText}. View photos and pricing information. Our ${config.seoData.envName} MLS Listings are updated every 5 minutes.`
        : `Search ${total} ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.minBedrooms:
    case FiltersToUseInMeta.maxBedrooms:
      return `Search ${total} ${bedroomsCount} Bedroom Homes ${titleText}. View photos and pricing information. ${config.seoData.listings.description}`;
    case FiltersToUseInMeta.workshop:
      return `Browse all ${total} Homes ${titleText} with workshop in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.energyEfficient:
      return `Browse all ${total} Energy Efficient homes ${titleText} in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.wineRoom:
      return `Browse all ${total} Homes ${titleText} with wine room in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.historicDesign:
      return `Browse all ${total} Homes ${titleText} with historic design in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.officeStudy:
      return `Browse all ${total} Homes office study ${titleText} in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.accessibilityFeatures:
      return `Browse all ${total} Homes ${titleText} with accessibility features in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.mediaRoom:
      return `Browse all ${total} Homes ${titleText} with media room in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.fireplace:
      return `Browse all ${total} Homes ${titleText} with fireplace in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.gameRoom:
      return `Browse all ${total} Homes ${titleText} with game room in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.exerciseRoom:
      return `Browse all ${total} Homes ${titleText} with room for exercise and fitness training in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.masterOnMain:
      return `Browse all ${total} Homes ${titleText} with Master on Main in ${config.seoData.envName}. Find open houses, view market trends, and see recently sold homes in ${config.seoData.envName}.`;
    case FiltersToUseInMeta.minBathrooms:
      return `Search ${total} ${bathroomsCount} Bathroom Homes ${titleText}. View photos and pricing information. Our ${config.seoData.envName} MLS Listings are updated every 5 minutes.`;
    case FiltersToUseInMeta.openHouseOnly:
      return `Search ${total} Open House Homes ${titleText}. Our ${config.seoData.envName} MLS Listings are updated every 5 minutes.`;
    default:
      return `Search ${total} ${config.seoData.listings.description}`;
  }
};
