import React from 'react';
import styled from 'styled-components/macro';

import { SEARCH_DESCRIPTION_PLACEHOLDER } from 'saved-search/savedSearchConstants';
import { SavedSearch } from 'saved-search/savedSearchTypes';
import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import {
  SearchBarItemContainer,
  SearchBarItemLabel,
  SearchBarItemSecondaryLabel,
} from 'shared/components/SearchBar/SearchBarItemStyled';
import { ButtonsCode } from 'shared/constants/appConstants';

const SecondaryLabel = styled(SearchBarItemSecondaryLabel)`
  margin-left: 8px;
`;

export interface Props {
  onClick: (savedSearchId: string) => void;
  savedSearch: SavedSearch;
  closeMenu: () => void;
}

export const SearchBarSavedSearchItem = (props: Props) => {
  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();

  const handleClick = () => {
    props.onClick(props.savedSearch.id);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ButtonsCode.Enter) {
      props.onClick(props.savedSearch.id);
      ariaLiveAnnouncerCtx.announce(`${props.savedSearch.name} selected`);
    }
    if (event.key === ButtonsCode.Esc) {
      props.closeMenu();
    }
  };

  return (
    <SearchBarItemContainer
      aria-label={props.savedSearch.name}
      onMouseDown={handleClick}
      onKeyDown={handleKeyDown}
      role="option"
      tabIndex={0}
    >
      <SearchBarItemLabel>{props.savedSearch.name}</SearchBarItemLabel>
      {props.savedSearch.description !== SEARCH_DESCRIPTION_PLACEHOLDER && (
        <SecondaryLabel>{props.savedSearch.description}</SecondaryLabel>
      )}
    </SearchBarItemContainer>
  );
};
