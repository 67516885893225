import { CollectionsActionType } from 'collections/collectionsActionType';
import {
  Collection,
  CollectionFilter,
  FetchCollectionsBody,
  RawCollection,
  UpdateCollectionBody,
} from 'collections/collectionsTypes';
import { ListingWithDetails } from 'listingDetails/listingDetailsTypes';
import { Listing } from 'listings/listingsTypes';
import { TypesOfMap } from 'map/mapConstants';
import { Invitation } from 'shared/types';

// TODO: Remove REQUEST suffix from action names, add SUCCESS suffix to correct actions

export interface UndoRemoveCollectionAction {
  type: CollectionsActionType.UNDO_REMOVE_COLLECTION;
  id: string;
}

export const undoRemoveCollection = (
  id: string
): UndoRemoveCollectionAction => ({
  type: CollectionsActionType.UNDO_REMOVE_COLLECTION,
  id,
});

export interface RemoveCollectionFromStoreAction {
  type: CollectionsActionType.REMOVE_COLLECTION_FROM_STORE;
  id: string;
}

export const removeCollectionFromStore = (
  id: string
): RemoveCollectionFromStoreAction => ({
  type: CollectionsActionType.REMOVE_COLLECTION_FROM_STORE,
  id,
});

export interface StartRemoveCollectionAction {
  type: CollectionsActionType.START_REMOVE_COLLECTION;
  id: string;
  name: string;
  cb?: (...args: unknown[]) => unknown;
  owner?: string;
}

export const startRemoveCollection = ({
  id,
  name,
  cb = () => {},
  owner,
}: {
  id: string;
  name: string;
  cb?: (...args: any) => unknown;
  owner?: string;
}): StartRemoveCollectionAction => ({
  type: CollectionsActionType.START_REMOVE_COLLECTION,
  id,
  name,
  cb,
  owner,
});

export interface RemoveCollectionRequestAction {
  type: CollectionsActionType.REMOVE_COLLECTION_REQUEST;
  id: string;
  name: string;
  cb?: (...args: any) => unknown;
  owner?: string;
}

export const removeCollectionRequest = ({
  id,
  name,
  cb = () => {},
  owner,
}: {
  id: string;
  name: string;
  cb?: (...args: unknown[]) => unknown;
  owner?: string;
}): RemoveCollectionRequestAction => ({
  type: CollectionsActionType.REMOVE_COLLECTION_REQUEST,
  id,
  name,
  cb,
  owner,
});

export interface RemoveCollectionSuccessAction {
  type: CollectionsActionType.REMOVE_COLLECTION_SUCCESS;
  id: string;
}

export const removeCollectionRequestSuccess = (
  id: string
): RemoveCollectionSuccessAction => ({
  type: CollectionsActionType.REMOVE_COLLECTION_SUCCESS,
  id,
});

export interface RemoveCollectionFailAction {
  type: CollectionsActionType.REMOVE_COLLECTION_FAIL;
  error: string;
}

export const removeCollectionFail = (
  error: string
): RemoveCollectionFailAction => ({
  type: CollectionsActionType.REMOVE_COLLECTION_FAIL,
  error,
});

export interface StartDeclineCollectionAction {
  type: CollectionsActionType.START_DECLINE_COLLECTION;
  id: string;
  name: string;
  contactId?: string;
}

export const startDeclineCollection = ({
  id,
  name,
  contactId,
}: {
  id: string;
  name: string;
  contactId?: string;
}): StartDeclineCollectionAction => ({
  type: CollectionsActionType.START_DECLINE_COLLECTION,
  id,
  name,
  contactId,
});

export interface DeclineCollectionRequestAction {
  id: string;
  name: string;
  contactId?: string;
  type: CollectionsActionType.DECLINE_COLLECTION_REQUEST;
}

export const declineCollectionRequest = ({
  id,
  name,
  contactId,
}: {
  id: string;
  name: string;
  contactId?: string;
}): DeclineCollectionRequestAction => ({
  type: CollectionsActionType.DECLINE_COLLECTION_REQUEST,
  id,
  name,
  contactId,
});

export interface DeclineCollectionFailAction {
  type: CollectionsActionType.DECLINE_COLLECTION_REQUEST_FAIL;
  error: string;
}

export const declineCollectionFail = (
  error: string
): DeclineCollectionFailAction => ({
  type: CollectionsActionType.DECLINE_COLLECTION_REQUEST_FAIL,
  error,
});

export interface DeclineCollectionSuccessAction {
  type: CollectionsActionType.DECLINE_COLLECTION_REQUEST_SUCCESS;
  id: string;
}

export const declineCollectionSuccess = (
  id: string
): DeclineCollectionSuccessAction => ({
  type: CollectionsActionType.DECLINE_COLLECTION_REQUEST_SUCCESS,
  id,
});

export interface FetchCollectionAction {
  collectionId: string;
  contactId?: string;
  limit?: number;
  page?: number;
  shouldUpdateListingsStore: boolean;
  sortBy?: string;
  sortType?: string;
  type: CollectionsActionType.FETCH_COLLECTION;
}

export const fetchCollection = (payload: {
  collectionId: string;
  contactId?: string;
  limit?: number;
  page?: number;
  // Currently on Collection page when we get collection, we set addedListings to listings store(items).
  // We use this field not to effect data in listings store when we don't need to.
  shouldUpdateListingsStore?: boolean;
  sortBy?: string;
  sortType?: string;
}): FetchCollectionAction => ({
  type: CollectionsActionType.FETCH_COLLECTION,
  shouldUpdateListingsStore: true,
  ...payload,
});

export interface JoinCollectionRequestAction {
  type: CollectionsActionType.JOIN_COLLECTION_REQUEST;
  inviteCode: string;
  cb: (...args: unknown[]) => unknown;
}

export const joinCollectionRequest = (
  inviteCode: string,
  cb: (...args: unknown[]) => unknown = () => {}
): JoinCollectionRequestAction => ({
  type: CollectionsActionType.JOIN_COLLECTION_REQUEST,
  inviteCode,
  cb,
});

export interface JoinCollectionAction {
  type: CollectionsActionType.JOIN_COLLECTION;
}

export const joinCollection = (): JoinCollectionAction => ({
  type: CollectionsActionType.JOIN_COLLECTION,
});

export interface JoinCollectionFailAction {
  type: CollectionsActionType.JOIN_COLLECTION_FAIL;
  error: string;
}

export const joinCollectionFail = (
  error: string
): JoinCollectionFailAction => ({
  type: CollectionsActionType.JOIN_COLLECTION_FAIL,
  error,
});

export interface FetchCollectionsAction {
  type: CollectionsActionType.FETCH_COLLECTIONS;
  silentUpdate: boolean;
  excludeDismissed: boolean;
  excludeProposed: boolean;
}

export const fetchCollections = ({
  silentUpdate = false,
  excludeDismissed = false,
  excludeProposed = false,
}: FetchCollectionsBody = {}): FetchCollectionsAction => ({
  type: CollectionsActionType.FETCH_COLLECTIONS,
  silentUpdate,
  excludeDismissed,
  excludeProposed,
});

export interface ResetCollectionsAction {
  type: CollectionsActionType.RESET_COLLECTIONS;
}

export const resetCollections = (): ResetCollectionsAction => ({
  type: CollectionsActionType.RESET_COLLECTIONS,
});

export interface ResetCollectionAction {
  type: CollectionsActionType.RESET_COLLECTION;
}

export const resetCollection = (): ResetCollectionAction => ({
  type: CollectionsActionType.RESET_COLLECTION,
});

export interface FetchCollectionsFailAction {
  type: CollectionsActionType.FETCH_COLLECTIONS_FAIL;
  error: string;
}

export const fetchCollectionsFail = (
  error: string
): FetchCollectionsFailAction => ({
  type: CollectionsActionType.FETCH_COLLECTIONS_FAIL,
  error,
});

export interface SetCollectionsAction {
  type: CollectionsActionType.SET_COLLECTIONS;
  collections: Collection[];
}

export const setCollections = (
  collections: Collection[]
): SetCollectionsAction => ({
  type: CollectionsActionType.SET_COLLECTIONS,
  collections,
});

export interface FilterCollectionsAction {
  type: CollectionsActionType.FILTER_COLLECTIONS;
  collectionsFilterBy: CollectionFilter;
}

export const filterCollections = (
  collectionsFilterBy: CollectionFilter
): FilterCollectionsAction => ({
  type: CollectionsActionType.FILTER_COLLECTIONS,
  collectionsFilterBy,
});

export interface UpdateCollectionRequestAction {
  type: CollectionsActionType.UPDATE_COLLECTION_REQUEST;
  body: UpdateCollectionBody;
}

export const updateCollectionRequest = (
  body: UpdateCollectionBody
): UpdateCollectionRequestAction => ({
  type: CollectionsActionType.UPDATE_COLLECTION_REQUEST,
  body,
});

export interface UpdateCollectionAction {
  type: CollectionsActionType.UPDATE_COLLECTION;
  collection?: Collection;
  collections?: Collection[];
}

export const updateCollection = (
  collection?: Collection,
  collections?: Collection[]
): UpdateCollectionAction => ({
  type: CollectionsActionType.UPDATE_COLLECTION,
  collection,
  collections,
});

export interface UpdateCollectionItemAction {
  type: CollectionsActionType.UPDATE_COLLECTION_ITEM;
  id: string;
  collection: Partial<Collection>;
}

export const updateCollectionItem = ({
  id,
  collection,
}: {
  id: string;
  collection: Partial<Collection>;
}): UpdateCollectionItemAction => ({
  type: CollectionsActionType.UPDATE_COLLECTION_ITEM,
  id,
  collection,
});

export interface UpdateCollectionItemsAction {
  type: CollectionsActionType.UPDATE_COLLECTION_ITEMS;
  items: Listing[];
}

export const updateCollectionItems = (
  items: Listing[]
): UpdateCollectionItemsAction => ({
  type: CollectionsActionType.UPDATE_COLLECTION_ITEMS,
  items,
});

interface SaveCollectionPayload {
  name: string;
  description?: string;
  comment?: string;
  listing?: Listing;
  cb?: () => void;
  owner?: string;
  notNotifyUser?: boolean;
}

export interface SaveCollectionAction {
  type: CollectionsActionType.SAVE_COLLECTION;
  payload: SaveCollectionPayload;
  silentUpdate: boolean;
}

export const saveCollection = (
  payload: SaveCollectionPayload,
  silentUpdate = false
): SaveCollectionAction => ({
  type: CollectionsActionType.SAVE_COLLECTION,
  payload,
  silentUpdate,
});

export interface SaveCollectionSuccessAction {
  type: CollectionsActionType.SAVE_COLLECTION_SUCCESS;
  id: string;
  listingid?: string;
  mapType: TypesOfMap;
  collection: RawCollection;
}

export const saveCollectionSuccess = (
  id: string,
  mapType: TypesOfMap,
  collection: RawCollection,
  listingid?: string
  // eslint-disable-next-line max-params
): SaveCollectionSuccessAction => ({
  type: CollectionsActionType.SAVE_COLLECTION_SUCCESS,
  id,
  listingid,
  mapType,
  collection,
});

interface SaveToListingsPayload {
  id: string;
  description: string;
  comment: string;
  listing: Listing;
  cb?: () => void;
  owner?: string;
  notNotifyUser?: boolean;
}

export interface SaveListingToCollectionAction {
  type: CollectionsActionType.SAVE_LISTING_TO_COLLECTION;
  payload: SaveToListingsPayload;
}

export const saveListingToCollection = (
  payload: SaveToListingsPayload
): SaveListingToCollectionAction => ({
  type: CollectionsActionType.SAVE_LISTING_TO_COLLECTION,
  payload,
});

export interface SaveListingToCollectionSuccessAction {
  type: CollectionsActionType.SAVE_LISTING_TO_COLLECTION_SUCCESS;
  id: string;
  listingid: string;
  mapType: TypesOfMap;
  collection: RawCollection;
}

export const saveListingToCollectionSuccess = (
  id: string,
  listingid: string,
  mapType: TypesOfMap,
  collection: RawCollection
  // eslint-disable-next-line max-params
): SaveListingToCollectionSuccessAction => ({
  type: CollectionsActionType.SAVE_LISTING_TO_COLLECTION_SUCCESS,
  id,
  listingid,
  mapType,
  collection,
});

export interface SaveCollectionRequestFailAction {
  type: CollectionsActionType.SAVE_COLLECTION_FAIL;
  error: string;
}

export const saveCollectionRequestFail = (
  error: string
): SaveCollectionRequestFailAction => ({
  type: CollectionsActionType.SAVE_COLLECTION_FAIL,
  error,
});

export interface RemoveCollectionItemRequestFailAction {
  type: CollectionsActionType.REMOVE_COLLECTION_ITEM_REQUEST_FAIL;
  error: string;
}

export const removeCollectionItemRequestFail = (
  error: string
): RemoveCollectionItemRequestFailAction => ({
  type: CollectionsActionType.REMOVE_COLLECTION_ITEM_REQUEST_FAIL,
  error,
});

export interface RemoveCollectionItemRequestAction {
  type: CollectionsActionType.REMOVE_COLLECTION_ITEM_REQUEST;
  collectionId: string;
  listingId: string;
  owner?: string;
}

export const removeCollectionItemRequest = (
  collectionId: string,
  listingId: string,
  owner?: string
): RemoveCollectionItemRequestAction => ({
  type: CollectionsActionType.REMOVE_COLLECTION_ITEM_REQUEST,
  collectionId,
  listingId,
  owner,
});

export interface RemoveCollectionItemAction {
  type: CollectionsActionType.REMOVE_COLLECTION_ITEM;
  id: string;
}

export const removeCollectionItem = (
  id: string
): RemoveCollectionItemAction => ({
  type: CollectionsActionType.REMOVE_COLLECTION_ITEM,
  id,
});

export interface SetLoadedCollectionAction {
  type: CollectionsActionType.SET_LOADED_COLLECTION;
  collection: Collection;
}

export const setLoadedCollection = (
  collection: Collection
): SetLoadedCollectionAction => ({
  type: CollectionsActionType.SET_LOADED_COLLECTION,
  collection,
});

export interface SetLoadedCollectionFailAction {
  type: CollectionsActionType.SET_LOADED_COLLECTION_FAIL;
  error: string;
}

export const setLoadedCollectionFail = (
  error: string
): SetLoadedCollectionFailAction => ({
  type: CollectionsActionType.SET_LOADED_COLLECTION_FAIL,
  error,
});

export interface UnshareCollectionRequestAction {
  type: CollectionsActionType.UNSHARE_COLLECTION_REQUEST;
  id: string;
  collaboratorIds: string[];
}

export const unshareCollectionRequest = (
  id: string,
  collaboratorIds: string[]
): UnshareCollectionRequestAction => ({
  type: CollectionsActionType.UNSHARE_COLLECTION_REQUEST,
  id,
  collaboratorIds,
});

export interface ShareCollectionRequestAction {
  type: CollectionsActionType.SHARE_COLLECTION_REQUEST;
  id: string;
  collaboratorEmails: string[];
}

export const shareCollectionRequest = (
  id: string,
  collaboratorEmails: string[]
): ShareCollectionRequestAction => ({
  type: CollectionsActionType.SHARE_COLLECTION_REQUEST,
  id,
  collaboratorEmails,
});

export interface RemoveFromDismissedAction {
  type: CollectionsActionType.REMOVE_FROM_DISMISSED_REQUEST;
  listing: Listing | ListingWithDetails;
  redirect: boolean;
  reFetchItems?: boolean;
}

export const removeFromDismissed = (
  listing: Listing | ListingWithDetails,
  redirect: boolean,
  reFetchItems = false
): RemoveFromDismissedAction => ({
  type: CollectionsActionType.REMOVE_FROM_DISMISSED_REQUEST,
  listing,
  redirect,
  reFetchItems,
});

export interface FetchCollectionsInvitationsAction {
  type: CollectionsActionType.FETCH_COLLECTIONS_INVITATIONS;
}

export const fetchCollectionsInvitations =
  (): FetchCollectionsInvitationsAction => {
    return {
      type: CollectionsActionType.FETCH_COLLECTIONS_INVITATIONS,
    };
  };

export interface UpdateCollectionsInvitations {
  type: CollectionsActionType.UPDATE_COLLECTIONS_INVITATIONS;
  invitations: Invitation[];
}

export function updateCollectionsInvitations(
  invitations: Invitation[]
): UpdateCollectionsInvitations {
  return {
    type: CollectionsActionType.UPDATE_COLLECTIONS_INVITATIONS,
    invitations,
  };
}

export interface AcceptCollectionInvitation {
  type: CollectionsActionType.ACCEPT_COLLECTION_INVITATION;
  inviteCode: string;
}

export function acceptCollectionInvitation(
  inviteCode: string
): AcceptCollectionInvitation {
  return {
    type: CollectionsActionType.ACCEPT_COLLECTION_INVITATION,
    inviteCode,
  };
}

export interface DeclineCollectionInvitation {
  type: CollectionsActionType.DECLINE_COLLECTION_INVITATION;
  collectionId: number;
}

export function declineCollectionInvitation(
  collectionId: number
): DeclineCollectionInvitation {
  return {
    type: CollectionsActionType.DECLINE_COLLECTION_INVITATION,
    collectionId,
  };
}

export interface SendCollectionInvitationReminderAction {
  type: CollectionsActionType.SEND_COLLECTION_INVITATION_REMINDER;
  id: string;
  collaboratorId: string;
}

export const sendCollectionInvitationReminder = (
  id: string,
  collaboratorId: string
): SendCollectionInvitationReminderAction => ({
  type: CollectionsActionType.SEND_COLLECTION_INVITATION_REMINDER,
  id,
  collaboratorId,
});

interface RecommendPayload {
  id: string;
  description: string;
  owner: string;
  name?: string;
  notNotifyUser?: boolean;
  listings: Listing[];
  comments: { [key: string]: string };
  cb?(): void;
}

export interface RecommendListingsAction {
  type: CollectionsActionType.RECOMMEND_LISTINGS;
  payload: RecommendPayload;
}

export const recommendListings = (
  payload: RecommendPayload
): RecommendListingsAction => ({
  type: CollectionsActionType.RECOMMEND_LISTINGS,
  payload,
});

export type CollectionsAction =
  | FetchCollectionAction
  | JoinCollectionRequestAction
  | JoinCollectionAction
  | JoinCollectionFailAction
  | FetchCollectionsAction
  | ResetCollectionsAction
  | ResetCollectionAction
  | FetchCollectionsFailAction
  | SetCollectionsAction
  | FilterCollectionsAction
  | RemoveCollectionFromStoreAction
  | RemoveCollectionRequestAction
  | RemoveCollectionFailAction
  | UpdateCollectionRequestAction
  | UpdateCollectionAction
  | UpdateCollectionItemsAction
  | SaveCollectionAction
  | SaveCollectionSuccessAction
  | SaveListingToCollectionAction
  | SaveListingToCollectionSuccessAction
  | SaveCollectionRequestFailAction
  | RemoveCollectionItemRequestFailAction
  | RemoveCollectionItemRequestAction
  | RemoveCollectionItemAction
  | SetLoadedCollectionAction
  | SetLoadedCollectionFailAction
  | UnshareCollectionRequestAction
  | ShareCollectionRequestAction
  | RemoveFromDismissedAction
  | UndoRemoveCollectionAction
  | RemoveCollectionSuccessAction
  | StartDeclineCollectionAction
  | DeclineCollectionRequestAction
  | DeclineCollectionSuccessAction
  | DeclineCollectionFailAction
  | StartRemoveCollectionAction
  | FetchCollectionsInvitationsAction
  | UpdateCollectionsInvitations
  | UpdateCollectionItemAction
  | AcceptCollectionInvitation
  | DeclineCollectionInvitation
  | RecommendListingsAction;
