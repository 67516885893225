import styled, { css } from 'styled-components/macro';

import { FILTER_CONTAINER_WIDTH } from 'filters/filtersContants';
import { CheckboxLabelWrapper } from 'shared/components/Checkbox/Checkbox';
import CounterLabel from 'shared/components/CounterLabel';
import { RadioGroupRoot } from 'shared/components/formikFields/RadioField';
import { RadioContainer } from 'shared/components/Radio/Radio';
import ShowMore from 'shared/components/ShowMore';
import { media } from 'styled-system/responsive';

export const collapsePanelOverridesForMoreFilters = css`
  .rc-collapse-content-box {
    padding: 16px 0 32px;
  }

  .rc-collapse-header {
    margin-left: -16px;
    width: calc(100% + 32px);
    padding: 0 24px !important;
  }
`;

export const FilterShowMore = styled(ShowMore)`
  &,
  && span,
  &&& button {
    line-height: 1;
    min-height: auto;
  }
`;

export const FilterHeaderContainer = styled.div`
  padding: 32px 0 24px 0;
  display: flex;
  align-items: center;
  position: relative;

  ${media.sm(css`
    padding: 24px 0;
  `)}
`;

export const FilterHeaderTitle = styled.span`
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.text2};
  padding: 0;
  line-height: 22px;
`;

export const FilterHeaderSubtitle = styled.span`
  font-family: Lato, sans-serif;
  font-size: 16px;
  color: #7f8e9b;
  margin-left: 8px;
`;

export const FilterSectionChangedIndicator = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  position: absolute;
  left: -12px;

  ${media.sm`
    left: -8px;
  `}
`;

export const Checkboxes = styled.div`
  width: 100%;
  margin-bottom: -16px;

  ${media.sm(css`
    margin-bottom: -24px;
  `)}
`;

export const CheckboxWrapper = styled.div`
  width: 50%;
  display: inline-block;
  margin-bottom: 16px;

  ${media.sm(css`
    margin-bottom: 24px;
  `)}
`;

export const FiltersDivider = styled.div`
  height: 32px;
  position: relative;

  ${media.sm`
    height: 24px;
  `}

  &:after {
    content: '';
    height: 1px;
    width: calc(100% + 48px);
    background-color: ${({ theme }) => theme.divider};
    position: absolute;
    bottom: 0;
    left: -24px;
    ${media.sm`
      left: -16px;
      width: calc(100% + 32px);
    `}
  }
`;

export const FiltersRadioContainer = styled(RadioGroupRoot)`
  display: flex;
  margin-bottom: 16px;

  ${RadioContainer} {
    margin-bottom: 0;
  }

  ${media.sm(css`
    margin-bottom: 24px;
  `)}
`;

export const FiltersRadioTitle = styled.span`
  font-family: Lato, sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  margin-right: 16px;
`;

export const FiltersTitleValue = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.text3};
`;

export const MoreFiltersItemContainer = styled.div<{ noTopPadding?: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 0;
  min-height: 24px;
  position: relative;
  color: ${({ theme }) => theme.text3};
  font-size: 16px;

  ${({ noTopPadding }) =>
    noTopPadding &&
    css`
      padding-top: 0;
    `}

  ${media.sm`
    padding: 12px 0;

    &:first-child {
      padding-top: 8px;
    }
  `}

  & > span {
    padding-left: 8px;
  }
`;

export const MultipleInputWrapper = styled.div`
  max-width: 326px;
  margin-bottom: 16px;
  > ${CheckboxLabelWrapper} {
    margin-bottom: 10px;
    ${media.sm(css`
      margin-bottom: 16px;
    `)}
  }

  ${media.sm(css`
    margin-bottom: 24px;
  `)}
`;

export const FiltersSection = styled.div<{
  alignFiltersToEnd?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: ${({ alignFiltersToEnd }) =>
    alignFiltersToEnd ? 'flex-end' : 'space-between'};
  align-items: center;
  font-size: 16px;
  height: 51px;

  ${media.sm`
    font-size: 14px;
    height: auto;
    margin-bottom: 16px;
  `};
`;

export const FiltersSortingSection = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: 32px;
  }
`;

export const FiltersActionButtonContainer = styled.div`
  position: relative;

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const AppliedFiltersCounter = styled(CounterLabel)`
  position: absolute;
  top: -7px;
  right: 3px;
  background-color: ${({ theme }) => theme.secondary};
`;

export const Filter = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 14px;

  button {
    & > span {
      padding-right: 0;
    }
    svg {
      fill: ${({ theme }) => theme.text3};
      width: 14px;
      height: 14px;
      margin: 0 0.2em;
      vertical-align: -3px;
    }
  }
`;

export const SelectWrapper = styled.div`
  width: 168px;
  &:nth-child(2n + 1) {
    margin-right: 74px;
  }

  ${media.sm(css`
    width: 70%;
    margin-right: 24px;
  `)};
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > ${SelectWrapper} {
    margin-bottom: 16px;
  }

  margin-bottom: -16px;

  ${media.sm(css`
    & > ${SelectWrapper} {
      margin-bottom: 24px;
    }

    margin-bottom: -24px;
  `)}
`;

export const FormSection = styled.div`
  width: ${FILTER_CONTAINER_WIDTH}px;
  max-width: 100%;
  background-color: ${({ theme }) => theme.white};
  ${media.sm`
    width: 100vw;
  `};
`;
