import { ComponentType, lazy, LazyExoticComponent } from 'react';

import { retry } from 'shared/helpers/promise';

export interface Preload<C> {
  preload: () => Promise<{ default: C }>;
}

export function loadable<C extends ComponentType<any>>(
  load: () => Promise<{ default: C }>
): LazyExoticComponent<C> & Preload<C>;

export function loadable<C extends ComponentType<any>, M>(
  load: () => Promise<M>,
  resolve: (module: M) => C
): LazyExoticComponent<C> & Preload<C>;

export function loadable<
  C extends ComponentType<any>,
  M extends { default: C }
>(load: () => Promise<M>, resolve: (module: M) => C = m => m.default) {
  const loadComponent = (): Promise<{ default: C }> => {
    return retry(load, { interval: 2000, timesLeft: 2 }).then(module => {
      return {
        default: resolve(module),
      };
    });
  };
  const Component = lazy(loadComponent);
  return Object.assign(Component, { preload: loadComponent });
}
