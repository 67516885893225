import { UserRole } from 'aws-sdk/clients/workmail';
import { keyBy } from 'lodash';

import { ACCESS_DENIED_FROM_AGENTS_ACCOUNT_MESSAGE } from 'shared/constants/errorConstants';
import { toArray } from 'shared/helpers/array';
import { APIRequestError, ApiService } from 'shared/services/ApiService';
import { executeRecaptcha } from 'shared/services/recaptcha';
import { CognitoFederatedProviders } from 'shared/types';
import {
  FavoritePlace,
  InquiryPayload,
  SendVerificationEmailPayload,
  User,
  UserProfile,
} from 'user/userTypes';

const BASE_URL = '/user';

export class UserService {
  static async getProfile() {
    return ApiService.request<UserProfile>({
      method: 'GET',
      path: `${BASE_URL}/me`,
      redirectToForbidden: false,
    });
  }

  static async updateProfile(profile: Partial<UserProfile>, userId: string) {
    return ApiService.request<UserProfile>({
      method: 'PATCH',
      path: `${BASE_URL}/${userId}`,
      data: profile,
    });
  }

  static async createProfile(profile: Partial<UserProfile>) {
    return ApiService.request<UserProfile>({
      method: 'POST',
      path: BASE_URL,
      data: profile,
    });
  }

  static async fetchUsers(usernames: string | string[]) {
    return ApiService.request<User[]>({
      method: 'GET',
      path: BASE_URL,
      query: { usernames: Array.from(new Set(toArray(usernames))) },
    });
  }

  static async fetchOwners(usernames: string | string[]) {
    const users = await UserService.fetchUsers(usernames);

    return keyBy(users, 'username');
  }

  static getFavoritePlaces(username: string) {
    return ApiService.request<{ hits: FavoritePlace[] }>({
      method: 'GET',
      path: `${BASE_URL}/favorite-place`,
      query: {
        username,
      },
    });
  }

  static createFavoritePlace<FavoritePlace>(
    place: FavoritePlace,
    username?: string
  ) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/favorite-place`,
      data: {
        place,
      },
      query: {
        username,
      },
    });
  }

  static deleteFavoritePlaces(id: number, username?: string) {
    return ApiService.request({
      method: 'DELETE',
      path: `${BASE_URL}/favorite-place/${id}`,
      query: {
        username,
      },
    });
  }

  static updateFavoritePlaces(place: FavoritePlace, username?: string) {
    return ApiService.request({
      method: 'PATCH',
      path: `${BASE_URL}/favorite-place/${place.id}`,
      data: {
        place,
      },
      query: {
        username,
      },
    });
  }

  static verifyEmail() {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/verify-email`,
    });
  }

  static sendVerificationEmail(params: SendVerificationEmailPayload) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/send-verification-email`,
      data: params,
    });
  }

  static cognitoFederatedConnect(primaryUsername: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/cognito-federated-connect`,
      data: {
        primaryUsername,
      },
    });
  }

  static cognitoFederatedDisconnect(provider: CognitoFederatedProviders) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/cognito-federated-disconnect`,
      data: {
        provider,
      },
    });
  }

  static trackUserPageHistory(data: { url: string; title: string }) {
    return ApiService.request({
      method: 'POST',
      path: `/user-page-history`,
      prefix: 'v2',
      data,
    });
  }

  static emailNotificationsSubscribe(hash: string) {
    return ApiService.request({
      method: 'PATCH',
      path: `${BASE_URL}/notifications/subscribe/${hash}`,
      data: { hash },
    });
  }

  static emailNotificationsUnsubscribe(hash: string) {
    return ApiService.request({
      method: 'PATCH',
      path: `${BASE_URL}/notifications/unsubscribe/${hash}`,
      data: { hash },
    });
  }

  static async inquiry(params: InquiryPayload) {
    const headers = new Headers();
    const token = await executeRecaptcha({
      // todo add regex to test if replaced version is valid
      action: params.type.replace(/-/g, '_'),
    });
    headers.append('re-captcha-token', token);
    return ApiService.request({
      data: params,
      headers,
      method: 'post',
      path: `${BASE_URL}/inquiry`,
      redirectToForbidden: false,
      resolve: res => res.text(),
    }).catch((error: APIRequestError) => {
      if (error.status === 403) {
        throw new Error(ACCESS_DENIED_FROM_AGENTS_ACCOUNT_MESSAGE);
      }
      throw error;
    });
  }
  static async sendFeedback(params: InquiryPayload) {
    const headers = new Headers();
    const token = await executeRecaptcha({
      action: params.type.replace(/-/g, '_'),
    });
    headers.append('re-captcha-token', token);
    return ApiService.request({
      data: params,
      headers,
      method: 'post',
      path: `${BASE_URL}/inquiry/feedback`,
      redirectToForbidden: false,
      prefix: 'v2',
      resolve: res => res.text(),
    });
  }

  static async getSocialPhotoURL() {
    return ApiService.request({
      method: 'GET',
      path: `${BASE_URL}/social-photo-url`,
      redirectToForbidden: false,
    });
  }

  static async setSocialPhotoURL(url: string) {
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/social-photo-url`,
      data: { url },
      redirectToForbidden: false,
    });
  }

  static async removeSocialPhotoURL() {
    return ApiService.request({
      method: 'DELETE',
      path: `${BASE_URL}/social-photo-url`,
      redirectToForbidden: false,
    });
  }

  static async getUserRoleByEmail(email: string) {
    return ApiService.request<UserRole>({
      method: 'POST',
      path: `${BASE_URL}/email`,
      data: { email },
      redirectToForbidden: false,
    });
  }
}
