import React, { useState } from 'react';

import Checkbox from 'shared/components/Checkbox';
import {
  Button,
  CloseButton,
  PopupContent,
  PopupIcon,
  PopupMessage,
  PopupWrapper,
} from 'shared/components/PopupNotification/PopupNotificationStyled';
import { ReactComponent as IconClose } from 'shared/icons/shared/24x24/cancel.svg';
import { ReactComponent as IconBell } from 'shared/icons/shared/bell.svg';

interface Props {
  message: string;
  onClose: () => void;
  onSubmit: (body: { checked: boolean }) => void;
}

export const PopupNotification = (props: Props) => {
  const { message, onClose, onSubmit } = props;
  const [checked, setChecked] = useState(false);

  return (
    <PopupWrapper>
      <PopupIcon>
        <IconBell />
      </PopupIcon>
      <PopupContent>
        <PopupMessage>{message}</PopupMessage>
        <Checkbox
          labelText="Don't show me this message again"
          onChange={() => setChecked(!checked)}
          marginBottom={2}
        />
        <Button onClick={() => onSubmit({ checked })}>Got it!</Button>
      </PopupContent>
      <CloseButton onClick={onClose} aria-label="Close">
        <IconClose />
      </CloseButton>
    </PopupWrapper>
  );
};

export default PopupNotification;
