export enum butterCmsComponent {
  actionBlock = 'action_block',
  blogFeed = 'blog_feed',
  bragBar = 'brag_bar',
  carouselBlock = 'carousel_block',
  consumerInfo = 'consumer_info',
  contactUsForm = 'contact_us_form',
  freeWhitepaper = 'free_whitepaper',
  heading = 'heading',
  homeHero = 'home_hero',
  homeVideoSection = 'home_video_section',
  homewardBenefits = 'homeward_benefits',
  homewardCost = 'homeward_cost',
  homewardFooter = 'homeward_footer',
  homewardHero = 'homeward_hero',
  howToSellYourHome = 'how_to_sell_your_home',
  infoBlock = 'info_block',
  linkBlock = 'link_block',
  officeBlock = 'office_block',
  sellHero = 'sell_hero',
  stepper = 'stepper',
  stepsToSuccess = 'steps_to_success',
  testimonialsSection = 'testimonials_section',
  testimonialsVideoCarousel = 'testimonials_video_carousel',
  textBlock = 'text_block',
  twoColumnsWysiwyg = 'two_columns_wysiwyg',
  videoBlock = 'video_block',
  wysiwyg = 'wysiwyg',
  imagesSlider = 'images_slider',
}

export enum PostType {
  post = 'post',
  video = 'video',
}

export enum BlogSortingValues {
  publishedDate = 'published_date',
  title = 'title',
}

export const BUTTER_CMS_BASE_URL = 'https://api.buttercms.com/v2';

export const POSTS_PER_BLOG_PAGE = 9;
export const POSTS_PER_CATEGORY_PAGE = 20;
export const BUTTER_CMS_TIMEOUT = 10000;
