import { cloneDeep, set } from 'lodash';
import { DeepReadonly } from 'utility-types';

import { CollectionsAction } from 'collections/collectionsActions';
import { CollectionsActionType } from 'collections/collectionsActionType';
import { CommentsAction, CommentsActionType } from 'comments/commentsActions';
import { LISTING_DETAILS_DEFAULT_ZOOM } from 'listingDetails/listingDetailsConstants';
import { ListingsAction, ListingsActionType } from 'listings/listingsActions';
import { addCollectionIdInListing } from 'listings/listingsHelpers';
import { detectMapType } from 'listings/listingsSagas';
import { Listing } from 'listings/listingsTypes';
import { MapAction, MapActionType } from 'map/mapActions';
import {
  CUSTOM_POLYGON_TYPE,
  DEFAULT_CENTER,
  DEFAULT_MAP_ZOOM,
  DrawingStatus,
  MapItemTypes,
  MapViewType,
} from 'map/mapConstants';
import { getLoadedClustersWithImages } from 'map/mapSelectors';
import { Cluster, Group, MapInnerState, MapState } from 'map/mapTypes';
import * as search from 'search/search-slice';
import { Tag } from 'search/search-types';
import { not, notEmpty } from 'shared/helpers/boolean';

export const MAP_INNER_DEFAULT_STATE: MapInnerState = {
  isMapInitialized: false,
  isMapVisible: true,
  mapSize: null,
  zoom: DEFAULT_MAP_ZOOM,
  center: DEFAULT_CENTER,
  mapBoundary: null,
  polygons: [],
  clusters: [],
  drawingStatus: DrawingStatus.NoDrawing,
  drawingPolygons: null,
  loading: false,
  currentMapView: MapViewType.Roadmap,
  streetViewActive: false,
  forceHideMap: false,
  savingPolygon: false,
};

export const MAP_DEFAULT_STATE: MapState = {
  listings: { ...MAP_INNER_DEFAULT_STATE },
  lease: { ...MAP_INNER_DEFAULT_STATE },
  collections: { ...MAP_INNER_DEFAULT_STATE },
  viewedListings: { ...MAP_INNER_DEFAULT_STATE },
  savedSearches: { ...MAP_INNER_DEFAULT_STATE },
  agentListings: { ...MAP_INNER_DEFAULT_STATE },
  agentSavedSearches: { ...MAP_INNER_DEFAULT_STATE },
  listingDetails: {
    ...MAP_INNER_DEFAULT_STATE,
    zoom: LISTING_DETAILS_DEFAULT_ZOOM,
  },
  listingDetailsModal: {
    ...MAP_INNER_DEFAULT_STATE,
    zoom: LISTING_DETAILS_DEFAULT_ZOOM,
  },
  bioPage: { ...MAP_INNER_DEFAULT_STATE },
  createListing: { ...MAP_INNER_DEFAULT_STATE },
};

export function mapReducer(
  state: DeepReadonly<MapState> = MAP_DEFAULT_STATE,
  action: MapAction | ListingsAction | CollectionsAction | CommentsAction
): DeepReadonly<MapState> {
  if (search.removeTag.match(action)) {
    const mapType = detectMapType({ action: action.payload });
    const tag = action.payload.tag;
    return {
      ...state,
      [mapType]: {
        ...state[mapType],
        polygons: removeTagPolygon(state[mapType].polygons, tag),
        drawingStatus:
          tag.type === CUSTOM_POLYGON_TYPE && tag.isTemporary
            ? DrawingStatus.NoDrawing
            : state[mapType].drawingStatus,
      },
    };
  }

  if (search.removeAllTags.match(action)) {
    const mapType = detectMapType({ action: action.payload });
    return {
      ...state,
      [mapType]: {
        ...state[mapType],
        drawingStatus: DrawingStatus.NoDrawing,
        polygons: MAP_DEFAULT_STATE[mapType].polygons,
      },
    };
  }

  switch (action.type) {
    case MapActionType.TOGGLE_MAP: {
      const toggleMap = action.mobileOnly ? action.isMobile : true;
      if (not(toggleMap)) {
        return state;
      }
      if (state[action.mapType].isMapVisible || !action.isMapVisible) {
        return {
          ...state,
          [action.mapType]: {
            ...state[action.mapType],
            isMapVisible: action.isMapVisible,
          },
        };
      }

      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          isMapVisible: action.isMapVisible,
          zoom: state[action.mapType].zoom,
          center: { ...state[action.mapType].center },
          mapBoundary: notEmpty(state[action.mapType].mapBoundary)
            ? { ...state[action.mapType].mapBoundary }
            : state[action.mapType].mapBoundary,
        },
      };
    }

    case MapActionType.CHANGE_MAP_SIZE: {
      const mapBoundary = action.boundaries
        ? action.boundaries
        : state[action.mapType].mapBoundary;
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          mapSize: action.size,
          mapBoundary,
        },
      };
    }

    case MapActionType.CHANGE_MAP_POSITION:
    case MapActionType.CHANGE_MAP_POSITION_FROM_URL:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          mapBoundary: { ...action.boundaries },
          zoom: action.zoom,
        },
      };

    case MapActionType.CLEAR_MAP_DATA:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          ...MAP_INNER_DEFAULT_STATE,
          isMapVisible: action.leaveIsMapVisible
            ? state[action.mapType].isMapVisible
            : MAP_INNER_DEFAULT_STATE.isMapVisible,
        },
      };

    case MapActionType.SOFT_CLEAR_MAP_DATA: {
      const mapType: keyof MapState = action.mapType;
      return {
        ...state,
        [mapType]: {
          ...MAP_DEFAULT_STATE[action.mapType],
          isMapInitialized: state[mapType].isMapInitialized,
          mapSize: { ...state[mapType].mapSize },
          isMapVisible: state[mapType].isMapVisible,
        },
      };
    }

    case MapActionType.INITIALIZE_MAP:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          isMapInitialized: true,
        },
      };

    case MapActionType.FETCH_POLYGONS_SUCCESS: {
      let polygons = state[action.mapType].polygons;
      const isItANewPolygon =
        polygons.findIndex(polygon => polygon.id === action.polygons.id) === -1;
      if (isItANewPolygon) {
        polygons = [...state[action.mapType].polygons].concat(action.polygons);
      }
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          polygons,
        },
      };
    }

    case MapActionType.FETCH_MARKER_IMAGES_SUCCESS:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          clusters: state[action.mapType].clusters.map(cluster => {
            if (
              cluster.type === MapItemTypes.Group &&
              Array.isArray(cluster.data)
            ) {
              const group = cluster as Cluster<Listing[]>;

              for (const item of group.data) {
                if (item.listingkey === action.markerKey) {
                  const itemCopy = { ...item, images: action.images };
                  const itemIndex = group.data.findIndex(
                    item => item.listingkey === action.markerKey
                  );
                  const data = [...group.data];
                  data[itemIndex] = itemCopy;

                  return {
                    ...cluster,
                    data,
                  };
                }
              }
            }

            return (cluster as Cluster<Listing>).data.listingkey ===
              action.markerKey
              ? {
                  ...cluster,
                  data: {
                    ...cluster.data,
                    images: action.images,
                  },
                }
              : cluster;
          }),
        },
      };

    case MapActionType.SAVE_CLUSTERS: {
      const clustersWithImages = getLoadedClustersWithImages(
        state[action.mapType]
      );

      const updatedClusters = action.clusters.map(cluster => {
        if (cluster.type === MapItemTypes.Group) {
          const group = cluster as unknown as Group;

          group.data.forEach(
            item => (item.images = clustersWithImages[item.listingkey] || [])
          );

          return group;
        }

        cluster.data.images = clustersWithImages[cluster.data.listingkey] || [];

        return cluster;
      });

      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          clusters: updatedClusters,
        },
      };
    }

    case ListingsActionType.ADD_LISTING_RECOMMENDATION: {
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          clusters: state[action.mapType].clusters.map(cluster =>
            cluster.type === MapItemTypes.Listing &&
            action.listingIds.includes(
              (cluster as Cluster<Listing>).data.listingid
            )
              ? {
                  ...cluster,
                  data: {
                    ...cluster.data,
                    recommendations: [
                      ...(cluster as Cluster<Listing>).data.recommendations,
                      {
                        user: action.user,
                        owner: action.owner,
                      },
                    ],
                  },
                }
              : cluster
          ),
        },
      };
    }

    case CollectionsActionType.SAVE_LISTING_TO_COLLECTION_SUCCESS:
    case CollectionsActionType.SAVE_COLLECTION_SUCCESS:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          clusters: state[action.mapType].clusters.map(clusterOrigin => {
            const cluster = cloneDeep(clusterOrigin);

            if (
              cluster.type === MapItemTypes.Group &&
              Array.isArray(cluster.data)
            ) {
              const group = cluster as Cluster<Listing[]>;

              for (const [i, item] of group.data.entries()) {
                if (item.listingid === action.listingid) {
                  const newDataArray = [...group.data];

                  newDataArray[i] = {
                    ...item,
                    collections: [...item.collections, { id: action.id }],
                  };

                  return {
                    ...group,
                    data: newDataArray.map((listing: Listing) =>
                      addCollectionIdInListing({
                        listing,
                        collectionID: action.id,
                        actionListingID: action.listingid,
                      })
                    ),
                  } as Cluster<Listing[]>;
                }
              }
            }

            set(
              cluster,
              'data',
              addCollectionIdInListing({
                listing: (cluster as Cluster<Listing>).data,
                collectionID: action.id,
                actionListingID: action.listingid,
              })
            );

            return (cluster as Cluster<Listing>).data.listingid ===
              action.listingid && Boolean(action.listingid)
              ? {
                  ...cluster,
                  data: {
                    ...cluster.data,
                    collections: [
                      ...(cluster as Cluster<Listing>).data.collections,
                      action.id,
                    ],
                  },
                }
              : cluster;
          }),
        },
        collections: {
          ...state.collections,
          clusters: state.collections.clusters.map(cluster => {
            const item = cluster as Cluster<Listing>;
            set(
              cluster,
              'data',
              addCollectionIdInListing({
                listing: item.data,
                actionListingID: action.listingid,
                collectionID: action.id,
              })
            );

            const { type, data } = item;

            if (
              type === 'cluster' ||
              data.listingid !== action.listingid ||
              not(action.listingid)
            ) {
              return cluster as Cluster;
            }

            return {
              ...cluster,
              data: {
                ...data,
                collections: [...data.collections, { id: action.id }],
              },
            };
          }),
        },
      };

    case MapActionType.CHANGE_MAP_DRAWING_STATUS: {
      let currentDrawingPolygons = {
        ...state[action.mapType].drawingPolygons,
      };
      if (action.drawingStatus === DrawingStatus.NoDrawing) {
        currentDrawingPolygons = null;
      }
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          drawingStatus: action.drawingStatus,
          drawingPolygons: currentDrawingPolygons,
        },
      };
    }

    case MapActionType.CHANGE_MAP_DRAWING_POLYGON: {
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          drawingPolygons: action.feature,
          savingPolygon: true,
        },
      };
    }

    case MapActionType.ADD_MAP_DRAWING_POLYGON:
    case MapActionType.SAVE_DRAWING_PALYGON_FAIL: {
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          savingPolygon: false,
        },
      };
    }

    case MapActionType.HANDLE_MAP_LOADING:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          loading: action.loading,
        },
      };

    case MapActionType.CHANGE_MAP_VIEW_TYPE:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          currentMapView: action.mapViewType,
          streetViewActive: action.streetViewActive,
        },
      };

    case MapActionType.FORCE_TOGGLE_MAP:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          forceHideMap: action.forceHideMap,
        },
      };

    case MapActionType.RESET_POLYGONS:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          polygons: MAP_INNER_DEFAULT_STATE.polygons,
        },
      };

    case CommentsActionType.SAVE_COMMENT_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          clusters: state.collections.clusters.map(cluster => {
            const item = cluster as Cluster<Listing>;
            const { data } = item;
            return item.data.listingid === action.listingid
              ? {
                  ...item,
                  data: { ...data, commentCount: item.data.commentCount + 1 },
                }
              : { ...item };
          }),
        },
      };

    case MapActionType.SET_IS_MAP_VISIBLE:
      return {
        ...state,
        [action.mapType]: {
          ...state[action.mapType],
          isMapVisible: action.isMapVisible,
        },
      };

    default:
      return state;
  }
}

function removeTagPolygon(polygons: DeepReadonly<any[]>, tag: Tag) {
  if (!tag.id) return polygons;

  const polygonsCopy = [...polygons];

  const index = polygonsCopy.findIndex(polygon => polygon.id === tag.id);

  if (index > -1) {
    polygonsCopy.splice(index, 1);
  }

  return polygonsCopy;
}
