import { LastLocation } from 'contacts/contactsTypes';
import { RawHtml } from 'history/emailHistory/emailHistoryTypes';
import { SortOrder } from 'shared/constants/appConstants';

export interface InquiryHistoryState extends InquiryHistoryFiltersAndSorting {
  items: InquiryHistoryItem[];
  inquiryRawHtmls: RawHtml;
  page: number;
  limit: number;
  total: number;
  loading: boolean;
  error: string;
}

export interface InquiryHistoryItem {
  type: TypesOfInquiryHistory;
  username: string;
  agentId: string;
  city?: string;
  createdAt: string;
  email: string;
  id: number;
  identityProvider: string;
  ip: string;
  location: LastLocation;
  landingPage: string;
  lastname: string;
  listingId?: string;
  message: string;
  originalSource: string;
  phone: string;
  source?: string;
  state: string;
  streetAddress: string;
  submittedPage: string;
  tourDateTime?: string;
  zipCode?: string;
  messageId: string;
  subject: string;
  status: string;
  sentAt: string;
  openedAt: string;
  bouncedAt: string;
  complainedAt: string;
  clickedAt: string;
}

export enum TypesOfInquiryHistory {
  AskAQuestion = 'ask-a-question',
  BioInquiry = 'bio-inquiry',
  BuyBeforeYouSell = 'buy-before-you-sell',
  ContactUs = 'contact-us',
  HomePriceEstimate = 'request-home-price-estimate',
  ListingQuestion = 'listing-question',
  Registration = 'registration',
  RelocationGuide = 'relocation-guide',
  RequestASpecialist = 'request-a-specialist',
  RequestConsultation = 'request-consultation',
  ScheduleATour = 'schedule-a-tour',
  ScheduleAVideoTour = 'schedule-a-video-tour',
  RequestAnInterview = 'request-an-interview',
  RequestFreeWhitepaper = 'request-free-whitepaper',
  LeftWebsiteFeedback = 'feedback',
  ReferMe = 'refer-me',
  ReviewMe = 'review-me',
}

export enum InquiryHistoryAllOption {
  All = 'all',
}
export enum UnitedAskAQuestionBio {
  AskAQuestion = 'ask-a-question,bio-inquiry',
}

export type TypesOfInquiryHistoryFilters =
  | TypesOfInquiryHistory
  | InquiryHistoryAllOption
  | UnitedAskAQuestionBio;

export interface InquiryHistoryQuery {
  page: number;
  sort: SortOrder;
  username: string;
  inquiryType?: TypesOfInquiryHistoryFilters;
  periodStart?: string;
  periodEnd?: string;
}

export interface InquiryHistoryFiltersAndSorting {
  sortBy: string;
  sortType: SortOrder;
  inquiryType: TypesOfInquiryHistoryFilters;
  period: number;
}

export interface InquiryHistoryAllFilters
  extends InquiryHistoryFiltersAndSorting {
  page: number;
}

export enum InquiryHistorySortBy {
  DATETIME = 'time',
}
