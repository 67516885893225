import { DeepReadonly } from 'utility-types';

import { CollectionsAction } from 'collections/collectionsActions';
import { CollectionsActionType } from 'collections/collectionsActionType';
import {
  CollectionFilter,
  CollectionNotification,
  CollectionsState,
} from 'collections/collectionsTypes';
import { not } from 'shared/helpers/boolean';

export const COLLECTIONS_DEFAULT_STATE: CollectionsState = {
  collection: {
    addedListings: [],
    agentId: '',
    agentMode: false,
    agentNotified: false,
    collaboratorCount: 0,
    collaborators: [],
    commentCount: 0,
    createdAt: '',
    createdBy: '',
    description: '',
    id: '',
    images: [],
    loading: false,
    name: '',
    notifications: {} as CollectionNotification,
    owner: '',
    source: '',
    total: 0,
    updatedAt: '',
    user: undefined,
  },
  collections: [],
  collectionsFilterBy: CollectionFilter.All,
  collectionsInvitations: [],
  collectionsToRemove: [],
  error: '',
  loading: false,
};

export function collectionsReducer(
  state: DeepReadonly<CollectionsState> = COLLECTIONS_DEFAULT_STATE,
  action: CollectionsAction
): DeepReadonly<CollectionsState> {
  switch (action.type) {
    case CollectionsActionType.FETCH_COLLECTIONS:
      return { ...state, loading: not(action.silentUpdate) };

    case CollectionsActionType.FETCH_COLLECTION:
      return {
        ...state,
        collection: { ...state.collection, loading: true },
      };

    case CollectionsActionType.SET_COLLECTIONS:
      return {
        ...state,
        collections: action.collections,
        loading: false,
      };

    case CollectionsActionType.FILTER_COLLECTIONS:
      return { ...state, collectionsFilterBy: action.collectionsFilterBy };

    case CollectionsActionType.FETCH_COLLECTIONS_FAIL:
      return { ...state, error: action.error, loading: false };

    case CollectionsActionType.REMOVE_COLLECTION_FROM_STORE:
      return {
        ...state,
        collectionsToRemove: [...state.collectionsToRemove, action.id],
      };

    case CollectionsActionType.DECLINE_COLLECTION_REQUEST_SUCCESS:
    case CollectionsActionType.REMOVE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: state.collections.filter(({ id }) => id !== action.id),
        collectionsToRemove: state.collectionsToRemove.filter(
          collection => collection !== action.id
        ),
      };

    case CollectionsActionType.UNDO_REMOVE_COLLECTION:
      return {
        ...state,
        collectionsToRemove: state.collectionsToRemove.filter(
          collection => collection !== action.id
        ),
      };

    case CollectionsActionType.DECLINE_COLLECTION_REQUEST_FAIL:
    case CollectionsActionType.REMOVE_COLLECTION_FAIL:
      return { ...state, error: action.error };

    case CollectionsActionType.SAVE_COLLECTION:
      return { ...state, loading: true };

    case CollectionsActionType.SAVE_COLLECTION_SUCCESS:
      return { ...state, loading: false };

    case CollectionsActionType.SAVE_COLLECTION_FAIL:
      return { ...state, error: action.error, loading: false };

    case CollectionsActionType.SET_LOADED_COLLECTION:
      return {
        ...state,
        error: '',
        collection: { ...action.collection, loading: false },
      };

    case CollectionsActionType.SET_LOADED_COLLECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case CollectionsActionType.REMOVE_COLLECTION_ITEM:
      return {
        ...state,
        collection: {
          ...state.collection,
          total: state.collection.total - 1 || 0,
        },
      };

    case CollectionsActionType.UPDATE_COLLECTION:
      const {
        collection = { ...state.collection },
        collections = [...state.collections],
      } = action;
      return { ...state, collection, collections };

    case CollectionsActionType.UPDATE_COLLECTION_ITEM:
      return {
        ...state,
        collections: state.collections.map(collection =>
          collection.id === action.id
            ? Object.assign(collection, action.collection)
            : collection
        ),
      };

    case CollectionsActionType.RESET_COLLECTIONS:
      return {
        ...state,
        ...COLLECTIONS_DEFAULT_STATE,
        collection: state.collection,
        collectionsToRemove: state.collectionsToRemove,
      };

    case CollectionsActionType.RESET_COLLECTION:
      return {
        ...state,
        collection: COLLECTIONS_DEFAULT_STATE.collection,
      };

    case CollectionsActionType.UPDATE_COLLECTIONS_INVITATIONS:
      return {
        ...state,
        collectionsInvitations: action.invitations,
      };

    default:
      return state;
  }
}
