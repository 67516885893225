import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import Button from 'shared/components/Button';
import { ButtonInnerBlock } from 'shared/components/Button/ButtonStyled';
import { Logo as Logotype } from 'shared/components/Logo';
import {
  Down,
  ProfileName,
} from 'shared/components/ProfileButton/ProfileButton';
import { TooltipLarge } from 'shared/components/Tooltip';
import { not } from 'shared/helpers/boolean';
import { zIndex } from 'shared/styles/z-indexes';
import { Themed } from 'shared/types';
import { media } from 'styled-system/responsive';

export const TooltipLargeWrapper = styled(TooltipLarge)``;

export const FiltersWithSearchBarRow = styled.div<{ stickySearch: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.divider};
  background-color: ${({ theme }) => theme.white};
  cursor: auto;

  ${props =>
    props.stickySearch &&
    css`
      position: sticky;
      top: 0;
      z-index: ${zIndex.HEADER};
    `};
`;

export const RealSearchBar = styled.span`
  padding: 1em 8px;
  margin-left: auto;

  ${media.sm`
    width: 100%;
  `};
`;

export const SearchBarWrapper = styled.div<{ showFiltersBar: boolean }>`
  ${props => !props.showFiltersBar && `& > div { width: 100% }`};
  ${props => !props.showFiltersBar && `width: 100%;`};
`;

export const Item = styled(NavLink).attrs({ activeClassName: 'active' })`
  color: ${({ theme }) => theme.text2};
  font-weight: normal;
  margin: 0;
  line-height: 56px;
  height: 100%;
  min-width: 70px;
  text-align: center;
  padding: 0 16px;
  &:focus {
    outline-offset: -7px;
  }
`;

export const ItemProfile = styled.div`
  margin-right: 1em;
  color: ${({ theme }) => theme.text2};
  margin-left: 8px;
  cursor: pointer;
`;

export const LogoItem = styled(Item)`
  margin-right: 14px;
  padding: 0;
  display: flex;
  align-items: center;
  ${media.sm`
    display: none;
  `};
`;

export const Logo = styled(Logotype)<{
  scrolled: boolean;
  isAnimated: boolean;
}>`
  margin: 12px 16px;

  transition: 0.5s;

  ${({ scrolled, isAnimated }) =>
    scrolled &&
    isAnimated &&
    css<any>`
      width: 200px;
      height: 37px;
    `}
`;

export const DesktopNavigation = styled.div<{
  fixed: boolean;
  scrolled: boolean;
  showBg: boolean;
}>`
  border-bottom: 1px solid ${({ theme }) => theme.divider};
  width: 100%;
  height: 56px;
  display: flex;
  transition-duration: 0.33s;
  background-color: transparent;

  ${({ fixed }) =>
    fixed &&
    css<any>`
      position: fixed;
      z-index: ${zIndex.HEADER};
    `};

  ${({ showBg }) =>
    not(showBg) &&
    css`
      background-color: ${({ theme }: Themed) => theme.white};
    `}

  ${({ fixed, scrolled, showBg }) =>
    fixed &&
    !scrolled &&
    showBg &&
    css<any>`
      border-bottom: none;
      ${Item} {
        color: ${({ theme }: Themed) => theme.white};
      }
      ${ProfileName} {
        color: ${({ theme }: Themed) => theme.white};
      }
      ${Down} {
        fill: ${({ theme }: Themed) => theme.white};
      }
      .austin-logo-text {
        fill: ${({ theme }: Themed) => theme.white};
      }
      &:before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 96px;
        z-index: -1;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 0.3) 44%,
          rgba(0, 0, 0, 0) 92%
        );
      }
    `};

  ${({ fixed, scrolled }) =>
    fixed &&
    scrolled &&
    css<any>`
      background-color: ${({ theme }) => theme.white};
    `};

  ${media.sm`
      display: none;
    `};
`;

export const WrapperDesktop = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-evenly;
  button {
    margin-right: 8px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${TooltipLargeWrapper} {
        pointer-events: none;
        opacity: 0.5;
      }
    `}
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  a.active {
    border-bottom: 2px solid ${({ theme }) => theme.blue};
  }
`;

export const DesktopHeaderButton = styled(Button)`
  padding: 0;
  position: relative;
  z-index: 99999;

  ${props =>
    !props.hasIconActive &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.border};
      }
    `}
`;

export const PriceButton = styled(DesktopHeaderButton)<{
  isLease?: boolean;
}>`
  min-width: ${({ isLease }) => (isLease ? '113px' : '106px')};
  ${ButtonInnerBlock} {
    padding: 0;
    justify-content: center;
  }
`;

export const BedsButton = styled(DesktopHeaderButton)`
  min-width: 62px;
`;

export const BathsButton = styled(DesktopHeaderButton)`
  min-width: 62px;
`;

export const MoreFiltersButton = styled(DesktopHeaderButton)`
  width: 105px;
  margin-right: 16px !important;
  span {
    text-overflow: clip;
  }
`;

export const LogoAndUpdateBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const LoginButton = styled(Button)<{
  fixed: boolean;
  scrolled: boolean;
  showBg: boolean;
}>`
  ${({ fixed, scrolled, showBg }) =>
    fixed &&
    !scrolled &&
    showBg &&
    css<any>`
      background: transparent;
      border-color: ${({ theme }: Themed) => theme.white};
      color: ${({ theme }: Themed) => theme.white};

      &:hover {
        background: ${({ theme }: Themed) => theme.white};
        color: ${({ theme }: Themed) => theme.text2};
        border-color: ${({ theme }: Themed) => theme.white};
      }
    `}
`;

export const ScheduleButton = styled(Button)`
  && {
    padding: 0 8px;
  }
`;

export const CategoriesMenuButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-right: 16px;
`;

export const CategoriesMenuHeading = styled.h2`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: ${({ theme }: Themed) => theme.text2};
  margin-right: 8px;
`;
