import { ApiService } from 'shared/services/ApiService';

const BASE_URL = '/listing/collection';

export class CommentsService {
  static async getComments(
    collectionId: string,
    listingId: string,
    page: number
  ) {
    return ApiService.request({
      method: 'GET',
      path: `${BASE_URL}/${collectionId}/comments`,
      query: {
        page,
        listingId,
      },
    });
  }

  static async saveComment(params: {
    collectionId: string;
    listingId: string;
    comment: string;
    owner?: string;
    notNotifyUser?: boolean;
  }) {
    const {
      collectionId,
      comment: text,
      listingId,
      notNotifyUser,
      owner,
    } = params;
    return ApiService.request({
      method: 'POST',
      path: `${BASE_URL}/${collectionId}/comment`,
      data: { text, listingId, owner, notNotifyUser },
    });
  }
}
