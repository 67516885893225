import {
  EstimatedPricesState,
  RequestConsultationBody,
  RequestHomePriceEstimateBody,
} from 'butter-cms/pages/sell/sellTypes';

export enum SellActionType {
  BUY_BEFORE_YOU_SELL = 'BUY_BEFORE_YOU_SELL',
  ESTIMATE_SELECTED_ADDRESS = 'ESTIMATE_SELECTED_ADDRESS',
  ESTIMATE_SELECTED_ADDRESS_SUCCESS = 'ESTIMATE_SELECTED_ADDRESS_SUCCESS',
  ESTIMATE_SELECTED_ADDRESS_FAIL = 'ESTIMATE_SELECTED_ADDRESS_FAIL',
  FETCH_SELL_HERO_SECTION_IMAGE = 'FETCH_SELL_HERO_SECTION_IMAGE',
  FETCH_SELL_HERO_SECTION_IMAGE_SUCCESS = 'FETCH_SELL_HERO_SECTION_IMAGE_SUCCESS',
  FETCH_SELL_HERO_SECTION_NO_IMAGE = 'FETCH_SELL_HERO_SECTION_NO_IMAGE',
  REQUEST_CONSULTATION = 'REQUEST_CONSULTATION',
  REQUEST_HOME_PRICE_ESTIMATE = 'REQUEST_HOME_PRICE_ESTIMATE',
  RESET_SELL_PAGE = 'RESET_SELL_PAGE',
}

export interface RequestConsultationAction {
  type: SellActionType.REQUEST_CONSULTATION;
  body: RequestConsultationBody;
}

export interface RequestHomePriceEstimateAction {
  type: SellActionType.REQUEST_HOME_PRICE_ESTIMATE;
  body: RequestHomePriceEstimateBody;
}

export interface BuyBeforeYouSellAction {
  type: SellActionType.BUY_BEFORE_YOU_SELL;
  body: RequestConsultationBody;
}

export interface FetchHeroSectionImageAction {
  type: SellActionType.FETCH_SELL_HERO_SECTION_IMAGE;
  latitude: number;
  longitude: number;
}

export interface FetchHeroSectionImageSuccessAction {
  type: SellActionType.FETCH_SELL_HERO_SECTION_IMAGE_SUCCESS;
  heroSectionImage: string;
}

export interface FetchHeroSectionNoImageAction {
  type: SellActionType.FETCH_SELL_HERO_SECTION_NO_IMAGE;
}

export interface EstimateSelectedAddressAction {
  type: SellActionType.ESTIMATE_SELECTED_ADDRESS;
  address: string;
  zip: string;
}

export interface EstimateSelectedAddressActionSuccess {
  type: SellActionType.ESTIMATE_SELECTED_ADDRESS_SUCCESS;
  prices: EstimatedPricesState;
}

export interface EstimateSelectedAddressFailAction {
  type: SellActionType.ESTIMATE_SELECTED_ADDRESS_FAIL;
  error: string;
}

export interface ResetSellPage {
  type: SellActionType.RESET_SELL_PAGE;
}

export function requestConsultation(
  body: RequestConsultationBody
): RequestConsultationAction {
  return { type: SellActionType.REQUEST_CONSULTATION, body };
}

export function buyBeforeYouSell(
  body: RequestConsultationBody
): BuyBeforeYouSellAction {
  return { type: SellActionType.BUY_BEFORE_YOU_SELL, body };
}

export function fetchHeroSectionImage(
  latitude: number,
  longitude: number
): FetchHeroSectionImageAction {
  return {
    type: SellActionType.FETCH_SELL_HERO_SECTION_IMAGE,
    latitude,
    longitude,
  };
}

export function fetchHeroSectionImageSuccess(
  heroSectionImage: string
): FetchHeroSectionImageSuccessAction {
  return {
    type: SellActionType.FETCH_SELL_HERO_SECTION_IMAGE_SUCCESS,
    heroSectionImage,
  };
}

export function fetchHeroSectionNoImage(): FetchHeroSectionNoImageAction {
  return {
    type: SellActionType.FETCH_SELL_HERO_SECTION_NO_IMAGE,
  };
}

export function estimateSelectedAddress(
  address: string,
  zip: string
): EstimateSelectedAddressAction {
  return {
    type: SellActionType.ESTIMATE_SELECTED_ADDRESS,
    address,
    zip,
  };
}

export function estimateSelectedAddressSuccess(
  prices: EstimatedPricesState
): EstimateSelectedAddressActionSuccess {
  return {
    type: SellActionType.ESTIMATE_SELECTED_ADDRESS_SUCCESS,
    prices,
  };
}

export function estimateSelectedAddressFail(
  error: string
): EstimateSelectedAddressFailAction {
  return {
    type: SellActionType.ESTIMATE_SELECTED_ADDRESS_FAIL,
    error,
  };
}

export function requestHomePriceEstimation(
  body: RequestHomePriceEstimateBody
): RequestHomePriceEstimateAction {
  return {
    type: SellActionType.REQUEST_HOME_PRICE_ESTIMATE,
    body,
  };
}

export function resetSellPage(): ResetSellPage {
  return {
    type: SellActionType.RESET_SELL_PAGE,
  };
}

export type SellAction =
  | RequestConsultationAction
  | RequestHomePriceEstimateAction
  | BuyBeforeYouSellAction
  | FetchHeroSectionImageAction
  | FetchHeroSectionImageSuccessAction
  | FetchHeroSectionNoImageAction
  | EstimateSelectedAddressAction
  | EstimateSelectedAddressActionSuccess
  | EstimateSelectedAddressFailAction
  | ResetSellPage;
