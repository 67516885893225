import styled from 'styled-components/macro';

import { zIndex } from 'shared/styles/z-indexes';
import { sx, SxProps } from 'styled-system/lib/sx';

export const Container = styled.div<SxProps>`
  background-color: ${props => props.theme.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  z-index: ${zIndex.POPUP};

  &[data-popper-placement^='top'] > [data-popper-arrow] {
    bottom: -5px;
  }

  &[data-popper-placement^='bottom'] > [data-popper-arrow] {
    top: -5px;
  }

  &[data-popper-placement^='left'] > [data-popper-arrow] {
    right: -5px;
  }

  &[data-popper-placement^='right'] > [data-popper-arrow] {
    left: -5px;
  }

  ${sx}
`;

export const Arrow = styled.div`
  &,
  ::before {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;
  }

  ::before {
    content: '';
    transform: rotate(45deg);
    background: ${({ theme }) => theme.white};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  }
`;
