import {
  AskQuestionBody,
  Distance,
  ListingWithDetails,
  PaymentCalculatorKey,
  PaymentCalculatorState,
  ScheduleTourBody,
  ShareListingBody,
  TravelMode,
} from 'listingDetails/listingDetailsTypes';
import { Listing } from 'listings/listingsTypes';
import { TypesOfMap } from 'map/mapConstants';
import { GoogleMapApiType } from 'shared/types/placesAndPolygons';
import { FavoritePlace } from 'user/userTypes';

export enum PaymentCalculatorActionType {
  CALCULATOR_INIT_LISTING_DATA = 'CALCULATOR_INIT_LISTING_DATA',
  CALCULATOR_VALUES_CHANGE = 'CALCULATOR_VALUES_CHANGE',
}

export enum CommuteTimesActionType {
  FETCH_DISTANCE_REQUEST = 'FETCH_DISTANCE_REQUEST',
  FETCH_DISTANCE_SUCCESS = 'FETCH_DISTANCE_SUCCESS',
  FETCH_DISTANCE_FAIL = 'FETCH_DISTANCE_FAIL',
  SET_ACTIVE_PLACE = 'SET_ACTIVE_PLACE',
  SET_TRAVEL_MODE = 'SET_TRAVEL_MODE',
  SET_STREET_VIEW_AVAILABLE = 'SET_STREET_VIEW_AVAILABLE',
  RESET_COMMUTE_TIMES = 'RESET_COMMUTE_TIMES',
}

export enum ListingDetailsActionType {
  ADD_OPEN_HOUSE_TO_CALENDAR = 'ADD_OPEN_HOUSE_TO_CALENDAR',
  ASK_QUESTION = 'ASK_QUESTION',
  CHANGE_LISTINGS_DETAILS_PAGE = 'CHANGE_LISTINGS_DETAILS_PAGE',
  DISMISS_LISTING = 'DISMISS_LISTING',
  FETCH_LISTING = 'FETCH_LISTING',
  FETCH_LISTING_DETAILS = 'FETCH_LISTING_DETAILS',
  FETCH_LISTING_DETAILS_SUCCESS = 'FETCH_LISTING_DETAILS_SUCCESS',
  FETCH_LISTING_FAIL = 'FETCH_LISTING_FAIL',
  FETCH_LISTING_NEARBY_FAIL = 'FETCH_LISTING_NEARBY_FAIL',
  FETCH_LISTING_NEARBY_SUCCESS = 'FETCH_LISTING_NEARBY_SUCCESS',
  FETCH_LISTING_SUCCESS = 'FETCH_LISTING_SUCCESS',
  MERGE_LISTING_DETAILS = 'MERGE_LISTING_DETAILS',
  RESET_LISTING_DETAILS = 'RESET_LISTING_DETAILS',
  SAVE_AGENT_IN_LISTING = 'SAVE_AGENT_IN_LISTING',
  SCHEDULE_TOUR = 'SCHEDULE_TOUR',
  SHARE_LISTING = 'SHARE_LISTING',
  TOGGLE_DISMISS_LISTING = 'TOGGLE_DISMISS_LISTING',
  UPDATE_LISTING_ITEM = 'UPDATE_LISTING_ITEM',
  UPDATE_RESYNC_STATUS = 'UPDATE_RESYNC_STATUS',
}

export interface UpdateListingItemAction {
  type: ListingDetailsActionType.UPDATE_LISTING_ITEM;
  data: Partial<ListingWithDetails>;
}

export function updateListingItem(
  data: Partial<ListingWithDetails>
): UpdateListingItemAction {
  return {
    type: ListingDetailsActionType.UPDATE_LISTING_ITEM,
    data,
  };
}

export interface FetchListingDetailsAction {
  type: ListingDetailsActionType.FETCH_LISTING_DETAILS;
  listingId: string;
}

export function fetchListingDetails(
  listingId: string
): FetchListingDetailsAction {
  return {
    type: ListingDetailsActionType.FETCH_LISTING_DETAILS,
    listingId,
  };
}

export interface FetchListingDetailsSuccessAction {
  type: ListingDetailsActionType.FETCH_LISTING_DETAILS_SUCCESS;
}

export function fetchListingDetailsSuccess(): FetchListingDetailsSuccessAction {
  return { type: ListingDetailsActionType.FETCH_LISTING_DETAILS_SUCCESS };
}

export interface FetchListingFailAction {
  type: ListingDetailsActionType.FETCH_LISTING_FAIL;
  error: string;
}

export function fetchListingFail(error: string): FetchListingFailAction {
  return { type: ListingDetailsActionType.FETCH_LISTING_FAIL, error };
}

export interface FetchListingSuccessAction {
  type: ListingDetailsActionType.FETCH_LISTING_SUCCESS;
  listing: ListingWithDetails;
}

export function fetchListingSuccess(
  listing: ListingWithDetails
): FetchListingSuccessAction {
  return { type: ListingDetailsActionType.FETCH_LISTING_SUCCESS, listing };
}

export interface FetchListingAction {
  type: ListingDetailsActionType.FETCH_LISTING;
  listingId: string;
}

export function fetchListing(listingId: string): FetchListingAction {
  return { type: ListingDetailsActionType.FETCH_LISTING, listingId };
}

export interface FetchListingNearbyFailAction {
  type: ListingDetailsActionType.FETCH_LISTING_NEARBY_FAIL;
  error: string;
}

export function fetchListingNearbyFail(
  error: string
): FetchListingNearbyFailAction {
  return { type: ListingDetailsActionType.FETCH_LISTING_NEARBY_FAIL, error };
}

export interface FetchListingNearbySuccessAction {
  type: ListingDetailsActionType.FETCH_LISTING_NEARBY_SUCCESS;
  forSaleNearby?: Listing[];
  soldNearby?: Listing[];
}

export function fetchListingNearbySuccess(
  forSaleNearby?: Listing[],
  soldNearby?: Listing[]
): FetchListingNearbySuccessAction {
  return {
    type: ListingDetailsActionType.FETCH_LISTING_NEARBY_SUCCESS,
    forSaleNearby,
    soldNearby,
  };
}

export interface AskQuestionAction {
  type: ListingDetailsActionType.ASK_QUESTION;
  body: AskQuestionBody;
}

export function askQuestion(body: AskQuestionBody): AskQuestionAction {
  return { type: ListingDetailsActionType.ASK_QUESTION, body };
}

export interface ScheduleTourAction {
  type: ListingDetailsActionType.SCHEDULE_TOUR;
  body: ScheduleTourBody;
  videoTour: boolean;
}

export function scheduleTour(
  body: ScheduleTourBody,
  videoTour: boolean
): ScheduleTourAction {
  return { type: ListingDetailsActionType.SCHEDULE_TOUR, body, videoTour };
}

export interface DismissListingAction {
  type: ListingDetailsActionType.DISMISS_LISTING;
  payload: DismissListingPayload;
}
interface DismissListingPayload {
  listing: ListingWithDetails;
  updateListings?: boolean;
  mapType?: TypesOfMap;
}

export function dismissListing(
  payload: DismissListingPayload
): DismissListingAction {
  return {
    type: ListingDetailsActionType.DISMISS_LISTING,
    payload,
  };
}

export interface ToggleDismissListingAction {
  type: ListingDetailsActionType.TOGGLE_DISMISS_LISTING;
  listingid: string;
  dismiss: boolean;
}

export function toggleDismissListing(
  listingid: string,
  dismiss: boolean
): ToggleDismissListingAction {
  return {
    type: ListingDetailsActionType.TOGGLE_DISMISS_LISTING,
    listingid,
    dismiss,
  };
}

export interface CalculatorValuesChangeAction {
  type: PaymentCalculatorActionType.CALCULATOR_VALUES_CHANGE;
  calculatorData: Partial<PaymentCalculatorState>;
  changedKey: PaymentCalculatorKey;
}

export const calculatorValuesChange = (
  calculatorData: Partial<PaymentCalculatorState>,
  changedKey: PaymentCalculatorKey
): CalculatorValuesChangeAction => ({
  type: PaymentCalculatorActionType.CALCULATOR_VALUES_CHANGE,
  calculatorData,
  changedKey,
});

export interface CalculatorInitListingAction {
  type: PaymentCalculatorActionType.CALCULATOR_INIT_LISTING_DATA;
  calculatorData: Partial<PaymentCalculatorState>;
}

export const calculatorInitListingAction = (
  calculatorData: Partial<PaymentCalculatorState>
): CalculatorInitListingAction => ({
  type: PaymentCalculatorActionType.CALCULATOR_INIT_LISTING_DATA,
  calculatorData,
});

export interface FetchDistanceRequestAction {
  type: CommuteTimesActionType.FETCH_DISTANCE_REQUEST;
  travelMode: TravelMode;
  maps: GoogleMapApiType;
  favoritePlaces: FavoritePlace[];
}

export function fetchDistanceRequest(
  travelMode: TravelMode,
  maps: GoogleMapApiType,
  favoritePlaces: FavoritePlace[]
): FetchDistanceRequestAction {
  return {
    type: CommuteTimesActionType.FETCH_DISTANCE_REQUEST,
    travelMode,
    maps,
    favoritePlaces,
  };
}

export interface FetchDistanceSuccessAction {
  type: CommuteTimesActionType.FETCH_DISTANCE_SUCCESS;
  distances: Distance[];
  travelMode: TravelMode;
}

export function fetchDistanceSuccess(
  distances: Distance[],
  travelMode: TravelMode
): FetchDistanceSuccessAction {
  return {
    type: CommuteTimesActionType.FETCH_DISTANCE_SUCCESS,
    distances,
    travelMode,
  };
}

export interface FetchDistanceFailAction {
  type: CommuteTimesActionType.FETCH_DISTANCE_FAIL;
  error: string;
}

export function fetchDistanceFail(error: string): FetchDistanceFailAction {
  return {
    type: CommuteTimesActionType.FETCH_DISTANCE_FAIL,
    error,
  };
}

export interface SetActivePlaceAction {
  type: CommuteTimesActionType.SET_ACTIVE_PLACE;
  activePlace: FavoritePlace;
}

export function setActivePlace(
  activePlace: FavoritePlace
): SetActivePlaceAction {
  return {
    type: CommuteTimesActionType.SET_ACTIVE_PLACE,
    activePlace,
  };
}

export interface SetTravelModeAction {
  type: CommuteTimesActionType.SET_TRAVEL_MODE;
  travelMode: TravelMode;
  maps: GoogleMapApiType;
  favoritePlaces: FavoritePlace[];
}

export function setTravelMode(
  travelMode: TravelMode,
  maps: GoogleMapApiType,
  favoritePlaces: FavoritePlace[]
): SetTravelModeAction {
  return {
    type: CommuteTimesActionType.SET_TRAVEL_MODE,
    travelMode,
    maps,
    favoritePlaces,
  };
}

export interface SetStreetViewAvailableAction {
  type: CommuteTimesActionType.SET_STREET_VIEW_AVAILABLE;
  streetViewAvailable: boolean;
}

export function setStreetViewAvailable(
  streetViewAvailable: boolean
): SetStreetViewAvailableAction {
  return {
    type: CommuteTimesActionType.SET_STREET_VIEW_AVAILABLE,
    streetViewAvailable,
  };
}

export interface RestCommuteTimesAction {
  type: CommuteTimesActionType.RESET_COMMUTE_TIMES;
}

export function resetCommuteTimes(): RestCommuteTimesAction {
  return {
    type: CommuteTimesActionType.RESET_COMMUTE_TIMES,
  };
}

export interface ShareListingAction {
  type: ListingDetailsActionType.SHARE_LISTING;
  body: ShareListingBody;
}

export function shareListing(body: ShareListingBody): ShareListingAction {
  return {
    type: ListingDetailsActionType.SHARE_LISTING,
    body,
  };
}

export interface ResetListingDetailsAction {
  type: ListingDetailsActionType.RESET_LISTING_DETAILS;
}

export function resetListingDetails(): ResetListingDetailsAction {
  return { type: ListingDetailsActionType.RESET_LISTING_DETAILS };
}

export interface ChangeListingDetailsPageActionPayload {
  fetchingNextPage: boolean;
  page: number;
  onError: () => void;
}

export interface ChangeListingDetailsPageAction
  extends ChangeListingDetailsPageActionPayload {
  type: ListingDetailsActionType.CHANGE_LISTINGS_DETAILS_PAGE;
}

export function changeListingDetailsPage(
  payload: ChangeListingDetailsPageActionPayload
): ChangeListingDetailsPageAction {
  return {
    type: ListingDetailsActionType.CHANGE_LISTINGS_DETAILS_PAGE,
    ...payload,
  };
}

export interface AddOpenHouseToCalendarRequestAction {
  type: ListingDetailsActionType.ADD_OPEN_HOUSE_TO_CALENDAR;
  listingId: string;
  openhousekey: string;
}

export function addOpenHouseToCalendarRequest(
  listingId: string,
  openhousekey: string
): AddOpenHouseToCalendarRequestAction {
  return {
    type: ListingDetailsActionType.ADD_OPEN_HOUSE_TO_CALENDAR,
    listingId,
    openhousekey,
  };
}

export interface UpdateResyncStatusAction {
  type: ListingDetailsActionType.UPDATE_RESYNC_STATUS;
  isSyncing: boolean;
}

export function updateResyncStatus(params: {
  isSyncing: boolean;
}): UpdateResyncStatusAction {
  return {
    type: ListingDetailsActionType.UPDATE_RESYNC_STATUS,
    isSyncing: params.isSyncing,
  };
}

export interface MergeListingDetailsAction {
  type: ListingDetailsActionType.MERGE_LISTING_DETAILS;
  payload: Partial<ListingWithDetails>;
}

export function mergeListingDetails(
  payload: Partial<ListingWithDetails>
): MergeListingDetailsAction {
  return { type: ListingDetailsActionType.MERGE_LISTING_DETAILS, payload };
}

export type ListingDetailsAction =
  | AskQuestionAction
  | CalculatorInitListingAction
  | CalculatorValuesChangeAction
  | ChangeListingDetailsPageAction
  | DismissListingAction
  | FetchDistanceFailAction
  | FetchDistanceRequestAction
  | FetchDistanceSuccessAction
  | FetchListingAction
  | FetchListingDetailsAction
  | FetchListingDetailsSuccessAction
  | FetchListingFailAction
  | FetchListingNearbyFailAction
  | FetchListingNearbySuccessAction
  | FetchListingSuccessAction
  | MergeListingDetailsAction
  | ResetListingDetailsAction
  | RestCommuteTimesAction
  | ScheduleTourAction
  | SetActivePlaceAction
  | SetStreetViewAvailableAction
  | SetTravelModeAction
  | ShareListingAction
  | ToggleDismissListingAction
  | UpdateListingItemAction
  | UpdateResyncStatusAction;
