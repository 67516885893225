import React, { useState } from 'react';

import PaymentCalculatorStyles from 'listingDetails/components/PaymentCalculator/PaymentCalculatorStyled';
import Tooltip, { Trigger } from 'shared/components/Tooltip/Tooltip';

export interface PaymentCalculatorTooltipProps {
  overlayText: string;
  isMobile?: boolean;
}

export const PaymentCalculatorTooltipDesktopConfig = {
  overlayStyle: { maxWidth: 256 },
  placement: 'top',
  align: { offset: [0, -10] },
};
export const PaymentCalculatorTooltipMobileConfig = {
  overlayStyle: { maxWidth: 200 },
  placement: 'topLeft',
  align: { offset: [-17, -10] },
};

const PaymentCalculatorTooltip = (props: PaymentCalculatorTooltipProps) => {
  const { overlayText, isMobile } = props;
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <Tooltip
      themeLight
      trigger={[Trigger.CLICK]}
      getTooltipContainer={() =>
        document.querySelector('.PaymentCalculatorStyled__TooltipContainer')
      }
      overlay={overlayText}
      onVisibleChange={visible => setTooltipVisible(Boolean(visible))}
      {...(isMobile
        ? PaymentCalculatorTooltipMobileConfig
        : PaymentCalculatorTooltipDesktopConfig)}
    >
      <PaymentCalculatorStyles.RowLabelIcon
        $isTooltipVisible={isTooltipVisible}
        aria-label={overlayText}
        aria-disabled="true"
      />
    </Tooltip>
  );
};
PaymentCalculatorTooltip.defaultProps = {
  isMobile: false,
};

export default PaymentCalculatorTooltip;
