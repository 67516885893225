import { not } from 'shared/helpers/boolean';

export const updateCommentCount = (
  accessor: string,
  id: string,
  items: any[]
): any => {
  return items.map(item =>
    String(item[accessor]) === id
      ? { ...item, commentCount: (item.commentCount || 0) + 1 }
      : item
  );
};

export const canCommentCollection = ({
  agentMode,
  isViewOnlyMode,
  ownersAgentId,
  userId,
}: {
  agentMode: boolean;
  isViewOnlyMode: boolean;
  ownersAgentId?: string;
  userId?: string;
}): boolean => {
  if (agentMode) {
    if (isViewOnlyMode) {
      return false;
    }
    return Boolean(
      (ownersAgentId && userId && ownersAgentId === userId) ??
        not(isViewOnlyMode)
    );
  }
  return not(isViewOnlyMode);
};
