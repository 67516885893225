import { useField } from 'formik';

import FlatCollection from 'shared/components/FlatCollection';
import { FlatCollectionProps } from 'shared/components/FlatCollection/FlatCollection';

interface IFlatCollectionField extends Omit<FlatCollectionProps, 'onSelect'> {
  name: string;
}

export const FlatCollectionField = (props: IFlatCollectionField) => {
  const [field, , helpers] = useField({ name: props.name });

  const handleSelect = (value: string) => {
    helpers.setValue(value);
  };

  return (
    <FlatCollection
      {...field}
      disabled={props.disabled}
      items={props.items}
      onSelect={handleSelect}
      value={props.value}
    />
  );
};
