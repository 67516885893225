import TagManager from 'react-gtm-module';

import { GTM_CUSTOM_EVENTS_NAMES } from 'shared/constants/appConstants';

export const sendUnlockGTMEvent = ({
  clickTarget,
}: {
  clickTarget: string;
}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: GTM_CUSTOM_EVENTS_NAMES.UNLOCK,
      unlock_action: `${clickTarget} click`.toLowerCase(),
      page_title: document.title,
    },
    dataLayerName: 'PageDataLayer',
  });
};
