import React from 'react';
import styled from 'styled-components/macro';

import { not } from 'shared/helpers/boolean';

const Counter = styled.div<{ label: string }>`
  background-color: ${({ theme }) => theme.primary};
  height: 16px;
  min-width: 16px;
  border-radius: ${({ label }) => (label.length > 1 ? '40%' : '50%')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding: 0 2px;
  span {
    font-size: 10px;
    color: ${({ theme }) => theme.white};
  }
`;

interface Props {
  label: string;
  styles?: React.CSSProperties;
}

const CounterLabel = ({ label, ...styles }: Props) => {
  if (not(label)) {
    return null;
  }

  return (
    <Counter {...styles} label={label}>
      {' '}
      <span>{label}</span>
    </Counter>
  );
};

export default CounterLabel;
