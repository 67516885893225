import { Form, Formik, useField, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import FiltersFooter from 'filters/components/FiltersFooter';
import { applyFilters, resetBathsFilters } from 'filters/filtersActions';
import { bathsOptions } from 'filters/filtersContants';
import { $getBathroomChanged } from 'filters/filtersSelectors';
import {
  FilterHeaderContainer,
  FilterHeaderSubtitle,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
} from 'filters/filtersStyled';
import { SharedFiltersProps } from 'filters/filtersTypes';
import { FlatCollectionField } from 'shared/components/formikFields/FlatCollectionField';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useEffectOnUpdate } from 'shared/hooks/use-effect-on-update';
import { mediaBetween } from 'shared/styles/customMedia';
import { media, useIsMobile } from 'styled-system/responsive';

interface BathsFilterValues {
  minBathrooms: number;
}

interface BathsFilterProps extends SharedFiltersProps {
  initialValues: BathsFilterValues;
  onSubmit: () => void;
}

export default function BathsFilter(props: BathsFilterProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
    >
      <Form>
        <BathsFilterFC disableFilter={props.disableFilter} />
        <SyncFormikWithRedux />
      </Form>
    </Formik>
  );
}

function SyncFormikWithRedux(): null {
  const dispatch = useDispatch();
  const { initialValues, values } = useFormikContext<BathsFilterValues>();

  useEffectOnUpdate(() => {
    if (values !== initialValues) {
      dispatch(applyFilters(values));
    }
  }, [initialValues, values]);

  return null;
}

export function BathsFilterFC(props: SharedFiltersProps) {
  const [field] = useField<number>({ name: 'minBathrooms' });

  const isMobile = useIsMobile();

  const dispatch = useDispatch();
  const bathroomsChanged = useSelector($getBathroomChanged);

  const selected = bathsOptions.find(option => option.value === field.value);

  return (
    <Container>
      <ItemContainer innerMode={props.innerMode}>
        <FilterHeaderContainer>
          {isMobile && bathroomsChanged && <FilterSectionChangedIndicator />}
          <FilterHeaderTitle>Baths</FilterHeaderTitle>
          <FilterHeaderSubtitle>{selected?.label || ''}</FilterHeaderSubtitle>
        </FilterHeaderContainer>
        <FlatContainer>
          <FlatCollectionField
            disabled={props.disableFilter}
            items={bathsOptions}
            name="minBathrooms"
            value={field.value}
          />
        </FlatContainer>
      </ItemContainer>
      {not(props.innerMode) && (
        <FiltersFooter
          canReset={bathroomsChanged && not(props.disableFilter)}
          onReset={() => dispatch(resetBathsFilters())}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 364px;
  max-width: 100vw;
  ${media.sm(css`
    width: 100%;
  `)};
`;

const FlatContainer = styled.div`
  padding: 0 0 22px;
  ${media.sm(css`
    max-width: 441px;
  `)};
`;

const ItemContainer = styled.div<{ innerMode?: boolean }>`
  padding: 0 24px;
  ${mediaBetween(768, 1024)} {
    padding: 0;
  }

  ${({ innerMode }) =>
    innerMode
      ? css`
          padding-bottom: 0;

          ${FlatContainer} {
            padding-bottom: 0;
          }
        `
      : css`
          ${FilterHeaderContainer} {
            padding: 24px 0;
          }
        `}
  ${media.sm(css`
    padding: 0;
  `)};
`;
