import { ListingWithDetails } from 'listingDetails/listingDetailsTypes';
import { ExclusiveListing } from 'myListings/myListingsTypes';
import { PlaceType } from 'shared/types/placesAndPolygons';

export const getLinkFromListingPolygon = (
  listingsUrl: string,
  listing: ListingWithDetails | ExclusiveListing,
  type: PlaceType
) => {
  if (listing?.polygons?.[type]?.url) {
    return {
      label: listing.polygons[type]?.name,
      url: listingsUrl + listing.polygons[type]?.url,
    };
  }

  return {
    label: '',
    url: '',
  };
};
