import { Contact } from 'contacts/contactsTypes';
import { PeriodFilters } from 'filters/filtersTypes';
import { SortOrder } from 'shared/constants/appConstants';

export interface EmailHistoryFilters {
  page: number;
  sortBy: EmailHistorySortBy;
  sortType: SortOrder;
  period: number;
  status: EmailHistoryStatusFilter;
}
export interface EmailHistoryState extends EmailHistoryFilters {
  items: EmailHistoryItem[];
  emailRawHtmls: RawHtml;
  loading: boolean;
  total: number;
  limit: number;
  error: string;
}

export enum EmailHistorySortBy {
  Status = 'status',
  DateTime = 'date',
}

export enum EmailHistoryStatus {
  Sent = 'Sent',
  Open = 'Opened',
  Clicked = 'Clicked',
  Complained = 'Complained',
  Bounced = 'Bounced',
  NotSent = 'Not Sent',
}

export enum EmailHistoryStatusFilter {
  Sent = 'Sent',
  Open = 'Opened',
  Clicked = 'Clicked',
  Complained = 'Complained',
  Bounced = 'Bounced',
  HardBounced = 'Hard Bounced',
  SoftBounced = 'Soft Bounced',
  NotSent = 'Not Sent',
  NotSentHardBounced = 'Not Sent Hard Bounced',
  NotSentSoftBounced = 'Not Sent Soft Bounced',
  All = '',
}

export type TimeType =
  | 'sentAt'
  | 'openedAt'
  | 'bouncedAt'
  | 'complainedAt'
  | 'clickedAt'
  | 'notSentAt';

export interface EmailHistoryItem {
  status: EmailHistoryStatus;
  subject: string;
  sentAt: string;
  openedAt: string;
  bouncedAt: string;
  complainedAt: string;
  clickedAt: string;
  createdAt: string;
  id: string;
  notSentAt: string;
  notSentReason: string;
  updatedAt: string;
  isMarketing: boolean;
}

export interface RawHtml {
  [messageId: string]: string;
}

export interface EmailHistoryRequestFilters {
  page: EmailHistoryState['page'];
  sortBy: EmailHistoryState['sortBy'];
  sortType: EmailHistoryState['sortType'];
  limit: EmailHistoryState['limit'];
  status: EmailHistoryState['status'];
  username: Contact['username'];
  periodStart?: PeriodFilters['periodStart'];
  periodEnd?: PeriodFilters['periodEnd'];
}

export interface EmailHistoryFilterModalData {
  sortBy: EmailHistorySortBy;
  sortType: SortOrder;
  period: number;
  status: EmailHistoryStatusFilter;
}
