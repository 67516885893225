import React from 'react';

import { PermissionRoles } from 'permissions/permissionsConstants';
import { ModalContext } from 'shared/components/Modal/modalContext';
import { loadable } from 'shared/utils/loadable';
import { AuthType, showAuthModal } from 'user/helpers/showAuthModal';

const VerifyAccount = loadable(
  () => import(/* webpackChunkName: "verify-account" */ 'user/VerifyAccount')
);

export const showModalDependOnLoginStatus = (
  role: PermissionRoles | undefined,
  modalContext: React.ContextType<typeof ModalContext>,
  callback?: () => unknown,
  startCallbackImmediately = false
  // eslint-disable-next-line max-params
) => {
  if (startCallbackImmediately) {
    return callback?.();
  }
  switch (role) {
    case PermissionRoles.Guest: {
      return showAuthModal(AuthType.SignUp, modalContext)();
    }
    case PermissionRoles.NotVerifiedUser: {
      return modalContext.showModal(VerifyAccount, {
        isOpen: true,
        label: 'Verify Account',
      })();
    }

    default: {
      if (callback) {
        return callback();
      }
    }
  }
};
