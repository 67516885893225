import styled, { css } from 'styled-components/macro';

import Button from 'shared/components/Button';
import SearchBarStyled from 'shared/components/SearchBar/SearchBarStyled';
import { zIndex } from 'shared/styles/z-indexes';
import { media } from 'styled-system/responsive';

export const MENU_WIDTH = 74;
export const CLEAR_BUTTON_WIDTH = 74;
export const SHOW_MORE_WIDTH = 80;

export const DESKTOP_PADDING = 16;
export const MOBILE_PADDING = 8;

export const DESKTOP_HEADING_HEIGHT = 64;
export const MOBILE_HEADING_HEIGHT = 48;
const TAGS_WRAPPER_WIDTH = 49;

const ClearButton = styled(Button)<{ isFocused?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${DESKTOP_PADDING}px;
  width: ${SHOW_MORE_WIDTH}px;
  height: ${DESKTOP_HEADING_HEIGHT}px;
  border: 0;
  color: ${({ theme }) => theme.secondary};
  font-weight: bold;

  & > span {
    padding: 0;
  }

  ${media.sm(css`
    bottom: 0;
    padding: ${MOBILE_PADDING}px;
    height: ${MOBILE_HEADING_HEIGHT}px;
  `)}
`;

type SearchInputProps = {
  disabled?: boolean;
  hide?: boolean;
  isFocused: boolean;
  shouldShowSecondLine?: boolean;
};

const SearchInput = styled(SearchBarStyled.SearchInput)<SearchInputProps>`
  align-self: stretch;
  border-radius: unset;
  border: none;
  box-sizing: content-box;
  height: ${DESKTOP_HEADING_HEIGHT}px;
  min-width: ${SHOW_MORE_WIDTH}px;
  width: 10px;

  &:focus {
    align-self: stretch;
  }

  ${({ shouldShowSecondLine = false }) =>
    shouldShowSecondLine &&
    css`
      margin-left: 16px;
      max-width: calc(100% - 24px);
    `};

  ${({ disabled = false }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0;
    `}

  ${({ hide = false }) =>
    hide &&
    css`
      display: none;
    `}

  ${media.sm(css<SearchInputProps>`
    height: ${MOBILE_HEADING_HEIGHT}px;
    font-size: 14px;
    flex: none;
    width: 100%;
    min-width: unset;
    box-sizing: border-box;
    &:focus {
      width: 100%;
    }

    ${({ shouldShowSecondLine = false }) =>
      shouldShowSecondLine &&
      css`
        padding-top: 8px !important;
      `};
  `)};
`;

const ShowMoreButton = styled(Button)`
  padding: ${DESKTOP_PADDING}px 0;
  border: 0;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
  width: 5vw;
  min-width: ${SHOW_MORE_WIDTH}px;

  & > span {
    padding: 0;
  }

  ${media.sm(css`
    padding: ${MOBILE_PADDING}px 0;
    padding-right: ${MOBILE_PADDING}px;
    font-size: 14px;
    & + ${SearchInput}:not(:focus) {
      opacity: 0;
      max-width: 0;
      max-height: 0;
    }
  `)}
`;

const SearchButton = styled(Button)<{ isFocused?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 16px;
  width: 36px;
  height: ${DESKTOP_HEADING_HEIGHT}px;
  border: 0;
  &:focus {
    outline-offset: -5px;
  }
  & > span {
    padding: 0;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  ${media.sm(css`
    height: ${MOBILE_HEADING_HEIGHT}px;
  `)}
`;

const SearchBarInput = styled.div<{ shouldShowSecondLine?: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;

  ${({ shouldShowSecondLine = false }) =>
    shouldShowSecondLine &&
    css`
      box-shadow: 0 4px 8px 0 rgba(188, 200, 210, 0.29);
    `};
`;

type ReactResizeDetectorWrapperProps = {
  isFocused?: boolean;
  hasTags?: boolean;
};

const ReactResizeDetectorWrapper = styled.div<ReactResizeDetectorWrapperProps>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-width: ${TAGS_WRAPPER_WIDTH}vw;

  ${({ isFocused = false, hasTags = false }) =>
    isFocused &&
    hasTags &&
    css`
      flex-direction: column;
      align-items: flex-start;
      ${SearchInput} {
        width: 100%;
        height: auto;
        padding: 16px 0 16px 8px;
      }
    `}
  ${media.sm(css`
    max-width: initial;
  `)}
`;

const Wrapper = styled.form<{ isFocused?: boolean }>`
  position: relative;
  align-self: stretch;
  max-width: 51%;
  flex-grow: 1;
  height: fit-content;
  min-height: ${DESKTOP_HEADING_HEIGHT}px;
  z-index: 1;

  & > div {
    position: relative;
    display: flex;
    flex-flow: column;
    min-height: 100%;

    & > ${SearchBarInput} {
      min-height: 100%;
      position: absolute;
      width: 100%;
      background-color: ${({ theme }) => theme.white};
    }
  }

  ${media.sm(
    css<{ isFocused?: boolean }>`
      width: ${window.innerWidth - MENU_WIDTH}px;
      max-width: 100%;
      margin-left: ${() => `${MENU_WIDTH}px`};
      min-height: ${MOBILE_HEADING_HEIGHT}px;
      display: flex;
      flex-direction: column;

      ${({ isFocused = false }) =>
        isFocused &&
        css`
          width: ${window.innerWidth}px;
          margin-left: 0;
          z-index: ${zIndex.HEADER};
        `};
    `
  )};
`;

type TagsWrapperProps = {
  isFocused?: boolean;
  shouldShowSecondLine?: boolean;
};

const TagsWrapper = styled.div<TagsWrapperProps>`
  display: flex;
  align-items: flex-start;
  max-height: ${DESKTOP_HEADING_HEIGHT - DESKTOP_PADDING}px;
  flex-wrap: wrap;
  max-width: calc(100% - ${CLEAR_BUTTON_WIDTH + DESKTOP_PADDING}px);
  overflow: hidden;
  min-height: ${DESKTOP_HEADING_HEIGHT - DESKTOP_PADDING}px;
  padding-top: 8px;
  margin-left: 16px;

  ${media.large(css`
    max-width: calc(100% - ${SHOW_MORE_WIDTH - DESKTOP_PADDING}px);
  `)}

  ${media.sm(css`
    min-height: ${MOBILE_HEADING_HEIGHT - MOBILE_PADDING}px;
    padding-top: ${MOBILE_PADDING}px;
    max-width: calc(
      100vw - ${MENU_WIDTH}px - ${MOBILE_HEADING_HEIGHT}px -
        ${SHOW_MORE_WIDTH}px
    );
    & + ${SearchInput}:not(:focus) {
      opacity: 0;
      max-width: 0;
      max-height: 0;
    }
  `)}

  ${({ shouldShowSecondLine = false }) =>
    shouldShowSecondLine &&
    css`
      max-height: inherit;
      padding-top: ${DESKTOP_PADDING}px;
    `}

  ${({ isFocused = false }) =>
    isFocused &&
    media.sm(css`
      max-width: calc(
        100vw - ${MOBILE_HEADING_HEIGHT}px - ${CLEAR_BUTTON_WIDTH}px
      );
    `)}
`;

const SearchBarWithTagsStyled = {
  ClearButton,
  ReactResizeDetectorWrapper,
  SearchBarInput,
  SearchButton,
  SearchInput,
  ShowMoreButton,
  TagsWrapper,
  Wrapper,
};

export default SearchBarWithTagsStyled;
