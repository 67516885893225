import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getAgentMode, getContactItem } from 'contacts/contactsSelectors';
import { searchListings } from 'listings/listingsActions';
import { RootState } from 'rootReducer';
import { filterSearchItems } from 'search/search-selectors';
import {
  addTag,
  fetchAutocomplete,
  removeAllTags,
  removeTag,
  toggleSearchTag,
} from 'search/search-slice';
import SearchBarWithTags from 'shared/components/SearchBarWithTags/SearchBarWithTags';
import { ConnectProps } from 'shared/types';
import { getUserLocation } from 'user/userSelectors';

const mapStateToProps = (state: RootState) => ({
  autocomplete: filterSearchItems(state),
  tags: state.search.tags,
  userLocation: getUserLocation(state),
  agentMode: getAgentMode(state),
  contactItem: getContactItem(state),
});

const mapDispatchToProps = {
  addTag,
  fetchAutocomplete,
  removeAllTags,
  removeTag,
  searchListings,
  toggleSearchTag,
};

export type SearchBarWithTagsConnectProps = ConnectProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
>;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchBarWithTags)
);
