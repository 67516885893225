import { omit } from 'lodash';
import { DeepReadonly } from 'utility-types';

import { CollectionsAction } from 'collections/collectionsActions';
import { CollectionsActionType } from 'collections/collectionsActionType';
import { MlsStatus } from 'filters/filtersContants';
import { computeCalculatorValues } from 'listingDetails/components/PaymentCalculator/paymentCalculatorHelpers';
import {
  CommuteTimesActionType,
  ListingDetailsAction,
  ListingDetailsActionType,
  PaymentCalculatorActionType,
} from 'listingDetails/listingDetailsActions';
import {
  DOWNPAYMENT,
  HOMEOWNERS_INSURANCE,
  INTEREST_RATE,
} from 'listingDetails/listingDetailsConstants';
import {
  CommuteTimes,
  ListingDetailsPaymentPeriod,
  ListingDetailsState,
  ListingStatistic,
  PaymentCalculatorKey,
  PaymentCalculatorState,
  TravelMode,
} from 'listingDetails/listingDetailsTypes';
import { addCollectionIdInListing } from 'listings/listingsHelpers';
import { Listing } from 'listings/listingsTypes';
import { MapAction } from 'map/mapActions';
import { SavedSearchAction } from 'saved-search/savedSearchActions';

const HOA_TYPE = ListingDetailsPaymentPeriod.Monthly;

export const PAYMENT_CALCULATOR_DEFAULT_STATE: PaymentCalculatorState = {
  listprice: 0,
  taxRate: 0,
  currencyPropertyTaxes: 0,
  homeownersInsurance: HOMEOWNERS_INSURANCE,
  currencyHomeownersInsurance: 0,
  downPayment: DOWNPAYMENT,
  currencyDownPayment: 0,
  hoaDues: 0,
  hoaType: HOA_TYPE,
  interestRate: INTEREST_RATE,
  currencyPrincipalInterest: 0,
  monthlyMortgagePayment: 0,
  changedArea: PaymentCalculatorKey.unknown,
  taxAnnualAmount: 0,
  initial: {
    hoaDues: 0,
    taxRate: 0,
    homeownersInsurance: HOMEOWNERS_INSURANCE,
    interestRate: INTEREST_RATE,
  },
};

export const COMMUTE_TIMES_DEFAULT_STATE: CommuteTimes = {
  distances: {},
  activePlace: null,
  travelMode: TravelMode.DRIVING,
  error: '',
  loading: false,
  streetViewAvailable: true,
};

export const LISTING_STATISTIC_DEFAULT_STATE: ListingStatistic = {
  savedToCollectionsCount: '0',
  dismissedCount: '0',
  listingId: '0',
  questionsCount: '0',
  scheduledToursCount: '0',
  viewsCount: '0',
};

export const LISTING_DETAILS_DEFAULT_STATE: ListingDetailsState = {
  loading: false,
  error: '',
  forSaleNearby: [],
  soldNearby: [],
  calculator: PAYMENT_CALCULATOR_DEFAULT_STATE,
  commuteTimes: COMMUTE_TIMES_DEFAULT_STATE,
  item: {
    address: '',
    city: '',
    closeprice: '',
    collections: [],
    dismissed: false,
    fulladdress: '',
    isFavorite: false,
    isSyncing: false,
    latitude: '0',
    listings: [],
    listingid: '',
    listingkey: '',
    listofficename: '',
    listprice: '',
    longitude: '0',
    mlsstatus: MlsStatus.Empty,
    openhouse: [],
    parcelnumber: '',
    postalcode: '',
    publicremarks: '',
    primarylistingid: '',
    stateorprovince: '',
    streetdirprefix: '',
    streetdirsuffix: '',
    streetname: '',
    streetnumber: '',
    streetsuffix: '',
    subdivisionname: '',
    urlAddress: '',
    virtualtoururlunbranded: '',
    primaryLabel: null,
    images: [],
    usageStatistics: LISTING_STATISTIC_DEFAULT_STATE,
    attributioncontact: '',
  },
};

export function listingDetailsReducer(
  state: DeepReadonly<ListingDetailsState> = LISTING_DETAILS_DEFAULT_STATE,
  action:
    | ListingDetailsAction
    | CollectionsAction
    | SavedSearchAction
    | MapAction
): DeepReadonly<ListingDetailsState> {
  switch (action.type) {
    case ListingDetailsActionType.CHANGE_LISTINGS_DETAILS_PAGE:
    case ListingDetailsActionType.FETCH_LISTING_DETAILS:
      return { ...state, loading: true, error: '' };

    case ListingDetailsActionType.FETCH_LISTING_DETAILS_SUCCESS:
      return { ...state, loading: false, error: '' };

    case ListingDetailsActionType.FETCH_LISTING_FAIL:
      return {
        ...state,
        error: action.error,
      };

    case ListingDetailsActionType.FETCH_LISTING_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.listing,
        },
      };

    case ListingDetailsActionType.UPDATE_LISTING_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.data,
        },
      };

    case ListingDetailsActionType.FETCH_LISTING_NEARBY_SUCCESS:
      return {
        ...state,
        forSaleNearby: action.forSaleNearby,
        soldNearby: action.soldNearby,
      };

    case ListingDetailsActionType.TOGGLE_DISMISS_LISTING:
      return {
        ...state,
        item: {
          ...state.item,
          dismissed:
            state.item.listingid === action.listingid
              ? action.dismiss
              : state.item.dismissed,
        },
      };

    case CollectionsActionType.SAVE_LISTING_TO_COLLECTION_SUCCESS:
    case CollectionsActionType.SAVE_COLLECTION_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          isFavorite: true,
          collections:
            action.listingid === state.item.listingid
              ? [
                  ...state.item.collections,
                  omit(action.collection, [
                    'agentMode',
                    'notifications',
                    'images',
                    'collaborators',
                    'loading',
                    'addedListings',
                  ]),
                ]
              : state.item.collections,
        },
        forSaleNearby: state.forSaleNearby?.map(listing =>
          addCollectionIdInListing({
            listing: listing as Listing,
            actionListingID: action.listingid,
            collectionID: action.id,
          })
        ),
        // eslint-disable-next-line sonarjs/no-identical-functions
        soldNearby: state.soldNearby?.map(listing =>
          addCollectionIdInListing({
            listing: listing as Listing,
            actionListingID: action.listingid,
            collectionID: action.id,
          })
        ),
      };

    case PaymentCalculatorActionType.CALCULATOR_INIT_LISTING_DATA:
      return {
        ...state,
        calculator: {
          ...PAYMENT_CALCULATOR_DEFAULT_STATE,
          ...action.calculatorData,
          initial: {
            ...PAYMENT_CALCULATOR_DEFAULT_STATE.initial,
            ...action.calculatorData.initial,
          },
        },
      };
    case PaymentCalculatorActionType.CALCULATOR_VALUES_CHANGE:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          ...computeCalculatorValues(action.changedKey, action.calculatorData),
          changedArea: action.changedKey,
          initial: {
            ...PAYMENT_CALCULATOR_DEFAULT_STATE.initial,
            ...action.calculatorData.initial,
          },
        },
      };

    case CommuteTimesActionType.FETCH_DISTANCE_REQUEST: {
      return {
        ...state,
        commuteTimes: {
          ...state.commuteTimes,
          error: '',
          loading: true,
        },
      };
    }

    case CommuteTimesActionType.FETCH_DISTANCE_SUCCESS: {
      return {
        ...state,
        commuteTimes: {
          ...state.commuteTimes,
          error: '',
          loading: false,
          distances: {
            ...state.commuteTimes.distances,
            [action.travelMode]: action.distances,
          },
        },
      };
    }

    case CommuteTimesActionType.FETCH_DISTANCE_FAIL: {
      return {
        ...state,
        commuteTimes: {
          ...state.commuteTimes,
          error: action.error,
          loading: false,
        },
      };
    }

    case CommuteTimesActionType.SET_ACTIVE_PLACE: {
      return {
        ...state,
        commuteTimes: {
          ...state.commuteTimes,
          activePlace: action.activePlace,
        },
      };
    }

    case CommuteTimesActionType.SET_TRAVEL_MODE: {
      return {
        ...state,
        commuteTimes: {
          ...state.commuteTimes,
          travelMode: action.travelMode,
        },
      };
    }

    case CommuteTimesActionType.SET_STREET_VIEW_AVAILABLE: {
      return {
        ...state,
        commuteTimes: {
          ...state.commuteTimes,
          streetViewAvailable: action.streetViewAvailable,
        },
      };
    }

    case CommuteTimesActionType.RESET_COMMUTE_TIMES: {
      return {
        ...state,
        commuteTimes: COMMUTE_TIMES_DEFAULT_STATE,
      };
    }

    case ListingDetailsActionType.RESET_LISTING_DETAILS: {
      return {
        ...state,
        ...LISTING_DETAILS_DEFAULT_STATE,
      };
    }

    case ListingDetailsActionType.UPDATE_RESYNC_STATUS: {
      return {
        ...state,
        item: { ...state.item, isSyncing: action.isSyncing },
      };
    }

    case ListingDetailsActionType.MERGE_LISTING_DETAILS: {
      return { ...state, item: { ...state.item, ...action.payload } };
    }

    default:
      return state;
  }
}
