import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { truncate } from 'lodash';

import { SAVED_SEARCH_TAG_TYPE } from 'listings/listingsConstants';
import { CUSTOM_POLYGON_TYPE } from 'map/mapConstants';
import { MapState } from 'map/mapTypes';
import { areTagsEqual } from 'search/helpers/tags-helpers';
import { HintGroup, Search, SearchState, Tag } from 'search/search-types';
import { not, notEmpty } from 'shared/helpers/boolean';
import { effect, Status } from 'shared/helpers/redux';

export const searchInitialState: SearchState = {
  autocomplete: { status: Status.initial },
  recentSearches: { status: Status.initial },
  tags: [],
};

const searchSlice = createSlice({
  name: 'search',
  initialState: searchInitialState,
  reducers: {
    fetchAutocomplete: (state, _action: PayloadAction<{ query: string }>) => {
      state.autocomplete.status = Status.loading;
    },

    autocompleteLoaded: (
      state,
      action: PayloadAction<{ items: HintGroup[] }>
    ) => {
      state.autocomplete = {
        status: Status.succeeded,
        items: action.payload.items,
      };
    },

    autocompleteFailed: (state, action: PayloadAction<{ error: string }>) => {
      state.autocomplete = {
        status: Status.failed,
        error: action.payload.error,
      };
    },

    addTag: (state, action: PayloadAction<{ tag: Tag }>) => {
      state.tags.push(action.payload.tag);
    },

    removeTag: (state, action: PayloadAction<{ tag: Tag }>) => {
      const { tags } = state;
      const tagToRemove = action.payload.tag;

      const idx = tags.findIndex(tag => areTagsEqual(tag, tagToRemove));
      if (idx > -1) {
        tags.splice(idx, 1);
      }

      tags.forEach(tag => {
        if (tag.type === SAVED_SEARCH_TAG_TYPE && tag.tags) {
          const idx = tag.tags.findIndex(tag => areTagsEqual(tag, tagToRemove));
          if (idx > -1) {
            tag.tags.splice(idx, 1);
          }
        }
      });
    },

    removeAllTags: (
      state,
      _action: PayloadAction<{ mapType: keyof MapState }>
    ) => {
      state.tags = state.tags.reduce((newTags, tag) => {
        if (tag.type === SAVED_SEARCH_TAG_TYPE) {
          newTags.push({ ...tag, tags: [] });
        }
        return newTags;
      }, []);
    },

    setTags: (state, action: PayloadAction<{ tags: Tag[] }>) => {
      state.tags = action.payload.tags;
    },

    toggleSearchTag: state => {
      const idx = state.tags.findIndex(
        tag => tag.type === SAVED_SEARCH_TAG_TYPE
      );
      state.tags[idx].open = !state.tags[idx].open;
      if (state.tags[idx].open) {
        state.tags.splice(1, 0, ...state.tags[idx].tags);
      } else {
        state.tags = state.tags.filter(tag => not(tag.isChild));
      }
    },

    setSavedSearchTags: (
      state,
      action: PayloadAction<{ name: string; tags: Tag[] }>
    ) => {
      const { name, tags } = action.payload;
      const tag = {
        label: truncate(name, { length: 25 }),
        open: false,
        tags: tags.map(tag => ({ ...tag, isChild: true, isTemporary: false })),
        type: SAVED_SEARCH_TAG_TYPE,
        value: '',
      } as const;
      state.tags = [tag];
    },

    addCustomTag: (state, action: PayloadAction<{ tag: Tag }>) => {
      const customIdx = state.tags.findIndex(tag => {
        return tag.type === CUSTOM_POLYGON_TYPE && tag.isTemporary;
      });
      if (customIdx > -1) {
        state.tags[customIdx] = action.payload.tag;
      } else {
        state.tags.push(action.payload.tag);
      }
    },

    removeCustomTag: state => {
      const customIdx = state.tags.findIndex(tag => {
        const { isChild, isTemporary, type } = tag;
        return type === CUSTOM_POLYGON_TYPE && isTemporary && not(isChild);
      });
      if (customIdx > -1) {
        state.tags.splice(customIdx, 1);
      }
    },

    fetchRecentSearches: (state, _action: PayloadAction<void>) => {
      state.recentSearches.status = Status.loading;
    },

    fetchRecentSearchesSucceded: (
      state,
      action: PayloadAction<{ items: Search[] }>
    ) => {
      state.recentSearches = {
        status: Status.succeeded,
        items: action.payload.items.filter(item => notEmpty(item.search.tags)),
      };
    },

    fetchRecentSearchesFailed: (
      state,
      action: PayloadAction<{ error: unknown }>
    ) => {
      state.recentSearches = {
        status: Status.failed,
        error: action.payload.error,
      };
    },

    searchTriggeredFx: effect<Search>(),
  },
});

export const searchReducer = searchSlice.reducer;

export const {
  addCustomTag,
  addTag,
  autocompleteFailed,
  autocompleteLoaded,
  fetchAutocomplete,
  fetchRecentSearches,
  fetchRecentSearchesFailed,
  fetchRecentSearchesSucceded,
  removeAllTags,
  removeCustomTag,
  removeTag,
  searchTriggeredFx,
  setSavedSearchTags,
  setTags,
  toggleSearchTag,
} = searchSlice.actions;
