import { buffers } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { RootState } from 'rootReducer';
import { SearchDataParser } from 'saved-search/services/SearchDataParser';
import { recentSearches } from 'search/helpers/recent-searches-helpers';
import { SearchService } from 'search/search-service';
import {
  fetchRecentSearches,
  fetchRecentSearchesFailed,
  fetchRecentSearchesSucceded,
  searchTriggeredFx,
} from 'search/search-slice';
import { Search } from 'search/search-types';
import { browserStorageItem } from 'shared/constants/appConstants';
import { not, notEmpty } from 'shared/helpers/boolean';
import { ReturnPromise } from 'shared/types';
import {
  isAuthorised as getIsAuthorised,
  getIsInitialized,
} from 'user/userSelectors';

const RECENT_SEARCHES_COUNT = 10;
const RECENT_SEARCHES_TO_FETCH = 100;

function* waitUntilInitialized() {
  type IsInitialized = ReturnType<typeof getIsInitialized>;
  let initialized: IsInitialized = yield select(getIsInitialized);

  while (not(initialized)) {
    yield take();
    initialized = yield select(getIsInitialized);
  }

  return initialized;
}

export function* fetchRecentSearchesSaga() {
  try {
    yield waitUntilInitialized();

    type IsAuthorised = ReturnType<typeof getIsAuthorised>;
    const isAuthorised: IsAuthorised = yield select(getIsAuthorised);

    if (isAuthorised) {
      type Response = ReturnPromise<typeof SearchService.getRecentSearches>;
      const response: Response = yield call(SearchService.getRecentSearches, {
        query: { page: 1, limit: RECENT_SEARCHES_TO_FETCH },
      });

      const items: Search[] = [];

      for (const recentSearch of response.hits) {
        if (items.length === RECENT_SEARCHES_COUNT) {
          break;
        }

        const item = {
          query: recentSearch.query,
          search: SearchDataParser.parse(recentSearch.searchData),
          searchCount: recentSearch.searchCount,
        };

        if (notEmpty(item.search.tags)) {
          items.push(item);
        }
      }

      yield put(fetchRecentSearchesSucceded({ items }));
    } else {
      const json = localStorage.getItem(browserStorageItem.guestRecentSearches);
      yield put(
        fetchRecentSearchesSucceded({ items: recentSearches.deserialize(json) })
      );
    }
  } catch (error) {
    yield put(fetchRecentSearchesFailed({ error: error?.message }));
  }
}

export function* saveRecentSearchesSaga(): Saga {
  const searchChannel = yield actionChannel(
    searchTriggeredFx,
    buffers.sliding(RECENT_SEARCHES_COUNT)
  );

  yield waitUntilInitialized();

  try {
    while (true) {
      type Action = ReturnType<typeof searchTriggeredFx>;
      const action: Action = yield take(searchChannel);
      type IsAuthorised = ReturnType<typeof getIsAuthorised>;
      const isAuthorised: IsAuthorised = yield select<
        (state: RootState) => IsAuthorised
      >(getIsAuthorised);

      if (not(isAuthorised) && notEmpty(action.payload.search.tags)) {
        const json = localStorage.getItem(
          browserStorageItem.guestRecentSearches
        );

        const recent = recentSearches
          .deserialize(json)
          .slice(0, RECENT_SEARCHES_COUNT - 1);
        recent.unshift(action.payload);

        localStorage.setItem(
          browserStorageItem.guestRecentSearches,
          recentSearches.serialize(recent)
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* recentSearchesSaga() {
  yield all([
    takeLatest(fetchRecentSearches, fetchRecentSearchesSaga),
    call(saveRecentSearchesSaga),
  ]);
}
