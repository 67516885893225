import { History } from 'history';
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';

import { getTrecLicenses } from 'agents/agentsSelectors';
import {
  getAgentMode,
  getContact,
  getContactItem,
} from 'contacts/contactsSelectors';
import { Contact } from 'contacts/contactsTypes';
import { MlsStatus } from 'filters/filtersContants';
import { $getSourceFilters, $getStatusFilters } from 'filters/filtersSelectors';
import { getIsLeaseListing } from 'listingDetails/listingDetailsSelectors';
import { ListingWithDetails } from 'listingDetails/listingDetailsTypes';
import { filterPolygonsTags } from 'listings/listingsHelpers';
import { Listing } from 'listings/listingsTypes';
import { CUSTOM_POLYGON_TYPE, TypesOfMap } from 'map/mapConstants';
import { addURLToClusters } from 'map/mapSelectors';
import { MapState } from 'map/mapTypes';
import { RootState } from 'rootReducer';
import {
  getCurrentSavedSearch,
  getIsLeaseSavedSearch,
} from 'saved-search/savedSearchSelectors';
import { getTags } from 'search/search-selectors';
import { RoutePath } from 'shared/constants/routesConstants';
import { isEmpty, not, notEmpty } from 'shared/helpers/boolean';
import { createListingsUrl } from 'shared/helpers/createListingsUrl';
import { isLeasePage } from 'shared/helpers/isLeasePage';
import {
  addLabelsToItem,
  addLabelsToItemsInClusters,
} from 'shared/helpers/labelsHelper';
import history from 'shared/services/history';
import { getIsAgent } from 'user/userSelectors';

export const getListings = (state: RootState) => state.listings;
export const getNearbyListings = (state: RootState) =>
  state.listings.nearbyListings;
export const getItems = (state: RootState) => state.listings.items;
const getListingsHistory = (state: RootState) => state.listings.history;
export const getClusters = (state: RootState, mapType: keyof MapState) =>
  state.map[mapType].clusters;
export const getCollectionID = (state: RootState) =>
  state.collections.collection.id;

export const getDataWithLabels = createSelector([getItems], items =>
  items.map(item => addLabelsToItem(item))
);

export const addLabelsToNearbyListings = createSelector(
  [getNearbyListings],
  (items: Listing[]): Listing[] => items.map(item => addLabelsToItem(item))
);

export function addURLToItems(params: {
  // TODO as input here should be Listing[] but as output ModifiedListings[] type
  items: Listing[];
  agentMode: boolean;
  contactItem?: Contact | null;
  history?: number;
}): Listing[];

export function addURLToItems({
  items,
  agentMode,
  contactItem,
  history,
}: {
  items: Array<Listing | ListingWithDetails>;
  agentMode: boolean;
  contactItem?: Contact | null;
  history?: number;
}): Array<Listing | ListingWithDetails> {
  return items.map(item => {
    const url = new URL(
      `${window.location.origin}${createListingsUrl(
        item,
        agentMode,
        contactItem
      )}`
    );

    if (not(agentMode) && history) {
      url.searchParams.append('search', history.toString());
    }

    return {
      ...item,
      url: url.toString().split(window.location.origin).pop() || '',
    };
  });
}

const getLabelsForClusters = createSelector(
  [getClusters],
  addLabelsToItemsInClusters
);

export const prepareListingsData = createSelector(
  [getDataWithLabels, getAgentMode, getContactItem, getListingsHistory],
  // eslint-disable-next-line max-params
  (items, agentMode, contactItem, history) => {
    return addURLToItems({ items, agentMode, contactItem, history });
  }
);

export function hasNearbyListings(state: RootState) {
  return notEmpty(state.listings.nearbyListings);
}

export const prepareClustersData = createSelector(
  [getLabelsForClusters, getAgentMode, getContactItem],
  addURLToClusters
);

export const getListingById = (state: RootState, id: string) => {
  return getItems(state).find(x => x.listingid === id);
};

export const getDataForFetchingListings = createSelector(
  [
    ({ collections }: RootState) => collections.collection,
    getTrecLicenses,
    getContact,
    getCurrentSavedSearch,
    getIsLeaseListing,
  ],
  // eslint-disable-next-line max-params
  (collection, trecLicenses, contact, savedSearch, isLeaseListing) => ({
    collectionId: collection.id,
    trecLicenses,
    userId: contact.currentContactId,
    savedSearch,
    isLeaseListing,
  })
);

export const getMarkPolygonsAsNoIndex = (
  state: RootState,
  mapType: TypesOfMap
) => {
  return (
    notEmpty(state.map[mapType].polygons) &&
    state.map[mapType].polygons.every(polygon => polygon.noIndex)
  );
};

export const getIsPriceAvailable = createSelector(
  [getIsAgent, getAgentMode],
  (isAgent, isAgentMode) => {
    return isAgent && not(isAgentMode);
  }
);

export const getListingsPage = (state: RootState) => state.listings.page;

export const isMarketOverviewEnabled = createSelector([getTags], tags => {
  if (!matchPath(history.location.pathname, RoutePath.LISTINGS)) {
    return false;
  }

  const { polygonsIds } = filterPolygonsTags(
    tags.filter(tag => tag.type !== CUSTOM_POLYGON_TYPE)
  );

  return notEmpty(polygonsIds);
});

export const getListingsTotal = createSelector(
  getListings,
  listings => listings.itemCount
);
export const getHasPrivateListings = createSelector(
  [
    getIsAgent,
    getAgentMode,
    $getStatusFilters,
    $getSourceFilters,
    getIsLeaseSavedSearch,
    (state: RootState, history: History) => history,
  ],
  // eslint-disable-next-line max-params
  (isAgent, agentMode, { mlsStatus }, sourceFilters, isLeaseSS, history) => {
    const isLease = isLeasePage(history.location.pathname) || isLeaseSS;
    const isPrivateStatusAvailable = isAgent && not(agentMode) && not(isLease);
    const hasExclusive =
      (not(sourceFilters.exclusiveListingsEnabled) &&
        not(sourceFilters.mlsListingsEnabled)) ||
      sourceFilters.exclusiveListingsEnabled;
    return (
      (mlsStatus.includes(MlsStatus.Private) ||
        (isEmpty(mlsStatus) && hasExclusive)) &&
      isPrivateStatusAvailable
    );
  }
);
