import { isEqual } from 'lodash';

import { Nullable } from 'shared/types';

type Falsy = undefined | null | 0 | '' | false;

// eslint-disable-next-line @typescript-eslint/ban-types
type Value = Record<string, unknown> | string | unknown[] | {};

export const not = (input: unknown): input is Falsy => !input;

export const isEmpty = <T extends Value>(
  input: Nullable<T>
): input is never => {
  if (Array.isArray(input) || typeof input === 'string') {
    return input.length === 0;
  }

  if (typeof input === 'object' && input !== null) {
    return Object.keys(input).length === 0;
  }

  return true;
};

export const notEmpty = <T extends Value>(
  input: Nullable<T> | never
): input is T => {
  return not(isEmpty(input));
};

export const notEqual = (a: unknown, b: unknown): boolean => not(isEqual(a, b));

export const isNil = <T>(input: Nullable<T>): input is null | undefined => {
  return typeof input === 'undefined' || input === null;
};

export const notNil = <T>(input: Nullable<T>): input is T => not(isNil(input));

export { isEqual };
