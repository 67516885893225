import { Search } from 'search/search-types';

export const recentSearches = {
  deserialize: (json: string | undefined): Search[] => {
    try {
      if (json) {
        const parsed = JSON.parse(json);
        if (Array.isArray(parsed)) {
          return parsed;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  },

  serialize: (searches: Search[]): string => {
    return JSON.stringify(searches);
  },
};
