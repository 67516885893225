import React from 'react';
import Div100vh from 'react-div-100vh';
import OutsideClickHandler from 'react-outside-click-handler';
import { generatePath, Link, useLocation } from 'react-router-dom';
import ScrollLock from 'react-scrolllock';
import styled, { css } from 'styled-components/macro';

import { getIsAdminAgent } from 'agents/agentsSelectors';
import { contactNavRoutes } from 'contacts/contactsConstants';
import { Contact } from 'contacts/contactsTypes';
import CounterLabel from 'shared/components/CounterLabel';
import { ContextMenuConnectProps } from 'shared/components/Header/components/ContextMenu/index';
import { Logo as Logotype } from 'shared/components/Logo';
import ProfileImage, {
  InitialsProfileImage,
  RealProfileImage,
} from 'shared/components/ProfileImage/ProfileImage';
import { AGENT_TIP_HEIGHT } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { ReactComponent as IconLogout } from 'shared/icons/shared/logout.svg';
import { mediaBetween } from 'shared/styles/customMedia';
import globalTheme from 'shared/styles/globalTheme';
import { Themed } from 'shared/types';
import { Typography } from 'styled-system/components';
import { media, useIsMobile } from 'styled-system/responsive';
import { UserRole } from 'user/userTypes';

const MenuOutsideClickContainer = styled(({ fixed, ...rest }) => (
  <Div100vh {...rest} />
))<{ disabled: boolean; fixed: boolean }>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  transform: translate3d(0, 0, 0);
  ${({ disabled }) => disabled && 'pointer-events: none;'};
  ${media.md`position: fixed;`};
`;

const ContainerStyles = css<any>`
  background: ${({ theme }: Themed) => theme.white};
  background-color: ${({ theme }: Themed) => theme.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  flex-direction: column;
  position: absolute;
`;

const MenuSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.divider};
  margin-left: 24px;
`;

const Mobile = styled.div<{ visible: boolean; agentMode?: boolean }>`
  ${ContainerStyles};

  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: translate3d(${({ visible }) => (visible ? 0 : '-100%')}, 0, 0);

  ${media.md`
    position: fixed;
    top: 0;
    left: 0;
    width: 85%;
    overflow: hidden;
    height: calc(100% - ${({ agentMode }) =>
      agentMode ? `${AGENT_TIP_HEIGHT}px` : '0px'});
    ${({ agentMode }) =>
      agentMode &&
      css`
        top: ${AGENT_TIP_HEIGHT}px;
      `}
  `};

  ${mediaBetween(768, 1024)} {
    width: 350px;
  }

  nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const Desktop = styled.div<{ visible: boolean; agentMode?: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  border-radius: 1px;
  width: 285px;
  top: 56px;
  right: 16px;

  ${({ agentMode }) =>
    agentMode &&
    css`
      top: 68px;
    `}

  ${ContainerStyles}

  & > ${MenuSection}:first-child {
    border-top: 0;
  }

  ${media.sm`
    display: none;
  `};
`;

const Item = styled(Link)`
  color: ${({ theme }) => theme.text2};
  display: flex;
  align-items: center;
  margin: 0;
  padding: 14px 0 14px 6px;
  transition: opacity 0.2s;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
`;

const MobileHeaderContainer = styled.div`
  height: 50px;
  padding-right: 16px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.divider};

  ${InitialsProfileImage},${RealProfileImage} {
    width: 32px;
    height: 32px;
  }
`;

const MobileSection = styled(MenuSection)`
  border: none;
  &:first-child {
    border-top: none;
  }
`;

const Separator = styled.span<{ agentMode?: boolean }>`
  display: block;
  width: calc(100% - 24px);
  margin: auto 0 0 24px;
  height: 1px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.divider};

  ${({ agentMode }) =>
    agentMode &&
    css`
      margin-top: 24px;
    `}
`;

const DesktopSection = styled(MenuSection)`
  &:first-child {
    padding-bottom: 16px;
  }
  &:not(:first-child) {
    padding-bottom: 8px;
  }
`;

const LogOutSection = styled(MenuSection)`
  margin-top: auto;
  bottom: 0;
  left: 0;
  svg {
    margin-right: 8px;
  }
`;

const Logo = styled(Logotype)`
  height: 21px;
`;

interface OwnProps {
  isVisible: boolean;
  closeMenu: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => void;
  handleKeyPress?: (event: React.KeyboardEvent<HTMLElement>) => void;
  fixed?: boolean;
}

type ContextMenuProps = ContextMenuConnectProps & OwnProps;

const ContextMenu: React.FC<ContextMenuProps> = props => {
  const {
    isVisible,
    closeMenu,
    isAuthorised,
    user,
    user: {
      profile: { data },
    },
    fixed,
    savedSearchInvitationsCount,
    collectionsInvitationsCount,
    notificationHistoryCount,
    agentMode,
    handleKeyPress,
  } = props;
  const location = useLocation();
  const isMobile = useIsMobile({ large: true });

  const isAdminAgent = useSelector(getIsAdminAgent);

  if (agentMode) {
    return (
      <AgentModeContextMenu
        isVisible={isVisible}
        fixed={fixed}
        closeMenu={closeMenu}
        handleKeyPress={handleKeyPress}
        contactItem={props.contactItem}
      />
    );
  }

  return (
    <MenuOutsideClickContainer disabled={not(isVisible)} fixed={fixed}>
      <OutsideClickHandler onOutsideClick={closeMenu}>
        {isMobile ? (
          <Mobile visible={isVisible}>
            <MobileHeaderContainer>
              <Logo isLink onClick={closeMenu} />
              {data && (
                <ProfileImage small profile={data} isLink onClick={closeMenu} />
              )}
            </MobileHeaderContainer>
            <ScrollLock isActive={isVisible}>
              <nav onClick={closeMenu}>
                <MobileSection>
                  <Item to={RoutePath.HOME}>Home</Item>
                  <Item to={RoutePath.LISTINGS}>Buy</Item>
                  <Item to={RoutePath.LEASE} rel="nofollow">
                    Lease
                  </Item>
                  <Item to={RoutePath.SELL}>Sell</Item>
                  <Item to={RoutePath.AGENTS}>Agents</Item>
                  <Item to={RoutePath.COMPANY}>Company</Item>
                  <Item to={RoutePath.CAREERS}>Careers</Item>
                  <Item to={RoutePath.BLOG}>Blog</Item>
                </MobileSection>
                <Separator />
                <MobileSection>
                  <Item to={RoutePath.SAVED_SEARCHES}>
                    Saved Searches{' '}
                    <CounterLabel
                      label={savedSearchInvitationsCount}
                      styles={{ marginLeft: '16px' }}
                    />
                  </Item>
                  <Item to={RoutePath.COLLECTIONS}>
                    Collections{' '}
                    <CounterLabel label={collectionsInvitationsCount} />
                  </Item>
                  <Item to={RoutePath.VIEWED_SEARCHES}>Viewed Searches</Item>
                  <Item to={RoutePath.VIEWED_LISTINGS}>Viewed Listings</Item>
                </MobileSection>
                <Separator />
                <MobileSection>
                  <Item to={RoutePath.ACCOUNT_SETTINGS}>Account Settings</Item>
                  <Item to={RoutePath.NOTIFICATION_INBOX}>
                    Notification Inbox{' '}
                    <CounterLabel label={notificationHistoryCount} />
                  </Item>
                  {not(user.role === UserRole.Agent) && (
                    <Item to={RoutePath.USER_EMAIL_SETTINGS}>
                      Email Settings
                    </Item>
                  )}

                  {user.role === UserRole.Agent && (
                    <>
                      <Item to={RoutePath.AGENT_MY_LISTINGS}>
                        {isAdminAgent ? 'Agent Listings' : 'My Listings'}
                      </Item>
                      <Item to={RoutePath.AGENT_CONTACTS}>My Contacts</Item>
                    </>
                  )}
                  {user.role === UserRole.Client && (
                    <Item to={RoutePath.YOUR_CONTACT}>Contact Your Agent</Item>
                  )}
                </MobileSection>
                {isAuthorised && (
                  <>
                    <LogOutSection>
                      <Item
                        to={{
                          pathname: RoutePath.HOME,
                          state: {
                            logOut: true,
                          },
                        }}
                      >
                        <IconLogout
                          width={24}
                          height={24}
                          fill={globalTheme.text2}
                        />
                        <span>Log Out</span>
                      </Item>
                    </LogOutSection>
                  </>
                )}
              </nav>
            </ScrollLock>
          </Mobile>
        ) : (
          <Desktop
            onClick={closeMenu}
            visible={isVisible}
            onKeyUp={(event: React.KeyboardEvent<HTMLElement>) =>
              handleKeyPress?.(event)
            }
          >
            <DesktopSection>
              <Item to={RoutePath.SAVED_SEARCHES}>
                Saved Searches{' '}
                <CounterLabel
                  label={savedSearchInvitationsCount}
                  styles={{ marginLeft: '16px' }}
                />
              </Item>
              <Item to={RoutePath.COLLECTIONS}>
                Collections{' '}
                <CounterLabel
                  label={collectionsInvitationsCount}
                  styles={{ marginLeft: '16px' }}
                />
              </Item>
              <Item to={RoutePath.VIEWED_SEARCHES}>Viewed Searches</Item>
              <Item to={RoutePath.VIEWED_LISTINGS}>Viewed Listings</Item>
            </DesktopSection>
            <DesktopSection>
              <Item to={RoutePath.ACCOUNT_SETTINGS}>Account Settings</Item>
              <Item to={RoutePath.NOTIFICATION_INBOX}>
                Notification Inbox{' '}
                <CounterLabel
                  label={notificationHistoryCount}
                  styles={{ marginLeft: '16px' }}
                />
              </Item>
              {not(user.role === UserRole.Agent) && (
                <Item to={RoutePath.USER_EMAIL_SETTINGS}>Email Settings</Item>
              )}

              {user.role === UserRole.Agent && (
                <>
                  <Item to={RoutePath.AGENT_MY_LISTINGS}>
                    {isAdminAgent ? 'Agent Listings' : 'My Listings'}
                  </Item>
                  <Item to={RoutePath.AGENT_CONTACTS}>My Contacts</Item>
                </>
              )}
              {user.role === UserRole.Client && (
                <Item to={RoutePath.YOUR_CONTACT}>Contact Your Agent</Item>
              )}
            </DesktopSection>
            <DesktopSection>
              <Item
                to={{
                  pathname: RoutePath.HOME,
                  state: {
                    logOut: true,
                  },
                }}
              >
                Log out
              </Item>
            </DesktopSection>
          </Desktop>
        )}
      </OutsideClickHandler>
    </MenuOutsideClickContainer>
  );
};

interface AgentModeContextMenuProps {
  isVisible: boolean;
  contactItem: Contact;
  fixed?: boolean;
  closeMenu: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => void;
  handleKeyPress?: (event: React.KeyboardEvent<HTMLElement>) => void;
}

const AgentModeContextMenu: React.FC<AgentModeContextMenuProps> = props => {
  const { isVisible, fixed, closeMenu, handleKeyPress, contactItem } = props;

  const isMobile = useIsMobile({ large: true });

  const {
    currentAgentOwnsThisContact,
    isLeasedSearchAllowed,
    username: contactId,
  } = contactItem || {};

  return (
    <MenuOutsideClickContainer disabled={not(isVisible)} fixed={fixed}>
      <OutsideClickHandler onOutsideClick={closeMenu}>
        {isMobile ? (
          <Mobile visible={isVisible} agentMode>
            <MobileHeaderContainer>
              <Logo isLink />
              <ProfileImage small profile={contactItem} />
            </MobileHeaderContainer>
            <ScrollLock isActive={isVisible}>
              <nav onClick={closeMenu}>
                {currentAgentOwnsThisContact && contactId && (
                  <>
                    <MobileSection>
                      <Item
                        to={generatePath(RoutePath.AGENT_LISTINGS, {
                          contactId,
                        })}
                      >
                        Buy
                      </Item>
                      {isLeasedSearchAllowed && (
                        <Item
                          to={generatePath(RoutePath.AGENT_LISTINGS_LEASE, {
                            contactId,
                          })}
                          rel="nofollow"
                        >
                          Lease
                        </Item>
                      )}
                    </MobileSection>
                    <Separator agentMode />
                  </>
                )}
                {contactId && (
                  <MobileSection>
                    {contactNavRoutes.map((route, i) => {
                      const url = generatePath(route.path, {
                        contactId,
                      });

                      return (
                        <Item key={i} to={url}>
                          {route.name}
                        </Item>
                      );
                    })}
                  </MobileSection>
                )}
                <LogOutSection>
                  <Item to={RoutePath.AGENT_CONTACTS}>
                    <IconLogout
                      width={24}
                      height={24}
                      fill={globalTheme.text2}
                    />
                    <span>Quit Impersonated Mode</span>
                  </Item>
                </LogOutSection>
              </nav>
            </ScrollLock>
          </Mobile>
        ) : (
          <Desktop
            agentMode
            onClick={closeMenu}
            visible={isVisible}
            onKeyUp={(event: React.KeyboardEvent<HTMLElement>) =>
              handleKeyPress?.(event)
            }
          >
            {contactId && (
              <DesktopSection>
                {contactNavRoutes.map((route, i) => {
                  const url = generatePath(route.path, {
                    contactId,
                  });

                  return (
                    <Item key={i} to={url}>
                      {route.name}
                    </Item>
                  );
                })}
              </DesktopSection>
            )}
            <DesktopSection>
              <Item to={RoutePath.AGENT_CONTACTS}>
                <IconLogout width={24} height={24} fill={globalTheme.text2} />
                <Typography ml="1">Quit Impersonated Mode</Typography>
              </Item>
            </DesktopSection>
          </Desktop>
        )}
      </OutsideClickHandler>
    </MenuOutsideClickContainer>
  );
};

export default ContextMenu;
