import { all } from 'redux-saga/effects';

import { agentsSagas } from 'agents/agentsSagas';
import { sellSagas } from 'butter-cms/pages/sell/sellSagas';
import { collectionsSagas } from 'collections/collectionsSagas';
import { commentsSagas } from 'comments/commentsSagas';
import { contactsSagas } from 'contacts/contactsSagas';
import { emailHistorySagas } from 'history/emailHistory/emailHistorySagas';
import { inquiryHistorySagas } from 'history/inquiryHistory/inquiryHistorySagas';
import { notificationHistorySagas } from 'history/notificationHistory/notificationHistorySagas';
import { listingDetailsSagas } from 'listingDetails/listingDetailsSagas';
import { listingsSagas } from 'listings/listingsSagas';
import { mapSagas } from 'map/mapSagas';
import { myListingsSagas } from 'myListings/myListingsSagas';
import { savedSearchSagas } from 'saved-search/savedSearchSagas';
import { searchSagas } from 'search/search-sagas';
import { userSagas } from 'user/userSagas';

export function* rootSaga() {
  yield all([
    agentsSagas(),
    collectionsSagas(),
    commentsSagas(),
    contactsSagas(),
    listingDetailsSagas(),
    listingsSagas(),
    mapSagas(),
    savedSearchSagas(),
    searchSagas(),
    sellSagas(),
    inquiryHistorySagas(),
    emailHistorySagas(),
    notificationHistorySagas(),
    userSagas(),
    myListingsSagas(),
  ]);
}
