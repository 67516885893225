import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  ContactsActionType,
  FetchContactAction,
  fetchContactFail,
  fetchContactsFail,
  fetchContactsRequest,
  fetchContactsSuccess,
  fetchContactSuccess,
  UpdateContactAction,
  updateContactFail,
  UpdateContactNotificationsSettingsAction,
  updateContactNotificationsSettingsFail,
  updateContactNotificationsSettingsSuccess,
  updateContactSuccess,
} from 'contacts/contactsActions';
import { assignIfThisContactsAgent } from 'contacts/contactsSelectors';
import { ContactsService } from 'contacts/ContactsService';
import { Contact, ContactsState } from 'contacts/contactsTypes';
import { showSuccessToast } from 'shared/helpers/notifications';
import { UnpackReturnedPromise } from 'shared/types';

export function* fetchContactsSaga() {
  const { page, sortBy, sortType, query }: ContactsState = yield select(
    ({ contacts }) => contacts
  );
  try {
    yield put(fetchContactsRequest());

    const contacts: UnpackReturnedPromise<
      typeof ContactsService.fetchContacts
    > = yield call(ContactsService.fetchContacts, {
      page,
      sortBy,
      sortType,
      query,
    });

    yield put(fetchContactsSuccess(contacts));
  } catch (e) {
    yield put(fetchContactsFail(e.message));
  }
}

export function* filterContactsSaga() {
  yield delay(500);
  yield fetchContactsSaga();
}

function* fetchContactSaga(action: FetchContactAction): Saga {
  try {
    const currentUser = yield select(({ user }) => user.profile.data);
    const contact: UnpackReturnedPromise<typeof ContactsService.fetchContact> =
      yield call(ContactsService.fetchContact, action.id);

    yield put(
      fetchContactSuccess(assignIfThisContactsAgent(contact, currentUser))
    );
  } catch (e) {
    yield put(fetchContactFail(e.message));
  }
}

function* updateContactNotificationsSettingsSaga(
  action: UpdateContactNotificationsSettingsAction
) {
  try {
    const contactNotificationsSettings: Partial<Contact> = yield call(
      ContactsService.updateContactNotificationsSettings,
      action.payload.body
    );

    yield put(
      updateContactNotificationsSettingsSuccess(contactNotificationsSettings)
    );

    showSuccessToast('Changes saved');
    action.payload.onSuccessCb?.();
  } catch (error) {
    action.payload.onErrorCb?.();
    yield put(updateContactNotificationsSettingsFail(error.message));
  }
}

function* updateContactSaga(action: UpdateContactAction): Saga {
  try {
    const currentUser = yield select(({ user }) => user.profile.data);
    const contact: UnpackReturnedPromise<typeof ContactsService.updateContact> =
      yield call(ContactsService.updateContact, action.id, action.contactData);

    yield put(
      updateContactSuccess(assignIfThisContactsAgent(contact, currentUser))
    );

    showSuccessToast('Changes saved');
  } catch (error) {
    yield put(updateContactFail(error.message));
  }
}

const fetchContactsSub = () =>
  takeLatest(
    [
      ContactsActionType.FETCH_CONTACTS,
      ContactsActionType.SORT_CONTACTS,
      ContactsActionType.CHANGE_CONTACTS_PAGE,
    ],
    fetchContactsSaga
  );

const filterContactsSub = () =>
  takeLatest([ContactsActionType.FILTER_CONTACTS], filterContactsSaga);

const fetchContactSub = () =>
  takeLatest(ContactsActionType.FETCH_CONTACT, fetchContactSaga);

const updateContactNotificationsSettingsSub = () =>
  takeLatest(
    ContactsActionType.UPDATE_CONTACT_NOTIFICATIONS_SETTINGS,
    updateContactNotificationsSettingsSaga
  );

const updateContactSub = () =>
  takeLatest(ContactsActionType.UPDATE_CONTACT, updateContactSaga);

export function* contactsSagas() {
  yield all([
    fetchContactsSub(),
    filterContactsSub(),
    fetchContactSub(),
    updateContactNotificationsSettingsSub(),
    updateContactSub(),
  ]);
}
