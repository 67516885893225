import styled, { css } from 'styled-components/macro';

import { ReactComponent as IconWarning } from 'shared/icons/listing-details/round-warning.svg';
import { media } from 'styled-system/responsive';

const Container = styled.div`
  border: solid 1px #edeeef;
  border-radius: 1px;
  padding: 20px;
  ${media.sm`
    border: none;
    margin-bottom: 0;
    padding: 0;
  `}
`;
const Header = styled.div`
  margin-bottom: 28px;
  ${media.sm`
    margin-bottom: 24px;
  `}
  strong {
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin-right: 8px;
  }
`;

const PercentageLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 18px;
  width: 100%;
  height: 14px;
  background: #eef0f2;
  ${media.sm`
    margin-bottom: 14px;
  `}
  span {
    transition: width 0.3s ease-in-out;
  }
`;
const Description = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  ${media.sm`
    display: block;
  `}
`;

const TooltipContainer = styled.div``;

const DescriptionItem = styled.div`
  display: flex;
  align-items: center;
  color: #515e68;
  font-size: 14px;
  line-height: 18px;
  margin-right: 24px;
  white-space: nowrap;
  ${media.sm`
    font-size: 12px;
    line-height: 16px;
    margin-right: 0;
    margin-bottom: 8px;
  `}
  strong {
    color: #1c262e;
    font-weight: bold;
    margin-left: 8px;
  }
`;

const ColorBox = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 8px;
  ${media.sm`
    width: 10px;
    height: 10px;
    margin-right: 14px;
  `}
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: solid 1px #edeeef;
  ${media.sm`
    display: block;
    border-bottom: none;
    padding-bottom: 4px;
  `}
`;

const Column = styled.div`
  width: 48%;
  ${media.sm`
    width: 100%;
  `}
`;

const Row = styled.div`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
    ${media.sm`
      margin-bottom: 14px;
    `}
  }
  ${media.sm`
    margin-bottom: 14px;
  `}
`;

const RowLabel = styled.span`
  display: flex;
  align-items: center;
`;

const RowLabelIcon = styled(IconWarning)<{ $isTooltipVisible: boolean }>`
  margin-left: 6px;
  cursor: pointer;
  ${({ $isTooltipVisible }) =>
    $isTooltipVisible &&
    css<any>`
      * {
        fill: ${({ theme }) => theme.text3};
      }
    `}
  &:hover {
    * {
      fill: ${({ theme }) => theme.text3};
    }
  }
`;
const PaymentCalculatorStyles = {
  Container,
  Header,
  PercentageLine,
  Description,
  DescriptionItem,
  ColorBox,
  FormContainer,
  Column,
  Row,
  RowLabel,
  RowLabelIcon,
  TooltipContainer,
};

export default PaymentCalculatorStyles;
