import { call, put, select } from 'redux-saga/effects';

import {
  FetchCollectionAction,
  setLoadedCollectionFail,
} from 'collections/collectionsActions';
import {
  DISMISSED_COLLECTION_ID,
  PROPOSED_COLLECTION_ID,
} from 'collections/collectionsConstants';
import { isDismissedId, isProposedId } from 'collections/collectionsHelpers';
import { CollectionService } from 'collections/CollectionsService';
import {
  DismissedCollection,
  ProposedCollection,
  RawCollection,
} from 'collections/collectionsTypes';
import { getContactUsername } from 'contacts/contactsSelectors';
import { LISTINGS_PER_PAGE } from 'listings/listingsConstants';
import { ListingsService } from 'listings/ListingsService';
import { RootState } from 'rootReducer';
import { ReturnPromise } from 'shared/types';
import { unsafeCoerce } from 'shared/utils/types';

export function* fetchCollectionSaga(action: FetchCollectionAction) {
  try {
    if (
      isNaN(Number(action.collectionId)) &&
      action.collectionId !== DISMISSED_COLLECTION_ID &&
      action.collectionId !== PROPOSED_COLLECTION_ID
    ) {
      throw new Error('Unable to fetch collection with wrong id 404');
    }

    const selector = (state: RootState) => state.listings;
    const listingsState: ReturnType<typeof selector> = yield select(selector);

    const username: string = yield select(getContactUsername);

    type Response = ReturnPromise<typeof CollectionService.fetchCollection>;
    let collection: Response = yield call(CollectionService.fetchCollection, {
      id: action.collectionId,
      limit: LISTINGS_PER_PAGE,
      ourListingsFirst: listingsState.ourListingsFirst,
      page: action.page || listingsState.page,
      sortBy: action.sortBy || listingsState.sortBy,
      sortType: action.sortType || listingsState.sortType,
      user: action.contactId,
    });

    if (isProposedId(action.collectionId)) {
      collection = yield call(prepareProposed, unsafeCoerce(collection));
    }
    if (isDismissedId(action.collectionId)) {
      collection = yield call(prepareDismissed, unsafeCoerce(collection));
    }

    const preparedCollection = unsafeCoerce<RawCollection>({ ...collection });

    yield call(mutateCollectionWithImages, {
      collection: preparedCollection,
      user: username,
    });

    return collection;
  } catch (e) {
    yield put(setLoadedCollectionFail(e.message));
  }
}

export async function mutateCollectionWithImages(params: {
  collection: RawCollection;
  user?: string | null;
}) {
  const { collection, user } = params;

  const listings = collection.addedListings
    ? collection.addedListings.map(added => added.listing)
    : [];

  const images = await ListingsService.fetchListingsImages({
    listingKeys: listings.map(listing => listing.listingkey).join(','),
    user,
  }).catch(error => {
    console.debug('fetchCollectionImages failed', error.message);
    return {} as any;
  });

  collection.addedListings?.forEach(addedListing => {
    addedListing.listing.images = images[addedListing.listing.listingkey] || [];
  });
}

export function prepareProposed({ data }: ProposedCollection) {
  return unsafeCoerce<RawCollection>({ ...data, addedListings: data.listings });
}

export function prepareDismissed({ listings, ...data }: DismissedCollection) {
  return unsafeCoerce<RawCollection>({ ...data, addedListings: listings });
}
