import { each, get, pickBy } from 'lodash';
import queryString, { ParsedQuery } from 'query-string';
import TagManager from 'react-gtm-module';
import { matchPath } from 'react-router-dom';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { isAgentBioPage } from 'agents/agentsHelpers';
import { getAgentBio } from 'agents/agentsSelectors';
import { Agent } from 'agents/agentsTypes';
import {
  FetchCollectionAction,
  setLoadedCollection,
} from 'collections/collectionsActions';
import { CollectionsActionType } from 'collections/collectionsActionType';
import { RawCollection } from 'collections/collectionsTypes';
import {
  FetchCollectionBoundariesResult,
  fetchCollectionBoundariesSaga,
} from 'collections/sagas/fetch-boundaries';
import { fetchCollectionSaga } from 'collections/sagas/fetch-collection';
import { getAgentMode, getContactUsername } from 'contacts/contactsSelectors';
import {
  ApplyFiltersAction,
  FiltersAction,
  FiltersActionType,
  mergeFiltersWithDefault,
  SetFilterRangeValueAction,
} from 'filters/filtersActions';
import {
  FILTERS_DEFAULT_STATE,
  getDefaultFilters,
} from 'filters/filtersReducer';
import { getChangedFilters } from 'filters/filtersSelectors';
import { FiltersState } from 'filters/filtersTypes';
import {
  changeListingsPage,
  ChangeListingsPageAction,
  fetchListings,
  FetchListingsAction,
  fetchListingsFail,
  fetchListingsRequest,
  fetchListingsSuccess,
  FetchViewedListingsAction,
  handleListingsLoading,
  initListings,
  ListingsActionType,
  mergeListings,
  mergeListingsWithDefault,
  SearchListingsAction,
  SetClickedMarkerAndChangePageAction,
  setClickedMarkerId,
  setIsFirstListingPage,
  setIsLastListingPage,
  SetListingsFromUrlAction,
  SortListingsAction,
  TrackAreaOfInterestAction,
} from 'listings/listingsActions';
import {
  AGENT_CONTACT_VIEWED_LISTINGS_URL,
  LISTINGS_PER_PAGE,
  SAVED_SEARCH_TAG_TYPE,
  searchUrl as SEARCH_BASE_URL,
  USER_VIEWED_LISTINGS_URL,
} from 'listings/listingsConstants';
import {
  addImagesToListings,
  constructUrl,
  getClickedMarketListingPage,
  getIsLease,
  isFirstPage,
  isLastPage,
  isMapAction,
} from 'listings/listingsHelpers';
import {
  getClusters,
  getDataForFetchingListings,
} from 'listings/listingsSelectors';
import { ListingsService } from 'listings/ListingsService';
import {
  Listing,
  ListingsImagesResponse,
  ListingsState,
  PaginatedListings,
  UpdateListingsAndUrlActionsType,
} from 'listings/listingsTypes';
import {
  generateListingsQueryUrl,
  getUserLocationFromQuery,
  parseListingsQueryUrl,
  parseViewedListingsUrl,
} from 'listings/listingUrlParser';
import {
  AddMapDrawingPolygon,
  changeMapPositionFromUrl,
  ClearMapDataAction,
  HandleClusterClickAction,
  HandleFetchClusters,
  HandleMapChangeAction,
  handleMapLoading,
  initializeMap,
  MapActionType,
  saveClusters,
} from 'map/mapActions';
import { TypesOfMap } from 'map/mapConstants';
import { getMapByType } from 'map/mapSelectors';
import { fetchCluster } from 'map/MapService';
import { MapInnerState } from 'map/mapTypes';
import { getPolygonUrlFromLink } from 'marketOverview/marketOverviewHelpers';
import { RESET_ALL, RESET_FILTERS, ResetAllAction } from 'rootActions';
import { RootState } from 'rootReducer';
import { SearchDescriptionBuilder } from 'saved-search/services/SearchDescriptionBuilder';
import { getTags } from 'search/search-selectors';
import * as search from 'search/search-slice';
import { Tag } from 'search/search-types';
import {
  GTM_CUSTOM_EVENTS_NAMES,
  SortingData,
} from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { isEmpty, not, notEmpty } from 'shared/helpers/boolean';
import { hasErrorMessage } from 'shared/helpers/errorHelpers';
import { isLeasePage } from 'shared/helpers/isLeasePage';
import { getIsViewedListingsPage } from 'shared/helpers/isViewedListings';
import {
  removeDefaultProperties,
  scrollToTop,
} from 'shared/helpers/mainHelpers';
import { callIfOnline } from 'shared/helpers/network';
import { showErrorToast } from 'shared/helpers/notifications';
import { safeSelect } from 'shared/helpers/saga';
import { waitForInitMapBoundaries } from 'shared/sagas/waitForInitMapBoundaries';
import { waitForUserLocation } from 'shared/sagas/waitForUserLocation';
import { getProfileData } from 'shared/selectors/profileSelector';
import { AuthService } from 'shared/services/AuthService';
import history from 'shared/services/history';
import { BaseResponse, ImageInfo, ReturnPromise } from 'shared/types';
import { unsafeCoerce } from 'shared/utils/types';
import { setUserLocation, UserActionType } from 'user/userActions';
import {
  getIsAgent,
  getIsMobile,
  getIsUserInitialized,
  getProfile,
  getUserLocation,
} from 'user/userSelectors';
import { UserService } from 'user/UserService';

const ExcludedFilters: Array<keyof FiltersState> = ['openHouse'];

function* sendFiltersToGASaga(
  action: SetFilterRangeValueAction | ApplyFiltersAction
): Saga {
  const { value: filters } = action;
  const isAgent = yield select(getIsAgent);
  const agentMode = yield select(getAgentMode);

  const selectedFilters = pickBy(
    removeDefaultProperties(filters, getDefaultFilters({ isAgent, agentMode })),
    (value: unknown, key: keyof FiltersState) => {
      return Boolean(value) && not(ExcludedFilters.includes(key));
    }
  );

  each(selectedFilters, (value, key) => {
    TagManager.dataLayer({
      dataLayer: {
        event: GTM_CUSTOM_EVENTS_NAMES.SEARCH_WITH_FILTERS,
        search_filter: key.toLowerCase(),
        search_value: JSON.stringify(value),
      },
      dataLayerName: 'PageDataLayer',
    });
  });
}

export async function fetchImages(params: {
  listings: Listing[];
  user?: string;
}): Promise<{ [key: string]: ImageInfo[] }> {
  if (!params.listings || !params.listings.length) {
    return {};
  }
  const listingKeys = params.listings.map(listing => listing.listingkey);

  return ListingsService.fetchListingsImages({
    listingKeys: listingKeys.join(','),
    user: params.user,
  });
}

// TODO: refactor
export function* fetchListingsImagesSaga(listings: Listing[], key = 'items') {
  try {
    const username: string = yield select(getContactUsername);

    const images: ListingsImagesResponse = yield call(fetchImages, {
      listings,
      user: username,
    });

    const listingsWithImages = addImagesToListings(listings, images);

    yield put(
      mergeListings({
        [key]: listingsWithImages,
      })
    );
  } catch (e) {
    yield put(
      mergeListings({
        [key]: listings.map(listing => ({ ...listing, images: [] })),
      })
    );
    console.info('Could not fetch images');
  }
}

const isTagsEmpty = (tags: Tag[]) => {
  if (notEmpty(tags)) {
    if (tags.length === 1 && tags[0].type === SAVED_SEARCH_TAG_TYPE) {
      return isEmpty(tags[0].tags);
    }
    return false;
  }
  return true;
};

export function* fetchListingsSaga(action: FetchListingsSagaAction): Saga {
  try {
    const tags: Tag[] = yield select(getTags);
    const isViewedListings = getIsViewedListingsPage(history.location.pathname);
    let baseUrl = SEARCH_BASE_URL;

    const { collectionId, trecLicenses, userId, savedSearch, isLeaseListing } =
      yield select(getDataForFetchingListings);

    const isLease = getIsLease({ savedSearch, isLeaseListing });

    const isTrec = notEmpty(trecLicenses);

    const mapType = detectMapType({
      action,
      collectionId,
      isTrec,
    });

    // TODO Check the behavior. It was a HOT fix of RADEV-5123. We ware fetching listings with an incorrect token
    const profile = yield select(getProfile);
    if (profile.loading) {
      yield take([
        UserActionType.CREATE_OR_UPDATE_PROFILE_SUCCESS,
        UserActionType.CREATE_OR_UPDATE_PROFILE_ERROR,
        UserActionType.FETCH_PROFILE_SUCCESS,
        UserActionType.FETCH_PROFILE_ERROR,
      ]);
    }

    const isUserInitialized = yield select(getIsUserInitialized);
    if (not(isUserInitialized)) {
      yield take([
        UserActionType.USER_IS_LOGGED,
        UserActionType.GET_USER_SESSION_ERROR,
      ]);
    }

    const agentMode = yield select(getAgentMode);
    if (isViewedListings) {
      baseUrl = agentMode
        ? AGENT_CONTACT_VIEWED_LISTINGS_URL
        : USER_VIEWED_LISTINGS_URL;
    }

    //TODO  For collection and collection listings, we could separate this into different sagas
    if (action.type === CollectionsActionType.FETCH_COLLECTION) {
      if (not(action.shouldUpdateListingsStore)) {
        const collection = yield call(fetchCollectionSaga, action);
        yield put(setLoadedCollection(collection));
        return;
      }

      yield put(fetchListingsRequest());
      const collectionsFx = call(fetchCollectionSaga, action);
      const boundariesFx = call(
        fetchCollectionBoundariesSaga,
        action.collectionId
      );

      type AllResults = [RawCollection, FetchCollectionBoundariesResult];
      const results: AllResults = yield all([collectionsFx, boundariesFx]);

      const [collection, searchResult] = results;
      yield put(setLoadedCollection(collection));
      yield put(
        fetchListingsSuccess({
          boundaries: searchResult.boundaries,
          history: searchResult.history || 0,
          listings: unsafeCoerce(
            collection?.addedListings?.map(a => a.listing)
          ),
          pageCount: searchResult.pageCount,
          total: collection.total,
        })
      );

      const listingsPage = yield select(
        (state: RootState) => state.listings.page
      );
      const currentPage = action.page || listingsPage;

      yield put(setIsFirstListingPage(isFirstPage(currentPage)));
      yield put(
        setIsLastListingPage(
          isLastPage(
            currentPage,
            collection.total,
            action.limit || LISTINGS_PER_PAGE
          )
        )
      );

      return;
    }

    const isCollection = isCollectionPage();

    if (isCollection && isMapAction(action)) {
      return yield call(
        fetchClustersSaga,
        action as unknown as HandleFetchClusters
      );
    }

    yield put(fetchListingsRequest());

    const dontSaveViewedSearch =
      action.type === ListingsActionType.SORT_LISTINGS ||
      isMapAction(action) ||
      Boolean(collectionId) ||
      Boolean(isViewedListings) ||
      Boolean(userId);

    let boundaries;

    if (
      search.addTag.match(action) ||
      (search.removeTag.match(action) && not(isTagsEmpty(tags)))
    ) {
      boundaries = null;
    } else if (action.type === ListingsActionType.FETCH_LISTINGS) {
      boundaries = action.force ? action.boundaries : null;
    } else {
      boundaries = isMapAction(action)
        ? action.boundaries
        : yield call(waitForInitMapBoundaries, mapType);
    }

    const url: URL = yield call(constructUrl, {
      path: baseUrl,
      mapData: { boundaries },
      collectionId,
      trecLicenses,
      userId,
      dontSaveViewedSearch,
      lease: isLease,
    });

    const queryParams: ParsedQuery = queryString.parse(url.search);
    const currentPage =
      action.type === ListingsActionType.CHANGE_LISTINGS_PAGE && action.page
        ? action.page
        : Number(queryParams.page);
    const limit = Number(queryParams.limit) || undefined;

    const paginatedListings: BaseResponse<PaginatedListings> = yield call(
      ListingsService.fetchListings,
      url
    );

    yield put(fetchListingsSuccess(paginatedListings?.data));

    if (notEmpty(paginatedListings?.data?.listings)) {
      yield call(fetchListingsImagesSaga, paginatedListings?.data?.listings);
      yield put(setIsFirstListingPage(isFirstPage(currentPage)));
      yield put(
        setIsLastListingPage(
          isLastPage(currentPage, paginatedListings?.data?.total, limit)
        )
      );
    } else if (notEmpty(paginatedListings?.data?.nearbyListings)) {
      yield call(
        fetchListingsImagesSaga,
        paginatedListings?.data?.nearbyListings,
        'nearbyListings'
      );
    }
  } catch (e) {
    callIfOnline(() => showErrorToast('Unable to fetch listings'));
    yield put(fetchListingsFail(hasErrorMessage(e) ? e.message : String(e)));
  }
}

export function* fetchListingsWithoutMapSaga(
  action: ChangeListingsPageAction | SortListingsAction
): Saga {
  try {
    const isViewedListings = getIsViewedListingsPage(history.location.pathname);
    let baseUrl = SEARCH_BASE_URL;

    yield delay(200);
    yield put(fetchListingsRequest());

    yield call(AuthService.getToken);

    const { collectionId, trecLicenses, userId, savedSearch, isLeaseListing } =
      yield select(getDataForFetchingListings);

    const isLease = getIsLease({ savedSearch, isLeaseListing });

    const username: string = yield select(getContactUsername);
    const agentMode = yield select(getAgentMode);

    const { username: agentBioId }: Agent = yield select(getAgentBio);
    const isTrec = notEmpty(trecLicenses) || Boolean(agentBioId);

    const mapType = detectMapType({
      action,
      collectionId,
      isTrec,
    });

    const { mapBoundary }: MapInnerState = yield select(getMapByType, mapType);

    const mapData = {
      boundaries: mapBoundary,
    };

    if (isViewedListings) {
      baseUrl = agentMode
        ? AGENT_CONTACT_VIEWED_LISTINGS_URL
        : USER_VIEWED_LISTINGS_URL;
    }

    const url: URL = yield call(constructUrl, {
      path: baseUrl,
      mapData,
      collectionId,
      trecLicenses,
      userId,
      dontSaveViewedSearch: true,
      lease: isLease,
    });

    // listings
    const queryParams: ParsedQuery = queryString.parse(url.search);
    const currentPage =
      action.type === ListingsActionType.CHANGE_LISTINGS_PAGE && action.page
        ? action.page
        : Number(queryParams.page);
    const limit = Number(queryParams.limit);

    const paginatedListings: BaseResponse<PaginatedListings> = yield call(
      ListingsService.fetchListings,
      url
    );
    const images: ListingsImagesResponse = yield call(fetchImages, {
      listings: paginatedListings?.data?.listings,
      user: username,
    });
    // for right order
    paginatedListings.data.listings = addImagesToListings(
      paginatedListings?.data?.listings,
      images
    );

    yield put(setIsFirstListingPage(isFirstPage(currentPage)));
    yield put(
      setIsLastListingPage(
        isLastPage(currentPage, paginatedListings?.data?.total, limit)
      )
    );

    yield put(fetchListingsSuccess(paginatedListings?.data));
    yield put(initializeMap(mapType));
  } catch (e) {
    showErrorToast('Unable to fetch listings');
    yield put(fetchListingsFail(hasErrorMessage(e) ? e.message : String(e)));
  }
}

export function* fetchListingsSagaWrapper(
  action: FetchListingsSagaAction | ResetAllAction | ClearMapDataAction
) {
  if (
    action.type === FiltersActionType.RESET_ALL_FILTERS ||
    action.type === RESET_ALL
  ) {
    scrollToTop();
  }

  yield race({
    task: call<any>(fetchListingsSaga, action),
    cancelFilters: take(RESET_FILTERS),
    cancelMap: take(MapActionType.CLEAR_MAP_DATA),
  });
}

/* TODO refactor to replace action: unknown with mapType?: MapType | undefined */
interface DetectMapTypeParams {
  action?: unknown;
  collectionId?: string;
  isTrec?: boolean;
}

/* TODO: refactor with params.history or params.location */
export function detectMapType(params: DetectMapTypeParams = {}): TypesOfMap {
  const { action, collectionId, isTrec } = params;

  const mapType =
    get(action, 'mapType') || get(history.location.state, 'mapType');

  if (mapType) {
    return mapType as TypesOfMap;
  }

  if (collectionId) {
    return TypesOfMap.Collections;
  }

  if (isTrec) {
    return TypesOfMap.BioPage;
  }

  if (
    (history.location.pathname.includes(RoutePath.AGENT_CONTACTS) ||
      history.location.pathname.includes(RoutePath.MY)) &&
    history.location.pathname.includes(RoutePath.VIEWED_LISTINGS_POSTFIX)
  ) {
    return TypesOfMap.ViewedListings;
  }

  if (
    history.location.pathname.includes(RoutePath.AGENT_CONTACTS) &&
    history.location.pathname.includes('saved-search')
  ) {
    return TypesOfMap.AgentSavedSearches;
  }

  if (
    history.location.pathname.includes(RoutePath.AGENT_CONTACTS) &&
    history.location.pathname.includes('listings')
  ) {
    return TypesOfMap.AgentListings;
  }

  if (history.location.pathname.includes('saved-search')) {
    return TypesOfMap.SavedSearches;
  }

  if (history.location.pathname.includes(RoutePath.LEASE)) {
    return TypesOfMap.Lease;
  }

  return TypesOfMap.Listings;
}

function* trackListingUrlSaga(url: string): Saga {
  try {
    const tags: Tag[] = yield safeSelect(getTags);
    const filters = yield select(getChangedFilters);
    const profile = yield select(getProfileData);
    const agentMode = yield select(getAgentMode);

    if (agentMode) {
      return;
    }

    const isLease = isLeasePage(history.location.pathname);
    const isLeasedSearchAllowed = profile?.isLeasedSearchAllowed;

    let title = SearchDescriptionBuilder.get({
      tags,
      filters,
    });

    if (title && isLeasedSearchAllowed) {
      title = isLease ? `For Lease: ${title}` : `For Sale: ${title}`;
    }

    yield call(UserService.trackUserPageHistory, { title, url });
  } catch (e) {
    console.error(e, "couldn't sent page info");
  }
}

function* updateListingsUrlSaga(action: UpdateListingsAndUrlActionsType): Saga {
  if (
    not(history.location.pathname.includes(RoutePath.LISTINGS)) &&
    not(getIsViewedListingsPage(history.location.pathname))
  ) {
    return;
  }
  try {
    const filters: FiltersState = yield select(({ filters }) => filters);
    const listings: ListingsState = yield select(({ listings }) => listings);
    const mapType = detectMapType({ action });

    let listingsQuery;
    const userLocation = getUserLocationFromQuery(history.location.search);

    const tags: Tag[] = yield safeSelect(getTags);
    const actionsForKeepSavingMapDataInUrl: string[] = [
      FiltersActionType.RESET_PRICE_FILTERS,
      FiltersActionType.RESET_BEDS_FILTERS,
      FiltersActionType.RESET_BATHS_FILTERS,
      FiltersActionType.RESET_ALL_FILTERS,
      FiltersActionType.RESET_SECONDARY_FILTERS,
      FiltersActionType.APPLY_FILTERS,
      FiltersActionType.SET_FILTER_RANGE_VALUE,
      MapActionType.HANDLE_MAP_CHANGE,
      MapActionType.HANDLE_CLUSTER_CLICK,
      MapActionType.ADD_MAP_DRAWING_POLYGON,
      ListingsActionType.SORT_LISTINGS,
      ListingsActionType.CHANGE_OUR_LISTINGS_FIRST,
      ListingsActionType.CHANGE_LISTINGS_PAGE,
    ];

    const isAgent = yield select(getIsAgent);
    const agentMode = yield select(getAgentMode);

    if (
      // change url after user interactions with map
      action.type === MapActionType.HANDLE_MAP_CHANGE ||
      action.type === MapActionType.HANDLE_CLUSTER_CLICK ||
      // keep mapData in url if user add filters or sorting
      actionsForKeepSavingMapDataInUrl.includes(action.type) ||
      // keep mapData in url if user remove last tag (in that case map stay in same place)
      search.removeTag.match(action) ||
      tags.length === 0
    ) {
      const currentMap = yield select((state: RootState) => state.map[mapType]);
      const mapData = {
        ...currentMap.mapBoundary,
        zoom: currentMap.zoom,
      };

      listingsQuery = generateListingsQueryUrl({
        agentMode,
        filters,
        isAgent,
        listings,
        mapData,
        tags,
        userLocation,
      });
    } else {
      listingsQuery = generateListingsQueryUrl({
        agentMode,
        filters,
        isAgent,
        listings,
        tags,
        userLocation,
      });
    }

    const pathname = history.location.pathname.replace(
      getPolygonUrlFromLink(history.location.pathname),
      ''
    );
    const searchQuery = listingsQuery.search ? `?${listingsQuery.search}` : '';

    if (
      `${history.location.pathname}${history.location.search}` ===
      `${pathname}${listingsQuery.path}${searchQuery}`
    ) {
      return;
    }

    history.push({
      pathname: `${pathname}${listingsQuery.path}`,
      search: searchQuery,
      state: { forceUrlUpdate: true },
    });
    if (action.type !== ListingsActionType.CHANGE_LISTINGS_PAGE) {
      yield put(
        search.searchTriggeredFx({
          query: searchQuery,
          search: {
            filters: removeDefaultProperties(filters, FILTERS_DEFAULT_STATE),
            tags,
          },
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* setListingsFromUrlSaga(
  action: SetListingsFromUrlAction
): Saga {
  if (isAgentBioPage()) {
    return;
  }

  const isViewedListings = getIsViewedListingsPage(history.location.pathname);

  try {
    const { location } = action;
    type ParsedData = ReturnPromise<typeof parseListingsQueryUrl>;
    const parsedUrlData: ParsedData = yield isViewedListings
      ? parseViewedListingsUrl(location)
      : parseListingsQueryUrl(location);

    const { filters, listings, tags, mapBoundary, zoom } = parsedUrlData;

    let { userLocation } = parsedUrlData;

    const queryParams: ParsedQuery = location?.search
      ? queryString.parse(location.search)
      : queryString.parse(window.location.search);

    if (listings.sortBy === SortingData.distance && isEmpty(userLocation)) {
      userLocation = yield select(getUserLocation);
      if (isEmpty(userLocation)) {
        yield put(handleListingsLoading(true));
        userLocation = yield call(waitForUserLocation);
      }
    }

    //we need to wait for use role
    const isUserInitialized = yield select(getIsUserInitialized);
    if (not(isUserInitialized)) {
      yield take([
        UserActionType.USER_IS_LOGGED,
        UserActionType.GET_USER_SESSION_ERROR,
      ]);
    }

    const isAgent = yield select(getIsAgent);
    const agentMode = yield select(getAgentMode);

    yield put(mergeFiltersWithDefault({ filters, isAgent, agentMode }));
    yield put(mergeListingsWithDefault(listings));
    yield put(search.setTags({ tags }));

    const q = queryString.stringify(queryParams, { encode: false });
    yield put(
      search.searchTriggeredFx({
        query: notEmpty(q) && not(q.startsWith('?')) ? `?${q}` : q,
        search: { filters, tags },
      })
    );

    if (notEmpty(userLocation)) {
      yield put(setUserLocation(userLocation));
    }

    yield call(
      trackListingUrlSaga,
      `${history.location.pathname}${history.location.search}`
    );

    if (mapBoundary) {
      const mapType = detectMapType();

      // TODO check when we don't have zoom here
      yield put(changeMapPositionFromUrl(mapType, mapBoundary, zoom));

      if (zoom) {
        history.replace({
          ...history.location,
          state: { ...history.location.state, forceZoom: zoom },
        });
      }

      yield put(fetchListings(mapType, true, mapBoundary));
    } else if (notEmpty(tags)) {
      // in case we have tags we don't need to wait for boundaries, we receive them from response
      yield put(fetchListings());
    } else {
      // in case we don't have boundaries - we should init listing (it means wait for our map boundaries)
      yield put(initListings());
    }
  } catch (e) {
    // fixme if there is an error, nothing happens
    console.error(e);
  }
}

function* setClickedMarkerIdAndChangePageSaga(
  action: SetClickedMarkerAndChangePageAction
): Saga {
  try {
    const state: RootState = yield select();
    const { page } = state.listings;
    const mapType = detectMapType();
    const clusters = getClusters(state, mapType);
    const id = action.listingId;

    if (!action.listingId) {
      return yield put(setClickedMarkerId('-1'));
    }

    const listingPage = getClickedMarketListingPage(id, clusters);

    if (listingPage && page !== listingPage) {
      yield put(changeListingsPage(listingPage));
    }
    yield put(setClickedMarkerId(id));
  } catch (e) {
    console.info(e);
  }
}

function* fetchClustersSaga(action: HandleFetchClusters): Saga {
  try {
    const { collectionId, trecLicenses, userId, savedSearch, isLeaseListing } =
      yield select(getDataForFetchingListings);

    const isLease = getIsLease({ savedSearch, isLeaseListing });

    yield put(handleMapLoading(action.mapType, true));

    const url: URL = yield call(constructUrl, {
      path: '/api/v2/map/points',
      collectionId,
      trecLicenses,
      lease: isLease,
      userId,
    });

    const isMobile: boolean = yield select(getIsMobile);

    const clusters = yield call(fetchCluster, {
      boundaries: action.boundaries,
      zoom: action.zoom,
      isMobile,
      url,
    });

    yield put(initializeMap(action.mapType));
    yield put(handleMapLoading(action.mapType, false));
    yield put(saveClusters(action.mapType, clusters));
  } catch (e) {
    console.info(e);
  }
}

function* trackAreaOfInterestSaga(action: TrackAreaOfInterestAction): Saga {
  try {
    yield call(ListingsService.trackAreaOfInterest, action.listingId);
  } catch (e) {
    console.info(e);
  }
}

export type FetchListingsSagaAction =
  | FetchListingsAction
  | SearchListingsAction
  | HandleMapChangeAction
  | HandleClusterClickAction
  | FetchViewedListingsAction
  | FiltersAction
  | AddMapDrawingPolygon
  | SortListingsAction
  | ChangeListingsPageAction
  | ResetAllAction
  | FetchCollectionAction;

const fetchListingsSub = () =>
  takeLatest(
    [
      CollectionsActionType.FETCH_COLLECTION,
      ListingsActionType.FETCH_LISTINGS,
      ListingsActionType.FETCH_VIEWED_LISTINGS,
      ListingsActionType.INIT_LISTINGS,
    ],
    fetchListingsSagaWrapper
  );

const fetchListingsAndUpdateUrl = () =>
  takeLatest(
    [
      // Listings
      ListingsActionType.SEARCH_LISTINGS,
      ListingsActionType.SORT_LISTINGS,
      ListingsActionType.CHANGE_OUR_LISTINGS_FIRST,

      // Search tags
      search.addTag.type,
      search.removeTag.type,
      search.removeAllTags.type,

      // Map
      MapActionType.HANDLE_CLUSTER_CLICK,
      MapActionType.HANDLE_MAP_CHANGE,
      MapActionType.ADD_MAP_DRAWING_POLYGON,

      // Filters
      FiltersActionType.RESET_PRICE_FILTERS,
      FiltersActionType.RESET_BEDS_FILTERS,
      FiltersActionType.RESET_BATHS_FILTERS,
      FiltersActionType.RESET_ALL_FILTERS,
      FiltersActionType.RESET_SECONDARY_FILTERS,
      FiltersActionType.APPLY_FILTERS,
      FiltersActionType.SET_FILTER_RANGE_VALUE,

      RESET_ALL,
    ],
    function* (action) {
      const isCollection = isCollectionPage();

      if (isCollection) {
        const skip = [
          ListingsActionType.SORT_LISTINGS,
          ListingsActionType.CHANGE_OUR_LISTINGS_FIRST,
        ];
        if (skip.includes(action.type)) {
          return void 0;
        }
      }

      yield* fetchListingsSagaWrapper(action);
      if (not(isCollection)) {
        yield* updateListingsUrlSaga(action);
      }
    }
  );

function isCollectionPage(): boolean {
  const path = history.location.pathname;
  const collection = matchPath(path, RoutePath.COLLECTION);
  const agentCollection = matchPath(path, RoutePath.AGENT_COLLECTION);
  return Boolean(collection || agentCollection);
}

const fetchListingsWithoutMapSub = () => {
  function* handler(action: ChangeListingsPageAction) {
    const isCollection = isCollectionPage();
    if (not(isCollection)) {
      yield call(fetchListingsWithoutMapSaga, action);
    }
  }

  return takeEvery([ListingsActionType.CHANGE_LISTINGS_PAGE], handler);
};

const fetchClustersSub = () =>
  takeEvery([MapActionType.FETCH_CLUSTERS], function* (action) {
    yield* fetchClustersSaga(action as HandleFetchClusters);
  });

const updateListingsUrlSub = () =>
  takeEvery(
    [
      ListingsActionType.CHANGE_LISTINGS_PAGE,
      ListingsActionType.UPDATE_LISTINGS_URL,
    ],
    updateListingsUrlSaga
  );

const setListingsFromUrlSub = () =>
  takeEvery([ListingsActionType.SET_LISTINGS_FROM_URL], setListingsFromUrlSaga);

const setClickedMarkerListingIdSub = () =>
  takeEvery(
    [ListingsActionType.SET_CLICKED_MARKER_ID_AND_CHANGE_PAGE],
    setClickedMarkerIdAndChangePageSaga
  );

const trackAreaOfInterestSub = () =>
  takeEvery(
    [ListingsActionType.TRACK_AREA_OF_INTEREST],
    trackAreaOfInterestSaga
  );

const sendFiltersToGASub = () =>
  takeEvery(
    [FiltersActionType.APPLY_FILTERS, FiltersActionType.SET_FILTER_RANGE_VALUE],
    sendFiltersToGASaga
  );

export function* listingsSagas() {
  yield all([
    fetchListingsSub(),
    fetchListingsWithoutMapSub(),
    fetchListingsAndUpdateUrl(),
    setListingsFromUrlSub(),
    setClickedMarkerListingIdSub(),
    updateListingsUrlSub(),
    fetchClustersSub(),
    trackAreaOfInterestSub(),
    sendFiltersToGASub(),
  ]);
}
