import { browserStorageItem } from 'shared/constants/appConstants';

interface UserSession {
  sessionId?: string;
}

const getUserSession = (): UserSession => {
  try {
    const json = JSON.parse(
      localStorage.getItem(browserStorageItem.userSession)
    );
    if (typeof json === 'object' && json !== null) {
      return json;
    }
    return {};
  } catch {
    return {};
  }
};

export const setUserSession = (session: UserSession) => {
  localStorage.setItem(browserStorageItem.userSession, JSON.stringify(session));
};

export const clearUserSession = () => {
  localStorage.removeItem(browserStorageItem.userSession);
};

export const setSessionId = (id: string) => {
  const session = getUserSession();
  setUserSession({ ...session, sessionId: id });
};

export const getSessionId = () => {
  const session = getUserSession();
  return session.sessionId;
};
