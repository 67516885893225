export enum strapiComponent {
  actionBlock = 'action_block',
  blogFeed = 'blog_feed',
  bragBar = 'brag_bar',
  carouselBlock = 'carousel_block',
  consumerInfo = 'consumer_info',
  contactUsForm = 'contact_us_form',
  freeWhitepaper = 'free_whitepaper',
  heading = 'heading',
  homeHero = 'home_hero',
  homeVideoSection = 'home_video_section',
  homewardBenefits = 'homeward_benefits',
  homewardCost = 'homeward_cost',
  homewardFooter = 'homeward_footer',
  homewardHero = 'homeward_hero',
  howToSellYourHome = 'how_to_sell_your_home',
  infoBlock = 'info_block',
  linkBlock = 'link_block',
  officeBlock = 'office_block',
  sellHero = 'sell_hero',
  stepper = 'stepper',
  stepsToSuccess = 'steps_to_success',
  testimonialsSection = 'testimonials_section',
  testimonialsVideoCarousel = 'testimonials_video_carousel',
  textBlock = 'text_block',
  twoColumnsWysiwyg = 'blog-post-components.two-columns-wysiwyg',
  videoBlock = 'video_block',
  wysiwyg = 'blog-post-components.wysiwyg',
  imagesSlider = 'blog-post-components.images-slider',
}

export enum PostType {
  post = 'post',
  video = 'video',
}

export enum StrapiEndpoints {
  blogPage = 'blog-pages',
  blogPost = 'blog-post',
  blogPosts = 'blog-posts',
  blogPostCategory = 'blog-post-category',
  blogPostCategories = 'blog-post-categories',
  categoriesMenu = 'categories-menus',
  testimonialVideoSliderPage = 'testimonial-video-slider-pages',
  testimonialVideoPage = 'testimonial-video-page',
  testimonialVideoPages = 'testimonial-video-pages',
  communityPage = 'community-page',
  footer = 'footer',
  agentsDirectories = 'agents-directories',
}

export enum BlogSortingValues {
  publishedDate = 'published_date',
  title = 'title',
}

export const POSTS_PER_BLOG_PAGE = 9;
export const POSTS_PER_CATEGORY_PAGE = 20;
export const BUTTER_CMS_TIMEOUT = 10000;
