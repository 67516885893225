import { useField } from 'formik';

import { Input } from 'shared/components/Input';
import { InputProps } from 'shared/components/Input/Input';
import { not } from 'shared/helpers/boolean';

interface InputFieldProps extends InputProps {
  ariaLabel?: string;
  dataTestId?: string;
  parentWidth?: boolean;
  formatter?: (value: string) => string;
  emptyValue?: string;
  validator?: (value: string) => boolean;
}

export const InputField = ({
  ariaLabel,
  dataTestId,
  emptyValue,
  formatter,
  validator,
  ...restProps
}: InputFieldProps) => {
  const [field, meta, helpers] = useField({ name: restProps.name });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = formatter
      ? formatter(event.target.value)
      : event.target.value;

    if (not(validator) || validator(value)) {
      helpers.setValue(value);
      helpers.setError(undefined);
    }
  };

  const handleClear = () => {
    helpers.setValue('');
  };

  return (
    <Input
      {...field}
      value={field.value ?? emptyValue}
      error={Boolean(meta.error)}
      message={meta.error}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      onChange={handleChange}
      onClear={handleClear}
      {...restProps}
    />
  );
};
