import styled from 'styled-components/macro';

import { scss } from 'styled-system/css';
import { createShouldForwardProp } from 'styled-system/lib/should-forward-prop';
import { space, SpaceProps } from 'styled-system/lib/space';

export type SpacerProps = SpaceProps;

export const spacerPropNames = space.propNames;

export const spacerConfig = {
  shouldForwardProp: createShouldForwardProp(spacerPropNames),
};

export const Spacer = styled.div.withConfig<SpacerProps>(spacerConfig)(
  scss({ m: 0 }),
  space
);

Spacer.displayName = 'Spacer';
