import { connect } from 'react-redux';

import { RootState } from 'rootReducer';
import ProfileButton from 'shared/components/ProfileButton/ProfileButton';
import { ConnectProps } from 'shared/types';

const mapStateToProps = (state: RootState) => ({
  SocialPhotoURL: state.user.profile.data.userSocialPhoto_URL,
});

export type AccountSettingsConnectProps = ConnectProps<typeof mapStateToProps>;

export default connect(mapStateToProps)(ProfileButton);
