import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

export const useIsScrolled = (props: {
  fixed: boolean;
  scrollableNode?: HTMLDivElement | null;
}) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const scrollableNode = props.scrollableNode || document;

    if (props.fixed) {
      const handleScroll = throttle(() => {
        const scrollToTop = props.scrollableNode?.scrollTop ?? window.scrollY;
        setIsScrolled(scrollToTop > 0);
      }, 200);

      scrollableNode.addEventListener('scroll', handleScroll);
      return () => scrollableNode.removeEventListener('scroll', handleScroll);
    }
  }, [props.fixed, props.scrollableNode]);

  return isScrolled;
};
