import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToAnchorProps {
  children: JSX.Element | JSX.Element[];
}

const ScrollToAnchor = ({ children }: ScrollToAnchorProps) => {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hash === '') return;
    const id = window.location.hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) element.scrollIntoView();
  }, [location.hash]);

  return <>{children}</>;
};

export default ScrollToAnchor;
