import React, { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import config from 'config';
import { Contact } from 'contacts/contactsTypes';
import { getHasPrivateListings } from 'listings/listingsSelectors';
import { SavedSearchSettingsModalProps } from 'saved-search/savedSearchTypes';
import Button from 'shared/components/Button/Button';
import ContextMenu from 'shared/components/Header/components/ContextMenu';
import Filters from 'shared/components/Header/components/DesktopHeader/Filters';
import { AuthorizationButtons } from 'shared/components/Header/components/MobileHeader/MobileHeaderStyled';
import SavedSearchCtaButtons from 'shared/components/Header/components/SavedSearchCtaButtons';
import { HeaderSharedProps } from 'shared/components/Header/Header';
import {
  DesktopNavigation,
  FiltersWithSearchBarRow,
  Item,
  ItemProfile,
  LoginButton,
  Logo,
  LogoAndUpdateBox,
  LogoItem,
  Nav,
  RealSearchBar,
  ScheduleButton,
  SearchBarWrapper,
  WrapperDesktop,
} from 'shared/components/Header/HeaderStyled';
import { useModalContext } from 'shared/components/Modal/modalContext';
import ProfileButton from 'shared/components/ProfileButton';
import { DROPDOWN_MAX_HEIGHT_BUY_PAGE } from 'shared/components/SearchBar/SearchBarStyled';
import SearchBarWithTags from 'shared/components/SearchBarWithTags';
import {
  ButtonsCode,
  LISTINGS_SEARCH_BAR_PLACEHOLDER,
  SAVED_SEARCH_SETTINGS_MODAL_NAME,
} from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { not, notEmpty } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';
import { useIsScrolled } from 'shared/hooks/useIsScrolled';
import { loadable } from 'shared/utils/loadable';
import { AuthType, showAuthModal } from 'user/helpers/showAuthModal';
import { UserLocation } from 'user/userTypes';

const SavedSearchSettingsModal = loadable(
  () =>
    import(
      /* webpackChunkName: "saved-search-settings-modal" */
      'saved-search/SavedSearchSettingsModal'
    )
);

export const LOGO_ITEM_ID = 'logoItem';

type DesktopHeaderProps = HeaderSharedProps & {
  bathroomButtonTitle: string;
  bedroomButtonTitle: string;
  priceButtonTitle: string;
  customFiltersBar: JSX.Element;
  stickySearch: boolean;
  shouldDisableSavedSearch: boolean;
  initialSearchChanged: boolean;
  openSavedSearchModal: () => void;
  userLocation: UserLocation;
  notificationsCount: string;
  isAgentMode: boolean;
  isAnimatedLogo: boolean;
  contactItem: Contact;
  subHeader: JSX.Element;
};

const DesktopHeader = (props: DesktopHeaderProps) => {
  const {
    mapType,
    appliedMoreFiltersCount,
    customFiltersBar,
    customSearchBar,
    fixed,
    handleOpenContextMenu,
    handleCloseContextMenu,
    hideAllBars,
    initialSearchChanged,
    isAuthorised,
    isOwner,
    onResetClick,
    onResetSavedSearchClick,
    openSavedSearchModal,
    savedSearch,
    savedSearchIsLoaded,
    savedSearchMode,
    saveSearchAsNewRequest,
    shouldDisableSavedSearch,
    showFiltersBar,
    showSearchBar,
    stickySearch,
    updateSearchRequest,
    user,
    user: {
      profile: { data: profileData },
    },
    isViewOnlyMode,
    disableSearch,
    showContextMenu,
    notificationsCount,
    isAgentMode,
    showBg,
    isAnimatedLogo,
    savingPolygon,
    contactItem,
    subHeader,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const isCareersPage = Boolean(useRouteMatch(RoutePath.CAREERS));

  const scrolled = useIsScrolled({ fixed });
  const modalContext = useModalContext();
  const isLeasePage = useIsLeasePage();
  const location = useLocation();

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === ButtonsCode.Esc) {
      handleCloseContextMenu(event);
    }
    if (event.key === ButtonsCode.Enter) {
      showContextMenu
        ? handleCloseContextMenu(event)
        : handleOpenContextMenu(event);
    }
  };

  return (
    <>
      <DesktopNavigation fixed={fixed} scrolled={scrolled} showBg={showBg}>
        <LogoAndUpdateBox ref={ref}>
          <LogoItem
            to={RoutePath.HOME}
            id={LOGO_ITEM_ID}
            aria-label={`${config.productFullName} Home`}
          >
            <Logo scrolled={scrolled} isAnimated={isAnimatedLogo} />
          </LogoItem>
        </LogoAndUpdateBox>
        <Nav>
          {not(isAgentMode) && (
            <>
              <Item
                to={RoutePath.LISTINGS}
                isActive={match => match && not(isLeasePage)}
              >
                Buy
              </Item>
              <Item to={RoutePath.LEASE} rel="nofollow">
                Lease
              </Item>
              <Item to={RoutePath.SELL}>Sell</Item>
              <Item to={RoutePath.AGENTS}>Agents</Item>
              <Item to={RoutePath.COMPANY}>Company</Item>
              <Item to={RoutePath.CAREERS}>Careers</Item>
              <Item to={RoutePath.BLOG}>Blog</Item>
            </>
          )}
          {isAgentMode && (
            <>
              {contactItem.currentAgentOwnsThisContact && contactItem.username && (
                <>
                  <Item
                    to={generatePath(RoutePath.AGENT_LISTINGS, {
                      contactId: contactItem.username,
                    })}
                    isActive={match => match && not(isLeasePage)}
                  >
                    Buy
                  </Item>
                  {contactItem.isLeasedSearchAllowed && (
                    <Item
                      to={generatePath(RoutePath.AGENT_LISTINGS_LEASE, {
                        contactId: contactItem.username,
                      })}
                      rel="nofollow"
                    >
                      Lease
                    </Item>
                  )}
                </>
              )}
            </>
          )}
          {not(isAuthorised) && user.initialized && (
            <AuthorizationButtons>
              {isCareersPage ? (
                <ScheduleButton primary onClick={props.onScheduleACallClick}>
                  Schedule a call
                </ScheduleButton>
              ) : (
                <>
                  <LoginButton
                    showBg={props.showBg}
                    fixed={fixed}
                    onClick={showAuthModal(
                      user.isLogged ? AuthType.SignUp : AuthType.LogIn,
                      modalContext
                    )}
                    scrolled={scrolled}
                    secondary
                  >
                    Log in
                  </LoginButton>
                  <Button
                    primary
                    onClick={showAuthModal(AuthType.SignUp, modalContext)}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </AuthorizationButtons>
          )}
          {isAuthorised && user.initialized && (
            <ItemProfile
              role="button"
              aria-label={'Open profile menu'}
              onKeyUp={handleKeyPress}
              onClick={handleOpenContextMenu}
              tabIndex={0}
            >
              <ProfileButton
                profile={isAgentMode ? contactItem : profileData}
                notificationsCount={notificationsCount}
                showNotificationsCount={not(isAgentMode)}
              />
            </ItemProfile>
          )}
        </Nav>
      </DesktopNavigation>
      <ContextMenu
        closeMenu={handleCloseContextMenu}
        isVisible={showContextMenu}
        fixed={fixed}
        handleKeyPress={handleKeyPress}
      />
      {subHeader}
      {!hideAllBars && (
        <FiltersWithSearchBarRow stickySearch={stickySearch}>
          <React.Fragment>
            {showSearchBar &&
              (customSearchBar ? (
                <SearchBarWrapper showFiltersBar={showFiltersBar}>
                  {customSearchBar}
                </SearchBarWrapper>
              ) : (
                <SearchBarWithTags
                  mapType={mapType}
                  placeholder={LISTINGS_SEARCH_BAR_PLACEHOLDER}
                  dropdownMenuHeight={DROPDOWN_MAX_HEIGHT_BUY_PAGE}
                  disabled={disableSearch || isViewOnlyMode}
                />
              ))}

            {showFiltersBar && (
              <RealSearchBar>
                <WrapperDesktop>
                  {customFiltersBar ? (
                    customFiltersBar
                  ) : (
                    <FiltersSection
                      isAgentMode={isAgentMode}
                      isViewOnlyMode={props.isViewOnlyMode}
                      appliedMoreFiltersCount={appliedMoreFiltersCount}
                      savedSearchMode={savedSearchMode}
                      savingPolygon={savingPolygon}
                      shouldDisableSavedSearch={shouldDisableSavedSearch}
                      isOwner={isOwner}
                      onResetSavedSearchClick={onResetSavedSearchClick}
                      saveSearchAsNewRequest={saveSearchAsNewRequest}
                      updateSearchRequest={updateSearchRequest}
                      onResetClick={onResetClick}
                      openSavedSearchModal={openSavedSearchModal}
                      initialSearchChanged={initialSearchChanged}
                      savedSearchIsLoaded={savedSearchIsLoaded}
                      savedSearch={savedSearch}
                    />
                  )}
                </WrapperDesktop>
              </RealSearchBar>
            )}
          </React.Fragment>
        </FiltersWithSearchBarRow>
      )}
    </>
  );
};

export default DesktopHeader;

const FiltersSection = (props: Partial<DesktopHeaderProps>) => {
  const { showModal } = useModalContext();
  const history = useHistory();
  const shouldDisableCollaboratorInvite = useSelector(state =>
    getHasPrivateListings(state, history)
  );
  const saveSearchDisabled =
    props.isViewOnlyMode ||
    not(props.initialSearchChanged) ||
    not(props.savedSearchIsLoaded);

  const hasCollaborators = notEmpty(props.savedSearch.collaborators);

  return (
    <>
      <Filters />

      <SavedSearchCtaButtons
        agentMode={props.isAgentMode}
        isDisabledResetSavedSearch={
          props.shouldDisableSavedSearch || props.isViewOnlyMode
        }
        isDisabledSavedSearch={saveSearchDisabled}
        isOwner={props.isOwner}
        isViewOnlyMode={props.isViewOnlyMode}
        onResetSavedSearch={props.onResetClick}
        onResetSavedSearchChanges={props.onResetSavedSearchClick}
        onSaveAsNewSavedSearch={showModal<SavedSearchSettingsModalProps>(
          SavedSearchSettingsModal,
          {
            isOpen: true,
            label: SAVED_SEARCH_SETTINGS_MODAL_NAME,
            onSave: props.saveSearchAsNewRequest,
            name: props.savedSearch.name,
            shouldDisableCollaboratorInvite,
          }
        )}
        onSaveSavedSearch={props.openSavedSearchModal}
        onUpdateSavedSearch={showModal<SavedSearchSettingsModalProps>(
          SavedSearchSettingsModal,
          {
            isOpen: true,
            label: SAVED_SEARCH_SETTINGS_MODAL_NAME,
            onSave: props.updateSearchRequest,
            id: props.savedSearch.id,
            isUpdate: true,
            shouldDisableCollaboratorInvite,
          }
        )}
        savedSearchMode={props.savedSearchMode}
        savingPolygon={props.savingPolygon}
        hasCollaborators={hasCollaborators}
        savedSearchId={props.savedSearch.id}
      />
    </>
  );
};
