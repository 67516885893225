export enum TypeOfViews {
  Greenbelt = 'Greenbelt',
  Panoramic = 'Panoramic',
  HillCountry = 'Hills',
  GolfCourse = 'Golf Course',
  LakeOrRiver = 'Lake/River',
  City = 'City',
}

export interface SharedFiltersProps {
  disableFilter?: boolean;
  innerMode?: boolean;
}

export type PriceFilters = {
  minPrice: number;
  maxPrice: number;
};

export type BedsFilters = {
  minBedrooms: number;
  maxBedrooms: number;
};

export type BathsFilters = {
  minBathrooms: number;
};

export interface CommunityFeaturesFilters {
  homeownersAssoc: 'Any' | 'Yes' | 'No';
  gatedCommunity: boolean;
  communityPool: boolean;
  playground: boolean;
  dogPark: boolean;
  fitnessCenter: boolean;
  golfCourse: boolean;
  sportCourt: boolean;
  tennisCourts: boolean;
  horsesAllowed: boolean;
  airplaneFacilities: boolean;
  lakeAccess: boolean;
  hikingTrails: boolean;
  clubHouse: boolean;
  boatDock: boolean;
  park: boolean;
}

export interface HomeFeaturesFilters {
  minYearBuilt: number;
  maxYearBuilt: number;
  masterOnMain: boolean;
  guestQuarters: boolean;
  fireplace: boolean;
  accessibilityFeatures: boolean;
  historicDesign: boolean;
  energyEfficient: boolean;
  exerciseRoom: boolean;
  gameRoom: boolean;
  mediaRoom: boolean;
  officeStudy: boolean;
  wineRoom: boolean;
  workshop: boolean;
  petsAllowed: 'Any' | 'Yes' | 'No';
}

export interface OpenHouseFilters {
  openHouseOnly: boolean;
  ourListingsOnly: boolean;
  openHouse: number;
  noPhoto: boolean;
}

export interface MlsStatusFilter {
  mlsStatus: string[];
}

export interface PropertyFeaturesFilters {
  backsToGolfCourse: boolean;
  backsToGreenbelt: boolean;
  swimmingPool: '' | 'true' | 'false';
  fencedYard: boolean;
  privateDock: boolean;
  views: string[];
  waterfront: boolean;
  waterBodyName: string[];
}

export interface PropertyTypeFilters {
  singleFamilyResidence: boolean;
  condominium: boolean;
  townhouse: boolean;
  lot: boolean;
  farm: boolean;
  multiFamily: boolean;
  commercialSale: boolean;
}

export interface SizeFilters {
  minLotSize: number;
  maxLotSize: number;
  minSquareFeet: number;
  maxSquareFeet: number;
  minStories: number;
  maxStories: number;
  minGarageSpaces: number;
  maxGarageSpaces: number;
}

export interface TimeFrameFilters {
  daysOnMarket: number;
  lastPriceReduction: number;
  soldTimeframe: number;
  leasedTimeframe: number;
}

export interface PeriodFilters {
  period: number;
  periodStart: string;
  periodEnd: string;
}

export interface KeywordsFilters {
  keyword: string;
}

export interface GreatSchoolRatingFilters {
  elementarySchoolRating: number;
  middleSchoolRating: number;
  highSchoolRating: number;
}

export interface SourceFilters {
  exclusiveListingsEnabled: boolean;
  mlsListingsEnabled: boolean;
}

export type FiltersState = PriceFilters &
  BedsFilters &
  BathsFilters &
  MlsStatusFilter &
  CommunityFeaturesFilters &
  HomeFeaturesFilters &
  OpenHouseFilters &
  PropertyFeaturesFilters &
  PropertyTypeFilters &
  SizeFilters &
  TimeFrameFilters &
  PeriodFilters &
  GreatSchoolRatingFilters &
  KeywordsFilters &
  SourceFilters;

export type SecondaryFilters = Omit<
  FiltersState,
  | keyof PriceFilters
  | keyof BedsFilters
  | keyof BathsFilters
  | keyof PeriodFilters
>;

export interface RadioButtonSettings {
  title: string;
  value: string;
}

type PrimaryFiltersForm = BathsFilters & BedsFilters & PriceFilters;

export type SecondaryFiltersForm = {
  communityFeaturesFilters: CommunityFeaturesFilters;
  greatSchoolRatingFilters: GreatSchoolRatingFilters;
  homeFeaturesFilters: HomeFeaturesFilters;
  keyword: string;
  mlsStatus: string[];
  propertyFeaturesFilters: PropertyFeaturesFilters;
  propertyTypeFilters: PropertyTypeFilters;
  showOnlyFilters: OpenHouseFilters;
  sizeFilters: SizeFilters;
  sourceFilters: SourceFilters;
  timeFrameFilters: TimeFrameFilters;
};

export type AllFiltersForm = PrimaryFiltersForm & SecondaryFiltersForm;
