import React, { useEffect } from 'react';

import { UserLocationConnectedProps } from 'shared/components/UserLocation/index';

type UserLocationProps = UserLocationConnectedProps;

const UserLocation: React.FC<UserLocationProps> = props => {
  useEffect(() => {
    if (!props.userLocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          props.setUserLocation({
            latitude,
            longitude,
          });
        },
        e => {
          console.warn('Error in location receiving', e.message);
        },
        { maximumAge: 10000 }
      );
    }
  }, [props.userLocation]);

  return <>{props.children}</>;
};

export default UserLocation;
