import styled, { css } from 'styled-components/macro';

import { $getKeywordsFilterChanged } from 'filters/filtersSelectors';
import {
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
} from 'filters/filtersStyled';
import { InputField } from 'shared/components/formikFields/InputField';
import { useSelector } from 'shared/helpers/redux';
import { media } from 'styled-system/responsive';

const Container = styled.div`
  margin-bottom: 32px;
  ${media.sm(css`
    margin-bottom: 0;
  `)}
`;

const BottomText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
`;

interface KeywordsFilterProps {
  disableFilter?: boolean;
}

const KeywordsFilter = (props: KeywordsFilterProps) => {
  const keywordsChanged = useSelector($getKeywordsFilterChanged);

  return (
    <Container>
      <FilterHeaderContainer>
        {keywordsChanged && (
          <FilterSectionChangedIndicator data-testid="change-indicator" />
        )}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Keywords
        </FilterHeaderTitle>
      </FilterHeaderContainer>
      <InputField
        name="keyword"
        ariaLabel="type keywords, separate multiple keywords with a comma"
        dataTestId="keyword-input"
        searchable
        clearable
        parentWidth
        disabled={props.disableFilter}
        emptyValue=""
        placeholder="ie. Office, Guest House, Historic…"
      />
      <BottomText>Separate multiple keywords with a comma</BottomText>
    </Container>
  );
};

export default KeywordsFilter;
