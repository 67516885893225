import { Form, Formik, useField, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import FiltersFooter from 'filters/components/FiltersFooter';
import { applyFilters, resetBedsFilters } from 'filters/filtersActions';
import {
  maxBedsOptions,
  minBedsOptions,
  SELECT_MAX,
  SELECT_MIN,
} from 'filters/filtersContants';
import {
  filterMinMax,
  getBedroomsTitle,
  validateMinMax,
  validateSelectInput,
} from 'filters/filtersHelpers';
import { $getBedroomChanged } from 'filters/filtersSelectors';
import {
  FilterHeaderContainer,
  FilterHeaderSubtitle,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
} from 'filters/filtersStyled';
import {
  BedsFilters,
  PriceFilters,
  SharedFiltersProps,
} from 'filters/filtersTypes';
import { InputSelectField } from 'shared/components/formikFields/InputSelectField';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useEffectOnUpdate } from 'shared/hooks/use-effect-on-update';
import { media, useIsMobile } from 'styled-system/responsive';

interface BedsFilterProps extends SharedFiltersProps {
  initialValues: BedsFilters;
  onSubmit: () => void;
}

export default function BedsFilter(props: BedsFilterProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
    >
      <Form>
        <BedsFilterFC disableFilter={props.disableFilter} />
        <SyncFormikWithRedux />
      </Form>
    </Formik>
  );
}

function SyncFormikWithRedux(): null {
  const dispatch = useDispatch();
  const { initialValues, values } = useFormikContext<PriceFilters>();

  useEffectOnUpdate(() => {
    if (values !== initialValues) {
      dispatch(applyFilters(values));
    }
  }, [values]);

  return null;
}

export const BedsFilterFC = (props: SharedFiltersProps) => {
  const isMobile = useIsMobile();

  const dispatch = useDispatch();
  const bedroomsChanged = useSelector($getBedroomChanged);

  const [{ value: minBedrooms }] = useField<number>({ name: 'minBedrooms' });
  const [{ value: maxBedrooms }] = useField<number>({ name: 'maxBedrooms' });
  const bedroomsTitle = getBedroomsTitle({ minBedrooms, maxBedrooms });

  const isInputValid = (value: string) =>
    validateSelectInput(value, minBedsOptions);

  const minValidator = (value: string) =>
    validateMinMax({
      value,
      compareValue: maxBedrooms,
      validator: isInputValid,
      isMin: true,
    });

  const maxValidator = (value: string) =>
    validateMinMax({
      value,
      compareValue: minBedrooms,
      validator: isInputValid,
    });

  return (
    <Container innerMode={props.innerMode}>
      <ItemContainer>
        <FilterHeaderContainer>
          {isMobile && bedroomsChanged && <FilterSectionChangedIndicator />}
          <FilterHeaderTitle>Beds</FilterHeaderTitle>
          <FilterHeaderSubtitle>{bedroomsTitle}</FilterHeaderSubtitle>
        </FilterHeaderContainer>
        <SelectContainer>
          <SelectWrapper>
            <InputSelectField
              name="minBedrooms"
              ariaLabel={`${SELECT_MIN} bedrooms`}
              isDisabled={props.disableFilter}
              validator={minValidator}
              rawValue={minBedrooms}
              options={minBedsOptions.filter(({ value }) =>
                filterMinMax({
                  value,
                  min: minBedrooms,
                  max: maxBedrooms,
                  isMin: true,
                })
              )}
            />
          </SelectWrapper>
          <ToContainer>to</ToContainer>
          <SelectWrapper>
            <InputSelectField
              name="maxBedrooms"
              ariaLabel={`${SELECT_MAX} bedrooms`}
              isDisabled={props.disableFilter}
              validator={maxValidator}
              rawValue={maxBedrooms}
              options={maxBedsOptions.filter(({ value }) =>
                filterMinMax({ value, min: minBedrooms, max: maxBedrooms })
              )}
            />
          </SelectWrapper>
        </SelectContainer>
      </ItemContainer>

      {not(props.innerMode) && (
        <FiltersFooter
          canReset={bedroomsChanged && not(props.disableFilter)}
          onReset={() => dispatch(resetBedsFilters())}
        />
      )}
    </Container>
  );
};

const ToContainer = styled.div`
  color: ${({ theme }) => theme.colors.text2};
  font-size: 16px;
  margin: 8px 16px 0;
  ${media.sm(css`
    margin: 0;
  `)};
`;

const SelectWrapper = styled.div`
  width: 168px;
  padding-bottom: 22px;

  ${media.sm(css`
    width: 135px;
  `)};
`;

const Container = styled.div<{ innerMode?: boolean }>`
  width: 442px;
  max-width: 100vw;

  ${({ innerMode }) =>
    innerMode
      ? css`
          ${SelectWrapper} {
            padding-bottom: 0;
          }
        `
      : css`
          ${FilterHeaderContainer} {
            padding: 24px 0;
          }
        `}

  ${media.sm(css`
    width: 100%;
  `)};
`;

const ItemContainer = styled.div`
  padding: 0 24px;
  ${media.md(css`
    padding: 0;
  `)};
`;

const SelectContainer = styled.div`
  display: flex;
  ${media.sm(css`
    justify-content: space-between;
    align-items: center;
    max-width: 441px;
  `)}
`;
