export enum zIndex {
  HOTJAR = 2147483640,
  MODAL_OVERLAY = 99999999,
  MAP = 9999999,
  POPUP = 1000000,
  DROPDOWN_MENU = 99999,
  MAP_BUTTONS = 99999,
  TOOLTIP = 997,
  AGENT_TIP = MODAL_OVERLAY + 1,
  HEADER_AGENT_MODE = 9999,
  HEADER = 999,
  TOOLTIP_NOTIFICATION = 997,
  BACK_TO_TOP_BUTTON = 996,
  SELL_HERO_IMAGE = 10,
  STICKY_BUTTON = 9,
  SEARCH_BAR = 6,
  HERO_CAROUSEL_SLIDER = 1,
  SHOW_MAP_TOGGLE = 99999,
  DETAILS_PAGE_MOBILE_CTA_BTNS_WRAPPER = 1,
  CONTENT_HEADER_WRAPPER = HEADER - 2,
  EMAIL_SETTINGS_RESUBSCRIBE_BUTTON = HEADER - 1,
  TABLE_HEADER = 1,
  LISTING_LINK = 1,
}
