import { get } from 'lodash';

import { not } from 'shared/helpers/boolean';

type AnyObject = { [x: string]: any };

/**
 * Deep merge objects, e.g. styles.
 * We can't use *lodash.merge* because it also merges arrays.
 *
 * @example
 * merge(
 *   { a: { b: 42 }, d: 'one', e: [0] }
 *   { a: { c: 64 }, d: 'two', e: [1, 2] }
 * )
 * // { a: { b: 42, c: 64 }, d: 'two', e: [1, 2] }
 */
const merge = <T = AnyObject>(a: AnyObject, b: AnyObject): T => {
  const result = Object.assign({}, a, b);
  for (const key in a) {
    if (a[key] && typeof b[key] === 'object') {
      Object.assign(result, { [key]: Object.assign(a[key], b[key]) });
    }
  }
  return result as T;
};

/**
 * Sort object-value responsive styles
 */
const sort = (obj: AnyObject) => {
  const next: AnyObject = {};
  Object.keys(obj)
    .sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
    )
    .forEach(key => {
      next[key] = obj[key];
    });
  return next;
};

const createMediaQuery = (n: string) => `@media screen and (min-width: ${n})`;
const defaults = {
  mediaQueries: [40, 52, 64].map(n => n + 'em').map(createMediaQuery),
};
const getValue = (n: any, scale: any, _props: any) => get(scale, n, n);

type Parser = {
  (...args: any[]): any;
  config?: Record<string, unknown>;
  propNames?: string[];
  cache?: Record<string, unknown>;
  [key: string]: any;
};

type ParserConfig = Record<string, StyleFn>;

export const createParser = (config: ParserConfig): Parser => {
  const cache: any = {};

  const parse: Parser = (props: AnyObject) => {
    let shouldSort = false;
    let styles: AnyObject = {};
    const isCacheDisabled = props.theme?.disableStyledSystemCache === true;

    for (const key in props) {
      if (config[key]) {
        const sx = config[key];
        const prop = props[key];
        const scale = get(props.theme, sx.scale, sx.defaults);

        if (typeof prop === 'object') {
          const mediaQueries = (cache.mediaQueries =
            (not(isCacheDisabled) && cache.mediaQueries) ||
            get(props.theme, 'breakpoints', defaults.mediaQueries));

          const responsiveConfig = { mediaQueries, prop, props, scale, sx };

          if (Array.isArray(prop)) {
            styles = merge(styles, parseResponsiveStyle(responsiveConfig));
            continue;
          }

          if (prop !== null) {
            styles = merge(styles, parseResponsiveObject(responsiveConfig));
            shouldSort = true;
          }

          continue;
        }

        Object.assign(styles, sx(prop, scale, props));
      }
    }

    // sort object-based responsive styles - really don't know why
    if (shouldSort) {
      styles = sort(styles);
    }

    return styles;
  };

  parse.config = config;
  parse.propNames = Object.keys(config);
  parse.cache = cache;

  // also don't know why
  const keys = Object.keys(config).filter(k => k !== 'config');
  if (keys.length > 1) {
    keys.forEach(key => {
      parse[key] = createParser({ [key]: config[key] });
    });
  }

  return parse;
};

type ResponsiveParser = (config: {
  mediaQueries: any;
  prop: any;
  props: AnyObject;
  scale: any;
  sx: StyleFn;
}) => AnyObject;

/**
 * @example
 * parseResponsiveStyle({
 *   mediaQueries: ['mobile query', null],
 *   prop: [2, 4], // margin prop value
 *   props: {...},
 *   scale: [0, 8, 16, 24, 32, 40, 48, 56, 64], // e.g. theme.space
 *   sx: (...) => ..., // e.g. styled-system/margin
 * })
 * {
 *   // null media query styles will be just added as is
 *   margin: '32px'
 *   'mobile query': { margin: '16px' },
 * }
 */
const parseResponsiveStyle: ResponsiveParser = config => {
  const { mediaQueries, prop, props, scale, sx } = config;

  const styles: AnyObject = {};

  prop.slice(0, mediaQueries.length).forEach((value: any, idx: number) => {
    const media = mediaQueries[idx];
    const style = sx(value, scale, props);
    if (not(media)) {
      Object.assign(styles, style);
    } else {
      styles[media] = Object.assign({}, styles[media], style);
    }
  });

  return styles;
};

/**
 * @example
 * parseResponsiveStyle({
 *   mediaQueries: { sm: 'mobile query', md: null },
 *   prop: { sm: 2, md: 4 }, // margin prop value
 *   props: {...},
 *   scale: [0, 8, 16, 24, 32, 40, 48, 56, 64], // e.g. theme.space
 *   sx: (...) => ..., // e.g. styled-system/margin
 * })
 * {
 *   // null media query styles will be just added as is
 *   margin: '32px'
 *   'mobile query': { margin: '16px' },
 * }
 */
const parseResponsiveObject: ResponsiveParser = config => {
  const styles: AnyObject = {};
  for (const key in config.prop) {
    if (config.prop[key]) {
      const value = config.prop[key];
      const media = config.mediaQueries[key];
      const style = config.sx(value, config.scale, config.props);
      if (not(media)) {
        Object.assign(styles, style);
      } else {
        styles[media] = Object.assign({}, styles[media], style);
      }
    }
  }
  return styles;
};

type StyleFn = ((value: any, scale: any, _props: any) => AnyObject) & {
  defaults?: any;
  scale?: any;
};

/**
 * Creates a function that will handle styled system prop
 */
export const createStyleFunction = (config: PropConfig): StyleFn => {
  const properties = config.properties || [config.property];
  const transform = config.transform ?? getValue;

  const sx = (value: any, scale: any, _props: any) => {
    const finalValue = transform(value, scale, _props);
    if (finalValue !== null) {
      const result: AnyObject = {};
      properties.forEach(prop => {
        result[prop] = finalValue;
      });
      return result;
    }
    return void 0;
  };

  return Object.assign(sx, {
    defaults: config.defaultScale,
    scale: config.scale,
  });
};

export type PropConfig = {
  defaultScale?: any;
  properties?: string[];
  property?: string;
  scale?: string;
  transform?: (value: any, scale: any, props: any) => any;
};

export type SystemConfig = Record<string, true | PropConfig>;

/**
 * @example
 * system({
 *   color: {
 *     property: 'color', // CSS color property
 *     scale: 'colors', // theme.colors scale
 *   },
 *   size: {
 *     properties: ['width', 'height'], // multiple CSS properties
 *     ...
 *   },
 *   opacity: true // shorthand for CSS opacity property w/o transformations
 * })
 *
 */
export const system = (config: SystemConfig): Parser => {
  const finalConfig: AnyObject = {};

  Object.keys(config).forEach(key => {
    const propConfig = config[key];

    if (propConfig === true) {
      finalConfig[key] = createStyleFunction({ property: key, scale: key });
      return void 0;
    }

    if (typeof propConfig === 'function') {
      finalConfig[key] = propConfig;
      return void 0;
    }

    finalConfig[key] = createStyleFunction(propConfig);
  });

  return createParser(finalConfig);
};

/**
 * Creates a composition of parsers
 *
 * @example
 * import { color } from 'styled-system/color'
 * import { space } from 'styled-system/space'
 *
 * const system = compose(color, space)
 * // system handles color and space props
 */
export const compose = (...parsers: Parser[]): Parser => {
  const config: AnyObject = {};

  parsers.forEach(parser => {
    if (parser?.config) {
      Object.assign(config, parser.config);
    }
  });

  return createParser(config);
};
