import styled from 'styled-components/macro';

import { scss } from 'styled-system/css';
import { createShouldForwardProp } from 'styled-system/lib/should-forward-prop';
import { space, SpaceProps } from 'styled-system/lib/space';
import { sx, SxProps } from 'styled-system/lib/sx';

export type FlexProps = SpaceProps & SxProps;

const flexPropNames = [...space.propNames, 'sx'];

export const flexConfig = {
  shouldForwardProp: createShouldForwardProp(flexPropNames),
};

export const Flex = styled.div.withConfig<FlexProps>(flexConfig)(
  scss({ boxSizing: 'border-box', color: 'text1', display: 'flex', margin: 0 }),
  sx,
  space
);

Flex.displayName = 'Flex';
