import React from 'react';

import {
  ButtonContainer,
  ButtonTitle,
  IconContainer,
} from 'shared/components/ScrollToTopButton/ScrollToTopButtonStyled';
import { ReactComponent as IconArrowUp } from 'shared/icons/shared/arrows/small-arrow-up.svg';

export interface ScrollToTopButtonProps {
  isMobile?: boolean;
  onButtonClick?: () => void;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const ScrollToTopButton = ({
  isMobile,
  onButtonClick,
  handleKeyDown,
}: ScrollToTopButtonProps) => {
  return (
    <ButtonContainer
      isMobile={isMobile}
      onClick={onButtonClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button"
      aria-label={'Back to top of page'}
    >
      <IconContainer>
        <IconArrowUp fill="#fff" />
      </IconContainer>
      <ButtonTitle>
        Back
        <br />
        to Top
      </ButtonTitle>
    </ButtonContainer>
  );
};

export default ScrollToTopButton;
