import { variant } from '@styled-system/variant';
import styled from 'styled-components/macro';

import { scss } from 'styled-system/css';
import { createShouldForwardProp } from 'styled-system/lib/should-forward-prop';
import { space, SpaceProps } from 'styled-system/lib/space';
import { sx, SxProps } from 'styled-system/lib/sx';

const typographyVariants = variant({
  variants: {
    default: {},
    h2: {
      fontSize: ['30px', '36px'],
      fontWeight: 'heading',
      lineHeight: 'heading',
      textAlign: 'center',
    },
  },
});

const typographyPropNames = [...space.propNames, 'sx', 'variant'];

export const typographyConfig = {
  shouldForwardProp: createShouldForwardProp(typographyPropNames),
};

export type TypographyProps = SpaceProps & {
  variant?: 'default' | 'h2';
} & SxProps;

export const Typography = styled.span.withConfig<TypographyProps>(
  typographyConfig
)(
  scss({ boxSizing: 'border-box', color: 'inherit', margin: 0 }),
  typographyVariants,
  sx,
  space
);

Typography.displayName = 'Typography';
