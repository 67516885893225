import config from 'config';
import {
  School,
  SchoolsGroup,
  SchoolType,
} from 'listingDetails/listingDetailsTypes';
import { PolygonProperty } from 'map/mapTypes';
import { Tag } from 'search/search-types';
import { RoutePath } from 'shared/constants/routesConstants';
import { not, notEmpty } from 'shared/helpers/boolean';
import { isLeasePage } from 'shared/helpers/isLeasePage';
import { pick } from 'shared/helpers/object';
import { PlaceType } from 'shared/types/placesAndPolygons';

export const getOverviewHeading = (tag?: Tag): string => {
  const polygonName = getMarketOverviewPlaceName(tag);
  return `${polygonName} Real Estate Listings and Homes for Sale`;
};

export const getMarketOverviewPlaceName = (tag?: Tag): string => {
  if (not(tag)) return '';

  if (tag.type === PlaceType.County) {
    return `${tag.label?.replace(/,/, '')}`;
  }

  return tag.label ?? '';
};

export const getSchoolsHeading = (tag?: Tag): string => {
  const polygonName = getMarketOverviewPlaceName(tag);
  return `Schools in ${polygonName}`;
};

export const getDemographicsHeading = (tag?: Tag): string => {
  const polygonName = getMarketOverviewPlaceName(tag);
  return `${polygonName} Demographics`;
};

export const getHomePricesHeading = (tag: Tag): string => {
  const polygonName = getMarketOverviewPlaceName(tag);
  return `Home Prices in ${polygonName}`;
};

export const getPostalCodesHeading = (params: {
  isPlural: boolean;
  name: string;
  tag?: Tag;
  type: string;
}) => {
  const { isPlural, name, tag, type } = params;

  if (not(tag)) return '';

  const zipCodes = isPlural ? 'Zip Codes' : 'Zip Code';

  switch (type) {
    case PlaceType.City:
      return `${zipCodes} in ${tag.filter?.city} TX`;
    case PlaceType.County:
      return `${zipCodes} in ${tag.filter?.county} County, TX`;
    case PlaceType.Subdivision:
      return `${zipCodes} in ${tag.filter?.subdivision}`;
    case PlaceType.School:
      return `${tag.filter?.school} ${zipCodes}`;
    case PlaceType.SchoolDistrict:
      return `${tag.filter?.schooldistrict} ${zipCodes}`;
    case PlaceType.MlsArea:
      return `${zipCodes} in ${config.seoData.envName} MLS Area ${tag.filter?.mlsarea}`;
    default:
      return `${name} ${zipCodes}`;
  }
};

export const getNeighborhoodsHeading = (params: {
  name: string;
  tag?: Tag;
  type: string;
}) => {
  const { tag, type } = params;

  if (not(tag)) return '';

  switch (type) {
    case PlaceType.City:
      return `Popular Neighborhoods in ${tag.filter?.city} TX`;
    case PlaceType.County:
      return `Popular Neighborhoods in ${tag.filter?.county} County`;
    case PlaceType.Subdivision:
      return `Popular Neighborhoods near ${tag.filter?.subdivision}`;
    case PlaceType.PostalCode:
      return `Popular Neighborhoods in ${tag.filter?.postalcode}`;
    case PlaceType.School:
      return `Popular Neighborhoods near ${tag.filter?.school}`;
    case PlaceType.SchoolDistrict:
      return `Popular Neighborhoods in ${tag.filter?.schooldistrict}`;
    case PlaceType.MlsArea:
      return `Popular Neighborhoods in ${config.seoData.envName} MLS Area ${tag.filter?.mlsarea}`;
    default:
      return `Popular Neighborhoods in ${params.name}`;
  }
};

// FIXME rename to get from location/url
export const getPolygonUrlFromLink = (url?: string, path?: string): string => {
  if (not(url)) {
    return '';
  }
  const routePath = path
    ? path
    : isLeasePage(url)
    ? RoutePath.LEASE
    : RoutePath.LISTINGS;

  const tagUrl = url?.match(new RegExp(`${routePath}(.*)`))?.[1];
  return tagUrl || '';
};

export const getButterCmsUrlFromPolygonUrl = (url: string): string => {
  const pattern = /\//g;
  const butterCMSUrl = url && `listings${url.replace(pattern, '-')}`;
  return butterCMSUrl ? butterCMSUrl : '';
};

export const getStrapiCmsUrlFromPolygonUrl = (url: string): string => {
  const pattern = /\//g;
  const strapiCMSUrl = url && `listings${url.replace(pattern, '-')}`;
  return strapiCMSUrl ? strapiCMSUrl : '';
};

const isSchool = (properties: any): properties is School => {
  return 'level' in properties && typeof properties.level === 'string';
};

const schoolTypeFromLevel = {
  Primary: SchoolType.Elementary,
  Middle: SchoolType.Middle,
  High: SchoolType.High,
};

export const prepareSchoolsForMarketOverview = ({
  schools,
  properties,
  name,
  url,
}: {
  schools: SchoolsGroup;
  properties: PolygonProperty | School;
  name: string;
  url: string;
}): SchoolsGroup => {
  if (isSchool(properties)) {
    const level = properties.level as keyof typeof schoolTypeFromLevel;
    const schoolType = schoolTypeFromLevel[level];

    if (Boolean(schoolType)) {
      const schoolFromProperties = pick(properties, [
        'address',
        'admission',
        'charter',
        'gradeHigh',
        'gradeLow',
        'gsRating',
        'gsSchoolUrl',
        'label',
        'latitude',
        'level',
        'longitude',
        'name',
        'rating',
        'schoolDistrict',
        'url',
        'schoolType',
        'studentTeacherRatio',
      ]);

      if (notEmpty(schoolFromProperties)) {
        const school = {
          id: 'schoolFromProperties',
          name,
          ...schoolFromProperties,
          url: schoolFromProperties.url || url.replace(RoutePath.LISTINGS, ''),
        };
        return {
          ...schools,
          [schoolType]: [school, ...schools[schoolType]],
        };
      }
    }
  }

  return schools;
};
