import styled, { css } from 'styled-components/macro';

import Button from 'shared/components/Button/Button';
import {
  ReactComponent as ArrowDown,
  ReactComponent as IconDown,
} from 'shared/icons/shared/arrows/small-arrow-down.svg';
import { mediaBetween } from 'shared/styles/customMedia';
import { MOBILE_HEADER_HEIGHT } from 'shared/styles/globals';
import { zIndex } from 'shared/styles/z-indexes';
import { Themed } from 'shared/types';
import { media } from 'styled-system/responsive';

export const MobileHeaderContainer = styled.nav<{
  fixed: boolean;
  scrolled: boolean;
  showBg?: boolean;
}>`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: ${zIndex.HEADER};
    `}

  ${({ fixed, scrolled, showBg }) =>
    fixed &&
    !scrolled &&
    showBg &&
    css<any>`
      ${TopBar} {
        background-color: transparent;
        border-bottom: none;
        border-top: none;
      }
      ${LogoItemMobile} {
        border: none;
      }
      ${Down} {
        fill: ${({ theme }: Themed) => theme.white};
      }
      &:before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 68px;
        z-index: -1;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.2) 43%,
          rgba(0, 0, 0, 0)
        );
      }
    `}
    ${({ fixed, scrolled }) =>
    fixed &&
    scrolled &&
    css<any>`
      background-color: ${({ theme }: Themed) => theme.white};
    `}
`;

export const TopBar = styled.div<{ customSearchBar?: JSX.Element }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.divider};
  border-top: 1px solid ${({ theme }) => theme.divider};
  background-color: ${({ theme }) => theme.white};
  height: ${MOBILE_HEADER_HEIGHT}px;

  ${props => props.customSearchBar && `height: 100px;`};
`;

export const LogoItemMobile = styled.div`
  max-height: ${MOBILE_HEADER_HEIGHT}px;
  padding-left: 8px;
  border: none;
  border-right: 1px solid ${({ theme }) => theme.divider};
  background-color: inherit;
  line-height: ${MOBILE_HEADER_HEIGHT}px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 74px;
  z-index: 9;
  align-items: center;
`;

export const FiltersBar = styled.span`
  padding: 1em 8px;
  margin-left: auto;
  border-bottom: 1px solid ${({ theme }) => theme.divider};
  background-color: ${({ theme }) => theme.white};
  ${media.md`
    width:100%;
    height: 48px;
    padding: 8px;
  `};
`;

export const AllFiltersButton = styled(Button)``;

export const WrapperMobile = styled.div`
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  justify-content: space-evenly;

  button {
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;

    & > span {
      padding: 0;
    }

    &[frozen] {
      opacity: 0.5;
    }

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  ${mediaBetween(768, 1024)} {
    justify-content: space-between;
  }

  ${AllFiltersButton} {
    padding: 0 8px 0 12px;
  }
`;

export const AuthorizationButtons = styled.div`
  margin-left: 32px;
  margin-right: 24px;
  button {
    min-width: 72px;
    padding: 0;
    font-size: 12px;
  }
  button:first-child {
    margin-right: 8px;
  }

  button:last-child {
    margin-right: 0;
  }
`;

export const AuthorizationButtonsMobile = styled(AuthorizationButtons)<{
  parentWidth?: boolean;
}>`
  display: none;
  margin-right: 16px;
  ${media.md`
    display: flex;
    justify-content: flex-end;
    width: 100%;
  `};
`;

export const LoginButton = styled(Button)<{
  showBg: boolean;
  fixed: boolean;
  scrolled: boolean;
}>`
  ${({ fixed, scrolled, showBg }) =>
    fixed &&
    !scrolled &&
    showBg &&
    css<any>`
      background: transparent;
      border-color: ${({ theme }: Themed) => theme.white};
      color: ${({ theme }: Themed) => theme.white};

      &:hover {
        background: ${({ theme }: Themed) => theme.white};
        color: ${({ theme }: Themed) => theme.text2};
        border-color: ${({ theme }: Themed) => theme.white};
      }
    `}
`;

export const Down = styled(IconDown)`
  height: 16px;
  margin: 0 4px;
  position: relative;
  width: 16px;
`;

export const CategoriesArrowDown = styled(ArrowDown)({
  width: '16px',
  height: '16px',
  transition: 'transform 160ms',
  '[data-state=true] &': {
    transform: 'rotate(180deg)',
  },
});
