import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getHasPrivateListings } from 'listings/listingsSelectors';
import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import SavedSearchCtaButtonsStyled from 'shared/components/Header/components/SavedSearchCtaButtons/SavedSearchCtaButtonsStyled';
import { HeaderContext } from 'shared/components/Header/HeaderContext';
import { PopperPopup, usePopperPopup } from 'shared/components/PopperPopup';
import { PopupNotification } from 'shared/components/PopupNotification';
import { Tooltip } from 'shared/components/Tooltip';
import { Placement, Trigger } from 'shared/components/Tooltip/Tooltip';
import { browserStorageItem } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { not, notEmpty } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { zIndex } from 'shared/styles/z-indexes';
import { Box, Typography } from 'styled-system/components';
import { useIsMobile } from 'styled-system/responsive';

export enum CtaLabels {
  resetChanges = 'Reset Changes',
  resetSearch = 'Reset Search',
  saveNewSearch = 'Save as New',
  saveSearch = 'Save Search',
  updateSearch = 'Update Search',
  updateSearchMobile = 'Update',
}

const boxShadow = '0 2px 10px 0 rgba(0, 0, 0, 0.16)';

export interface SavedSearchCtaButtonsProps {
  isDisabledResetSavedSearch: boolean;
  isDisabledSavedSearch: boolean;
  isOwner: boolean;
  isViewOnlyMode: boolean;
  onResetSavedSearch: () => void;
  onResetSavedSearchChanges: () => void;
  onSaveAsNewSavedSearch: () => void;
  onSaveSavedSearch: () => void;
  onUpdateSavedSearch: () => void;
  savedSearchMode: boolean;
  savingPolygon: boolean;
  agentMode: boolean;
  hasCollaborators: boolean;
  savedSearchId: string;
}

enum SaveSearchPopupState {
  closed = 'closed',
  initial = 'initial',
  opened = 'opened',
}

const useSaveSearchPopupState = () => {
  const headerContext = useContext(HeaderContext);

  const [saveSearchPopupState, setSaveSearchPopupState] =
    useState<SaveSearchPopupState>(SaveSearchPopupState.initial);

  useEffect(() => {
    const { areAnyFiltersApplied, canShowSaveSearchPopup, tags } =
      headerContext;

    const showPopupCondition = areAnyFiltersApplied && notEmpty(tags);

    if (
      canShowSaveSearchPopup &&
      saveSearchPopupState === SaveSearchPopupState.initial &&
      showPopupCondition
    ) {
      setSaveSearchPopupState(SaveSearchPopupState.opened);
    }

    if (saveSearchPopupState === 'opened' && not(showPopupCondition)) {
      setSaveSearchPopupState(SaveSearchPopupState.initial);
    }
  }, [headerContext]);

  useEffect(() => {
    setSaveSearchPopupState(SaveSearchPopupState.initial);
  }, [headerContext?.isAuthorized]);

  const handleSaveSearchPopupSubmit = (body: { checked: boolean }) => {
    setSaveSearchPopupState(SaveSearchPopupState.closed);
    if (body.checked) {
      if (headerContext.isAuthorized) {
        headerContext.updateProfile(
          {
            data: { showPromptSaveSearch: false },
          },
          true
        );
      } else {
        localStorage.setItem(browserStorageItem.showPromptSaveSearch, 'false');
      }
    }
  };

  const handleSaveSearchPopupClose = () => {
    setSaveSearchPopupState(SaveSearchPopupState.closed);
  };

  return {
    handleSaveSearchPopupClose,
    handleSaveSearchPopupSubmit,
    saveSearchPopupState,
    setSaveSearchPopupState,
  };
};

const SavedSearchCtaButtons = (props: SavedSearchCtaButtonsProps) => {
  const {
    isDisabledResetSavedSearch,
    isDisabledSavedSearch,
    isOwner,
    isViewOnlyMode,
    onResetSavedSearch,
    onSaveAsNewSavedSearch,
    onSaveSavedSearch,
    onUpdateSavedSearch,
    savedSearchMode,
    savingPolygon,
  } = props;

  const popup = useSaveSearchPopupState();
  const popper = usePopperPopup({ offset: [0, 12], placement: 'bottom-end' });
  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();
  const history = useHistory();
  const hasPrivateListings = useSelector(state =>
    getHasPrivateListings(state, history)
  );

  const isMobile = useIsMobile();
  const updateButtonLabel = isMobile
    ? CtaLabels.updateSearchMobile
    : CtaLabels.updateSearch;

  const hasPrivateListingsAndCollaborators =
    props.hasCollaborators && hasPrivateListings;

  const isSaveSearchBtnDisabled = isViewOnlyMode || savingPolygon;

  const isSavedSearchModeBtnDisabled = isDisabledSavedSearch || savingPolygon;

  const isUpdateBtnDisabled =
    isDisabledSavedSearch ||
    savingPolygon ||
    hasPrivateListingsAndCollaborators;

  return savedSearchMode ? (
    <SavedSearchCtaButtonsStyled.Wrapper>
      <SavedSearchCtaButtonsStyled.ResetChangesButton
        data-testid={CtaLabels.resetChanges}
        disabled={isDisabledSavedSearch}
        onClick={onResetSavedSearch}
        secondary
        small
      >
        {CtaLabels.resetChanges}
      </SavedSearchCtaButtonsStyled.ResetChangesButton>

      <SavedSearchCtaButtonsStyled.SaveSearchBtnWrapper
        btnDisabled={isSavedSearchModeBtnDisabled}
      >
        <SavedSearchCtaButtonsStyled.SaveAsNewButton
          data-testid={CtaLabels.saveNewSearch}
          disabled={isSavedSearchModeBtnDisabled}
          onClick={onSaveAsNewSavedSearch}
          secondary
          small
        >
          {CtaLabels.saveNewSearch}
        </SavedSearchCtaButtonsStyled.SaveAsNewButton>
      </SavedSearchCtaButtonsStyled.SaveSearchBtnWrapper>

      {isOwner && (
        <Tooltip
          placement={isMobile ? Placement.BOTTOM_RIGHT : Placement.BOTTOM_RIGHT}
          overlay={
            <UpdateSearchBtnOverlay savedSearchId={props.savedSearchId} />
          }
          themeLight
          overlayStyle={{
            boxShadow: boxShadow,
            zIndex: zIndex.HEADER + 1,
          }}
          align={{ offset: isMobile ? [0, 8] : [-7, 0] }}
          trigger={[isMobile ? Trigger.CLICK : Trigger.HOVER]}
          disabled={not(hasPrivateListingsAndCollaborators)}
        >
          <SavedSearchCtaButtonsStyled.SaveSearchBtnWrapper
            btnDisabled={isUpdateBtnDisabled}
          >
            <SavedSearchCtaButtonsStyled.UpdateButton
              data-testid={updateButtonLabel}
              disabled={isUpdateBtnDisabled}
              onClick={onUpdateSavedSearch}
              primary
              small
            >
              {updateButtonLabel}
            </SavedSearchCtaButtonsStyled.UpdateButton>
          </SavedSearchCtaButtonsStyled.SaveSearchBtnWrapper>
        </Tooltip>
      )}
    </SavedSearchCtaButtonsStyled.Wrapper>
  ) : (
    <SavedSearchCtaButtonsStyled.Wrapper>
      <SavedSearchCtaButtonsStyled.ResetSearchButton
        data-testid={CtaLabels.resetSearch}
        disabled={isDisabledResetSavedSearch}
        onClick={() => {
          popup.setSaveSearchPopupState(SaveSearchPopupState.initial);
          onResetSavedSearch();
          ariaLiveAnnouncerCtx.announce('Search is reset');
        }}
        secondary
        small
      >
        {CtaLabels.resetSearch}
      </SavedSearchCtaButtonsStyled.ResetSearchButton>

      <SavedSearchCtaButtonsStyled.SaveSearchBtnWrapper
        btnDisabled={isSaveSearchBtnDisabled}
      >
        <SavedSearchCtaButtonsStyled.SaveSearchButton
          data-testid={CtaLabels.saveSearch}
          disabled={isSaveSearchBtnDisabled}
          onClick={() => {
            popup.setSaveSearchPopupState(SaveSearchPopupState.closed);
            onSaveSavedSearch();
          }}
          primary
          ref={popper.refs.setAnchor}
          small
        >
          {CtaLabels.saveSearch}
        </SavedSearchCtaButtonsStyled.SaveSearchButton>
      </SavedSearchCtaButtonsStyled.SaveSearchBtnWrapper>

      {popup.saveSearchPopupState === SaveSearchPopupState.opened && (
        <PopperPopup popper={popper}>
          <PopupNotification
            message="Save this search and be the first to know about new listings."
            onClose={() => {
              ariaLiveAnnouncerCtx.announce('Popup is closed');
              popup.handleSaveSearchPopupClose();
            }}
            onSubmit={popup.handleSaveSearchPopupSubmit}
          />
        </PopperPopup>
      )}
    </SavedSearchCtaButtonsStyled.Wrapper>
  );
};

export const UpdateSearchBtnOverlay = ({
  savedSearchId,
}: {
  savedSearchId: string;
}) => {
  return (
    <Box sx={{ width: 232, backgroundColor: 'white' }}>
      <Typography
        sx={{ fontSize: 14, lineHeight: 1.5, fontWeight: 400, color: 'text2' }}
      >
        Private exclusive listings are not permitted on saved searches with a{' '}
        <SavedSearchCtaButtonsStyled.CollaboratorsLink
          to={{
            pathname: RoutePath.SAVED_SEARCHES + RoutePath.ADD_MEMBERS,
            search: queryString.stringify({ id: savedSearchId }),
          }}
        >
          collaborator
        </SavedSearchCtaButtonsStyled.CollaboratorsLink>
        .
      </Typography>
    </Box>
  );
};

export default SavedSearchCtaButtons;
