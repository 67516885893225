import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getIsLeaseListing } from 'listingDetails/listingDetailsSelectors';
import { RootState } from 'rootReducer';
import { getIsLeaseSavedSearch } from 'saved-search/savedSearchSelectors';
import { RoutePath } from 'shared/constants/routesConstants';
import { isLeasePage as getIsLeasePage } from 'shared/helpers/isLeasePage';

export const useIsLeasePage = () => {
  const { pathname } = useLocation();

  const isLeaseSavedSearch = useSelector((state: RootState) =>
    pathname.includes(RoutePath.LISTINGS) ? false : getIsLeaseSavedSearch(state)
  );
  const isLeaseListing = useSelector(getIsLeaseListing);

  return useMemo(() => {
    return getIsLeasePage(pathname) || isLeaseSavedSearch || isLeaseListing;
  }, [pathname, isLeaseSavedSearch, isLeaseListing]);
};
