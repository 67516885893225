import styled, { css } from 'styled-components/macro';

import Button from 'shared/components/Button/Button';
import TextArea from 'shared/components/TextArea';
import globalTheme from 'shared/styles/globalTheme';
import { zIndex } from 'shared/styles/z-indexes';
import { media } from 'styled-system/responsive';

const HEADER_HEIGHT = 48;
const HEADER_HEIGHT_WITHOUT_DIVIDER = 40;

export const modalFormStyles = css`
  flex: 1;
  position: relative;
  min-height: 0;
  display: flex;
  flex-direction: column;

  ${media.sm`height: 100%;`}
`;

export const ModalSection = styled.div`
  padding-bottom: 32px;
`;

export const ModalSectionTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.text2};
  margin: 0;
  padding-bottom: 16px;
`;

export const ModalInnerFormContent = styled.div`
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  padding: 24px 64px 32px;
  flex: 1 1 auto;

  ${media.sm`padding: 16px 16px 24px 16px;`}
`;

export const ReferMeInnerFormContent = styled.div`
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  padding: 24px 24px 24px;
`;

export const ModalForm = styled.form`
  ${modalFormStyles};
`;

export const ResetButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.blue};
  font-size: 14px;
  font-weight: bold;
`;

export const ModalBottom = styled.div<{ hideDivider?: boolean }>`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  padding: 0 64px 32px;
  z-index: 1;
  button {
    height: 40px;
    &:nth-child(2) {
      margin-top: 16px;
    }
  }

  ${media.sm`
    && {
      padding: 24px 16px;
      border-top: 1px solid ${({ theme, hideDivider }) =>
        hideDivider ? 'transparent' : theme.divider};
    }
  `};
`;

export const ModalBottomHistory = styled(ModalBottom)`
  display: flex;
  justify-content: space-between;
  button {
    &:nth-child(2) {
      margin-top: 0px;
    }
  }
`;

export const ModalStickyBottom = styled(ModalBottom)`
  position: sticky;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.divider};
  padding: 24px 64px;
`;

export const ModalInnerMessageContent = styled(ModalInnerFormContent)`
  padding: 40px 32px;
  text-align: center;
  ${media.sm`padding: 80px 32px 24px 32px;`};
`;

export const ModalMessageTitle = styled.div`
  font-size: 20px;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.text2};
  margin-bottom: 8px;
`;

export const ModalMessage = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.text2};
`;

export const SubmitButton = styled(Button)`
  margin-top: 32px;
  height: 40px;
  ${media.sm`
    margin-top: 24px;
  `};
`;

export const RelocationGuideModalTextAreaMessageContainer = styled(TextArea)``;

export const MessageSecondaryText = styled.span`
  font-family: Lato, sans-serif;
  font-style: italic;
  color: ${({ theme }) => theme.disabled};
  margin-left: 8px;
  font-size: 12px;
  line-height: normal;
`;

export const defaultStyles = {
  content: {
    top: '56px',
    right: 0,
    left: 'auto',
    bottom: 'auto',
    height: 'auto',
    width: 'auto',
    maxHeight: 'calc(100% - 112px)',
    maxWidth: 'calc(100% - 112px)',
    position: 'relative',
    padding: 0,
    borderRadius: 0,
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate3d(0,0,0)',
  },
  overlay: {
    zIndex: `${zIndex.MODAL_OVERLAY}`,
    backdropFilter: 'blur(3.4px)',
    backgroundColor: 'rgba(8, 23, 36, 0.28)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
};

// DON'T MODIFY
export const mobileStyles = {
  content: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    maxHeight: 'inherit',
    maxWidth: 'inherit',
    minHeight: 'inherit',
    border: 'none',
  },
  overlay: {
    // should overlap tooltip
    zIndex: zIndex.TOOLTIP + 1,
  },
};

export const smallMobileStyles = {
  content: {
    top: '50%',
    transform: 'translateY(-50%)',
    height: 'auto',
    width: 'calc(100% - 32px)',
  },
};

export const ModalHeader = styled.div<{ hasHeaderDivider?: boolean }>`
  background-color: ${({ theme }) => theme.white};
  flex-basis: ${HEADER_HEIGHT_WITHOUT_DIVIDER}px;
  height: ${HEADER_HEIGHT_WITHOUT_DIVIDER}px;
  line-height: ${HEADER_HEIGHT_WITHOUT_DIVIDER}px;
  text-align: center;
  position: relative;
  z-index: 1;

  ${({ hasHeaderDivider }) =>
    hasHeaderDivider &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.divider};
      flex-basis: ${HEADER_HEIGHT}px;
      height: ${HEADER_HEIGHT}px;
      line-height: ${HEADER_HEIGHT}px;
    `}

  ${media.sm`
    position: fixed;
    z-index: 10;
    width: 100%;
  `};
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
`;

export const ModalHeaderActions = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  .listingDetailHeaderActionButton {
    display: flex;
    justify-content: center;
    padding: 0 12px;

    & > span {
      padding: 0;
    }
  }

  .listingDetailHeaderActionButton + .modalCloseButton:last-child {
    border-left: 1px solid #e8eaec;
  }
`;

export const ModalCustomHeaderActions = styled.div`
  display: flex;
  align-items: center;
  ${media.sm`
    display: none;
  `};
`;

export const ModalTitle = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;

  ${media.sm`
    padding: 0 64px;
  `};
`;

export const CloseButton = styled(Button)`
  height: 100%;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 16px;
    width: 16px;
    fill: ${({ theme }) => theme.text2};
  }

  &:hover {
    path,
    rect,
    polygon,
    line {
      fill: ${globalTheme.secondary};
    }
  }
  ${media.sm`
    right: 8px;
  `};
`;

export const BackButton = styled(CloseButton)`
  position: absolute;
  top: 0;
  ${media.sm`left: 8px;`};
`;

export const ModalContent = styled.div<{
  hasHeader?: boolean;
  hasHeaderDivider?: boolean;
}>`
  flex: 1;
  position: relative;
  min-height: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;

  ${media.sm`
    height: 100%;
    padding-top: ${props =>
      props.hasHeader
        ? props.hasHeaderDivider
          ? HEADER_HEIGHT
          : HEADER_HEIGHT_WITHOUT_DIVIDER
        : 0}px;
  `};
`;

export const BackToTopContainer = styled.div<{
  isMobile?: boolean;
  visible?: boolean;
  hasAnimation?: boolean;
}>`
  position: fixed;
  bottom: ${props => (props.isMobile ? 88 : 64)}px;
  right: ${props => (props.isMobile ? 16 : 48)}px;
  z-index: ${zIndex.BACK_TO_TOP_BUTTON};

  ${({ hasAnimation }) =>
    hasAnimation &&
    css<{ visible?: boolean }>`
      visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
      opacity: ${({ visible }) => (visible ? 1 : 0)};
      transition: visibility 0.2s linear, opacity 0.2s linear;
    `}
`;
