import { LeadSource } from 'agents/agentsConstants';
import {
  Agent,
  AgentReferMeBody,
  AgentReviewMeBody,
  AgentReviews,
  AgentsState,
  AgentSuggestions,
  ContactAgentBody,
} from 'agents/agentsTypes';
import { AgentsDirectoryFields } from 'butter-cms/butter-cms-types';
import { MapState } from 'map/mapTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { Paginated } from 'shared/types';

export enum AgentsActionType {
  FETCH_AGENTS_REQUEST = 'FETCH_AGENTS_REQUEST',
  FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS',
  FETCH_AGENTS_FAIL = 'FETCH_AGENTS_FAIL',
  FETCH_AGENTS = 'FETCH_AGENTS',
  FETCH_AGENT_SUGGESTIONS = 'FETCH_AGENT_SUGGESTIONS',
  FETCH_AGENT_SUGGESTIONS_SUCCESS = 'FETCH_AGENT_SUGGESTIONS_SUCCESS',
  FETCH_AGENT_SUGGESTIONS_FAIL = 'FETCH_AGENT_SUGGESTIONS_FAIL',
  SET_AGENTS_FROM_URL = 'SET_AGENTS_FROM_URL',
  SET_AGENTS_DATA_FROM_URL = 'SET_AGENTS_DATA_FROM_URL',

  FETCH_AGENT_BIO = 'FETCH_AGENT_BIO',
  FETCH_AGENT_BIO_REQUEST = 'FETCH_AGENTS_BIO_REQUEST',
  FETCH_AGENT_BIO_SUCCESS = 'FETCH_AGENTS_BIO_SUCCESS',
  FETCH_AGENT_BIO_FAIL = 'FETCH_AGENTS_BIO_FAIL',

  FETCH_AGENT_BIO_REVIEWS = 'FETCH_AGENT_BIO_REVIEWS',
  FETCH_AGENT_BIO_REVIEWS_REQUEST = 'FETCH_AGENTS_BIO_REVIEWS_REQUEST',
  FETCH_AGENT_BIO_REVIEWS_SUCCESS = 'FETCH_AGENTS_BIO_REVIEWS_SUCCESS',
  FETCH_AGENT_BIO_REVIEWS_FAIL = 'FETCH_AGENTS_BIO_REVIEWS_FAIL',

  SORT_AGENTS = 'SORT_AGENTS',
  FILTER_AGENTS = 'FILTER_AGENTS',
  FILTER_AGENTS_BY_LANGUAGE = 'FILTER_AGENTS_BY_LANGUAGE',
  CHANGE_AGENTS_PAGE = 'CHANGE_AGENTS_PAGE',
  RESET_AGENTS = 'RESET_AGENTS',
  RESET_AGENT_BIO = 'RESET_AGENT_BIO',
  CONTACT_AGENT = 'CONTACT_AGENT',
  AGENT_REFER_ME = 'AGENT_REFER_ME',
  AGENT_REVIEW_ME = 'AGENT_REVIEW_ME',
  REASSIGN_AGENT = 'REASSIGN_AGENT',
  CONTACT_AGENT_FAIL = 'CONTACT_AGENT_FAIL',
  RESET_AGENTS_LANGUAGE_FILTERS = 'RESET_AGENTS_LANGUAGE_FILTERS',
  INIT_AGENT_BIO_AND_LIST = 'INIT_AGENT_BIO_AND_LIST',
  CHANGE_AGENT_BIO_LISTINGS_PAGE = 'CHANGE_AGENT_BIO_LISTINGS_PAGE',
}

export interface FetchAgentsRequestAction {
  type: AgentsActionType.FETCH_AGENTS_REQUEST;
}

export function fetchAgentsRequest(): FetchAgentsRequestAction {
  return { type: AgentsActionType.FETCH_AGENTS_REQUEST };
}

export interface FetchAgentsAction {
  type: AgentsActionType.FETCH_AGENTS;
  all: boolean;
}

export function fetchAgents(all = false): FetchAgentsAction {
  return { type: AgentsActionType.FETCH_AGENTS, all };
}

export interface FetchAgentsFailAction {
  type: AgentsActionType.FETCH_AGENTS_FAIL;
  error: string;
}

export function fetchAgentsFail(error: string): FetchAgentsFailAction {
  return { type: AgentsActionType.FETCH_AGENTS_FAIL, error };
}

export interface FetchAgentsSuccessAction {
  type: AgentsActionType.FETCH_AGENTS_SUCCESS;
  agents: Paginated<Agent>;
  butterCmsData: AgentsDirectoryFields;
}

export function fetchAgentsSuccess(
  agents: Paginated<Agent>,
  butterCmsData: AgentsDirectoryFields
): FetchAgentsSuccessAction {
  return { type: AgentsActionType.FETCH_AGENTS_SUCCESS, agents, butterCmsData };
}

export interface ResetAgentsAction {
  type: AgentsActionType.RESET_AGENTS;
}

export function resetAgents(): ResetAgentsAction {
  return { type: AgentsActionType.RESET_AGENTS };
}

export interface FetchAgentSuggestionsAction {
  type: AgentsActionType.FETCH_AGENT_SUGGESTIONS;
  query: string;
  hideTeamMembers: boolean;
}

export function fetchAgentsSuggestion({
  query = '',
  hideTeamMembers = false,
}: {
  query?: string;
  hideTeamMembers?: boolean;
}): FetchAgentSuggestionsAction {
  return {
    type: AgentsActionType.FETCH_AGENT_SUGGESTIONS,
    query,
    hideTeamMembers,
  };
}

export interface FetchAgentsSuggestionSuccessAction {
  type: AgentsActionType.FETCH_AGENT_SUGGESTIONS_SUCCESS;
  suggestions: AgentSuggestions;
}

export function fetchAgentSuggestionsSuccess(
  suggestions: AgentSuggestions
): FetchAgentsSuggestionSuccessAction {
  return {
    type: AgentsActionType.FETCH_AGENT_SUGGESTIONS_SUCCESS,
    suggestions,
  };
}

export interface FetchAgentsSuggestionFailAction {
  type: AgentsActionType.FETCH_AGENT_SUGGESTIONS_FAIL;
  error: string;
}

export function fetchAgentSuggestionsFail(
  error: string
): FetchAgentsSuggestionFailAction {
  return { type: AgentsActionType.FETCH_AGENT_SUGGESTIONS_FAIL, error };
}

/* AGENT BIO */
export interface FetchAgentsBioRequestAction {
  type: AgentsActionType.FETCH_AGENT_BIO_REQUEST;
}

export function fetchAgentBioRequest(): FetchAgentsBioRequestAction {
  return { type: AgentsActionType.FETCH_AGENT_BIO_REQUEST };
}

export interface FetchAgentBioAction {
  type: AgentsActionType.FETCH_AGENT_BIO;
  name: string;
  sfId?: string;
}

export function fetchAgentBio(
  options: Omit<FetchAgentBioAction, 'type'>
): FetchAgentBioAction {
  return {
    type: AgentsActionType.FETCH_AGENT_BIO,
    ...options,
  };
}

export interface FetchAgentBioSuccessAction {
  type: AgentsActionType.FETCH_AGENT_BIO_SUCCESS;
  agent: Agent;
}

export function fetchAgentBioSuccess(agent: Agent): FetchAgentBioSuccessAction {
  return { type: AgentsActionType.FETCH_AGENT_BIO_SUCCESS, agent };
}

export interface FetchAgentBioFailAction {
  type: AgentsActionType.FETCH_AGENT_BIO_FAIL;
  error: string;
}

export interface SetAgentsFromUrlAction {
  type: AgentsActionType.SET_AGENTS_FROM_URL;
}

export interface SetAgentsDataFromUrlAction {
  type: AgentsActionType.SET_AGENTS_DATA_FROM_URL;
  agentsQueryData: Partial<AgentsState>;
}

export function fetchAgentBioFail(error: string): FetchAgentBioFailAction {
  return { type: AgentsActionType.FETCH_AGENT_BIO_FAIL, error };
}

/* AGENT BIO REVIEWS */
export interface FetchAgentsBioReviewsRequestAction {
  type: AgentsActionType.FETCH_AGENT_BIO_REVIEWS_REQUEST;
}

export function fetchAgentBioReviewsRequest(): FetchAgentsBioReviewsRequestAction {
  return { type: AgentsActionType.FETCH_AGENT_BIO_REVIEWS_REQUEST };
}

export interface FetchAgentBioReviewsAction {
  type: AgentsActionType.FETCH_AGENT_BIO_REVIEWS;
}

export function fetchAgentBioReviews(): FetchAgentBioReviewsAction {
  return { type: AgentsActionType.FETCH_AGENT_BIO_REVIEWS };
}

export interface FetchAgentBioReviewsSuccessAction {
  type: AgentsActionType.FETCH_AGENT_BIO_REVIEWS_SUCCESS;
  reviews: AgentReviews;
}

export function fetchAgentBioReviewsSuccess(
  reviews: AgentReviews
): FetchAgentBioReviewsSuccessAction {
  return { type: AgentsActionType.FETCH_AGENT_BIO_REVIEWS_SUCCESS, reviews };
}

export interface FetchAgentBioReviewsFailAction {
  type: AgentsActionType.FETCH_AGENT_BIO_REVIEWS_FAIL;
  error: string;
}

export function fetchAgentBioReviewsFail(
  error: string
): FetchAgentBioReviewsFailAction {
  return { type: AgentsActionType.FETCH_AGENT_BIO_REVIEWS_FAIL, error };
}

/* SORT AND FILTER AGENTS */
export interface SortAgentsAction {
  type: AgentsActionType.SORT_AGENTS;
  sort: {
    sortBy?: string;
    sortType?: SortOrder;
  };
}

export function sortAgents(sort: {
  sortBy?: string;
  sortType?: SortOrder;
}): SortAgentsAction {
  return { type: AgentsActionType.SORT_AGENTS, sort };
}

export interface FilterAgentsAction {
  type: AgentsActionType.FILTER_AGENTS;
  query: string;
}

export function filterAgents(query: string): FilterAgentsAction {
  return { type: AgentsActionType.FILTER_AGENTS, query };
}

export interface FilterAgentsByLanguageAction {
  type: AgentsActionType.FILTER_AGENTS_BY_LANGUAGE;
  languagesFilter: string[];
}

export function filterAgentsByLanguage(
  languagesFilter: string[]
): FilterAgentsByLanguageAction {
  return { type: AgentsActionType.FILTER_AGENTS_BY_LANGUAGE, languagesFilter };
}

export interface ResetAgentsLanguagesFilterAction {
  type: AgentsActionType.RESET_AGENTS_LANGUAGE_FILTERS;
}

export function resetAgentsLanguagesFilter(): ResetAgentsLanguagesFilterAction {
  return { type: AgentsActionType.RESET_AGENTS_LANGUAGE_FILTERS };
}

export interface ChangeAgentsPageAction {
  type: AgentsActionType.CHANGE_AGENTS_PAGE;
  page: number;
}

export function changeAgentsPage(page: number): ChangeAgentsPageAction {
  return { type: AgentsActionType.CHANGE_AGENTS_PAGE, page };
}

export interface ResetAgentBioAction {
  type: AgentsActionType.RESET_AGENT_BIO;
}

export function resetAgentBio(): ResetAgentBioAction {
  return { type: AgentsActionType.RESET_AGENT_BIO };
}
export interface ContactAgentFailAction {
  type: AgentsActionType.CONTACT_AGENT_FAIL;
  error: string;
}

export function contactAgentFail(error: string): ContactAgentFailAction {
  return {
    type: AgentsActionType.CONTACT_AGENT_FAIL,
    error,
  };
}

export interface ContactAgentAction {
  type: AgentsActionType.CONTACT_AGENT;
  body: ContactAgentBody;
}

export function contactAgent(body: ContactAgentBody): ContactAgentAction {
  return { type: AgentsActionType.CONTACT_AGENT, body };
}

export interface AgentReferMeAction {
  type: AgentsActionType.AGENT_REFER_ME;
  body: AgentReferMeBody;
}

export function agentReferMe(body: AgentReferMeBody): AgentReferMeAction {
  return { type: AgentsActionType.AGENT_REFER_ME, body };
}

export interface AgentReviewMeAction {
  type: AgentsActionType.AGENT_REVIEW_ME;
  body: AgentReviewMeBody;
}

export function agentReviewMe(body: AgentReviewMeBody): AgentReviewMeAction {
  return { type: AgentsActionType.AGENT_REVIEW_ME, body };
}

export interface ReassignAgentAction {
  type: AgentsActionType.REASSIGN_AGENT;
  agentId: string;
  leadSource: LeadSource;
}

export function reassignAgent(
  agentId: string,
  leadSource: LeadSource
): ReassignAgentAction {
  return { type: AgentsActionType.REASSIGN_AGENT, agentId, leadSource };
}

export interface InitAgentBioAndListAction {
  type: AgentsActionType.INIT_AGENT_BIO_AND_LIST;
  name: string;
  mapType: keyof MapState;
}

export function initAgentBioAndListAction(
  options: Omit<InitAgentBioAndListAction, 'type'>
): InitAgentBioAndListAction {
  return {
    type: AgentsActionType.INIT_AGENT_BIO_AND_LIST,
    ...options,
  };
}

export function setAgentsFromUrl(): SetAgentsFromUrlAction {
  return {
    type: AgentsActionType.SET_AGENTS_FROM_URL,
  };
}

export function setAgentsDataFromUrl(
  queryData: Partial<AgentsState>
): SetAgentsDataFromUrlAction {
  return {
    type: AgentsActionType.SET_AGENTS_DATA_FROM_URL,
    agentsQueryData: queryData,
  };
}

export interface ChangeAgentBioListingsPage {
  type: AgentsActionType.CHANGE_AGENT_BIO_LISTINGS_PAGE;
  page: number;
}

export const changeAgentBioListingsPage = (
  page: number
): ChangeAgentBioListingsPage => {
  return {
    type: AgentsActionType.CHANGE_AGENT_BIO_LISTINGS_PAGE,
    page,
  };
};

export type AgentsAction =
  | FetchAgentsRequestAction
  | FetchAgentsSuccessAction
  | FetchAgentsFailAction
  | FetchAgentsBioRequestAction
  | FetchAgentBioAction
  | FetchAgentBioSuccessAction
  | FetchAgentBioFailAction
  | FetchAgentsBioReviewsRequestAction
  | FetchAgentBioReviewsAction
  | FetchAgentBioReviewsSuccessAction
  | FetchAgentBioReviewsFailAction
  | FetchAgentsAction
  | SortAgentsAction
  | FilterAgentsAction
  | ChangeAgentsPageAction
  | ResetAgentsAction
  | ResetAgentBioAction
  | ContactAgentAction
  | ReassignAgentAction
  | ContactAgentFailAction
  | FilterAgentsByLanguageAction
  | ResetAgentsLanguagesFilterAction
  | InitAgentBioAndListAction
  | FetchAgentSuggestionsAction
  | FetchAgentsSuggestionSuccessAction
  | FetchAgentsSuggestionFailAction
  | SetAgentsFromUrlAction
  | SetAgentsDataFromUrlAction
  | ChangeAgentBioListingsPage
  | AgentReferMeAction;
