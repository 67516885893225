import { Collection, RawCollection } from 'collections/collectionsTypes';
import { RawSavedSearch, SavedSearch } from 'saved-search/savedSearchTypes';
import { notEmpty } from 'shared/helpers/boolean';
import { UserService } from 'user/UserService';

export async function addOwners(
  rawEntities: RawCollection[]
): Promise<Collection[]>;
export async function addOwners(
  rawEntities: RawSavedSearch[]
): Promise<SavedSearch[]>;

export async function addOwners<T extends RawSavedSearch | RawCollection>(
  rawEntities: T[]
) {
  const rawEntitiesWithOwners = rawEntities.filter(entity => entity.owner);

  const owners =
    rawEntitiesWithOwners.length > 0
      ? await UserService.fetchOwners(rawEntities.map(entity => entity.owner))
      : {};

  return rawEntities.map(rawEntity => ({
    ...rawEntity,
    owner: owners[rawEntity.owner],
  }));
}

export async function addOwner<T extends RawSavedSearch | RawCollection>(
  rawEntity: T
) {
  const owners = rawEntity.owner
    ? await UserService.fetchOwners(rawEntity.owner)
    : {};

  return {
    ...rawEntity,
    owner: owners[rawEntity.owner],
  };
}

export async function addAddedByProfile(rawEntity: RawCollection) {
  const addedListingsWithAddedBy =
    rawEntity.addedListings?.filter(listing => listing.addedBy) || [];

  const addedBy = notEmpty(addedListingsWithAddedBy)
    ? await UserService.fetchOwners(
        addedListingsWithAddedBy.map(({ addedBy }) => addedBy)
      )
    : {};

  rawEntity.addedListings = rawEntity.addedListings?.map(listing => ({
    ...listing,
    addedByProfile: addedBy[listing.addedBy],
  }));

  return rawEntity;
}
