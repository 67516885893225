import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import 'remove-focus-outline';
import { setAutoFreeze } from 'immer';
import lazySizes from 'lazysizes';
import { omit } from 'lodash';
import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import styled, {
  css,
  StyleSheetManager,
  ThemeProvider,
} from 'styled-components/macro';

import { ConnectedAdwordsForceRegistrationProvider } from 'adwords';
import { getEnv } from 'env-config';
import Routes from 'Routes';
import AriaLiveAnnouncerProvider from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerProvider';
import BackToTop from 'shared/components/BackToTop';
import { ErrorBoundary } from 'shared/components/ErrorBoundary';
import ManageNetworkStatus from 'shared/components/ManageNetworkStatus';
import MemoizedMetadata from 'shared/components/Metadata';
import { StaticRobots } from 'shared/components/Metadata/metadataConstants';
import ModalProvider from 'shared/components/Modal/ModalProvider';
import ModalRoot from 'shared/components/Modal/ModalRoot';
import ScrollToAnchor from 'shared/components/ScrollToAnchor';
import ScrollToTop from 'shared/components/ScrollToTop';
import Toaster from 'shared/components/Toaster';
import { DeploymentTarget } from 'shared/config/configConstants';
import {
  browserStorageItem,
  LANDING_PAGE,
} from 'shared/constants/appConstants';
import { BotContext } from 'shared/context/BotContext';
import { not } from 'shared/helpers/boolean';
import { bugsnag } from 'shared/services/bugsnag';
import { gaSubscribe } from 'shared/services/GoogleAnalyticsService';
import history from 'shared/services/history';
import { ReCAPTCHA } from 'shared/services/recaptcha';
import { setSessionId } from 'shared/services/session';
import { GlobalStyle } from 'shared/styles/globals';
import globalTheme from 'shared/styles/globalTheme';
import { store } from 'store';
import { media } from 'styled-system/responsive';
import { ServiceWorkerProvider } from 'sw/service-worker-provider';

import {
  FeatureSupportContext,
  IFeaturesSupport,
  initializeFeatureSupport,
} from './FeatureSupportProvider';

const PageContent = styled.div`
  min-height: 720px;
  ${media.sm(css`
    min-height: 100vh;
  `)};
`;

export async function main() {
  const verifyUserAgent = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const bots = [
      'googlebot',
      'bingbot',
      'slurp',
      'duckduckbot',
      'baiduspider',
      'yandexbot',
      'sogou',
      'exabot',
      'facebot',
      'ia_archiver',
    ];
    return bots.some(bot => userAgent.includes(bot));
  };

  try {
    deactivateHardReset();
    removeExternalRef();

    const rootElement = document.getElementById('root');
    if (not(rootElement)) {
      throw new Error('Cannot find root element to render app.');
    }

    await loadBrowserApiPolyfills();

    lazySizes.init();

    setAutoFreeze(false);

    if (not(sessionStorage.getItem(LANDING_PAGE))) {
      sessionStorage.setItem(LANDING_PAGE, window.location.href);
    }

    if (history.location.state?.modal) {
      history.replace(
        history.location.pathname,
        omit(history.location.state, 'modal')
      );
    }

    const searchParams = new URLSearchParams(history.location.search);
    const sessionIdParam = 'sessionid';
    const sessionId = searchParams.get(sessionIdParam);
    if (sessionId) {
      setSessionId(sessionId);
      searchParams.delete(sessionIdParam);
      history.replace({
        pathname: history.location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    }

    const env = getEnv();

    if (env.googleTagId && not(env.isPrerender)) {
      const tagManagerArgs = {
        gtmId: env.googleTagId,
        dataLayerName: 'PageDataLayer',
      };

      TagManager.initialize(tagManagerArgs);
    }

    if (env.googleAnalyticsId && not(env.isPrerender)) {
      gaSubscribe();
    }

    if (
      env.deploymentTarget !== DeploymentTarget.Production &&
      env.enableWhyDidYorRender === 'true'
    ) {
      const whyDidYouRender = await import(
        /* webpackChunkName: "why-did-you-render" */
        '@welldone-software/why-did-you-render'
      );
      whyDidYouRender.default(React);
    }

    render(
      <StrictMode>
        {env.deploymentTarget !== DeploymentTarget.Production && (
          <MemoizedMetadata robots={StaticRobots.NoindexNofollow} />
        )}
        <StyleSheetManager disableCSSOMInjection={env.isPrerender}>
          <Router history={history}>
            <ManageNetworkStatus />
            <ServiceWorkerProvider>
              <ThemeProvider theme={globalTheme}>
                <ErrorBoundary>
                  <Provider store={store}>
                    <BotContext.Provider value={{ isBot: verifyUserAgent() }}>
                      <FeatureSupportContext.Provider
                        value={initializeFeatureSupport()}
                      >
                        <AriaLiveAnnouncerProvider>
                          <ModalProvider>
                            <ScrollToTop>
                              <ScrollToAnchor>
                                <PageContent>
                                  <ConnectedAdwordsForceRegistrationProvider>
                                    <Routes />
                                  </ConnectedAdwordsForceRegistrationProvider>
                                  <BackToTop />
                                  <ReCAPTCHA />
                                </PageContent>
                                <ModalRoot />
                                <Toaster />
                              </ScrollToAnchor>
                            </ScrollToTop>
                          </ModalProvider>
                        </AriaLiveAnnouncerProvider>
                      </FeatureSupportContext.Provider>
                    </BotContext.Provider>
                  </Provider>
                </ErrorBoundary>
              </ThemeProvider>
            </ServiceWorkerProvider>
          </Router>
        </StyleSheetManager>
        <GlobalStyle />
      </StrictMode>,
      rootElement
    );

    bugsnag.leaveBreadcrumb('render() called');
  } catch (error) {
    console.error(error);
    bugsnag.leaveBreadcrumb('error in main()');
    bugsnag.notify(error);
  }
}

function removeExternalRef() {
  localStorage.removeItem(browserStorageItem.originUrl);
}

function deactivateHardReset() {
  setTimeout(() => {
    sessionStorage.removeItem(browserStorageItem.hardReset);
  }, 60000);
}

async function loadBrowserApiPolyfills() {
  if (typeof AbortController === 'undefined') {
    await import(
      /* webpackChunkName: "abortcontroller-polyfill" */
      'abortcontroller-polyfill'
    );
  }
  if (typeof IntersectionObserver === 'undefined') {
    await import(
      /* webpackChunkName: "intersection-observer-polyfill" */
      'intersection-observer'
    );
  }
}
