import React, {
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css, CSSObject } from 'styled-components/macro';

import { ButtonsCode } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { ReactComponent as IconClear } from 'shared/icons/shared/24x24/cancel.svg';
import { ReactComponent as IconError } from 'shared/icons/shared/24x24/error.svg';
import { ReactComponent as IconSearch } from 'shared/icons/shared/24x24/search.svg';
import { ReactComponent as IconWarning } from 'shared/icons/shared/24x24/warning.svg';
import globalTheme from 'shared/styles/globalTheme';
import { ErrorMessagePlacement } from 'shared/types/errorTypes';
import { media } from 'styled-system/responsive';

const errorStyles = css`
  background-color: ${({ theme }) => theme.errorBg};
  border-color: ${({ theme }) => theme.error};
`;

const disabledStyles = css`
  background-color: ${({ theme }) => theme.disabledArea};
  border-color: ${({ theme }) => theme.divider};
  color: ${({ theme }) => theme.text3};
  cursor: not-allowed;
  &:hover {
    border-color: ${({ theme }) => theme.divider};
  }

  /* required on iOS */
  input {
    -webkit-text-fill-color: ${({ theme }) => theme.text3};
    opacity: 1;
  }
`;

export const focusStyles = css`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.secondary};
  &:hover {
    border-color: ${({ theme }) => theme.secondary};
  }
`;

export const inputStyles = css`
  border: 0;
  background-color: inherit;
  color: inherit;
  cursor: inherit;
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 0 16px;

  &::-ms-clear {
    display: none;
  }

  &:focus {
    outline: 0;
  }
  &::placeholder {
    color: ${({ theme }) => theme.disabled};
  }
`;

const hasIconStyles = css`
  & > input {
    padding-right: 48px;
  }
  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }
`;

const searchableStyles = css`
  & > input {
    padding-left: 40px;
  }
`;

const SearchIconWrapper = styled(IconSearch)`
  fill: ${({ theme }) => theme.text3};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
`;

const RealInput = styled.div<{ maxWidth?: number }>`
  position: relative;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};
`;

interface MessageProps {
  placement?: ErrorMessagePlacement;
  color?: string;
  floating?: boolean;
  messageArrowStyle?: CSSObject;
  duplicateError?: boolean;
}

export const Message = styled.div<MessageProps>`
  display: flex;
  border-radius: 1px;
  max-width: 380px;
  position: absolute;
  font-size: 12px;
  line-height: 1.2;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  padding: 4px 8px;
  transition: 0.3s;
  background-color: ${({ color, theme }) => color || theme.error};
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  ${({ placement, color, theme }) =>
    placement === ErrorMessagePlacement.Top &&
    css`
      right: 0;
      bottom: 48px;

      &::after {
        bottom: -4px;
        left: 20px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid ${color || theme.error};
      }
    `}

  ${({ placement, color, theme }) =>
    placement === ErrorMessagePlacement.Right &&
    css`
      left: calc(100% + 6px);
      bottom: 8px;

      &::after {
        bottom: 8px;
        left: -4px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 5px solid ${color || theme.error};
      }
    `}

    ${({ placement, color, theme }) =>
    placement === ErrorMessagePlacement.Bottom &&
    css`
      right: 0;
      bottom: -28px;

      &::after {
        top: -4px;
        left: 20px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 5px solid ${color || theme.error};
      }
    `}

  &:after {
    ${({ messageArrowStyle }) =>
      messageArrowStyle &&
      css`
        ${messageArrowStyle}
      `}
  }

  ${({ floating }) =>
    not(floating) &&
    css`
      position: relative;
      margin-top: 8px;
      width: fit-content;
      top: auto;
      bottom: auto;
      right: auto;
      left: auto;
      white-space: normal;
    `}

  ${media.sm`
    max-width: 320px;
  `};

  ${({ duplicateError }) =>
    duplicateError &&
    css`
      bottom: 0;
      height: 100%;
      max-width: none;
      width: 332px;
      white-space: normal;
      line-height: 16px;

      &::after {
        bottom: 15px;
      }

      ${media.sm`
        max-width: none;
      `};
    `}
`;

Message.defaultProps = {
  placement: ErrorMessagePlacement.Top,
};

export const Label = styled.label`
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
  text-align: left;
`;

export const BottomText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
  padding-top: 8px;
`;

export const SecondaryLabel = styled.span`
  font-size: 12px;
  font-style: italic;
  color: ${({ theme }) => theme.disabled};
  margin-left: 8px;
  font-weight: normal;
`;

interface InputContainerProps {
  hasIcon?: boolean;
  parentWidth?: boolean;
  error?: boolean;
  focused?: boolean;
  disabled?: boolean;
  searchable?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  background-color: ${({ theme }) => theme.white};
  border-radius: 1px;
  border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.text2};
  height: 40px;
  position: relative;
  transition: 0.3s;

  &:hover {
    border-color: ${({ theme }) => theme.text3};
  }
  ${props => props.hasIcon && hasIconStyles};
  ${props => props.searchable && searchableStyles};
  ${props => props.parentWidth && 'width: 100%;'};
  ${props => props.error && errorStyles};
  ${props => props.focused && focusStyles};
  ${props => props.disabled && disabledStyles};
`;

export const InputField = styled.input`
  ${inputStyles}

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
  }
`;

const IconClearWrap = styled(IconClear)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.text3};
  pointer-events: auto !important;

  &:hover {
    fill: ${({ theme }) => theme.text2};
  }

  &:active {
    fill: ${globalTheme.text};
  }
`;

export const ErrorMessageClassName = 'error-message';

export interface InputProps {
  id?: string;
  name?: string;
  type?: string;
  value?: string;
  min?: number;
  max?: number;
  defaultValue?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  icon?: ReactElement;
  disabled?: boolean;
  error?: boolean;
  warn?: boolean;
  message?: string | JSX.Element;
  bottomText?: string | JSX.Element;
  parentWidth?: boolean;
  maxLength?: number;
  maxWidth?: number;
  className?: string;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  warning?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  readOnly?: boolean;
  focused?: boolean;
  secondaryLabel?: string;
  errorMessagePlacement?: ErrorMessagePlacement;
  floatingMessage?: boolean;
  messageArrowStyle?: CSSObject;
  showErrorIcon?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClear?: React.MouseEventHandler;
}

const Input = forwardRef(
  (props: InputProps, ref: MutableRefObject<HTMLInputElement | undefined>) => {
    const [focused, setFocused] = useState(false);
    const input = useRef<HTMLInputElement>();

    useEffect(() => {
      if (props.focused) {
        setFocusOnRef();
        handleFocus(true, props.onFocus)();
      }
    }, [props.focused]);

    const setFocusOnRef = () => {
      if (input.current) {
        input.current.focus();
      }
    };

    const handleFocus =
      (focused: boolean, cb: (e?: React.FocusEvent) => void) =>
      (e?: React.FocusEvent) => {
        e?.persist();
        setFocused(focused);
        cb?.(e);
      };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (not(props.maxLength) || e.target.value.length <= props.maxLength) {
        return props.onChange?.(e);
      }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === ButtonsCode.Enter) {
        setFocused(false);
      }
    };

    const {
      id,
      name,
      type = 'text',
      icon,
      label,
      disabled,
      error,
      warn,
      message,
      parentWidth,
      clearable,
      searchable,
      secondaryLabel,
      bottomText,
      onClear,
      onChange,
      value,
      onBlur,
      onFocus,
      errorMessagePlacement,
      floatingMessage,
      messageArrowStyle,
      showErrorIcon,
      ...rest
    } = props;
    const hasIcon = Boolean(icon) || clearable || not(showErrorIcon);
    const hasLabel = Boolean(label);
    const hasMessage = Boolean(message);
    const hasBottomText = Boolean(bottomText);
    const messageColor = error
      ? globalTheme.error
      : warn
      ? globalTheme.warning
      : globalTheme.white;

    return (
      <>
        <RealInput className={props.className} maxWidth={props.maxWidth}>
          {hasLabel && (
            <Label htmlFor={id || name}>
              {label}
              <SecondaryLabel> {secondaryLabel}</SecondaryLabel>
            </Label>
          )}
          <InputContainer
            searchable={searchable}
            disabled={disabled}
            focused={focused}
            parentWidth={parentWidth}
            hasIcon={hasIcon || error || warn}
            error={error}
          >
            {hasMessage && not(focused) && floatingMessage && (
              <Message
                floating
                className={ErrorMessageClassName}
                color={messageColor}
                placement={errorMessagePlacement}
                messageArrowStyle={messageArrowStyle}
              >
                {message}
              </Message>
            )}
            {searchable && <SearchIconWrapper height={16} width={16} />}
            <InputField
              ref={ref || input}
              id={id || name}
              type={type}
              disabled={disabled}
              value={value}
              onFocus={handleFocus(true, onFocus)}
              onBlur={handleFocus(false, onBlur)}
              onInput={handleChange}
              onKeyPress={handleKeyPress}
              {...rest}
            />
            {error && not(hasIcon) && not(focused) && (
              <IconError width="24px" fill={globalTheme.error} />
            )}
            {warn && not(error) && not(hasIcon) && not(focused) && (
              <IconWarning width="24px" fill={globalTheme.warning} />
            )}
            {clearable && Boolean(value) && (
              <IconClearWrap
                onClick={onClear}
                role="button"
                aria-label="clear keywords"
              />
            )}
            {hasIcon && not(clearable) && icon}
          </InputContainer>
        </RealInput>
        {hasMessage && not(focused) && not(floatingMessage) && (
          <Message
            className={ErrorMessageClassName}
            color={messageColor}
            placement={ErrorMessagePlacement.Bottom}
            messageArrowStyle={messageArrowStyle}
          >
            {message}
          </Message>
        )}
        {hasBottomText && <BottomText>{bottomText}</BottomText>}
      </>
    );
  }
);

Input.defaultProps = {
  floatingMessage: true,
};

Input.displayName = 'Input';

export default Input;
