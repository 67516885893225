import {
  Contact,
  UpdateContactNotificationsSettingsBody,
} from 'contacts/contactsTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { Paginated } from 'shared/types';

export enum ContactsActionType {
  FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST',
  FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS',
  FETCH_CONTACTS_FAIL = 'FETCH_CONTACTS_FAIL',
  FETCH_CONTACTS = 'FETCH_CONTACTS',
  SORT_CONTACTS = 'SORT_CONTACTS',
  FILTER_CONTACTS = 'FILTER_CONTACTS',
  CHANGE_CONTACTS_PAGE = 'CHANGE_CONTACTS_PAGE',
  RESET_CONTACTS = 'RESET_CONTACTS',
  FETCH_CONTACT = 'FETCH_CONTACT',
  FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS',
  FETCH_CONTACT_FAIL = 'FETCH_CONTACT_FAIL',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_FAIL = 'UPDATE_CONTACT_FAIL',
  RESET_CONTACT = 'RESET_CONTACT',
  SET_BASIC_INFO_WAS_LOADED = 'SET_BASIC_INFO_WAS_LOADED',
  UPDATE_CONTACT_NOTIFICATIONS_SETTINGS = 'UPDATE_CONTACT_NOTIFICATIONS_SETTINGS',
  UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_SUCCESS = 'UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_SUCCESS',
  UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_FAIL = 'UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_FAIL',
}

export interface FetchContactsRequestAction {
  type: ContactsActionType.FETCH_CONTACTS_REQUEST;
}

export function fetchContactsRequest(): FetchContactsRequestAction {
  return { type: ContactsActionType.FETCH_CONTACTS_REQUEST };
}

export interface FetchContactsAction {
  type: ContactsActionType.FETCH_CONTACTS;
}

export function fetchContacts(): FetchContactsAction {
  return { type: ContactsActionType.FETCH_CONTACTS };
}

export interface SortContactsAction {
  type: ContactsActionType.SORT_CONTACTS;
  sort: {
    sortBy?: string;
    sortType?: SortOrder;
  };
}

export function sortContacts(sort: {
  sortBy?: string;
  sortType?: SortOrder;
}): SortContactsAction {
  return { type: ContactsActionType.SORT_CONTACTS, sort };
}

export interface FilterContactsAction {
  type: ContactsActionType.FILTER_CONTACTS;
  query: string;
}

export function filterContacts(query: string): FilterContactsAction {
  return { type: ContactsActionType.FILTER_CONTACTS, query };
}

export interface ChangeContactsPageAction {
  type: ContactsActionType.CHANGE_CONTACTS_PAGE;
  page: number;
}

export function changeContactsPage(page: number): ChangeContactsPageAction {
  return { type: ContactsActionType.CHANGE_CONTACTS_PAGE, page };
}

export interface FetchContactsFailAction {
  type: ContactsActionType.FETCH_CONTACTS_FAIL;
  error: string;
}

export function fetchContactsFail(error: string): FetchContactsFailAction {
  return { type: ContactsActionType.FETCH_CONTACTS_FAIL, error };
}

export interface FetchContactsSuccessAction {
  type: ContactsActionType.FETCH_CONTACTS_SUCCESS;
  contacts: Paginated<Contact>;
}

export function fetchContactsSuccess(
  contacts: Paginated<Contact>
): FetchContactsSuccessAction {
  return { type: ContactsActionType.FETCH_CONTACTS_SUCCESS, contacts };
}

export interface ResetContactsAction {
  type: ContactsActionType.RESET_CONTACTS;
}

export function resetContacts(): ResetContactsAction {
  return { type: ContactsActionType.RESET_CONTACTS };
}

export interface FetchContactAction {
  type: ContactsActionType.FETCH_CONTACT;
  id: string;
}

export function fetchContact(id: string): FetchContactAction {
  return { type: ContactsActionType.FETCH_CONTACT, id };
}

export interface FetchContactSuccessAction {
  type: ContactsActionType.FETCH_CONTACT_SUCCESS;
  contact: Contact;
}

export function fetchContactSuccess(
  contact: Contact
): FetchContactSuccessAction {
  return { type: ContactsActionType.FETCH_CONTACT_SUCCESS, contact };
}

export interface FetchContactFailAction {
  type: ContactsActionType.FETCH_CONTACT_FAIL;
  error: string;
}

export function fetchContactFail(error: string): FetchContactFailAction {
  return { type: ContactsActionType.FETCH_CONTACT_FAIL, error };
}

export interface UpdateContactAction {
  type: ContactsActionType.UPDATE_CONTACT;
  id: string;
  contactData: Partial<Contact>;
}

export function updateContact(
  id: string,
  contactData: Partial<Contact>
): UpdateContactAction {
  return {
    type: ContactsActionType.UPDATE_CONTACT,
    id,
    contactData,
  };
}

export interface UpdateContactSuccessAction {
  type: ContactsActionType.UPDATE_CONTACT_SUCCESS;
  contact: Contact;
}

export function updateContactSuccess(
  contact: Contact
): UpdateContactSuccessAction {
  return { type: ContactsActionType.UPDATE_CONTACT_SUCCESS, contact };
}

export interface UpdateContactFailAction {
  type: ContactsActionType.UPDATE_CONTACT_FAIL;
  error: string;
}

export function updateContactFail(error: string): UpdateContactFailAction {
  return { type: ContactsActionType.UPDATE_CONTACT_FAIL, error };
}

export interface ResetContactAction {
  type: ContactsActionType.RESET_CONTACT;
}

export function resetContact(): ResetContactAction {
  return { type: ContactsActionType.RESET_CONTACT };
}

export interface SetBasicInfoWasLoadedAction {
  type: ContactsActionType.SET_BASIC_INFO_WAS_LOADED;
}

export function setBasicInfoWasLoaded(): SetBasicInfoWasLoadedAction {
  return { type: ContactsActionType.SET_BASIC_INFO_WAS_LOADED };
}

export interface UpdateUserOptOutStatusRequestPayload {
  hasOptedOutOfEmail: boolean;
  userId: string;
  onSuccess?: () => void;
  onError?: () => void;
}

interface UpdateContactNotificationsSettingsPayload {
  body: UpdateContactNotificationsSettingsBody;
  onErrorCb?: () => void;
  onSuccessCb?: () => void;
}

export interface UpdateContactNotificationsSettingsAction {
  type: ContactsActionType.UPDATE_CONTACT_NOTIFICATIONS_SETTINGS;
  payload: UpdateContactNotificationsSettingsPayload;
}

export function updateContactNotificationsSettings(
  payload: UpdateContactNotificationsSettingsPayload
): UpdateContactNotificationsSettingsAction {
  return {
    type: ContactsActionType.UPDATE_CONTACT_NOTIFICATIONS_SETTINGS,
    payload,
  };
}

export interface UpdateContactNotificationsSettingsSuccessAction {
  type: ContactsActionType.UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_SUCCESS;
  contactNotificationsSettings: Partial<Contact>;
}

export function updateContactNotificationsSettingsSuccess(
  contactNotificationsSettings: Partial<Contact>
): UpdateContactNotificationsSettingsSuccessAction {
  return {
    type: ContactsActionType.UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_SUCCESS,
    contactNotificationsSettings,
  };
}

export interface UpdateContactNotificationsSettingsFailAction {
  type: ContactsActionType.UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_FAIL;
  error: string;
}

export function updateContactNotificationsSettingsFail(
  error: string
): UpdateContactNotificationsSettingsFailAction {
  return {
    type: ContactsActionType.UPDATE_CONTACT_NOTIFICATIONS_SETTINGS_FAIL,
    error,
  };
}

export type ContactsAction =
  | FetchContactsRequestAction
  | FetchContactsAction
  | SortContactsAction
  | FilterContactsAction
  | ChangeContactsPageAction
  | FetchContactsSuccessAction
  | FetchContactsFailAction
  | ResetContactsAction
  | FetchContactAction
  | FetchContactSuccessAction
  | FetchContactFailAction
  | ResetContactAction
  | SetBasicInfoWasLoadedAction
  | UpdateContactNotificationsSettingsAction
  | UpdateContactNotificationsSettingsSuccessAction
  | UpdateContactNotificationsSettingsFailAction
  | UpdateContactAction
  | UpdateContactSuccessAction
  | UpdateContactFailAction;
