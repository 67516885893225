import { DeepReadonly } from 'utility-types';

import { CommentsAction, CommentsActionType } from 'comments/commentsActions';
import { CommentsState } from 'comments/commentsTypes';

const {
  FETCH_COMMENTS,
  FETCH_COMMENTS_ERROR,
  FETCH_COMMENTS_SUCCESS,
  RESET_COMMENTS,
  SAVE_COMMENT_ERROR,
  SAVE_COMMENT_SUCCESS,
  ADD_COMMENT,
} = CommentsActionType;

export const COMMENTS_DEFAULT_STATE: CommentsState = {
  loading: false,
  error: '',
  items: [],
  total: 0,
};

export function commentsReducer(
  state: DeepReadonly<CommentsState> = COMMENTS_DEFAULT_STATE,
  action: CommentsAction
): DeepReadonly<CommentsState> {
  switch (action.type) {
    case FETCH_COMMENTS:
      return { ...state, loading: true };

    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        items: [...state.items, ...action.items],
        total: action.total,
        loading: false,
        error: '',
      };

    case FETCH_COMMENTS_ERROR:
      return {
        ...state,
        ...COMMENTS_DEFAULT_STATE,
        loading: false,
        error: action.error,
      };

    case SAVE_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };

    case SAVE_COMMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case ADD_COMMENT:
      return {
        ...state,
        items: [...state.items, action.comment],
        total: state.total + 1,
      };

    case RESET_COMMENTS:
      return { ...COMMENTS_DEFAULT_STATE };

    default:
      return state;
  }
}
