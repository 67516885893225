// TODO: rename file to errorHelpers because it is excluded from coverage report file name is end's by Types
import { mapValues } from 'lodash';

import {
  AuthErrorType,
  ServerValidationErrorsMessage,
} from 'shared/constants/errorConstants';
import { FormErrors, ServerValidationErrors } from 'shared/types/errorTypes';

export const authErrors: { [key in AuthErrorType]?: string } = {
  [AuthErrorType.UserExist]: 'This email address is already registered',
  [AuthErrorType.EmailVerification]: 'This email address is invalid',
};

export const ERRORS = [
  {
    type: AuthErrorType.UserResetPasswordLinkExpired,
    messages: [
      'Invalid verification code provided, please try again.',
      'Invalid code provided, please request a code again.',
    ],
  },
  {
    type: AuthErrorType.UserNotFound,
    messages: ['Username/client id combination not found.'],
  },
  {
    type: AuthErrorType.UserExist,
    messages: [
      'username exists',
      'An account with the given email already exists.',
    ],
  },
  {
    type: AuthErrorType.WrongCredentials,
    messages: ['User does not exist.', 'Incorrect username or password.'],
  },
  {
    type: AuthErrorType.EmailVerification,
    messages: ['email is not verified'],
  },
  {
    type: AuthErrorType.EmailExistOnSignInError,
    messages: [`User does not exist. ${AuthErrorType.EmailExistOnSignInError}`],
  },
  {
    type: AuthErrorType.EmailWithWrongPassError,
    messages: [
      `Incorrect username or password. ${AuthErrorType.EmailWithWrongPassError}`,
    ],
  },
];

// TODO: tests
export const getErrorType = (message?: string): AuthErrorType => {
  if (!Boolean(message)) {
    return AuthErrorType.NoError;
  }

  const error = ERRORS.find(({ messages }) => messages.includes(message));

  return error ? error.type : AuthErrorType.UnrecognizedError;
};

export const mapServerValidationErrors = (
  serverValidationErrors: ServerValidationErrors
): FormErrors => {
  return mapValues(
    serverValidationErrors,
    (_, key: keyof typeof ServerValidationErrorsMessage) =>
      ServerValidationErrorsMessage[key]
  );
};

export const hasErrorMessage = (error: any): error is { message: string } => {
  return 'message' in error;
};
