import { nanoid } from '@reduxjs/toolkit';
import { Search } from 'history';
import { pickBy } from 'lodash';
import queryString from 'query-string';

import {
  ButtonsCode,
  SCREENS_COUNT_OFFSET_FOR_SCROLL_TO_TOP,
  YOU_TUBE_VIDEO_ID_REG_EXP,
} from 'shared/constants/appConstants';
import { notEqual } from 'shared/helpers/boolean';

export const scrollToTop = (top = 0): void => {
  window.scrollTo(0, top);
};

export const showScrollToTopUnder = (): number => {
  const { innerHeight } = window;
  return innerHeight * SCREENS_COUNT_OFFSET_FOR_SCROLL_TO_TOP;
};

// Checks if value is number or numeric string
export function isNumeric(n: any) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(+n);
}

export const isNotFoundError = (error = '') => error.includes('404');

export const getYoutubeVideoId = (videoUrl?: string): string =>
  videoUrl?.match(YOU_TUBE_VIDEO_ID_REG_EXP)[1];

export const setAllFormFieldsBlur = () => {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
};

export const generateUniqueID = (): string => nanoid();

export const removeDefaultProperties = <T>(
  state: Partial<T>,
  defaultState: T
): Partial<T> => {
  return pickBy(state, (value, key: keyof T) => {
    if (typeof value === 'object') {
      if (Array.isArray(value) && Array.isArray(defaultState[key])) {
        return notEqual(
          [...value].sort(),
          [...(defaultState[key] as unknown as unknown[])].sort()
        );
      }
      return notEqual(value, defaultState[key]);
    }
    return value !== defaultState[key];
  }) as Partial<T>;
};

export const removeEmptyProperties = <T>(values: Record<string, T>) => {
  return pickBy(values, value => Boolean(value) || typeof value === 'boolean');
};

export const getNotEmptyQueryParams = (
  search: Search,
  queryOptions: queryString.ParseOptions = {}
) => {
  return removeEmptyProperties(queryString.parse(search, queryOptions));
};

export const getNumberIfExists = (value: unknown): number | undefined => {
  return Number.isNaN(value) ? undefined : Number(value);
};

export const isIOSdevice = (): boolean => {
  const ua = navigator.userAgent;
  return (
    /iPad|iPhone|iPod/.test(ua) ||
    (ua.includes('Mac') && 'ontouchend' in document)
  );
};

export const handleEnterOrSpaceKeyPress =
  <T = HTMLElement>(handler: (e: React.KeyboardEvent<T>) => void) =>
  (e: React.KeyboardEvent<T>) => {
    if (e.key === ButtonsCode.Enter || e.key === ButtonsCode.Space) {
      handler(e);
    }
  };
