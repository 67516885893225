import { Omit } from 'utility-types';

import { LeadSource } from 'agents/agentsConstants';
import { Agent, AgentReviews } from 'agents/agentsTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { isEmpty } from 'shared/helpers/boolean';
import { ApiService } from 'shared/services/ApiService';
import { BaseResponse, Paginated } from 'shared/types';

export const AGENT_BASE_URL = '/agent';

export type RawAgent = Omit<Agent, 'languagesSpoken'> & {
  languagesSpoken?: string;
};

export class AgentsService {
  static async uploadCover(data: FormData) {
    return ApiService.request<any>({
      method: 'POST',
      path: `${AGENT_BASE_URL}/cover`,
      data,
      isFormData: true,
    });
  }

  static async getCover(agentId: string) {
    return ApiService.request<any>({
      method: 'GET',
      path: `${AGENT_BASE_URL}/${agentId}/cover`,
    });
  }

  static async fetchAgents(query: {
    limit: number;
    query: string;
    sortBy?: string;
    sortType?: SortOrder;
    page?: number;
    language?: string[];
    hideTeamMembers?: boolean;
  }): Promise<{ hits: Agent[]; total: number }> {
    const response = await ApiService.request<Paginated<RawAgent>>({
      method: 'GET',
      path: AGENT_BASE_URL,
      query,
    });

    return {
      ...response,
      hits: response.hits.map(parseLanguagesAndPhotoUrl),
    };
  }

  static async fetchAgent({
    agentId,
    redirectToNotFound = true,
    signal,
  }: {
    agentId: string;
    redirectToNotFound?: boolean;
    signal?: AbortSignal;
  }): Promise<Agent> {
    const agent: RawAgent = await ApiService.request<RawAgent>({
      method: 'GET',
      path: `${AGENT_BASE_URL}/${agentId}`,
      redirectToNotFound,
      signal,
    });
    return parseLanguagesAndPhotoUrl(agent);
  }

  static async fetchDefaultAgent(): Promise<Agent> {
    const response = await ApiService.request<BaseResponse<RawAgent>>({
      method: 'GET',
      path: `${AGENT_BASE_URL}/default`,
    });

    return parseLanguagesAndPhotoUrl(response.data);
  }

  static async fetchORSAgent(query: {
    mlsNumber?: string;
    polygon?: string;
    address?: string;
    street?: string;
  }): Promise<Agent> {
    const response = await ApiService.request<
      BaseResponse<{ agent: RawAgent }>
    >({
      method: 'GET',
      path: '/ors-agent',
      prefix: 'v2',
      query,
    });

    return parseLanguagesAndPhotoUrl(response.data.agent);
  }

  static async fetchAgentReviews(
    agentId: string,
    limit?: number,
    page?: number
  ): Promise<AgentReviews> {
    const query = {
      agentId,
      limit: limit || 1000,
      page: page || 1,
    };
    return ApiService.request<AgentReviews>({
      method: 'GET',
      path: `${AGENT_BASE_URL}/review`,
      query,
    });
  }

  static async reassignAgent(
    agentId: string,
    leadSource: LeadSource
  ): Promise<{ agentId: string }> {
    return ApiService.request<{ agentId: string }>({
      method: 'PATCH',
      path: `user/${agentId}/reassign`,
      query: { leadSource },
      data: {
        agentId,
      },
    });
  }

  static async fetchInterviewerAgent(): Promise<Agent> {
    return ApiService.request({
      method: 'GET',
      path: `/agent/interviewer`,
    });
  }
}

export function parseLanguagesAndPhotoUrl(agent: RawAgent): Agent {
  if (isEmpty(agent)) {
    return agent;
  }

  const newAgent: Agent = {
    ...agent,
    languagesSpoken: agent.languagesSpoken
      ? agent.languagesSpoken.split(';')
      : [],
  };

  if (agent.headshotUrlSquare) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(
      agent.headshotUrlSquare,
      'text/html'
    );
    const imgs = htmlDoc.getElementsByTagName('img');

    if (imgs && imgs.length) {
      newAgent.headshotUrlSquare = imgs[0].src;
    }
  }

  return newAgent;
}
