import config from 'config';

export const DEFAULT_CENTER = config.mapCenter;

export const DEFAULT_BOUNDARIES = {
  minLatitude: 22.382142629063907,
  maxLongitude: -89.40032662499999,
  maxLatitude: 37.64169405454581,
  minLongitude: -106.09954537499999,
};

export const DEFAULT_MAP_ZOOM = 10;
export const MIN_ZOOM_LEVEL = 20;
export const MAX_ZOOM_LEVEL = 6;
export const NO_BOUNDARY_SOUTH_WEST = -0.0005;
export const NO_BOUNDARY_NORTH_EAST = 0.0005;

export enum MapItemTypes {
  Group = 'group',
  Listing = 'listing',
  Cluster = 'cluster',
}

// TODO: Check usages
export enum MapViewType {
  Roadmap = 'roadmap',
  Satellite = 'satellite',
  Terrain = 'terrain',
}

export enum DrawingStatus {
  NoDrawing = 'NoDrawing',
  InitDrawing = 'Init',
  DrawingInProcess = 'Drawing In Process',
  ContinueDrawing = 'Continue Drawing',
}

export const CUSTOM_POLYGON_TYPE = 'custom';

export enum TypesOfMap {
  AgentListings = 'agentListings',
  AgentSavedSearches = 'agentSavedSearches',
  BioPage = 'bioPage',
  Collections = 'collections',
  ListingDetails = 'listingDetails',
  ListingDetailsModal = 'listingDetailsModal',
  Listings = 'listings',
  Lease = 'lease',
  SavedSearches = 'savedSearches',
  ViewedListings = 'viewedListings',
  CreateListing = 'createListing',
}

export const mapsWithTags: string[] = [
  TypesOfMap.Listings,
  TypesOfMap.Lease,
  TypesOfMap.SavedSearches,
  TypesOfMap.AgentListings,
  TypesOfMap.AgentSavedSearches,
];

export const typesOfMapWhereDrawingModeOn: Array<Partial<TypesOfMap>> = [
  TypesOfMap.Listings,
  TypesOfMap.Lease,
  TypesOfMap.SavedSearches,
  TypesOfMap.AgentListings,
  TypesOfMap.AgentSavedSearches,
];

export const controlledTypesOfMap: Array<Partial<TypesOfMap>> = [
  TypesOfMap.SavedSearches,
  TypesOfMap.AgentSavedSearches,
];

export const DRAWN_POLYGON_TYPE = 'drawn';
export const SYSTEM_POLYGON_TYPE = 'system';

export const MAPDATA_TO_URL_HASHMAP: { [key: string]: string } = {
  minLatitude: 'minlat',
  maxLongitude: 'maxlong',
  maxLatitude: 'maxlat',
  minLongitude: 'minlong',
  zoom: 'z',
};

export const USER_LOCATION_TO_URL_HASHMAP: { [key: string]: string } = {
  latitude: 'ulat',
  longitude: 'ulng',
};

export const MAPDATA_TO_SAVED_SEARCH_QUERY: string[] = [
  'minLatitude',
  'maxLongitude',
  'maxLatitude',
  'minLongitude',
  'zoom',
  'lat',
  'lng',
];

export enum PopupPosition {
  Top = 'top',
  Center = 'center',
}

// user location layer
export const BeaconOutsideZIndex = 1;
// main position of all markers and clusters layer
export const ClustersAndMarkersZIndex = BeaconOutsideZIndex + 1;
// beacon should be on top of all marker and clusters
export const BeaconZIndex = ClustersAndMarkersZIndex + 1;
// when you hover some particular marker or cluster
export const MarkerOrClusterHoverZIndex = BeaconZIndex + 1;
// when you open listing card
export const ListingItemWrapperZIndex = MarkerOrClusterHoverZIndex + 1;
// selected marker should be shown even in case when you open listing card
export const SelectedMarkerZIndex = ListingItemWrapperZIndex + 1;

export const ZOOM_CONTROL_CLASSNAME = 'gm-control-active';

export const MAP_CONTAINER_PERCENTAGE_SIZE = 49;
