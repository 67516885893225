import { useEffect, useRef, useState } from 'react';
import Select, { ActionMeta, Props, SelectInstance } from 'react-select';
import { useTheme } from 'styled-components/macro';

import { ErrorMessageClassName, Message } from 'shared/components/Input/Input';
import RaSelectCustomComponents from 'shared/components/MultiSelect/components/MultiSelectCustomComponents';
import {
  RaSelectActionType,
  SELECT_ALL_LABEL,
  selectAllOptionItem,
} from 'shared/components/MultiSelect/MultiSelectConstants';
import { getMultiSelectStyles } from 'shared/components/MultiSelect/MultiSelectStyled';
import SelectInput from 'shared/components/Select/components/SelectInput';
import { ButtonsCode } from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { usePrevious } from 'shared/hooks/usePrevious';
import { ErrorMessagePlacement } from 'shared/types/errorTypes';
import { Box } from 'styled-system/components/box';

const {
  MultiValueRemove,
  DropdownIndicator,
  ClearIndicator,
  Option,
  ValueContainer,
} = RaSelectCustomComponents;

export interface MultiSelectOption<
  OptionValueType = string,
  OptionLabelType = string
> {
  value: OptionValueType;
  label: OptionLabelType;
}

export type MultiSelectProps = Props<MultiSelectOption> & {
  selectAllOption?: boolean;
  expandOnEnabled?: boolean;
  error?: boolean;
  message?: string;
  errorMessagePlacement?: ErrorMessagePlacement;
  floatingMessage?: boolean;
  ariaLabel?: string;
};

export const MultiSelect = ({
  selectAllOption = false,
  expandOnEnabled = false,
  floatingMessage = true,
  ...restProps
}: MultiSelectProps) => {
  const {
    onChange,
    options,
    value,
    isDisabled,
    error,
    message,
    errorMessagePlacement,
    ariaLabel,
  } = restProps;

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const multiSelectRef = useRef<SelectInstance | null>(null);

  const wasDisabled = usePrevious(isDisabled);
  const theme = useTheme();
  const messageColor = error && theme.colors.error;

  useEffect(() => {
    if (expandOnEnabled && not(isDisabled) && wasDisabled) {
      setMenuIsOpen(true);
      multiSelectRef.current.focus();
    }

    if (isDisabled) {
      setMenuIsOpen(false);
    }
  }, [isDisabled, expandOnEnabled]);

  const handleChange = (
    values: MultiSelectOption[],
    actionMeta: ActionMeta<MultiSelectOption>
  ) => {
    const { action, option, removedValue } = actionMeta;

    const isSelectAllOption =
      (removedValue && String(removedValue.label) === SELECT_ALL_LABEL) ||
      (option && String(option.label) === SELECT_ALL_LABEL);

    const { DeselectOption, SelectOption, RemoveValue } = RaSelectActionType;

    const withoutAllOptionValues = selectAllOption
      ? values.filter(option => option.value !== selectAllOptionItem.value)
      : values;

    if (selectAllOption && isSelectAllOption) {
      if (action === DeselectOption || action === RemoveValue) {
        onChange([], actionMeta);
      } else if (action === SelectOption) {
        // fixme options type is incompatible
        onChange(options as any, actionMeta);
      }
    } else {
      onChange(withoutAllOptionValues, actionMeta);
    }
  };

  const selectOptions = selectAllOption
    ? [selectAllOptionItem as any, ...options]
    : options;

  const selectAll =
    selectAllOption && options.length === (value as MultiSelectOption[]).length;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      (event.key === ButtonsCode.Enter || event.key === ButtonsCode.Space) &&
      not(menuIsOpen)
    ) {
      event.preventDefault();
      setMenuIsOpen(true);
    }

    if (event.key === ButtonsCode.Esc && menuIsOpen) {
      setMenuIsOpen(false);
      event.stopPropagation();
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Select
        isMulti
        ref={multiSelectRef}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onKeyDown={handleKeyDown}
        onMenuClose={() => setMenuIsOpen(false)}
        onMenuOpen={() => setMenuIsOpen(true)}
        menuIsOpen={menuIsOpen}
        aria-label={ariaLabel}
        {...restProps}
        options={selectOptions}
        components={{
          MultiValueRemove,
          DropdownIndicator,
          ClearIndicator,
          Option,
          ValueContainer,
          Input: SelectInput,
        }}
        value={
          selectAll
            ? (selectOptions as any) /* fixme selectOptions type is incompatible, maybe we should use value as array? */
            : value
        }
        onChange={handleChange}
        styles={getMultiSelectStyles({ error: restProps.error })}
        ariaLiveMessages={{
          guidance: () => '',
        }}
      />
      {Boolean(message) && (
        <Message
          floating={floatingMessage}
          className={ErrorMessageClassName}
          color={messageColor}
          placement={
            floatingMessage
              ? errorMessagePlacement
              : ErrorMessagePlacement.Bottom
          }
        >
          {message}
        </Message>
      )}
    </Box>
  );
};
