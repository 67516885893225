import { createSelector } from 'reselect';

import { getAgentMode, getContactItem } from 'contacts/contactsSelectors';
import { RootState } from 'rootReducer';
import { isEmpty } from 'shared/helpers/boolean';
import { getProfileData } from 'shared/selectors/profileSelector';
import { getNotificationCountLabel } from 'user/userHelpers';
import { UserRole } from 'user/userTypes';

export const getUser = ({ user }: RootState) => user;

export const getAgent = ({ user }: RootState) => user.agent;

export const getProfile = ({ user }: RootState) => user.profile;

export const getIsMobile = (state: RootState) => state.user.isMobile;

export const getUserId = createSelector([getProfileData], data =>
  data ? data.username : ''
);

export const getUserAgentId = createSelector([getProfileData], profileData =>
  profileData ? profileData.agentId : ''
);

export const getIsLogged = ({ user }: RootState) => user.isLogged;

export const getIsInitialized = ({ user }: RootState) => user.initialized;

export const getUserLocation = (state: RootState) => state.user.userLocation;

export const getDefaultAgent = (state: RootState) => state.user.defaultAgent;

export const getUserRole = (state: RootState) => state.user.role;

export const getRecommendedAgent = (state: RootState) =>
  state.user.recommendedAgent;

const getSavedSearchInvitationsCount = (state: RootState) =>
  state.user.savedSearchInvitationsCount;

const getCollectionsInvitationsCount = (state: RootState) =>
  state.user.collectionsInvitationsCount;

export const getNotificationHistoryCount = (state: RootState) =>
  state.user.notificationHistoryCount;

export const getIsAgent = createSelector(
  [getUserRole],
  userRole => userRole === UserRole.Agent
);

export const getIsClient = createSelector(
  [getUserRole],
  userRole => userRole === UserRole.Client
);

export const getIsUserInitialized = (state: RootState) => {
  return state.user.initialized;
};

export const isAuthorised = createSelector(
  [getIsLogged, getProfileData],
  (isLogged, profileData) => Boolean(isLogged && profileData)
);

export const hasOptedOutOfEmail = createSelector(
  [getAgentMode, getProfileData, getContactItem],
  (isAgentMode, user, contact) =>
    Boolean(
      isAgentMode ? contact?.hasOptedOutOfEmail : user?.hasOptedOutOfEmail
    )
);

export const isRequestSpecialistEnabled = createSelector(
  [getAgent, getUser],
  (agent, user) => {
    if (user.isLogged && isEmpty(agent)) {
      return false;
    }

    return agent.defaultAgent || agent.defaultAgent === undefined;
  }
);

export const getDefaultAgentId = createSelector(
  [getDefaultAgent],
  defaultAgent => (defaultAgent ? defaultAgent.username : null)
);

export const getIsUserAssignedToDefaultAgent = createSelector(
  [getUserAgentId, getDefaultAgentId],
  (userAgentId, defaultAgentId) => userAgentId && userAgentId === defaultAgentId
);

export const getUserFullName = ({ user }: RootState) =>
  user.profile.data
    ? `${user.profile.data.firstname || ''} ${
        user.profile.data.lastname || ''
      }`.trim()
    : '';

export const selectNotificationHistoryCount = createSelector(
  [getNotificationHistoryCount],
  (notificationHistoryCount): string => {
    return getNotificationCountLabel(notificationHistoryCount);
  }
);

export const selectSavedSearchInvitationsCount = createSelector(
  [getSavedSearchInvitationsCount],
  (savedSearchInvitationsCount): string => {
    return getNotificationCountLabel(savedSearchInvitationsCount);
  }
);

export const selectCollectionsInvitationsCount = createSelector(
  [getCollectionsInvitationsCount],
  (collectionsInvitationsCount): string => {
    return getNotificationCountLabel(collectionsInvitationsCount);
  }
);

export const selectNotificationsCount = createSelector(
  [
    getNotificationHistoryCount,
    getSavedSearchInvitationsCount,
    getCollectionsInvitationsCount,
  ],
  (
    notificationHistoryCount,
    savedSearchInvitationsCount,
    collectionsInvitationsCount
  ): string => {
    const notificationsCount =
      notificationHistoryCount +
      savedSearchInvitationsCount +
      collectionsInvitationsCount;

    return getNotificationCountLabel(notificationsCount);
  }
);

export const getShouldShowORSAgent = createSelector(
  getIsUserAssignedToDefaultAgent,
  getIsClient,
  (isUserAssignedToDefaultAgent, isClient) => {
    return isUserAssignedToDefaultAgent && isClient;
  }
);
