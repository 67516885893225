import { useField } from 'formik';
import styled, { css } from 'styled-components/macro';

import {
  daysOnOptions,
  MlsStatus,
  timeframeOptions,
} from 'filters/filtersContants';
import { $getTimeframeChanged } from 'filters/filtersSelectors';
import {
  FilterHeaderContainer,
  FilterHeaderTitle,
  FilterSectionChangedIndicator,
  FormSection,
  SelectContainer,
  SelectWrapper,
} from 'filters/filtersStyled';
import { SelectField } from 'shared/components/formikFields/SelectField';
import { not } from 'shared/helpers/boolean';
import { useSelector } from 'shared/helpers/redux';
import { useIsLeasePage } from 'shared/hooks/useIsLeasePage';
import { media } from 'styled-system/responsive';

const Container = styled(FormSection)`
  ${media.sm(css`
    flex-direction: column;
  `)};
`;

interface TimeframeFilterProps {
  disableFilter?: boolean;
}

export const TimeframeFilter = (props: TimeframeFilterProps) => {
  const isLeasePage = useIsLeasePage();

  const [mlsStatusField] = useField({ name: 'mlsStatus' });

  const timeframeChanged = useSelector($getTimeframeChanged);

  return (
    <Container>
      <FilterHeaderContainer>
        {timeframeChanged && <FilterSectionChangedIndicator />}
        <FilterHeaderTitle role="heading" aria-level={2} tabIndex={0}>
          Timeframe
        </FilterHeaderTitle>
      </FilterHeaderContainer>
      <SelectContainer>
        <SelectWrapper>
          <SelectField
            name="timeFrameFilters.daysOnMarket"
            isDisabled={props.disableFilter}
            labelText={isLeasePage ? 'Days on Market' : 'Listed Date'}
            options={daysOnOptions}
          />
        </SelectWrapper>

        <SelectWrapper>
          {isLeasePage ? (
            <SelectField
              name="timeFrameFilters.leasedTimeframe"
              placeholder=""
              labelText="Leased Timeframe"
              isDisabled={
                props.disableFilter ||
                not(mlsStatusField.value.includes(MlsStatus.Leased))
              }
              defaultValue={timeframeOptions[0]}
              options={timeframeOptions}
            />
          ) : (
            <SelectField
              name="timeFrameFilters.soldTimeframe"
              placeholder=""
              labelText="Sold Timeframe"
              isDisabled={
                props.disableFilter ||
                not(mlsStatusField.value.includes(MlsStatus.Sold))
              }
              defaultValue={timeframeOptions[0]}
              options={timeframeOptions}
            />
          )}
        </SelectWrapper>

        <SelectWrapper>
          <SelectField
            name="timeFrameFilters.lastPriceReduction"
            isDisabled={props.disableFilter}
            labelText="Last Price Reduction"
            options={daysOnOptions}
          />
        </SelectWrapper>
      </SelectContainer>
    </Container>
  );
};
