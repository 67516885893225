import React from 'react';
import styled, { css } from 'styled-components/macro';

import { useAriaLiveAnnouncerContext } from 'shared/components/AriaLiveAnnouncer/AriaLiveAnnouncerContext';
import Button from 'shared/components/Button/Button';

export const FilterButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const activeButtonStyles = css<FilterButtonProps>`
  & path {
    fill: ${({ theme }) => theme.white};
  }

  &,
  &:hover {
    color: ${({ theme }) => theme.white} !important;
    background-color: ${({ theme }) => theme.secondary} !important;
    border-color: ${({ theme }) => theme.secondary} !important;
  }

  &:hover:not(:disabled) {
    path {
      fill: ${({ theme }) => theme.secondaryLight};
    }
  }
`;

interface FilterButtonProps {
  isActive: boolean;
}

export const FilterButton = styled(Button)<{ isActive: boolean }>`
  width: calc(100% / 6);
  height: 40px;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.disabled};
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.border};
  font-size: 14px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 0 !important;
  margin-right: 0 !important;
  span {
    line-height: inherit;
    padding: 0;
  }

  &:not(:first-child) {
    border-left: none;
  }

  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.secondary};
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.secondary};
    box-shadow: none;
    width: 54px;
    margin-left: -1px;
    path {
      fill: ${({ theme }) => theme.secondary};
    }

    &:first-child {
      margin-left: 0;
      width: 53px;
    }
  }

  ${props => props.isActive && activeButtonStyles};
`;

interface Item {
  value: any;
  label?: string;
  icon?: React.ReactNode;
}

export interface FlatCollectionProps {
  value: any;
  onSelect?: (value: any) => void;
  items: Item[];
  disabled?: boolean;
}

const FlatCollection = (props: FlatCollectionProps) => {
  const { onSelect, items, value, disabled } = props;

  const ariaLiveAnnouncerCtx = useAriaLiveAnnouncerContext();

  return (
    <FilterButtonsContainer>
      {items.map(obj => (
        <FilterButton
          disabled={disabled}
          aria-label={obj.value}
          small
          key={obj.value}
          isActive={obj.value === value}
          onClick={() => {
            ariaLiveAnnouncerCtx.announce(`${obj.value} button selected`);
            onSelect?.(obj.value);
          }}
          icon={obj.icon}
        >
          {!!obj.label && obj.label}
        </FilterButton>
      ))}
    </FilterButtonsContainer>
  );
};

export default FlatCollection;
