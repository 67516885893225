import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { MlsStatus } from 'filters/filtersContants';
import { $getSourceFilters } from 'filters/filtersSelectors';
import { getIsLease } from 'listings/listingsHelpers';
import { getCurrentSavedSearch } from 'saved-search/savedSearchSelectors';
import { SavedSearch } from 'saved-search/savedSearchTypes';
import { groupByType } from 'search/helpers/autocomplete-helpers';
import { SearchService } from 'search/search-service';
import {
  autocompleteFailed,
  autocompleteLoaded,
  fetchAutocomplete,
} from 'search/search-slice';
import { GetHintsQueryParams } from 'search/search-types';
import { not } from 'shared/helpers/boolean';
import { UnpackReturnedPromise } from 'shared/types';

export function* fetchAutocompleteSaga(
  action: ReturnType<typeof fetchAutocomplete>
) {
  yield delay(500);
  const { mlsStatus, soldTimeframe } = yield select(({ filters }) => filters);
  const { exclusiveListingsEnabled } = yield select($getSourceFilters);
  const savedSearch: SavedSearch = yield select(getCurrentSavedSearch);
  const isLease = getIsLease({ savedSearch });

  try {
    const queryParams: GetHintsQueryParams = {
      query: action.payload.query,
      mlsStatus: String(mlsStatus),
      showExclusiveListings: not(isLease) && exclusiveListingsEnabled,
    };

    if (mlsStatus.includes(MlsStatus.Sold)) {
      queryParams.soldTimeframe = soldTimeframe;
    }

    type Response = UnpackReturnedPromise<typeof SearchService.getHints>;
    const response: Response = yield call(SearchService.getHints, queryParams);
    yield put(autocompleteLoaded({ items: groupByType(response) }));
  } catch (error) {
    yield put(autocompleteFailed(error.message));
  }
}

export function* autocompleteSagas() {
  yield all([takeLatest(fetchAutocomplete, fetchAutocompleteSaga)]);
}
