import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar_plugin from 'dayjs/plugin/calendar';
import customDateformat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

export const fromNow = (date: string) => {
  dayjs.extend(relativeTime);

  return dayjs(date).fromNow();
};

export const getIsToday = (date: Date | string) => {
  dayjs.extend(isToday);

  return date ? dayjs(date).isToday() : false;
};

export const getIsDateInPast = (date: Date | string) =>
  date ? dayjs().isAfter(dayjs(date)) : false;

export const isTodayOrAfterDate = (date: Date | string) => {
  return getIsToday(date) || getIsDateInPast(date);
};

export const formatCurrentDate = (formatPattern?: string) => {
  dayjs.extend(advancedFormat);

  return dayjs(new Date()).format(formatPattern);
};

export const formatDate = (
  date: string,
  formatPattern: string,
  keepLocalTime = true
) => {
  dayjs.extend(utc);

  return dayjs(date).utc(keepLocalTime).format(formatPattern);
};

export const formatByUTC = () => {
  dayjs.extend(utc);

  return dayjs(new Date()).utc().format();
};

export const calendar = (
  calendarData: { [key: string]: string },
  date: string
) => {
  dayjs.extend(calendar_plugin);
  dayjs.extend(advancedFormat);

  return dayjs(date).calendar(null, calendarData);
};

export const dateSetter = (
  date: Date,
  patternObject: { [key: string]: number }
) => {
  dayjs.extend(objectSupport);

  return dayjs(date).set(patternObject);
};

export const formatCustomDate = (date: string, pattern: string) => {
  dayjs.extend(customDateformat);

  return dayjs(date, pattern);
};

export const covertStringToUTCDate = (date?: string): Date | null => {
  dayjs.extend(utc);

  return date
    ? // convert to YYYY-MM-DD format, because "to utc" works incorrectly for YYYY-MM-DDT00:00:00.000Z format
      dayjs(date.replace(/T.*$/g, '')).utc(false).toDate()
    : null;
};
