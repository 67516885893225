import { useEffect } from 'react';
import ScrollLock from 'react-scrolllock';
import styled, { useTheme } from 'styled-components/macro';

import ThreeDotsLoader from 'shared/components/loaders/ThreeDotsLoader';
import { zIndex } from 'shared/styles/z-indexes';

const FullScreen = styled.div`
  top: 0;
  bottom: 0;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.colors.white};
  z-index: ${zIndex.HEADER + 1};
`;

export const FullScreenLoader = () => {
  useEffect(() => void window.scrollTo(0, 0), []);
  return (
    <ScrollLock>
      <FullScreen data-testid="full-screen-loader">
        <ThreeDotsLoader color={useTheme().colors.blue} size={8} />
      </FullScreen>
    </ScrollLock>
  );
};
