import { Placement } from '@popperjs/core/lib/enums';
import { Options } from '@popperjs/core/lib/modifiers/offset';
import { useState } from 'react';
import { usePopper } from 'react-popper';

export type PopperPopupUtils = ReturnType<typeof usePopper> & {
  refs: {
    setAnchor: (ref: any) => void;
    setArrow: (ref: any) => void;
    setPopup: (ref: any) => void;
  };
};

export const usePopperPopup = (
  options: {
    offset?: Options['offset'];
    placement?: Placement;
  } = {}
): PopperPopupUtils => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [arrow, setArrow] = useState<HTMLElement | null>(null);
  const [popup, setPopup] = useState<HTMLElement | null>(null);

  const { placement = 'bottom', offset = [0, 0] } = options;

  const popper = usePopper(anchor, popup, {
    placement,
    modifiers: [
      { name: 'arrow' as const, options: { element: arrow } },
      {
        name: 'offset' as const,
        options: { offset } as Options,
      },
    ],
  });

  return { ...popper, refs: { setAnchor, setArrow, setPopup } };
};
