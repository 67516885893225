import React, { useContext } from 'react';

export type Role = 'alert' | 'log';

interface AriaLiveAnnouncerType {
  announce: (message: string, role?: Role) => void;
}

const defaultContext: AriaLiveAnnouncerType = {
  announce: () => {},
};

export const AriaLiveAnnouncerContext =
  React.createContext<AriaLiveAnnouncerType>(defaultContext);

export const useAriaLiveAnnouncerContext = () =>
  useContext(AriaLiveAnnouncerContext);
