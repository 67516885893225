import { not } from 'shared/helpers/boolean';

export const parseImgSrc = (imgSrc?: string): string => {
  if (not(imgSrc)) {
    return '';
  }

  return imgSrc.includes('<img')
    ? imgSrc.match(/<img[^>]+src="([^">]+)"/)[1]
    : imgSrc;
};

export const getInitials = (...initials: string[]): string => {
  return initials
    .filter(str => typeof str === 'string' && str !== '')
    .map(str => str[0].toUpperCase())
    .join('');
};
