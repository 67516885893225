import queryString from 'query-string';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  AgentReferMeAction,
  AgentReviewMeAction,
  AgentsActionType,
  changeAgentBioListingsPage,
  ChangeAgentsPageAction,
  ContactAgentAction,
  contactAgentFail,
  fetchAgentBio,
  FetchAgentBioAction,
  fetchAgentBioFail,
  fetchAgentBioRequest,
  fetchAgentBioReviews,
  fetchAgentBioReviewsFail,
  fetchAgentBioReviewsSuccess,
  fetchAgentBioSuccess,
  fetchAgents,
  FetchAgentsAction,
  fetchAgentsFail,
  fetchAgentsRequest,
  fetchAgentsSuccess,
  FetchAgentSuggestionsAction,
  fetchAgentSuggestionsFail,
  fetchAgentSuggestionsSuccess,
  FilterAgentsAction,
  FilterAgentsByLanguageAction,
  InitAgentBioAndListAction,
  ReassignAgentAction,
  ResetAgentsAction,
  ResetAgentsLanguagesFilterAction,
  setAgentsDataFromUrl,
  SetAgentsFromUrlAction,
  SortAgentsAction,
} from 'agents/agentsActions';
import { AGENTS_PER_PAGE, sfIdRegExp } from 'agents/agentsConstants';
import {
  getAgentSuggestions,
  getLanguagesFilterLabels,
  isAgentBioPage,
  parseAgentsUrl,
  prepareAgentsUrl,
} from 'agents/agentsHelpers';
import { getDataForUpdateUrl } from 'agents/agentsSelectors';
import { AgentsService } from 'agents/AgentsService';
import { Agent, AgentsState } from 'agents/agentsTypes';
import { TypesOfInquiryHistory } from 'history/inquiryHistory/inquiryHistoryTypes';
import {
  fetchListings,
  ListingsActionType,
  SetListingsFromUrlAction,
  switchOffOurListingsFirst,
} from 'listings/listingsActions';
import { getListingsPage } from 'listings/listingsSelectors';
import { TypesOfMap } from 'map/mapConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { not } from 'shared/helpers/boolean';
import { mapServerValidationErrors } from 'shared/helpers/errorHelpers';
import { result } from 'shared/helpers/fp/result';
import { showErrorToast, showSuccessToast } from 'shared/helpers/notifications';
import { isNotEmpty } from 'shared/helpers/validators';
import history from 'shared/services/history';
import { FromAwaited, UnpackReturnedPromise } from 'shared/types';
import { fetchAgentsDirectory } from 'strapi/strapi-service';
import { changeUserAgent } from 'user/userActions';
import { getInquiryTrackingInfo } from 'user/userHelpers';
import { UserService } from 'user/UserService';

const fetchAgentsDirectoryWrapper = result.makeWrapper(fetchAgentsDirectory);

export function* fetchAgentsSaga(action: FetchAgentSagaAction) {
  const { page, sortBy, sortType, query, limit, languagesFilter }: AgentsState =
    yield select(({ agents }) => agents);

  let allAgents = false;

  if (action.type === AgentsActionType.FETCH_AGENTS) {
    allAgents = action.all;
  }

  try {
    yield put(fetchAgentsRequest());

    type Result = [
      FromAwaited<ReturnType<typeof AgentsService.fetchAgents>>,
      FromAwaited<ReturnType<typeof fetchAgentsDirectoryWrapper>>
    ];

    const labels = getLanguagesFilterLabels(languagesFilter);

    const [agents, agentsDirectoryPage]: Result = yield all([
      call(AgentsService.fetchAgents, {
        sortBy,
        sortType,
        query,
        page,
        limit: allAgents ? limit : AGENTS_PER_PAGE,
        language: labels.length > 0 ? labels : [],
      }),
      call(fetchAgentsDirectoryWrapper),
    ]);

    const agentDirectoryFields = result.getOrElse(
      result.map(agentsDirectoryPage, page => page),
      () => ({})
    );

    yield put(fetchAgentsSuccess(agents, agentDirectoryFields));
  } catch (e) {
    yield put(fetchAgentsFail(e.message));
  }
}

export function* updateAgentUrlSaga(action: FetchAgentSagaAction): Saga {
  if (history.location.pathname !== RoutePath.AGENTS) {
    return;
  }

  const queryData: any = yield select(getDataForUpdateUrl);
  const agentQuery = prepareAgentsUrl(queryData);
  const agentQuerySearch = agentQuery.search ? `${agentQuery.search}` : '';

  if (isNotEmpty(history.location.search) || isNotEmpty(agentQuery.search)) {
    history.push({
      pathname: RoutePath.AGENTS,
      search: agentQuerySearch,
      state: { forceUrlUpdate: true },
    });
  }
}

export function* setAgentsFromUrlSaga(action: SetAgentsFromUrlAction): Saga {
  const parsedUrlData: ReturnType<typeof parseAgentsUrl> = yield call(
    parseAgentsUrl
  );

  yield put(setAgentsDataFromUrl(parsedUrlData));
  yield put(fetchAgents());
}

export function* fetchAgentSuggestionsSaga(
  action: FetchAgentSuggestionsAction
) {
  try {
    const agents: UnpackReturnedPromise<typeof AgentsService.fetchAgents> =
      yield call(AgentsService.fetchAgents, {
        limit: 0,
        query: action.query,
        hideTeamMembers: action.hideTeamMembers,
      });

    const suggestions = getAgentSuggestions(agents.hits);
    yield put(fetchAgentSuggestionsSuccess(suggestions));
  } catch (e) {
    yield put(fetchAgentSuggestionsFail(e.message));
  }
}

export function* fetchAgentBioSaga(action: FetchAgentBioAction) {
  const { name, sfId } = action;
  let agent: Agent;

  function updateUrlWithAgentName(agent: Agent) {
    if (sfIdRegExp.test(history.location.pathname)) {
      const pathname = history.location.pathname.replace(
        sfIdRegExp,
        `${agent.firstname}-${agent.lastname}`
      );
      history.replace(pathname);
    }
  }

  try {
    const param = sfId ? sfId : name;
    yield put(fetchAgentBioRequest());

    // in case when we fetch agent for listing details we dont need to redirect on 404 error, because it possible for p and t links
    agent = yield call(AgentsService.fetchAgent, {
      agentId: param,
      redirectToNotFound: false,
    });

    updateUrlWithAgentName(agent);

    yield put(fetchAgentBioSuccess(agent));

    if (agent.reviewCount > 0) {
      yield put(fetchAgentBioReviews());
    }
  } catch (e) {
    yield put(fetchAgentBioFail(e.message));
  }
}

export function* fetchAgentBioReviewsSaga() {
  const { username }: Agent = yield select(({ agents }) => agents.agentBio);
  try {
    const reviews: UnpackReturnedPromise<
      typeof AgentsService.fetchAgentReviews
    > = yield call(AgentsService.fetchAgentReviews, username);
    yield put(fetchAgentBioReviewsSuccess(reviews));
  } catch (e) {
    yield put(fetchAgentBioReviewsFail(e.message));
  }
}

export function* contactAgentSaga(action: ContactAgentAction) {
  try {
    const { data, onSuccess, agentId, landingPage } = action.body;
    const trackingInfo = getInquiryTrackingInfo();

    yield call(UserService.inquiry, {
      ...data,
      ...trackingInfo,
      landingPage,
      agentId,
      type: TypesOfInquiryHistory.BioInquiry,
    });

    onSuccess();

    showSuccessToast('Message to the agent sent');
  } catch (error) {
    const { onError } = action.body;
    if (error?.validationErrors) {
      onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
    yield put(contactAgentFail(error));
  }

  action.body.cb();
}

export function* agentReferMeSaga(action: AgentReferMeAction) {
  try {
    const { data, onSuccess, agentId, landingPage } = action.body;
    const trackingInfo = getInquiryTrackingInfo();

    yield call(UserService.inquiry, {
      ...data,
      ...trackingInfo,
      landingPage,
      agentId,
      type: TypesOfInquiryHistory.ReferMe,
    });

    onSuccess();

    showSuccessToast('Referral Sent');
  } catch (error) {
    const { onError } = action.body;
    if (error?.validationErrors) {
      onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
    yield put(contactAgentFail(error));
  }

  action.body.cb();
}

export function* agentReviewMeSaga(action: AgentReviewMeAction) {
  try {
    const { data, onSuccess, agentId, landingPage } = action.body;
    const trackingInfo = getInquiryTrackingInfo();

    yield call(UserService.inquiry, {
      ...data,
      ...trackingInfo,
      landingPage,
      agentId,
      type: TypesOfInquiryHistory.ReviewMe,
    });

    onSuccess();

    showSuccessToast('Review Sent');
  } catch (error) {
    const { onError } = action.body;
    if (error?.validationErrors) {
      onError(mapServerValidationErrors(error.validationErrors));
    } else {
      showErrorToast(error?.message || String(error));
    }
    yield put(contactAgentFail(error));
  }

  action.body.cb();
}

export function* reassignAgentSaga(action: ReassignAgentAction) {
  try {
    yield call(AgentsService.reassignAgent, action.agentId, action.leadSource);
    yield put(changeUserAgent(action.agentId));
  } catch (e) {
    yield put(contactAgentFail(e.message));
  }
}

export function* initAgentBioAndListSaga(action: InitAgentBioAndListAction) {
  try {
    yield put(switchOffOurListingsFirst());
    yield* fetchAgentBioSaga(
      fetchAgentBio({
        name: action.name,
      })
    );
  } catch {
    // nothing needed beacause others saga has own error handlers
  }
}

export function* updateAgentBioUrlSaga() {
  if (not(isAgentBioPage())) {
    return;
  }

  try {
    const parsedQuery = queryString.parse(history.location.search);
    const listingsPage: number = yield select(getListingsPage);
    const pathname = history.location.pathname;

    if (Number(parsedQuery.page) !== listingsPage) {
      history.push({
        pathname: pathname,
        search:
          not(listingsPage) || listingsPage === 1 ? '' : `page=${listingsPage}`,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* setAgentBioUrlSaga(action: SetListingsFromUrlAction) {
  if (not(isAgentBioPage())) {
    return;
  }

  try {
    const parsedQuery = queryString.parse(action.location.search);

    const page = Number(parsedQuery.page);

    yield put(changeAgentBioListingsPage(page ? page : 1));

    yield put(fetchListings(TypesOfMap.BioPage));
  } catch (error) {
    console.error(error);
  }
}

export type FetchAgentSagaAction =
  | FetchAgentsAction
  | SortAgentsAction
  | ChangeAgentsPageAction
  | FilterAgentsAction
  | FilterAgentsByLanguageAction
  | ResetAgentsAction
  | ResetAgentsLanguagesFilterAction;

const fetchAgentsAndUpdateUrlSub = () =>
  takeLatest(
    [
      AgentsActionType.SORT_AGENTS,
      AgentsActionType.CHANGE_AGENTS_PAGE,
      AgentsActionType.FILTER_AGENTS,
      AgentsActionType.FILTER_AGENTS_BY_LANGUAGE,
      AgentsActionType.RESET_AGENTS_LANGUAGE_FILTERS,
      AgentsActionType.RESET_AGENTS,
    ],
    function* (action) {
      yield* fetchAgentsSaga(action);
      yield* updateAgentUrlSaga(action);
    }
  );

const fetchAgentsSub = () =>
  takeLatest([AgentsActionType.FETCH_AGENTS], fetchAgentsSaga);

const fetchAgentBioSub = () => {
  return takeLatest(AgentsActionType.FETCH_AGENT_BIO, fetchAgentBioSaga);
};

const fetchAgentBioReviewsSub = () => {
  return takeLatest(
    AgentsActionType.FETCH_AGENT_BIO_REVIEWS,
    fetchAgentBioReviewsSaga
  );
};

const initBioPageSub = () => {
  return takeLatest(
    AgentsActionType.INIT_AGENT_BIO_AND_LIST,
    initAgentBioAndListSaga
  );
};

const contactAgentSub = () => {
  return takeLatest(AgentsActionType.CONTACT_AGENT, contactAgentSaga);
};

const agentReferMeSub = () => {
  return takeLatest(AgentsActionType.AGENT_REFER_ME, agentReferMeSaga);
};

const agentReviewMeSub = () => {
  return takeLatest(AgentsActionType.AGENT_REVIEW_ME, agentReviewMeSaga);
};

const fetchAgentSuggestionsSub = () => {
  return takeLatest(
    AgentsActionType.FETCH_AGENT_SUGGESTIONS,
    fetchAgentSuggestionsSaga
  );
};

const reassignAgentSub = () => {
  return takeLatest(AgentsActionType.REASSIGN_AGENT, reassignAgentSaga);
};

const setAgentFromUrlSub = () => {
  return takeLatest(AgentsActionType.SET_AGENTS_FROM_URL, setAgentsFromUrlSaga);
};

const updateAgentBioUrlSub = () => {
  return takeEvery(
    [ListingsActionType.CHANGE_LISTINGS_PAGE, ListingsActionType.SORT_LISTINGS],
    updateAgentBioUrlSaga
  );
};

const setAgentBioFromUrlSub = () => {
  return takeEvery(
    ListingsActionType.SET_LISTINGS_FROM_URL,
    setAgentBioUrlSaga
  );
};

export function* agentsSagas() {
  yield all([
    fetchAgentsSub(),
    fetchAgentsAndUpdateUrlSub(),
    fetchAgentSuggestionsSub(),
    fetchAgentBioSub(),
    contactAgentSub(),
    agentReferMeSub(),
    agentReviewMeSub(),
    fetchAgentBioReviewsSub(),
    initBioPageSub(),
    reassignAgentSub(),
    setAgentFromUrlSub(),
    updateAgentBioUrlSub(),
    setAgentBioFromUrlSub(),
  ]);
}
