import { createSelector } from 'reselect';

import { getAgentMode } from 'contacts/contactsSelectors';
import { RootState } from 'rootReducer';
import { not } from 'shared/helpers/boolean';
import { getProfile } from 'user/userSelectors';

import { isNotRawCollection, isRawCollection } from './collectionsHelpers';

export const getCollectionId = ({ collections }: RootState) =>
  collections.collection.id;
export const getCollectionsToRemove = ({ collections }: RootState) =>
  collections.collectionsToRemove;
export const getCollections = ({ collections }: RootState) =>
  collections.collections;
export const getCollection = ({ collections }: RootState) =>
  collections.collection;

export const getIsRawCollection = createSelector([getCollection], collection =>
  isRawCollection(collection)
);

export const getExistingCollections = createSelector(
  [getCollections, getCollectionsToRemove],
  (collections, collectionsToRemove) =>
    collections.filter(collection =>
      not(collectionsToRemove.includes(collection.id))
    )
);

export const isCollectionOwner = createSelector(
  [getCollection, getAgentMode, getProfile],
  (collection, agentMode, profile) => {
    if (isNotRawCollection(collection)) {
      return false;
    }

    return (
      Boolean(collection.user) &&
      Boolean(profile.data) &&
      ((agentMode && profile.data?.username === collection.user?.agentId) ||
        (!agentMode &&
          profile.data &&
          profile.data.username === collection.user?.username))
    );
  }
);
