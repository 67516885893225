import {
  Contact,
  UpdateContactNotificationsSettingsBody,
} from 'contacts/contactsTypes';
import { SortOrder } from 'shared/constants/appConstants';
import { ApiService } from 'shared/services/ApiService';
import { BaseResponse, Paginated } from 'shared/types';

const BASE_URL = '/agent';

export class ContactsService {
  static async fetchContacts(query: {
    page: number;
    sortBy: string;
    sortType: SortOrder;
    query: string;
  }) {
    return ApiService.request<Paginated<Contact>>({
      method: 'GET',
      path: `${BASE_URL}/users`,
      query,
    });
  }

  static async fetchContact(id: string) {
    return ApiService.request<Contact>({
      method: 'GET',
      path: `${BASE_URL}/user/${id}`,
    });
  }

  static async updateContact(
    id: string,
    contactData: Partial<Contact>
  ): Promise<Contact> {
    const response = await ApiService.request<BaseResponse<Contact>>({
      method: 'PATCH',
      path: `${BASE_URL}/user/${id}`,
      data: contactData,
    });
    return response.data;
  }

  static async updateUserOptOutStatus(
    userId: string,
    hasOptedOutOfEmail: boolean
  ) {
    return ApiService.request<Contact>({
      method: 'PATCH',
      path: `${BASE_URL}/${userId}/notifications`,
      data: { hasOptedOutOfEmail },
    });
  }

  static async updateContactNotificationsSettings(
    reqBody: UpdateContactNotificationsSettingsBody
  ): Promise<Partial<Contact>> {
    const { userId, ...data } = reqBody;
    const response = await ApiService.request<BaseResponse<Partial<Contact>>>({
      method: 'PATCH',
      path: `${BASE_URL}/${userId}/notifications`,
      data,
    });

    return response.data;
  }
}
