import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';

import { BackToTopContainer } from 'shared/components/Modal/ModalStyled';
import ScrollToTopButton from 'shared/components/ScrollToTopButton/ScrollToTopButton';
import {
  ButtonsCode,
  SCREENS_COUNT_OFFSET_FOR_SCROLL_TO_TOP,
} from 'shared/constants/appConstants';
import { not } from 'shared/helpers/boolean';
import { useIsMobile } from 'styled-system/responsive';

interface ScrollToTopModalProps {
  scrollableNode: HTMLDivElement | null;
}

const ScrollToTopModal: React.FC<ScrollToTopModalProps> = props => {
  const { scrollableNode } = props;
  const [showBackToTop, setShowBackToTop] = useState(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (not(scrollableNode)) {
      return void 0;
    }
    scrollableNode.addEventListener('scroll', handleScroll);
    return () => scrollableNode.removeEventListener('scroll', handleScroll);
  }, [scrollableNode]);

  const handleScroll = throttle(() => {
    if (not(scrollableNode)) {
      return void 0;
    }

    const scrolledPX = scrollableNode.scrollTop;
    const currentWindow = scrollableNode.offsetHeight;

    if (scrolledPX >= currentWindow * SCREENS_COUNT_OFFSET_FOR_SCROLL_TO_TOP) {
      setShowBackToTop(true);
    }
    if (scrolledPX < currentWindow * SCREENS_COUNT_OFFSET_FOR_SCROLL_TO_TOP) {
      setShowBackToTop(false);
    }
  }, 300);

  const handleBackToTopClick = () => {
    scrollableNode?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === ButtonsCode.Enter) {
      handleBackToTopClick();
    }
  };

  return (
    <BackToTopContainer
      isMobile={isMobile}
      visible={showBackToTop}
      hasAnimation
    >
      <ScrollToTopButton
        isMobile={isMobile}
        onButtonClick={handleBackToTopClick}
        handleKeyDown={handleKeyDown}
      />
    </BackToTopContainer>
  );
};

export default ScrollToTopModal;
