import styled from 'styled-components/macro';

import { media } from 'styled-system/responsive';

export const PopupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 15px 17px;
  width: 360px;
  background-color: ${({ theme }) => theme.white};
  pointer-events: all;

  ${media.sm`
    flex-direction: column;
    width: 267px;
  `}
`;

export const PopupIcon = styled.div`
  ${media.sm`
    svg {
      width: 40px;
      height: 40px;
    }
  `}
`;

export const PopupMessage = styled.strong`
  display: block;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
`;

export const PopupContent = styled.div`
  width: 232px;
`;

export const Button = styled.button`
  width: 232px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1px;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;

export const CloseButton = styled.button`
  background-color: ${({ theme }) => theme.white};
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
  border: none;

  svg {
    width: 17px;
    height: 17px;
    fill: ${({ theme }) => theme.text3};
  }

  &:hover {
    cursor: pointer;
  }

  ${media.sm`
    position: absolute;
    right: 15px;
    padding: 0 !important;
  `}
`;
