import { PayloadAction } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';

import { RootState } from 'rootReducer';

/**
 * Creates a reducer for an effect with payload of type T,
 * that will be handled outside of createSlice (e.g. in redux-saga)
 *
 * @example
 * createSlice({
 *   reducers: {
 *     // without payload, e.g. fetchUsers()
 *     fetchUsers: effect<void>(),
 *     // with payload, e.g. fetchUser({id: 'whatever'})
 *     fetchUser: effect<{id: string}>(),
 *   }
 * })
 */
export const effect =
  <T>() =>
  (_state: any, _action: PayloadAction<T>) => {};

export enum Status {
  failed = 'failed',
  initial = 'initial',
  loading = 'loading',
  reloading = 'reloading',
  succeeded = 'succeeded',
}

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
