import { forEach, get } from 'lodash';
import memoizeOne from 'memoize-one';
import { generatePath } from 'react-router-dom';

import {
  BreadcrumbItem,
  Breadcrumbs,
} from 'listingDetails/components/AddressBar/Breadcrumb';
import { OpenHouseBarListing } from 'listingDetails/components/OpenHouseBar/OpenHouseBarTypes';
import { filterListingDetailsData } from 'listingDetails/components/PropertyDetailsInfoSection/propertyDetailsInfoSectionHelpers';
import { getLinkFromListingPolygon } from 'listingDetails/components/PropertyList/helpers/getLinkFromListingPolygon';
import {
  FeaturesAndFactsSectionValueType,
  ListingDetailsSection,
  ListingDetailsSubSection,
  ListingImage,
  ListingWithDetails,
  PrimaryLabelData,
  PropertyDetailsLabels,
  SubSectionValue,
} from 'listingDetails/listingDetailsTypes';
import { PropertyType } from 'listings/listingsTypes';
import { ExclusiveListing } from 'myListings/myListingsTypes';
import { GalleryImageItem } from 'shared/components/Gallery/Gallery';
import { getListingDetailsPageTitle } from 'shared/components/Metadata/metadataHelpers';
import { AGENT_TIP_HEIGHT } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import {
  isEmpty,
  isNil,
  notEmpty as isNotEmpty,
  not,
} from 'shared/helpers/boolean';
import history from 'shared/services/history';
import { PlaceType } from 'shared/types/placesAndPolygons';
import { UserProfile } from 'user/userTypes';

export interface ListingsUrlProps {
  agentMode?: boolean;
  contactId?: string;
  fromLeased?: boolean;
}

export const getListingsUrl = (params: ListingsUrlProps = {}): string => {
  const { agentMode = false, contactId, fromLeased } = params;

  if (fromLeased) {
    return agentMode && contactId
      ? generatePath(RoutePath.AGENT_LISTINGS_LEASE, { contactId })
      : generatePath(RoutePath.LEASE);
  }
  return agentMode && contactId
    ? generatePath(RoutePath.AGENT_LISTINGS, { contactId })
    : generatePath(RoutePath.LISTINGS);
};

export interface GetListingDetailsBreadcrumbsProps {
  listing: ListingWithDetails;
  agentMode: boolean;
  contactName: string;
  contactId: string;
}

const placeBreadcrumbs: PlaceType[] = [
  PlaceType.City,
  PlaceType.PostalCode,
  PlaceType.Subdivision,
];

export function getListingDetailsBreadcrumbs(props: {
  listing: ListingWithDetails;
  agentMode?: boolean;
  contactName: string;
  contactId?: string;
}): Breadcrumbs {
  const listingsUrl = getListingsUrl({
    agentMode: props.agentMode,
    contactId: props.contactId,
    fromLeased: props.listing.propertyType === PropertyType.ResidentialLease,
  });

  const breadcrumbs: Breadcrumbs = [];

  for (const place of placeBreadcrumbs) {
    const link = getLinkFromListingPolygon(listingsUrl, props.listing, place);
    if (link.label && link.url) {
      breadcrumbs.push({ value: link.label, url: link.url });
    }
  }

  if (props.listing.address) {
    breadcrumbs.push({ value: props.listing.address, url: '#' });
  }

  if (props.agentMode && props.contactId) {
    const contactUrl = generatePath(RoutePath.AGENT_CONTACT_BASIC_INFO, {
      contactId: props.contactId,
    });
    const agentBreadcrumbItem: BreadcrumbItem = {
      value: 'My Contacts',
      url: RoutePath.AGENT_CONTACTS,
    };
    const agentContactBreadcrumbItem: BreadcrumbItem = {
      value: props.contactName,
      url: contactUrl,
    };

    return [agentBreadcrumbItem, agentContactBreadcrumbItem, ...breadcrumbs];
  }

  return breadcrumbs;
}

export interface RemoveEmptyFieldsProps<T> {
  listingDetailsSubSection: ListingDetailsSection<T>;
  showWithLabels?: string[];
}

export const removeEmptyFields = <T extends string>(
  params: RemoveEmptyFieldsProps<T>,
  // Temporary fix https://ra-next-jira.atlassian.net/browse/RAD-3771
  withMls?: boolean
): Array<ListingDetailsSubSection<T>> => {
  const { listingDetailsSubSection, showWithLabels } = params;

  if (
    isEmpty(listingDetailsSubSection) ||
    isEmpty(listingDetailsSubSection.subSections)
  ) {
    return [];
  }

  const subSections = listingDetailsSubSection.subSections.map(item => {
    const { label, values } = item;
    const toShowWithLabel = showWithLabels && showWithLabels.includes(label);

    if (toShowWithLabel) {
      // Temporary fix https://ra-next-jira.atlassian.net/browse/RAD-3771
      if (not(withMls)) {
        return {
          ...item,
          values: values.filter(value => value.type !== 'mlsArea'),
        };
      }
      return { ...item, values };
    }

    return {
      ...item,
      values: values.filter(value => Boolean(value.desc)),
    };
  });

  return subSections.filter(item => Boolean(item.values.length));
};

export const prepareListingDetailsData = (listing: ListingWithDetails) => {
  const fieldsToMap: Array<Partial<keyof ListingWithDetails>> = [
    'interiorFeatures',
    'buildingInfo',
    'propertyTaxes',
    'hoa',
    'rentalInfo',
  ];

  const withMls = listing.polygons
    ? Object.keys(listing.polygons).includes('mlsarea')
    : false;

  forEach(listing, (value, key) => {
    // Temporary fix https://ra-next-jira.atlassian.net/browse/RAD-3771
    if (fieldsToMap.includes(key as keyof ListingWithDetails)) {
      (listing[key as keyof ListingWithDetails] as Array<
        ListingDetailsSubSection<string>
      >) = removeEmptyFields({
        listingDetailsSubSection: value as ListingDetailsSection<string>,
      });
    }

    if (key === 'propertyDetails') {
      const listingDetailsData = removeEmptyFields(
        {
          listingDetailsSubSection: value as ListingDetailsSection<string>,
          showWithLabels: [PropertyDetailsLabels.Location],
          // Temporary fix https://ra-next-jira.atlassian.net/browse/RAD-3771
        },
        withMls
      );
      listing[key] = filterListingDetailsData(
        listingDetailsData,
        listing.propertyType
      );
    }

    if (key === 'financial') {
      const listingDetailsData = removeEmptyFields({
        listingDetailsSubSection: value as ListingDetailsSection<string>,
      });
      listing[key] = filterListingDetailsData(
        listingDetailsData,
        listing.propertyType
      );
    }

    if (key === 'features') {
      listing[key] = getListingDetailsFeatures(
        value as ListingDetailsSection<FeaturesAndFactsSectionValueType>
      ).map(feature =>
        feature.type === FeaturesAndFactsSectionValueType.propertyType
          ? {
              ...feature,
              desc:
                listing.propertyfiltertype?.replace(' Lease', '') ||
                feature.desc,
            }
          : feature
      );
    }
  });

  return listing;
};

export const prepareGalleryData = (
  images: ListingImage[]
): GalleryImageItem[] => {
  return images.map(
    ({ mediaurl, diffimgsizes, height, width }: ListingImage, index) => ({
      diffimgsizes,
      height,
      index,
      original: mediaurl,
      thumbnail: mediaurl,
      width,
    })
  );
};

export interface ListingDetailModalData {
  isOpen: boolean;
  label: string;
  initialFirstName: string;
  initialLastName: string;
  initialPhone: string;
  initialEmail: string;
  listing: ListingWithDetails;
}

export interface GetListingDetailModalDataProps {
  label: string;
  profileData?: UserProfile;
  listing: ListingWithDetails;
  images: ListingImage[];
}

export const getListingDetailModalData = (
  props: GetListingDetailModalDataProps
): ListingDetailModalData => {
  const { listing, profileData, images, label } = props;

  return {
    isOpen: true,
    label,
    initialFirstName: profileData ? profileData.firstname : '',
    initialLastName: profileData ? profileData.lastname : '',
    initialPhone: profileData ? profileData.phone : '',
    initialEmail: profileData ? profileData.email : '',
    listing: {
      ...listing,
      images,
    },
  };
};

export interface GetOffsetProps {
  isMobile: boolean;
  agentMode: boolean;
}

export const STICKY_TOP_HEIGHT = 64;
export const STICKY_TOP_HEIGHT_AGENT = STICKY_TOP_HEIGHT + AGENT_TIP_HEIGHT;
export const MOBILE_STICKY_TOP_HEIGHT = 48;
export const MOBILE_STICKY_TOP_HEIGHT_AGENT =
  STICKY_TOP_HEIGHT + AGENT_TIP_HEIGHT;

export const getOffset = (props: GetOffsetProps): number => {
  const { agentMode, isMobile } = props;

  if (isMobile) {
    return agentMode
      ? MOBILE_STICKY_TOP_HEIGHT_AGENT
      : MOBILE_STICKY_TOP_HEIGHT;
  }

  return agentMode ? STICKY_TOP_HEIGHT_AGENT : STICKY_TOP_HEIGHT;
};

export const getGalleryImages = (
  images: ListingImage[],
  address: string
): ListingImage[] => {
  return images.map(
    ({
      diffimgsizes,
      mediaurl,
      width,
      height,
      shortdescription,
      mediaobjectid,
    }) => ({
      description: address,
      diffimgsizes,
      height,
      mediaurl,
      width,
      shortdescription,
      mediaobjectid,
    })
  );
};

export const getListingDetailsFeatures = (
  features: ListingDetailsSection<FeaturesAndFactsSectionValueType>
): Array<SubSectionValue<FeaturesAndFactsSectionValueType>> => {
  if (isEmpty(features) || isEmpty(features.subSections)) {
    return [];
  }

  return features.subSections[0].values;
};

export const getListingImageURL = (images: ListingImage[]): string => {
  if (isEmpty(images)) {
    return '';
  }

  return images[0]?.mediaurl;
};

export const memoizedGetListingDetailsPageTitle = memoizeOne(
  getListingDetailsPageTitle
);

export const _getPropertyValues = ({
  listing,
  accessors,
  notEmpty = true,
  propertyOptions = {},
}: Pick<
  GetPropertiesFromListingParams,
  'listing' | 'accessors' | 'notEmpty' | 'propertyOptions'
>): Array<SubSectionValue<string>> => {
  const values: Array<SubSectionValue<string>> = [];

  forEach(accessors, (label, accessor) => {
    if (notEmpty && isNil(listing[accessor as keyof ListingWithDetails])) {
      return;
    }
    values.push({
      title: label || '',
      desc: String(listing[accessor as keyof ListingWithDetails]),
      type: accessor,
      ...propertyOptions[accessor as keyof ListingWithDetails],
    });
  });

  return values;
};

interface GetPropertiesFromListingParams {
  label: string;
  listing: ListingWithDetails;
  accessors: { [key in keyof Partial<ListingWithDetails>]: string };
  notEmpty?: boolean;
  propertyOptions?: {
    [key in keyof Partial<ListingWithDetails>]: Partial<
      SubSectionValue<string>
    >;
  };
}

export const getPropertiesFromListing = ({
  label,
  listing,
  accessors,
  notEmpty,
  propertyOptions,
}: GetPropertiesFromListingParams): ListingDetailsSubSection<string> | null => {
  const values = _getPropertyValues({
    listing,
    accessors,
    notEmpty,
    propertyOptions,
  });
  return isNotEmpty(values) ? { label, values } : null;
};

export const getPrimaryLabelData = (
  listing: ListingWithDetails
): PrimaryLabelData => ({
  primaryLabel: listing.primaryLabel,
  latitude: listing.latitude,
  longitude: listing.longitude,
  openhouse: listing.openhouse,
});

export const getOpenHouseListingData = (
  listing: ListingWithDetails
): OpenHouseBarListing => ({
  name: listing.address,
  description: listing.publicremarks,
  location: listing.address,
  latitude: listing.latitude,
  longitude: listing.longitude,
  listingId: listing.listingid,
});

interface ParsedListingDetailsUrl {
  address: string | undefined;
  id: string;
}

const listingDetailsUrlRegExp = new RegExp('/?(?:(.+)-)?([a-z0-9]+)\\.html');
export const parseListingDetailsUrl = (
  pathname = history.location.pathname
): ParsedListingDetailsUrl | null => {
  const htmlPart = decodeURIComponent(pathname)
    .split('/')
    .find(x => x.includes('.html'));
  const matched = htmlPart?.match(listingDetailsUrlRegExp);
  if (matched) {
    return { address: matched[1], id: matched[2] };
  }
  return null;
};

export const getSchoolDistrictFromListing = (
  listing: ListingWithDetails | ExclusiveListing
) => get(listing, ['polygons', PlaceType.SchoolDistrict]);
